import React from 'react';
import { Feed, Icon, Label, SemanticCOLORS } from "semantic-ui-react";
import parse from "html-react-parser";
import { v4 as uuidv4 } from 'uuid';
import { getPassedTime } from "../../../helper/HelperFunctions";
import TranslateHelper from '../../../helper/TranslateHelper';
import '../../../../src/App.css';

const LogCard = (props: any) => {
    var { translate } = TranslateHelper();
    const getBgStyle = (log): SemanticCOLORS => {
        if (log.eventType == "Login") {
            return "olive";
        } else if (log.eventType == "ForceLogin") {
            return "orange";
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && log?.detailModel?.isCompleted) {
            return "blue";
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && !log?.detailModel?.isCompleted) {
            return "violet";
        } else if (log.eventType == "CertificateUpload") {
            return "yellow";
        } else if (log.eventType == "TOUC") {
            return "green";
        }
        return "grey";
    }

    const getBgClassName = (log): string => {
        if (log.eventType == "Login") {
            return "ingOrange";
        } else if (log.eventType == "ForceLogin") {
            return "ingLeaf";
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && log?.detailModel?.isCompleted) {
            return "ingIndigo";
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && !log?.detailModel?.isCompleted) {
            return "ingSky";
        } else if (log.eventType == "CertificateUpload") {
            return "ingLime";
        } else if (log.eventType == "TOUC") {
            return "ingGreen"; 
        }
        return "grey";
    }
    const convertEventTypeToTurkishText = (log) => {
        if (log.eventType == "Login") {
            return <Label circular={true} className={"ingOrange"} horizontal>{translate("Giriş")}</Label>;
        } else if (log.eventType == "ForceLogin") {
            return <Label circular={true} className="ingLeaf" horizontal>{translate("Adına Giriş")}</Label>;
        } else if (log.eventType == "ModuleProggress") {
            return <Label circular={true} className="ingIndigo" horizontal>{translate("Modül İlerlemesi")}</Label>;
        } else if (log.eventType == "ModuleProggress") {
            return <Label circular={true} className="ingSky" horizontal>{translate("Modül İlerlemesi")}</Label>;
        } else if (log.eventType == "CertificateUpload") {
            return <Label circular={true} className="ingLime" horizontal>{translate("Sertifika Kazanımı")}</Label>;
        } else if (log.eventType == "TOUC") {
            return <Label circular={true} className="ingGreen" horizontal>{translate("KVKK Kabul")}</Label>;
        }
        return <Label className="roundedButton" horizontal>{translate("Tanımsız")}</Label>;
    }

    const getLogIconName = (log) => {
        if (log.eventType == "Login") {
            return "user";
        } else if (log.eventType == "ForceLogin") {
            return "user secret";
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && log?.detailModel?.isCompleted) {
            return "star";
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && !log?.detailModel?.isCompleted) {
            return "star half full";
        } else if (log.eventType == "CertificateUpload") {
            return "certificate";
        } else if (log.eventType == "TOUC") {
            return "handshake";
        }
        return "world";
    }

    return (
        <Feed key={uuidv4()} style={{ width: "100%" }}>
            <Feed.Event>
                <Feed.Label className={"adminDashboardLogFeed"}>
                    <div>
                        <Label size={"large"} circular className={getBgClassName(props.Log)}>
                            <div>
                                <Icon
                                    className={"adminDashboardLabelIcon"}
                                    style={{ color: "white" }}
                                    name={getLogIconName(props.Log)}
                                />
                            </div>
                        </Label>
                    </div>
                    <div className={"adminDashboardLogLine"}></div>
                </Feed.Label>
                <Feed.Content
                    className={"adminDashboardLogContentFeed"}
                    style={{
                        marginLeft: "6%",
                        padding: "3%"
                    }}
                >
                    <Feed.Date><Icon name={"clock outline"} />{getPassedTime(props.Log.createDateTime)}</Feed.Date>
                    <Feed.Summary>
                        {parse(props.Log.detailModel.data)}
                    </Feed.Summary>
                    <Feed.Extra text>
                        {convertEventTypeToTurkishText(props.Log)}
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        </Feed>
    );
};

export default LogCard;