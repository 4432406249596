import React, {useState, useEffect} from 'react';
import {
    Input,
    Icon,
    Modal,
    Button,
    Header,
    Grid,
    Dropdown,
    Popup,
    Message,
    Transition,
    Checkbox
} from 'semantic-ui-react';
import {Link} from "react-router-dom";
import './WordListScreen.css';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import DataTable from '../../../components/DataTable';
import {IDataTable} from '../../../interfaces/interfaces';
import {datableFilterChange, digitallicaConfirm, prepareDataForTable} from '../../../helper/HelperFunctions';
import {toast} from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import {timingSafeEqual} from "crypto";
import { DataHandler } from '../../../helper/DataHelper';


const Words = (props) => {
    var {translate} = TranslateHelper();
    const _ = require('lodash');
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState({
        currentPage: 1,
        tenantId: DataHandler.getUserDataWithKey("tenantId"),
        isDeleted: isDeleted,
        asc: true,
        filterText: filterText
    });

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    };

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    //delete selected data
    const deleteWord = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz ?"),
            () => {

                let word = {
                    id: id
                }
                NetworkRequest("api/DictionaryWord/DeleteById", word).then((response) => {
                    let filterData = {
                        currentPage: currentPage,
                        tenantId: DataHandler.getUserDataWithKey("tenantId"),
                        isDeleted: isDeleted,
                        isActive: isActive,
                        asc: true,
                        filterText: filterText
                    }
                    getWords(filterData);
                    toast(translate("Başarılı."), {type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000});

                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Geri döndürmek istediğinize emin misiniz ?"),
            () => {
                let word = {
                    id: id
                }

                NetworkRequest("api/DictionaryWord/UndoDelete", word).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        filterData.isDeleted = true;
                        setFilterData(filterData);

                        getWords(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }


    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const collectiveProggress = (e, data, dtm?) => {
        if (data.value == "")
            return;
        dtm = dtm == undefined ? dataTableModel : dtm;
        digitallicaConfirm(
            translate("Toplu işlem yapacaksınız ? Emin misiniz ?"),
            () => {
                let rows: Array<any> = [];
                let datatableClone = _.cloneDeep(dtm);
                let filtereeds = datatableClone.data.filter(x => x.secretData[0].isSelected == true);

                filtereeds.map(x => {
                    rows.push(x.secretData[0].id)
                })
                ////console.log(rows);

                switch (data.value) {
                    case 1:
                        NetworkRequest("/api/DictionaryWord/UndoDeleteCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                setIsDeleted(false);
                                toast(rows.length + translate(" Sözcük geri getirildi."), {
                                    type: 'info',
                                    theme: 'dark',
                                    position: 'top-center',
                                    autoClose: 3500
                                });
                            }
                        });
                        break;
                    case 2:
                        NetworkRequest("/api/DictionaryWord/DeleteCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                setIsDeleted(true);
                                toast(rows.length + translate(" Sözcük silindi."), {
                                    type: 'info',
                                    theme: 'dark',
                                    position: 'top-center',
                                    autoClose: 3500
                                });
                            }
                        });
                        break;
                    default:
                }
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const cloneRow = (id) => {
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/api/DictionaryWord/Clone", test).then((response) => {
                    if (response.data.resultCode === 0) {
                        getWords(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );

    }

    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            <Checkbox
                onChange={setAllSelected} checked={isAllSelected}/>,
            translate('Kelime'),
            translate('Oluşturma Tarihi'),
            translate('İşlemler')
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {
                        id: "",
                        isDeleted: false,
                        isSelected: false
                    }
                ]
            }
        ],
        transactions: [
            {
                icon: "edit",
                desc: translate("Düzenle"),
                color: "yellow",
                link: "/editWord",
                type: "edit"
            },
            {
                icon: "clone",
                desc: translate("Klonla"),
                color: undefined,
                function: cloneRow,
                type: "clone"
            },
            {
                icon: "trash",
                desc: translate("Sil"),
                color: "red",
                link: null,
                type: "delete",
                function: deleteWord
            },
            {
                icon: "redo",
                desc: translate("Geri al"),
                color: undefined,
                link: null,
                type: "undo",
                function: undoDelete
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            currentPage: currentPage,
            setCurrentPage: setCurrentPage,
        },
        noCheckBox: false,
        setRowSelected: setRowSelected,
        addExcelLink: "/dictionaryExcelImport",
        addRowLink: "/addWord",
        collectiveProgresses:
            {
                options: [{key: 2, text: translate('Toplu Sil'), value: 2}],
                event: collectiveProggress
            }
    });

    useEffect(() => {
        let filterData = {
            currentPage: currentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            isDeleted: isDeleted,
            isActive: isActive,
            asc: true,
            filterText: filterText,
            startingDate: startingDate,
            dueDate: dueDate
        }
        setFilterData(filterData);
        getWords(filterData);

    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    const prepareData = (response, filterData) => {

        let data = response;
        if (data.length == 0 && currentPage > 1)
            setCurrentPage(1);
        ////console.log(data);
        //hidden column
        let model = prepareDataForTable(data.list, [
            'id',
            'abbreviation',
            'category',
            'categoryId',
            'isDeleted',
            'deletedDate',
            'description',
            'dictionary',
            'dictionaryId',
            'dictionaryWordsId',
            'image',
            'link',
            'modifiedDate',
            'otherLanguage',
            'tenant',
            'tenantId',
            'useArea',
            'useAreaId',
            'video',
            'voice'
        ]);


        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                header: dataTableModel.header,
                data: model,
                transactions: dataTableModel.transactions,
                filter: {
                    search: filterText,
                    setSearch: setFilterText,
                    filterOption: [

                        {
                            desc: translate("Silinen"),
                            defaultValue: filterData.isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(data.count / 50),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);
        setIsLoading(false);
    }


    const getWords = (filterData) => {
        NetworkRequest('/api/DictionaryWord/GetWordsByDate', filterData).then((response) => {
            if (response.data.resultCode == 0) {

                var result = response.data.detail;
                ////console.log(result);

                prepareData(result, filterData)
            } else {
                ////console.log("error")
            }
        });
    };

    useEffect(() => {

        if (isDeleted) {
            dataTableModel.collectiveProgresses = {
                options: [{key: 1, text: translate('Toplu Geri Al'), value: 1}],
                event: collectiveProggress
            }
            setDataTableModel(dataTableModel);
        } else {
            dataTableModel.collectiveProgresses = {
                options: [{key: 2, text: translate('Toplu Sil'), value: 2}],
                event: collectiveProggress
            }
            setDataTableModel(dataTableModel);
        }


    }, [isDeleted]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                                   setAllSelected={setAllSelected} selectAllHandler={selectAllHandler}
                                   isLoading={isLoading}/>
                    </div>
                </Transition>
            </div>
        </div>
    );

}

export default Words;
