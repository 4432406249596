import * as React from 'react';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation, Navigate } from 'react-router-dom';
import { checkLoginTimestamp, getRandomIntInclusive, logoutApi, sessionControlForLogout, tabTitle } from "./helper/HelperFunctions";
import Layout from './pages/CommonPages/Layout';
import './App.css';
import './components/Quill/quill.snow.css';
import { useNavigate } from 'react-router-dom';
//common pages
import SiteOff from './pages/CommonPages/SiteOff';
import PrivacyPolicy from './pages/CommonPages/PrivacyPolicy';

//super admin pages
import SuperAdminLayout from './pages/SuperAdminDashbord/SuperAdminLayout';
import SuperAdminDashboard from './pages/SuperAdminDashbord/SuperAdminDashboard';
import Tenants from './pages/SuperAdminDashbord/Tenants/Tenants';
import Subscriptions from './pages/SuperAdminDashbord/Subscriptions/Subscriptions';
import TenantSubscriptions from './pages/SuperAdminDashbord/TenantSubscriptions/TenantSubscriptions';
import LanguageSettings from './pages/SuperAdminDashbord/Languages/LanguageSettings';

//admin pages
import AdminLayout from './pages/AdminDashboard/AdminLayout';
import Dashboard from './pages/AdminDashboard/Dashboard';
import UserList from './pages/AdminDashboard/Users/UserList';
import UserAdd from './pages/AdminDashboard/Users/UserAdd';
import UserImport from './pages/AdminDashboard/Users/UserImport';
import Quizes from './pages/AdminDashboard/Quizes/Quizes';
import Questions from './pages/AdminDashboard/Questions/Questions';
import AddQuiz from './pages/AdminDashboard/Quizes/AddQuiz';
import EditQuiz from './pages/AdminDashboard/Quizes/EditQuiz';
import QuestionAdd from './pages/AdminDashboard/Questions/QuestionAdd';
import QuestionEdit from './pages/AdminDashboard/Questions/QuestionEdit';
import Modules from './pages/AdminDashboard/Modules/Modules';
import Activities from './pages/AdminDashboard/Activities/Activities';
import ActivityImport from './pages/AdminDashboard/Activities/ActivityImport';
import ActivityAdd from './pages/AdminDashboard/Activities/ActivityAdd';
import Surveys from './pages/AdminDashboard/Surveys/Surveys';
import AddSurvey from './pages/AdminDashboard/Surveys/AddSurvey';
import ModuleAdd from './pages/AdminDashboard/Modules/ModuleAdd';
import Reports from './pages/AdminDashboard/Reports/Report';
import UserReportDetail from './pages/AdminDashboard/Reports/UserReportDetail';
import ModuleReportDetail from './pages/AdminDashboard/Reports/ModuleReportDetail';
import ExamReportDetail from './pages/AdminDashboard/Reports/ExamReportDetail';
import QuizQuestionDetail from './pages/AdminDashboard/Reports/QuizQuestionDetail';
import SurveyPersonDetailReport from './pages/AdminDashboard/Reports/SurveyPersonDetailReport';
import SurveyQuestionDetailReport from './pages/AdminDashboard/Reports/SurveyQuestionDetailReport';
import QuestionAndQuiz from './pages/AdminDashboard/Questions/QuestionAndQuiz';
import SurveyQuestionAdd from './pages/AdminDashboard/SurveyQuestions/SurveyQuestionAdd';
import SurveyQuestions from './pages/AdminDashboard/SurveyQuestions/SurveyQuestions';
import SurveyQuestionAndSurvey from './pages/AdminDashboard/SurveyQuestions/SurveyQuestionAndSurvey';
import Settings from './pages/AdminDashboard/Settings/Settings';
import HomePage from './pages/UserDashboard/Home/HomePage';
import HomePage2 from './pages/UserDashboard/Home/HomePageV2';
import ResetPassword from './pages/CommonPages/ResetPassword';
import ScheduledJobList from './pages/AdminDashboard/ScheduledJobs/ScheduledJobList';
import AddScheduledJob from './pages/AdminDashboard/ScheduledJobs/AddScheduledJob';
import CategoryList from './pages/AdminDashboard/Categories/CategoryList';
import AddCategory from './pages/AdminDashboard/Categories/AddCategory';
import Dictionary from './pages/AdminDashboard/Dictionary/Dictionary';
import AddDictionary from './pages/AdminDashboard/Dictionary/AddDictionary';
import DictionarySettings from './pages/AdminDashboard/Dictionary/DictionarySettings';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IdleTimeOutHandler from './components/IdleTimeOutHandler';
import { media, style } from 'typestyle';
import AddTOUCPopup from './pages/AdminDashboard/PopupFirstLogin/AddTOUCPopup';
import FirstLoginPopup from './components/FirstLoginPopup';
import AddDocument from './pages/AdminDashboard/Documents/AddDocument';
import CertificatePrinter from './Certificate/CertificatePrinter';
import { NetworkRequest } from './helper/NetworkRequest';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddCertificate from './pages/AdminDashboard/Certificates/AddCertificate';
import LdapLogin from './pages/CommonPages/LdapLogin';
import Home from './pages/CommonPages/Home';
import HomeV2 from './pages/CommonPages/HomeV2';
import Partner from './pages/CommonPages/HomeV2Pages/Partner';
import RequestDemo from './pages/CommonPages/HomeV2Pages/RequestDemo';
import Pricing from './pages/CommonPages/HomeV2Pages/Pricing';
import Customers from './pages/CommonPages/HomeV2Pages/Customers';
import AboutUs from './pages/CommonPages/HomeV2Pages/AboutUs';

import HomeLogin from './pages/CommonPages/HomeLogin';
import Packets from './pages/CommonPages/Packets';
import ManagementHomePageV2 from './pages/ManagementDashboard/Home/ManagementHomePageV2';
import WelcomeBant from './pages/CommonPages/WelcomeBannerSettings';
import LdapSettings from './pages/CommonPages/LdapSettings';
import TestInputTrial from './pages/AdminDashboard/TestInputTrial';

import AddWord from './pages/AdminDashboard/Dictionary/AddWord';
import { isMobile } from 'react-device-detect';
import Words from './pages/AdminDashboard/Dictionary/Words';
import WordImportExcel from './components/Dictionary/WordImportExcel';
import CertificateList from './pages/AdminDashboard/Certificates/CertificateList';
import ModuleDetail from './pages/UserDashboard/ModuleDetail/ModuleDetail';
import RenderContentScorm from './components/Content/RenderContentScorm';
import SurveyQuestionImport from './pages/AdminDashboard/SurveyQuestions/SurveyQuestionImport';
import QuestionImport from './pages/AdminDashboard/Questions/QuestionImport';
import { v4 as uuidv4 } from 'uuid';
import DocumentList from './pages/AdminDashboard/Documents/DocumentList';

import { selectedLanguageContext, translationListContext, authUserListContext, apiUrlContext } from "./Store";
import { displayProtectedAppDoc } from './helper/DisplayProtectedDocument';
import { DataHandler } from './helper/DataHelper';
import NotAuthorized from './pages/CommonPages/NotAuthorized';
import NotFoundPage from "./pages/CommonPages/NotFoundPage";
import NotFoundPageCommon from "./pages/CommonPages/NotFoundPageCommon";
import EditTranslationKeyword from "./pages/SuperAdminDashbord/Languages/EditTranslationKeyword";
import AddTranslationKeyword from "./pages/SuperAdminDashbord/Languages/AddTranslationKeyword";
import ApplicationHelpers from './pages/AdminDashboard/ApplicationHelpers/ApplicationHelpers';
import ApplicationHelperTypes from './pages/AdminDashboard/ApplicationHelperType/ApplicationHelperTypes';
import AddApplicationHelperType from './pages/AdminDashboard/ApplicationHelperType/AddApplicationHelperType';
import AddApplicationHelper from './pages/AdminDashboard/ApplicationHelpers/AddApplicationHelper';
import NewTenant from './pages/CommonPages/NewTenant';
import ModuleCategory from "./pages/UserDashboard/ModuleDetail/ModuleCategory";
import GenerateSecret from './pages/CommonPages/GenerateSecret';
import { motion } from 'framer-motion';
import LoadingGif from '../src/images/loading-gif.gif';
import { Button, Icon, Input, Loader, Message, MessageHeader } from 'semantic-ui-react';
import TOUCEmptyPage from './pages/CommonPages/TOUCEmptyPage';
import TranslateHelper from './helper/TranslateHelper';
import PasswordPolicyChecker from './components/PasswordPolicyChecker/PasswordPolicyChecker';
import { time } from 'console';
function App() {
    var { translate } = TranslateHelper();
    let navigate = useNavigate();

    let _ = require('lodash');
    let styles = {
        footer: style({
            width: "100%",
            backgroundColor: "#292828",
            textAlign: "center" as "center",
            bottom: "0",
            color: "white",
            display: "flex",
            alignItems: "center",
            flexDirection: "column" as "column",
            justifyContent: "center",
            position: "absolute" as "absolute",
            height: "2.5rem"
        },
            media({ maxWidth: 820 })
        )
    }
    const [isAllPageLoading, setIsAllPageLoading] = useState(true);
    const [translationList, setTranslationList] = useContext(translationListContext);
    const [authUserList, setAuthUserList] = useContext(authUserListContext);
    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [apiUrl, setApiUrl] = useContext(apiUrlContext);

    const { pathname } = useLocation();
    const [documentManagerUrl, setDocumentManagerUrl] = useState("");
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [userRoleId] = useState(DataHandler.getUserDataWithKey("roleId"));
    const [isBaseTenant] = useState(DataHandler.getUserDataWithKey("isBaseTenant"));
    const [loginUserId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [loginUserEmail] = useState(DataHandler.getUserDataWithKey("email"));
    const [loginUserTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [isUserFirstEntryPassword, setIsUserFirstEntryPassword] = useState(false);
    const [isDefaultSetting, setIsDefaultSetting] = useState(DataHandler.getUserDataWithKey("isDefaultSetting"));
    const [logoUrl, setLogoUrl] = useState(DataHandler.getUserDataWithKey("logoUrl"));
    const [siteTitle, setSiteTitle] = useState(DataHandler.getUserDataWithKey("siteTitle"));
    const [favIconUrl, setFavIconUrl] = useState(DataHandler.getUserDataWithKey("favIconUrl"));
    const [isSiteActive, setIsSsSiteActive] = useState(true);
    const [isSiteActiveCookie, setIsSsSiteActiveCookie] = useState(DataHandler.getUserDataWithKey("isActive"));
    const [isActive, setIsActive] = useState(true);
    const [pageRoutes, setPageRoutes] = useState<any>();
    const [isLogout, setLogout] = useState(DataHandler.getStore() == undefined);
    const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
    const [isPageLoading, setIsLoadingPage] = useState(true);
    const [isLoadingPasswordChange, setIsLoadingPasswordChange] = useState(false);


    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");

    const [isShowPasswordPolicyChecker, setIsShowPasswordPolicyChecker] = useState(false);
    const [passwordPolicyResponseList, setPasswordPolicyResponseList] = useState<any>();
    const [isPasswordPolicyAllDone, setIsPasswordPolicyAllDone] = useState<any>(false);
    const [isCloseAllPasswordSettings, setIsCloseAllPasswordSettings] = useState(false);


    const [isShowCurrentPassword, setIsShowCurrentPassword] = useState(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState(false);
    const [isShowReNewPassword, setIsShowReNewPassword] = useState(false);

    const [passwordChangeErrorMessage, setPasswordChangeErrorMessage] = useState("İlk kez giriş yaptığınızda, güvenliğiniz için şifrenizi değiştirmeniz gerekmektedir.");

    const getTabTitle = () => {
        let tenantId = DataHandler.getUserDataWithKey("tenantId");
        if (tenantId != undefined) {
            let data = {
                Id: tenantId
            };
            NetworkRequest("/api/SiteSetting/Get", data).then((response) => {
                if (response.data.resultCode == 0) {
                    let title = response.data.detail.settingTitle;
                    tabTitle(title);

                    DataHandler.removeLogoImage();

                    setLogoUrl(response.data.detail.logoUrl)
                }
            });
        }

        setIsLoadingPage(false);
        //const loaderElement = document.querySelector(".loader-container");
        //if (loaderElement) {
        //    loaderElement.remove();
        //}
    }

    const getDocumentManagerUrl = () => {
        NetworkRequest("/api/SiteSetting/GetDocumentManagerUrl").then((response) => {
            if (typeof response != "string") {
                if (response.data.resultCode == 0) {
                    DataHandler.writeDocmanagerUrl(response.data.detail);
                    setDocumentManagerUrl(response.data.detail);
                }
            }


        });
    }

    const getLoginImage = () => {
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        NetworkRequest("/api/SiteSetting/GetFavIcon", data).then(response => {

            if (response.data.resultCode == 0) {
                var link = document.getElementById("siteFavIcon");

                if (link != undefined) {

                    var favIconResponse = response.data.detail;

                    if (!favIconResponse) return;

                    var favicon = "data:image/x-icon;" + favIconResponse?.split(";")[1]?.trim();
                    link.setAttribute('href', favicon);
                }
            }
        });
    }

    const getGAKey = () => {
        NetworkRequest("/api/SiteSetting/GetGAKey").then((response) => {
            if (typeof response != "string") {
                if (response.data.resultCode == 0) {
                    DataHandler.writeGAKey(response.data.detail);
                    // setDocumentManagerUrl(response.data.detail);
                }
            }


        });
    }

    const getSiteKey = () => {
        NetworkRequest("/api/SiteSetting/GetSiteKey").then((response) => {
            if (typeof response != "string") {
                if (response.data.resultCode == 0) {
                    DataHandler.writeSiteKey(response.data.detail);
                    // setDocumentManagerUrl(response.data.detail);
                }
            }


        });
    }

    const getComponent = (page) => {
        switch (page.pageComponent) {
            case "Home":
                return (
                    <React.Fragment key={uuidv4()}>
                        {/*<Route path={page.pageUrl} element={<Home />} />*/}
                        {/*<Route path={page.pageUrl} element={<Home />} />*/}
                        <Route path={page.pageUrl} element={<HomeV2 />} />
                    </React.Fragment>
                );

            case "Partner":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route path={page.pageUrl} element={<Partner />} />
                    </React.Fragment>
                )

            case "RequestDemo":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route path={page.pageUrl} element={<RequestDemo />} />
                    </React.Fragment>
                )

            case "Pricing":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route path={page.pageUrl} element={<Pricing />} />
                    </React.Fragment>
                )

            case "Customers":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route path={page.pageUrl} element={<Customers />} />
                    </React.Fragment>
                )

            case "HomeV2":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route path={page.pageUrl} element={<HomeV2 />} />
                    </React.Fragment>
                )
            case "Packets":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Packets />} />
                );
            case "AboutUs":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<AboutUs />} />
                );
            case "HomeLogin":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<HomeLogin />} />
                );
            case "ResetPassword":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<ResetPassword />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ResetPassword />} />
                    </React.Fragment>
                );
            case "LdapLogin":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<LdapLogin />} />
                );
            case "RenderContentScorm":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<RenderContentScorm />} />
                );
            case "SuperAdminDashboard":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SuperAdminDashboard />} />
                );
            case "Tenants":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Tenants />} />
                );
            case "Subscriptions":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Subscriptions />} />
                );
            case "TenantSubscriptions":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<TenantSubscriptions />} />
                );
            case "LanguageSettings":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<LanguageSettings />} />
                );
            case "Dashboard":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Dashboard />} />
                );
            case "UserList":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<UserList />} />
                );
            case "UserAdd":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<UserAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<UserAdd />} />
                    </React.Fragment>
                );
            case "UserImport":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<UserImport />} />
                );
            case "Quizes":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Quizes />} />
                );
            case "AddQuiz":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<AddQuiz />} />
                );
            case "EditQuiz":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<EditQuiz />} />
                );
            case "Questions":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Questions />} />
                );
            case "SurveyQuestions":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestions />} />
                );
            case "SurveyQuestionImport":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestionImport />} />
                );
            case "QuestionAdd":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<QuestionAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<QuestionAdd />} />
                    </React.Fragment>
                );
            case "QuestionImport":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<QuestionImport />} />
                );
            case "SurveyQuestionAdd":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestionAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestionAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestionAdd />} />
                    </React.Fragment>
                );
            case "SurveyQuestionAndSurvey":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestionAndSurvey />} />
                );
            case "QuestionEdit":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<QuestionEdit />} />
                );
            case "ExamReportDetail":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ExamReportDetail />} />
                );
            case "Surveys":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Surveys />} />
                );
            case "AddSurvey":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddSurvey />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddSurvey />} />
                    </React.Fragment>
                );
            case "Modules":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Modules />} />
                );
            case "ModuleAdd":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleAdd />} />
                    </React.Fragment>
                );
            case "Activities":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Activities />} />
                );
            case "ActivityAdd":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<ActivityAdd />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ActivityAdd />} />
                    </React.Fragment>
                );
            case "ActivityImport":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ActivityImport />} />
                );
            case "AddScheduledJob":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddScheduledJob />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddScheduledJob />} />
                    </React.Fragment>
                );
            case "CategoryList":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<CategoryList />} />
                );
            case "AddCategory":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddCategory />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddCategory />} />
                    </React.Fragment>
                );
            case "Reports":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<Reports />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<Reports />} />
                    </React.Fragment>
                );
            case "UserReportDetail":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<UserReportDetail />} />
                );
            case "ModuleReportDetail":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ModuleReportDetail />} />
                );
            case "QuizQuestionDetail":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<QuizQuestionDetail />} />
                );
            case "SurveyPersonDetailReport":

                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SurveyPersonDetailReport />} />
                );
            case "SurveyQuestionDetailReport":

                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SurveyQuestionDetailReport />} />
                );
            case "QuestionAndQuiz":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<QuestionAndQuiz />} />
                );
            case "AddPopupFirstLogin": //need to change, before db after here. #change
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<AddTOUCPopup />} />
                );
            case "WelcomeBant":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<WelcomeBant />} />
                );
            case "LdapSettings":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<LdapSettings />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<LdapSettings />} />
                    </React.Fragment>
                );
            case "TestInputTrial":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<TestInputTrial />} />
                );
            case "Documents":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<DocumentList />} />
                );
            case "AddDocument":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddDocument />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddDocument />} />
                    </React.Fragment>
                );
            case "CertificatePrinter":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<CertificatePrinter />} />
                );
            case "CertificateList":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<CertificateList />} />
                );
            case "AddCertificate":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddCertificate />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddCertificate />} />
                    </React.Fragment>
                );
            case "Settings":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Settings />} />
                );
            case "Dictionary":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Dictionary />} />
                );
            case "AddDictionary":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<AddDictionary />} />
                );
            case "DictionarySettings":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<DictionarySettings />} />
                );
            case "Words":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<Words />} />
                );
            case "AddWord":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddWord />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddWord />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddWord />} />
                    </React.Fragment>
                );
            case "WordImportExcel":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<WordImportExcel />} />
                );
            case "ModuleDetail":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ModuleDetail />} />
                );
            case "HomePage":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<HomePage2 />} />
                );
            case "ModuleDetail":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleDetail />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleDetail />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleDetail />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<ModuleDetail />} />
                    </React.Fragment>
                );
            case "ManagementHomePageV2":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ManagementHomePageV2 />} />
                );

            case "ModuleCategory":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ModuleCategory />} />
                )
            case "CertificatePrinter":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<CertificatePrinter />} />
                );
            case "ScheduledJobList":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ScheduledJobList />} />
                );
            // case "NotFoundPage":
            //     return (
            //         <Route
            //             key={uuidv4()}
            //             path={page.pageUrl}
            //             element={<NotFoundPageCommon/>}
            //         />
            //     );
            case "NotAuthorized":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<NotAuthorized />} />
                );
            case "SiteOff":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<SiteOff />} />
                );
            case "PrivacyPolicy":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<PrivacyPolicy />} />
                );

            case "EditTranslationKeyword":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<EditTranslationKeyword />} />
                );

            case "AddTranslationKeyword":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<AddTranslationKeyword />} />
                );

            case "ApplicationHelpers":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ApplicationHelpers />} />
                );

            case "AddApplicationHelper":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddApplicationHelper />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddApplicationHelper />} />
                    </React.Fragment>
                );

            case "ApplicationHelperTypes":
                return (
                    <Route key={uuidv4()} path={page.pageUrl} element={<ApplicationHelperTypes />} />
                );

            case "AddApplicationHelperType":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddApplicationHelperType />} />
                        <Route key={uuidv4()} path={page.pageUrl} element={<AddApplicationHelperType />} />
                    </React.Fragment>
                );

            case "NewTenant":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<NewTenant />} />
                    </React.Fragment>
                );

            case "GenerateSecret":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<GenerateSecret />} />
                    </React.Fragment>
                );

            case "TOUCEmptyPage":
                return (
                    <React.Fragment key={uuidv4()}>
                        <Route key={uuidv4()} path={page.pageUrl} element={<TOUCEmptyPage />} />
                    </React.Fragment>
                );
        }
    }

    const setVisiblePages = (pages) => {

        let switchComp = (
            <Routes>
                {pages.map((page) => (
                    getComponent(page)
                ))}

                <Route
                    key={uuidv4()}
                    path={"/*"}
                    element={<NotFoundPage />}
                />

                <Route
                    key={uuidv4()}
                    path={"/NotFoundPage"}
                    element={<NotFoundPageCommon />}
                />

            </Routes>
        );

        let notNullParentCompLayout = pages.find(x => x.parentComponent != null)?.parentComponent;

        if (notNullParentCompLayout == null) {
            //DataHandler.addPages(switchComp);
            setPageRoutes(switchComp);
        } else if (pages[0].parentComponent == "SuperAdminLayout") {
            let layout = (
                <React.Fragment>
                    <SuperAdminLayout IsDefaultSetting={isDefaultSetting}
                        SetIsDefaultSetting={setIsDefaultSetting} LogoUrl={logoUrl}
                        FavIconUrl={favIconUrl} SiteTitle={siteTitle} UserRole={userRole}>
                        {switchComp}
                    </SuperAdminLayout>
                </React.Fragment>
            );
            //DataHandler.addPages(layout);
            setPageRoutes(layout);
        } else if (notNullParentCompLayout == "AdminLayout") {
            let layout = (
                <React.Fragment>
                    <AdminLayout isDefaultSetting={isDefaultSetting}
                        setIsDefaultSetting={setIsDefaultSetting} LogoUrl={logoUrl} FavIconUrl={favIconUrl}
                        SiteTitle={siteTitle} userRole={userRole}>
                        {switchComp}
                    </AdminLayout>
                </React.Fragment>
            );
            //DataHandler.addPages(layout);
            setPageRoutes(layout);
        } else if (notNullParentCompLayout == "Layout") {
            let layout = (
                <React.Fragment>
                    <Layout LogoUrl={logoUrl} SiteTitle={siteTitle} FavIconUrl={favIconUrl}>
                        {switchComp}
                    </Layout>
                </React.Fragment>
            );
            //DataHandler.addPages(layout);
            setPageRoutes(layout);
        }
    }

    const getUserPages = () => {


        //NetworkRequest("/api/UserPages/GetUserPages", { role: userRole == undefined ? "ALL" : userRole }).then((response) => {
        //    if (typeof response != "string" && response.data.resultCode == 0) {
        //        let pages = response.data.detail;
        //        setVisiblePages(pages);
        //    }
        //});

        //for ing Custom Role name = JustLoginPage replace ALL
        /*   debugger;*/

        NetworkRequest("/api/UserPages/GetUserPages",
            {
                //role: (userRole == undefined || userRole == "ALL") ? "JustLoginPage" : userRole
                roleId: userRoleId
            }
        ).then((response) => {
            if (typeof response != "string" && response.data.resultCode == 0) {
                let pages = response.data.detail;
                setVisiblePages(pages);

                //var pathName = window.location.pathname;

                //if ((userRole == undefined || userRole == "ALL") && pathName != "/resetPassword") {
                //    //for ing
                //    navigate('/homeLogin');
                //}

            }
        });
    }

    const getSelectedLanguageId = () => {
        let tenantId = DataHandler.getUserDataWithKey("tenantId");
        if (tenantId != undefined) {
            let languageData = {
                tenantId: tenantId,
                userId: DataHandler.getUserDataWithKey("userId")
            }
            NetworkRequest("/api/Language/GetUserClaimLanguage", languageData).then((response) => {
                if (response.data.resultCode == 0) {
                    let language = response.data.detail;

                    if (translationList.length == 0) {
                        setSelectedLanguage(language);
                    }
                }
            });
        }
    }

    const getGroupedTranslationData = () => {
        if (translationList.length > 0) {
            return;
        }

        NetworkRequest("/api/Language/GetGroupedTranslationList", null).then((response) => {

            if (typeof response != "string" && response.data.resultCode == 0) {
                let data = response.data.detail;
                setTranslationList(data);
                setIsLoadingLanguages(false);
            }
        });
    }

    const getSavePhysical = () => {
        NetworkRequest("/api/Settings/GetSavePhysical").then((response) => {
            if (typeof response != "string" && response.data.resultCode == 0) {
                DataHandler.writeSavePhysical(response.data.detail);
            }
        });
    }

    const isAppAuth = () => {
        NetworkRequest('/Authentication/isAppAuth').then((response) => {
            if (typeof response != "string" && response.data.resultCode == 0) {
                /*debugger;*/
                DataHandler.writeIsAppAuth(response.data.detail);
                var link = document.getElementById("siteFavIcon");
                var control = DataHandler.getUserDataWithKey("favIconUrl");
                if (control != undefined && control != "" && link != undefined) {
                    link.setAttribute('href', DataHandler.getUserDataWithKey("favIconUrl") + "?v=" + getRandomIntInclusive());
                } else if (link != undefined) {
                    let linkRes = displayProtectedAppDoc("/Documents/Buddy/favico.ico");
                    if (link != undefined && linkRes != undefined && typeof (linkRes) == "string") {
                        link.setAttribute('href', linkRes);
                    }
                }
            }
            setIsAllPageLoading(!isAllPageLoading);
        });
    }

    const readSetting = () => {
        let config = require('./Config.txt');
        return fetch(config).then(response => {
            return response.json();
        }).then((jsondata) => {
            let url = "";
            if (process.env.NODE_ENV == "development") {
                url = jsondata.apiUrlDev;
            } else {
                url = jsondata.apiUrlLive;
            }
            DataHandler.writeApiUrl(url);
            DataHandler.writeUseCaptcha(JSON.parse(jsondata.useCaptcha));
            DataHandler.writeUseLdap(JSON.parse(jsondata.useLdap));
            return true;
        });
    }

    const getLoginUser = (userId) => {

        NetworkRequest('/api/User/GetViewById', { id: userId }).then((response) => {
            if (response.data.resultCode == 0) {

                var jsonUserDetail = response?.data?.detail?.details;

                if (jsonUserDetail)
                    jsonUserDetail = JSON.parse(jsonUserDetail);

                setIsFirstLogin(response.data.detail.firstLogin);
            }
        });
    }

    const getUserFirstEntryPasswordChangeMustSetting = () => {
        let data = {
            key: "FirstEntryPasswordChangeMust",
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            // value: isChecked
        };

        NetworkRequest("api/Settings/GetSetting", data, true).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail != null) {
                setIsUserFirstEntryPassword(response.data.detail.settingValue == "1" ? true : false)
            }
        });
    };

    useEffect(() => {
        if (isSiteActive == false && userRole == "User" && window.location.pathname.indexOf('siteOff') == -1) {
            window.location.href = '/siteOff';
        }
    }, [logoUrl, isSiteActive]);

    useEffect(() => {
        if (isSiteActiveCookie != undefined) {
            setIsSsSiteActive(isSiteActiveCookie != undefined ? isSiteActiveCookie : false);
        }
    }, [isSiteActiveCookie]);

    useEffect(() => {
        (async () => {
            await readSetting();
        })();


        //for ApiUrl set localStorage time 500ms
        setTimeout(() => {
            getLoginImage();
            getGAKey();
            getSiteKey();
            getGroupedTranslationData();
            getSelectedLanguageId();
            getDocumentManagerUrl();
            sessionControlForLogout();
            checkLoginTimestamp();
            setInterval(() => {
                checkLoginTimestamp();
            }, 60000);
            getTabTitle();
            if (DataHandler.isAppAuth == undefined) {
                isAppAuth();
            }
            if (DataHandler.savePhysical == undefined) {
                getSavePhysical();
            }

            getUserPages();

        }, 500)

        /*readSetting();*/
    }, []);

    useEffect(() => {
        sessionControlForLogout();
    }, [pathname]);

    useEffect(() => {
        if (loginUserId) {
            getLoginUser(loginUserId);
            getUserFirstEntryPasswordChangeMustSetting();
        }

        getUserPages();
    }, [userRole]);

    useEffect(() => {


        const timer = setTimeout(() => {
            let passwordData = {
                tenantId: DataHandler.getUserDataWithKey("tenantId"),
                password: newPassword?.trim()
            }

            NetworkRequest("api/Settings/CheckPasswordPolicyRequirementsStatus", passwordData, true).then((response) => {
                //
                if (response.data.resultCode == 0) {
                    let data = response.data.detail;
                    let list = data.passwordPolicyRequirementsStatusList;
                    let isAllPassed = data.isAllPassed;

                    setPasswordPolicyResponseList(list);
                    setIsPasswordPolicyAllDone(isAllPassed);
                    setIsCloseAllPasswordSettings(list != null);
                }
            });

        }, 1000);


        return () => { clearTimeout(timer) }



    }, [newPassword])

    if (isAllPageLoading) {
        return null;
    }

    const container = {
        hidden: { opacity: 0 }
    }

    const handleLogout = () => {
        logoutApi();
        DataHandler.logOutUser();

        window.location.href = "/homeLogin";

    }

    

    const saveUserPasswordAgain = () => {
        setIsShowPasswordPolicyChecker(false);
        setIsLoadingPasswordChange(true);

        let data = {
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
            ReNewPassword: newPasswordAgain,
            Email: loginUserEmail,
            TenantId: loginUserTenantId
        }



        NetworkRequest("api/User/UpdateUserPassword", data, true).then((response) => {
            if (response.data.resultCode == 0) {

                setIsFirstLogin(response.data != null && response.data.detail != null && response.data.detail.firstLogin == "1" ? true : false);

            } else {

                setPasswordChangeErrorMessage(response.data.message);
            }

            setIsLoadingPasswordChange(false);
        });
    }



    return (
        <>
            <motion.div
                className="loader-container"
                initial="show"
                animate={isPageLoading ? {
                    backdropFilter: ['blur(0px)', 'blur(65px)'],
                } : {
                    opacity: [1, 0], backdropFilter: ['blur(65px)', 'blur(0px)'],
                    transitionEnd: { zIndex: 0 }
                }}
                transition={{ delay: 1, duration: 1 }}

            >
                <Loader color="orange" size="big" active inline='centered' />
            </motion.div>

            {isFirstLogin && userRole != "User" && isUserFirstEntryPassword &&
                <div className="firstLoginPasswordChangeContainerClass">
                    <div> <img src={logoUrl} /> </div>
                    <div>
                        <Message style={{ borderRadius: "20px" }} error>
                            <MessageHeader>{translate("Uyarı")}</MessageHeader>
                            <p>{translate(passwordChangeErrorMessage)}</p>
                        </Message>
                    </div>
                    <div className={"newPasswordField"}>

                    </div>
                    <div>
                        <label>{translate("Geçerli şifre")}</label>
                        <Input
                            type={isShowCurrentPassword ? "text" : "password"}
                            icon={true}
                            onChange={(e) => { setCurrentPassword(e.target.value) }}
                            size="big"
                            placeholder={translate("Geçerli şifre")}
                            className="greyInput"
                        >
                            <Icon
                                onClick={() => {
                                    setIsShowCurrentPassword(!isShowCurrentPassword)
                                }}
                                link={true}
                                name={isShowCurrentPassword ? "eye" : "eye slash"}
                            />
                            <input />

                        </Input>
                    </div>
                    <div>
                        <div className="firstLoginPasswordChangePasswordPolicyContainer">
                            <PasswordPolicyChecker
                                PasswordPolicyResponseList={passwordPolicyResponseList}
                                IsShow={isShowPasswordPolicyChecker}
                            />
                        </div>

                        <label>{translate("Yeni şifre")}</label>
                        <Input
                            type={isShowNewPassword ? "text" : "password"}
                            icon={true}
                            onFocus={() => {
                                setIsShowPasswordPolicyChecker(true)
                            }}
                            onBlur={() => {
                                setIsShowPasswordPolicyChecker(false)
                            }}
                            onChange={(e) => {

                                setNewPassword(e.target.value);
                            }}
                            size="big"
                            placeholder={translate("Yeni şifre")}
                            className="greyInput"
                        >
                            <Icon
                                onClick={() => {
                                    setIsShowNewPassword(!isShowNewPassword)
                                }}
                                link={true}
                                name={isShowNewPassword ? "eye" : "eye slash"}
                            />
                            <input />

                        </Input>
                    </div>
                    <div>
                        <label>{translate("Yeni şifre tekrar")}</label>
                        <Input
                            type={isShowReNewPassword ? "text" : "password"}
                            icon={true}
                            onChange={(e) => { setNewPasswordAgain(e.target.value) }}
                            size="big"
                            placeholder={translate("Yeni şifre tekrar")}
                            className="greyInput"
                        >
                            <Icon
                                onClick={() => {
                                    setIsShowReNewPassword(!isShowReNewPassword)
                                }}
                                link={true}
                                name={isShowReNewPassword ? "eye" : "eye slash"}
                            />
                            <input />
                        </Input>
                    </div>

                    <div className="firstLoginPasswordButtonsContainer">
                       <Button
                            loading={isLoadingPasswordChange}
                            disabled={isLoadingPasswordChange || !isPasswordPolicyAllDone}
                            onClick={saveUserPasswordAgain}
                            circular
                            color="green">{translate("Kaydet")}</Button>

                      <Button
                            onClick={handleLogout}
                            circular
                            color="red">{translate("Çıkış")}</Button>
                    </div>
                    
                </div>
            }



            <motion.div
                variants={container}
                initial="hidden"
                animate={(pageRoutes != undefined && !isLoadingLanguages && !isPageLoading) ? {
                    opacity: [0, 1],
                    transitionEnd: { zIndex: 9999 }
                } : { opacity: [1, 0] }}
                transition={{ duration: 1 }}
            >
                <div id="appDiv"
                    className="appContainer"
                >
                    <div className="appContainerBottomPadding"
                        style={DataHandler.getUserDataWithKey("isLogin") ? { marginTop: isMobile ? "0" : "unset" } : {}}>
                        <IdleTimeOutHandler
                            onActive={() => {
                                setIsActive(true)
                            }}
                            onIdle={() => {
                                setIsActive(false)
                            }}
                            onLogout={() => {
                                setLogout(true)
                            }}
                        />
                        <FirstLoginPopup />



                        {pageRoutes}

                        <ToastContainer />
                    </div>
                </div>
            </motion.div>
        </>
    );

}

export default React.memo(App);