import * as React from 'react';
import { useState, useEffect } from 'react';
import { Checkbox, Transition, Loader, Table, Popup, Button, Icon, Pagination } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DateFormat } from '../../../../helper/DateFormat';
import { NetworkRequest } from '../../../../helper/NetworkRequest';
import DataTable from '../../../../components/DataTable';
import { IDataTable } from '../../../../interfaces/interfaces'
import { prepareDataForTable } from '../../../../helper/HelperFunctions';
import { isMobile } from "react-device-detect";
import { style } from 'typestyle';
import TranslateHelper from "../../../../helper/TranslateHelper";
import { v4 as uuidv4 } from 'uuid';

const SubscriptionList = (props) => {
    var { translate } = TranslateHelper();
    //styles
    let styles = {
        tableSection: {
            marginTop: "20px"
        },
        paginationBox: {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
        activeColor: {
            color: 'green',
            position: 'relative',
            left: '9%'
        },
        passiveColor: {
            color: 'red',
            position: 'relative',
            left: '9%'
        },
        editIcon: {
            position: 'relative',
            color: 'blue'
        },
        removeIcon: {
            position: 'relative',
            left: '1%',
            color: 'red'
        }
    }

    const [isLoading, setIsLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [subscriptionTypes, setSubscriptionTypes] = useState<any>([]);
    const tableHeader = [translate("Adı"), translate("Oluşturma Tarihi"), translate("İşlemler")];
    const [paginationHtml, setPaginationHtml] = useState<any>();
    const [filter, setFilter] = useState({
        filterText: filterText,
        currentPage: 1
    });

    //get table data from api
    const getSubscriptionTypes = (filterData?) => {
        NetworkRequest("/api/SubscriptionTypes/GetList", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let count = response.data.detail.count;
                setSubscriptionTypes(response.data.detail.list);
                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={Math.ceil(count / 25)}
                    onPageChange={
                        (event, data) => { setCurrentPage(Number(data.activePage)); }
                    }
                />);
                setIsLoading(false);
            }
        });
    }

    const removeSubscriptionType = (subscriptionType) => {
        subscriptionType.isDeleted = true;
        props.Update(subscriptionType).then((response) => {
            if (response.data.resultCode == 0) {
                let filterData = {
                    filterText: filterText,
                    currentPage: currentPage
                }
                getSubscriptionTypes(filterData);
            }
        });
    }

    const editSubscriptionTypeIcon = (subscriptionType) => {
        ////console.log('edit clicked');
        props.EditSubscriptionType(subscriptionType);
    }

    const changeActive = (subscriptionType) => {
        subscriptionType.isActive = !subscriptionType.isActive;
        props.Update(subscriptionType).then((response) => {
            if (response.data.resultCode == 0) {
                let filterData = {
                    filterText: filterText,
                    currentPage: currentPage
                }
                getSubscriptionTypes(filterData);
            }
        });
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage
        }
        getSubscriptionTypes(filterData);
    }, [filterText, currentPage]);

    return (
        <div>
            <Transition visible={isLoading} animation='scale' duration={500}>
                <div>
                    <Loader size="huge" active inline='centered' />
                </div>
            </Transition>

            <Transition visible={!isLoading} animation='scale' duration={500}>
                <div style={styles.tableSection}>
                    <Table basic='very'>
                        <Table.Header>
                            <Table.Row>
                                {tableHeader.map(header => (
                                    <Table.HeaderCell key={uuidv4()}>{header}</Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {subscriptionTypes.map((subscriptionType) => (
                                <Table.Row key={subscriptionType.id}>
                                    <Table.Cell>{subscriptionType.displayName}</Table.Cell>
                                    <Table.Cell>{DateFormat(subscriptionType.createDateTime) || "-"}</Table.Cell>
                                    <Table.Cell>
                                        <Icon style={styles.editIcon} onClick={() => { editSubscriptionTypeIcon(subscriptionType); }} name="edit" />
                                        <span>|</span>
                                        <Icon style={styles.removeIcon} onClick={() => { removeSubscriptionType(subscriptionType); }} name="trash" />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Transition>
            {subscriptionTypes.length > 0
                &&
                <div style={styles.paginationBox}>
                    {paginationHtml}
                </div>
            }
        </div>
    );
}

export default SubscriptionList;