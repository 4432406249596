import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Grid, Label, Button, Icon } from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';

import { SiteSettingDetailComponent } from '../../../components/SiteSettingComponents/SiteSettingDetailComponent';
import { SiteSettingCreateUpdateComponent } from '../../../components/SiteSettingComponents/SiteSettingCreateUpdateComponent';
import { ISiteSetting } from '../../../interfaces/interfaces';
import { toast } from 'react-toastify';
import { digitallicaConfirm } from '../../../helper/HelperFunctions';
import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from "../../../helper/TranslateHelper";

const SettingsAll = (props) => {
    var { translate } = TranslateHelper();
    let styles = {
        font20: {
            fontSize: '20px'
        },
        siteOn: {
            color: 'green',
            position: 'relative',
            top: '3px'
        },
        siteOff: {
            color: 'red',
            position: 'relative',
            top: '3px'
        },
        marginR1: {
            marginRight: '1%'
        }
    }

    const [tenantId, setTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isPageReady, setIsPageReady] = useState(false);
    const [isCreateEnabled, setIsCreateEnabled] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(false);
    const [siteSetting, setSiteSetting] = useState<ISiteSetting>();

    const getSiteSetting = () => {
        return NetworkRequest("api/SiteSetting/Get", { id: tenantId }).then((response) => {
            ////
            if (response.data.resultCode == 0) {
                setSiteSetting(response.data.detail);
                return response;
            }
        });
    }

    const removeSetting = () => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            return NetworkRequest("api/SiteSetting/DevDelete", { id: siteSetting?.siteSettingId }).then((response) => {
                ////
                if (response.data.resultCode == 0) {
                    getSiteSetting();
                    props.setLogoUrl(DataHandler.getUserDataWithKey("logoUrl"));
                    props.setSiteTitle(DataHandler.getUserDataWithKey("siteTitle"));
                    props.setFavIconUrl(DataHandler.getUserDataWithKey("favIconUrl"));
                    return response;
                }
            });
        }, () => { toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    useEffect(() => {
        getSiteSetting();
    }, []);

 
    useEffect(() => {
        //console.log(siteSetting);
    }, [siteSetting]);

 
    useEffect(() => {
        if (siteSetting != undefined && siteSetting.siteSettingId?.startsWith('00000000')) {
            props.setIsDefaultSetting(true);
            toast("Varsayılan ayarlar gösterilmektedir. Ayarları özelleştirmek için + butonuna tıklayınız.", { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 3000 });
        } else {
            props.setIsDefaultSetting(false);
        }
        setIsPageReady(true);
    }, [siteSetting]);

    return (
        <Container textAlign='justified'>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <h1>{translate("Ayarlar")}</h1>
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: "right" }}>
                        {isPageReady && !isCreateEnabled && !isEditEnabled && props.isDefaultSetting
                            &&
                            <Button className={"positiveGreenButton"} icon onClick={() => { setIsCreateEnabled(true); toast.dismiss(); }}>
                                <Icon name="plus" />
                            </Button>
                        }
                        {isPageReady && !isCreateEnabled && !isEditEnabled && !props.isDefaultSetting
                            &&
                            <Button circular className={"ingOrange"} icon onClick={() => { setIsEditEnabled(true); toast.dismiss(); }}>
                                <Icon name="edit" />
                            </Button>
                        }
                        {isPageReady && (isCreateEnabled || isEditEnabled)
                            &&
                            <Button className={"dangerRedButton"} icon onClick={() => { setIsCreateEnabled(false); setIsEditEnabled(false); toast.dismiss(); }}>
                                <Icon name="arrow left" />
                            </Button>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {isPageReady && !isCreateEnabled && !isEditEnabled
                &&
                <SiteSettingDetailComponent
                    tenantId={tenantId}
                    isDefaultSetting={props.isDefaultSetting}
                    isPageReady={isPageReady}
                    siteSetting={siteSetting}
                    getSiteSetting={getSiteSetting}
                    setIsSsSiteActive={props.setIsSsSiteActive}
                />
            }
            {isPageReady && (isCreateEnabled || isEditEnabled)
                &&
                <SiteSettingCreateUpdateComponent
                    setIsCreateEnabled={setIsCreateEnabled}
                    setIsEditEnabled={setIsEditEnabled}
                    isEditEnabled={isEditEnabled}
                    getSiteSetting={getSiteSetting}
                    siteSetting={siteSetting}
                    setLogoUrl={props.setLogoUrl}
                    setSettingTitle={props.setSiteTitle}
                    setFavIconUrl={props.setFavIconUrl}
                />
            }
        </Container>
    );
}

export default SettingsAll;