import * as React from 'react';
import {useEffect, useState} from 'react';
import {Transition, Tab, Loader, Dropdown, Select} from 'semantic-ui-react'
import ModuleReport from './ModuleReport';
import UserReport from './UserReport';
import TOUCReport from './TOUCReport';
import SystemReport from './SystemReport';
import {ISystemReport} from "../../../interfaces/interfaces";
import {useNavigate} from 'react-router-dom';
import QuizReport from "./QuizReport";
import SurveyReport from "./SurveyReport";
import LogsTimeLine from './LogsTimeLine';
import { useLocation } from 'react-router-dom';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const Reports = (props) => {
    var _ = require('lodash');
    var { translate } = TranslateHelper();
    const location = useLocation();
    const history = useNavigate();
    const [pageIndex, setPageIndex] = useState<number>(0);
    const queryParams = new URLSearchParams(window.location.search)
    const page = queryParams.get("page");
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [tenantList, setTenantList] = useState([{}]);
    const [selectedTenantId, setSelectedTenantId] = useState<string>(DataHandler.getUserDataWithKey("userRole") == "HeadAdmin" ? "1" : DataHandler.getUserDataWithKey("tenantId"));
    useEffect(() => {
        var urlPage: number = page == null ? 0 : parseInt(page);
        pushQueryString(urlPage);
    }, []);

    const panes = [
        {
            menuItem: translate('Sistem Raporu'),
            render: () => <SystemReport selectedTenantId={selectedTenantId} />
        },
        {
            menuItem: translate('Loglar'),
            render: () => <LogsTimeLine selectedTenantId={selectedTenantId} />
        },
        {
            menuItem: translate('Çalışan Raporu'),
            render: () => <UserReport selectedTenantId={selectedTenantId} />
        },
        {
            menuItem: translate('Modül Raporu'),
            render: () => <Tab.Pane key="module"><ModuleReport selectedTenantId={selectedTenantId}/></Tab.Pane>
        },
        {
            menuItem: translate('Sınav Raporu'),
            render: () => <Tab.Pane key="quiz"><QuizReport selectedTenantId={selectedTenantId}/></Tab.Pane>
        },
        {
            menuItem: translate('Anket Raporu'),
            render: () => <Tab.Pane key="survey"><SurveyReport selectedTenantId={selectedTenantId}/></Tab.Pane>
        },
        {
            menuItem: translate('KVKK Raporu'),
            render: () => <Tab.Pane key="survey"><TOUCReport selectedTenantId={selectedTenantId} /></Tab.Pane>
        }
    ]

    const pushQueryString = (value: number) => {
        setPageIndex(value);

        history({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({page: value.toString()}).toString()
        });
    }

    const styles = {
        dropdownReport: {
            minWidth: "170px",
            width: "10%"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: "1.5rem"
        },
        dropdownContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1rem",
            alignItems: "baseline"
        }
    }

    const getSubTenantList = () => {
        NetworkRequest('/api/Tenants/GetChildTenants', {id: DataHandler.getUserDataWithKey("tenantId")}).then((response) => {
            if (response.data.resultCode == 0) {
                let list = response.data.detail;
                if (list.length > 0) {
                    let arr: any = [{
                        text: translate("Tümü"),
                        key: "1",
                        value: "1"
                    }];
                    _.each(list, (doc) => {
                        arr.push({
                            text: doc.displayName,
                            key: doc.tenantId,
                            value: doc.tenantId
                        });
                    });
                    setTenantList(arr);
                }
            }
        });
    }


    useEffect(() => {
        selectMenu();
        if (userRole == "HeadAdmin") {
            getSubTenantList()
        }
    }, [])

    useEffect(() => {
        selectMenu();
    }, [location.search])

    const selectMenu = () => {
        let pathname = (location.search).slice(1).split("/")[0];
        switch (pathname) {
            case "page=0":
                setPageIndex(0)
                break;

            case "page=1":
                setPageIndex(1)
                break;

            case "page=2":
                setPageIndex(2)
                break;

            case "page=3":
                setPageIndex(3)
                break;

            case "page=4":
                setPageIndex(4)
                break;

            case "page=5":
                setPageIndex(5)
                break;
        }
    }

    const changeDropdown = (event, data) => {
        setSelectedTenantId(data.value);
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={styles.container}>
                {userRole == "HeadAdmin" &&
                    <span style={styles.dropdownContainer}>
                    <span>{translate("Şirket:")}</span> <Dropdown
                        fluid selection
                        value={selectedTenantId}
                        name="selectedTenantIds"
                        options={tenantList}
                        size="big"
                        placeholder={translate("Şirket Seçiniz")}
                        onChange={changeDropdown}
                        style={styles.dropdownReport}
                    /></span>
                }

                <Tab
                    menu={{secondary: true}}
                    panes={panes}
                    activeIndex={pageIndex}
                    onTabChange={
                        (e, data) => {
                            setPageIndex(Number(data.activeIndex));
                            pushQueryString(Number(data.activeIndex));
                        }
                    }
                />
            </div>
        </div>
    );
}

export default Reports;