import React, {useState, useEffect} from 'react';
import {Input, Icon, Modal, Button, Header, Grid, Dropdown, Form, TextArea, Loader, Image} from 'semantic-ui-react';
//import './AddWordScreen.css';
import axios from 'axios';

import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import {digitallicaConfirm, toCapitalize} from '../../../helper/HelperFunctions';
import {lang} from 'moment';
import {useNavigate, useParams} from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../../helper/DataHelper';
const AddWord = (props) => {
    const navigate = useNavigate();
    var {translate} = TranslateHelper();
    let {id} = useParams();
    let _ = require('lodash');
    const [wordId, setWordId] = useState(id);
    const [modalHeader, setModalHeader] = useState("");
    const [modalDesc, setModalDesc] = useState("");
    const [dicName, setDicName] = useState("");
    const [voice, setVoice] = useState("");
    const [word, setWord] = useState("");
    const [wordDesc, setWordDesc] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [video, setVideo] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpload, setIsUpload] = useState(false);
    const [image, setImage] = useState(null);
    const [otherLanguages, setOtherLanguages] = useState<Array<any>>([]);
    const [otherLanguageValue, setOtherLanguageValue] = useState<any>({});

    const handleInputChange = (event) => {
        let clone = _.cloneDeep(otherLanguageValue);
        clone[event.target.name] = event.target.value;
        setOtherLanguageValue(clone);
        ////console.log("handleInputChange: ", clone);
    };

    const getDictionaryInfo = () => {
        let dicData = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        };
        NetworkRequest("/api/Dictionary/GetByTenantId", dicData).then((response) => {
            ////
            if (response.data.resultCode === 0 && response.data.detail != null) {
                let dic = response.data.detail;
                setDicName(dic.name);
            }
        });
    }

    const getLanguagesByDictionaryId = () => {
        var data = {
            Id: DataHandler.getUserDataWithKey("tenantId")
        };
        NetworkRequest("/api/DictionaryLanguage/GetLanguagesByTenantId", data).then((response) => {
            setIsLoading(false);
            if (response.data.resultCode === 0) {
                setOtherLanguages(response.data.detail);
                let obj: any = {};
                _.each(response.data.detail, (doc) => {
                    obj[doc.key] = "";
                });
                setOtherLanguageValue(obj);
            } else {
                toast(translate("Beklenmedik bir hata meydana geldi. Lütfen daha sonra tekrar deneyiniz."), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 10000
                });
            }
        });
    }

    const getWord = (id) => {

        let request = {
            id: wordId
        }
        NetworkRequest("/api/DictionaryWord/GetById", request).then((response) => {
            var langReq = {
                TenantId: DataHandler.getUserDataWithKey("tenantId")
            };
            NetworkRequest("/api/DictionaryLanguage/GetLanguagesByTenantId", langReq).then((languagesResponse) => {
                if (response.data.resultCode == 0) {
                    let result = response.data.detail;
                    setAbbreviation(result.abbreviation);
                    setWord(result.name);
                    setWordDesc(result.description);
                    setImage(result.image);
                    setVoice(result.voice);
                    setImage(result.image);
                    setVideo(result.video);

                    if (languagesResponse.data.resultCode == 0) {
                        setOtherLanguages(languagesResponse.data.detail);
                        let obj: any = {};
                        _.each(languagesResponse.data.detail, (doc) => {
                            obj[doc.key] = "";
                        });
                        let json = JSON.parse(result.otherLanguage);
                        if (json != undefined) {
                            let objKeys = Object.keys(json);
                            _.each(objKeys, (doc) => {
                                obj[doc] = json[doc];
                            });
                        }
                        setOtherLanguageValue(obj);
                    }
                    setIsLoading(false);
                }
            });
        });

    }

    const insertWord = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            setIsUpload(true);

            var voiceInput: any = document.getElementById('fileVoice');
            var voice = voiceInput?.files[0];
            var formData = new FormData();
            formData.append('audioFile', voice);

            var selectedAudioName;
            var selectedImageName;


            //upload image file
            var fileInput: any = document.getElementById('file1');
            var file = fileInput.files[0];

            formData.append('imageFile', file);


            //insert keyword
            var wordSet = {
                // AudioFile: voice,
                // ImageFile: file,
                Name: word,
                Description: wordDesc,
                Abbreviation: abbreviation,
                OtherLanguage: JSON.stringify(otherLanguageValue),
                Image: null,
                Voice: null,
                Video: video,
                IsDeleted: false,
                TenantId: DataHandler.getUserDataWithKey("tenantId"),
                appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',
            };

            formData.append('wordSet', JSON.stringify(wordSet));

            ////console.log("wordSet: ", wordSet);

            NetworkRequest("/api/DictionaryWord/Insert", formData, true).then((response) => {
                ////
                if (response.data.resultCode == 0) {
                    toast(translate("Sözcük eklendi"), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                    setIsUpload(false);

                    setTimeout(() => {
                        window.location.href = "/wordList"

                    }, 1000);
                } else if (response.data.resultCode == 5) {
                    toast(translate("Lütfen zorunlu alanları doldurun"), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                } else if (response.data.resultCode == 6) {
                    toast(translate("Sözcük zaten ekli"), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
        });
    }

    const updateWord = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {

            setIsUpload(true);

            var voiceInput: any = document.getElementById('fileVoice');
            var voice = voiceInput?.files[0];
            var formData = new FormData();
            formData.append('audioFile', voice);

            var selectedAudioName;
            var selectedImageName;


            //upload image file
            var fileInput: any = document.getElementById('file1');
            var file = fileInput.files[0];

            formData.append('imageFile', file);


            //insert keyword
            var wordSet = {
                // AudioFile: voice,
                // ImageFile: file,
                Id: wordId,
                DictionaryWordId: wordId,
                Name: word,
                Description: wordDesc,
                Abbreviation: abbreviation,
                OtherLanguage: JSON.stringify(otherLanguageValue),
                Image: null,
                Voice: null,
                Video: video,
                IsDeleted: false,
                TenantId: DataHandler.getUserDataWithKey("tenantId"),
                appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',
            };

            formData.append('wordSet', JSON.stringify(wordSet));

            ////console.log("wordSet: ", wordSet);

            NetworkRequest("/api/DictionaryWord/Update", formData, true).then((response) => {
                ////
                if (response.data.resultCode == 0) {
                    toast(translate("Sözcük eklendi"), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                    setIsUpload(false);

                    setTimeout(() => {
                        /*props.history.push("/wordList");*/
                        navigate("/wordList");
                    }, 1000);
                } else if (response.data.resultCode == 5) {
                    toast(translate("Lütfen zorunlu alanları doldurun"), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                } else if (response.data.resultCode == 6) {
                    toast(translate("Sözcük zaten ekli"), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
        });
    }

    const getLanguageValue = (key) => {
        let control = otherLanguageValue[key];
        if (control != undefined) {
            return control;
        }
        return "";
    }

    useEffect(() => {
        getDictionaryInfo();
        if (wordId == undefined) {
            getLanguagesByDictionaryId();
        } else {
            getWord(wordId);
        }
    }, []);

    useEffect(() => {
        ////console.log("otherLanguageValue", otherLanguageValue);
    }, [otherLanguageValue]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>

                <Modal className={"undefinednotification-modal reset-password-modal"} size={"mini"} open={isOpen}
                       onClose={() => {
                           setIsOpen(false)
                       }}>
                    <Modal.Header><span className={"undefinedaccount-text"}>{modalHeader}</span></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{modalDesc}</Header>
                        </Modal.Description>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            setIsOpen(false)
                        }} className={"undefineddigitallica-button"} primary>
                            {translate("Tamam")} <Icon name='chevron right'/>
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Grid divided='vertically'>
                    {isLoading ? <Loader active inline='centered'/> :
                        <Grid.Row columns={1} className="column1name">
                            <Grid.Column>
                                <div style={{width: "50%"}} className={"undefinedlanguage-screen-left-section"}>
                                    <h1 className={"undefinedaccount-text generic-header"}>
                                        {wordId != undefined ? translate("Kelime Güncelle") : translate("Kelime Ekle")}

                                    </h1>
                                    <div style={{width: "100%"}} className={"undefinedadd-word-inputs-container"}>
                                        <div className={"undefineddictionary-label word-input-text"}>
                                            {translate("Kelime (Zorunlu alan)")}
                                        </div>
                                        <Input
                                            className={"greyInput"}
                                            name="word"
                                            value={word}
                                            onChange={o => {
                                                setWord(o.target.value);
                                            }}
                                            fluid={true}
                                            placeholder='Kelime'
                                            size="big"
                                        />

                                        <div style={{marginTop: "1%"}}
                                             className={"undefineddictionary-label word-input-text"}>
                                            {translate("Kelime açıklaması")}
                                        </div>
                                        <Form style={{width: "100%"}}>
                                            <TextArea className={"greyInput"} value={wordDesc} onChange={o => {
                                                setWordDesc(o.target.value);
                                            }} size="big" placeholder={translate("Kelime açıklaması")}
                                                      style={{minHeight: 100, width: "100%"}}/>
                                        </Form>


                                        <div style={{marginTop: "1%"}}
                                             className={"undefineddictionary-label word-input-text"}>
                                            {translate("Kısaltma")}
                                        </div>
                                        <Input
                                            className={"greyInput"}
                                            size="big"
                                            name="abbreviation"
                                            value={abbreviation}
                                            onChange={o => {
                                                setAbbreviation(o.target.value);
                                            }}
                                            fluid={true}
                                            placeholder={translate("Kısaltma")}

                                        />

                                        <div style={{marginTop: "1%"}}
                                             className={"undefineddictionary-label word-input-text"}>
                                            {translate("Ses dosyası")}
                                        </div>
                                        {(wordId != undefined && voice) &&
                                            <audio controls>
                                                <source src={voice} type="audio/mpeg"/>
                                            </audio>
                                        }
                                        <Input
                                            size="big"
                                            type="file"
                                            fluid
                                            className={"greyInput"}
                                            id={"fileVoice"}
                                            name={"voice"}
                                        />


                                        <div style={{marginTop: "1%"}}
                                             className={"undefineddictionary-label word-input-text"}>
                                            {translate("Görsel")}
                                        </div>

                                        {(wordId != undefined && image) &&
                                            <Image id="addWordImage" src={displayProtectedAppDoc(image,"addWordImage")} style={{ width: "200px", height: "200px", objectFit:"contain" }} />
                                        }
                                        <Input
                                            size="big"
                                            type="file"
                                            fluid
                                            className={"greyInput"}
                                            id={"file1"}
                                            name={"file"}
                                        />

                                        <div style={{marginTop: "1%"}}
                                             className={"undefineddictionary-label word-input-text"}>
                                            {translate("Video url")}
                                        </div>

                                        <Input
                                            size="big"
                                            className={"greyInput"}
                                            type="text"
                                            placeholder="Video url"
                                            fluid
                                            value={video}
                                            onChange={o => {
                                                setVideo(o.target.value);
                                            }}
                                           
                                        />

                                        {otherLanguages.map((language) => (
                                            <div style={{marginTop: "3%"}} key={language.key}>
                                                <div className="undefineddictionary-label word-input-text">
                                                    {toCapitalize(language.text)}
                                                </div>
                                                <Input
                                                    id={language.key}
                                                    size="big"
                                                    name={language.key}
                                                    value={getLanguageValue(language.key)}
                                                    onChange={e => {
                                                        handleInputChange(e)
                                                    }}
                                                    fluid={true}
                                                    placeholder={language.text}
                                                />
                                            </div>

                                        ))}
                                    </div>

                                    <div className={"undefineddictionary-button-box generic-setting-button-box"}>
                                        <Button
                                            circular={true}
                                            color="green"
                                            style={{ marginTop: "4%" }}
                                            onClick={() => {
                                                wordId == undefined ? insertWord() : updateWord()
                                            }}
                                            className={"undefineddigitallica-button"}>
                                            {isUpload ? <Loader size={"mini"} active
                                                                inline='centered'/> : wordId == undefined ? translate("Ekle") : translate("Güncelle")}

                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </div>
        </div>
    );

}

export default AddWord;