import React from 'react';
import TranslateHelper from '../../helper/TranslateHelper';
import {isMobile} from "react-device-detect";

const NotFoundPageCommon = () => {
    var { translate } = TranslateHelper();
    return (
        <div className={"container404Page"}>
            <h1>{translate("404 Hata Sayfası")}</h1>
            <p className="zoom-area"><b>{translate("Kayboldun sanırım...")}</b></p>
            <section className="error-container">
                <span><span>4</span></span>
                <span>0</span>
                <span><span>4</span></span>
            </section>
            <div className="link-container">
                <a href={"/homeLogin"} className="more-link">{translate("Anasayfa")}</a>
            </div>
        </div>
    );
};

export default NotFoundPageCommon;