import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';
import { Dropdown, Image } from 'semantic-ui-react';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';
import { NetworkRequest } from '../../helper/NetworkRequest';
import TranslateHelper from '../../helper/TranslateHelper';
import { ILanguageDropdown } from '../../interfaces/interfaces';
import { selectedLanguageContext } from '../../Store';
import './HomeHeader.css';

const HomeHeader = (props) => {

    var { translate } = TranslateHelper();
    let _ = require("lodash");
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [languageList, setLanguageList] = useState<ILanguageDropdown[]>([]);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);

    const checkClientClaim = () => {
        NetworkRequest('/api/Home/CheckClientClaim').then((response) => {
            if (response.data.resultCode == 0) {
                let lang = _.find(languageList, (doc) => {
                    return doc.key == response.data.detail.value;
                });
                if (lang != undefined) {
                    setSelectedDefaultLanguage(lang.value);
                    setSelectedLanguage(lang);
                }
            } else {
                setSelectedDefaultLanguage("tr");
            }
        });
    }

    const addOrUpdate = (event, data) => {
        let lang = _.find(languageList, (doc) => {
            return doc.value == data.value;
        });
        NetworkRequest('/api/Home/AddOrUpdateClientClaim', {
            claimType: 'Language',
            value: lang.key
        }).then((response) => {
            if (response.data.resultCode == 0) {
                setSelectedDefaultLanguage(lang.value);
                setSelectedLanguage(lang);
            }
        });
    }

    const getLanguages = () => {
        NetworkRequest("/api/Language/GetActiveLanguages").then((response) => {
            if (response.data.resultCode == 0) {
                setLanguageList(response.data.detail);
                let mappedList = _.map(response.data.detail, (l) => {
                    let languageForDropdown: ILanguageDropdown = {
                        flag: l.flag,
                        key: l.key,
                        text: l.text,
                        value: l.value
                    }
                    return languageForDropdown;
                });
                setLanguageListDropdown(mappedList);
                checkClientClaim();
            }
        });
    }

    useEffect(() => {
        if (languageList.length > 0) {
            checkClientClaim();
        }
    }, [languageList]);

    useEffect(() => {
        getLanguages();
    }, []);

    return (
        <div className="homeHeader">
            <div className="headerLogoContainer" style={{ width: "40% !important" }}>
                <Link className="homeHeaderLink" to="/home">
                    <Image id="buddyLogoBeyaz" source={displayProtectedAppDoc("/Documents/Buddy/images/BUDDY_LOGO_beyaz.png", "buddyLogoBeyaz")} className="homeBuddyLogo" />
                </Link>
            </div>
            <div className="homeHeaderLinks index4">
                {(props.page != undefined && props.page == "home") ?
                    <AnchorLink href="#scrollDown" className="activeLink" id="scroll">{translate('Neden Buddy?')}</AnchorLink>
                    : <Link to='/home#scrollDown'>{translate('Neden Buddy?')}</Link>
                }
                <Link to="/packets" className={props.page == "packets" ? "activeLink" : ""}> {translate('Paketler')}
                </Link>
                <Link to="/aboutUs" className={props.page == "aboutUs" ? "activeLink" : ""}> {translate('Hakkımızda')}
                </Link>
                <AnchorLink href='#contact'>İletişim</AnchorLink>
                <Link to="/newTenant" className={props.page == "newTenant" ? "activeLink" : ""}>{translate('Üye Ol')}
                </Link>
                <Link to="/homeLogin" className="explore" style={{
                    marginTop: "0"

                }}
                >
                    {translate('Giriş')}
                </Link>
                <Dropdown
                    className="greyInput"
                    placeholder={translate('Dil Seçiniz')}
                    clearable
                    search
                    selection
                    value={selectedDefaultLanguage}
                    options={languageListDropdown}
                    onChange={(event, data) => {
                        addOrUpdate(event, data)
                    }}
                />
            </div>
        </div>
    );
}

export default HomeHeader;