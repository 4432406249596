import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dropdown, Grid, Header, Input, Transition } from 'semantic-ui-react';
import EditorComponent from '../../../components/EditorComponent';
import QuillEditor from '../../../components/Quill/QuillEditor';
import { DataHandler } from '../../../helper/DataHelper';
import { checkFieldsIsFilled, isNullOrEmpty } from '../../../helper/HelperFunctions';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';
import { IDropdown } from '../../../interfaces/interfaces';

const AddApplicationHelper = () => {

    var { translate } = TranslateHelper();
    const navigate = useNavigate();
    let _ = require('lodash');
    let { id } = useParams();
    const [isFormOk, setIsFormOk] = useState(false);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [editorContent, setEditorContent] = useState("");
    const [selectedType, setSelectedType] = useState<any>("");
    const [appHelper, setAppHelper] = useState<any>({
        question: "",
        answer: "",
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });
    const [appHelperTypes, setAppHelperTypes] = useState<Array<any>>([]);

    const getHelperTypes = () => {
        NetworkRequest('/api/ApplicationHelperTypes/GetAllTypes', { id: tenantId }).then((response) => {
            if (response.data.resultCode == 0) {
                if (response.data.detail.length == 0) {
                    toast(translate("Önce Soru Tipi eklemelisiniz."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                }
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (doc) => {
                    list.push({
                        key: doc.id,
                        value: doc.typeName,
                        text: translate(doc.typeName)
                    });
                });
                setAppHelperTypes(list);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    const add = () => {
        let control = _.find(appHelperTypes, (doc) => {
            return doc.value == selectedType;
        });
        if (control != undefined) {
            appHelper.applicationHelperTypeId = control.key;
        }
        appHelper.answer = editorContent;
        NetworkRequest('/api/ApplicationHelpers/AddAppHelper', appHelper, false, undefined, true).then((response) => {
            if (response.data.resultCode == 0) {
                toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setTimeout(() => {
                    navigate("/applicationHelpers");
                }, 1000);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    const update = () => {
        let control = _.find(appHelperTypes, (doc) => {
            return doc.value == selectedType;
        });
        if (control != undefined) {
            appHelper.applicationHelperTypeId = control.key;
        }
        appHelper.answer = editorContent;
        NetworkRequest('/api/ApplicationHelpers/UpdateAppHelper', appHelper, false, undefined, true).then((response) => {
            if (response.data.resultCode == 0) {
                toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setTimeout(() => {
                    navigate("/applicationHelpers");
                }, 1000);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    const getAppHelper = () => {
        NetworkRequest('/api/ApplicationHelpers/GetAppHelper', { id: id }).then((response) => {
            if (response.data.resultCode == 0) {
                setEditorContent(response.data.detail.answer);
                setAppHelper(response.data.detail);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    useEffect(() => {
        getHelperTypes();
    }, [tenantId]);

    useEffect(() => {
        if (!isNullOrEmpty(appHelper.applicationHelperTypeId) && selectedType == "" && appHelperTypes.length > 0) {
            let type = _.find(appHelperTypes, (doc) => {
                return doc.key == appHelper.applicationHelperTypeId;
            });
            if (type != undefined) {
                setTimeout(() => {
                    setSelectedType(type.value);
                }, 500);
            }
        }
    }, [appHelper.applicationHelperTypeId]);

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                appHelper.question,
                editorContent,
                selectedType
            )
        );
    }, [appHelper.question, editorContent, selectedType]);

    useEffect(() => {
        if (id != undefined) {
            getAppHelper();
        }
    }, []);

    return (
        <div className="generalContainerForWhiteBg">
            <div className="addAppHelperCont">
                <Transition visible={true} animation='scale' duration={500}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Yardımcı Tipi: (*)")}</Header>
                                    <Dropdown
                                        className="greyInput"
                                        fluid
                                        selection
                                        value={selectedType}
                                        name="helperType"
                                        required="required"
                                        clearable
                                        options={appHelperTypes}
                                        placeholder={translate("Yardımcı Tipi")}
                                        onChange={(e, data) => { setSelectedType(data.value); }}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Soru: (*)")}</Header>
                                    <Input
                                        value={appHelper?.question}
                                        name="title"
                                        required="required"
                                        className="greyInput width100"
                                        placeholder={translate("Bildirim Adı")}
                                        onChange={(e) => { setAppHelper({ ...appHelper, question: e.target.value }); }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Cevap: (*)")}</Header>

                                    <QuillEditor
                                        value={editorContent}
                                        setValue={setEditorContent}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    {!isNullOrEmpty(appHelper.id)
                                        &&
                                        <Button
                                            color="blue"
                                            className="roundedButton"
                                            onClick={update}
                                            disabled={!isFormOk}
                                            style={{ float: 'right' }}
                                        >
                                            {translate("Güncelle")}
                                        </Button>
                                    }
                                    {isNullOrEmpty(appHelper.id)
                                        &&
                                        <Button
                                            className="roundedButton"
                                            color='green'
                                            onClick={add}
                                            disabled={!isFormOk}
                                            style={{ float: 'right' }}
                                        >
                                            {translate("Ekle")}
                                        </Button>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Transition>
            </div>
        </div>
    )
}

export default AddApplicationHelper;