import * as React from 'react';
import { useState, useEffect } from 'react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    Loader,
    Popup,
    Table,
    TextArea,
    Transition
} from 'semantic-ui-react';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../../helper/HelperFunctions';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Activity {
    id?: string,
    title?: string,
    description?: string,
    createdDate?: Date
}

interface Dropdown {
    key?: string;
    value?: string;
    text?: string;
}

const ActivityAdd = (props) => {
    var { translate } = TranslateHelper();
    let { id } = useParams();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [loader, setLoader] = useState(true);
    const [activityType, setActivityType] = useState("");
    const [activityId] = useState<any>(id);
    const [activity, setActivity] = useState({
        id: null,
        title: "",
        description: "",
        isActive: true,
        startDate: null,
        endDate: null,
        locationName: "",
        mapHtml: "",
        teacherName: "",
        isOnlineActivity: false,
        onlineActivityLink: "",
        isDeleted: null,
        deletedDate: null,
        updatedDate: null

    });

    let styles = {
        activityAddSection: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        },
        inputsSection: {
            display: "flex",
            width: "100%",
            padding: "20px",
            flexDirection: "row" as "row",
            gap: "3em",
            justifyContent: "space-between",
            alignItems: "unset"
        },

        inputSectionBox: {
            display: "flex",
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row" as "row",
            width: "100%"
        },

        inputStyle: {
            width: "100%",
        },
        inputStyleLink: {
            width: "100%",
            marginLeft: "-35%"
        },

        inputContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "2em",
            justifyContent: "space-around"
        },
        formContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "space-around"
        },
        labelContainer: {
            width: "15%",
            fontWeight: "bold"
        },
        inputSection: {
            width: "100%"
        }
    }

    const checkActivityId = (activityId: string) => {
        activityId !== undefined ? getByActivityId(activityId) : setLoader(false);
    }

    const getByActivityId = (activityId) => {
        let activityData = {
            id: activityId
        }

        NetworkRequest("api/Activities/GetById", activityData, true).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setActivity({
                    id: result.id,
                    title: result.title,
                    description: result.description,
                    endDate: result.endDate,
                    isActive: result.isActive,
                    isOnlineActivity: result.isOnlineActivity,
                    locationName: result.locationName,
                    mapHtml: result.mapHtml,
                    onlineActivityLink: result.onlineActivityLink,
                    startDate: result.startDate,
                    teacherName: result.teacherName,
                    deletedDate: result.deletedDate,
                    isDeleted: result.isDeleted,
                    updatedDate: result.updatedDate
                });
                setLoader(false);
            }
        },
            error => {
                ////console.log(error);
            }
        );
    }

    const addActivity = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let activityData = {
                title: activity.title,
                description: activity.description,
                endDate: activity.endDate,
                isActive: activity.isActive,
                isOnlineActivity: activity.isOnlineActivity,
                locationName: activity.locationName,
                mapHtml: activity.mapHtml,
                onlineActivityLink: activity.onlineActivityLink,
                startDate: activity.startDate,
                teacherName: activity.teacherName,
                tenantId: tenantId
            };

            NetworkRequest("api/Activities/Add", activityData, undefined, undefined, true).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    window.location.href = "/activities";
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    };

    const updateActivity = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let activityData = {
                id: activity.id,
                title: activity.title,
                description: activity.description,
                endDate: activity.endDate,
                isActive: activity.isActive,
                isOnlineActivity: activity.isOnlineActivity,
                locationName: activity.locationName,
                mapHtml: activity.mapHtml,
                onlineActivityLink: activity.onlineActivityLink,
                startDate: activity.startDate,
                teacherName: activity.teacherName,
                deletedDate: activity.deletedDate,
                isDeleted: activity.isDeleted,
                updatedDate: activity.updatedDate,
                tenantId: tenantId
            };

            NetworkRequest("api/Activities/Update", activityData, undefined, undefined, true).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    window.location.href = "/activities";
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const handleChange = (e) => {
        setActivity({
            ...activity,
            [e.target.name]: e.target.value
        });
    }

    const changeDropdown = (event, data) => {
        setActivity({
            ...activity,
            [data.name]: data.value
        });
    }

    useEffect(() => {
        checkActivityId(activityId);
    }, [activityId]);

    const [isFormOk, setIsFormOk] = useState(false);
    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                activity.title,
                activity.startDate,
                activity.endDate
            )
        );
        if (activity.startDate != null && activity.endDate != null) {
            if (activity.startDate > activity.endDate) {
                toast(translate("Başlangıç tarihi bitiş tarihinden büyük olamaz"), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 4500
                });

            }

        }


    }, [activity]);
    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={styles.activityAddSection}>
                <Transition visible={loader} animation='scale' duration={500}>
                    <div>
                        <Loader size="huge" active inline='centered' />
                    </div>
                </Transition>

                <Transition visible={!loader} animation='scale' duration={500}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <div style={styles.formContainer}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "50%",
                                    flexDirection: "row" as "row",
                                    gap: "unset",

                                }}>
                                <div style={styles.inputContainer}>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>

                                            <span>{translate("Adı: (*)")}</span>

                                        </div>
                                        <div style={styles.inputSection}>
                                            <Input
                                                className={"greyInput"}
                                                value={activity.title}
                                                name="title"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("Adı")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <span>{translate("Açıklama:")}</span>
                                        </div>
                                        <div style={styles.inputSection}>
                                            <Form style={{ width: "100%" }}>
                                                <TextArea
                                                    className={"greyInput"}
                                                    rows={5}
                                                    placeholder=''
                                                    style={styles.inputStyle}
                                                    value={activity.description}
                                                    onChange={(e) => {
                                                        setActivity({ ...activity, description: e.target.value })
                                                    }}
                                                />
                                            </Form>
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>

                                            <span>{translate("Lokasyon Adı")}</span>
                                        </div>
                                        <div style={styles.inputSection}>
                                            <Input
                                                value={activity.locationName}
                                                name="locationName"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("Adı")}
                                                onChange={handleChange}
                                                className={"greyInput"}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <span>{translate("Eğitmen Adı")}</span>
                                        </div>
                                        <div style={styles.inputSection}>
                                            <Input
                                                value={activity.teacherName}
                                                name="teacherName"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("Adı")}
                                                onChange={handleChange}
                                                className={"greyInput"}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>

                                            <span>{translate("Etkinlik Lokasyonu")}</span>
                                        </div>
                                        <div style={styles.inputSection}>
                                            <Input
                                                value={activity.mapHtml}
                                                name="mapHtml"
                                                style={styles.inputStyle}
                                                placeholder={translate("Etkinlik Lokasyonu")}
                                                onChange={handleChange}
                                                className={"greyInput"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <div style={{
                                    ...styles.inputContainer,
                                    height: "100%",
                                    justifyContent: "",
                                    position: "relative"
                                }}>
                                    <div style={styles.inputSectionBox}>
                                        <div style={{ width: "50%" }}>
                                            <span style={{ marginLeft: "30%" }}>{translate("Başlangıç Tarihi (*)")}</span>
                                        </div>
                                        <div style={styles.inputSection}>
                                            <Input
                                                value={activity.startDate}
                                                name="startDate"
                                                required="required"
                                                type="datetime-local"
                                                placeholder={translate("Başlangıç Tarihi")}
                                                onChange={handleChange}
                                                className={"greyInput"}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.inputSectionBox}>
                                        <div style={{ width: "50%" }}>
                                            <span style={{ marginLeft: "30%" }}>{translate("Bitiş Tarihi (*)")}</span>
                                        </div>
                                        <div style={styles.inputSection}>
                                            <Input
                                                timezone="[[timezone]]"
                                                value={activity.endDate}
                                                name="endDate"
                                                required="required"
                                                type="datetime-local"
                                                placeholder={translate("Bitiş Tarihi")}
                                                onChange={handleChange}
                                                className={"greyInput"}
                                            />
                                        </div>
                                    </div>
                                    {/*<div style={{ display: "flex", alignItems: "flex-start", flexDirection:"column" as "column" }}>*/}
                                    {/*    <div style={{ display: "flex", alignItems: "center", width: "50%" }}>*/}
                                    {/*        <Header as='h3' style={{ marginLeft: "30%" }}>{translate("Sanal Etkinlik")}</Header>*/}
                                    {/*        <Checkbox toggle*/}
                                    {/*            checked={activity.isOnlineActivity}*/}
                                    {/*            onChange={(e, data) => {*/}
                                    {/*                setActivity({*/}
                                    {/*                    ...activity,*/}
                                    {/*                    isOnlineActivity: data.checked != undefined ? data.checked : false*/}
                                    {/*                })*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}

                                    {/*    <div>*/}
                                    {/*        <Input*/}
                                    {/*            value={activity.onlineActivityLink}*/}
                                    {/*            name="onlineActivityLink"*/}
                                    {/*            required="required"*/}
                                    {/*            size="big"*/}
                                    {/*            disabled={!activity.isOnlineActivity}*/}
                                    {/*            placeholder='Link'*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            className={"greyInput"}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}

                                    <div style={{ display: "flex", gap: "1em", flexDirection: "column" as "column", justifyContent: "flex-start", alignItems: "flex-start", width: "100%" }}>
                                        <div style={styles.inputSectionBox}>
                                            <span style={{ marginLeft: "10%" }}>{translate("Sanal Etkinlik")}</span>
                                            <Checkbox toggle
                                                checked={activity.isOnlineActivity}
                                                onChange={(e, data) => {
                                                    setActivity({
                                                        ...activity,
                                                        isOnlineActivity: data.checked != undefined ? data.checked : false
                                                    })
                                                }}
                                            />
                                        </div>

                                        {activity.isOnlineActivity &&
                                            <div style={{ marginLeft: "9.5%", width: "65%" }}>
                                                <Input
                                                    style={{ width: "100%" }}
                                                    value={activity.onlineActivityLink}
                                                    name="onlineActivityLink"
                                                    required="required"
                                                    disabled={!activity.isOnlineActivity}
                                                    placeholder='Link'
                                                    onChange={handleChange}
                                                    className={"greyInput"}
                                                />
                                            </div>
                                        }
                                    </div>







                                    <div style={styles.inputSectionBox}>
                                        <span style={{ marginLeft: "10%" }}>{translate("Aktif")}</span>
                                        <Checkbox toggle
                                            checked={activity.isActive}
                                            onChange={(e, data) => {
                                                setActivity({
                                                    ...activity,
                                                    isActive: data.checked != undefined ? data.checked : false
                                                })
                                            }}

                                        />
                                    </div>
                                    <div style={{ position: "absolute", bottom: "0", right: "0" }}>
                                        {activityId !== undefined ?

                                            <Button
                                                size="big"
                                                color="green"
                                                onClick={updateActivity}
                                                disabled={!isFormOk}
                                                className={"roundedButton"}
                                            >
                                                {translate("Etkinlik Güncelle")}
                                            </Button>

                                            :

                                            <Button
                                                circular
                                                color="blue"
                                                onClick={addActivity}
                                                disabled={!isFormOk}
                                            >
                                                {translate("Etkinlik Ekle")}
                                            </Button>

                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default ActivityAdd;