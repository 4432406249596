import * as React from 'react';
import { useState, useEffect } from 'react';
import userPlaceholderImg from '../../../images/user.png';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import {
    Header,
    Input,
    Dropdown,
    Checkbox,
    Button,
    Form,
    Select
} from 'semantic-ui-react'

import IQuestion from '../../../interfaces/IQuestion';
import { checkFieldsIsFilled, digitallicaConfirm, extractAllElements, getQuestionContentForRender } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import EditorComponent from '../../../components/EditorComponent';
import { useNavigate, useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
import QuillEditor from '../../../components/Quill/QuillEditor';

const QuestionEdit = (props) => {
    const navigate = useNavigate();
    var {translate} = TranslateHelper();
    let { id } = useParams();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [question, setQuestion] = useState<IQuestion>({
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        CorrectAnswer: ""
    });
    //const [editorContent, setEditorContent] = useState("");
    const [editorContent, setEditorContent] = useState({
        embedCode: "",
        embedValue: ""
    });
    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [a, setA] = useState("");
    const [b, setB] = useState("");
    const [c, setC] = useState("");
    const [d, setD] = useState("");
    const [e, setE] = useState("");
    const [options, setOptions] = useState(["A", "B", "C", "D", "E"]);
    const [checkedOption, setCheckedOption] = useState("");
    const [isFormOk, setIsFormOk] = useState(false);

    let styles = {
        questionAddContainer: {
            width: "60%",
            margin: "0 auto",
            marginTop: "3%",
            height: "auto",
            padding:"3%"
        },
        optionsContainer: {
            display: "flex",
            gap: "1em",
            flexDirection: "column" as "column",
            marginTop: "20px",
            marginBottom: "20px"
        },
        option: {
            display: "flex",
            width: "100%",
            gap: "2em",
            alignItems: "center",
            justifyContent: "center"
        },
        input: {
            width: "100%"
        }
    }

    const handleChangeOption = (e) => {
        //debugger;
        switch (e.target.name) {
            case "A":
                setA(e.target.value)
                break;
            case "B":
                setB(e.target.value)
                break;
            case "C":
                setC(e.target.value)
                break;
            case "D":
                setD(e.target.value)
                break;
            case "E":
                setE(e.target.value)
                break;
            default:
        }
        setQuestion({
            Body: editorContent,
            A: a,
            B: b,
            C: c,
            D: d,
            E: e,
            CorrectAnswer: question.CorrectAnswer
        });
    }

    const correctOptionChange = (e, { value }): void => {
        setCheckedOption(value);
        question.CorrectAnswer = value;
        setQuestion(question);
    }

    const saveQuestion = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
        let questionData = {
            Id: id,
            questionJSON: JSON.stringify({
                A: a,
                B: b,
                C: c,
                D: d,
                E: e,
                Body: editorContent,
                CorrectAnswer: checkedOption
            }),
            tenantId: tenantId
        }
            NetworkRequest("/Question/Update", questionData).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    toast(translate("Soru başarılı bir şekilde kaydedildi"), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                    navigate("/questions");

                } else {
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const getQuestionById = (questionId: any) => {
        let questionData = {
            id: questionId
        }

        NetworkRequest("/Question/GetById", questionData).then((response) => {
            ////
            if (response.data.resultCode == 0) {
                let question = JSON.parse(response.data.detail.questionJSONString);
                ////console.log(question)
              /*  setEditorContent(question.Body);*/
                setEditorContent({ embedCode: getQuestionContentForRender(question.Body), embedValue: extractAllElements(getQuestionContentForRender(question.Body)) });

                setA(question.A);
                setB(question.B);
                setC(question.C);
                setD(question.D);
                setE(question.E);
                setCheckedOption(question.CorrectAnswer);
            }
        });
    }

    const handleEditorChange = (data) => {
        setEditorContent(data);
    }

    const setSelection = (data, data2, data3) => {
        if (data != undefined) {
            setEditorContentIndex(data.index);
        }
    }

    //onLoad page function
    useEffect(() => {
        getQuestionById(id);
    }, []);

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                editorContent
            )
        );
    }, [editorContent]);

    return (
        <div style={styles.questionAddContainer} className={"generalContainerForWhiteBg"}>


            <QuillEditor

                value={getQuestionContentForRender(editorContent)}
                setValue={(value) => { setEditorContent({ embedCode: value, embedValue: extractAllElements(value) }) }}
            />

            <div style={styles.optionsContainer}>

                {options.map(option => (
                    <div
                        key={option}
                        style={styles.option}
                    >

                        <Checkbox
                            name='checkboxRadioGroup'
                            toggle
                            value={option}
                            checked={option == checkedOption}
                            onChange={(e, { value }) => correctOptionChange(e, { value })}
                        />

                        <Input
                            value={option == "A" ? a : option == "B" ? b : option == "C" ? c : option == "D" ? d : option == "E" && e}
                            style={styles.input}
                            name={option}
                            fluid
                            size="big"
                            label={{ basic: true, content: option }}
                            labelPosition='left'
                            placeholder={option}
                            onChange={handleChangeOption}
                        />

                    </div>
                ))}
            </div>
            <div style={{ float: 'right' }}>
                <Button color="green" circular={ true} disabled={!isFormOk} onClick={saveQuestion}>
                    {translate("Kaydet")}
                </Button>
                </div>

        </div>
    );
}

export default QuestionEdit;