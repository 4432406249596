import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SurveyQuestionAttachToSurvey from '../../../components/SurveyQuestionAttachToSurvey';

const SurveyQuestionAndSurvey = (props) => {

    let { surveyId } = useParams();
    const [surveyIdHook] = useState(surveyId);

    useEffect(() => {
        ////console.log("SurveyQuestionAndSurvey surveyId: ", surveyId);
    }, [surveyIdHook]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <SurveyQuestionAttachToSurvey id={surveyIdHook} />
        </div>
    );
}

export default SurveyQuestionAndSurvey;

