
import { useState, useEffect } from 'react';
import { Input, Button, Icon, Header, Transition, Loader } from 'semantic-ui-react';
import Message, { MessageTypes } from '../../components/Message';
import { isMobile, isTablet } from 'react-device-detect';
import { NetworkRequest } from '../../helper/NetworkRequest';
import { media, style } from 'typestyle';
import { DataHandler } from '../../helper/DataHelper';
import { Link } from 'react-router-dom';
import TranslateHelper from '../../helper/TranslateHelper';
import Logo from '../../images/ING_Primary_Logo_RGB.png';

const LdapLogin = (props) => {

    var { translateFromEng } = TranslateHelper();

    let styles = {
        loginBoxStyle: {
            width: isTablet ? "50%" : isMobile ? "90%" : "20%",
            backgroundColor: "#ffffffc4",
            backdropFilter: "blur(40px)",
            height: "fit-content",
            borderRadius: "10px",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            display: "flex",
            flexDirection: "column" as "column",
            margin: "0 auto",
            marginTop: isMobile ? "10px" : "50px",
            padding: isMobile ? "15px" : "30px",
            gap: "1em",
            position: "sticky" as "sticky",
            top: "1rem",
            minWidth: "300px"
        },
        inputElementBoxStyle: {
            width: "100%",
            marginBottom: ".25rem",
            marginTop: "1em"
        },
        loginButtonBoxStyle: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column",
            justifyContent: "flex-end",
            alignItems: "normal",
            marginTop: "15%",
            gap: "1rem",
        },
        ldapLoginHeaderStyle: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        ldapLoginHeader: {
            fontSize: "1.6em",
            fontWeight: "bold",
            marginTop: "15%",
            marginBottom: "10%"
        },
        loginContainer: style({
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1rem",
            justifyContent: "center",
            fontFamily: "roboto!important",
            paddingBottom: "2rem",
             backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
            media({ maxWidth: 820 }, { flexDirection: "column", height: "100vh", backgroundSize: "cover", })
        )
    }

    const [user, setUser] = useState<any>({
        email: "",
        userName: "",
        tenantName: "ING"
    });
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<any>({
        Header: "",
        Description: "",
        MessageType: MessageTypes.Error
    });



    const redirectByUserRole = (userRole: string, isActive: boolean) => {
        if (userRole == "User" && !isActive) {
            window.location.href = "/siteOff";
            return;
        }
        window.location.href = "/";
    }

    const showError = (message) => {
        setMessage({
            ...message,
            Header: "Error",
            Description: message,
            MessageType: MessageTypes.Error
        });
        setIsError(true);
    }

    const ldapLogin = () => {
        setIsLoading(true);
        setIsError(false);
        let userData = {
            UserName: user.userName,
            Password: user.password,
            tenantName: user.tenantName
        }
        NetworkRequest("/api/Ldap/Login", userData).then(response => {

            if (response.data.resultCode === 0) {
                let userData = response?.data?.detail;
                if (userData != null) {
                    DataHandler.removePages();
                    DataHandler.pushLoginData(userData);
                    redirectByUserRole(userData.userRole[0], userData.isActive);
                }
                else {
                    showError(response?.data?.detail);
                }


            } else {
                if (response?.data?.detail?.response?.data != null) {
                    showError(response?.data?.detail?.response?.data);
                } else {
                    showError(response?.data?.detail);
                }

            }
            setIsLoading(false);
        });
    };

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }


    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            ldapLogin();
        }
    }



    return (
        <div className={styles.loginContainer}>
            <div style={styles.loginBoxStyle}>



                <div>
                    <div style={styles.ldapLoginHeaderStyle}>
                        <Link to={"/HomeLogin"}><Icon name="arrow left" />{translateFromEng("Back")}</Link>
                        <img width="30%" src={Logo} />
                    </div>


                    <div>
                        <div style={styles.ldapLoginHeader}>{translateFromEng("LDAP Login")}</div>
                    </div>




                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className="greyInput"
                            size="big"
                            fluid
                            icon='building'
                            iconPosition='left'
                            placeholder={translateFromEng("Company Name")}
                            value={user.tenantName}
                            disabled={true}
                            onChange={handleChange}
                            name="tenantName"
                            onKeyUp={onKeyUp}
                        />
                    </div>

                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className="greyInput"
                            size="big"
                            fluid
                            icon='user'
                            iconPosition='left'
                            placeholder={translateFromEng("Username")}
                            value={user.userName}
                            onChange={handleChange}
                            name="userName"
                            onKeyUp={onKeyUp}
                        />
                    </div>

                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className="greyInput"
                            type="password"
                            size="big"
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder={translateFromEng("Password")}
                            value={user.password}
                            onChange={handleChange}
                            name="password"
                            onKeyUp={onKeyUp}
                        />
                    </div>

                    <div style={styles.loginButtonBoxStyle}>

                        <Button
                            style={{ backgroundColor:"#ff6200"} }
                            disabled={isLoading}
                            loading={isLoading}
                            circular={true}
                            color={"pink"}
                            icon
                            size="big"
                            labelPosition='right'
                            onClick={ldapLogin}
                        >
                            {translateFromEng("Login")}
                            <Icon
                                name={"arrow right"}
                            />
                        </Button>
                    </div>
                </div>


                <Transition visible={isError} animation='scale' duration={500}>
                    <div>
                        <Message
                            Header={message.Header}
                            Description={message.Description}
                            MessageType={message.MessageType}
                        />
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default LdapLogin;
