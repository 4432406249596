import React, {useEffect, useState, memo} from 'react';
import {IMenuItem, IMenuItems} from "../interfaces/interfaces";
import LeftNavbarItem from "./LeftNavbarItem";
import {useLocation} from 'react-router-dom';

function LeftNavbarItems(props: IMenuItems) {
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState("");
    const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

    const styles = {
        navItems: {
            display: "flex",
            flexDirection: "column" as "column",
        }
    }

    useEffect(() => {
        //console.log("selected item", selectedItem);
        props.SetCurrentSetMenu(selectedItem)
    }, [selectedItem])

    useEffect(() => {
        let pathname = (location.pathname).slice(1).split("/")[0];

        pathname =
            pathname == "" ? "dashboard"
                // : pathname == "setting" ? "settings"
                    : pathname == "questions" ? "quizes"
                        : pathname == "dictionary" ? "dictionaryandfaq"
                            : pathname == "addDictionary" ? "dictionaryandfaq"
                                : pathname == "applicationHelpers" ? "dictionaryandfaq"
                                    : pathname == "applicationHelperTypes" ? "dictionaryandfaq"
                                        : pathname == "inquiryContentList" ? "surveyList"
                                            : pathname == "moduleEdit" ? "modules"
                                                : pathname == "userAdd" ? "users"
                                                    : (pathname == "qizQestionReport"
                                                        || pathname == "examReportDetail"
                                                        || pathname == "srvyPersonDetailReport") ? "reports" : pathname;
        setSelectedItem(pathname);
    }, [location]);

    const items = props.MenuItems.map((item) => (
        <LeftNavbarItem
            Id={item.Id}
            key={item.Text}
            Open={props.Open}
            Icon={item.Icon}
            Text={item.Text}
            Link={item.Link}
            SelectedItem={selectedItem}
            SetSelectedItem={setSelectedItem}
            Title={item.Title}
            IsHaveSubCategory={item.IsHaveSubCategory}
            SetIsOpenSubPanel={props.SetIsOpenSubPanel}
            BgColor={props.BgColor}
            MenuItemTextColor={props.MenuItemTextColor}
            MenuItemTextColorHover={props.MenuItemTextColorHover}
            MenuItemIconColor={props.MenuItemIconColor}
            MenuItemIconColorHover={props.MenuItemIconColorHover}
            MenuItemMarkerColor={props.MenuItemMarkerColor}
            MenuItemBgColor={props.MenuItemBgColor}
            MenuItemBgColorHover={props.MenuItemBgColorHover}
        />
    ));

    return (
        <div style={styles.navItems}>
            {items}
        </div>
    );
}

export default memo(LeftNavbarItems);