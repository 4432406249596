import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Button, Header } from 'semantic-ui-react';

import { NetworkRequest } from '../../helper/NetworkRequest';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import parse from "html-react-parser";
import WelcomeBanner from './WelcomeBanner';
import moment from 'moment'
import FileInputComp from "../AdminDashboard/components/FileInputComp";
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../helper/DataHelper';
import TranslateHelper from '../../helper/TranslateHelper';

const WelcomeBant = (props) => {

    var { translate } = TranslateHelper();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));

    const [image, setImage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [photoUrl, setPhotoUrl] = useState("");
    const [welcomeBantImg, setWelcomeBantImg] = useState<any>("");


    const [welcomeBant, setWelcomeBant] = useState<any>({
        welcomeBannerColorCode: "#FF4390",
        welcomeBannerImg: "/Documents/Buddy/images/party_face.png",
        welcomeTextColor: "#000"

    });

    const styles = {
        inputSectionBox: {
            display: "flex",
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row" as "row",
            width: "100%"
        },
        label: {
            width: "15%",
            minWidth: "150px"
        },
        btnDark: style({
            width: "228px",
            right: "0px",
            top: "700px",
            borderRadius: "8px",
            padding: "10px 0px !important"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
    }

    const save = () => {
        setIsLoading(true);
        var url = "api/SiteSetting/WelcomeBannerSettings";
        var req = {
            appType: "Test",
            welcomeBannerColorCode: welcomeBant.welcomeBannerColorCode,
            welcomeTextColor: welcomeBant.welcomeTextColor,
            tenantId: tenantId
        };
        req.appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';
        var formData = new FormData();
        formData.append('File', photoUrl);
        formData.append('Request', JSON.stringify(req));
        //debugger;
        NetworkRequest(url, formData, true).then((response) => {
            var result = response.data.detail;
            if (response.data.resultCode == 0) {
                toast(translate(response.data.message), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });


                getSiteSetting();


                if (result?.welcomeBannerImg != null) {
                    setWelcomeBant({ ...welcomeBant, wecomeBannerImg: displayProtectedAppDoc(result.welcomeBannerImg) });
                }
                else {
                    setWelcomeBant({ ...welcomeBant, wecomeBannerImg: displayProtectedAppDoc(welcomeBant.welcomeBannerImg) });
                }
            } else {
                toast(translate(response.data.message), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }

            setIsLoading(false);
        });
    }

    const getSiteSetting = () => {
        return NetworkRequest("api/SiteSetting/Get", { id: tenantId }).then((response) => {
            ////
            if (response.data.resultCode == 0) {
                var result = response.data.detail;
                let model = {
                    welcomeBannerColorCode: result.welcomeBannerColorCode,
                    wecomeBannerImg: result.wecomeBannerImg,
                    welcomeTextColor: result.welcomeTextColor
                };
                setWelcomeBant(model);

            }
        });
    }


    useEffect(() => {
        getSiteSetting();
    }, []);

    const handleChangeImage = (e) => {
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPhotoUrl(e.target.files[0]);
        setImage(objectUrl);
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: "2vw" }}>
                {!isLoading &&
                    <WelcomeBanner
                        user={{
                            workStartDate: moment().add(452000000), name: "Örnek Çalışan"
                        }}
                        image={welcomeBant.wecomeBannerImg}
                        bgColor={welcomeBant.welcomeBannerColorCode}
                        textColor={welcomeBant.welcomeTextColor}
                    />
                }
                <div style={styles.inputSectionBox}>
                    <div style={styles.label}>
                        <Header as='h3'>{translate("Arkaplan Rengi: ")}</Header>
                    </div>
                    <input
                        className={"greyInput"}
                        type="color"
                        placeholder="Bant Rengi"
                        style={{ width: "10%" }}
                        value={welcomeBant.welcomeBannerColorCode || ""}
                        onChange={e => setWelcomeBant({ ...welcomeBant, welcomeBannerColorCode: e.target.value })} />
                </div>
                <div style={styles.inputSectionBox}>
                    <div style={styles.label}>
                        <Header as='h3'>{translate("Metin Rengi:")} </Header>
                    </div>
                    <input type="color"
                        className={"greyInput"}
                        placeholder={translate("Metin Rengi")}
                        style={{ width: "10%" }}
                        value={welcomeBant.welcomeTextColor || ""}
                        onChange={e => setWelcomeBant({ ...welcomeBant, welcomeTextColor: e.target.value })} />
                </div>
                <div style={styles.inputSectionBox}>
                    <div style={styles.label}>
                        <Header as='h3'>{translate("Emoji Yükle:")} </Header>
                    </div>

                    <FileInputComp
                        setPhotoUrl={setPhotoUrl}
                        setImage={setImage}
                        type={"image"}
                    />
                </div>
                <div>
                    <Button className={"roundedButton"} color='blue' onClick={save}> {translate("Kaydet")}
                    </Button>
                </div>
            </div>
        </>
    )
};

export default WelcomeBant;