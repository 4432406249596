import * as React from 'react';
import { Grid, Label, Button, Icon, GridRow, Popup, Image } from 'semantic-ui-react';
import { ISiteSettingDetailComp } from '../../interfaces/interfaces';
import { NetworkRequest } from '../../helper/NetworkRequest';

import { toast } from 'react-toastify';
import { digitallicaConfirm } from '../../helper/HelperFunctions';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../helper/DataHelper';
import TranslateHelper from "../../helper/TranslateHelper";

export const SiteSettingDetailComponent = (props: ISiteSettingDetailComp) => {
    var { translate } = TranslateHelper();
    let styles = {
        font20: {
            fontSize: '20px'
        },
        siteOn: {
            color: 'green',
            position: 'relative',
            top: '3px'
        },
        siteOff: {
            color: 'red',
            position: 'relative',
            top: '3px'
        },
        marginR1: {
            marginRight: '1%'
        },
        siteLogo: {
            maxWidth: '150px',
            maxHeight: '100px',
            left: '37px',
            top: '43px !important'
        },
        siteIkon: {
            maxWidth: '150px',
            maxHeight: '100px',
            left: '37px'
        },
        loginPhoto: {
            maxWidth: '250',
            maxHeight: '250px',
            left: '37px'
        }
    }

    const requester = (model) => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            return NetworkRequest('api/SiteSetting/RunOrStop', model, true).then((response) => {
                ////
                toast.dismiss();
                props.getSiteSetting();
                return response;
            });
        }, () => { toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const stopSite = () => {
        toast("Uygulama kapatılıyor, lütfen bekleyin.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2000 });
        window.setTimeout(() => {
            DataHandler.setDataWithKey('isActive', false );
            props.setIsSsSiteActive(false);
            requester({ SiteSettingId: props.siteSetting?.siteSettingId, IsActive: false });
        }, 1500);
    }

    const runSite = () => {
        toast("Uygulama açılıyor, lütfen bekleyin.", { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
        window.setTimeout(() => {
            DataHandler.setDataWithKey('isActive', true);
            props.setIsSsSiteActive(true);
            requester({ SiteSettingId: props.siteSetting?.siteSettingId, IsActive: true });
        }, 1500);
    }

    return (
        <>
            {props.isPageReady
                &&
                <Grid columns={1}>
                    {props.isDefaultSetting
                        &&
                        <p>
                            {translate("Varsayılan Ayarlar")}
                            
                        </p>
                    }
                    <Grid.Row>
                        <Grid.Column>
                            <Label style={styles.marginR1}>{translate("Site Başlığı")}</Label>
                            <span>{props.siteSetting?.settingTitle}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label style={styles.marginR1}>{translate("Site Açık Mı?")}</Label>
                            {props.siteSetting?.isActive
                                &&
                                <Icon name="circle" style={Object.assign({}, styles.siteOn, styles.font20)} />
                            }
                            {!props.siteSetting?.isActive
                                &&
                                <Icon name="circle" style={Object.assign({}, styles.siteOff, styles.font20)} />
                            }
                            {!props.isDefaultSetting
                                &&
                                <Popup position="right center" wide='very' inverted content={props.siteSetting?.isActive ? translate("Durdur") : translate("Başlat")} trigger={
                                    <Button.Group icon>
                                        {props.siteSetting?.isActive
                                            &&
                                            <Button onClick={stopSite} >
                                                <Icon name='pause' />
                                            </Button>
                                        }
                                        {!props.siteSetting?.isActive
                                            &&
                                            <Button onClick={runSite}>
                                                <Icon name='play' />
                                            </Button>
                                        }
                                    </Button.Group>} />
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Label style={styles.marginR1}>Logo</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Image id="sitSetLogoImage" style={styles.siteLogo} src={displayProtectedAppDoc(props.siteSetting?.logoUrl, "sitSetLogoImage")} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Popup position="right center" wide='very' inverted content="Tarayıcı sekmesinde gözükecek ikon" trigger={
                                <Label style={styles.marginR1}>{translate("Favikon")}</Label>
                            } >
                            </Popup>
                        </Grid.Column>

                        <Grid.Column>
                            <Popup position="right center" wide='very' inverted content="Tarayıcı sekmesinde gözükecek ikon" trigger={
                                <Image id="sitSetFavImage" style={styles.siteIkon} src={displayProtectedAppDoc(props.siteSetting?.favIconUrl, "sitSetFavImage")} />
                            } >
                            </Popup>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Popup position="right center" wide='very' inverted content={translate("Giriş Ekranının solunda gözükecek görsel")} trigger={
                                <Label style={styles.marginR1}>{translate("Login Görsel")}</Label>
                            } >
                            </Popup>
                        </Grid.Column>

                        <Grid.Column>
                            <Popup position="right center" wide='very' inverted content={translate("Giriş Ekranının solunda gözükecek görsel")} trigger={
                                <Image id="sitSetLoginPhoto" style={styles.loginPhoto} src={displayProtectedAppDoc(props.siteSetting?.loginPhotoUrl, "sitSetLoginPhoto")} />
                            } >
                            </Popup>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </>
    );
}