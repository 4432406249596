import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Input, Label} from "semantic-ui-react";
import TranslateHelper from "../../../helper/TranslateHelper";

import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import { DataHandler } from '../../../helper/DataHelper';

const PasswordPolicySettings = () => {
    var {translate} = TranslateHelper();

    const [isCloseAllPasswordSettings, setIsCloseAllPasswordSettings] = useState(true);
    const [isRequireDigit, setIsRequireDigit] = useState(false);
    const [isRequiredLength, setIsRequiredLength] = useState(false);
    const [requiredLenght, setRequiredLength] = useState(1);
    const [isRequireLowercase, setIsRequireLowercase] = useState(false);
    const [isRequireNonAlphanumeric, setIsRequireNonAlphanumeric] = useState(false);
    const [isRequiredUniqueChars, setIsRequiredUniqueChars] = useState(false);
    const [isRequireUppercase, setIsRequireUppercase] = useState(false);
    const [isRequireSpecialCharacters, setIsRequireSpecialCharacters] = useState(false);
    const [specialCharacters, setSpecialCharacters] = useState("!@#$%^&*.")

    const [isLoading, setIsLoading] = useState(false);
    const [isGetLoading, setIsGetLoading] = useState(false);

    const getPasswordPolicySettingsByTenantId = () => {
        setIsGetLoading(true);

        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId"),
            Key: "PasswordPolicy"
        }

        NetworkRequest("api/Settings/GetSetting", data).then((response) => {
            if (response.data.resultCode === 0) {
                if (response.data.detail != null) {
                    let passwordSettingsJson = JSON.parse(response.data.detail.settingValue);

                    if (passwordSettingsJson != null) {
                        setIsCloseAllPasswordSettings(!passwordSettingsJson.CloseAllPasswordSettings);
                        setIsRequireDigit(passwordSettingsJson.RequireDigit);
                        setIsRequireLowercase(passwordSettingsJson.RequireLowerCase);
                        setIsRequireNonAlphanumeric(passwordSettingsJson.RequireNonAlphanumeric)
                        setIsRequireSpecialCharacters(passwordSettingsJson.RequireSpecialCharacters.IsActive);
                        setSpecialCharacters(passwordSettingsJson.RequireSpecialCharacters.Value);
                        setIsRequiredUniqueChars(passwordSettingsJson.RequireUniqueChars);
                        setIsRequireUppercase(passwordSettingsJson.RequireUpperCase);
                        setIsRequiredLength(passwordSettingsJson.RequiredLength.IsActive);
                        setRequiredLength(passwordSettingsJson.RequiredLength.Value);
                    }
                    
                }
            } else {
                toast(translate(response.data.message), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 3500});
            }
            setIsLoading(false);
        });
    }

    const savePasswordPolicySettings = () => {
        setIsLoading(true);

        let passwordSettingsData = {
            CloseAllPasswordSettings: !isCloseAllPasswordSettings,
            RequireDigit: isRequireDigit,
            RequiredLength: {
                IsActive: isRequiredLength,
                Value: requiredLenght.toString()
            },
            RequireLowerCase: isRequireLowercase,
            RequireNonAlphanumeric: isRequireNonAlphanumeric,
            RequireUniqueChars: isRequiredUniqueChars,
            RequireUpperCase: isRequireUppercase,
            RequireSpecialCharacters: {
                IsActive: isRequireSpecialCharacters,
                Value: specialCharacters
            }
        }

        let requestData = {
            TenantId: DataHandler.getUserDataWithKey("tenantId"),
            Key: "PasswordPolicy",
            PasswordPolicy: passwordSettingsData
        }
        
        NetworkRequest("api/Settings/SavePasswordPolicySettingsByTenantId", requestData).then((response) => {
            if (response.data.resultCode === 0) {
                toast(translate(response.data.message), {type: 'success', theme: 'dark', position: 'top-center', autoClose: 3500});
            } else {
                toast(translate(response.data.message), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 3500});
            }
            setIsLoading(false);
        });

        //console.log(passwordSettingsData);
    }

    useEffect(() => {
        getPasswordPolicySettingsByTenantId();
    }, []);

    return (
        <div className={"passwordPolicySettingsContainer"}>
            <div style={{marginBottom: "2%"}}>
                <Checkbox
                    onChange={(e, data) => {
                        setIsCloseAllPasswordSettings(data.checked != undefined ? data.checked : false)
                    }}
                    checked={isCloseAllPasswordSettings}
                    
                    label={translate(isCloseAllPasswordSettings ? translate("Parola ayarlarını devre dışı bırak.") : translate("Parola ayarlarını aktif et"))}
                    toggle
                />
                <hr style={{height: "1px", backgroundColor: "#ccc", border: "none", marginTop: "1%"}}/>
            </div>


            <div className={"passwordPolicySettingsContainer"}>
                <div>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequireDigit(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequireDigit}
                        
                        label={translate("Parolada 0-9 arasında bir numara gerektirir.")}
                        toggle
                    />
                </div>

                <div className={"passwordPolicyField"}>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequiredLength(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequiredLength}
                        
                        label={translate("Parolanın minimum uzunluğu.")} toggle />
                    <Input
                        onChange={(e) => {
                            setRequiredLength(parseInt(e.target.value))
                        }}
                        value={requiredLenght}
                        disabled={!isRequiredLength || !isCloseAllPasswordSettings}
                        min={1}
                        style={{width: "110px"}}
                        className={"greyInput"}
                        type={"number"}
                        placeholder={translate('Uzunluk')}/>
                </div>

                <div>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequireLowercase(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequireLowercase}
                       
                        label={translate("Parolada küçük harf karakteri gereklidir.")} toggle/>
                </div>

                <div>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequireNonAlphanumeric(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequireNonAlphanumeric}
                        
                        label={translate("Parolada alfanümerik olmayan bir karakter gereklidir.")} toggle/>
                </div>

                <div>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequiredUniqueChars(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequiredUniqueChars}
                       
                        label={translate("Parolada farklı karakterlerin kullanılması gereklidir.")} toggle/>
                </div>

                <div>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequireUppercase(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequireUppercase}
                       
                        label={translate("Parolada büyük harf karakteri gereklidir.")} toggle/>
                </div>

                <div className={"passwordPolicyField"}>
                    <Checkbox
                        disabled={!isCloseAllPasswordSettings}
                        onChange={(e, data) => {
                            setIsRequireSpecialCharacters(data.checked != undefined ? data.checked : false)
                        }}
                        checked={isRequireSpecialCharacters}
                        
                        label={translate("Özel karakter gereklidir.")} toggle/>
                    <Input
                        onChange={(e) => {
                            setSpecialCharacters(e.target.value)
                        }}
                        disabled={!isRequireSpecialCharacters || !isCloseAllPasswordSettings}
                        value={specialCharacters}
                        className={"greyInput"}
                        label={translate("İzin verilen özel karakterler")}
                        placeholder={translate('Karakterler  ')}
                    />
                </div>
            </div>
            <div>
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={savePasswordPolicySettings} circular={true} color={"green"}>{translate("Kaydet")}</Button>
            </div>
        </div>
    );
};

export default PasswordPolicySettings;