import * as React from 'react';
import { useState, useEffect } from "react";
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { Button, Checkbox, Grid, Label } from "semantic-ui-react";

import { DataHandler } from '../../../helper/DataHelper';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';

const LogSettings = () => {

    let _ = require("lodash");
    var { translate } = TranslateHelper();
    const [tenantId, setTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [logSetting, setLogSetting] = useState({
        isAuthorizeLogEnabled: false,
        isUnauthorizeLogEnabled:false,
        isExceptionLogEnabled:false
    });

    const saveLogSettings = () => {
        NetworkRequest('/api/Settings/AddOrUpdateSetting', {
            tenantId: tenantId,
            key: 'LogSettings',
            value: JSON.stringify(logSetting)
        }).then((response) => {
            if (response.data.resultCode == 0) {
                NetworkRequest('/api/LogSettings/UpdateCache', {
                    tenantId: tenantId,
                    logConfiguration: logSetting
                }).then((response) => {
                    if (response.data.resultCode == 0) {
                        toast(translate("Başarılı"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    }
                });
            }
        });
    }

    useEffect(() => {
        NetworkRequest('/api/Settings/GetSetting', { tenantId: tenantId, key: 'LogSettings' }).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail != null) {
                let json = JSON.parse(response.data.detail.settingValue);
                setLogSetting(json);
            } else {
                NetworkRequest('/api/LogSettings/GetUnAuthLogSetting', { tenantId: tenantId, key: 'LogSettings' }).then((response) => {
                    if (response.data.resultCode == 0) {
                        setLogSetting({ ...logSetting, isUnauthorizeLogEnabled:response.data.detail });
                    }
                });
            }
        });
    }, [tenantId]);

    return (
        <div className="documentSettingsContainer">
            <h1>{translate("Log ayarları")}</h1>
            <Grid className="width100" columns={2}>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Label>
                            {translate("Çalışanlar giriş yapmadan önce tüm çağrılar kaydedilsin mi?")}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            onChange={(e, data) => {
                                setLogSetting({ ...logSetting, isUnauthorizeLogEnabled: data.checked != undefined ? data.checked : false })
                            }}
                            checked={logSetting.isUnauthorizeLogEnabled}
                            toggle
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Label>
                            {translate("Çalışanlar giriş yaptıktan sonra tüm çağrılar kaydedilsin mi?")}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            onChange={(e, data) => {
                                setLogSetting({ ...logSetting, isAuthorizeLogEnabled: data.checked != undefined ? data.checked : false })
                            }}
                            checked={logSetting.isAuthorizeLogEnabled}
                            toggle
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Label>
                            {translate("Hatalar kaydedilsin mi?")}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox
                            onChange={(e, data) => {
                                setLogSetting({ ...logSetting, isExceptionLogEnabled: data.checked != undefined ? data.checked : false })
                            }}
                            checked={logSetting.isExceptionLogEnabled}
                            toggle
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button circular={true} color="green" className="floatRight" onClick={() => { saveLogSettings(); }}>
                            {translate("Ayarları Kaydet")}
                        </Button>
                        <Button circular={true} color="orange" className="floatRight" onClick={() => {
                            //console.log("NotTransLatedWords: ", secureLocalStorage.getItem("NotTransLatedWords"));
                        }}>
                            {translate("Çevirilmemiş Kelimeleri Konsola Yaz")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default LogSettings;