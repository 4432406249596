import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import 'react-quill/dist/quill.snow.css';

const QuillEditor = (props) => {

    return (
        <div className="text-editor">
            <EditorToolbar />
            <ReactQuill
                theme="snow"
                value={props.value}
                onChange={props.setValue}
                placeholder={"Write something awesome..."}
                modules={modules}
                formats={formats}
            />
        </div>
    )
}

export default QuillEditor;