import React, {FC, useEffect, useState, createContext} from 'react';
import {NetworkRequest} from "./helper/NetworkRequest";

export const rightSlidePanelMobileContext = createContext<any>(false);

const StoreUserDashboard = ({children}:any) => {
    const [isOpenRightSlidePanelMobile, setIsOpenRightSlidePanelMobile] = useState<boolean>(false);
    
    return (
        <rightSlidePanelMobileContext.Provider value={{isOpenRightSlidePanelMobile, setIsOpenRightSlidePanelMobile}}>
            {children}
        </rightSlidePanelMobileContext.Provider>
    );
}

export default StoreUserDashboard;