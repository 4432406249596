import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Button, Icon } from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TenantList from "./Components/TenantList";
import AddTenant from "./Components/AddTenant";
import EditTenant from "./Components/EditTenant";
import { useParams } from 'react-router-dom';

const Tenants = (props) => {
    let { id, page } = useParams();
    //styles
    let styles = {
        activeColor: {
            color: 'green'
        }
    }

    const [isList, setIsList] = useState(true);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const addTenant = () => {
        setIsCreate(true);
        setIsEdit(false);
        setIsList(false);
    }

    const editTenant = (tenant) => {
        setSelectedTenant(tenant);
        setIsCreate(false);
        setIsEdit(true);
        setIsList(false);
    }

    const showList = () => {
        setIsCreate(false);
        setIsEdit(false);
        setIsList(true);
    }

    const update = (tenant) => {
        return NetworkRequest("/api/Tenants/Update", tenant).then((response) => {
            ////
            return response
        });
    }

    useEffect(() => {
        if (page == "2") {
            addTenant()
        }
    }, [page])

    return (
        <Container className="tenantListContainer">
            {isList
                &&
                <>

                <Button circular color="green" className="marginBottom" style={{float:"right"}} onClick={addTenant}>
                            <Icon name="plus" />
                            Yeni
                        </Button>

                <TenantList AddTenant={addTenant} EditTenant={editTenant} Update={update}   
                    />
                </>
            }
            {isCreate
                &&
                <AddTenant showList={showList} />
            }
            {isEdit
                &&
                <EditTenant showList={showList} Tenant={selectedTenant} Update={update} />
            }
        </Container>
    );
}

export default Tenants;