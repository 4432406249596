import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkRequest } from "../../../helper/NetworkRequest";
import { Checkbox, Header, Icon, Loader, Popup, Progress, Table, Transition, Rating } from "semantic-ui-react";
import { IDataTable, IModuleDetailReportView } from "../../../interfaces/interfaces";
import { SystemReportCard } from "../../../components/ReportCard";
import { DateFormat } from "../../../helper/DateFormat";
import RightPanel from "../../../components/RightPanel";
import { downloadFile, prepareDataForTable } from "../../../helper/HelperFunctions";
import DataTable from "../../../components/DataTable";

import ModuleDetailUserListReport from "../../../components/ModuleDetailUserListReport";
import * as Scroll from 'react-scroll';
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const ModuleReportDetail = (props) => {
    var { translate } = TranslateHelper();
    var scroll = Scroll.animateScroll;
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [moduleReport, setModuleReport] = useState<IModuleDetailReportView>();
    const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
    const [rightPanelHeader, setRightPanelHeader] = useState<string>("");
    const [rightPanelContent, setRightPanelContent] = useState<any>();
    const [sectionContentId, setsectionContentId] = useState<any>();
    const [currentSelectedSectionContentId, setCurrentSelectedSectionContentId] = useState<string>("");
    const [filterData, setFilterData] = useState<any>({});
    const [filterText, setFilterText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

    const CONTENT_ICON = {
        1: <Icon disabled name={"file video outline"} size={"big"} />,
        2: <Icon disabled name={"file code outline"} size={"big"} />,
        4: <Icon disabled name={"question"} size={"big"} />,
        8: <Icon disabled name={"file alternate outline"} size={"big"} />,
        15: <Icon disabled name={"tasks"} size={"big"} />,
    }
    const CONTENT_ICON_TEXT = {
        1: translate("Video"),
        2: translate("HTML"),
        4: translate("Sınav"),
        8: translate("Anket"),
        15: translate("Görev"),
    }

    const onChangeActive = (e, data) => {
        setIsActive(data.checked);
    }

    const onChangeDeleted = (e, data) => {
        setIsDeleted(data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(dataTableModel);
        }, 100);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(model);
        }, 100);
    }

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: [
            translate("Modül"),
            translate("Bölüm"),
            translate("İçerik"),
            translate("Tip"),
            translate("Durum"),
            translate('Başarı'),
            translate('Deneme'),
            translate('Puan'),
            translate('İsim'),
            translate('Soyisim'),
            translate('Email'),
            translate('Çalışan Notu'),
            translate('İşe Başlama Tarihi'),
            translate('İçeriğe Başlama Tarihi'),
            translate('İçeriği Bitirme Tarihi'),
            translate('Son Oturum Açma Tarihi'),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "line graph",
                desc: translate("Rapor"),
                color: "orange",
                link: "/employeeReport",
                type: "report"
            },

        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: true,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: false,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        setRowSelected: setRowSelected,
        addRowLink: "",
        isAttachingPage: true,
    });

    //prepare data for table
    const prepareData = (data, sectionContentId) => {
        //hidden column
        var model = prepareDataForTable(data.list, [
            'userId',
            'status',
            'statusId',
            'searchField',
            'id'
        ]);
        let dataTableObj = {
            header: dataTable.header,
            data: model,
            transactions: dataTable.transactions,
            filter: dataTable.filter,
            pagination: dataTable.pagination,
            addRowLink: dataTable.addRowLink,
            noCheckBox: dataTable.noCheckBox,
            isAttachingPage: dataTable.isAttachingPage,
        }
        setDataTable(dataTableObj);
        generateRightPanelContent(dataTableObj, sectionContentId);
    }

    const getModuleById = () => {
        let moduleData = {
            id: id
        }

        NetworkRequest("api/Report/GetModuleReportById", moduleData).then((response) => {
            ////
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                let data = response.data.detail;
                setModuleReport(data);
                setIsLoading(false);
            }
        });
    }

    const generateRightPanelContent = (dataTableObj, sectionContentId) => {
        setRightPanelContent(<DataTable
            dataTable={dataTableObj}
            isAllSelected={isAllSelected}
            exportExcelFunction={modulDetailExcelReport}
            filterData={sectionContentId}
            selectAllHandler={selectAllHandler}
            isLoading={isLoading}
        />)
    }

    const prepareRightPanel = (filterDataObj, content?) => {

        if (filterDataObj.sectionContentId == null)
            return;

        if (content) {
            setCurrentSelectedSectionContentId(content.id);
            setRightPanelHeader(content.title);
            //filterDataObj = { ...filterDataObj, sectionContentId: content.id }
        }
        //debugger;
        NetworkRequest("api/Report/GetModuleDetailReportByContentId", filterDataObj).then((response) => {
            ////
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                if (!rightPanelOpen) {
                    setRightPanelOpen(true);
                }
                prepareData(response.data.detail, filterDataObj.sectionContentId);
            };
            setIsLoading(false)
        });
    }

    const modulDetailExcelReport = (sectionId) => {
        let data = {
            sectionContentId: sectionId
        }
        NetworkRequest("api/Report/GetModulDetailReportToExcel", data).then((response) => {
            downloadFile(response.data, translate("Task Detay Raporu"));
        });
    }

    useEffect(() => {
        ////console.log("currentSelectedSectionContentId", currentSelectedSectionContentId);
    }, [currentSelectedSectionContentId]);

    useEffect(() => {
        ////console.log("moduleReport", moduleReport);
    }, [moduleReport]);

    useEffect(() => {
        //console.log("filterText", filterText);
        setIsLoading(true);
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            sectionContentId: currentSelectedSectionContentId == "" ? null : currentSelectedSectionContentId
        };
        setFilterData(filterData);
        prepareRightPanel(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, props.selectedTenantId]);


    useEffect(() => {
        getModuleById();
    }, []);

    const styles = {
        userInformationRightPanel: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "row" as "row",
            flexWrap: "wrap" as "wrap",
        },

        contentContainer: {
            display: "flex",
            width: "100%",
            flexDirection: "column" as "column",
            marginTop: "40px",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            borderLeft: "solid 10px #838383"
        },

        sectionHeader: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "4px",
            alignItems: "flex-start",
            height: "70px",
            borderRadius: "10px",
            padding: "20px",
            backgroundColor: "white",
            fontSize: "1.6em",
            fontWeight: "100",
            marginBottom: "4%"
        },

        contentBox: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            padding: "20px",
            height: "60px",
            width: "98%",
            margin: "10px",
            backgroundColor: "white",
            flexDirection: "row" as "row",
            gap: "1em",
            cursor: "pointer"
        },

        contentBoxIndex: {
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "#727272",
            color: "white",
            fontWeight: "thin" as "thin",
            textAlign: "center" as "center",
            width: "40px",
            height: "40x"
        },

        moduleHeader: {
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center",
            marginBottom: "10px"
        }
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <RightPanel
                    Open={rightPanelOpen}
                    ClosePanel={() => {
                        setRightPanelOpen(false)
                    }}
                    Header={rightPanelHeader}
                    Content={rightPanelContent}
                    SectionContentId={currentSelectedSectionContentId}
                />

                {/*loader*/}
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size="huge" active inline='centered' />
                    </div>
                </Transition>

                {/*content*/}
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <div style={styles.moduleHeader}>
                            <div>
                                <Popup
                                    content={moduleReport?.moduleReportDetailModuleView.isActive ? translate("Modül Erişime Açıktır") : translate("Modül Erişime Kapalıdır")}
                                    trigger={
                                        <Icon size={"big"} name={"circle"} disabled
                                            color={moduleReport?.moduleReportDetailModuleView.isActive ? "green" : "grey"} />
                                    } />
                            </div>

                            <div><Header style={{ fontSize: "3em" }}
                                as={"h1"}>{moduleReport?.moduleReportDetailModuleView.title}

                            </Header></div>
                        </div>
                        <div>
                            {(moduleReport != undefined && moduleReport?.averageRating >= 1) &&
                                <Rating
                                    disabled={true}
                                    maxRating={5}
                                    defaultRating={moduleReport?.averageRating}/>
                            }
                        </div>
                        <div>
                            <p>{moduleReport?.moduleReportDetailModuleView.descriptiom}</p>
                            <p>{translate("Oluşturulma Tarihi:") + DateFormat(moduleReport?.moduleReportDetailModuleView.createdDate)}</p>

                            {moduleReport?.moduleReportDetailModuleView.deletedDate &&
                                <p>{translate("Silinme Tarihi:") + DateFormat(moduleReport?.moduleReportDetailModuleView.deletedDate)}</p>
                            }
                        </div>

                        {moduleReport?.moduleReportDetailModuleView.deletedDate &&
                            <SystemReportCard
                                Header={translate("Modül Detayı")}
                                Text={DateFormat(moduleReport?.moduleReportDetailModuleView.deletedDate)}
                                Padding={"5px"}
                            />
                        }

                        <div style={{ marginTop: "50px" }}>
                            <Header as={"h2"} content={translate("Çalışan")} />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            {!rightPanelOpen &&
                                <ModuleDetailUserListReport
                                    moduleId={id}
                                />
                            }
                        </div>
                        <div style={{ marginTop: "80px" }}>
                            <Header as={"h2"} content={translate("Bölümler")} />
                        </div>
                        <div>
                            {moduleReport?.moduleReportDetailSectionView?.map(section => (
                                <div style={styles.contentContainer} key={uuidv4()}>
                                    <div style={styles.sectionHeader}>{section.title}
                                        {section.averageRating >= 1
                                            &&
                                            <Rating
                                                disabled={true}
                                                maxRating={5}
                                                defaultRating={section.averageRating}
                                            />
                                        }
                                    </div>

                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column" as "column",
                                        flexWrap: "wrap" as "wrap",
                                        justifyContent: "space-evenly"
                                    }}>
                                        {section.moduleReportDetailSectionContentViews.map((content, index) => (
                                            <div key={uuidv4()}>
                                                <div
                                                    onClick={(e) => {
                                                        setCurrentSelectedSectionContentId(content.id);
                                                        filterData.sectionContentId = content.id;
                                                        prepareRightPanel(filterData, content);
                                                    }}
                                                    style={styles.contentBox}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
                                                        <div style={styles.contentBoxIndex}>
                                                            {index + 1}
                                                        </div>
                                                        {CONTENT_ICON[content.contentType] != undefined
                                                            ? <Popup
                                                                content={CONTENT_ICON_TEXT[content.contentType]}
                                                                trigger={CONTENT_ICON[content.contentType]}
                                                            />

                                                            : <Popup content={translate("İçerik tipi belirlenmemiş")} trigger={<Icon
                                                                name={"times circle outline"}
                                                                size="big"
                                                                disabled
                                                            />} />
                                                        }

                                                        <div>{content.title}</div>

                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "8px"
                                                    }}>
                                                        <div>
                                                            {content.averageRating >= 1 &&
                                                                //<Rating icon='star' defaultRating={content.averageRating} maxRating={5} disabled />
                                                                <Rating
                                                                    disabled={true}
                                                                    maxRating={5}
                                                                    defaultRating={content.averageRating }
                                                                />

                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default ModuleReportDetail;