import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Loader, Modal, Transition } from 'semantic-ui-react';
import { datableFilterChange, digitallicaConfirm, prepareDataForTable } from '../helper/HelperFunctions';
import { NetworkRequest } from '../helper/NetworkRequest';
import DataTable from './DataTable';
import { IDataTable } from '../interfaces/interfaces';

import { toast } from 'react-toastify';
import { DataHandler } from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

const CertificateAttachToModule = (props) => {
    var {translate} = TranslateHelper();
    let styles = {
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        },
        thumbnailPhoto: {
            width: "50vw" as "50vw",

        }
    }

    const [moduleId] = useState(props.id);
    const [certificate, setCertificate] = useState({ title: "", base64: "" });
    const [loader, setLoader] = useState(true);
    const [photoThumbnail, setPhotoThumbnail] = useState(false);
    const [certificateId, setCertificateId] = useState("");
    const [image, setImage] = useState("");
    var _ = require('lodash');
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const zoomPhoto = (id) => {
        let data = { id: id };
        NetworkRequest("/api/Certificate/GetById", data).then((response) => {

            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setImage(result.base64);
                setPhotoThumbnail(true);
            }
        });
    }

    const attachCertificate = (id) => {
        let data = { certificateId: id, moduleId: moduleId };
        NetworkRequest("/api/Modules/AttachCertificate", data).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setCertificateId(id);
                toast("Sertifika tanımlandı.", { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
        });
    }

    const removeCertificate = () => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let data = { id: moduleId };
                NetworkRequest("/api/Modules/RemoveCertificate", data).then((response) => {
                    if (response.data.resultCode === 0) {
                        let result = response.data.detail;
                        setCertificateId(result.certificateId);
                        toast("Sertifika silindi.", { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    }
                });
            },
            () => {
                toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {

        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }
    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [

            translate("Adı"),
            translate("Metin Rengi"),
            translate("Yüklenme Tarihi"),
            translate("Yetkili Adı"),
            translate("İşlemler"),
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "zoom",
                desc: "Görüntüle",
                color: "black",
                link: null,
                type: "zoom",
                function: zoomPhoto
            },
            {
                icon: "plus",
                desc: "Sertifikayı Ata",
                color: "green",
                link: null,
                type: "add",
                function: attachCertificate
            },

        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/AddQuiz",
        setRowSelected: setRowSelected,
        isAttachingPage: true,
        noCheckBox: true,
        noFilter: true,


    });

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    //prepare data for table
    const prepareData = (response) => {
        //debugger;
        let data = response.certificateViews;
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        let model = prepareDataForTable(data, [
            "id",
            "tenantId",
            "certificateId",
            "createUserId",
            "url",
            "appType",
            "certificateUrl",
            "base64",
            "assignedModuleCount",
            "textColorCode"
        ]);
        setDataTableModel({ ...dataTableModel, data: [] })
        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(
                        response.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
            setIsLoading(false);
        }, 1000);
    }

    useEffect(() => {
        ////
    }, [dataTableModel]);

    //get table data from database
    const getCertificates = (filterData?) => {
        if (filterData == undefined)
            filterData = { tenantId: tenantId }
        NetworkRequest("/api/Certificate/GetList", filterData).then((response) => {
            ////
            if (response.data.resultCode == 0) {
                prepareData(response.data.detail);
                setLoader(false)
            }
        });
    }

    const checkAssignedCertificate = () => {
        let checkData = {
            id: moduleId
        }
        NetworkRequest("/api/Modules/GetById", checkData).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                if (result == null)
                    return;
                setCertificateId(
                    result.certificateId
                );
            }
        })
    }

    const getCertificate = () => {
        let checkData = {
            id: certificateId
        }
        NetworkRequest("/api/Certificate/GetById", checkData).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                //debugger;
                setCertificate({ title: result.title, base64: result.base64 });
                setLoader(false);
            }
        });
    }

    useEffect(() => {
        if (moduleId != "")
            checkAssignedCertificate();
    }, [moduleId]);

    useEffect(() => {
        if (certificateId != "" && certificateId != null)
            getCertificate();
        else {
            getCertificates({ tenantId: tenantId });
        }
    }, [certificateId]);

    useEffect(() => {
        //
    }, [tenantId]);


    return (
        <>
            <div>
                <Transition visible={loader} animation='scale' duration={0}>
                    <Loader size="huge" active inline='centered' />
                </Transition>
                {((certificateId == "" || certificateId == null) && !loader) &&
                    <DataTable
                        dataTable={dataTableModel}
                        isAllSelected={isAllSelected}
                        selectAllHandler={selectAllHandler}
                        isLoading={isLoading}
                    />}
                {((certificateId != "" && certificateId != null) && (certificate.base64 != undefined && certificate.base64 != "" && certificate.base64 != null) && !loader) &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                        <img style={styles.thumbnailPhoto} src={"data:image/png;base64," + certificate.base64} />

                        <p style={{ fontStyle: "italic" }}>
                            {translate("Module atanmış bir sertifika şablonu bulunmaktadır, yenisini atamak için mevcut şablonu silmeniz gerek.")}
                        </p>

                        <Button
                            className={"dangerRedButton"}
                            onClick={() => { removeCertificate() }}>
                            {translate("Sil")}
                        </Button>

                    </div>
                }

                <Modal
                    onClose={() => setPhotoThumbnail(false)}
                    onOpen={() => setPhotoThumbnail(true)}
                    open={photoThumbnail}

                >
                    <Modal.Actions>
                        <Button
                            className={"dangerRedButton"}
                            onClick={() => setPhotoThumbnail(false)}>{translate("Kapat")}</Button>
                    </Modal.Actions>
                    <Modal.Content style={{ display: "flex", flexDirection: "column" as "column", alignItems: "center" }} image>
                        <img style={styles.thumbnailPhoto} src={"data:image/png;base64," + image} />
                    </Modal.Content>
                </Modal>
            </div>
        </>

    );
}

export default CertificateAttachToModule;