import React, {SyntheticEvent, useEffect, useState} from 'react';
import {Button, Dropdown, Header, Input, Loader, TextArea} from "semantic-ui-react";
import {NetworkRequest} from "../../../helper/NetworkRequest";

import './DictionarySettings.css';
import {toast} from "react-toastify";
import {digitallicaConfirm} from '../../../helper/HelperFunctions';
import {useParams} from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const DictionarySettings = (props) => {
    var {translate} = TranslateHelper();
    let {id} = useParams();
    const [themeColor, setThemeColor] = useState("#fafafa");
    const [themeStartColor, setThemeStartColor] = useState("#ff8a00");
    const [themeEndColor, setThemeEndColor] = useState("#e52e71");
    const [currentDictionaryId, setCurrentDictionaryId] = useState(id);
    const [currentThemeId, setCurrentThemeId] = useState(0);
    const [isSuccessTheme, setIsSuccessTheme] = useState(false);
    const languagesInit: object = {
        "46ceeca7-d8bd-4b72-842c-fa950642c67c": "turkish",
        "3a521c60-45e2-4de7-b666-0e3ce9de8ba6": "english",
        "454eb846-8475-4ef0-8dd0-898e29972438": "french",
        "2d28fc4a-8d69-42f8-8965-7218831422dd": "arabic",
        "19e4a575-cbe5-458a-9e77-694033409ae6": "italian",
        "3c54230b-e48f-4a61-87df-c3457c1f1df5": "german",
        "f105310f-f633-445c-9598-57851e764e89": "bulgarian",
        "13a983c5-1998-4e84-94e6-1d01971c9451": "chinese",
        "f3cde82c-3b34-4b66-bae7-bed8a2a0b9ff": "indonesian",
        "2cc6ef7d-717e-4ccf-8f0f-835b37bf7867": "persian",
        "336b8797-7eec-44e7-b8a4-575f1f72deb5": "hindi",
        "24172253-6d43-4596-a364-77dbdad39822": "spanish",
        "21565297-cd76-4627-b80a-d87942fe2c6f": "hebrew",
        "7d628efa-97b9-42b8-9982-0a31a5a84deb": "swedish",
        "b2ec094d-e62e-439d-a569-d0233ccd01f0": "icelandic",
        "27302de2-b432-40d0-bcc3-0c67b60fc384": "japanese",
        "5db06373-68e2-4fab-ba8f-bca5dfe62ed8": "korean",
        "a936b9e5-83f7-4caa-87ee-801d1e4e572a": "kirghiz",
        "194f1b36-8d93-46e5-9341-eb83be713a50": "norwegian",
        "a8fc2938-b95a-4682-9227-699e44ef762b": "ottoman",
        "872912ff-803e-4c00-a585-965c553fd17e": "uzbek",
        "59feead2-8f4c-410a-b831-49addb4d2adc": "portuguese",
        "ed87dfc2-d7d0-4b76-9efc-c1b89313d347": "russian",
        "7d514bff-fbc5-4a82-bdba-e05d27bd7111": "serbian",
        "2dfb8033-732d-491b-9d6d-1fba3349442b": "tatar",
        "e0130b27-99f4-4626-abfe-a13476343280": "greek",
    };

    const [options, setOptions] = useState<Array<any>>([]);
    const [currentValues, setCurrentValues] = useState<any>([]);
    const [dicName, setDicName] = useState<string>("");
    const [dicDesc, setDicDesc] = useState<string>("");
    const [dicId, setDicId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleAddition = (event: SyntheticEvent, data: object) => {
        ////console.log(data);
        // setOptions(oldArray => [...oldArray, { text: data.v }]);
    }

    const handleChange = (event: SyntheticEvent, data: any) => {
        setCurrentValues(data.value);
    }

    const getLanguages = () => {
        NetworkRequest("/api/DictionaryLanguage/GetLanguages").then(response => {
            if (response.data.resultCode === 0) {
                
                let languageList = response.data.detail;
                languageList = languageList.map((l) => {
                    return {
                        dictionaryLanguagesId: l.dictionaryLanguagesId,
                        flag: l.flag,
                        key:l.key,
                        name:l.name,
                        text: translate(l.text),
                        value:l.value
                    }
                });
                setOptions(languageList);
            }
        });
    }

    const getDicData = () => {
        let dicData = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        };

        NetworkRequest("/api/Dictionary/GetByTenantId", dicData).then((response) => {
            if (response.data.resultCode === 0) {
                let dic = response.data.detail;
                setDicName(dic.name);
                setDicDesc(dic.description);
                setDicId(dic.buddyDictionaryId);
            }

            setIsLoading(false);
        });
    }

    const saveSettings = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            saveDictionarySettings();
            saveThemeSetting();
        }, () => {
            toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
        });
    }

    const saveDictionarySettings = () => {


        var lastSelectedLanguage: any = [];

        for (var i = 0; i < currentValues.length; i++) {
            var result = options.filter(language => language.key == currentValues[i]);

            var selectedLanguage = {
                IsDeleted: false,
                LanguageId: result[0].dictionaryLanguagesId,
                TenantId: DataHandler.getUserDataWithKey("tenantId"),
                DictionaryId: dicId
            }

            lastSelectedLanguage.push(selectedLanguage);
        }

        let dicData = {
            Name: dicName,
            Description: dicDesc,
            TenantId: DataHandler.getUserDataWithKey("tenantId"),
            DicLanguages: lastSelectedLanguage
        };
        NetworkRequest("/api/Dictionary/Update", dicData).then(response => {
            if (response.data.resultCode === 0) {
                toast(translate("Sözlük ayarları kaydedildi!"), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 10000
                });
                setTimeout(() => {
                    window.location.href = "/dictionary"
                }, 1000);
            }
            setIsLoading(false);
        }).catch(error => {
            toast(translate("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."), {
                type: 'info',
                theme: 'light',
                position: 'top-center',
                autoClose: 10000
            });
        });
    }

    const getSelectedLanguages = () => {
        let dicData = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        };
        NetworkRequest("/api/DictionaryTenantLanguage/GetByTenantId", dicData).then(response => {
            if (response.data.resultCode === 0) {
                let userSelectedLanguageListObject: Array<object> = response.data.detail;
                let lastLanguagesArray: Array<string> = [];

                Object.keys(userSelectedLanguageListObject).forEach((key) => {
                    lastLanguagesArray.push(languagesInit[userSelectedLanguageListObject[key].languageId]);
                });
                setCurrentValues(lastLanguagesArray);
            }

            setIsLoading(false);
        });
    }

    const saveThemeSetting = () => {
        var themeData = {
            DictionaryId: currentDictionaryId,
            Color: themeColor,
            StartColor: themeStartColor,
            EndColor: themeEndColor,
            id: currentThemeId,
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        NetworkRequest('api/DictionaryTheme/Insert', themeData).then((response) => {
            if (response.data.resultCode == 0) {
                setIsSuccessTheme(true);
            }
        })
    }

    const getThemeSettingByTenantId = () => {
        var getThemeData = {
            TenantId: DataHandler.getUserDataWithKey("tenantId"),
        }

        NetworkRequest('api/DictionaryTheme/GetByTenantId', getThemeData).then((response) => {

            if (response.data.resultCode == 0) {
                if (response.data.detail != null) {
                    let theme = response.data.detail;

                    setThemeColor(theme.color);
                    setThemeStartColor(theme.startColor);
                    setThemeEndColor(theme.endColor);
                    setCurrentThemeId(theme.id);
                }
            }
        });
    };

    useEffect(() => {
        getDicData();
        getLanguages();
        getSelectedLanguages();
        getThemeSettingByTenantId();
    }, []);
    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={{display: "flex", flexDirection: "column", gap: "1em", width: "50%"}}>
                {isLoading ? <Loader active inline='centered'/> :
                    <div>
                        <Header as='h2'>{translate("Sözlük Ayarları")}</Header>
                        <Input size={"big"} fluid={true} className="greyInput" value={dicName} onChange={e => setDicName(e.target.value)} focus
                               placeholder={translate("Sözlük Adı (*)")}/>

                        <div style={{marginTop: "1%"}}>{translate("Sözlük Açıklaması")}</div>
                        <TextArea className={"greyInput"} onChange={e => setDicDesc(e.target.value)} value={dicDesc}
                                  placeholder={translate("Sözlük Açıklaması")}/>

                        <div style={{marginTop: "1%"}}>{translate("Seçilen diller")}</div>
                        <Dropdown
                            className={"language-select-dropdown greyInput"}
                            options={options}
                            placeholder={translate("Dil Seçiniz")}
                            search
                            selection
                            fluid
                            multiple
                            allowAdditions
                            value={currentValues}
                            onAddItem={handleAddition}
                            onChange={handleChange}
                        />

                        <div className={"dictionary-generic-setting-box"}>
                            <div className={"dictionary-generic-setting-form-elements-theme"}>
                                <div className={"dictionary-row-input"}>
                                    <div className={"dictionary-label"}>{translate("Tema rengi")}</div>
                                    <Input
                                        size="big"
                                        value={themeColor}
                                        onChange={o => {
                                            setThemeColor(o.target.value);
                                        }}
                                        type="color"
                                        fluid={true}
                                        placeholder={translate("Tema rengi")}
                                        className={"dictionary-input-text greyInput"}
                                        id={"dictionary-select-color-input"}
                                    />
                                </div>

                                <div className={"dictionary-row-input"}>
                                    <div className={"dictionary-label"}>{translate("Bar başlangıç rengi")}</div>
                                    <Input
                                        size="big"
                                        value={themeStartColor}
                                        onChange={o => {
                                            setThemeStartColor(o.target.value);
                                        }}
                                        type="color"
                                        fluid={true}
                                        placeholder={translate("Bar başlangıç rengi")}
                                        className={"dictionary-input-text greyInput"}
                                        id={"dictionary-select-color-input"}
                                    />
                                </div>

                                <div className={"dictionary-row-input"}>
                                    <div className={"dictionary-label"}>{translate("Bar bitiş rengi")}</div>
                                    <Input
                                        size="big"
                                        value={themeEndColor}
                                        onChange={o => {
                                            setThemeEndColor(o.target.value);
                                        }}
                                        type="color"
                                        fluid={true}
                                        placeholder={translate("Bar bitiş rengi")}
                                        className={"dictionary-input-text greyInput"}
                                        id={"dictionary-select-color-input"}
                                    />
                                </div>


                            </div>

                            <div className={"example-bar-box"}>
                                <div className={"dictionary-label"}>
                                    {translate("Örnek bar (seçtiğiniz renklerin oluşturacağı bar bu şekilde olacaktır)")}
                                </div>
                                <div
                                    style={{background: `linear-gradient(to right, ${themeStartColor}, ${themeEndColor})`}}
                                    className={"example-bar"}></div>
                            </div>

                        </div>

                        <div className="dic-save-btn">
                            <Button color={"blue"} className={"roundedButton"} onClick={saveSettings}
                                    style={{width: "100px", marginTop: "1%"}}
                            >{translate("Kaydet")}</Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default DictionarySettings;