import * as React from 'react';
import { useState } from 'react';
import { Button, Icon, Image, Input, Loader, Popup, Progress, Transition } from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { useNavigate } from 'react-router-dom';

import { DataHandler } from '../../../helper/DataHelper';
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import BuddyPagination from '../../CommonPages/BuddyPagination';
import TranslateHelper from "../../../helper/TranslateHelper";
import DefaultProfileImage from '../../../images/user.png';
import { v4 as uuidv4 } from 'uuid';
function ManagementHomePageV2(props) {
    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [teamList, setTeamList] = useState<Array<any>>();
    const [timeOut, setTimeOut] = useState<any>();
    const [roleNameCookie, setRoleNameCookie] = useState<any>();
    const [userList, setUserList] = useState<Array<any>>();
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [teamCount, setTeamCount] = useState(1);
    const [filterText, setFilterText] = useState("");
    const [paginationLoader, setPaginationLoader] = useState(true);
    const [isLogin, setIsLogin] = useState(DataHandler.getUserDataWithKey("isLogin") != undefined ? JSON.parse(DataHandler.getUserDataWithKey("isLogin")) : false);
    const [forceLogin, setForceLogin] = useState(DataHandler.getUserDataWithKey("forceLogin") != undefined ? JSON.parse(DataHandler.getUserDataWithKey("forceLogin")) : false);

    const changeUserPage = (user, page, propName) => {
        let clone = _.cloneDeep(userList);
        var elem = clone.find(x => x.userId == user.userId)
        elem[propName] = page;
     
        setUserList(clone);

    }

    const getTeam = (filter) => {

        setPaginationLoader(true);
        const timerSearch:any = setTimeout(() => {
            NetworkRequest("api/Team/GetMyTeamList", filter).then((response) => {
                ////console.log("getTeam: ", response);
                if (response.data.resultCode === 0) {
                    let users = response.data.detail.list;
                    setTeamList(users);

                    if (users != undefined && users.length > 0) {
                        let slicedUsers = users.slice(0, 15);
                        setUserList(slicedUsers);
                    }
                    setRoleNameCookie(filter.roleName);
                    setPageCount(Math.ceil(response.data.detail.count / 15))
                    setTeamCount(response.data.detail.count)
                    setIsLoading(false);
                    setPaginationLoader(false);
                }
            });
        },1000);

        return () => {
            clearInterval(timerSearch);
        }


    }

    const styles = {
        container: {
            display: "flex",
            flexDirection: "column" as "column",
            height: "auto",
            width: "90%",
            paddingBottom: "3%"
        },
        heading: {
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "space-between"
        },
        managementHeader: {
            fontSize: "2vw",
            fontWeight: 700,
            color: "#626c85",
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center"
        },
        searchContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1rem",
            alignItems: "center"

        },
        usersContainer: {
            // display: "flex",
            // flexDirection: "row" as "row",
            // minHeight: "70vh",
            // margin: "3vw",
            // gap: "3vw",
            // justifyContent: "center"
        },
        paginationContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "center",
            fontSize: "1vw",
            width: "100%",
            alignItems: "center",
            justifyItems: "center",
            color: "rgb(98, 108, 133)",
            fontWeight: 700
        },
        userDiv: {
            // display: "flex",
            // flexDirection: "column" as "column",
            // width: "20%",
            // padding: "1vw",
            // borderRadius: "1.3vw",
            // gap: "1vw",
            // alignItems: "center"
        },
        userGroupedInfo: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: "0.5vw",
            alignItems: "center", matginTop: "35px"
        },
        goTasksBtn: {
            backgroundColor: "#ff408d",
            color: "white",
            fontWeight: 700,
            letterSpacing: "1.5px",
            border: "none",
            padding: "0.5rem 1.5rem",
            borderRadius: ".25rem",
            fontSize: "1vw"
        },
        defaultPhoto: {
            width: "200px", height: "200px", borderRadius: "50%", marginTop: "20px", marginLeft: "10px"
        },
        userPhoto: {
            width: "150px", height: "150px", borderRadius: "50%", marginTop: "35px"
        },
        dikey: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: "0.5rem"
        },
        yatay: {
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem"
        }, moduleHeader: {
            fontSize: "1.3rem",
            color: "rgb(98, 108, 133)",
            textAlign: "justify" as "justify"
        },
        moduleProgress: {
            width: "60%",
            margin: "0"
        },
        moduleList: {
            width: "100%",
            gap: "2rem",

            display: "flex",
            flexDirection: "column" as "column"
        },
        marginTop15: { marginTop: "15%" }

    }

    React.useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: page,
            roleName: DataHandler.getUserDataWithKey("userRole")
        }

        setPaginationLoader(true);
        const timerSearch: any = setTimeout(() => {
            NetworkRequest("api/Team/GetMyTeamList", filterData).then((response) => {
                ////console.log("getTeam: ", response);
                if (response.data.resultCode === 0) {
                    let users = response.data.detail.list;
                    setTeamList(users);

                    if (users != undefined && users.length > 0) {
                        let slicedUsers = users.slice(0, 15);
                        setUserList(slicedUsers);
                    }
                    setRoleNameCookie(filterData.roleName);
                    setPageCount(Math.ceil(response.data.detail.count / 15))
                    setTeamCount(response.data.detail.count)
                    setIsLoading(false);
                    setPaginationLoader(false);
                }
            });
        }, 1000);

        return () => {
            clearInterval(timerSearch);
        }

        //getTeam(filterData);
    }, [page, filterText]);

    //React.useEffect(() => {
    //    console.log(userList);
    //}, [userList]);

    return (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "3vh" }}>
            <Transition visible={isLoading} animation='scale' duration={500}>
                <div>
                    <Loader size={"huge"} active inline='centered' />
                </div>
            </Transition>
            <Transition visible={!isLoading} animation='scale' duration={500}>
                <div style={styles.container} id="managementContainer">
                    <div style={styles.heading}>
                        <div style={styles.managementHeader}>
                            {roleNameCookie == "HumanResources"
                                ?
                                translate("İK Sorumlusu Olduğunuz Çalışanlar:")
                                :
                                roleNameCookie == "Manager"
                                    ?
                                    translate("Yöneticisi Olduğunuz Çalışanlar:")
                                    :
                                    roleNameCookie == "ItManager"
                                        ?
                                        translate("BT Sorumlusu Olduğunuz Çalışanlar:")
                                        :
                                        roleNameCookie == "AdminManager" ?
                                            translate("İdari İşler Sorumlusu Olduğunuz Çalışanlar:")
                                            :
                                            roleNameCookie == "PersonalAffairs" ?
                                                translate("Özlük İşler Sorumlusu Olduğunuz Çalışanlar:") :
                                                translate("Buddy'si Olduğunuz Çalışanlar:")
                            }
                        </div>
                        <div style={styles.searchContainer}>

                            <Popup
                                content={DataHandler.getUserDataWithKey("adminFullName")}
                                trigger={isLogin && forceLogin &&
                                    <Button circular onClick={() => { DataHandler.bringOldLogin(); }} size={"big"} color={"purple"} icon>
                                        <Icon name='long arrow alternate left' />
                                    </Button>
                                }
                            />
                            <Input
                                icon='search'
                                loading={paginationLoader}
                                size="huge"
                                placeholder={translate("Arama..")}
                                className="greyInput50"
                                onChange={e => setFilterText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.keyCode == 13) {
                                        getTeam({
                                            filterText: filterText, page: 1,
                                            roleName: DataHandler.getUserDataWithKey("userRole")
                                        })
                                    }
                                }} />
                        </div>
                    </div>

                    <div>
                        Toplam Kayıt: {teamCount >= 15 ? "15" : teamCount}/{teamCount}
                    </div>
                    <div className={"buddyUsersContainer"}>
                        {userList?.map((user) => (
                            <div key={user.userId} className="buddyUserContainer">
                                <div className={"buddyUserContainerLeftSide"}>

                                    <Image
                                        circular={true}
                                        id={"manageHomeUserPhoto"}
                                        className={"buddyUserProfilePicture"}
                                        src={user.photo != null ? user.photo : DefaultProfileImage}
                                    // style={user.photo == null ? styles.defaultPhoto : styles.userPhoto} 
                                    />
                                    <div className="textContainer">
                                        <div className="leftSideHeader">
                                            {user.nameSurname}
                                        </div>
                                        <div className="leftSideText">
                                            {user.workStartDate} <br />
                                            {translate("İşe Başlama Tarihi")}
                                        </div>
                                    </div>
                                </div>
                                {/*<Transition animation={'zoom'} duration={300} visible={user.page != 2}>*/}
                                {user.page != 2 &&
                                    <div className={"buddyUserCardRightContent"}>
                                        <div className="buddyProgressContainer">
                                            <Popup
                                                style={{ width: "100%" }}
                                                content={user.complatetionRate == 100 ? translate("Tamamlamış") : translate("Tamamlamamış")}
                                                trigger={
                                                    <Progress
                                                        style={{
                                                         paddingLeft: "2%"
                                                        }}
                                                        className={"buddyUserContainerProgress"}
                                                        size={"large"}
                                                        percent={user.complatetionRate} indicating progress
                                                    />
                                                }
                                            />
                                        </div>


                                        <div style={styles.userGroupedInfo}>
                                            <div className="buddyText1">
                                                {translate("Çalışanın kayıtlı olduğu modül: ")} {user.registeredModuleCount}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="buddyText1">
                                                <div
                                                    className={"buddyUserContainerTitle"}>
                                                    {translate("Toplam göreviniz:")} {user?.userContentRate?.split("/")[1]}
                                                </div>

                                                <div
                                                    className={"buddyUserContainerTitle mt3"}>
                                                    {translate("Tamamladığınız görev:")} {user?.userContentRate?.split("/")[0]}
                                                </div>

                                                <div className={"mt3"}>{user.complatetionRate == 100 && translate("Tamamladı") /*: user.userContentRate*/}</div>
                                            </div>
                                            <Button
                                                color={user.complatetionRate == 100 ? "green" : "orange"}
                                                onClick={() => {
                                                    changeUserPage(user, 2, "page")
                                                }}
                                                style={styles.marginTop15}
                                                circular={true}
                                                icon
                                                labelPosition='left'
                                            >
                                                <Popup
                                                    style={{ width: "100%" }}
                                                    content={user.complatetionRate == 100 ? translate("Tüm görevlerinizi tamamladınız") : "Görevleriniz tamamlanmadı"}
                                                    trigger={
                                                        <Icon name={"list layout"} />
                                                    }
                                                />
                                                {translate("Modüllere Git")}
                                            </Button>
                                        </div>
                                    </div>
                                }
                                {user.page == 2
                                    &&
                                    <div className={"buddyUserCardRightContent"}>
                                        <div style={styles.moduleList} >
                                            {user.moduleList.slice(user.paginationPage ? 2 * (user.paginationPage) : 0, user.paginationPage ? user.paginationPage * 2 + 2 : 2)
                                                .map((module, index) => (
                                                    <div key={index} style={styles.dikey}>
                                                        <div style={styles.moduleHeader}>
                                                            {module.moduleName}
                                                        </div>
                                                        <div style={styles.yatay}>
                                                            <Progress
                                                                className={"buddyUserContainerProgress"}
                                                                size={"large"}
                                                                style={{
                                                                    ...styles.moduleProgress, paddingLeft:"2%"
                                                                }}
                                                                percent={module.complatetionRate} indicating progress
                                                            />
                                                            <Button
                                                                color={user.complatetionRate == 100 ? "green" : "orange"}
                                                                onClick={() => {
                                                                    navigate("/course/management/" + module.id);
                                                                }}
                                                                circular={true}
                                                                icon
                                                                labelPosition='left'
                                                            >
                                                                <Popup
                                                                    style={{ width: "100%" }}
                                                                    content={user.complatetionRate == 100 ? translate("Tüm görevlerinizi tamamladınız") : translate("Görevleriniz tamamlanmadı")}
                                                                    trigger={
                                                                        <Icon name={user.complatetionRate == 100 ? "checkmark" : "clock"} />
                                                                    } />

                                                                {translate("Görevlere Git")}
                                                               
                                                                <div style={{marginTop:"5%"}}>{module.userContentRate}</div>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div style={styles.dikey}>
                                            <BuddyPagination
                                                pageCount={user.moduleList.length / 2}
                                                page={user.paginationPage ? (user.paginationPage + 1) : 1}

                                                paginationLoader={false}
                                                entity={user}
                                                setterWithEntity={(page, entity) => { changeUserPage(entity, page - 1, "paginationPage") }}
                                            />
                                            <Button
                                                color={"blue"}
                                                onClick={() => {
                                                    changeUserPage(user, 1, "page")
                                                }}
                                                style={styles.marginTop15}
                                                circular={true}
                                                icon
                                                labelPosition='left'
                                                iconName={'left'}
                                            >
                                                <Popup
                                                    style={{ width: "100%" }}
                                                    content={user.complatetionRate == 100 ? translate("Tüm görevlerinizi tamamladınız") : translate("Görevleriniz tamamlanmadı")}
                                                    trigger={
                                                        <Icon name="arrow circle left" />
                                                    }
                                                />
                                                {translate("Geri")}
                                            </Button>

                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    <div style={styles.paginationContainer}>
                        <BuddyPagination
                            pageCount={pageCount}
                            page={page}
                            setter={setPage}
                            paginationLoader={paginationLoader}
                        />
                    </div>
                </div>
            </Transition>
        </div>
    );
}

export default ManagementHomePageV2;