import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import {Button, Checkbox, Input, Loader, Modal, TextArea, Transition} from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import {IDataTable, IDoc} from '../../../interfaces/interfaces'

import {
    prepareDataForTable,
    datableFilterChange,
    checkFieldsIsFilled,
    digitallicaConfirm
} from '../../../helper/HelperFunctions';
import {media, style} from 'typestyle';
import {toast} from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
interface Test {
    id?: string,
    testName?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const CertificateList = (props) => {
    var {translate} = TranslateHelper();
    const [photoThumbnail, setPhotoThumbnail] = useState(false);
    const [certificate, setCertificate] = useState({
        name: "",
        description: "",
        createUserId: DataHandler.getUserDataWithKey("userId"),
        tenantId: DataHandler.getUserDataWithKey("tenantId"),
        appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
    });
    const [image, setImage] = useState("");
    var _ = require('lodash');
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isLoading, setIsLoading] = useState<boolean>(true);


    let styles = {
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        },
        thumbnailPhoto: {
            width: "100%"
        }
    }

    //delete selected data

    const deleteCertificate = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz ?"),
            () => {
                let data = {
                    id: id
                }
                NetworkRequest("/api/Certificate/Delete", data).then((response) => {
                    ////
                    if (response.data.resultCode == 0) {
                        let result = response.data.detail;
                        getCertificates({
                            tenantId: tenantId,
                            checkTenants: true,
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                        });

                    }
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: response.data.resultCode == 0 ? 2500 : 10000
                    });
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const zoomPhoto = (id) => {
        let data = {id: id};
        NetworkRequest("/api/Certificate/GetById", data).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setImage(result.base64);
                setPhotoThumbnail(true);
            }
        });
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {

        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    //filter's every change
    useEffect(() => {
        getCertificates({
            tenantId: tenantId,
            checkTenants: true,
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
        });
    }, [tenantId,filterText,startingDate,dueDate,currentPage,isActive,isDeleted]);

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [

            translate("Adı"),
            translate("Metin Rengi"),
            translate("Yüklenme Tarihi"),
            translate("Yetkili Adı"),
            translate("Atanmış Modül Sayısı"),
            translate("İşlemler"),
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: [
            {
                icon: "zoom",
                desc: translate("Görüntüle"),
                color: "black",
                link: null,
                type: "zoom",
                function: zoomPhoto
            },
            {
                icon: "edit",
                desc: translate("Düzenle"),
                color: "blue",
                link: "/editCertificate",
                type: "edit",

            },
            {
                icon: "trash",
                desc: translate("Sil"),
                color: "red",
                link: null,
                type: "delete",
                function: deleteCertificate
            },

        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: null
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/addCertificate",
        setRowSelected: setRowSelected,
        noCheckBox:true,
        noFilter:true

    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response;

        let model = prepareDataForTable(data.certificateViews, [
            "id",
            "tenantId",
            "certificateId",
            "createUserId",
            "url",
            "appType",
            "certificateUrl",
            "base64",
            "textColorCode"
        ]);
        ////console.log("dataTableModel.filter", dataTableModel.filter);
        setDataTableModel({
            header: dataTableModel.header,
            data: [],
            transactions: dataTableModel.transactions,
            filter: dataTableModel.filter,
            pagination: {
                count: Math.ceil(data.count / 25),
                setCurrentPage: setCurrentPage, currentPage: currentPage
            },
            addRowLink: dataTableModel.addRowLink,
            noCheckBox:true,
            noFilter:true
        });
        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                pagination: {
                    count: Math.ceil(data.count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);
        setIsLoading(false);
    }

    useEffect(() => {
        ////
    }, [dataTableModel]);

    //get table data from database
    const getCertificates = (filterData?) => {
        setIsLoading(true);

        var filter = {
            tenantId: tenantId,
            checkTenants: true,
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
        }
        
        
        NetworkRequest("/api/Certificate/GetList", filter).then((response) => {
            ////
            if (response.data.resultCode == 0) {
                setCertificate(response.data.detail);
                prepareData(response.data.detail);
                setIsLoading(false);
            }
        });
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                                   selectAllHandler={selectAllHandler} isLoading={isLoading}/>
                    </div>
                </Transition>
                <Modal
                    onClose={() => setPhotoThumbnail(false)}
                    onOpen={() => setPhotoThumbnail(true)}
                    open={photoThumbnail}
                >
                    <Modal.Actions>
                        <Button style={{...styles.btnDark, width: "auto", height: "auto"}}
                                onClick={() => setPhotoThumbnail(false)}>{translate("Kapat")}</Button>
                    </Modal.Actions>
                    <Modal.Content style={{
                        display: "flex",
                        flexDirection: "column" as "column",
                        alignItems: "center",
                        marginTop: "-40%"
                    }} image>
                        <img style={styles.thumbnailPhoto} src={"data:image/png;base64," + image}/>
                    </Modal.Content>
                </Modal>
            </div>
        </div>
    );
}

export default CertificateList;