import React, {useEffect, useState, memo} from 'react';
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import {DataHandler} from "../../../helper/DataHelper";
import ModuleListCard from "./ModuleListCard";
import {v4 as uuidv4} from 'uuid';
import {Button, Input, Loader, Pagination, Popup} from "semantic-ui-react";
import TranslateHelper from "../../../helper/TranslateHelper";
import {DateFormat} from "../../../helper/DateFormat";
import {useNavigate} from "react-router-dom";
import NotFoundModuleMessage from "./NotFoundModuleMessage";

interface IModuleListByCategory {
    category?: string | null
}

const ModuleListByCategory = ({category}: IModuleListByCategory) => {
    var {translate} = TranslateHelper();
    const navigate = useNavigate();
    const [userId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [modules, setModules] = useState<any>([]);
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState<any>({
        filterText: filterText,
        currentPage: currentPage,
        startingDate: startingDate,
        dueDate: dueDate,
        status: category == "1" ? "newModules" : category == "3" ? "continueModules" : category == "5" ? "doneModules" : null
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isFilter, setIsFilter] = useState(false);

    const getModulesByCategory = (data) => {
        setIsFilter(true);
        NetworkRequest("api/ModuleUserRegistrations/GetAssignedsByFilter", data).then((response) => {
            if (response.data.resultCode == 0) {
                setModules(response.data.detail)
                setIsLoading(false);
                setIsFilter(false);
            } else {
                toast(translate(response.data.message), {type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500});
                setIsLoading(false);
                setIsFilter(false);
            }
        });
    }

    useEffect(() => {

        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            startingDate: startingDate,
            dueDate: dueDate,
            status: category == "1" ? "newModules" : category == "3" ? "continueModules" : category == "5" ? "doneModules" : null
        }

        let data = {
            id: userId,
            pagination: filterData
        }

        getModulesByCategory(data);
    }, [category])

    useEffect(() => {
        const timer = setTimeout(() => {
            let filterData = {
                filterText: filterText,
                currentPage: currentPage,
                startingDate: startingDate,
                dueDate: dueDate,
                status: category == "1" ? "newModules" : category == "3" ? "continueModules" : category == "5" ? "doneModules" : null
            }

            let data = {
                id: userId,
                pagination: filterData
            }

            getModulesByCategory(data);
        }, 2000);

        return () => clearTimeout(timer);

    }, [filterText, currentPage, startingDate, dueDate])

    return (
        <>
            <div className={"moduleListViewContainer"}>
                {isLoading && <div className={"mt2"}><Loader active inline='centered'/></div>}
                {!isLoading && <>
                    <div className={"moduleListByCategoryFilterBox"}>

                        <Popup content={translate("Geri dön")} trigger={
                            <Button  circular={true} onClick={(e) => {
                                navigate("/home")
                            }} color={"violet"} icon='long arrow alternate left'/>
                        }/>

                        <Input
                            onChange={(e) => {
                                setFilterText(e.target.value)
                            }}
                            value={filterText}
                            loading={isFilter}
                            className={"greyInput"}
                            icon='search'
                            placeholder={translate("Ara..")}
                        />

                        <Popup content={translate("Başlangıç tarihi")} trigger={
                            <Input disabled={isFilter}
                                   value={startingDate != null ? DateFormat(startingDate, true) : ""}
                                   className={"greyInput"}
                                   onChange={(e) => {
                                       var lastValue: any = e.target.value;
                                       lastValue = e.target.value == "" ? undefined : e.target.value;
                                       setStartingDate(lastValue)
                                   }}
                                   type={"date"}/>
                        }/>

                        <Popup content={translate("Bitiş tarihi")} trigger={
                            <Input disabled={isFilter} value={dueDate != null ? DateFormat(dueDate, true) : ""}
                                   className={"greyInput"}
                                   onChange={(e) => {
                                       var lastValue: any = e.target.value;
                                       lastValue = e.target.value == "" ? undefined : e.target.value;
                                       setDueDate(lastValue)
                                   }}
                                   type={"date"}/>
                        }/>


                    </div>

                    {modules.list != undefined && modules.list.map((item, index) => (
                        <ModuleListCard
                            key={uuidv4().toString()}
                            cardTitle={item.courseTitle}
                            cardDesc={item.courseDescription}
                            videoCount={item.videoCount}
                            htmlCount={item.htmlCount}
                            testCount={item.testCount}
                            surveyCount={item.surveyCount}
                            taskCount={item.taskCount}
                            registerDate={item.registerDate}
                            courseTotalSection={item.courseTotalSection}
                            courseTotalContent={item.courseTotalContent}
                            link={"/course/" + item.moduleId + "/" + item.registrationId}
                            status={item.status}
                            isDone={item.isDone}
                            progress={item.status == "newModules" ? 0 : item.status == "continueModules" ? item.complatetionRate : 100}
                        />
                    ))}
                </>
                }

                {modules.count == 0 && <NotFoundModuleMessage />}

            </div>

            {modules.count > 0 &&
                <Pagination
                    className={"mt3 ml2"}
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={Math.ceil(modules.count / 25)}
                    onPageChange={
                        (event, data) => {
                            setFilterData({...filterData, currentPage: Number(data.activePage)});
                        }
                    }
                />
            }
        </>

    );
};

export default memo(ModuleListByCategory);