import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Loader} from "semantic-ui-react";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";

import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from '../../../helper/TranslateHelper';

const UserSettings = () => {

    var { translate } = TranslateHelper();
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getUserFirstEntryPasswordChangeMustSetting = () => {
        setIsLoading(true);
        let data = {
            key: "FirstEntryPasswordChangeMust",
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            // value: isChecked
        };

        NetworkRequest("/api/Settings/GetSetting", data).then((response) => {
            //
            if (response.data.resultCode === 0) {
                if (response.data.detail != null) {
                    setIsChecked(response.data.detail.settingValue == "1" ? true : false);
                }
            }

            setIsLoading(false);
        });
    }

    const saveUserSettings = () => {

        setIsLoading(true);
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId"),
            Key: "FirstEntryPasswordChangeMust",
            Value: isChecked ? "1" : "0"
        }
        NetworkRequest("api/Settings/AddUserFirstEntryPasswordChangeMustSetting", data).then((response) => {
            if (response.data.resultCode === 0) {
                toast(translate(response.data.message), {type: 'success', theme: 'dark', position: 'top-center', autoClose: 3500});
            } else {
                toast(translate(response.data.message), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 3500});
            }
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getUserFirstEntryPasswordChangeMustSetting();
    }, []);

    useEffect(() => {
        //console.log(isChecked)
    }, [isChecked])

    return (
        <div style={{display: "flex", flexDirection: "column", gap: "1em"}}>
            {!isLoading ?
                <>
                    <Checkbox
                        label={translate("ilk girişte şifreyi yenilemeyi zorunlu tut")}
                        defaultChecked={isChecked}
                        onChange={(e, data) => {
                            setIsChecked(data.checked != undefined ? data.checked : false)
                        }}
                    />

                    <Button style={{width: "100px"}} circular={true} color={"green"}
                        onClick={saveUserSettings}>{translate("Kaydet")}</Button>
                </>
                :
                <Loader size="huge" active inline='centered'/>
            }

        </div>
    );
};

export default UserSettings;