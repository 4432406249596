import React, {useContext} from 'react';
//store import
import {translationListContext, selectedLanguageContext} from "../Store";
import {DataHandler} from './DataHelper';
import {isNullOrEmpty} from './HelperFunctions';

export const TranslateHelper = () => {
    let selectedLanguage = useContext(selectedLanguageContext);
    let translationList = useContext(translationListContext);

    const translate = (word: string) => {
        if (isNullOrEmpty(word)) {
            return word;
        }

        if (selectedLanguage[0] == null || selectedLanguage[0].key == "tr-TR" || selectedLanguage[0] == undefined) {
            //DataHandler.addNotTranslatedWord(word);
            return word;
        }

        var filterTranslations = translationList[0].filter(
            t => t.languageId == selectedLanguage[0].languageId);

        if (filterTranslations == null || filterTranslations.length == 0) {
            //DataHandler.addNotTranslatedWord(word);
            return word;
        }

        filterTranslations = filterTranslations[0].translationViewModels;

        var translatedWords = filterTranslations.filter(sl => sl.word.toLowerCase().trim() == word.toLowerCase().trim());

        if (translatedWords.length == 0) {
            //DataHandler.addNotTranslatedWord(word);
            return word;
        }

        return translatedWords[0].translatedWord.toString();
    }

    const translateFromEng = (word: string) => {
        if (isNullOrEmpty(word)) {
            return word;
        }


        if (selectedLanguage[0] == null || selectedLanguage[0].key == "en-US" || selectedLanguage[0] == undefined) {
            //DataHandler.addNotTranslatedWord(word);
            return word;
        }

        var filterTranslations = translationList[0].filter(
            t => t.languageId == selectedLanguage[0].languageId);

        if (filterTranslations.length == 0 && selectedLanguage[0].key == "tr-TR") {
           
            //TODO:English language id will not be written as hard coded.
            filterTranslations = translationList[0].filter(
                t => t.languageId ==  "2f079486-e8cb-44b8-b187-5285fc841b4c");

            if (filterTranslations != null && filterTranslations.length > 0) {

                var filteredWord = filterTranslations[0].translationViewModels.filter(
                    w => w.translatedWord.toLowerCase().trim() == word.toLowerCase().trim()
                );

                // debugger;
                if (filteredWord.length > 0)
                    return filteredWord[0].word?.toString();

                return word;
            }
        }

        if (filterTranslations == null || filterTranslations.length == 0) {
            //DataHandler.addNotTranslatedWord(word);
            return word;
        }

        filterTranslations = filterTranslations[0].translationViewModels;

        var translatedWords = filterTranslations.filter(sl => sl.word.toLowerCase().trim() == word.toLowerCase().trim());

        if (translatedWords.length == 0) {
            //DataHandler.addNotTranslatedWord(word);
            return word;
        }

        return translatedWords[0].translatedWord.toString();
    }

    return {translate, translateFromEng}
}

export default TranslateHelper;