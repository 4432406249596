import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TabPane, Tab, Input, Button } from 'semantic-ui-react'
import { DataHandler } from '../../../helper/DataHelper';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';


const CanvaSettingsComp = () => {

    var { translate } = TranslateHelper();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));

    const [CLIENT_ID, setClientId] = useState("");
    const [CLIENT_SECRET, setClientSecret] = useState("");
    const [REDIRECT_URI, setRedirectUri] = useState("");

    const saveCanvaSettings = () => {
        let canvaData = {
            ClientID: CLIENT_ID,
            ClientSecret: CLIENT_SECRET,
            TenantID: tenantId
        };

        NetworkRequest("/api/Settings/SaveCanvaData", canvaData).then((response) => {
            if (response.data.resultCode === 0) {
                toast(translate("Ayar kaydedildi."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 10000 });
            } else {
                toast(translate("Bir hata meydana geldi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 10000 });

            }
        });
    }

    const getRedirectUri = () => {
        NetworkRequest("/api/Settings/GetFrontendUri").then((response) => {
            if (response.data.resultCode === 0) {
                setRedirectUri(response.data.detail + "addCertificate");
            }
        });
    }

    const getCanvaSettings = () => {
        let canvaData = {
            Key:"Canva",
            TenantID: tenantId
        };

        NetworkRequest("/api/Settings/GetSetting", canvaData).then((response) => {
            if (response.data.resultCode === 0) {
                let data = response.data.detail;
           
                if (data != null) {
                    let jsonObject = JSON.parse(data.settingValue);

                    setClientId(jsonObject?.ClientID);
                    setClientSecret(jsonObject?.ClientSecret);
                }
                
            }
        });
    }

    useEffect(() => {
        getRedirectUri();
        getCanvaSettings();
    }, [])

    return (
        <div className="canvaSettingContainerClass">
            <div>
                <Input
                    onChange={(e) => { setClientId(e.target.value) }}
                    value={CLIENT_ID}
                    label='CLIENT_ID:'
                    fluid
                    className="greyInput"
                    placeholder="CLIENT_ID"
                />
            </div>

            <div>
                <Input
                    onChange={(e) => { setClientSecret(e.target.value) }}
                    value={CLIENT_SECRET}
                    label='CLIENT_SECRET:'
                    fluid
                    className="greyInput"
                    placeholder="CLIENT_SECRET"
                />
            </div>

            <div>
                <Input
                    className="greyInput"
                    label='REDIRECT_URI:'
                    fluid
                    action={{
                        color: 'teal',
                        labelPosition: 'right',
                        icon: 'copy',
                        content: translate('Kopyala'),
                    }}
                    value={REDIRECT_URI }
                />
            </div>

            <div>
                <Button onClick={saveCanvaSettings} circular color="green">{translate("Kaydet")}</Button>
            </div>
        </div>
    );
};

export default CanvaSettingsComp;