import React, { useContext, useState } from 'react';
import { IFixedBottomMenuElememt } from "../../../../interfaces/interfaces";
import { Button, Header, Icon, Image, Modal, Transition } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

import { rightSlidePanelMobileContext } from "../../../../StoreUserDashboard";
import { useNavigate } from "react-router-dom";
import useSound from 'use-sound';
import PickSound from "../../files/sounds/pickSound.mp3";
import TranslateHelper from "../../../../helper/TranslateHelper";
import { displayProtectedAppDoc } from '../../../../helper/DisplayProtectedDocument';
import { logoutApi } from '../../../../helper/HelperFunctions';
import { DataHandler } from '../../../../helper/DataHelper';

const FixedBottomMenuElement = (props: IFixedBottomMenuElememt) => {

    const [playSound] = useSound(PickSound, { volume: 0.20 });
    const navigate = useNavigate();
    const [isOpenExitModal, setIsOpenExitModal] = useState(false);
    const [userPhoto, setUserPhoto] = useState(DataHandler.getUserDataWithKey("photo"));
    const [documentManagerUrl, setDocumentManagerUrl] = useState(DataHandler.getDocmanagerUrl());
    const { isOpenRightSlidePanelMobile, setIsOpenRightSlidePanelMobile } = useContext(rightSlidePanelMobileContext);

    var { translate } = TranslateHelper();

    const styles = {
        userContainer: {
            display: "flex",
            alignItems: "center",
            gap: ".3em"
        },

        profilePicture: {
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            border: isOpenRightSlidePanelMobile ? "dashed 3px white" : "dashed 2px white",
            padding: "2px",
            transition: "all .5s",
            filter: isOpenRightSlidePanelMobile ? "opacity(1.0)" : "opacity(0.7)"
        },

        profilePictureContainer: {
            display: "flex",
            width: "auto"
        }
    }

    const clickElementHandler = (icon: SemanticICONS) => {

        props.SetSelectedElement != undefined && props.SetSelectedElement(icon)

        if (icon.toString() == "user") {
            props.SetShowFabIcon(!props.ShowFabIcon);
            setIsOpenRightSlidePanelMobile(!isOpenRightSlidePanelMobile);
        }
        else if (icon.toString() == "tasks") {
            setIsOpenRightSlidePanelMobile(false);
            props.SetShowFabIcon(false);
            props.SetIsOpenTaskContentForMobile(!props.IsOpenTaskContentForMobile)
        }

        if (typeof props.Link == "function" && icon.toString() == "paint brush") {
            props.SetIsOpenTaskContentForMobile(false);
            setIsOpenRightSlidePanelMobile(false);
            props.SetShowFabIcon(!props.ShowFabIcon);
        } else {
            props.SetShowFabIcon(true);
        }
    }

    const renderMenuElement = (isProfileElement: boolean) => {
        if (isProfileElement) {
            return (
                <>
                    <Image
                        id="fixedBotProfile"
                        onClick={() => {
                            setIsOpenRightSlidePanelMobile(!isOpenRightSlidePanelMobile)
                        }}
                        style={styles.profilePicture}
                        source={displayProtectedAppDoc(userPhoto, "fixedBotProfile")}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =displayProtectedAppDoc("/Documents/DefaultUserImage/user.png", "fixedBotProfile")
                        }}
                    />
                </>
            )
        }

        return (
            <>
                <div className={"fixedBottomMenuElementIcon"}>
                    <Icon
                        style={{
                            color: props.Color,
                            filter: props.IsSelected ? "opacity(0.9)" : "opacity(0.5)"
                        }}
                        size="large" name={props.Icon} />
                </div>
                <div
                    style={{
                        color: props.Color,
                        filter: props.IsSelected ? "opacity(0.9)" : "opacity(0.5)"
                    }}
                    className="fixedBottomMenuElementText">
                    {props.Text}
                </div>
            </>
        )
    }

    const logoutMethod = () => {
        logoutApi();
        ////debugger;
        DataHandler.logOutUser();
        navigate('/homeLogin');
    }

    return (
        <>
            <div 
                style={{width:props.Location == "/userdashboard" ? "20%":"100%"}}
                onClick={() => {
                    playSound();
                    if (props.Icon == "sign-out") {
                        setIsOpenExitModal(true);
                        return;
                    }
                    clickElementHandler(props.Icon);
                }}
                className={props.IsSelected ? "fixedBottomMenuElementActive fixedBottomMenuElementContainer" : "fixedBottomMenuElementContainer"}
            >
                <Transition visible={typeof props.Link == "function" && ((!props.ShowFabIcon || props.IsOpenTaskContentForMobile) && props.IsSelected)}
                    animation='scale'
                    duration={500}
                >
                    <div className="greenPointBottomMenu"><Icon size="mini" name="circle" color="green" /></div>
                </Transition>
                {renderMenuElement(props.IsProfileElement)}
            </div>
            {props.Icon == "sign-out"
                &&
                <Transition
                    visible={isOpenExitModal}
                    animation='scale'
                    duration={500}
                >
                    <Modal
                        className="fixedBottomMenuModal"
                        style={{ textAlign: "center", width: "85%" }}
                        size="mini"
                        open={isOpenExitModal}
                    >
                        <Modal.Header style={{ border: "none", borderRadius: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Icon className="fixedBottomMenuLogoutHand" size="small" name="hand paper" color="yellow" />
                            </div>
                        </Modal.Header>
                        <Modal.Content>
                            <p>{translate("Çıkış yapmak istediğinizden emin misiniz?")}</p>
                        </Modal.Content>
                        <Modal.Actions style={{ border: "none", textAlign: "center", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
                            <Button className="roundedButton" color="grey" onClick={() => setIsOpenExitModal(false)}>
                                {translate("İptal")}
                            </Button>
                            <Button className="roundedButton" color="red" onClick={() => logoutMethod()}>
                                {translate("Çık")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>
            }
        </>

    );
};

export default FixedBottomMenuElement;