import React, {useState} from 'react';
import {Button, Grid, Image, Input} from 'semantic-ui-react';
import HomeV2Footer from "../HomeV2Components/HomeV2Footer";
import HomeV2Header from "../HomeV2Components/HomeV2Header";
import CustomerBannerHearth from '../../../images/home/v2/customer-banner-hearth.png'
import CustomerBannerShape from '../../../images/home/v2/customer-banner-shape.png'
import {v4 as uuidv4} from 'uuid';
import '../Home.css';
import Logo1 from '../../../images/home/v2/Picture4.png';
import Logo2 from '../../../images/home/v2/Picture5.jpg';
import Logo3 from '../../../images/home/v2/Picture6.png';
import Logo4 from '../../../images/home/v2/Picture7.png';
import Logo5 from '../../../images/home/v2/Picture8.png';
import Logo6 from '../../../images/home/v2/Picture9.png';
import Logo7 from '../../../images/home/v2/Picture10.png';
import Logo8 from '../../../images/home/v2/Picture11.png';
import Logo9 from '../../../images/home/v2/Picture12.jpg';
import Logo10 from '../../../images/home/v2/Picture13.png';
import Logo11 from '../../../images/home/v2/Picture14.png';
import Logo12 from '../../../images/home/v2/Picture15.png';
import Logo13 from '../../../images/home/v2/Picture16.png';
import Logo14 from '../../../images/home/v2/Picture17.png';
import Logo15 from '../../../images/home/v2/Picture18.png';
import Logo16 from '../../../images/home/v2/Picture19.jpg';
import {toast} from "react-toastify";
import TranslateHelper from "../../../helper/TranslateHelper";
import {useNavigate} from "react-router-dom";
import {isLandscapeTablet} from "../../../helper/HelperFunctions";

const Customers = () => {

    const navigate = useNavigate();
    var {translateFromEng} = TranslateHelper();
    const companyImageList: Array<any> = [
        Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10, Logo11, Logo12, Logo13, Logo14, Logo15, Logo16
    ]
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");

    const getStarted = () => {
        if (email === "" || companyName === "") {
            toast(translateFromEng("Please fill in all fields"), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
            return
        }

        navigate('/newTenant?email=' + email + '&company=' + companyName);
    }

    return (
        <Grid className={"homeV2Container"} columns={16}>
            <HomeV2Header/>

            <Grid.Row columns={3} className="homeV2CustomersBannerHeader">
                <Grid.Column computer={isLandscapeTablet() ? 16 : 5} mobile={16} className="homeV2BannerTextCustomersSectionContainer">
                    <Grid.Row centered>
                        <Grid.Column className="homeV2BannerCustomersTitle">
                            <div>{translateFromEng("Love at first sight")}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <div
                                className="homeV2BannerText homeV2BannerTextCustomers mt4">{translateFromEng("Hundreds of organizations trust us to lead the way")}</div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column computer={isLandscapeTablet() ? 16 : 6} className={"pricingPageBannerContainer"} style={{height: "100%"}} mobile={16}>
                    <Image className={"customersPageBannerImageClass"}
                           style={{width: "100%", objectFit: "cover", height: "100%"}} src={CustomerBannerHearth}/>
                </Grid.Column>
                <Grid.Column computer={5} className={"CustomerPageBannerContainer CustomerPageShapeBannerContainer"}
                             style={{height: "100%"}} mobile={16}>
                    <Image style={{width: "100%", objectFit: "cover", height: "100%"}} src={CustomerBannerShape}/>
                </Grid.Column>
            </Grid.Row>

            <div className={"customerPageCompanySection"} style={{paddingBottom: "6%"}}>
                {companyImageList.map((item) => (
                    <div key={uuidv4()} className="customersImageContainer">
                        <img className={"customersImage"} src={item} alt=""/>
                    </div>
                ))}
            </div>

            <Grid.Row centered className="homeV2GetStartedContent">

                <Grid.Column computer={12} textAlign="center" mobile={13} tablet={16}>
                    <Grid.Column className="homeV2BannerTitle">
                        <div>{translateFromEng("Start Building Your Own Onboarding Path")}</div>
                    </Grid.Column>
                </Grid.Column>

                <Grid.Column computer={12} textAlign="left" mobile={13} tablet={16}>
                    <div className="homeV2GetStartedContainer">

                        <div className="homeV2GetStartedGeneralBox">
                            <div className="homeV2GetStartedBox">
                                <Input value={companyName} onChange={(e) => {
                                    setCompanyName(e.target.value)
                                }} className="homeV2Input" type="text" placeholder="Company Name" size="huge"/>
                                <Input value={email} onChange={(e) => {
                                    setEmail(e.target.value)
                                }} className="homeV2Input" type="mail" placeholder="E-mail" size="huge"/>
                                <Button onClick={getStarted} className="homeV2GetStartedButton" size="huge"
                                        circular>{translateFromEng("Get Started")}</Button>
                            </div>
                            <div>
                                <span
                                    className="homeV2GetStatedInfoSection">* {translateFromEng("No credit card required")}</span>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <HomeV2Footer/>
        </Grid>
    );
};

export default Customers;