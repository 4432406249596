
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import { Input, Checkbox, Button, Card } from 'semantic-ui-react';
import IQuestion from '../../../interfaces/IQuestion';
import { checkFieldsIsFilled, digitallicaConfirm, extractAllElements, getQuestionContentForRender } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import EditorComponent from '../../../components/EditorComponent';
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
import QuillEditor from '../../../components/Quill/QuillEditor';

const QuestionAdd = (props) => {
    let navigate = useNavigate();
    var {translate} = TranslateHelper();
    let { quizId } = useParams();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [question, setQuestion] = useState<IQuestion>({
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        CorrectAnswer: ""
    });
    const [quizIdHook, setQuizIdHook] = useState<any>(quizId);
    /*    const [editorContent, setEditorContent] = useState("");*/
    const [editorContent, setEditorContent] = useState({
        embedCode: "",
        embedValue: ""
    });
    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [options, setOptions] = useState(["A", "B", "C", "D", "E"]);
    const [checkedOption, setCheckedOption] = useState("");
    const [isFormOk, setIsFormOk] = useState(false);
    const [test, setTest] = useState({ title: "", description: "" });

    let styles = {
        questionAddContainer: {
            width: "60%",
            margin: "0 auto",
            marginTop: "3%",
            height: "auto",
            padding: "3%"
        },
        optionsContainer: {
            display: "flex",
            gap: "1em",
            flexDirection: "column" as "column",
            marginTop: "20px",
            marginBottom: "20px"
        },
        option: {
            display: "flex",
            width: "100%",
            gap: "2em",
            alignItems: "center",
            justifyContent: "center"
        },
        input: {
            width: "100%"
        }
    }

    const handleChangeOption = (e) => {
        switch (e.target.name) {
            case "A":
                setQuestion({ ...question, A: e.target.value })
                break;
            case "B":
                setQuestion({ ...question, B: e.target.value })
                break;
            case "C":
                setQuestion({ ...question, C: e.target.value })
                break;
            case "D":
                setQuestion({ ...question, D: e.target.value })
                break;
            case "E":
                setQuestion({ ...question, E: e.target.value })
                break;
            default:
        }
    }

    const correctOptionChange = (e, { value }) => {
        setCheckedOption(value);
        question.CorrectAnswer = value;
        setQuestion(question);
    }

    const saveQuestion = (type) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let questionData = {
                questionJSON: JSON.stringify(question),
                tenantId: tenantId,
                userId: DataHandler.getUserDataWithKey("userId")
            }
            NetworkRequest("/Question/Add", questionData).then(response => {
                if (response.data.resultCode === 0) {
                    toast(translate("Soru başarılı bir şekilde kaydedildi"), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                    let result = response.data.detail;
                    if (!(quizIdHook == "" || quizIdHook == undefined)) {
                        attachQuestionToQuiz(result.id);
                    }
                    if (type === 1) {
                        setEditorContent({ embedCode: "", embedValue: "" });
                        setCheckedOption("");
                        setQuestion({
                            Body: "",
                            CorrectAnswer: "",
                            A: "",
                            B: "",
                            C: "",
                            D: "",
                            E: ""
                        });
                    } else {
                        navigate("/questions");
                    }
                } else {
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const attachQuestionToQuiz = (questionId: string) => {
        let quizQuestion = {
            questionId: questionId,
            quizId: quizIdHook
        };

        NetworkRequest("/api/QuestionAndQuiz/Add", quizQuestion).then(response => {
            ////
            if (response.data.resultCode === 0) {
                toast(translate("Soru başarılı bir şekilde eklendi") + ": " + test.title, { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            } else {
                toast(translate(response.data.detail), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            }
        });
    }

    const getTestById = (testId: string) => {
        if (testId == null || testId == "" || testId == undefined)
            return;

        let testData = {
            id: testId
        }
        NetworkRequest("/Quiz/GetById", testData).then((response) => {
            if (response.data.resultCode === 0) {

                let result = response.data.detail;
                setTest({
                    ...test,
                    title: result.title,
                    description: result.description
                });
            }
        });
    }

    useEffect(() => {
        getTestById(quizIdHook);
    }, [quizIdHook]);

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                editorContent
            )
        );

        debugger;
        setQuestion({ ...question, Body: editorContent });
    }, [editorContent]);

    return (
        <div style={styles.questionAddContainer} className={"generalContainerForWhiteBg"}>
            <span>{translate("İçeriğe tablo veya resim ekleneceği zaman eni (width) maksimum 200 olmalıdır!")}</span>

            {/*<QuillEditor value={editorContent} setValue={setEditorContent} />*/}

            <QuillEditor
                value={getQuestionContentForRender(editorContent)}
                setValue={(value) => { setEditorContent({ embedCode: value, embedValue: extractAllElements(value) }) }}
            />

            <div style={styles.optionsContainer}>

                {options.map(option => (
                    <div
                        key={option}
                        style={styles.option}
                    >

                        <Checkbox
                            className={"greyInput"}
                            name='checkboxRadioGroup'
                            toggle
                            value={option}
                            checked={option == checkedOption}
                            onChange={(e, { value }) => correctOptionChange(e, { value })}
                        />

                        <Input
                            className={"greyInput"}
                            style={styles.input}
                            name={option}
                            fluid
                            label={{ content: option }}
                            labelPosition='left'
                            placeholder={option}
                            onChange={handleChangeOption}
                            value={question[option]}
                        />

                    </div>
                ))}
            </div>
            {!((quizIdHook == undefined) || (quizIdHook == "") || (quizIdHook == null)) &&
                <Card style={{ width: "100%" }}>
                    <Card.Content>
                        <Card.Header>{translate("Sınav Başlığı")}: {test.title}</Card.Header>
                        <Card.Description>
                            <b>{test.title}</b>{translate("sınavına yukarıda detaylarını girdiğiniz soru, sınava ve soru havuzuna eklenecektir. Sadece soru havuzuna eklemek istiyorsanız, eklemeden önce temizle butonuna tıklayınız.")}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <div style={{ padding: "0 .5rem" }}>
                            <Button basic color='red' onClick={() => setQuizIdHook(undefined)} >
                                {translate("Temizle")}
                            </Button>
                        </div>
                    </Card.Content>
                </Card>}
            <div style={{ textAlign: 'end' }}>
                <Button className={"roundedButton"} disabled={!isFormOk} color='blue' onClick={() => { saveQuestion(1) }}>{translate("Soruyu Kaydet ve Yeni Soru Ekle")}</Button>
                <Button className={"roundedButton"} disabled={!isFormOk} color='green' onClick={() => { saveQuestion(2) }}>{translate("Soruyu Kaydet ve Çık")}</Button>

            </div>
        </div>
    );
}

export default QuestionAdd;