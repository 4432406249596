import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Checkbox, Dropdown, Header, Icon, Input, Loader, Popup, Table, Transition } from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import { ICommonReportPage, IDataTable } from '../../../interfaces/interfaces'
import { downloadFile, prepareDataForTable } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Users {
    id?: string,
    title?: string,
    description?: string,
    createdDate?: Date
}

const UserReport = (props) => {

    var { translate } = TranslateHelper();
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [filterText, setFilterText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isAccepted, setIsAccepted] = useState<boolean>(true);
    const [filterData, setFilterData] = useState<object>({});
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const onChangeAccepted = (e, data) => {
        setIsAccepted(data.checked);
    }


    let tableHeader = [
        translate("E Posta"),
        translate("Ad Soyad"),
        translate("KVKK Kabul"),
    ];
    let hiddenProps = [
        'userId'
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(4, 0, translate("�irket"));
    } else {
        hiddenProps.push("tenantName");
    }
    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Kabul edenler"),
                    defaultValue: isAccepted,
                    key: "isAccepted",
                    type: "boolean",
                    onChange: onChangeAccepted
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        addRowLink: "",
        isAttachingPage: true
    });

    const getExcel = () => {
        const filter = {
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1",
        }
        NetworkRequest("api/Report/GetTOUCResponsesForAllUsersExcel", filter).then(response => {
            downloadFile(response.data, translate("KVKK Raporu"));
        });
    }

    //prepare data for table
    const prepareData = (data) => {

        //hidden column
        var model = prepareDataForTable(data.list, hiddenProps);
        setTimeout(() => {
            setDataTable({
                header: isAccepted ? [...tableHeader, translate("Kabul tarihi")] : tableHeader,
                transactions: dataTable.transactions,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Kabul edenler"),
                            defaultValue: isAccepted,
                            key: "isAccepted",
                            type: "boolean",
                            onChange: onChangeAccepted
                        }
                    ]
                },
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox,
                data: model,
                pagination: {
                    count: Math.ceil(
                        data.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });

            setIsLoading(false);
        }, 200)
    }


    //filter's every change
    useEffect(() => {
        setIsLoading(true);
        let filterDataObj = {
            filterText: filterText,
            currentPage: currentPage,
            isAccepted: isAccepted,
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1",
            take: 25,
            skip: (currentPage - 1) * 25,
        };
        //setFilterData(filterDataObj);
        //getUsers(filterDataObj);


        const checkPasswordTimer = setTimeout(() => {
            NetworkRequest("api/Report/GetTOUCResponsesForAllUsers", filterDataObj).then(response => {

                if (response.status == 200) {
                    prepareData(response.data);
                }
            });
        }, 1000);

        return () => clearTimeout(checkPasswordTimer)


    }, [filterText, currentPage, isAccepted, props.selectedTenantId]);




    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered' />
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable
                            dataTable={dataTable}
                            isAllSelected={isAllSelected}
                            selectAllHandler={() => { }}
                            exportExcelFunction={() => { getExcel() }}
                            isLoading={isLoading}
                        />
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default UserReport;