import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import { DataHandler } from '../helper/DataHelper';
import { NetworkRequest } from '../helper/NetworkRequest';
import TranslateHelper from '../helper/TranslateHelper';
import DataTable from './DataTable';


const DashboardReportDatatable = (props) => {
    var { translate } = TranslateHelper();
    const styles = {
        datatableRow: {
            display: "flex",
            flexDirection: "column" as "column",
            marginTop: "1.7%"
        },
        datatableHeader: {
            // background: "#F3F3F3",
            // borderRadius: "5px",
            // padding: "8px 10px",
            fontWeight: "bold",
            letterSpacing: "1px",
            color: "#6c6c6c",
            fontSize: "1.5em"
        },
        table: {
            display: "flex",
            flexDirection: "column" as "column",
            margin: "5px 0px"
        },
        tableRow: {
            display: "flex",
            flexDirection: "row" as "row"
        },

        rowHeader: {
            fontSize: "2.3em",
            color: "#565656",
            marginTop: "9%",
            width: "100%",
            textAlign: "right" as "right"
        },
        rowTitle: {
            color: "#565656",
            fontSize: "1.3em",
            width: "100%",
            textAlign: "right" as "right"
        },
        dataContainer: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        negativeColor: {
            color: "#a94038"
        },
        positiveColor: {
            color: "#7abe7a"
        }
    }
    const [loader, setLoader] = useState(true);
    const [reportModel, setReportModel] = useState<any>();

    const [toucResponse, setToucResponse] = useState({ acceptedCount: 0, notAcceptedCount: 0, acceptanceRate: 0, notificationExists: false });

    useEffect(() => {
        let filterDataObj = {
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            isAll: props.selectedTenantId == "1",

        };
        NetworkRequest("api/Report/GetTOUCResponsesCountForAllUsers", filterDataObj).then(response => {
            setLoader(false);
            if (response.status == 200) {
                setToucResponse(response.data.detail);
            }
        });

        setTimeout(() => {
            setLoader(false);
        }, 10000)

    }, [props.selectedTenantId]);



    const getPassedDate = (time: string) => {
        return time + translate("gün");
    }

    const getPercent = (nmb1, nmb2, text?) => {
        let rt = 0;
        if (nmb1 == 0 || nmb2 == 0) {
            rt = (nmb1 == 0 && nmb2 == 0) ? 0 : nmb1 > nmb2 ? 100 : -100;
        } else {
            rt = (nmb1 / nmb2) * 100;
        }

        rt = Math.ceil(rt);
        text = text == null ? " " : text;
        if (rt > 0) {
            return <span>(<span style={styles.positiveColor}>+{rt}%</span> {text})</span>
        } else if (rt == 0) {
            return <span>(<span>{rt}%</span> {text})</span>
        } else {
            return <span>(<span style={styles.negativeColor}>-{rt}%</span> {text})</span>
        }
    }

    useEffect(() => {
        if (props.reportModel != undefined) {
            setReportModel(props.reportModel);
        }
        //getByTenantId();
    }, [props]);

    return (
        <div className="width100">
            {reportModel != undefined
                &&
                <>
                    <div className={"adminDashboardLineGraph adminDashboardColorfulContent"}
                        style={styles.datatableRow}>
                        <div style={styles.datatableHeader}> {translate("Genel Bakış")}
                        </div>
                        <div style={styles.table} className="reportDatatableContainer">
                            <div className={"dashboardReportContainerContent"} style={styles.tableRow}>
                                <div style={styles.dataContainer}>
                                    <Label className={"dashboardReportDatatableLabel label-bg-olive"} size={"small"}
                                        image>
                                        <div className="shrinkAnimate">
                                            <div style={styles.rowTitle}>{translate("Aktif Çalışan")}</div>
                                            <div className={"dashboardReportDataCardIcon"}>
                                                <Button
                                                    circular
                                                    className={"dashboardReportDataCircularButton"}
                                                >
                                                    <div><Icon size={"large"} color={"olive"}
                                                        name={"users"} /></div>
                                                </Button>
                                            </div>
                                            <div
                                                style={styles.rowHeader}>{reportModel.genericLook.activeUserCount}</div>
                                        </div>
                                    </Label>
                                </div>
                                <div style={styles.dataContainer}>
                                    <Label className={"dashboardReportDatatableLabel label-bg-red"} size={"small"}
                                        image>
                                        <div className="shrinkAnimate">
                                            <div style={styles.rowTitle}>{translate("Aktif Modül")}</div>
                                            <div className={"dashboardReportDataCardIcon"}>
                                                <Button
                                                    circular
                                                    className={"dashboardReportDataCircularButton"}
                                                >
                                                    <div><Icon size={"large"} color={"red"}
                                                        name={"file outline"} /></div>
                                                </Button>
                                            </div>
                                            <div
                                                style={styles.rowHeader}>{reportModel.genericLook.activeModuleCount}</div>
                                        </div>
                                    </Label>
                                </div>
                                <div style={styles.dataContainer}>
                                    <Popup position="top center" wide='very' inverted
                                        content={translate("Çalışan") + " x " + translate("Modül") + " " + reportModel.genericLook.assignedCount}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-orange"}
                                                size={"small"}
                                                image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Kayıt")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div>
                                                                <Icon
                                                                    size={"large"}
                                                                    color={"orange"}
                                                                    name={"save"}
                                                                /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.genericLook.assignedCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />
                                </div>


                            </div>
                            <div className={"dashboardReportContainerContent"} style={styles.tableRow}>
                                <div style={styles.dataContainer}>
                                    <Label className={"dashboardReportDatatableLabel label-bg-blue"} size={"small"}
                                        image>
                                        <div className="shrinkAnimate">
                                            <div style={styles.rowTitle}>{translate("Tamamlama Sayısı")}</div>
                                            <div className={"dashboardReportDataCardIcon"}>
                                                <Button
                                                    circular
                                                    className={"dashboardReportDataCircularButton"}
                                                >
                                                    <div><Icon size={"large"} color={"blue"}
                                                        name={"checkmark"} /></div>
                                                </Button>
                                            </div>
                                            <div style={styles.rowHeader}>{reportModel.genericLook.completedCount}</div>
                                        </div>
                                    </Label>
                                </div>
                                <div style={styles.dataContainer}>

                                    <Label className={"dashboardReportDatatableLabel label-bg-green"} size={"small"}
                                        image>
                                        <div className="shrinkAnimate">
                                            <div style={styles.rowTitle}>{translate("Devam Eden Sayısı")}</div>
                                            <div className={"dashboardReportDataCardIcon"}>
                                                <Button
                                                    circular
                                                    className={"dashboardReportDataCircularButton"}
                                                >
                                                    <div><Icon size={"large"} color={"green"}
                                                        name={"star half"} /></div>
                                                </Button>
                                            </div>
                                            <div
                                                style={styles.rowHeader}>{reportModel.genericLook.inProggressCount}</div>
                                        </div>
                                    </Label>

                                </div>
                                <div style={styles.dataContainer}>

                                    <Label className={"dashboardReportDatatableLabel label-bg-purple"} size={"small"}
                                        image>
                                        <div className="shrinkAnimate">
                                            <div style={styles.rowTitle}>{translate("Eğitim Süresi")}</div>
                                            <div className={"dashboardReportDataCardIcon"}>
                                                <Button
                                                    circular
                                                    className={"dashboardReportDataCircularButton"}
                                                >
                                                    <div><Icon size={"large"} color={"purple"}
                                                        name={"clock"} /></div>
                                                </Button>
                                            </div>
                                            <div
                                                style={styles.rowHeader}> {getPassedDate(reportModel.genericLook.educationTime)}</div>
                                        </div>
                                    </Label>

                                </div>


                            </div>
                            {/*touc*/}
                            {loader &&
                                <Loader active inline='centered' />
                            }
                            {toucResponse.notificationExists &&
                                <div className={"dashboardReportContainerContent"} style={{ ...styles.tableRow, justifyContent: "center" }}>
                                    <div style={styles.dataContainer}>
                                        <Label className={"dashboardReportDatatableLabel label-bg-teal"}
                                            size={"small"}
                                            image>
                                            <div className="shrinkAnimate">
                                                <div style={styles.rowTitle}>{translate("KVKK Kabul Oranı")}</div>
                                                <div className={"dashboardReportDataCardIcon"}>
                                                    <Button
                                                        circular
                                                        className={"dashboardReportDataCircularButton"}
                                                    >
                                                        <div>
                                                            <Icon
                                                                size={"large"}
                                                                color={"teal"}
                                                                name={"pie graph"}
                                                            /></div>
                                                    </Button>
                                            </div>
                                            {toucResponse?.acceptanceRate &&
                                                <div
                                                    style={styles.rowHeader}>%{toucResponse?.acceptanceRate?.toFixed(1)}</div>
                                            }
                                               
                                                </div>
                                        </Label>

                                    </div>
                                    <div style={styles.dataContainer}>
                                        <Label className={"dashboardReportDatatableLabel label-bg-olive"} size={"small"}
                                            image>
                                            <div className="shrinkAnimate">
                                                <div style={styles.rowTitle}>{translate("KVKK Kabul Edenler")}</div>
                                                <div className={"dashboardReportDataCardIcon"}>
                                                    <Button
                                                        circular
                                                        className={"dashboardReportDataCircularButton"}
                                                    >
                                                        <div><Icon size={"large"} color={"black"}
                                                            name={"handshake"} /></div>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={styles.rowHeader}>{toucResponse.acceptedCount}</div>
                                            </div>
                                        </Label>
                                    </div>
                                    <div style={styles.dataContainer}>
                                        <Label className={"dashboardReportDatatableLabel label-bg-orange"}
                                            size={"small"}
                                            image>
                                            <div className="shrinkAnimate">
                                                <div style={styles.rowTitle}>{translate("KVKK Kabul Etmeyenler")}</div>
                                                <div className={"dashboardReportDataCardIcon"}>
                                                    <Button
                                                        circular
                                                        className={"dashboardReportDataCircularButton"}
                                                    >
                                                        <div>
                                                            <Icon
                                                                size={"large"}
                                                                color={"red"}
                                                                name={"delete"}
                                                            /></div>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={styles.rowHeader}>{toucResponse.notAcceptedCount}</div>
                                            </div>
                                        </Label>

                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                    <div className={"adminDashboardLineGraph"} style={styles.datatableRow}>
                        <div style={styles.datatableHeader}>{translate("Bugün")}
                        </div>
                        <div style={styles.table} className="reportDatatableContainer">
                            <div className={"dashboardReportContainerContent"} style={styles.tableRow}>
                                <div style={styles.dataContainer}>
                                    <Popup position="top center" wide='very' inverted
                                        content={getPercent(reportModel.todayLook.loginCount, reportModel.yesterdayLook.loginCount, translate("düne göre değişim"))}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-teal"}
                                                size={"small"} image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Oturum Açma")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div><Icon size={"large"} color={"teal"}
                                                                name={"key"} /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.todayLook.loginCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />
                                </div>
                                <div style={styles.dataContainer}>
                                    <Popup position="top center" wide='very' inverted
                                        content={getPercent(reportModel.todayLook.activeUserCount, reportModel.yesterdayLook.activeUserCount, translate("düne göre değişim"))}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-yellow"}
                                                size={"small"} image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Etkin Çalışan")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div><Icon size={"large"} color={"yellow"}
                                                                name={"circle"} /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.todayLook.activeUserCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />
                                </div>
                                <div style={styles.dataContainer}>

                                    <Popup position="top center" wide='very' inverted
                                        content={getPercent(reportModel.todayLook.moduleCompletingCount, reportModel.yesterdayLook.moduleCompletingCount, translate("düne göre değişim"))}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-brown"}
                                                size={"small"} image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Modül Tamamlama")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div><Icon size={"large"} color={"brown"}
                                                                name={"star"} /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.todayLook.moduleCompletingCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"adminDashboardLineGraph"} style={styles.datatableRow}>
                        <div style={styles.datatableHeader}>{translate("Bu Hafta")}
                        </div>
                        <div style={styles.table} className="reportDatatableContainer">
                            <div className={"dashboardReportContainerContent"} style={styles.tableRow}>
                                <div style={styles.dataContainer}>

                                    <Popup position="top center" wide='very' inverted
                                        content={getPercent(reportModel.thisWeekLook.loginCount, reportModel.yesterdayLook.loginCount, translate("geçen haftaya göre değişim"))}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-teal"}
                                                size={"small"} image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Oturum Açma")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div><Icon size={"large"} color={"teal"}
                                                                name={"key"} /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.thisWeekLook.loginCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />

                                </div>
                                <div style={styles.dataContainer}>
                                    <Popup position="top center" wide='very' inverted
                                        content={getPercent(reportModel.thisWeekLook.activeUserCount, reportModel.previousWeekLook.activeUserCount, translate("geçen haftaya göre değişim"))}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-yellow"}
                                                size={"small"} image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Etkin Çalışan")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div><Icon size={"large"} color={"yellow"}
                                                                name={"circle"} /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.thisWeekLook.activeUserCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />

                                </div>
                                <div style={styles.dataContainer}>
                                    <Popup position="top center" wide='very' inverted
                                        content={getPercent(reportModel.thisWeekLook.moduleCompletingCount, reportModel.previousWeekLook.moduleCompletingCount, translate("geçen haftaya göre değişim"))}
                                        trigger={
                                            <Label className={"dashboardReportDatatableLabel label-bg-brown"}
                                                size={"small"} image>
                                                <div className="shrinkAnimate">
                                                    <div style={styles.rowTitle}>{translate("Modül Tamamlama")}</div>
                                                    <div className={"dashboardReportDataCardIcon"}>
                                                        <Button
                                                            circular
                                                            className={"dashboardReportDataCircularButton"}
                                                        >
                                                            <div><Icon size={"large"} color={"brown"}
                                                                name={"star"} /></div>
                                                        </Button>
                                                    </div>
                                                    <div
                                                        style={styles.rowHeader}>{reportModel.thisWeekLook.moduleCompletingCount}</div>
                                                </div>
                                            </Label>
                                        }
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default DashboardReportDatatable;