import * as React from 'react';
import {useState, useEffect} from 'react';
import {Button, Checkbox, Dropdown, Loader, Modal, Transition} from 'semantic-ui-react';
import {NetworkRequest } from '../../../helper/NetworkRequest';
import DataTable from '../../../components/DataTable';
import {IDataTable, IDropdown} from '../../../interfaces/interfaces'
import {datableFilterChange, digitallicaConfirm, prepareDataForTable} from '../../../helper/HelperFunctions';
import {toast} from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const Surveys = (props) => {
    var {translate} = TranslateHelper();
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [selectedSurveyId, setSelectedSurveyId] = useState<any>();
    const [showIsCloneTenant, setShowIsCloneTenant] = useState<boolean>(false);
    const [tenantList, setTenantList] = useState<Array<IDropdown>>([]);
    const [selectedTenantIds, setSelectedTenantIds] = useState<Array<string>>([]);

    //get table data from api
    const getSurveys = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/Surveys/GetSurveys", filterData).then(response => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
                setIsLoading(false);
            }
        });
    }

    const getTenants = () => {
        NetworkRequest("api/Tenants/GetTenantListCanClone").then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                const roleToDropdownType = result.map(elem => (
                    {
                        text: elem.displayName,
                        key: elem.tenantId,
                        value: elem.tenantId
                    }
                ));
                setTenantList(roleToDropdownType);
            }
        });
    }

    const changeDropdownTenant = (event, data) => {
        setSelectedTenantIds(data.value);
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            checkSubTenants: userRole == "HeadAdmin"
        }
        getSurveys(filterData);

    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    const showChildTenants = (id) => {
        setSelectedSurveyId(id);
        setShowIsCloneTenant(true);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
        setIsDeleted(!data.checked);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
        setIsActive(!data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    //delete selected data
    const deleteTest = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Surveys/Delete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        getSurveys(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
    }

    const cloneRow = (id) => {
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Surveys/Clone", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        ////
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        getSurveys(filterData);

                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Geri döndürmek istediğinize emin misiniz ?"),
            () => {
                let test = {
                    id: id
                }

                NetworkRequest("/Surveys/UndoDelete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {

                        let index = dataTableModel.data.findIndex(
                            data => data.secretData[0].id === id
                        )

                        if (index > -1) {
                            dataTableModel.data.splice(index, 1);

                            setDataTableModel({
                                header: dataTableModel.header,
                                data: [],
                                transactions: dataTableModel.transactions,
                                filter: dataTableModel.filter,
                                pagination: dataTableModel.pagination,
                                addRowLink: dataTableModel.addRowLink
                            });

                            setDataTableModel(dataTableModel);

                        }
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
        if (window.confirm("")) {

        }
    }

    let tableHeader = [
        <Checkbox onChange={setAllSelected}/>,
        translate("Başlık"),
        translate("Açıklama"),
        translate("Ekleyen Çalışan"),
        translate("Oluşturma Tarihi"),
        translate("Soru Sayısı"),
        translate("Aktif"),
        translate("İşlemler")
    ];


    if (userRole == "HeadAdmin") {
        tableHeader.splice(3, 0, "Şirket");
    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false},
                    {isOnlineActivity: false},
                    {settingsJson: false},
                ]
            }
        ],
        transactions: [
            {
                icon: "chart bar",
                desc: translate("Rapor"),
                color: "black",
                link: "/srvyPersonDetailReport",
                type: "report"
            },
            {
                icon: "clone",
                desc: translate("Klonla"),
                color: undefined,
                link: null,
                type: "clone",
                function: userRole == "HeadAdmin" ? showChildTenants : cloneRow,
            },
            {
                icon: "question",
                desc: translate("Havuzdan Soru ekle"),
                color: "blue",
                link: "/surveyContentPool",
                type: "add"
            },
            {
                icon: "plus",
                desc: translate("Yeni Soru ekle"),
                color: "green",
                link: "/inquiryContentEdit/attach",
                type: "add",

            },

            {
                icon: "edit",
                desc: translate("Düzenle"),
                color: "blue",
                link: "/editSurvey",
                type: "edit",

            },
            {
                icon: "trash",
                desc: translate("Sil"),
                color: "red",
                link: null,
                type: "delete",
                function: deleteTest
            },
            {
                icon: "redo",
                desc: translate("Geri al"),
                color: "black",
                link: null,
                type: "undo",
                function: undoDelete
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/addSurvey",
        setRowSelected: setRowSelected
    });

    //prepare data for table
    const prepareData = (data) => {
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        //hidden column
        var model = prepareDataForTable(data.list, [
            'id',
            'settingsJson',
            'isDeleted',
            'updatedDate',
            'deletedDate',
            userRole == "HeadAdmin" ? "" : "companyName"
        ]);


        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(data.count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);

        setIsLoading(false);
    }

    const cloneSurvey = () => {
        setShowIsCloneTenant(false);
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz ?"),
            () => {
                let surveyView = {
                    id: selectedSurveyId
                }

                var formData = new FormData();                
                formData.append('SurveyView', JSON.stringify(surveyView));
                formData.append('List', JSON.stringify(selectedTenantIds));

                toast(translate("Anketiniz Klonlanıyor Lütfen Bekleyiniz"), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });

                NetworkRequest("Surveys/CloneSurveyByTenant", formData).then((response) => {
                    if (response.data.resultCode === 0) {
                        //
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId"),
                            checkSubTenants: userRole == "HeadAdmin"
                        }
                        getSurveys(filterData);
                        toast(translate("Tamamlandı!"), {type: 'success', theme: 'dark', position: 'top-center', autoClose: 1000});
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }


    useEffect(() => {
        if (showIsCloneTenant)
            getTenants();
    }, [showIsCloneTenant]);


    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        {/*{dataTableHtml}*/}
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                                   setAllSelected={setAllSelected} selectAllHandler={selectAllHandler}
                                   isLoading={isLoading}/>
                    </div>
                </Transition>
                <Modal
                    size={"mini"}
                    open={showIsCloneTenant}
                >
                    <Modal.Header>{translate("Anketi Klonla")}</Modal.Header>
                    <Modal.Content>
                        <Dropdown
                            fluid multiple selection
                            value={selectedTenantIds}
                            name="tenantId"
                            clearable
                            options={tenantList}
                            size="big"
                            placeholder={translate("Şirketleri Seçiniz")}
                            onChange={changeDropdownTenant}
                        />
                        {/*style={{ ...styles.multipleDropdown }}*/}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setShowIsCloneTenant(false)}>
                            {translate("Hayır")}
                        </Button>
                        <Button positive onClick={() => cloneSurvey()}>
                            {translate("Evet")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    );
}

export default Surveys;