import * as React from 'react';
import {useState, useEffect} from 'react';
import parse from "html-react-parser";
import moment from 'moment'
import {NetworkRequest} from '../../helper/NetworkRequest';

import {displayProtectedAppDoc} from '../../helper/DisplayProtectedDocument';
import {Image} from 'semantic-ui-react';
import {DataHandler} from '../../helper/DataHelper';
import TranslateHelper from '../../helper/TranslateHelper';
import PartyFaceIcon from '../../images/party_face.png';


const WelcomeBanner = (props) => {

    var {translate} = TranslateHelper();
    const styles = {
        headerStyle: {
            color: "black !important",
            fontSize: "24px",
            fontWeight: "700"
        },
    };

    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isLoading, setIsLoading] = useState(true);
    const [welcomeBant, setWelcomeBant] = useState<any>();
    const [user] = useState(props.user);
    const [isHiddenBanner, setIsHiddenBanner] = useState(false);

    const getSiteSetting = () => {
        return NetworkRequest("api/SiteSetting/Get", {id: tenantId}).then((response) => {
            if (response.data.resultCode == 0) {
                var result = response.data.detail;
                let model: any = {};
                if (result.welcomeBannerColorCode != null) {
                    model.welcomeBannerColorCode = result.welcomeBannerColorCode;
                } else {
                    model.welcomeBannerColorCode = "#FF4390";
                }
                if (result.welcomeTextColor != null) {
                    model.welcomeTextColor = result.welcomeTextColor;
                } else {
                    model.welcomeTextColor = "#fff";
                }
                if (result.wecomeBannerImg != null) {
                    model.wecomeBannerImg = result.wecomeBannerImg;
                } else {
                    model.wecomeBannerImg = PartyFaceIcon;
                }
                setWelcomeBant(model);
                setIsLoading(false);
            }
        });
    }

    function invertColor(hex) {
        if (hex.indexOf('#') === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error('Invalid HEX color.');
        }
        // invert color components
        var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
            g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
            b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
        // pad each with zeros and return
        return '#' + padZero(r) + padZero(g) + padZero(b);
    }

    function padZero(str, len?) {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    }

    useEffect(() => {
        getSiteSetting();
    }, []);

    useEffect(() => {
        ////console.log("welcomeBant: ", welcomeBant);
        ////console.log("props: ", props);
    }, [welcomeBant, props]);

    useEffect(() => {
        let user = props.user;
        var days = moment().diff(user.workStartDate, 'days');
        var hours = moment().diff(user.workStartDate, 'hours');
        if (days == 0 && hours < 0) {
            days = -1
        }
        var mes = "";
        if (days < 0) {
            mes = "<span>" + translate("Hoş geldin") + " <b>" + user.name + "</b>,</span> <span>" + translate("Aramıza katılmana ") + Math.abs(days) + translate(" gün var! Bizi tanımaya devam edebilirsin.") + "</span>";
        } else if (days > 0) {
            setIsHiddenBanner(true);
        }
        setWelcomeMessage(mes);
    }, [user]);

    return (
        <>
            {isLoading == false && welcomeBant != undefined && !isHiddenBanner
                &&
                <span className={"userDashboardWelcomeBanner"} style={{
                    ...styles.headerStyle,
                    fontSize: "22px",
                    fontWeight: "normal",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    backgroundColor: props.bgColor == undefined ? welcomeBant.welcomeBannerColorCode : props.bgColor,
                    padding: ".5rem 1.5rem",
                    margin: "-2rem -1rem 1rem"
                }}>
                    <img
                        id="welcomeImage"
                        src={displayProtectedAppDoc(
                            props.image != "" && props.image != undefined
                                ? props.image
                                : props.image == undefined
                                    ? welcomeBant.wecomeBannerImg
                                    : props.image, "welcomeImage")
                        }
                        width="70"
                        alt={"emoji"}
                    />
                    <span style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5rem",
                        color: props.textColor == undefined ? welcomeBant.welcomeTextColor : "black"
                    }}>{parse(welcomeMessage)}</span>
                </span>
            }
        </>
    )
};

export default WelcomeBanner;