import * as React from 'react';
import { DataHandler } from '../../helper/DataHelper';
import DropZone from '../DropZone';
import WordImportExcelOutline from './WordImportExcelOutline';
import wordImportFile from '../../images/Buddy/ExcelImport/word-import.xlsx';


let dropZoneProps = {
    links: {
        uploadLink: "/api/DictionaryWord/ImportWords",
        returnLink: "/wordList"
    },
    table: {
        headers: ["Kelime", "Hata Nedeni"],
        propertyNames: ["name"]
    },
    messages: {
        1: "Kelime alanı boş bırakılamaz",

    },
    dataName: "Kelime",
    // excelTemplateFileLink: DataHandler.getDocmanagerUrl() + "/Documents/Buddy/ExcelImport/word-import.xlsx",
    excelTemplateFileLink: wordImportFile,
    sendTenantId: true,
    redirectToPage: true
}
let styles = {

    importContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column" as "column",
        alignItems: "center",
        gap:"4rem"
    }
}

const WordImportExcel = () => {
    return (
        <div style={styles.importContainer}>
            <DropZone
                dropZoneProps={dropZoneProps}
            />
            <WordImportExcelOutline />
        </div>
    )
}

export default WordImportExcel;