import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Image, Input, Loader, Transition } from 'semantic-ui-react';

import { DataHandler } from '../helper/DataHelper';
import { displayProtectedAppDoc } from '../helper/DisplayProtectedDocument';
import { NetworkRequest } from '../helper/NetworkRequest';

const CretificatePrinter = (props) => {

    const [image, setImage] = useState("");
    const [printedCertificate, setPrintedCertificate] = useState(undefined);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [registrationId] = useState(props.id);
    const [photoUrl, setPhotoUrl] = useState("");
    const [nameSurname, setNameSurname] = useState("");
    const [loader, setLoader] = useState(true);

    const checkUploadedCertificate = () => {
        let data = { id: registrationId };
        NetworkRequest("/api/Certificate/CheckUploadedCertificate", data).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                if (result == null || result.url == null || result.url == "") {
                    print();
                } else {
                    setPrintedCertificate(result.url);
                    setLoader(false);
                }
            }
        });
    }

    const print = () => {
        toast("Lütfen Bekleyin", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 10000 });
        var adSoyad = "X"
        var yetkili = "X"
        var colorCode = "#5BAECC";
        var base64 = "";
        let data = { id: registrationId };
        NetworkRequest("/api/Certificate/GetForPrint", data).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                adSoyad = result.nameSurname;
                yetkili = result.signName;
                colorCode = result.textColorCode
                yetkili = result.signText
                setImage(result.base64)
                base64 = result.base64
            }
        });
        setNameSurname(adSoyad)
        const params = new URLSearchParams(window.location.search);

        window.location.href.substr(0, window.location.href.indexOf('?'));
        var canvas = document.querySelector('canvas');
        var myFont = new FontFace('Roboto', 'url(https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2)');

        myFont.load().then((font) => {

            var myFont: any = new FontFace('Comfortaa', 'url(https://fonts.gstatic.com/s/comfortaa/v34/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LDr0fIA9c.woff2)');

            myFont.load().then((font1) => {

                let monthsList = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
                document.fonts.add(font);
                document.fonts.add(font1);
                var canvas: any = document.getElementById('canvas');
                var ctx = canvas.getContext('2d');

                window.setTimeout(() => {
                    var img: any = document.getElementById("imgCertificate")
                    canvas.width = img.width;
                    canvas.crossOrigin = "Anonymous";
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0);
                    ctx.font = "28pt 'Roboto', cursive";
                    ctx.fillStyle = colorCode;

                    //redraw image
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0);
                    //refill text
                    var x = 535 - ctx.measureText(adSoyad).width / 2;
                    ctx.fillText(adSoyad, x, 415);

                    var date = new Date();
                    var datetime = date.getDate() + ' ' + monthsList[date.getMonth()] + ' ' + date.getUTCFullYear();

                    ctx.font = "18px 'Roboto'";
                    x = 375 - ctx.measureText(datetime).width / 2;
                    ctx.fillText(datetime, x, 585);

                    x = 715 - ctx.measureText(yetkili).width / 2;
                    ctx.fillText(yetkili, x, 585);
                    uploadImage();
                }, 5000);
            });
        })
    }

    function uploadImage() {
        const canvas: any = document.getElementById('canvas');
        var canvasDataUrl: any = canvas.toDataURL("image/png")
        saveCertificate(canvasDataUrl);
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays: Array<any> = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const saveCertificate = (certificate) => {
        certificate = certificate.replace('data:image/png;base64,', '');
        var formData = new FormData();
        const contentType = 'image/png';
        let blob = b64toBlob(certificate, contentType);

        formData.append('Data', blob);
        formData.append('ModuleRegistration', JSON.stringify({ registrationId: registrationId, tenantId: tenantId }));
        NetworkRequest("/api/Certificate/UploadPrintedCertificate", formData).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                setPrintedCertificate(result.url);
                setLoader(false);
                toast.dismiss();
            }
        });
    }

    React.useEffect(() => {
        if (registrationId != undefined && registrationId != null && registrationId != "")
            checkUploadedCertificate();

    }, [registrationId]);

    return (
        <div>
            <Transition visible={loader} animation='scale' >
                <Loader size="huge" active inline='centered' />
            </Transition>
            <img id="imgCertificate" crossOrigin="anonymous" style={{ display: "none" }} className="imgCertificate" src={"data:image/png;base64," + image} />
            {printedCertificate != undefined && !loader
                &&
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <Image id="printedCertificate" src={displayProtectedAppDoc(printedCertificate,"printedCertificate")} className="width100" />

                    <div style={{ display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "center" }}>
                        <a href={printedCertificate} download target="_blank" >
                            <Button className={"positiveGreenButton"}>
                                <Icon name='download' /> İndir
                            </Button>
                        </a>
                        <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + printedCertificate} target="_blank" >
                            <Button color='linkedin'>
                                LinkedIn'de Paylaş
                            </Button>
                        </a>
                    </div>
                </div>
            }
            <canvas id="canvas" style={{ display: "none" }}></canvas>
            <a id="download" style={{ display: "none" }} ></a>
        </div>
    );
}


export default CretificatePrinter;