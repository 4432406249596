import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import DataTable from '../../../components/DataTable';
import { IDataTable, ISurvey, ISurveyQuestion } from '../../../interfaces/interfaces'
import { datableFilterChange, digitallicaConfirm, getQuestionContentForRender, prepareDataForTable } from '../../../helper/HelperFunctions';
import { Checkbox, Loader, Transition } from 'semantic-ui-react';

import { toast } from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

enum SuveyQuestionType {
    "Çoktan Seçmeli" = 1,
    "Serbest Metin" = 2,
    "2 Şık" = 4
}

const SurveySurveys = (props) => {
    var { translate } = TranslateHelper();
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const cloneRow = (id) => {
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/SurveyQuestion/Clone", test).then((response) => {
    
                    if (response.data.resultCode === 0) {
                  
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        
                        toast(translate(response.data.message), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2500 })
                        getData(filterData);
                    } else {
                        toast(translate(response.data.message), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 })
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    //delete selected data
    const deleteRow = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/SurveyQuestion/Delete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        getData(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Geri döndürmek istediğinize emin misiniz ?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/SurveyQuestion/UndoDelete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {

                        let index = dataTableModel.data.findIndex(
                            data => data.secretData[0].id === id
                        )

                        if (index > -1) {
                            dataTableModel.data.splice(index, 1);

                            setDataTableModel({
                                header: dataTableModel.header,
                                data: [],
                                transactions: dataTableModel.transactions,
                                filter: dataTableModel.filter,
                                pagination: dataTableModel.pagination,
                                addRowLink: dataTableModel.addRowLink
                            });

                            setDataTableModel(dataTableModel);

                        }
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
        setIsDeleted(!data.checked);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
        setIsActive(!data.checked);

    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getData(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            <Checkbox
                onChange={setAllSelected}
            />,
            translate("Soru Metni"),
            translate("Soru Tipi"),
            translate("Şıklar"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            //{
            //    icon: "chart bar",
            //    desc: "Rapor",
            //    color: undefined,
            //    link: "/report",
            //    type: "report"
            //},
            {
                icon: "clone",
                desc: translate("Klonla"),
                color: undefined,
                function: cloneRow,
                type: "clone"
            },
            {
                icon: "edit",
                desc: translate("Düzenle"),
                color: "blue",
                link: "/inquiryContentEdit/edit",
                type: "edit",

            },
            {
                icon: "trash",
                desc: translate("Sil"),
                color: "red",
                link: null,
                type: "delete",
                function: deleteRow
            },
            {
                icon: "redo",
                desc: translate("Geri al"),
                color: "black",
                link: null,
                type: "undo",
                function: undoDelete
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/inquiryContentAdd",
        setRowSelected: setRowSelected,
        addExcelLink: "/surveyQuestionImport"
    });

    //prepare data for table
    const prepareData = (data) => {
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        //hidden column
        var model = prepareDataForTable(data.list, [
            'Id',
            'id',
            'UpdatedDate',
            'DeletedDate',
            'IsDeleted',
            "A",
            "B",
            "C",
            "D",
            "E",
        ]);

        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(
                        data.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);

        // setDataTableHtml("");
        //
        // setDataTableHtml(
        //     <DataTable
        //         dataTable={{
        //             ...dataTableModel,
        //             data: model,
        //             pagination: {
        //                 count: Math.ceil(
        //                     data.length / 25
        //                 ),
        //                 setCurrentPage: setCurrentPage, currentPage: currentPage
        //             }
        //         }}
        //         isAllSelected={isAllSelected}
        //         selectAllHandler={selectAllHandler}
        //     />
        // );

        setIsLoading(false);
    }



    //get table data from database
    const getData = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/SurveyQuestion/GetSurveyQuestions", filterData).then(response => {
            ////
            if (response.data.resultCode === 0) {
                let designedSurveyArray = Array<ISurveyQuestion>();

                let surveys = response.data.detail.list;
                surveys.map(survey => {
                    survey.questionJSON = JSON.parse(survey.questionJSONString);
                    let options =
                        "<b>A: </b>" + survey.questionJSON.A + ", " +
                        "<b>B: </b>" + survey.questionJSON.B
                    options += survey.surveyQuestionType == 1 ?
                        ", <b>C: </b>" + survey.questionJSON.C + ", " +
                        "<b>D: </b>" + survey.questionJSON.D + ", " +
                        "<b>E: </b>" + survey.questionJSON.E : "";
                    
                    let newSurveyFormat = {
                        id: survey.id,
                        Id: survey.id,
                        Body: getQuestionContentForRender(survey.questionJSON.Body),
                        A: (survey.surveyQuestionType == 1 || survey.surveyQuestionType == 4) ? survey.questionJSON.A : "",
                        B: (survey.surveyQuestionType == 1 || survey.surveyQuestionType == 4) ? survey.questionJSON.B : "",
                        C: survey.surveyQuestionType == 1 ? survey.questionJSON.C : "",
                        D: survey.surveyQuestionType == 1 ? survey.questionJSON.D : "",
                        E: survey.surveyQuestionType == 1 ? survey.questionJSON.E : "",
                        SurveyQuestionType: SuveyQuestionType[survey.surveyQuestionType],
                        IsDeleted: survey.isDeleted,
                        UpdatedDate: survey.updatedDate,
                        DeletedDate: survey.deletedDate,
                        Options: (survey.surveyQuestionType == 1 || survey.surveyQuestionType == 4) ? options : "",
                        IsActive: survey.isActive,
                    }

                    designedSurveyArray.push(newSurveyFormat);
                });

                ////console.log(designedSurveyArray);

                prepareData({ list: designedSurveyArray, count: response.data.detail.count });
                setIsLoading(false);
            }
        });
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered' />
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected} setAllSelected={setAllSelected} selectAllHandler={selectAllHandler} isLoading={isLoading} />
                    </div>
                </Transition>
            </div>
        </div>
    );
}


export default SurveySurveys;