import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Loader, Message, MessageHeader } from 'semantic-ui-react';

import parse from 'html-react-parser';

import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import TranslateHelper from '../../helper/TranslateHelper';
import { scrollToTop } from "../../helper/HelperFunctions";
import DOMPurify from 'dompurify'



export interface IHtml {
    id: string,
    embedCode: any,
    sectionContentId: string,
    htmlJson: object | string,
}

function RenderContentHtml(props) {
    var { translate } = TranslateHelper();
    const [coutdownFinished, setCoutdownFinished] = useState(false);

    const [canComplate, setCanComplate] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [content, setContent] = useState(props.content);

    //const [localProps] = useState(props);
    const [html, setHtml] = useState<any>({});
    const [showModal, setShowModal] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
       
        try {
            setHtml("");
            setContent(props.content);
            let html = props.content.data.contentHtmlView;
            if (html == undefined)
                return;
            html.htmlJsonModel = JSON.parse(html.htmlJson);

            if (props.content.status != undefined) {
                setCoutdownFinished(props.content?.status?.isCompleted == true);
            }

            setHtml(html);

            setMinutes(html.htmlJsonModel.time);
            setSeconds(0);

            setCanComplate(!html.htmlJsonModel.assignTime);

            setIsLoading(false);
            props.setContentLoading(false);
        } catch (e) {
            ////
        }
    }, [props]);

    let styles = {
        modal: {
            width: "90%"
        },
        complateButton: {
            marginTop: "2rem",
            width: "90%",
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center" as "center",
            gap: "1rem"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center" as "center",

        },
        btnDarkOutline: {
            width: "228px",
            height: "53px",
            right: "350px",
            top: "700px",
            background: "#fff",
            border: "1px solid #292828",
            boxSizing: "border-box",
            borderRadius: "8px"
        },
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            borderRadius: "8px"
        },
        timeCounter: {
            width: "58px",
            height: "58px",
            background: "#C4C4C4",
            borderRadius: "50%",
            fontSize: "14px",
            fontWeight: "700",
            alignItems: "center",
            justifyContent: "center",
            color: "#292828",
            display: "flex"

        },
        complateContainer: {
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            marginTop: "1rem"
        },
        htmlContainer: {
            width: "90%",
            display: "flex",
            justifyContent: "center"
        },
        transparent: {
            background: 'rgba(255,255,255,.60)'
        },
    }

    const complateContent = (isSuccess) => {
        //alert(isSuccess);
        props.onComplete();
        if (isSuccess) {
            setCoutdownFinished(true)
        }

        props.setIsOpenTaskContentForMobile(true);
    }

    const children = (remainingTime) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60

        let secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`
        let minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`

        return `${minutesString}.${secondsString}`
    }

    useEffect(() => {
        //Appointment of Scroll at the top when the content changes
        scrollToTop();
    }, []);

    function checkStatus() {
        var htmlJson = props.content.data.contentHtmlView.htmlJsonModel;
        if (htmlJson.showFormHtmlIframe) {
            var iframe: any = document.getElementById(htmlJson.iframeID);
            var elmnt = iframe.contentWindow.document.getElementById(htmlJson.inputID);
            if (elmnt.placeholder.toLowerCase() == htmlJson.searchInputValue.toLowerCase()) {
                complateContent(true);
            }
        }
    }

    useEffect(() => {
        setInterval(() => {
            checkStatus()
        }, 1000);
    }, []);

    return (
        <div className="iframeContainer">
            {props.isLocked != true &&
                <>
                    <div style={styles.htmlContainer} className="iframe">
                    {(html?.htmlJsonModel?.embedValue == undefined && isLoading) && <Loader active inline='centered' />}
                    {(html?.htmlJsonModel?.embedValue != undefined && !isLoading)
                        ?
                        <div style={{ width: "100%", height: "max-content" }}>{parse(html?.htmlJsonModel?.embedValue)}</div>
                        :
                        <Message warning>
                            <MessageHeader>{translate("Uyarı")}</MessageHeader>
                            <p>{translate("Embed Code içeriği görüntülenemiyor.")}</p>
                        </Message>
                        }

                    </div>
                    <div style={styles.complateContainer}>
                        {(!canComplate && !coutdownFinished) &&
                            <CountdownCircleTimer
                                isPlaying
                                duration={minutes * 60}
                                onComplete={() => {
                                    setCanComplate(true)
                                }}
                                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                colorsTime={[10, 5, 3, 0]}
                                size={100}
                            >
                                {({ remainingTime }) => children(remainingTime)}
                            </CountdownCircleTimer>
                        }
                        {(content != undefined && html != undefined && html?.embedCode != "" && canComplate && content?.status != undefined && content?.status?.isCompleted == false) &&
                            <Button
                                circular={true}
                                size={"big"}
                                color={"green"}
                                onClick={() => complateContent(true)}
                                disabled={!canComplate}
                            >{translate("Tamamla")}
                            </Button>
                        }
                    </div>
                </>
            }
        </div>

    )
}

export default RenderContentHtml;