import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter, HashRouter } from 'react-router-dom';
import App from './App';
import Store from './Store';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(<BrowserRouter>
    <Store>
        <App />
    </Store>
</BrowserRouter>);
//createRoot(document.getElementById('root')!).render(
//    <BrowserRouter>
//        <Store>
//            <App />
//            {/*<ErrorBoundary>*/}
//            {/*    <App />*/}
//            {/*</ErrorBoundary>*/}
//        </Store>
//    </BrowserRouter>)
/*reportWebVitals();*/
