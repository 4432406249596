import * as React from 'react';
import { useEffect, useState } from "react";
import { Grid, Input } from 'semantic-ui-react';

const TestInputTrial = () => {

    let _ = require('lodash');
    const [nediyim, setNediyim] = useState<any>({});

    const handleChange = (e, i) => {
        let clone = _.cloneDeep(nediyim);
        clone.list[i].text = e.target.value;
        setNediyim(clone);
    }

    useEffect(() => {
        setNediyim({
            list: [
                {
                    text: 'abc'
                },
                {
                    text: 'abc2'
                },
                {
                    text: 'abc3'
                },
                {
                    text: 'abc4'
                },
            ]
        });
    }, []);

    useEffect(() => {
        ////console.log("nediyim: ", nediyim);
    }, [nediyim]);

    return (
        <>
            {nediyim.list?.map((data, i) => (
                <Grid columns={1} key={i}>
                    <Grid.Row>
                        <Grid.Column>
                            <Input
                                autoFocus
                                value={data.text}
                                name="title"
                                required="required"
                                size="big"
                                onChange={(e) => { handleChange(e, i); }} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                ))}
        </>
    );
}

export default TestInputTrial;