import * as React from 'react';
import {useState} from "react";

import {useNavigate} from 'react-router-dom';

import {isDesktop, isMobile, isTablet} from 'react-device-detect';
import TranslateHelper from '../../helper/TranslateHelper';
import {Divider, Grid, Image, Icon, Button, Segment, Input} from 'semantic-ui-react';

import Banner from '../../images/home/v2/banner_new.png';
import Computer from '../../images/home/v2/computer.png';
import People from '../../images/home/v2/people.png';
import Report from '../../images/home/v2/report.png';

import Gif1 from '../../images/gif1.gif';
import Gif2 from '../../images/gif2.gif';
import Gif3 from '../../images/gif3.gif';


import './Home.css';

import HomeV2Header from './HomeV2Components/HomeV2Header';
import HomeV2Footer from './HomeV2Components/HomeV2Footer';
import {toast} from "react-toastify";
import {isLandscapeTablet} from "../../helper/HelperFunctions";


const HomeV2 = (props) => {
    const navigate = useNavigate();
    var {translateFromEng} = TranslateHelper();

    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");

    const getStarted = () => {
        if (email === "" || companyName === "") {
            toast(translateFromEng("Lütfen tüm alanları doldurunuz"), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
            return
        }

        navigate('/newTenant?email=' + email + '&company=' + companyName);
    }
    
    return (
        <Grid className={"homeV2Container"} columns={16}>
            <HomeV2Header/>

            <Grid.Row columns={2} className="homeV2BannerContainer">
                <Grid.Column computer={isLandscapeTablet() ? 16 : 8} mobile={16} tablet={16}
                             className="homeV2BannerTextSectionContainer">
                    <Grid.Row centered>
                        <Grid.Column className="homeV2BannerTitle">
                            <div>{translateFromEng("Your entire onboarding process in a single app")}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <div
                                className="homeV2BannerText">{translateFromEng("With the Buddy App, you can provide inspiring interactive content to your employees by putting the employee experience at the center of the onboarding process.")}</div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column className={"homeV2BannerSection homeV2BannerImagehomeContainer"} computer={isLandscapeTablet() ? 16 : 8} mobile={16} tablet={16}>
                    <Image className={"homeV2BannerImage homeV2BannerImagehome"} src={Banner} size="massive"/>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row centered className="homeV2TextContainer">
                <Grid.Column textAlign="center" computer={16}>
                    <div className="homeV2BannerTitleV2">{translateFromEng("Why Buddy App?")}</div>
                </Grid.Column>


                <Grid.Column textAlign="center" computer={16}>
                    <div className="homeV2Text">{translateFromEng("Discover what the Buddy App can do for you")}</div>
                </Grid.Column>
            </Grid.Row>


            <Grid.Row centered columns={3} className="hoveV2GifContainer">
                <Grid.Column className={"homeV2GifCard"} textAlign="center" verticalAlign="middle" computer={5}
                             mobile={13} tablet={7}>
                    <Image className="homeV2Gif" src={Gif1}/>

                    <Grid.Row>
                        <Grid.Column computer={16}>
                            <div className="homeV2BannerTitleV3">{translateFromEng("Adapt Quickly")}</div>
                        </Grid.Column>
                        <Grid.Column computer={16} textAlign="center">
                            <div className="homeV2BannerTextV2">
                                {translateFromEng("Accelerate the adaptation processes of your employees to your company and corporate culture by providing them personalized onboarding experience.")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Column className={"homeV2GifCard"} textAlign="center" verticalAlign="middle" computer={5}
                             mobile={13} tablet={7}>
                    <Image className="homeV2Gif" src={Gif2}/>

                    <Grid.Row>
                        <Grid.Column computer={16}>
                            <div className="homeV2BannerTitleV3">Increase Efficiency</div>
                        </Grid.Column>
                        <Grid.Column computer={16} textAlign="center">
                            <div className="homeV2BannerTextV2">
                                {translateFromEng("Say goodbye to Excel! Reduce the time you spend on operations for new hires by digitizing your processes.")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Column className={"homeV2GifCard"} textAlign="center" verticalAlign="middle" computer={5}
                             mobile={13} tablet={7}>
                    <Image className="homeV2Gif" src={Gif3}/>

                    <Grid.Row>
                        <Grid.Column computer={16}>
                            <div className="homeV2BannerTitleV3">{translateFromEng("Increase Engagement")}</div>
                        </Grid.Column>
                        <Grid.Column computer={16} textAlign="center">
                            <div className="homeV2BannerTextV2">
                                {translateFromEng("Save on talent search and onboarding by keeping your best employees engaged longer.")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>


            <Grid.Row centered columns={2} className="homeV2InfoGraphBox">
                <Grid.Column computer={6} mobile={13} tablet={16}>
                    <Grid.Row className="homeV2InfoGraphContainer">
                        <Grid.Column textAlign="center" computer={16} mobile={13} tablet={16}>
                            <div className="homeV2BannerTitleV4">
                                <div>{translateFromEng("Design the inspiring")}</div>
                                <div>{translateFromEng("experience your way")}</div>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={16} textAlign="left" mobile={13} tablet={16}>
                            <div className="homeV2BannerTextV3">
                                {translateFromEng("Create, assign and manage tasks for your new onboardees and their teammates with integrated task management.")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column computer={6} textAlign="left" mobile={13} tablet={16}>
                    <Image className="infoGraphImage" src={Computer}/>

                </Grid.Column>
            </Grid.Row>


            <Grid.Row centered columns={2} className="homeV2InfoGraphBox">
                <Grid.Column computer={6} textAlign="left" mobile={13} tablet={16}>
                    <Image className="infoGraphImage" src={People}/>

                </Grid.Column>

                <Grid.Column computer={6} mobile={13} tablet={16}>
                    <Grid.Row className="homeV2InfoGraphContainer">
                        <Grid.Column textAlign="center" computer={16}>
                            <div className="homeV2BannerTitleV4">
                                <div>{translateFromEng("Involve all stakeholders")}</div>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={16} textAlign="left">
                            <div className="homeV2BannerTextV3">
                                {translateFromEng("While creating your program, you can assign your employees in different profiles to complete the tasks. For example, you can add tasks that you want the Buddy or manager of the new recruit to finish in your program flow.")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>

            </Grid.Row>

            <Grid.Row centered columns={2} className="homeV2InfoGraphBox">

                <Grid.Column computer={6} mobile={13} tablet={16}>
                    <Grid.Row className="homeV2InfoGraphContainer">
                        <Grid.Column textAlign="center" computer={16}>
                            <div className="homeV2BannerTitleV4">
                                <div>{translateFromEng("I measure, therefore I am")}</div>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={16} textAlign="left">
                            <div className="homeV2BannerTextV3">
                                {translateFromEng("Thanks to the detailed reporting feature, you can report all the details, whether on a person or program basis, whenever you want. You can export the reports to Excel.")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Column computer={6} textAlign="left" mobile={13} tablet={16}>
                    <Image className="infoGraphImage" src={Report}/>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row centered className="homeV2GetStartedContent">

                <Grid.Column computer={12} textAlign="center" mobile={13} tablet={16}>
                    <Grid.Column className="homeV2BannerTitle">
                        <div>{translateFromEng("Start Building Your Own Onboarding Path")}</div>
                    </Grid.Column>
                </Grid.Column>

                <Grid.Column computer={12} textAlign="left" mobile={13} tablet={16}>
                    <div className="homeV2GetStartedContainer">
                        <div className="homeV2GetStartedGeneralBox">
                            <div className="homeV2GetStartedBox">
                                <Input value={companyName} onChange={(e) => {
                                    setCompanyName(e.target.value)
                                }} className="homeV2Input" type="text" placeholder={translateFromEng("Company Name")}
                                       size="huge"/>
                                <Input value={email} onChange={(e) => {
                                    setEmail(e.target.value)
                                }} className="homeV2Input" type="mail" placeholder="E-mail" size="huge"/>
                                <Button onClick={getStarted} className="homeV2GetStartedButton" size="huge"
                                        circular>{translateFromEng("Get Started")}</Button>
                            </div>
                            <div>
                                <span
                                    className="homeV2GetStatedInfoSection">* {translateFromEng("No credit card required")}</span>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <HomeV2Footer/>
        </Grid>
    )
}


export default HomeV2;