import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Checkbox, Dropdown, Header, Input, Loader, TextArea, Transition } from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import { IDataTable, IDropdown } from '../../../interfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';
import {base64toBlob, checkFieldsIsFilled, digitallicaConfirm} from '../../../helper/HelperFunctions';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import FileInputComp from "../components/FileInputComp";
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
interface Test {
    id?: string,
    testName?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const AddDocument = (props) => {
    var { translate } = TranslateHelper();
    let { id } = useParams();
    var _ = require('lodash');

    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isFormOk, setIsFormOk] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documentFile, setDocumentFile] = useState(""); //COMMENT ???
    const [documentUrl, setDocumentUrl] = useState("");
    const [showFile, setShowFile] = useState(false);
    const [documentId] = useState<any>(id);
    const [loader, setLoader] = useState(true);
    const [childTenants, setChildTenants] = useState<Array<IDropdown>>([]);
    const [selectedTenants, setSelectedTenants] = useState<Array<string>>([]);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [document, setDocument] = useState({
        id: "00000000-0000-0000-0000-000000000000",
        name: "",
        description: "",
        createUserId: DataHandler.getUserDataWithKey("userId"),
        tenantId: tenantId,
        url: "",
        appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
    });

    let styles = {
        formContainer: {
            display: "flex",
            width: "100%",
            flexDirection: "row" as "row",
            gap: "1rem",
            marginBottom: "2.5rem"
        },
        inputStyle: {
            width: "100%"
        },
        btnDark: style({
            width: "228px",
            right: "0px",
            top: "700px",
            borderRadius: "8px",
            padding: "10px 0px !important"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
    }

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setNumPages(numPages);
    }

    const handleChangeDoc = (e) => {
        if (e.target.files[0] == null) {
            setDocumentUrl("");
            setDocumentFile("");
            return;
        }
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setDocumentUrl(e.target.files[0]);
        setDocumentFile(objectUrl);
    }

    const addOrUpdate = (url) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let docClone = _.cloneDeep(document);
            if (selectedTenants.indexOf("1") != -1) {
                let arr = _.map(childTenants, "value");
                arr = _.filter(arr, (elem) => {
                    return elem != "1"
                });
                docClone.selectedTenants = arr;
            } else {
                docClone.selectedTenants = selectedTenants;
            }
            var formData = new FormData();
            formData.append('Data', documentUrl);
            formData.append('Document', JSON.stringify(docClone));

            toast(translate("Lütfen Bekleyin, Doküman Yükleniyor"), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 120000
            });
            NetworkRequest(url, formData, true).then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    window.location.href = "/documents";
                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    // const base64toBlob = (data: string) => {
    //     // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //     const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    //
    //     const bytes = atob(base64WithoutPrefix);
    //     let length = bytes.length;
    //     let out = new Uint8Array(length);
    //
    //     while (length--) {
    //         out[length] = bytes.charCodeAt(length);
    //     }
    //
    //     //return new Blob([out], { type: 'application/pdf' });
    //     return new Blob([out], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    // };

    const checkDocumentId = (documentId: string) => {
        documentId !== undefined ? getById(documentId) : setLoader(false);
    }

    const getById = (documentId) => {
        let data = { id: documentId, checkTenants: true };
        NetworkRequest("/api/Documents/GetById", data).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setDocument(result);
                setDocumentUrl(result.url);
                // setDocumentFile(result.url);
               /* debugger;*/
                let blob = base64toBlob(result.url,result.mimeType)
                setDocumentFile(URL.createObjectURL(blob))
                //console.log(new File([new Uint8Array(result.convertedDocument)], uuidv4(), { type: 'application/pdf' }));
                setLoader(false);
                setSelectedTenants(result.selectedTenants);
            }
        });
    }

    const changeDropdownTenants = (event, data) => {
        setSelectedTenants(data.value);
    }

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                document.name,
                document.description,
                documentUrl,
            )
        );
    }, [document, documentUrl]);

    useEffect(() => {
        checkDocumentId(documentId);
    }, [documentId]);

    useEffect(() => {
        if (userRole == "HeadAdmin") {
            NetworkRequest('/api/Tenants/GetChildTenants', { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
                if (response.data.resultCode == 0) {
                    let list = response.data.detail;
                    if (list.length > 0) {
                        let arr: any = [{
                            text: "Tümü",
                            key: "1",
                            value: "1"
                        }];
                        _.each(list, (doc) => {
                            arr.push({
                                text: doc.displayName,
                                key: doc.tenantId,
                                value: doc.tenantId
                            });
                        });
                        setChildTenants(arr);
                    }
                }
            });
        }
    }, []);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={!isLoading} animation='scale' duration={500}>
                    <div>
                        <div style={styles.formContainer}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column" as "column",
                                gap: "1rem",
                                width: "50%",
                                justifyContent: "flex-start"
                            }}>

                                <Header as='h5'>{translate("Doküman Adı:")} (*)</Header>
                                <Input
                                    className={"greyInput"}
                                    autoFocus
                                    value={document.name}
                                    name="name"
                                    required="required"
                                    style={styles.inputStyle}
                                    size="big"
                                    placeholder={translate("Adı")}
                                    onChange={(e) => {
                                        setDocument({ ...document, name: e.target.value })
                                    }}
                                />

                                <Header as='h5'>{translate("Açıklama:")} (*)</Header>

                                <TextArea
                                    className={"greyInput"}
                                    rows={5} placeholder={translate("Açıklama")}
                                    value={document.description}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setDocument({ ...document, description: e.target.value })
                                    }}
                                />

                                {userRole == "HeadAdmin" && documentId == undefined
                                    &&
                                    <>
                                        <Header as='h5'>{translate("Şirket:")} (*)</Header>
                                        <Dropdown
                                            fluid
                                            multiple
                                            selection
                                            clearable
                                            value={selectedTenants}
                                            name="childTenants"
                                            options={childTenants}
                                            size="big"
                                            placeholder={translate("Şirket Seçiniz")}
                                            onChange={changeDropdownTenants}
                                            className="multipleDropdown greyInput"
                                        />
                                    </>
                                }

                                <Header as='h5'>{translate("Dosya:")} (*)</Header>
                                <FileInputComp
                                    setDocumentFile={setDocumentFile}
                                    setDocumentUrl={setDocumentUrl}
                                    type={"image"}
                                />
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column" as "column",
                                gap: "1rem",
                                width: "50%",
                                justifyContent: "flex-start"
                            }}>

                                <div className="iframeContainerDocument">
                                    {documentId !== undefined ?

                                        <div id="iframe" className="iframeDocument">
                                            <span
                                                style={{ fontSize: "18px", fontWeight: "bold" }}>{translate("Eklenmiş Doküman")} </span>
                                            <iframe src={documentFile} style={{ width: "100%", marginTop: "3%" }}
                                                height="70%" />
                                        </div>
                                        :
                                        <div></div>
                                    }
                                </div>

                                <div style={{ alignSelf: "flex-end", marginTop: "-20%" }}>
                                    {documentId !== undefined
                                        ?
                                        <Button
                                            className={"ingLeaf"}
                                            circular
                                            size="big"
                                            onClick={(e) => {
                                                addOrUpdate("/api/Documents/Update");
                                            }}
                                        >
                                            {translate("Doküman Güncelle")}
                                        </Button>
                                        :
                                        <Button className={"roundedButton"}
                                            color={"blue"}
                                            size="big"
                                            onClick={(e) => {
                                                addOrUpdate("/api/Documents/AddWithFile");
                                            }}
                                            disabled={!isFormOk}
                                        >
                                            {translate("Doküman Ekle")}
                                        </Button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default AddDocument;