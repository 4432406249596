import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Input, Dropdown, Checkbox, Button, Transition, Loader, Icon, Image, Popup } from 'semantic-ui-react'
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { IDropdown, } from '../../../interfaces/interfaces';
import { DateFormat, DateFormatDatePicker } from '../../../helper/DateFormat';
import { isMobile } from "react-device-detect";
import {
    checkFieldsIsFilled,
    digitallicaConfirm,
    getRandomIntInclusive,
    isNullOrEmpty
} from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import PasswordPolicyChecker from "../../../components/PasswordPolicyChecker/PasswordPolicyChecker";
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../../helper/DataHelper';
import { motion } from "framer-motion";
import DigitallicaImageCrop from '../../../components/DigittallicaImageCrop';
import DefaultProfileImage from '../../../images/user.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const UserAdd = (props) => {

    const navigate = useNavigate();
    var { translate } = TranslateHelper();

    let { id } = useParams();
    const _ = require('lodash');
    const [buddyList, setBuddyList] = useState<Array<any>>([]);
    const [itManagerList, setItManagerList] = useState<Array<any>>([]);
    const [adminManagerList, setAdminManagerList] = useState<Array<any>>([]);
    const [managerList, setManagerList] = useState<Array<any>>([]);
    const [humanResourcesList, setHumanResourcesList] = useState<Array<any>>([]);
    const [personalAffairsList, setPersonalAffairsList] = useState<Array<any>>([]);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [loader, setLoader] = useState(true);
    const [canChooseSubscription, setCanChooseSubscription] = useState(false);
    const [isFormOk, setIsFormOk] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);
    const [profilePictureHover, setProfilePictureHover] = useState(false);
    const [userId] = useState<any>(id);
    const [passwordType, setPasswordType] = useState("text");
    const [userList, setUserList] = useState<Array<IDropdown>>([]);
    const [roleList, setRoleList] = useState<Array<IDropdown>>([]);
    const [tenantSubscriptions, setTenantSubscriptions] = useState<Array<IDropdown>>([]);
    const [selectedRoles, setSelectedRoles] = useState<Array<string>>([]);
    const [image, setImage] = useState("");
    const [photoUrl, setPhotoUrl] = useState("");
    const [user, setUser] = useState<any>();
    const [showAddUserWarningPanel, setShowAddUserWarningPanel] = useState<boolean>(true);
    const [isLoadingUserNameField, setIsLoadingUserNameField] = useState<boolean>(false);
    const [emailHeaderStyle, setEmailHeaderStyle] = useState<any>({
        color: "black",

    })

    const [emailPersonalHeaderStyle, setEmailPersonalHeaderStyle] = useState<any>({
        color: "black",

    })
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gender] = useState([
        {
            text: "Erkek",
            key: "1",
            value: "Erkek"
        },
        {
            text: "Kadın",
            key: "2",
            value: "Kadın"
        },
        {
            text: "Belirtmek İstemiyorum",
            key: "3",
            value: "Belirtmek İstemiyorum"
        },
    ]);

    const [isShowPasswordPolicyChecker, setIsShowPasswordPolicyChecker] = useState(false);
    const [passwordPolicyResponseList, setPasswordPolicyResponseList] = useState<any>();
    const [isPasswordPolicyAllDone, setIsPasswordPolicyAllDone] = useState<any>(true);
    const [isCloseAllPasswordSettings, setIsCloseAllPasswordSettings] = useState(false);
    const fileInputRef = useRef<any>();
    const [userPassword, setUserPassword] = useState("");
    const [isLdapUser, setIsLdapUser] = useState(false);


    let styles = {
        userAddSection: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        },
        inputsSection: {
            display: "flex",
            width: "100%",
            padding: "20px",
            flexDirection: "row" as "row",
            gap: "3em",
            justifyContent: "space-between",
            alignItems: isMobile ? "center" : "unset"
        },

        userSection: {
            display: "flex",
            width: "20%",
            padding: "20px",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column" as "column",
            gap: "unset"
        },
        inputSectionBox: {
            display: "flex",
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row" as "row"
        },

        inputStyle: {
            width: "88%"
        },
        inputStyleSemantic: {
            width: "228.8px"
        },
        inputStyleRole: {
            width: "126%"
        },
        inputStyleDate: {
            width: "107%"
        },

        multipleDropdown: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px"
        },

        inputContainer: {
            width: "50%",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "2em"
        },
        labelContainer: {
            width: "30%"
        },
        dropdownWithIcon: {
            width: "130%",
            // height: "50px",
            display: "flex",
            alignItems: "center"
        },
        dropdownUserContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center",
            // border: "1px solid rgba(34,36,38,.15)",
            borderRadius: "2em",
            backgroundColor: "#faebd754",
            width: "120%",
            height: "auto",
            justifyContent: "space-between",
            padding: "5%"

        },
        dropdownUser: {
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center",
            gap: "0.5rem",
            fontWeight: "700"
        },
        otherRequired: {
            display: "flex",
            alignItems: "stretch",
            gap: "1rem",
            color: "#0D71BB",
            fontSize: "1.3rem"
        }
    }



    const checkUserId = (userId: string) => {
        userId !== undefined ? getByUserId(userId) : setLoader(false);
    }

    const getByUserId = (userId) => {
        let userData = {
            id: userId
        }
        NetworkRequest("api/User/GetViewById", userData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;

                setUser({
                    ...user,
                    buddyId: result.buddyId,
                    itManagerId: result.itManagerId,
                    adminManagerId: result.adminManagerId,
                    personalAffairId: result.personalAffairId,
                    departman: result.departman,
                    email: result.email,
                    emailPersonal: result.emailPersonal,
                    humanResourcesId: result.humanResourcesId,
                    id: result.id,
                    isActive: result.isActive,
                    lastLogin: result.lastLogin,
                    managerId: result.managerId,
                    name: result.name,
                    photo: result.photo,
                    phone: result.phone,
                    surname: result.surname,
                    title: result.title,
                    tenantId: tenantId,
                    tenantSubscriptionId: result.tenantSubscriptionId,
                    workStartDate: result.workStartDate,
                    userName: result.userName,
                    functionTitle: result.functionTitle,
                    gender: result.gender,
                    tcNo: result.tcNo,
                    companyName: result.companyName,
                    location: result.location,
                    isTwoFactorEnabled: result.isTwoFactorEnabled,
                    details: result.details != undefined ? JSON.parse(result.details) : undefined
                });

                if (result?.userDetailView?.details != undefined && result?.userDetailView?.details != null) {
                    setIsLdapUser(JSON.parse(result.userDetailView?.details)?.isLdapUser || false);
                } else {
                    setIsLdapUser(false);
                }
                

                setLoader(false);
                let userRoleIds: any = [];
                result.userRole.map((role, index) => {
                    userRoleIds.push(role.role.roleId);
                })

                setSelectedRoles(userRoleIds);
            }
        });
    }

    const apiBuddySuccesss = (response: any) => {
        if (response.data.resultCode === 0) {
            let arr: any = [];
            _.each(response.data.detail, (doc) => {
                arr.push(
                    {
                        text: doc.name + " " + doc.surname,
                        key: doc.id,
                        value: doc.id,
                        image: {
                            avatar: true,
                            src: displayProtectedAppDoc(doc.photo != null ? doc.photo : DataHandler.getDocmanagerUrl() + "/Documents/DefaultUserImage/user.png")
                        }
                    }
                );
            });
            setBuddyList(arr);
        }
    }

    const apiAssignedManagerSuccesss = (response: any) => {
        if (response.data.resultCode === 0) {
            let arr: any = [];
            _.each(response.data.detail, (doc) => {
                arr.push(
                    {
                        text: doc.name + " " + doc.surname,
                        key: doc.id,
                        value: doc.id,
                        image: {
                            avatar: true,
                            src: displayProtectedAppDoc(doc.photo != null ? doc.photo : DataHandler.getDocmanagerUrl() + "/Documents/DefaultUserImage/user.png")
                        }
                    }
                );
            });
            setManagerList(arr);
        }
    }

    const apiHumanResourceSuccesss = (response: any) => {
        if (response.data.resultCode === 0) {
            let arr: any = [];
            _.each(response.data.detail, (doc) => {
                arr.push(
                    {
                        text: doc.name + " " + doc.surname,
                        key: doc.id,
                        value: doc.id,
                        image: {
                            avatar: true,
                            src: displayProtectedAppDoc(doc.photo != null ? doc.photo : DataHandler.getDocmanagerUrl() + "/Documents/DefaultUserImage/user.png")
                        }
                    }
                );
            });
            setHumanResourcesList(arr);
        }
    }

    const apiItManagerSuccesss = (response: any) => {
        if (response.data.resultCode === 0) {
            let arr: any = [];
            let promiseArr: any = [];
            _.each(response.data.detail, (doc) => {
                arr.push(
                    {
                        text: doc.name + " " + doc.surname,
                        key: doc.id,
                        value: doc.id,
                        image: {
                            avatar: true,
                            src: displayProtectedAppDoc(doc.photo != null ? doc.photo : DataHandler.getDocmanagerUrl() + "/Documents/DefaultUserImage/user.png")
                        }
                    }
                );
            });
            setItManagerList(arr);
        }
    }

    const apiAdminManagerResourceSuccesss = (response: any) => {
        if (response.data.resultCode === 0) {
            let arr: any = [];
            _.each(response.data.detail, (doc) => {
                arr.push(
                    {
                        text: doc.name + " " + doc.surname,
                        key: doc.id,
                        value: doc.id,
                        image: {
                            avatar: true,
                            src: displayProtectedAppDoc(doc.photo != null ? doc.photo : DataHandler.getDocmanagerUrl() + "/Documents/DefaultUserImage/user.png")
                        }
                    }
                );
            });
            setAdminManagerList(arr);
        }
    }

    const apiPersonalAffairsSuccesss = (response: any) => {
        if (response.data.resultCode === 0) {
            let arr: any = [];
            _.each(response.data.detail, (doc) => {
                arr.push(
                    {
                        text: doc.name + " " + doc.surname,
                        key: doc.id,
                        value: doc.id,
                        image: {
                            avatar: true,
                            src: displayProtectedAppDoc(doc.photo != null ? doc.photo : DataHandler.getDocmanagerUrl() + "/Documents/DefaultUserImage/user.png")
                        }
                    }
                );
            });
            setPersonalAffairsList(arr);
        }
    }

    const getAllUsers = () => {
        NetworkRequest("api/User/GetAllAssignedBuddies", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            apiBuddySuccesss(response);
        });
        NetworkRequest("api/User/GetAllAssignedManagers", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            apiAssignedManagerSuccesss(response);
        });
        NetworkRequest("api/User/GetAllAssignedHumanResources", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            apiHumanResourceSuccesss(response);
        });
        NetworkRequest("api/User/GetAllAssignedItManagers", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            apiItManagerSuccesss(response);
        });
        NetworkRequest("api/User/GetAllAssignedAdminManagers", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            apiAdminManagerResourceSuccesss(response);
        });
        NetworkRequest("api/User/GetAllAssignedPersonalAffairs", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            apiPersonalAffairsSuccesss(response);
        });
    }

    const getTenantSubscriptions = () => {
        NetworkRequest("/api/TenantSubscriptions/GetListByTenantId", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {

            if (response.data.resultCode === 0) {
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response?.data?.detail?.length; i++) {
                    list.push({
                        key: response.data.detail[i].tenantSubscriptionId,
                        value: response.data.detail[i].tenantSubscriptionId,
                        text: translate(response.data.detail[i].displayName)
                    });
                }
                setTenantSubscriptions(list);
                setTimeout(() => {

                    if (userId == undefined)
                        setUser({
                            ...user,
                            tenantSubscriptionId: response.data.detail[0].tenantSubscriptionId,
                            tenantId: tenantId
                        });

                }, 500)
            }
        });
    }

    const getUserRoles = () => {
        NetworkRequest("api/UserRole/GetRoles").then(response => {

            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                result = result.filter(x => x.roleName != "SuperAdmin");
                const roleToDropdownType = result.map(elem => (
                    {
                        text: elem.displayName,
                        key: elem.roleId,
                        value: elem.roleId
                    }
                ));
                setRoleList(roleToDropdownType);
            }
        });
    }

    const insertOrUpdate = (url: string) => {

        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            setIsLoading(true);
            user.appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';
            var formData = new FormData();
            formData.append('File', photoUrl);
            formData.append('Request', JSON.stringify({ ...user, details: JSON.stringify(user.details) }));
            formData.append('Roles', JSON.stringify(selectedRoles));

            if (url == 'api/User/Add' && (user?.password == undefined || user?.password == "")) {
                toast(translate("Şifre boş geçilemez."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
                return;
            }



            NetworkRequest(url, formData, true).then((response) => {
                ////
                if (response.data.resultCode == 0) {
                    toast(translate(response.data.message), {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    setTimeout(() => {
                        navigate("/users");
                        //window.location.href = "/users"
                    }, 1000)
                } else {
                    toast(response.data.detail, {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                    setIsLoading(false);
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
        });
    }

    const onDefaultEmailHandler = (e, data, type) => {
        if (data.checked) {
            setUser({
                ...user,
                details: { ...user.details, ["notificationMailType"]: type }
            });
        }
    }

    const handleChange = (e) => {

        let name = e?.target?.name || "workStartDate";
        var value = e?.target?.value;

        if (name == "workStartDate") {
            value = e;
        }

        if (name === "password-digitallica") {
            setPasswordType("password");
            name = "password";
        }
        setUser({
            ...user,
            [name]:
                name == "email" ||
                    name == "emailPersonal" ||
                    name == "userName"
                    ? value.toLowerCase() : value
        });


        if (name == "password-digitallica") {

            setUserPassword(value.trim());

            //let passwordData = {
            //    tenantId: DataHandler.getUserDataWithKey("tenantId"),
            //    password: e.target.value.trim()
            //}

            //const checkPasswordTimer = setTimeout(() => {
            //    NetworkRequest("api/Settings/CheckPasswordPolicyRequirementsStatus", passwordData, true).then((response) => {
            //        ////
            //        if (response.data.resultCode == 0) {
            //            let data = response.data.detail;
            //            let list = data.passwordPolicyRequirementsStatusList;
            //            let isAllPassed = data.isAllPassed;

            //            setPasswordPolicyResponseList(list);
            //            setIsPasswordPolicyAllDone(isAllPassed);
            //            setIsCloseAllPasswordSettings(list != null);
            //        }
            //    });
            //}, 2000);

            //return () => clearTimeout(checkPasswordTimer)

        } else {
            setIsLoading(false);
        }

    }

    useEffect(() => {

        let passwordData = {
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            password: userPassword
        }

        /*     console.log(passwordData);*/

        const checkPasswordTimer = setTimeout(() => {
            NetworkRequest("api/Settings/CheckPasswordPolicyRequirementsStatus", passwordData, true).then((response) => {
                ////
                if (response.data.resultCode == 0) {
                    let data = response.data.detail;
                    let list = data.passwordPolicyRequirementsStatusList;
                    let isAllPassed = data.isAllPassed;

                    setPasswordPolicyResponseList(list);
                    setIsPasswordPolicyAllDone(isAllPassed);
                    setIsCloseAllPasswordSettings(list != null);
                }
            });
        }, 1000);

        return () => clearTimeout(checkPasswordTimer)
    }, [userPassword])

    const removeSpaces = (e) => {
        let name = e.target.name;
        setUser({
            ...user,
            [name]: e.target.value.split(' ').join('')
        });

        if (name == "email" || name == "emailPersonal")
            checkEmail(user[name], name);
    }

    const searchResponsibleUsersHandler = (e, list, listSetter) => {
        var text = e == null ? "" : e.target.value;
        var listClone = _.cloneDeep(list);
        for (var i = 0; i < listClone?.length; i++) {
            listClone[i] = { ...listClone[i], hidden: listClone[i].text.toLowerCase().indexOf(text.toLowerCase()) == -1 }
        }
        listSetter(listClone);
    }

    const clearResponsibleUser = (dataName) => {
        setUser({
            ...user,
            [dataName]: null
        });
    }

    const getResponsibleUserById = (id, list, propertyName) => {
        if (id == undefined) {

            return;
        }


        var _user = list.find(x => x.key == id);
        let rnd = getRandomIntInclusive();

        if (_user != undefined && _user != null && _user.lenght != 0 && list?.length != 0) {
            return <div style={styles.dropdownUserContainer}>
                <div style={styles.dropdownUser}>
                    <img id={"userImage-1" + rnd}
                        src={_user?.image?.src == null ? DefaultProfileImage : _user?.image?.src}
                        style={{ borderRadius: "50%" }}
                        width="35"
                        height="35"
                    />
                    <span>{_user?.text}</span>
                </div>
                <Icon name="close" color="red" className="closeHover" onClick={() => {
                    clearResponsibleUser(propertyName)
                }} />
            </div>
        } else {
            return (<div style={styles.dropdownUserContainer}>
                <div style={styles.dropdownUser}>
                    <img id={"genericUserImage" + rnd}
                        src={DefaultProfileImage}
                        style={{ borderRadius: "50%" }}
                        width="35"
                        height="35"
                    />
                    <span>{translate("Çalışan Bulunamadı")}</span>
                </div>
                <Icon name="close" color="red" className="closeHover" onClick={() => {
                    clearResponsibleUser(propertyName)
                }} />
            </div>);
        }
    }

    const changeDropdown = (event, data) => {

        setUser({
            ...user,
            [data.name]: data.value == "" ? null : data.value
        });
    }

    const changeDropdownRoles = (event, data) => {
        setSelectedRoles(data.value);
    }

    const handleChangeImage = (data) => {
        const objectUrl = URL.createObjectURL(data);
        setPhotoUrl(data);
        setImage(objectUrl);
    }

    const selectFiles = (): any => {
        if (fileInputRef?.current?.inputRef?.current?.files?.length) {
            handleChangeImage(fileInputRef?.current?.inputRef?.current?.files[0]);
        }
    }

    const getSubscriptionSetting = () => {
        NetworkRequest('api/Settings/GetSubscriptionSetting', { id: tenantId }).then((response) => {
            if (response.data.resultCode == 0) {
                setCanChooseSubscription(response.data.detail);
            }
        });
    }

    const checkEmail = (email, name) => {

        if (email === undefined || email?.length === 0 || email === null)
            return;

        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var address = email;
        if (reg.test(address) == false) {

            if (name == "emailPersonal") {
                setEmailPersonalHeaderStyle({
                    color: "red"
                })
            } else {
                setEmailHeaderStyle({
                    color: "red"
                })
            }

            toast(translate("Email adresi geçerli değil"), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });

            //setIsFormValid(false);
            return false;
        }
        else {
            if (name == "emailPersonal") {
                setEmailPersonalHeaderStyle({
                    color: "black"
                });
            } else {
                setEmailHeaderStyle({
                    color: "black"
                });
            }

            setIsFormValid(true)
        }
    }

    const getMotionImageUrl = () => {

        if (!isNullOrEmpty(image)) {
            return displayProtectedAppDoc(image, "motionImage");
        }
        if (user != undefined && user.photo != undefined) {
            return displayProtectedAppDoc(user.photo, "motionImage");
        }
        return DefaultProfileImage;
    }


    //const checkRequiredFieldForWarningPanel = () => {
    //    return !(
    //        (user?.name?.trim() == undefined || user?.name?.trim().length == 0) 
    //        || (user?.surname?.trim() == undefined || user?.surname?.trim().length == 0) 
    //        || (user?.userName?.trim() == undefined || user?.userName?.trim().length == 0) 
    //        || (user?.email?.trim() == undefined || user?.email?.trim().length == 0) 
    //        || (user?.emailPersonal?.trim() == undefined || user?.emailPersonal?.trim().length == 0) 
    //        || (user?.details?.notificationMailType == undefined || user?.details?.notificationMailType.length == 0) 
    //        || (user?.workStartDate == null)
    //        || (selectedRoles.length == 0) 
    //    )

    //}

    useEffect(() => {
        checkUserId(userId);
        getAllUsers();
        getUserRoles();
        getTenantSubscriptions();
    }, []);

    const generateUserName = (name, surname, userName) => {
        setIsLoadingUserNameField(true);

        let data = {
            fullName: name + "." + surname,
            tenantId: tenantId
        };

        NetworkRequest("api/User/GenerateUserName", data, true).then((response) => {
            if (response.data.resultCode == 0) {
                var data = response.data.detail;
                setUser({ ...user, userName: data })
            }

            setIsLoadingUserNameField(false);
        });
    }

    useEffect(() => {

        if (window.location.href.split("/")[3] === "userEdit") {
            setShowAddUserWarningPanel((
                (user?.name?.trim() == undefined || user?.name?.trim().length == 0)
                || (user?.surname?.trim() == undefined || user?.surname?.trim()?.length == 0)
                || (user?.userName?.trim() == undefined || user?.userName?.trim()?.length == 0)
                || (user?.email?.trim().length == 0 && user?.emailPersonal?.trim()?.length == 0)
                || ((user?.details?.notificationMailType == 1 && user?.email?.trim()?.length == 0) || (user?.details?.notificationMailType == 2 && user?.emailPersonal?.trim().length == 0))
                || (user?.details?.notificationMailType == undefined || user?.details?.notificationMailType?.length == 0)
                || (user?.workStartDate == null)
                || (selectedRoles?.length == 0)
            ))
        } else {
            setShowAddUserWarningPanel((
                (user?.name?.trim() == undefined || user?.name?.trim()?.length == 0)
                || (user?.surname?.trim() == undefined || user?.surname?.trim()?.length == 0)
                || (user?.userName?.trim() == undefined || user?.userName?.trim()?.length == 0)
                || (user?.email?.trim().length == 0 && user?.emailPersonal?.trim()?.length == 0)
                || ((user?.details?.notificationMailType == 1 && (user?.email?.trim()?.length == 0 || user?.email?.trim() == undefined)) || (user?.details?.notificationMailType == 2 && (user?.emailPersonal?.trim().length == 0 || user?.emailPersonal?.trim() == undefined)))
                || (user?.password?.trim() == undefined || user?.password?.trim()?.length == 0)
                || (user?.details?.notificationMailType == undefined || user?.details?.notificationMailType?.length == 0)
                || (user?.workStartDate == null)
                || (selectedRoles?.length == 0)
            ))
        }




    }, [user, selectedRoles]);

    useEffect(() => {

        if (window.location.href.split("/")[3] == "userEdit")
            return;

        if (
            !user
            || user?.name?.trim()?.length == 0
            || user?.surname?.trim()?.length == 0
            || user?.name == undefined
            || user?.surname == undefined
        )
            return;

        const checkUserNameTimer = setTimeout(() => {
            generateUserName(user?.name, user?.surname, user?.userName)
        }, 2000);

        return (() => clearTimeout(checkUserNameTimer))


    }, [user?.name, user?.surname])

    useEffect(() => {
        if (tenantId != undefined) {
            setUser({ ...user, tenantId: tenantId });
            getSubscriptionSetting();
        }
    }, [tenantId]);

    useEffect(() => {



        var result = selectedRoles?.length != 0 &&
            checkFieldsIsFilled(
                // user?.email,
                user?.userName,
                user?.name,
                user?.surname,
                user?.workStartDate,

            );


        if (result && user?.details != undefined) {
            result = (
                (user?.details.notificationMailType == 1 && user?.email?.trim()?.length > 0)
                || (user?.details.notificationMailType == 2 && user?.emailPersonal?.trim()?.length > 0))

        } else {
            result = false;
        }
        setIsFormOk(result);

    }, [user, selectedRoles]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={styles.userAddSection}>
                <Transition visible={loader} animation='scale' duration={500}>
                    <div>
                        <Loader size="huge" active inline='centered' />
                    </div>
                </Transition>

                <Transition visible={!loader} animation='scale' duration={500}>
                    <div style={{
                        width: "100%", display: "flex!important", alignItems: "center",
                        gap: "1rem",
                        flexDirection: "column"
                    }}>
                        <div style={styles.inputsSection}>
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row" as "row",
                                    gap: "unset"
                                }}>
                                <div style={styles.inputContainer}>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Adı: (*)")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                disabled={isLdapUser}
                                                className={"greyInput"}
                                                value={user?.name || ""}
                                                name="name"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("Adı")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Soyadı: (*)")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                disabled={isLdapUser}
                                                className={"greyInput"}
                                                value={user?.surname || ""}
                                                name="surname"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("Soyadı")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Ünvan:")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                value={user?.title || ""}
                                                name="title"
                                                style={styles.inputStyle}
                                                placeholder={translate("Ünvan")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {userId !== undefined &&
                                        <div style={styles.inputSectionBox}>
                                            <div style={styles.labelContainer}>
                                                <Header as='h3'>{translate("Şirket:")}</Header>
                                            </div>
                                            <div>
                                                <Input
                                                    className={"greyInput"}
                                                    value={user?.companyName || ""}
                                                    name="companyName"
                                                    style={styles.inputStyle}
                                                    placeholder={translate("Şirket")}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Fonksiyon:")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                value={user?.functionTitle || ""}
                                                name="functionTitle"
                                                style={styles.inputStyle}
                                                placeholder={translate("Fonksiyon")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Bölüm Adı:")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                value={user?.departman || ""}
                                                name="departman"
                                                style={styles.inputStyle}
                                                placeholder={translate("Bölüm Adı")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Buddy:")}</Header>
                                        </div>
                                        <div>
                                            {user?.buddyId != null
                                                &&
                                                getResponsibleUserById(user?.buddyId, buddyList, "buddyId")
                                            }
                                            {user?.buddyId == null
                                                &&
                                                <Dropdown
                                                    value={user?.buddyId}
                                                    text={translate("Seçiniz")}
                                                    icon='add user'
                                                    floating
                                                    labeled
                                                    button
                                                    clearable
                                                    className='icon searchableDropdown greyInput'
                                                    style={styles.dropdownWithIcon}
                                                    name="buddyId"
                                                    onSearchChange={(e) => {
                                                        searchResponsibleUsersHandler(e, buddyList, setBuddyList)
                                                    }}
                                                    search
                                                    onBlur={(e) => {
                                                        searchResponsibleUsersHandler(null, buddyList, setBuddyList)
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header content={translate("Buddy Seçiniz")} />
                                                        {buddyList.filter(x => !x.hidden).map((option) => (
                                                            <Dropdown.Item

                                                                name="buddyId"
                                                                {...option}
                                                                onClick={changeDropdown}
                                                                selected={option.id == user?.buddyId}
                                                            />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </div>
                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Yönetici:")}</Header>
                                        </div>
                                        <div>
                                            {user?.managerId != null
                                                &&
                                                getResponsibleUserById(user?.managerId, managerList, "managerId")
                                            }
                                            {user?.managerId == null
                                                &&
                                                <Dropdown
                                                    value={user?.managerId}
                                                    text={translate("Seçiniz")}
                                                    icon='add user'
                                                    floating
                                                    labeled
                                                    button
                                                    clearablef="true"
                                                    className='icon searchableDropdown greyInput'
                                                    style={styles.dropdownWithIcon}
                                                    onChange={changeDropdown}
                                                    name="managerId"
                                                    onSearchChange={(e) => {
                                                        searchResponsibleUsersHandler(e, managerList, setManagerList)
                                                    }}
                                                    search
                                                    onBlur={(e) => {
                                                        searchResponsibleUsersHandler(null, managerList, setManagerList)
                                                    }}

                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header content={translate("Yönetici Seçiniz")} />
                                                        {managerList.filter(x => !x.hidden).map((option) => (
                                                            <Dropdown.Item
                                                                {...option} name="managerId"
                                                                onClick={changeDropdown}
                                                                selected={option.id == user?.managerId}
                                                            />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("IK Sorumlusu:")}</Header>
                                        </div>
                                        <div>

                                            {user?.humanResourcesId != null
                                                &&
                                                getResponsibleUserById(user?.humanResourcesId, humanResourcesList, "humanResourcesId")
                                            }
                                            {user?.humanResourcesId == null
                                                &&
                                                <Dropdown
                                                    value={user?.humanResourcesId}
                                                    text={translate("Seçiniz")}
                                                    icon='add user'
                                                    floating
                                                    labeled
                                                    button
                                                    clearable
                                                    className='icon searchableDropdown greyInput'
                                                    style={styles.dropdownWithIcon}
                                                    search
                                                    onSearchChange={(e) => {
                                                        searchResponsibleUsersHandler(e, humanResourcesList, setHumanResourcesList)
                                                    }}
                                                    onBlur={(e) => {
                                                        searchResponsibleUsersHandler(null, humanResourcesList, setHumanResourcesList)
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header content={translate("IK Sorumlusu Seçiniz")} />
                                                        {humanResourcesList.filter(x => !x.hidden).map((option) => (
                                                            <Dropdown.Item onClick={changeDropdown}
                                                                name="humanResourcesId" {...option}
                                                                selected={option.id == user?.humanResourcesId}
                                                            />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            }


                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("BT Sorumlusu")}:</Header>
                                        </div>
                                        <div>
                                            {user?.itManagerId != null
                                                &&
                                                getResponsibleUserById(user?.itManagerId, itManagerList, "itManagerId")
                                            }
                                            {user?.itManagerId == null
                                                &&
                                                <Dropdown
                                                    value={user?.adminManagerId}
                                                    text={translate("Seçiniz")}
                                                    icon='add user'
                                                    floating
                                                    labeled
                                                    button
                                                    clearable
                                                    className='icon searchableDropdown greyInput'
                                                    style={styles.dropdownWithIcon}
                                                    search
                                                    onSearchChange={(e) => {
                                                        searchResponsibleUsersHandler(e, itManagerList, setItManagerList)
                                                    }}
                                                    onBlur={(e) => {
                                                        searchResponsibleUsersHandler(null, itManagerList, setItManagerList)
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header content={translate("BT Sorumlusu Seçiniz")} />
                                                        {itManagerList.filter(x => !x.hidden).map((option) => (
                                                            <Dropdown.Item onClick={changeDropdown}
                                                                name="itManagerId" {...option}
                                                                selected={option.id == user?.itMaangerId}
                                                            />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("İdari İşler Sorumlusu:")}</Header>
                                        </div>
                                        <div>
                                            {user?.adminManagerId != null
                                                &&
                                                getResponsibleUserById(user?.adminManagerId, adminManagerList, "adminManagerId")
                                            }
                                            {user?.adminManagerId == null
                                                &&
                                                <Dropdown

                                                    value={user?.adminManagerId}
                                                    text={translate("Seçiniz")}
                                                    icon='add user'
                                                    floating
                                                    labeled
                                                    button
                                                    clearable
                                                    className='icon searchableDropdown greyInput'
                                                    style={styles.dropdownWithIcon}
                                                    search
                                                    onSearchChange={(e) => {
                                                        searchResponsibleUsersHandler(e, adminManagerList, setAdminManagerList)
                                                    }}
                                                    onBlur={(e) => {
                                                        searchResponsibleUsersHandler(null, adminManagerList, setAdminManagerList)
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header
                                                            content={translate("İdari İşler Sorumlusu Seçiniz")} />
                                                        {adminManagerList.filter(x => !x.hidden).map((option) => (
                                                            <Dropdown.Item onClick={changeDropdown}
                                                                name="adminManagerId" {...option}
                                                                selected={option.id == user?.adminManagerId}
                                                            />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Özlük İşler Sorumlusu:")}</Header>
                                        </div>
                                        <div>
                                            {user?.personalAffairId != null
                                                &&
                                                getResponsibleUserById(user?.personalAffairId, personalAffairsList, "personalAffairId")
                                            }
                                            {user?.personalAffairId == null
                                                &&
                                                <Dropdown

                                                    value={user?.personalAffairId}
                                                    text={translate("Seçiniz")}
                                                    icon='add user'
                                                    floating
                                                    labeled
                                                    button
                                                    clearable
                                                    className='icon searchableDropdown greyInput'
                                                    style={styles.dropdownWithIcon}
                                                    search
                                                    onSearchChange={(e) => {
                                                        searchResponsibleUsersHandler(e, personalAffairsList, setPersonalAffairsList)
                                                    }}
                                                    onBlur={(e) => {
                                                        searchResponsibleUsersHandler(null, personalAffairsList, setPersonalAffairsList)
                                                    }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Header
                                                            content={translate("Özlük İşler Sorumlusu Seçiniz")} />
                                                        {personalAffairsList.filter(x => !x.hidden).map((option) => (
                                                            <Dropdown.Item onClick={changeDropdown}
                                                                name="personalAffairId" {...option}
                                                                selected={option.id == user?.personalAffairId}
                                                            />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div style={styles.inputContainer}>
                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Kullanıcı Adı: (*)")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                loading={isLoadingUserNameField}
                                                disabled={isLoadingUserNameField || isLdapUser}
                                                className={"greyInput"}
                                                autoComplete="off"
                                                value={user?.userName || ""}
                                                onBlur={removeSpaces}
                                                name="userName"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("Kullanıcı Adı")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3' id="emailHeader" style={emailHeaderStyle}>{translate("E-posta Adresi")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                disabled={isLdapUser}
                                                className={"greyInput"}
                                                type="email"
                                                autoComplete="off"
                                                value={user?.email || ""}
                                                onBlur={removeSpaces}
                                                name="email"
                                                required="required"
                                                style={styles.inputStyle}
                                                placeholder={translate("E-posta")}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div style={styles.otherRequired}>
                                            <Checkbox
                                                toggle
                                                name="defaultEmail1"
                                                checked={user?.details == undefined ? false : user?.details.notificationMailType == 1}
                                                onChange={(e, data) => {
                                                    onDefaultEmailHandler(e, data, 1);
                                                }}
                                            /> (**)
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3' style={emailPersonalHeaderStyle} id="personalEmailHeader">{translate("Kişisel E-posta Adresi:")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                type="email"
                                                autoComplete="off"
                                                value={user?.emailPersonal || ""}
                                                onBlur={removeSpaces}
                                                name="emailPersonal"
                                                style={styles.inputStyle}
                                                placeholder={translate("Kişisel E-posta")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div style={styles.otherRequired}>
                                            <Checkbox
                                                toggle
                                                name="defaultEmail2"
                                                checked={user?.details == undefined ? false : user?.details.notificationMailType == 2}
                                                onChange={(e, data) => {
                                                    onDefaultEmailHandler(e, data, 2);
                                                }}
                                            /> (**)
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header
                                                as='h3'>{translate("Şifre:")} {userId !== undefined ? "" : "(*)"}</Header>
                                        </div>
                                        <div>
                                            <div className={"newPasswordField"}>
                                                <PasswordPolicyChecker
                                                    Left={["90%", "100%"]}
                                                    PasswordPolicyResponseList={passwordPolicyResponseList}
                                                    IsShow={isShowPasswordPolicyChecker}
                                                />
                                            </div>
                                            <Input
                                                onFocus={() => { setIsShowPasswordPolicyChecker(true) }}
                                                onBlur={() => { setIsShowPasswordPolicyChecker(false) }}
                                                className={"greyInput"}
                                                autoComplete="new-password"
                                                id="new-password"
                                                value={user?.password || ""}
                                                name="password-digitallica"
                                                required={true}
                                                type={passwordType}
                                                style={styles.inputStyle}
                                                placeholder={translate("Şifre")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("İşe Giriş Tarihi: (*)")}</Header>
                                        </div>
                                        <div>
                                            {/*<Input*/}
                                            {/*    placeholder="dd-mm-yyyy" */}
                                            {/*    min="1997-01-01"*/}
                                            {/*    max="2030-12-31"*/}


                                            {/*    max-length="8"*/}
                                            {/*    className={"greyInput"}*/}
                                            {/*    timezone="[[timezone]]"*/}
                                            {/*    value={DateFormat(user?.workStartDate, true)}*/}
                                            {/*    name="workStartDate"*/}
                                            {/*    required="required"*/}
                                            {/*    type="date"*/}
                                            {/*    style={{ ...styles.inputStyleDate, ...styles.inputStyleSemantic }}*/}
                                            {/*    onChange={handleChange}*/}
                                            {/*/>*/}

                                            <DatePicker

                                                isClearable
                                                showYearDropdown
                                                yearDropdownItemNumber={10}
                                                scrollableYearDropdown
                                                name="workStartDate"
                                                type="date"
                                                className={"greyInput datePickerCustomClass"}

                                                maxLength="8"
                                                selected={user?.workStartDate}
                                                value={DateFormatDatePicker(user?.workStartDate, false)}
                                                onChange={date => handleChange(date)}
                                                placeholderText="dd-mm-yyyy"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Cinsiyet:")} </Header>
                                        </div>
                                        <div>
                                            <Dropdown
                                                className={"greyInput"}
                                                value={user?.gender}
                                                name="gender"
                                                required="required"
                                                clearable
                                                options={gender}
                                                selection
                                                placeholder={translate("Cinsiyet Seçiniz")}
                                                onChange={changeDropdown}
                                                style={styles.inputStyleSemantic}

                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("TC No") + ":"}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                value={user?.tcNo || ""}
                                                name="tcNo"
                                                style={styles.inputStyle}
                                                placeholder={translate("TC No")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Lokasyon:")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                value={user?.location || ""}
                                                name="location"
                                                style={styles.inputStyle}
                                                placeholder={translate("Lokasyon")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Telefon No:")}</Header>
                                        </div>
                                        <div>
                                            <Input
                                                className={"greyInput"}
                                                value={user?.phone || ""}
                                                name="phone"
                                                style={styles.inputStyle}
                                                placeholder={translate("Telefon No")}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    {(canChooseSubscription || user?.tenantSubscriptionId == undefined || user?.tenantSubscriptionId == null || user?.tenantSubscriptionId == "")
                                        &&
                                        <div style={styles.inputSectionBox}>
                                            <div style={styles.labelContainer}>
                                                <Header as='h3'>{translate("Abonelik: (*)")}</Header>
                                            </div>
                                            <div>
                                                <Dropdown
                                                    className={"greyInput"}
                                                    value={user?.tenantSubscriptionId}
                                                    name="tenantSubscriptionId"
                                                    required="required"
                                                    clearable
                                                    options={tenantSubscriptions}
                                                    selection
                                                    placeholder={translate("Abonelik Seçiniz")}
                                                    onChange={changeDropdown}
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("Çalışan Rolü: (*)")}</Header>
                                        </div>
                                        <div>
                                            <Dropdown
                                                className={"greyInput"}
                                                fluid multiple selection
                                                value={selectedRoles}
                                                name="userRoles"
                                                clearable
                                                options={roleList}
                                                size="big"
                                                placeholder={translate("Çalışan Rolü Seçiniz")}
                                                onChange={changeDropdownRoles}
                                                style={{ ...styles.multipleDropdown, ...styles.inputStyleRole }}
                                            />
                                        </div>
                                    </div>

                                    {userId !== undefined &&
                                        <div style={styles.inputSectionBox}>
                                            <div style={styles.labelContainer}>
                                                <Header as='h3'>{translate("Aktif:")}</Header>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    checked={user?.isActive}
                                                    slider
                                                    onChange={(e, data) => {
                                                        setUser({
                                                            ...user,
                                                            isActive: data.checked != undefined ? data.checked : false
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {userId !== undefined &&
                                        <div style={styles.inputSectionBox}>
                                            <div style={styles.labelContainer}>
                                                <Header as='h3'>{translate("LDAP Kullanıcısı:")}</Header>
                                            </div>
                                            <div>
                                                {/*<Checkbox*/}
                                                {/*    disabled*/}
                                                {/*    checked={isLdapUser}*/}
                                                {/*    slider*/}
                                                {/*/>*/}
                                                <Popup
                                                    content={isLdapUser ? translate("LDAP kullanıcısı") : translate("Sistem kullanıcısı")}
                                                    trigger={
                                                        <Icon name="circle" style={{ color: isLdapUser ? "green" : "#cccccc", cursor: "auto" as "auto" }} />
                                                    }
                                                />

                                            </div>
                                        </div>
                                    }

                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.labelContainer}>
                                            <Header as='h3'>{translate("İki Faktörlü Kimlik Doğrulama")}</Header>
                                        </div>
                                        <div>
                                            <Checkbox
                                                checked={user?.isTwoFactorEnabled}
                                                slider
                                                onChange={(e, data) => {
                                                    setUser({
                                                        ...user,
                                                        isTwoFactorEnabled: data.checked != undefined ? data.checked : false
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div style={styles.userSection}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column" as "column",
                                    alignItems: "center"
                                }}>
                                    {loader === false
                                        &&
                                        <>
                                            <motion.div
                                                onClick={() => {
                                                    document.getElementById("userAddPhoto")?.click();
                                                    //fileInputRef.current.click();
                                                }}
                                                className="userAddChooseProfilePicture"
                                                onMouseEnter={() => {
                                                    setProfilePictureHover(true)
                                                }}
                                                onMouseLeave={() => {
                                                    setProfilePictureHover(false)
                                                }}
                                                animate={profilePictureHover ? { opacity: [0, 1] } : {
                                                    opacity: [1, 0],
                                                    marginTop: ["0px", "-10px"]
                                                }}
                                            >
                                                <div style={{ width: "100%" }}>{translate("Fotoğraf Seç")}</div>
                                            </motion.div>
                                            <Input
                                                id="userAddPhoto"
                                                ref={fileInputRef}
                                                style={{ display: "none" }}
                                                type="file"
                                                accept="image/*"
                                                onChange={selectFiles}
                                            />
                                            <DigitallicaImageCrop
                                                imageUrl={user.photo ? user.photo : ""}
                                                userId={userId}
                                                setImage={setImage}
                                            />
                                            <motion.img

                                                id="motionImage"
                                                style={{
                                                    width: "300px",
                                                    height: "300px",
                                                    borderRadius: "50%",
                                                    filter: profilePictureHover ? "blur(50px)" : "blur(0)",
                                                    cursor: "pointer",
                                                    transition: "all 1s"
                                                }}
                                                onMouseEnter={() => {
                                                    setProfilePictureHover(true)
                                                }}
                                                onMouseLeave={() => {
                                                    setProfilePictureHover(false)
                                                }}
                                                src={getMotionImageUrl()}
                                            />
                                        </>
                                    }
                                </div>

                                <Transition visible={showAddUserWarningPanel} animation='scale' duration={500}>
                                    <div className={"addUserFormControlContainer"}>
                                        <Header as="h3" content={translate("Uyarı")} />
                                        <div>{(user?.name?.trim() == undefined || user?.name?.trim()?.length == 0) && translate("* Çalışan ismi zorunlu alandır.")}</div>
                                        <div>{(user?.surname?.trim() == undefined || user?.surname?.trim()?.length == 0) && translate("* Çalışan soyadı zorunlu alandır.")}</div>
                                        <div>{(user?.userName?.trim() == undefined || user?.userName?.trim()?.length == 0) && translate("* Çalışan Adı zorunlu alandır.")}</div>
                                        <div>{(user?.email?.trim().length == 0 && user?.emailPersonal?.trim()?.length == 0) && translate("* E-posta adresi veya Kişisel E-posta adresi zorunlu alandır.")}</div>
                                        <div>{((user?.details?.notificationMailType == 1 && (user?.email?.trim()?.length == 0 || user?.email?.trim() == undefined)) || (user?.details?.notificationMailType == 2 && (user?.emailPersonal?.trim().length == 0 || user?.emailPersonal?.trim() == undefined))) && translate("* Seçilen E-posta adresi boş olamaz.")}</div>
                                        <div>{(window.location.href.split("/")[3] !== "userEdit") && (user?.password?.trim() == undefined || user?.password?.trim().length == 0) && translate("* Şifre zorunlu alandır.")}</div>
                                        <div>{(user?.details?.notificationMailType == undefined || user?.details?.notificationMailType?.length == 0) && translate("* Kullanılacak E-posta adresi seçilmesi zorunludur.")}</div>
                                        <div>{(user?.workStartDate == null) && translate("* İşe giriş tarihi zorunlu alandır.")}</div>
                                        <div>{(selectedRoles?.length == 0) && translate("* Çalışan rolü zorunlu alandır.")}</div>
                                    </div>
                                </Transition>


                                {userId !== undefined ?
                                    <Button
                                        loading={isLoading}
                                        circular
                                        className="ingOrange"
                                        onClick={() => insertOrUpdate('api/User/Update')}
                                        disabled={!isFormOk || !isFormValid || !isPasswordPolicyAllDone}
                                    >
                                        {translate("Güncelle")}
                                    </Button>
                                    :

                                    <div>

                                        <Button
                                            loading={isLoading}
                                            color={"green"}
                                            onClick={() => insertOrUpdate('api/User/Add')}
                                            disabled={!isFormOk || !isFormValid || !isPasswordPolicyAllDone}
                                            className="roundedButton"
                                        >
                                            {translate("Kaydet")}
                                        </Button>
                                    </div>

                                }
                            </div>
                        </div>
                        <div style={{ float: "right" }}>
                            {translate("(**) Alanların en az bir tanesi seçilmiş olmalı.")}
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default UserAdd;