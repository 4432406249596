import * as React from 'react';
import DropZone from '../../../components/DropZone';
import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from '../../../helper/TranslateHelper';
import surveyQuestionImportFile from '../../../images/Buddy/ExcelImport/SurveyQuestionImport.xlsx';

let styles = {

    importContainer: {
        display: "flex",
        width: "100%"
    }
}

const SurveyQuestionImport = () => {
    var {translate} = TranslateHelper();
    let dropZoneProps = {
        links: {
            uploadLink: "/SurveyQuestion/ExcelImport",
            returnLink: "/inquiryContentList"
        },
        table: {
            headers: [translate("Metin"), translate("Hata Nedeni")],
            propertyNames: ["body"]
        },
        messages: {
            1: translate("Metin adı veya soru tipi hatalı!"),
            2:translate("Şıklar eksik")

        },
        dataName: translate("Anket Soru"),
        // excelTemplateFileLink: DataHandler.getDocmanagerUrl() + "/Documents/Buddy/ExcelImport/SurveyQuestionImport.xlsx"
        excelTemplateFileLink: surveyQuestionImportFile
    }
    
    return (
        <div style={styles.importContainer}>
            <DropZone
                dropZoneProps={dropZoneProps}
            />
        </div>
    )
}

export default SurveyQuestionImport;