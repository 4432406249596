import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

import { NetworkRequest } from '../helper/NetworkRequest';
import parse from "html-react-parser";
import { DataHandler } from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";
import { useLocation } from 'react-router-dom';


const FirstLoginPopup = (props) => {
    const location = useLocation();

    var { translate } = TranslateHelper();
    const [isFirstLogin] = useState(DataHandler.getUserDataWithKey("shouldAcceptTOUC"));
    const [userId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [showModal, setShowModal] = useState(false);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));

    const [firstLoginPopup, setFirstLoginPopup] = useState({
        notificationId: null,
        isActive: false,
        notificationTypeId: "",
        body: "",
        subject: "",
        tenantId: tenantId
    });

    const insertLog = () => {
        NetworkRequest('/api/Notifications/InsertTOUCLog', {
            tenantId: tenantId,
            userId: userId
        }).then((response) => {
            DataHandler.setDataWithKey('shouldAcceptTOUC', false);
            
            window.location.href = "/";
        });
    }

    const getFirstLoginPopup = () => {

        NetworkRequest('/api/Notifications/GetTOUCPopup', { id: tenantId }).then((response) => {
            ////console.log("respons: ", response);
            if (response.data.resultCode == 0) {
                let result = response.data.detail;

                let body = result.body;
                var div = document.createElement('div');
                div.innerHTML = body;
                let images = div.getElementsByTagName('img');

                if (typeof images == "object") {
                    for (let i = 0; i < images.length; i++) {
                        ////console.log(images[i]);
                        images[i].setAttribute("style", "width:100%;");
                    }
                    body = div.innerHTML;
                }
                setFirstLoginPopup({
                    notificationId: result.notificationId,
                    body: body,
                    isActive: result.isActive,
                    notificationTypeId: result.notificationTypeId,
                    subject: result.subject,
                    tenantId: result.tenantId
                });

                setShowModal(true);

            }
        });
    }

    let styles = {
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        }
    }

    useEffect(() => {
        if (isFirstLogin == true) {
            if (location.pathname != "/toucEmptyPage") {
                window.location.href = "/toucEmptyPage";
            } else {
                getFirstLoginPopup();

            }
        }
    }, [isFirstLogin]);

    return (
        <Modal

            open={showModal}

        >
            <Header className={"firstLoginPopupHeaderContainer"} id="firstLoginPopupHeaderContainer" content={firstLoginPopup.subject} />
            <Modal.Content>
                {parse(firstLoginPopup.body)}
            </Modal.Content>
            <Modal.Actions>

                <Button
                    className={"sweetPurpleButton"}

                    onClick={() => { setShowModal(false); insertLog(); }


                    }>{translate("Kabul Et")}</Button>
                <Button
                    className={"dangerRedButton"}

                    onClick={() => DataHandler.logOutUser()
                    }>
                    {translate("Çıkış Yap")}</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default FirstLoginPopup;