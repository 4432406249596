import * as React from 'react';
import { useState, useEffect } from 'react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import {
    Button,
    Accordion,
    Label,
    Header,
    Icon,
    Loader,
    Popup,
    Transition,
    TextArea,
    Form,
    Grid,
    Progress,
    Image
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { IUser, IModuleReport, IAccordionRootPanel, IModuleSectionReport, ICompletedUserMission } from '../../../interfaces/interfaces';
import { DateFormat } from "../../../helper/DateFormat";
import ReportQuestionList from '../../../components/ReportQuestionList';
import ReportSurveyQuestionList from '../../../components/ReportSurveyQuestionList';
import parse from "html-react-parser";
import { toast } from 'react-toastify';
import './UserReportDetail.css';
import * as Scroll from 'react-scroll';
import ReactPlayer from 'react-player';

import { digitallicaConfirm, downloadFile, downloadFileWithEx, giveMePreview, giveMePreviewWithBlob } from '../../../helper/HelperFunctions';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import TranslateHelper from '../../../helper/TranslateHelper';
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../../helper/DataHelper';

import pngIcon from '../../../images/FileUploadIcons/new/png-file.png';
import gifIcon from '../../../images/FileUploadIcons/new/gif-file.png';
import jpegIcon from '../../../images/FileUploadIcons/new/jpeg-file.png';
import pdfIcon from '../../../images/FileUploadIcons/new/pdf-file.png';
import pptIcon from '../../../images/FileUploadIcons/new/ppt-file.png';
import xlsxIcon from '../../../images/FileUploadIcons/new/xlsx-file.png';
import folderIcon from '../../../images/FileUploadIcons/new/folder.png';
import docIcon from '../../../images/FileUploadIcons/new/doc-file.png';


export default function UserReportDetail() {

    var { translate } = TranslateHelper();
    var scroll = Scroll.animateScroll;
    const _ = require('lodash');
    const { id } = useParams();
    const [user, setUser] = useState<IUser | any>({});
    const [module, setModule] = useState<Array<IModuleReport>>([]);
    const [defaultProfilePicture] = useState<string>("/Documents/testAppName//testDivider/testFolderName/028c108a-da44-4c6a-9bdc-6275b8be35fe.png");
    const [isLoading, setIsLoading] = useState(true);
    const [rootPanels, setRootPanels] = useState<Array<IAccordionRootPanel>>([]);
    const [baseList, setBaseList] = useState<any>(null);
    const [moduleSectionContentIndex, setModuleSectionContentIndex] = useState<any>();
    const [activeAccordionIndex, setActiveAccordionIndex] = useState<any>(0);
    const [scrollYPosition, setScrollYPosition] = useState<number>(0);
    const [defaultUserImage, setDefaultUserImage] = useState<string>();
    const [photoThumbnailEx, setPhotoThumbnailEx] = useState(false);




    const [iconUrls] = useState([

    ]);
    const [icons, setIcons] = useState([]);

    const styles = {
        userInformationPanel: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row" as "row",
            alignItems: "start",
            gap: "3em"
        },

        userInformationRightPanel: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "row" as "row",
            flexWrap: "wrap" as "wrap"
        },

        headerStyle: {
            fontFamily: "sans-serif",
            fontWeight: "500"
        },

        moduleReportContainer: {
            display: "flex",
            marginTop: "40px",
            flexDirection: "column" as "column"
        },

        labelStyle: {
            width: "100%",
            fontFamily: "sans-serif",
            fontWeight: "500"
        },

        taskContentContainer: {
            display: "flex",
            gap: "1em"
        },

        taskContentHeader: {
            fontWeight: "bold",
            fontFamily: "sans-serif",
        },
        unauthorizedFileUploadedsContainer: { margin: "1rem" },
        htmlBody: {
            minWidth: "800px",
            width: "100%"
        }

    };

    enum CONTENT_TYPE {
        Video = 1,
        Html = 2,
        Quiz = 4,
        Survey = 8,
        Task = 15
    }

    const getAccordionData = (activeAccordionIndex?) => {
        setRootPanels([]);
        let userData = {
            id: id,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        NetworkRequest("api/Report/GetUserReportById", userData).then((response) => {
  
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                let data = response.data.detail;
                setUser(data.user);
                setBaseList(data.moduleReportViewModel);
                prepareModuleToAccordion(data.moduleReportViewModel, activeAccordionIndex);
                setModule(data.moduleReportViewModel);
                //setModuleReportView(data.moduleReportViewModel)
                setIsLoading(false);
                toast.dismiss();
                smoothScroll(scrollYPosition);

            }
        });
    }


    const giveMeIconForDocType = (path) => {

        if (path == undefined) return;
   
        if (path.src == undefined)
            path = path.slice(path.lastIndexOf(".") + 1).toLowerCase()
        else
            path = path.src.slice(path.src.lastIndexOf(".") + 1).toLowerCase()

        switch (path) {
            case "png":
                return pngIcon;
            case "jpeg":
            case "jpg":
                return jpegIcon;
            case "ppt":
            case "pptx":
                return pptIcon;
            case "pdf":
                return pdfIcon;
            case "xls":
            case "xlsx":
                return xlsxIcon;
            case "doc":
            case "docx":
                return docIcon;
            case "gif":
                return gifIcon;
            default:
                return pngIcon;

        }
    }

    const giveMeFileNameWithoutPath = (fileName) => {
        if (!fileName)
            return "";
        return fileName.indexOf(".") == -1 ? fileName : fileName.slice(0, fileName.lastIndexOf("."));
    }

    const checkCanISeeUploadeds = (json) => {
        var parsedJson = typeof json == 'object' ? json : JSON.parse(json);

        if (parsedJson.whoCanSeeUploadeds != undefined) {
            var _whoCanComplate = parsedJson.whoCanSeeUploadeds;
            var role = DataHandler.getUserDataWithKey("userRole");
            if (role == "User") {
                return true;
            } else if (_whoCanComplate.filter(x => x == "All").length > 0 || _whoCanComplate.filter(x => x == role).length > 0) {
                return true;
            }
        }
        return false;
    }


    const contentToContentAccordion = (contentType: CONTENT_TYPE, contentParam: IModuleSectionReport) => {

        switch (contentType) {
            case CONTENT_TYPE.Quiz:
                if (contentParam.quizReportView != null) {
                    return (
                        <div>
                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Sınav:")}</div>
                                <div>{contentParam?.quizReportView?.title}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Açıklama:")}</div>
                                <div>{contentParam?.quizReportView?.description}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Oluşturulma Tarihi:")}</div>
                                <div>{DateFormat(contentParam?.quizReportView?.createdDate)}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Başarı Puanı:")}</div>
                                <div>{contentParam?.quizReportView?.successScore}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Zaman (dakika):")}</div>
                                <div>{contentParam?.quizReportView?.time}</div>
                            </div>

                            {contentParam?.quizReportView?.isSuccess &&
                                <div style={styles.taskContentContainer}>
                                    <div style={styles.taskContentHeader}>{translate("Başarı Durumu:")}</div>
                                    <div>{contentParam?.quizReportView?.isSuccess ? translate("Başarılı") : translate("Başarısız")}</div>
                                </div>
                            }

                            {contentParam?.isCompleted
                                &&
                                <div style={styles.taskContentContainer}>
                                    <div style={styles.taskContentHeader}>{translate("Tamamlama Durumu:")}</div>
                                    <div>{contentParam?.isCompleted ? translate("Tamamlandı") : translate("Tamamlanmadı")}</div>
                                </div>
                            }

                            {contentParam?.quizReportView?.repeatCount &&
                                <div style={styles.taskContentContainer}>
                                    <div style={styles.taskContentHeader}>{translate("Tekrarlama Sayısı:")}</div>
                                    <div>{contentParam?.quizReportView?.repeatCount}</div>
                                </div>
                            }

                            {contentParam?.quizReportView?.completedDate &&
                                <div style={styles.taskContentContainer}>
                                    <div style={styles.taskContentHeader}>{translate("Tamamlama Tarihi:")}</div>
                                    <div>{contentParam?.quizReportView?.completedDate}</div>
                                </div>
                            }

                            <div style={styles.taskContentContainer}>
                                <div>
                                    <ReportQuestionList
                                        questions={contentParam.quizReportView.questions}
                                    />
                                </div>

                            </div>

                        </div>
                    );
                }

                break;

            case CONTENT_TYPE.Html:
                if (contentParam?.htmlReportView?.embedCode)
                    return parse(contentParam.htmlReportView.embedCode)
                break;

            case CONTENT_TYPE.Task:
                if (contentParam.taskReportView != null && contentParam.taskReportView.taskJson != null && typeof contentParam.taskReportView.taskJson == 'string')
                    contentParam.taskReportView.taskJson = JSON.parse(contentParam.taskReportView.taskJson);

                if (contentParam.taskReportView != null) {
           
                    switch (contentParam.taskReportView.taskType) {
                        case 1: //photo
                            return (<div>
                                {contentParam.taskReportView?.activityTitle != null &&
                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Açıklama:")}</div>
                                        <div>{contentParam.taskReportView.activityTitle}</div>
                                    </div>
                                }
                                <br />
                                <div style={{ display: "flex", gap: "1em", width: "100%", padding: "1%" }}>
                                    {contentParam.taskReportView?.userAnswer != null && (JSON.parse(contentParam.taskReportView?.userAnswer)).length > 0 &&
                                        (JSON.parse(contentParam.taskReportView?.userAnswer)).map(image => (
                                            <div key={uuidv4()}>
                                                <img
                                                    src={image}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                            )
                            break;
                        case 8:
                            return (
                                <div>
                                    {contentParam.taskReportView?.activityTitle != null && checkCanISeeUploadeds(contentParam.taskReportView?.taskJson)
                                        &&
                                        <div style={styles.taskContentContainer}>
                                            <div style={styles.taskContentHeader}>{translate("Açıklama:")}</div>
                                            <div>{contentParam.taskReportView.activityTitle}</div>
                                        </div>
                                    }
                                    <br />
                                    <Grid>
                                        <Grid.Row style={{padding:"2%"}}>
                                            {checkCanISeeUploadeds(contentParam.taskReportView?.taskJson) && contentParam.taskReportView?.userAnswer != null && (JSON.parse(contentParam.taskReportView?.userAnswer)) != null && (JSON.parse(contentParam.taskReportView?.userAnswer)).length > 0
                                                &&
                                                (JSON.parse(contentParam.taskReportView?.userAnswer)).map(file => (
                                                    <div
                                                        key={uuidv4()}
                                                        className={"userDetailReportFileDesign"}
                                                    >
                                                        <div className={"userDetailReportFileHeaderDesign"}>
                                                            <img
                                                                width="20px"
                                                                height="auto"
                                                                id="reportTaskIMage-2"
                                                                src={giveMeIconForDocType(file.fileName)}
                                                            />

                                                            <div className={"userDetailReportFileNameDesign"}>{file.fileName}</div>
                                                        </div>
                                                        <div>
                                                            <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                                downloadFileWithEx(file.Src, file?.fileName || "file", file?.fileName?.split(".")[file?.fileName?.split(".")?.length - 1] || "txt")
                                                            }}>
                                                                {/*{translate("İndir")}*/}
                                                                <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            {checkCanISeeUploadeds(contentParam.taskReportView?.taskJson) == false
                                                &&
                                                <div className="unauthorizedFileUploadedsContainer"
                                                    style={styles.unauthorizedFileUploadedsContainer}>
                                                    <Image id="unauthImage" source={displayProtectedAppDoc("/Documents/FileUploadIcons/new/unauthorized.png", "unauthImage")}
                                                        width="150" />
                                                    <p>
                                                        {translate("Yüklenen dosyaları görüntülemeye yetkiniz bulunmamaktadır")}
                                                    </p>
                                                </div>
                                            }

                                        </Grid.Row>
                                    </Grid>
                                </div>
                            )

                        case 2: //document
                            return <div>
                                {contentParam.taskReportView?.fileUri != null && contentParam.taskReportView?.fileUri.startsWith("http")
                                    &&


                                    <div
                                        className={"userDetailReportFileDesign"}
                                    >
                                        <div className={"userDetailReportFileHeaderDesign"}>
                                            <img
                                                width="20px"
                                                height="auto"
                                                id="reportTaskIMage-2"
                                                src={giveMeIconForDocType(contentParam.taskReportView?.taskJson.fileName)}
                                            />

                                            <div className={"userDetailReportFileNameDesign"}>{contentParam.taskReportView?.taskJson.fileName}</div>
                                        </div>
                                        <div>
                                            <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                downloadFileWithEx(contentParam.taskReportView?.fileUri, contentParam.taskReportView?.taskJson.fileName || "file", contentParam.taskReportView?.taskJson.fileName?.split(".")[contentParam.taskReportView?.taskJson.fileName?.split(".")?.length - 1] || "txt")
                                            }}>
                                                {/*{translate("İndir")}*/}
                                                <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                            </div>
                                        </div>
                                    </div>


                                 
                                }

                                {contentParam.taskReportView?.fileUri != null && !contentParam.taskReportView?.fileUri.startsWith("http")
                                    && 

                                    <div
                                        className={"userDetailReportFileDesign"}
                                    >
                                        <div className={"userDetailReportFileHeaderDesign"}>
                                            <img
                                                width="20px"
                                                height="auto"
                                                id="reportTaskIMage-2"
                                                src={giveMeIconForDocType(contentParam.taskReportView?.taskJson.fileName)}
                                            />

                                            <div className={"userDetailReportFileNameDesign"}>{contentParam.taskReportView?.taskJson.fileName}</div>
                                        </div>
                                        <div>
                                            <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                downloadFileWithEx(
                                                    contentParam.taskReportView?.fileUri,
                                                    contentParam.taskReportView?.taskJson.fileName || "file",
                                                    contentParam.taskReportView?.taskJson.fileName?.split(".")[contentParam.taskReportView?.taskJson.fileName?.split(".")?.length - 1] || "txt")
                                            }}>
                                                {/*{translate("İndir")}*/}
                                                <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                            </div>
                                        </div>
                                    </div>

                                    
                                }
                            </div>
                        case 4: //events
                            return (
                                <div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Eğitim Adı:")}</div>
                                        <div>{contentParam.taskReportView.activityTitle}</div>
                                    </div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Eğitim Detayı:")}</div>
                                        <div>{contentParam.taskReportView.activityDesc}</div>
                                    </div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Başlangıç Tarihi:")}</div>
                                        <div>{DateFormat(contentParam.taskReportView.activityStartDate)}</div>
                                    </div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Bitiş Tarihi:")}</div>
                                        <div>{DateFormat(contentParam.taskReportView.activityEndDate)}</div>
                                    </div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Eğitim Türü:")}</div>
                                        <div>{contentParam.taskReportView.activityIsOnlineActivity ? translate("Online") : translate("Yüzyüze")}</div>

                                    </div>

                                    {contentParam.taskReportView.activityOnlineActivityLink != null &&
                                        <div style={styles.taskContentContainer}>
                                            <div style={styles.taskContentHeader}>{translate("Link:")}</div>
                                            <a
                                                href={contentParam.taskReportView.activityOnlineActivityLink}
                                                target={"_blank"}
                                            >{contentParam.taskReportView.activityOnlineActivityLink}</a>
                                        </div>
                                    }

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Eğitmen Adı:")}</div>
                                        <div>{contentParam.taskReportView.activityTeacherName}</div>
                                    </div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Lokasyon Adı:")}</div>
                                        <div>{contentParam.taskReportView.activityLocationName}</div>
                                    </div>

                                    <div style={styles.taskContentContainer}>
                                        <div style={styles.taskContentHeader}>{translate("Tamamlama Durumu:")}</div>
                                        <div>{contentParam.taskReportView.displayName}</div>
                                    </div>
                                </div>
                            )

                            break;

                        case 8: //meeting

                            return <div>
                                <Form>
                                    <TextArea className={"greyInput"} disabled value={contentParam.taskReportView?.userAnswer} />
                                </Form>
                            </div>
                            break;

                        case 15: //free
                            return <div>
                                <Form>
                                    <TextArea className={"greyInput"} disabled value={contentParam.taskReportView?.activityTitle} />
                                </Form>
                            </div>
                            break;
                    }

                }
                break;

            case CONTENT_TYPE.Survey:
                if (contentParam.surveyReportView != null) {
                    return (
                        <div>
                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Anlet İsmi:")}</div>
                                <div>{contentParam?.surveyReportView?.title}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Anket Detayı:")}</div>
                                <div>{contentParam?.surveyReportView?.description}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Tamamlama Durumu:")}</div>
                                <div>{contentParam?.isCompleted ? translate("Tamamlanmış") : translate("Tamamlanmamış")}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={styles.taskContentHeader}>{translate("Oluşturulma Tarihi:")}</div>
                                <div>{DateFormat(contentParam?.surveyReportView?.createdDate)}</div>
                            </div>

                            <div style={styles.taskContentContainer}>
                                <div style={{ width: "100%" }}>
                                    <ReportSurveyQuestionList
                                        questions={contentParam?.surveyReportView?.surveyQuestionReportView}
                                    />
                                </div>
                            </div>

                        </div>
                    )
                }
                break;

            case CONTENT_TYPE.Video:
                if ((contentParam?.videoReportView?.videoType == 1 || contentParam?.videoReportView?.videoType == 2) && contentParam?.videoReportView?.uri != null) {
                    return (
                        <ReactPlayer
                            controls={true}
                            url={contentParam.videoReportView.uri} playing={false}
                        />
                    );
                } else if (contentParam?.videoReportView?.videoType == 4 && contentParam?.videoReportView?.embedCode != null) {
                    return (
                        <html><body style={styles.htmlBody}>
                            {parse(contentParam?.videoReportView.embedCode)}
                        </body> </html>
                    );
                }

                break;
        }
    }

    const geContentIsCompleted = (content) => {
        let isCompleted = false;
        switch (content?.contentType) {
            case CONTENT_TYPE.Quiz:
                isCompleted = content?.isCompleted;
                break;

            case CONTENT_TYPE.Html:
                isCompleted = content?.isCompleted;
                break;

            case CONTENT_TYPE.Task:
                isCompleted = content?.isCompleted;
                break;

            case CONTENT_TYPE.Survey:
                isCompleted = content?.isCompleted;
                break;

            case CONTENT_TYPE.Video:
                isCompleted = content?.isCompleted;
                break;
        }
        return isCompleted;
    }

    const contentButtonHandler = (e, content, index, sectionIndex, moduleIndex) => {
        let isCompleted = geContentIsCompleted(content);
        if (isCompleted) {
            e.stopPropagation();
            digitallicaConfirm(
                translate("Sıfırlamak istediğinizden emin misiniz ?"),
                () => {
                    completeContentForUser(id, content.sectionContentId, content.moduleSectionId, 1, index, sectionIndex, moduleIndex, !isCompleted);
                },
                () => {
                    toast(translate("İşlem iptal edildi."), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2500
                    });
                }
            );
        } else {
            e.stopPropagation();
            //e.nativeEvent.stopImmediatePropagation();
            digitallicaConfirm(
                translate("Tamamlandı olarak işaretlenecek emin misiniz ?"),
                () => {
                    completeContentForUser(id, content.sectionContentId, content.moduleSectionId, 1, index, sectionIndex, moduleIndex, !isCompleted);
                },
                () => {
                    toast(translate("İşlem iptal edildi."), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2500
                    });
                }
            );
        }
    }

    const contentPanel = (contents: Array<IModuleSectionReport>, sectionIndex: number, moduleIndex: number): Array<IAccordionRootPanel> => {
        let arr: Array<IAccordionRootPanel> = [];
        let isCompleted = false;

        _.each(contents, (content, index) => {

            isCompleted = geContentIsCompleted(content);

            arr.push({
                title: {
                    content:
                        <div
                            key={uuidv4()}
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center"
                            }}
                        >
                            <Label content={content?.title} />
                            <div>
                                <Popup
                                    content={isCompleted ? translate("Tamamlandı olarak işaretlendi. Sıfırlamak isterseniz tıklayınız") : translate("İçeriği bitirdi olarak işaretle")}
                                    trigger={
                                        <Button
                                            onClick={(e) => {
                                                contentButtonHandler(e, content, index, sectionIndex, moduleIndex);
                                            }}
                                            color={isCompleted ? "black" : "grey"}
                                            className={"roundedButton"}
                                            icon={isCompleted ? "undo" : "check"}
                                        />
                                    }
                                />
                                <Popup content={isCompleted ? translate("Tamamlanmış") : translate("Tamamlanmamış")}
                                    trigger={
                                        <Button
                                            color={isCompleted ? "green" : "black"}
                                            className={"roundedButton"}
                                            icon={isCompleted ? "check square" : "hourglass half"}
                                        />
                                    }
                                />
                            </div>
                        </div>

                },
                key: uuidv4(),
                content: {
                    content: contentToContentAccordion(content?.contentType, content)
                }

            });
        });

        return arr;
    }

    const contentToAccordion = (arr, sectionIndex, moduleIndex) => {
        return (
            <div>
                <Accordion.Accordion
                    panels={contentPanel(arr, sectionIndex, moduleIndex)}
                />
            </div>
        )
    }

    const sectionPanel = (sections: Array<IModuleSectionReport>, moduleIndex: number): Array<IAccordionRootPanel> => {
        let arr: Array<IAccordionRootPanel> = [];

        sections.map((section, index) => {

            arr.push({
                title: {
                    content: <div
                        onClick={(e) => {
                            //e.stopPropagation();
                            //e.preventDefault();

                            //setActiveAccordionIndex(activeAccordionIndex === index ? -1 : index);

                            //activeAccordionIndex == index ? setActiveAccordionIndex(-1) : setActiveAccordionIndex(index);
                            //setActiveAccordionIndex(index);

                        }}
                        style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <Label content={section?.title} />
                        <div>
                            <Popup
                                content={section?.isCompleted ? translate("Tamamlandı olarak işaretlendi. Sıfırlamak isterseniz tıklayınız") : translate("Bölümü bitirdi olarak işaretle")}
                                trigger={
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (section?.isCompleted) {
                                                digitallicaConfirm(
                                                    translate("Bölümü ve altında ki tüm içerikleri sıfırlamak istediğinize emin misiniz ?"),
                                                    () => {
                                                        completeContentForUser(id, null, section?.moduleSectionId, 2, null, index, moduleIndex, false);
                                                    },
                                                    () => {
                                                        toast(translate("İşlem iptal edildi."), {
                                                            type: 'error',
                                                            theme: 'dark',
                                                            position: 'top-center',
                                                            autoClose: 2500
                                                        });
                                                    }
                                                );

                                            } else {
                                                //e.nativeEvent.stopImmediatePropagation();
                                                digitallicaConfirm(
                                                    translate("Bölümü ve altında ki tüm içerikleri tamamlandı olarak işaretlemek istediğinize emin misiniz ?"),
                                                    () => {
                                                        completeContentForUser(id, null, section?.moduleSectionId, 2, null, index, moduleIndex, true);
                                                    },
                                                    () => {
                                                        toast(translate("İşlem iptal edildi."), {
                                                            type: 'error',
                                                            theme: 'dark',
                                                            position: 'top-center',
                                                            autoClose: 2500
                                                        });
                                                    }
                                                );
                                            }


                                        }}
                                        color={section?.isCompleted ? "black" : "grey"}
                                        className={"roundedButton"}
                                        icon={section?.isCompleted ? "undo" : "check"}
                                    />
                                }
                            />

                            <Popup content={section?.isCompleted ? translate("Tamamlanmış") : translate("Tamamlanmamış")}
                                trigger={
                                    <Button
                                        className={"roundedButton"}
                                        color={section?.isCompleted ? "green" : "black"}
                                        icon={section?.isCompleted ? "check square" : "hourglass half"}
                                    />
                                }
                            />
                        </div>
                    </div>

                },
                key: uuidv4(),
                content: {
                    content: contentToAccordion(section?.sectionReportView?.contentReportView, index, moduleIndex)
                }
            });
        });

        return arr;
    }

    const SectionToAccordion = (sections, moduleIndex, activeAccordionIndex) => {
        return (
            <div>
                <Accordion.Accordion
                    defaultActiveIndex={activeAccordionIndex}
                    panels={sectionPanel(sections, moduleIndex)}
                />
            </div>
        )
    }

    const prepareModuleToAccordion = (moduleData: Array<IModuleReport>, activeAccordionIndex?: number) => {
        if (moduleData != null) {
            setModule(moduleData);
            renderModuleData(moduleData, activeAccordionIndex);
        }
    }

    const renderModuleData = (data, activeAccordionIndex?) => {
        setRootPanels([]);
        data.map((module, index) => {
            let rootObject: IAccordionRootPanel = {
                title: {
                    content: <Label style={{ width: "100%" }} content={
                        <div className={"userReportDetailHeaderContainer"}>
                            <div>{module?.title}</div>
                            <div className={"userReportDetailProgressContainer"}>
                                <Progress size="small" percent={module.progressPercentage} indicating progress />
                            </div>
                        </div>

                    } />,
                },
                key: uuidv4(),
                content: { content: SectionToAccordion(module.moduleSectionReportView, index, activeAccordionIndex) }
            };
            setRootPanels(oldArray => [...oldArray, rootObject]);
        });
    }

    const completeContentForUser = (userId, sectionContentId, moduleSectionId, type, index, sectionIndex, moduleIndex, isCompleted) => {

        let apiPath = "api/Report/CompleteSectionContent";
        let data: ICompletedUserMission = {
            userId: userId,
            sectionContentId: sectionContentId,
            moduleSectionId: moduleSectionId,
            isComplete: isCompleted,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        };

        if (type == 2)
            apiPath = "api/Report/CompleteModuleSection"
        //debugger;
        statusRequest(apiPath, data, index, sectionIndex, moduleIndex, isCompleted);
    }

    const statusRequest = (apiPath, data, index, sectionIndex, moduleIndex, isCompleted) => {

        toast(translate("Güncelleniyor lütfen bekleyiniz.."), {
            type: 'warning',
            theme: 'dark',
            position: 'top-center',
            autoClose: 50000
        });
        NetworkRequest(apiPath, data).then((response) => {
            let resultCode = response.data.resultCode;
            toast.dismiss();
            if (resultCode === 0) {
                toast(translate("Başarılı."), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
                setModuleSectionContentIndex({
                    moduleIndex: moduleIndex,
                    sectionIndex: sectionIndex,
                    contentIndex: index,
                    isComplete: isCompleted
                });

            }
        });
    }

    window.addEventListener("scroll", (event) => {
        let scroll = window.scrollY;
        setScrollYPosition(scroll);
    });

    const smoothScroll = (position) => {
        scroll.scrollTo(position, {
            duration: 500
        });
    }

    const setContentComplete = (contentType, isCompleted) => {

        let clone = _.cloneDeep(module);

        let content = clone[moduleSectionContentIndex.moduleIndex]
            .moduleSectionReportView[moduleSectionContentIndex.sectionIndex]
            .sectionReportView.contentReportView[moduleSectionContentIndex.contentIndex]
        [contentType];

        if (content != null) {
            clone[moduleSectionContentIndex.moduleIndex]
                .moduleSectionReportView[moduleSectionContentIndex.sectionIndex]
                .sectionReportView.contentReportView[moduleSectionContentIndex.contentIndex].isCompleted = isCompleted;

            clone[moduleSectionContentIndex.moduleIndex]
                .moduleSectionReportView[moduleSectionContentIndex.sectionIndex]
                .sectionReportView.contentReportView[moduleSectionContentIndex.contentIndex]
            [contentType].displayName = isCompleted ? translate("Tamamlandı") : translate("Başlamadı");
        }

        //prepareModuleToAccordion(clone, activeAccordionIndex);
        getAccordionData(activeAccordionIndex);
    }

    const setContentAndSubSectionCompleted = (isCompleted) => {

        let clone = _.cloneDeep(baseList);

        clone[moduleSectionContentIndex.moduleIndex]
            .moduleSectionReportView[moduleSectionContentIndex.sectionIndex].isCompleted = isCompleted;


        clone[moduleSectionContentIndex.moduleIndex]
            .moduleSectionReportView[moduleSectionContentIndex.sectionIndex].sectionReportView.contentReportView.map((content, index) => {

                var reportTypes = ["videoReportView", "surveyReportView", "quizReportView", "htmlReportView", "taskReportView"];

                reportTypes.map(reportType => {
                    if (clone[moduleSectionContentIndex.moduleIndex]
                        .moduleSectionReportView[moduleSectionContentIndex.sectionIndex].sectionReportView.contentReportView[index] != null) {
                        clone[moduleSectionContentIndex.moduleIndex]
                            .moduleSectionReportView[moduleSectionContentIndex.sectionIndex].sectionReportView.contentReportView[index].isCompleted = isCompleted == true ? translate("Tamamlandı") : translate("Başlamadı");
                    }
                });
            });
        getAccordionData(activeAccordionIndex);
    }

    const userExportReport = () => {
        let data = {
            UserId: id,
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        NetworkRequest("api/Report/GetUserDetailReportToExcel", data).then((response) => {
            downloadFile(response.data, translate("Sistem Raporu"));
        });
    }

    const getIconFiles = () => {
        let arr: any = [];
        _.each(iconUrls, (url) => {
            arr.push(displayProtectedAppDoc(url));
        });
        setIcons(arr);
    }

    const getSafeContentType = (baseList, moduleSectionContentIndex) => {
        const contentReportView = baseList[moduleSectionContentIndex.moduleIndex]
            ?.moduleSectionReportView[moduleSectionContentIndex.sectionIndex]
            ?.sectionReportView?.contentReportView[moduleSectionContentIndex.contentIndex];

        return contentReportView?.contentType ?? 15;
    };

    useEffect(() => {
        if (moduleSectionContentIndex != undefined && moduleSectionContentIndex.contentIndex == null) {
            setContentAndSubSectionCompleted(moduleSectionContentIndex.isComplete);
        } else if (moduleSectionContentIndex != undefined && moduleSectionContentIndex.contentIndex != null) {

            let contentType = getSafeContentType(baseList, moduleSectionContentIndex) || 15;

            switch (contentType) {
                case 4:
                    setContentComplete("quizReportView", moduleSectionContentIndex.isComplete);
                    break;

                case 2:
                    setContentComplete("htmlReportView", moduleSectionContentIndex.isComplete);
                    break;

                case 15:
                    setContentComplete("taskReportView", moduleSectionContentIndex.isComplete);
                    break;

                case 8:
                    setContentComplete("surveyReportView", moduleSectionContentIndex.isComplete);
                    break;

                case 1:
                    setContentComplete("videoReportView", moduleSectionContentIndex.isComplete);
                    break;
            }
        }
    }, [moduleSectionContentIndex]);

    useEffect(() => {
        getAccordionData();
        setDefaultUserImage(displayProtectedAppDoc("/Documents/DefaultUserImage/user.png"));
        getIconFiles();
    }, []);

    useEffect(() => {
        prepareModuleToAccordion(module, activeAccordionIndex);
    }, [activeAccordionIndex]);

    return (
        <div className="generalContainerForWhiteBg">
            <div>
                <Transition visible={isLoading} animation='scale' duration={500}>
                    <div>
                        <Loader size="huge" active inline='centered' />
                    </div>
                </Transition>

                <Transition visible={!isLoading} animation='scale' duration={500}>
                    <div>
                        <div className="userReportDetailAllContainer">
                            <div>
                                <div style={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
                                    <div><Popup
                                        content={user?.isActive ? translate("Çalışan aktiftir") : translate("Çalışan aktif değildir !")}
                                        trigger={
                                            <Icon name={"circle"} disabled color={user?.isActive ? "green" : "grey"} />
                                        } />
                                    </div>
                                    <div><Header as='h1'>{user?.name + " " + user?.surname}</Header></div>
                                    <div>
                                        <Popup
                                            content={"Excel report"}
                                            trigger={
                                                <Button onClick={userExportReport} color={"green"} icon='excel file' />
                                            } />
                                    </div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column" as "column",
                                    gap: "0.4em",
                                    marginTop: "20px"
                                }}>
                                    <div>
                                        <b>{translate("Email:")} </b> {user?.email}
                                    </div>
                                    <div>
                                        <b>{translate("Kişisel Email:")} </b> {user?.userDetailView?.emailPersonal}
                                    </div>
                                    <div>
                                        <b>{translate("Telefon:")} </b> {user?.phone}
                                    </div>
                                    <div>
                                        <b>{translate("Departman:")} </b> {user?.departman}
                                    </div>
                                    <div>
                                        <b>{translate("İşe Başlama Tarihi:")} </b> {DateFormat(user?.workStartDate)}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Image id="userrepImage" source={displayProtectedAppDoc(user.photo == undefined ? defaultUserImage : user.photo, "userrepImage")}
                                    width="200px"
                                    height="auto"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = displayProtectedAppDoc(defaultProfilePicture);
                                    }}
                                />
                            </div>
                        </div>
                        <div style={styles.moduleReportContainer}>
                            <div className="userReportDetailAccordionContainer">
                                <div className="userReportDetailModuleReportText">
                                    <Header color="grey" as="h2" textAlign="left">
                                        <Icon name='area graph' /> {translate("Modüller Raporu")}
                                    </Header>
                                </div>
                                <Accordion
                                    className={"userReportDetailAccordion"}
                                    panels={rootPanels}
                                    styled
                                />
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}