import React, {useState, useEffect} from 'react';
import {Input, Icon, Button, Grid, Flag, Transition, Image} from 'semantic-ui-react';
import './Dictionary.css';
import {NetworkRequest} from '../../helper/NetworkRequest';

import {toast} from 'react-toastify';
import {displayProtectedAppDoc} from '../../helper/DisplayProtectedDocument';
import {DataHandler} from '../../helper/DataHelper';
import TranslateHelper from "../../helper/TranslateHelper";
import {v4 as uuidv4} from 'uuid';

const countries = [
    {name: 'Afghanistan', countryCode: 'af'},
    {name: 'Aland Islands', countryCode: 'ax'},
    {name: 'Albania', countryCode: 'al'},
    {name: 'Algeria', countryCode: 'dz'},
    {name: 'American Samoa', countryCode: 'as'},
    {name: 'Andorra', countryCode: 'ad'},
    {name: 'Angola', countryCode: 'ao'},
    {name: 'Anguilla', countryCode: 'ai'},
    {name: 'Antigua', countryCode: 'ag'},
    {name: 'Argentina', countryCode: 'ar'},
    {name: 'Armenia', countryCode: 'am'},
    {name: 'Aruba', countryCode: 'aw'},
    {name: 'Australia', countryCode: 'au'},
    {name: 'Austria', countryCode: 'at'},
    {name: 'Azerbaijan', countryCode: 'az'},
    {name: 'Bahamas', countryCode: 'bs'},
    {name: 'Bahrain', countryCode: 'bh'},
    {name: 'Bangladesh', countryCode: 'bd'},
    {name: 'Barbados', countryCode: 'bb'},
    {name: 'Belarus', countryCode: 'by'},
    {name: 'Belgium', countryCode: 'be'},
    {name: 'Belize', countryCode: 'bz'},
    {name: 'Benin', countryCode: 'bj'},
    {name: 'Bermuda', countryCode: 'bm'},
    {name: 'Bhutan', countryCode: 'bt'},
    {name: 'Bolivia', countryCode: 'bo'},
    {name: 'Bosnia', countryCode: 'ba'},
    {name: 'Botswana', countryCode: 'bw'},
    {name: 'Bouvet Island', countryCode: 'bv'},
    {name: 'Brazil', countryCode: 'br'},
    {name: 'British Virgin Islands', countryCode: 'vg'},
    {name: 'Brunei', countryCode: 'bn'},
    {name: 'Bulgaria', countryCode: 'bg'},
    {name: 'Burkina Faso', countryCode: 'bf'},
    {name: 'Burma', countryCode: 'mm', alias: 'Myanmar'},
    {name: 'Burundi', countryCode: 'bi'},
    {name: 'Caicos Islands', countryCode: 'tc'},
    {name: 'Cambodia', countryCode: 'kh'},
    {name: 'Cameroon', countryCode: 'cm'},
    {name: 'Canada', countryCode: 'ca'},
    {name: 'Cape Verde', countryCode: 'cv'},
    {name: 'Cayman Islands', countryCode: 'ky'},
    {name: 'Central African Republic', countryCode: 'cf'},
    {name: 'Chad', countryCode: 'td'},
    {name: 'Chile', countryCode: 'cl'},
    {name: 'China', countryCode: 'cn'},
    {name: 'Christmas Island', countryCode: 'cx'},
    {name: 'Cocos Islands', countryCode: 'cc'},
    {name: 'Colombia', countryCode: 'co'},
    {name: 'Comoros', countryCode: 'km'},
    {name: 'Congo', countryCode: 'cd'},
    {name: 'Congo Brazzaville', countryCode: 'cg'},
    {name: 'Cook Islands', countryCode: 'ck'},
    {name: 'Costa Rica', countryCode: 'cr'},
    {name: 'Cote Divoire', countryCode: 'ci'},
    {name: 'Croatia', countryCode: 'hr'},
    {name: 'Cuba', countryCode: 'cu'},
    {name: 'Cyprus', countryCode: 'cy'},
    {name: 'Czech Republic', countryCode: 'cz'},
    {name: 'Denmark', countryCode: 'dk'},
    {name: 'Djibouti', countryCode: 'dj'},
    {name: 'Dominica', countryCode: 'dm'},
    {name: 'Dominican Republic', countryCode: 'do'},
    {name: 'Ecuador', countryCode: 'ec'},
    {name: 'Egypt', countryCode: 'eg'},
    {name: 'El Salvador', countryCode: 'sv'},
    {name: 'England', countryCode: 'gb eng'},
    {name: 'Equatorial Guinea', countryCode: 'gq'},
    {name: 'Eritrea', countryCode: 'er'},
    {name: 'Estonia', countryCode: 'ee'},
    {name: 'Ethiopia', countryCode: 'et'},
    {name: 'Europeanunion', countryCode: 'eu'},
    {name: 'Falkland Islands', countryCode: 'fk'},
    {name: 'Faroe Islands', countryCode: 'fo'},
    {name: 'Fiji', countryCode: 'fj'},
    {name: 'Finland', countryCode: 'fi'},
    {name: 'France', countryCode: 'fr'},
    {name: 'French Guiana', countryCode: 'gf'},
    {name: 'French Polynesia', countryCode: 'pf'},
    {name: 'French Territories', countryCode: 'tf'},
    {name: 'Gabon', countryCode: 'ga'},
    {name: 'Gambia', countryCode: 'gm'},
    {name: 'Georgia', countryCode: 'ge'},
    {name: 'Germany', countryCode: 'de'},
    {name: 'Ghana', countryCode: 'gh'},
    {name: 'Gibraltar', countryCode: 'gi'},
    {name: 'Greece', countryCode: 'gr'},
    {name: 'Greenland', countryCode: 'gl'},
    {name: 'Grenada', countryCode: 'gd'},
    {name: 'Guadeloupe', countryCode: 'gp'},
    {name: 'Guam', countryCode: 'gu'},
    {name: 'Guatemala', countryCode: 'gt'},
    {name: 'Guinea', countryCode: 'gn'},
    {name: 'Guinea-Bissau', countryCode: 'gw'},
    {name: 'Guyana', countryCode: 'gy'},
    {name: 'Haiti', countryCode: 'ht'},
    {name: 'Heard Island', countryCode: 'hm'},
    {name: 'Honduras', countryCode: 'hn'},
    {name: 'Hong Kong', countryCode: 'hk'},
    {name: 'Hungary', countryCode: 'hu'},
    {name: 'Iceland', countryCode: 'is'},
    {name: 'India', countryCode: 'in'},
    {name: 'Indian Ocean Territory', countryCode: 'io'},
    {name: 'Indonesia', countryCode: 'id'},
    {name: 'Iran', countryCode: 'ir'},
    {name: 'Iraq', countryCode: 'iq'},
    {name: 'Ireland', countryCode: 'ie'},
    {name: 'Israel', countryCode: 'il'},
    {name: 'Italy', countryCode: 'it'},
    {name: 'Jamaica', countryCode: 'jm'},
    {name: 'Jan Mayen', countryCode: 'sj', alias: 'Svalbard'},
    {name: 'Japan', countryCode: 'jp'},
    {name: 'Jordan', countryCode: 'jo'},
    {name: 'Kazakhstan', countryCode: 'kz'},
    {name: 'Kenya', countryCode: 'ke'},
    {name: 'Kiribati', countryCode: 'ki'},
    {name: 'Kuwait', countryCode: 'kw'},
    {name: 'Kyrgyzstan', countryCode: 'kg'},
    {name: 'Laos', countryCode: 'la'},
    {name: 'Latvia', countryCode: 'lv'},
    {name: 'Lebanon', countryCode: 'lb'},
    {name: 'Lesotho', countryCode: 'ls'},
    {name: 'Liberia', countryCode: 'lr'},
    {name: 'Libya', countryCode: 'ly'},
    {name: 'Liechtenstein', countryCode: 'li'},
    {name: 'Lithuania', countryCode: 'lt'},
    {name: 'Luxembourg', countryCode: 'lu'},
    {name: 'Macau', countryCode: 'mo'},
    {name: 'Macedonia', countryCode: 'mk'},
    {name: 'Madagascar', countryCode: 'mg'},
    {name: 'Malawi', countryCode: 'mw'},
    {name: 'Malaysia', countryCode: 'my'},
    {name: 'Maldives', countryCode: 'mv'},
    {name: 'Mali', countryCode: 'ml'},
    {name: 'Malta', countryCode: 'mt'},
    {name: 'Marshall Islands', countryCode: 'mh'},
    {name: 'Martinique', countryCode: 'mq'},
    {name: 'Mauritania', countryCode: 'mr'},
    {name: 'Mauritius', countryCode: 'mu'},
    {name: 'Mayotte', countryCode: 'yt'},
    {name: 'Mexico', countryCode: 'mx'},
    {name: 'Micronesia', countryCode: 'fm'},
    {name: 'Moldova', countryCode: 'md'},
    {name: 'Monaco', countryCode: 'mc'},
    {name: 'Mongolia', countryCode: 'mn'},
    {name: 'Montenegro', countryCode: 'me'},
    {name: 'Montserrat', countryCode: 'ms'},
    {name: 'Morocco', countryCode: 'ma'},
    {name: 'Mozambique', countryCode: 'mz'},
    {name: 'Namibia', countryCode: 'na'},
    {name: 'Nauru', countryCode: 'nr'},
    {name: 'Nepal', countryCode: 'np'},
    {name: 'Netherlands', countryCode: 'nl'},
    {name: 'Netherlandsantilles', countryCode: 'an'},
    {name: 'New Caledonia', countryCode: 'nc'},
    {name: 'New Guinea', countryCode: 'pg'},
    {name: 'New Zealand', countryCode: 'nz'},
    {name: 'Nicaragua', countryCode: 'ni'},
    {name: 'Niger', countryCode: 'ne'},
    {name: 'Nigeria', countryCode: 'ng'},
    {name: 'Niue', countryCode: 'nu'},
    {name: 'Norfolk Island', countryCode: 'nf'},
    {name: 'North Korea', countryCode: 'kp'},
    {name: 'Northern Mariana Islands', countryCode: 'mp'},
    {name: 'Norway', countryCode: 'no'},
    {name: 'Oman', countryCode: 'om'},
    {name: 'Pakistan', countryCode: 'pk'},
    {name: 'Palau', countryCode: 'pw'},
    {name: 'Palestine', countryCode: 'ps'},
    {name: 'Panama', countryCode: 'pa'},
    {name: 'Paraguay', countryCode: 'py'},
    {name: 'Peru', countryCode: 'pe'},
    {name: 'Philippines', countryCode: 'ph'},
    {name: 'Pitcairn Islands', countryCode: 'pn'},
    {name: 'Poland', countryCode: 'pl'},
    {name: 'Portugal', countryCode: 'pt'},
    {name: 'Puerto Rico', countryCode: 'pr'},
    {name: 'Qatar', countryCode: 'qa'},
    {name: 'Reunion', countryCode: 're'},
    {name: 'Romania', countryCode: 'ro'},
    {name: 'Russia', countryCode: 'ru'},
    {name: 'Rwanda', countryCode: 'rw'},
    {name: 'Saint Helena', countryCode: 'sh'},
    {name: 'Saint Kitts and Nevis', countryCode: 'kn'},
    {name: 'Saint Lucia', countryCode: 'lc'},
    {name: 'Saint Pierre', countryCode: 'pm'},
    {name: 'Saint Vincent', countryCode: 'vc'},
    {name: 'Samoa', countryCode: 'ws'},
    {name: 'San Marino', countryCode: 'sm'},
    {name: 'Sandwich Islands', countryCode: 'gs'},
    {name: 'Sao Tome', countryCode: 'st'},
    {name: 'Saudi Arabia', countryCode: 'sa'},
    {name: 'Scotland', countryCode: 'gb sct'},
    {name: 'Senegal', countryCode: 'sn'},
    {name: 'Serbia', countryCode: 'cs'},
    {name: 'Serbia', countryCode: 'rs'},
    {name: 'Seychelles', countryCode: 'sc'},
    {name: 'Sierra Leone', countryCode: 'sl'},
    {name: 'Singapore', countryCode: 'sg'},
    {name: 'Slovakia', countryCode: 'sk'},
    {name: 'Slovenia', countryCode: 'si'},
    {name: 'Solomon Islands', countryCode: 'sb'},
    {name: 'Somalia', countryCode: 'so'},
    {name: 'South Africa', countryCode: 'za'},
    {name: 'South Korea', countryCode: 'kr'},
    {name: 'Spain', countryCode: 'es'},
    {name: 'Sri Lanka', countryCode: 'lk'},
    {name: 'Sudan', countryCode: 'sd'},
    {name: 'Suriname', countryCode: 'sr'},
    {name: 'Swaziland', countryCode: 'sz'},
    {name: 'Sweden', countryCode: 'se'},
    {name: 'Switzerland', countryCode: 'ch'},
    {name: 'Syria', countryCode: 'sy'},
    {name: 'Taiwan', countryCode: 'tw'},
    {name: 'Tajikistan', countryCode: 'tj'},
    {name: 'Tanzania', countryCode: 'tz'},
    {name: 'Thailand', countryCode: 'th'},
    {name: 'Timorleste', countryCode: 'tl'},
    {name: 'Togo', countryCode: 'tg'},
    {name: 'Tokelau', countryCode: 'tk'},
    {name: 'Tonga', countryCode: 'to'},
    {name: 'Trinidad', countryCode: 'tt'},
    {name: 'Tunisia', countryCode: 'tn'},
    {name: 'Turkey', countryCode: 'tr'},
    {name: 'Turkmenistan', countryCode: 'tm'},
    {name: 'Tuvalu', countryCode: 'tv'},
    {name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates'},
    {name: 'Uganda', countryCode: 'ug'},
    {name: 'Ukraine', countryCode: 'ua'},
    {name: 'United Kingdom', countryCode: 'gb', alias: 'uk'},
    {name: 'United States', countryCode: 'us', alias: 'America'},
    {name: 'Uruguay', countryCode: 'uy'},
    {name: 'US Minor Islands', countryCode: 'um'},
    {name: 'US Virgin Islands', countryCode: 'vi'},
    {name: 'Uzbekistan', countryCode: 'uz'},
    {name: 'Vanuatu', countryCode: 'vu'},
    {name: 'Vatican City', countryCode: 'va'},
    {name: 'Venezuela', countryCode: 've'},
    {name: 'Vietnam', countryCode: 'vn'},
    {name: 'Wales', countryCode: 'gb wls'},
    {name: 'Wallis and Futuna', countryCode: 'wf'},
    {name: 'Western Sahara', countryCode: 'eh'},
    {name: 'Yemen', countryCode: 'ye'},
    {name: 'Zambia', countryCode: 'zm'},
    {name: 'Zimbabwe', countryCode: 'zw'},
]

const flagRenderer = (item) => <Flag name={item.countryCode}/>

var keywordsArray: Array<any> = [];
var flagArrayToHtml: Array<any> = [];
var keywordFirstWord: Array<any> = [];
var keywordFirstWordHtml: Array<any> = [];
var keywordsForFilter: Array<any> = [];

var selectedBarColor = "";

var currentSelectedVoice = "";

const DictionaryViewer = (props) => {

    var {translate} = TranslateHelper();

    const axiosHeader = {
        headers: {'Content-Type': 'application/json'}
    };

    const [startColor, setStartColor] = useState("");
    const [endColor, setEndColor] = useState("");
    const [color, setColor] = useState("");
    const [timeOut, setTimeOutLocal] = useState<any>(null);
    selectedBarColor = color;
    const [keywords, setKeywords] = useState<Array<any>>([]);
    const [keywordsHtml, setKeywordsHtml] = useState<any>("");

    const [loader, setLoader] = useState(true);
    const [header, setHeader] = useState("");
    const [desc, setDesc] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [otherLanguage, setOtherLanguage] = useState("");
    const [video, setVideo] = useState("");
    const [voice, setVoice] = useState("");
    const [image, setImage] = useState("");
    const [voiceUrl, setVoiceUrl] = useState<any>();

    const [showFirstImage, setShowFirstImage] = useState(true);

    const [promotionText, setPromotionText] = useState("");

    const [flagArray, setFlagArray] = useState<any>([]);
    const [flagHtml, setFlagHtml] = useState<any>("");

    const [searchText, setSearchText] = useState(props.Word);

    const [uniqueKeyword, setUniqueKeyword] = useState<any>("");

    const [categoryName, setCategoryName] = useState("");
    const [useAreaName, setUseAreaName] = useState("");

    const [dictionaryLogo, setDictionaryLogo] = useState("");

    const filesFromUploads = "/api/Dictionary/"


    const [currentVoiceHtml, setCurrentVoiceHtml] = useState("");

    const [linkKeyword, setLinkKeyword] = useState("");
    const [linkId, setLinkId] = useState("");


    const linkKeywordChangeContent = (event) => {


        var autoSelectedKeywordBarId = event.target.innerText[0].toUpperCase();

        if (document.getElementById(autoSelectedKeywordBarId))
            document.getElementById(autoSelectedKeywordBarId)?.click();


        setTimeout(function () {
            if (document.getElementById("d" + linkId))
                document.getElementById("d" + linkId)?.click();
        }, 500);
    }


    const getKeywordDesc = (e) => {

        setLinkKeyword("");
        setLinkId("");
        setShowFirstImage(false);

        var elements = document.getElementsByClassName("selectedColor");
        for (var i = 0, len = elements.length; i < len; i++) {
            if (elements[i]) {
                elements[i].removeAttribute("style");
            }
            elements[i].classList.remove("selectedColor");
        }
        let keywordId = e.target.id;
        let elementId = keywordId.toString();
        document.getElementById(elementId)?.classList.add("selectedColor");
        //document.getElementById(elementId)?.style.background = color;
        //document.getElementById(elementId)?.style.opacity = 1;

        let selectedKeywordObject = keywords.filter(word => word.dictionaryWordsId == elementId)[0];
        let selectedKeywordLinkId = parseInt(selectedKeywordObject.link);
        if (selectedKeywordLinkId) {
            var wordKeyword = {
                id: selectedKeywordLinkId
            }
            //NetworkRequestWithFunctions('/Word/GetById', wordKeyword,
            //    (response) => {
            //        setLinkKeyword(response.data.count.result.data.name);
            //        setLinkId(response.data.count.result.data.id);
            //    },
            //    (error) => {
            //        ////console.log(error);
            //    }
            //);
        }

        let useAreaId = {
            Id: parseInt(selectedKeywordObject.useAreaId)
        };
        let categoryId = {
            Id: parseInt(selectedKeywordObject.categoryId)
        }
        setHeader(selectedKeywordObject.name);
        setDesc(selectedKeywordObject.description);
        setAbbreviation(selectedKeywordObject.abbreviation);
        setOtherLanguage(selectedKeywordObject.otherLanguage);
        setVideo(selectedKeywordObject.video);

        if (selectedKeywordObject.voice != "" && selectedKeywordObject.voice != null) {
            setVoice("");
            currentSelectedVoice = "";
            setVoice(selectedKeywordObject.voice);
            currentSelectedVoice = selectedKeywordObject.voice;
        } else {
            setVoice("");
            currentSelectedVoice = "";
        }


        if (selectedKeywordObject.image != "" && selectedKeywordObject.image != null)
            setImage(selectedKeywordObject.image);
        else
            setImage("");


        let otherLanguagesFromDatabase = [];
        let otherLanguageArray = Object.keys(JSON.parse(selectedKeywordObject.otherLanguage));
        var flags = flagArray;
        if (flags.length == 0) {
            var dataTenant = {
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            NetworkRequest('api/DictionaryLanguage/GetLanguagesByTenantId', dataTenant).then(response => {
                if (response.data.resultCode == 0) {
                    flags = response.data.detail;

                    if (flags != undefined) {
                        flagArrayToHtml = [];

                        for (let i = 0; i < flags.length; i++) {
                            var content = JSON.parse(selectedKeywordObject.otherLanguage);
                            var text = "";
                            try {
                                var finalText = content[flags[i].key.toString().toLowerCase()] || content[0][flags[i].key.toString().toLowerCase()] || "";
                                if (finalText != undefined) {
                                    text = finalText;
                                }
                            } catch (err) {

                            }

                            flagArrayToHtml.push(<div key={uuidv4()} className="otherLanguageBoxDiv"><Flag name={flags[i].flag}/>{text}
                            </div>)
                        }
                        setFlagHtml(flagArrayToHtml);
                    }
                }

            })
        } else {
            if (flags != undefined) {
                flagArrayToHtml = [];

                for (let i = 0; i < flags.length; i++) {
                    var content = JSON.parse(selectedKeywordObject.otherLanguage);
                    var text = "";
                    try {
                        var finalText = content[flags[i].key.toString().toLowerCase()] || content[0][flags[i].key.toString().toLowerCase()] || "";
                        if (finalText != undefined) {
                            text = finalText;
                        }
                    } catch (err) {

                    }

                    flagArrayToHtml.push(
                        <div
                            key={uuidv4()}
                            className="otherLanguageBoxDiv"
                        >
                            <Flag name={flagArray[i].flag}/>
                            {text}
                        </div>
                    )
                }
                setFlagHtml(flagArrayToHtml);
            }
        }

        if (document.getElementsByClassName("audio-from-word")[0])
            document.getElementsByClassName("audio-from-word")[0].setAttribute("src", currentSelectedVoice);

    }

    useEffect(() => {
        var data = {
            Id: props.DictionaryId
        };

        var dataWords = {
            Id: props.DictionaryId
        }

        var dataTenant = {
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        var filterItems = {
            CategoryId: null,
            UseAreaId: null,
            FilterText: searchText,
            DictionaryId: props.DictionaryId,
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        Promise.all([
            NetworkRequest('api/Dictionary/IncreaseViewerCount', dataWords),
            NetworkRequest('api/Dictionary/GetById', dataWords),
            NetworkRequest('api/DictionaryTheme/GetByTenantId', {tenantId: DataHandler.getUserDataWithKey("tenantId")}),
            NetworkRequest('api/DictionaryLanguage/GetLanguagesByTenantId', dataTenant)
        ]).then(([participantList, dictionaryData, themeData, otherLanguageFlag]) => {

            if (dictionaryData.data.detail.logo != "" && dictionaryData.data.detail.logo != null)
                setDictionaryLogo(dictionaryData.data.detail.logo);
            else
                setDictionaryLogo("");

            setFlagArray([...otherLanguageFlag.data.detail]);

            setPromotionText(dictionaryData.data.detail.promotionText);

            if (themeData.data.detail != null) {
                setStartColor(themeData.data.detail.startColor);
                setEndColor(themeData.data.detail.endColor);
                setColor(themeData.data.detail.color);
            } else {
                setStartColor("");
                setEndColor("");
                setColor("");
            }

            for (var i = 0; i < keywordFirstWord.length; i++) {
                keywordFirstWordHtml.push(
                    <Button
                        key={uuidv4()}
                        onClick={selectedFirstKeyword}
                        id={keywordFirstWord[i].toUpperCase()}
                        className="keywordbar-button"
                        circular
                    >
                        {keywordFirstWord[i].toUpperCase()}
                    </Button>
                )
            }
            setUniqueKeyword(keywordFirstWordHtml);
        });

    }, []);

    const getWords = () => {

        var dataTenant = {
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            isDeleted: false
        }

        NetworkRequest('api/Dictionary/GetWordsByTenantId', dataTenant).then((response) => {
            setKeywords(response.data.detail)
            keywordsForFilter = response.data.detail

            keywordFirstWord = [];

            response.data.detail.forEach((item) => {
                keywordFirstWord.push(item.name[0].toUpperCase());
            });

            keywordFirstWordHtml = [];
            keywordFirstWord = [...new Set(keywordFirstWord)].sort();
            
            for (var i = 0; i < keywordFirstWord.length; i++) {
                keywordFirstWordHtml.push(
                    <Button
                        key={uuidv4()}
                        onClick={selectedFirstKeyword}
                        id={keywordFirstWord[i].toUpperCase()}
                        className="keywordbar-button"
                        circular
                    >
                        {keywordFirstWord[i].toUpperCase()}
                    </Button>
                )
            }
            setUniqueKeyword(keywordFirstWordHtml);
            setLoader(false);
        })

    }


    useEffect(() => {

        keywordsArray = [];

        Object.keys(keywords).forEach((key) => {

            const currentKeywordId = keywords[key].dictionaryWordsId
            keywordsArray.push(
                <div
                    key={uuidv4()}
                    onClick={getKeywordDesc}
                    id={currentKeywordId}
                    // key={currentKeywordId} 
                    className="dictionary-keyword-box"
                >
                    {keywords[key].name}
                </div>
            );

        });
        setKeywordsHtml(keywordsArray);

    }, [keywords]);


    useEffect(() => {
        if (searchText != "")
            search(searchText);
        else
            getWords();
    }, [searchText]);

    useEffect(() => {
        //console.log(flagArray);
    }, [flagArray]);

    const search = (text) => {
        if (text != "") {
            var filterItems = {
                CategoryId: null,
                UseAreaId: null,
                FilterText: text,
                DictionaryId: props.DictionaryId,
                TenantId: DataHandler.getUserDataWithKey("tenantId"),
                isDeleted: false
            };
            NetworkRequest('api/DictionaryWord/Search', filterItems).then((response) => {
                if (response.data.detail != null) {
                    setKeywords(response.data.detail);
                } else {
                    toast("'" + text + "' aramasına uyan kelime bulunamadı.", {
                        type: 'warning',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    setKeywords([]);
                }

                setLoader(false);
            });
        } else {
            setKeywords(keywordsForFilter);
        }
    }

    const selectedFirstKeyword = (e) => {

        var elements = document.getElementsByClassName("selectedElement");
        for (var i = 0, len = elements.length; i < len; i++) {
            elements[i].removeAttribute("style");
        }

        let currentSelectedButton = e.target.id.toString();

        document.getElementById(currentSelectedButton.toUpperCase())?.classList.add("selectedElement");
        document.getElementById(currentSelectedButton.toUpperCase())?.style.setProperty("background-color", selectedBarColor, "important");
        //document.getElementById(currentSelectedButton.toUpperCase())?.style.opacity = 1;
        document.getElementById(currentSelectedButton.toUpperCase())?.setAttribute("style", "opacity: 1");
        let selectedKeywordsObject = keywordsForFilter.filter(word => word.name[0].toLowerCase() == currentSelectedButton.toLowerCase());
        setKeywords(selectedKeywordsObject);
    }

    useEffect(() => {
        setVoiceUrl(displayProtectedAppDoc(voice));
    }, [voice])
    return (
        <Transition visible={!loader} animation='fade left' duration={200}>
            <div className={"dictionaryViewerContainer"}>
                <Grid className={"dictionaryViewerContainer"} divided='vertically'>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <div className="dictionary-container-box">
                                <div className="dictionary-close">
                                    <Button circular icon='close' onClick={() => {
                                        setLoader(true);
                                        setTimeout(() => {
                                            props.setShowDictionaryViewer(false)
                                        }, 250)
                                    }}/>
                                </div>
                                <div className="dictionary-searchbar">
                                    <Input
                                        icon='search'
                                        placeholder={translate('Arama..')}
                                        fluid
                                        className="dictionary-searchbar-input"
                                        value={searchText}
                                        onChange={o => {
                                            setSearchText(o.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{background: `linear-gradient(to right, ${startColor}, ${endColor})`}}
                                     className="dictionary-keywordbar">
                                    {uniqueKeyword}
                                </div>
                                <div className="dictionary-content-box">
                                    <div style={{background: `linear-gradient(to right, ${startColor}, ${endColor})`}}
                                         className="dictionary-left-keyword-list-box">
                                        {keywordsHtml}
                                    </div>
                                    <div className="dictionary-right-keyword-desc-box">
                                        {showFirstImage
                                            &&
                                            <div id="firstScreenImageBox"></div>
                                        }
                                        <h2 style={{color: `${color}`}} className="dictonary-header-text">{header}</h2>
                                        <div className="dictionar-desc-box-text">
                                            <p className="dictionary-desc-text">
                                                <span className="dictionary-desc-bold"> </span>
                                                {desc}
                                            </p>
                                        </div>
                                        <div className="category-and-useara-content">
                                            {categoryName ? <div className="micro-data"><Icon size="large"
                                                                                              name="box"/> (Kategori): {categoryName}
                                            </div> : ""}
                                            {useAreaName ? <div className="micro-data"><Icon size="large"
                                                                                             name="setting"/> (Kullanım
                                                alanı): {useAreaName} </div> : ""}
                                            {abbreviation ? <div className="micro-data"><Icon size="large"
                                                                                              name="cut"/> (Kısaltma): {abbreviation}
                                            </div> : ""}
                                        </div>
                                        <div className={"link-keyword-box"}>
                                            {
                                                linkKeyword ? <div className="micro-data"><strong>bknz : </strong> <span
                                                    id={"linkId-" + linkId} onClick={linkKeywordChangeContent}
                                                    className={"link-keyword-button"}>{linkKeyword}</span></div> : ""
                                            }
                                        </div>

                                        <div className="other-languages-box">
                                            <div>
                                                {flagHtml}
                                            </div>
                                        </div>

                                        {voiceUrl
                                            &&
                                            <div className="voice-box">
                                                <audio className={"audio-from-word"} controls>
                                                    <source src={voiceUrl} type="audio/ogg"/>
                                                </audio>
                                            </div>
                                        }
                                        <div className="video-box">
                                            <Image id="keyword-image" className="keyword-image"
                                                   src={displayProtectedAppDoc(image, "keyword-image")}/>
                                        </div>
                                        <div className="video-box">
                                            <iframe width="100%" height="480" src={video} frameBorder="0"
                                                    allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="promotionText">{promotionText}</div>
                                <a href="https://www.digitallica.com.tr" target="_blank">
                                    <div className="dictionary-digitallica-logo-box"></div>
                                </a>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Transition>
    );
}

export default DictionaryViewer;