import React, {useEffect} from 'react';

import {useNavigate} from "react-router-dom";
import { DataHandler } from '../../helper/DataHelper';

const NotFoundPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        DataHandler.logOutUser();
        window.location.href = "/NotFoundPage";
    }, []);

    return (
        <div className="loader-container">
            <div className="custom-loader"></div>
        </div>
    );
};

export default NotFoundPage;