import React, {useEffect, useState} from 'react';
import SystemLogRow from "./SystemLogRow";
import TranslateHelper from "../../../helper/TranslateHelper";
import {IDataTable} from "../../../interfaces/interfaces";
import {prepareDataForTable} from "../../../helper/HelperFunctions";

import DataTable from "../../../components/DataTable";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import { DataHandler } from '../../../helper/DataHelper';

const SystemLogs = () => {
    var {translate} = TranslateHelper();
    const [pageCount, setPageCount] = useState<any>(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [logList, setLogList] = useState<Array<any>>();
    const [filterData, setFilterData] = useState<any>({});
    const [filterText, setFilterText] = useState<string>("");
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    
    const [logLevel, setLogLevel] = useState("");
    const [logType, setLogType] = useState("");
    const [eventType, setEventType] = useState("");

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }
    
    const onChangeDropdownLogLevel = (value) => {
        setLogLevel(value);
    }
    const onChangeDropdownLogType = (value) => {
        setLogType(value);
    }
    const onChangeDropdownEventType = (value) => {
        setEventType(value);
    }

    let tableHeader = [
        translate("Sorumlu Çalışan"),
        translate("Log Level"),
        translate("Log Type"),
        translate("Event Type"),
        translate("Receiver IP"),
        translate("Receiver Port"),
        translate("Referrer IP"),
        translate("Referrer Port"),
        translate("Oluşturma Tarihi")
    ];
    let hiddenProps = [
        'id'
    ];

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: [],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                },
                {
                    desc: translate("Log Level"),
                    defaultValue: "css",
                    key: "dropdown",
                    type: "dropdown",
                    onChange: onChangeDropdownLogLevel,
                    options: [
                        { key: 'test', text: 'Test', value: 'Test' },
                        { key: 'error', text: 'Error', value: 'Error' },
                        { key: 'firstModel', text: 'FirstModel', value: 'FirstModel' },
                        { key: 'information', text: 'Information', value: 'Information' }
                    ]
                },
                {
                    desc: translate("Log Type"),
                    defaultValue: "css",
                    key: "dropdown",
                    type: "dropdown",
                    onChange: onChangeDropdownLogType,
                    options: [
                        { key: 'system', text: 'System', value: 'System' },
                        { key: 'test', text: 'Test', value: 'Test' },
                        { key: 'firstModel', text: 'FirstModel', value: 'FirstModel' },
                    ]
                },
                {
                    desc: translate("Event Type"),
                    defaultValue: "css",
                    key: "dropdown",
                    type: "dropdown",
                    onChange: onChangeDropdownEventType,
                    options: [
                        { key: 'DDOS', text: 'DDOS', value: 'DDOS' },
                        { key: 'Get', text: 'Get', value: 'Get' },
                        { key: 'Invoke', text: 'Invoke', value: 'Invoke' },
                        { key: 'Job', text: 'Job', value: 'Job' },
                        { key: 'PendingJob', text: 'Pending Job', value: 'PendingJob' },
                        { key: 'Start', text: 'Start', value: 'Start' },
                    ]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        addRowLink: "",
        isAttachingPage: true
    });

    //prepare data for table
    const prepareData = (response) => {

        let data = response;
        //hidden column
        let model = prepareDataForTable(data.list, hiddenProps);

        // var pageCount = Math.ceil(data.length / 25);
       

        setDataTable({

            data: model,
            header: dataTable.header,
            filter: {
                setSearch: setFilterText,
                search: filterText,
                filterOption: [
                    {
                        desc: translate("Tarih"),
                        defaultValue: [startingDate, dueDate],
                        key: "date",
                        type: "date",
                        onChange: [onChangeDate1, onChangeDate2]
                    },
                    {
                        desc: translate("Log Level"),
                        defaultValue: logLevel,
                        key: "dropdown",
                        type: "dropdown",
                        onChange: onChangeDropdownLogLevel,
                        options: [
                            { key: 'test', text: 'Test', value: 'Test' },
                            { key: 'error', text: 'Error', value: 'Error' },
                            { key: 'firstModel', text: 'FirstModel', value: 'FirstModel' },
                            { key: 'information', text: 'Information', value: 'Information' }
                        ]
                    },
                    {
                        desc: translate("Log Type"),
                        defaultValue: logType,
                        key: "dropdown",
                        type: "dropdown",
                        onChange: onChangeDropdownLogType,
                        options: [
                            { key: 'system', text: 'System', value: 'System' },
                            { key: 'test', text: 'Test', value: 'Test' },
                            { key: 'firstModel', text: 'FirstModel', value: 'FirstModel' },
                        ]
                    },
                    {
                        desc: translate("Event Type"),
                        defaultValue: eventType,
                        key: "dropdown",
                        type: "dropdown",
                        onChange: onChangeDropdownEventType,
                        options: [
                            { key: 'DDOS', text: 'DDOS', value: 'DDOS' },
                            { key: 'Get', text: 'Get', value: 'Get' },
                            { key: 'Invoke', text: 'Invoke', value: 'Invoke' },
                            { key: 'Job', text: 'Job', value: 'Job' },
                            { key: 'PendingJob', text: 'Pending Job', value: 'PendingJob' },
                            { key: 'Start', text: 'Start', value: 'Start' },
                        ]
                    }
                ]
            },
            transactions: dataTable.transactions,
            addRowLink: dataTable.addRowLink,
            noCheckBox: dataTable.noCheckBox,
            pagination: {
                count: Math.ceil(
                    data.count / 50
                ), setCurrentPage: setCurrentPage, currentPage: currentPage
            }
        });
        setIsLoading(false);

    }

    
    const getSystemLogs = (filterData?) => {
        NetworkRequest("api/Report/GetSystemLogs", filterData).then((response) => {
            ////
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }


    //filter's every change
    useEffect(() => {
        setIsLoading(true)
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            logLevel: logLevel,
            logType:logType,
            eventType:eventType
        }
        setFilterData(filterData);
        getSystemLogs(filterData);
    }, [filterText, currentPage, startingDate, dueDate,logType,logLevel,eventType]);

    return (
        <div>
            <div>
                <DataTable 
                    dataTable={dataTable} 
                    isAllSelected={false} 
                    selectAllHandler={()=> {}}
                    isLoading={isLoading} 
                    filterData={filterData}
                />
            </div>
        </div>
    );
};

export default SystemLogs;