import * as React from 'react';

export enum MessageTypes {
    Success,
    Error,
    Warning
}

export interface Message {
    Header: string;
    Description: string;
    MessageType: MessageTypes;
}

const Message: React.FC<Message> = ({ Header, Description, MessageType }: Message) => {

    let styles = {
        message: {
            width: "100%",
            height: "auto",
            borderRadius: "1rem",
            boxShadow: "rgb(149 157 165 / 10%) 0px 8px 24px",
            display: "flex",
            flexDirection: "column" as "column",
            margin: "0 auto",
            padding: "5%",
            gap: "1em",
            border: MessageType === MessageTypes.Success
                ? "green solid 0.1rem"
                : MessageType === MessageTypes.Error
                    ? "#9f3a38 solid 0.1rem"
                    : "orange solid 0.1rem",
            color: MessageType === MessageTypes.Success
                ? "green"
                : MessageType === MessageTypes.Error
                ? "#9f3a38"
                : "orange",
            backgroundColor: MessageType === MessageTypes.Success
                ? "green"
                : MessageType === MessageTypes.Error
                ? "#fff6f6"
                : "orange"
        },
        header: {
            fontWeight: "bold" as "bold",
            fontSize: "1.3em"
        },
        description: {
            fontSize: "1em"
        }
    }

    return (
        <div style={styles.message}>
            <div style={styles.header}>
                {Header}
            </div>
            <div style={styles.description}>
                {Description}
            </div>
        </div>
    );
}

export default Message;