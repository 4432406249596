import * as React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SyntheticEvent } from 'react-toastify/dist/utils';
import {Container, Button, Icon, Dropdown, Divider, Loader, Header} from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { ILanguage, ILanguageDropdown } from '../../../interfaces/interfaces';
import LanguageKeywords from "./LanguageKeywords";
import TranslateHelper from "../../../helper/TranslateHelper";


const LanguageSettings = (props) => {
    var { translate } = TranslateHelper();
    const [languageList, setLanguageList] = useState<ILanguage[]>([]);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [selectedLanguages, setSelectedLanguages] = useState<any>([]);
    const [notSelectedLanguages, setNotSelectedLanguages] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const getLanguages = () => {
        setIsLoading(true);
        NetworkRequest("/api/Language/GetLanguages", null)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    const languages = response.data.detail;

                    languages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: l.text,
                            value: l.value
                        }

                        setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                    })

                    fillSelectedLanguages(languages);

                } else {
                    toast(translate("Bir sorun oluştu. Lütfen tekrar deneyiniz."),
                        {
                            type: 'info',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });
                }

                setIsLoading(false);
            });
    }

    const handleChanges = (event: any, data: any) => {
        setSelectedLanguages(data?.value)
    }

    const saveLanguagePreferences = () => {

        if (selectedLanguages.length < 1)
            toast(translate("Lütfen en az bir dil seçiniz."),
                {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
        else {
            let languageData = {
                selectedLanguages: selectedLanguages,
                notSelectedLanguages: notSelectedLanguages
            };

            setIsLoading(true);
            NetworkRequest("/api/Language/SaveLanguagePreferences", languageData)
                .then((response) => {
                    if (response.data.resultCode == 0) {
                        toast(translate(response.data.message),
                            {
                                type: 'success',
                                theme: 'dark',
                                position: 'top-center',
                                autoClose: 2000
                            });

                    } else {
                        toast(translate("Bir sorun oluştu. Lütfen tekrar deneyiniz."),
                            {
                                type: 'info',
                                theme: 'dark',
                                position: 'top-center',
                                autoClose: 2000
                            });
                    }

                    setIsLoading(false);
                });
        }
    }

    const fillSelectedLanguages = (languages) => {
        const selectedLanguages = languages
            .filter((l) => { return l.isActive == true })
            .map((l) => { return l.value });


        setSelectedLanguages(selectedLanguages);
    }

    useEffect(() => {
        var allLanguageAsFlafCode = languageListDropdown.map((l) => { return l.flag });

        var notSelectedLanguages = allLanguageAsFlafCode
            .filter(function (obj) { return selectedLanguages.indexOf(obj) == -1; });

        setNotSelectedLanguages(notSelectedLanguages);
       
    }, [selectedLanguages]);

    useEffect(() => {
        getLanguages();
    }, [])

    return (
        <Container>
            <h4>{translate("Sistem dillerini seçiniz.")}</h4>
            <div>
                <Dropdown
                    value={selectedLanguages}
                    size="big"
                    multiple
                    placeholder={translate('Dil Seçiniz')}
                    fluid
                    search
                    selection
                    lazyLoad={isLoading}
                    options={languageListDropdown}
                    onChange={(event, data) => {
                        handleChanges(event, data)
                    }}
                />
            </div>
            <Button
                disabled={selectedLanguages.length < 1}
                loading={isLoading}
                style={{ marginTop: "2%" }}
                onClick={saveLanguagePreferences}
                color="green"
            >
                {translate("Kaydet")}
            </Button>
            <LanguageKeywords />
        </Container>
    );
}

export default LanguageSettings;