import React, {useEffect, useState, memo} from 'react';
import {Button, Icon, Label} from "semantic-ui-react";
import {v4 as uuidv4} from 'uuid';
import TranslateHelper from "../../../helper/TranslateHelper";

interface ISelectedFilterElements {
    filterData: any
    setFilterData: any
    getAssignedCoursesByFilter: any
    getAssignedCourses: any
}

const SelectedFilterElements = ({filterData, setFilterData,getAssignedCoursesByFilter, getAssignedCourses}: ISelectedFilterElements) => {
    var {translate} = TranslateHelper();
    const [filterElement, setFilterElement] = useState<any>([]);

    const returnValueToText = (value: string) => {
        switch (value) {
            case "newModules":
                return "New modules"

            case "continueModules":
                return "Continue modules"

            case "doneModules":
                return "Done modules"

            default:
                return value;
        }
    }

    const returnKeyToText = (key: string) => {
        switch (key) {
            case "filterText":
                return "Filtreleme metni: "

            case "startingDate":
                return "Başlangıç tarihi: "

            case "dueDate":
                return "Bitiş tarihi: "

            case "status":
                return "İlerleme durumu: "
        }
    }

    const removeFilterByKey = (key: string): void => {
        //debugger;
        
        let filterDataNew = {
            filterText: filterData.filterText,
            startingDate: filterData.startingDate,
            dueDate: filterData.dueDate,
            status: filterData.status,
            currentPage: filterData.currentPage
        }
        switch (key) {
            case "filterText":
                filterDataNew.filterText = "";
                setFilterData(filterDataNew);
                break;

            case "startingDate":
                //debugger;
                filterDataNew.startingDate = null;
                setFilterData(filterDataNew);
                break;

            case "dueDate":
                filterDataNew.dueDate = null;
                setFilterData(filterDataNew);
                break;

            case "status":
                filterDataNew.status = ""
                setFilterData(filterDataNew);
                break;
        }
    }

    const removeAllFilter = (): void => {
        let filterData = {
            filterText: "",
            startingDate: null,
            dueDate: null,
            status: "",
            currentPage: 1
        }

        setFilterData(filterData);

        //getAssignedCourses(filterData);
    }

    const renderFilterElement = () => (
        <div className={"selectedFilterElementRenderContainer"}>
            {
                filterElement.map((filterElement) => (
                    <Label
                        as='button'
                        key={uuidv4()}
                        size={"large"}
                        circular={true}
                    >
                        {returnKeyToText(translate(filterElement.key))}
                        {returnValueToText(translate(filterElement.value))}

                        <Icon onClick={()=>{removeFilterByKey(filterElement.key)}} color={"red"} name='delete'/>
                    </Label>
                ))
            }

            {filterElement.length > 0 &&  <Button onClick={removeAllFilter} className={"ml-1"} size={"tiny"} color={"red"} inverted
                                                 circular={true}>{translate("Tümünü temizle")}</Button>}
           
        </div>

    )

    useEffect(() => {
        if (filterData != undefined && filterData != null) {
            for (const [key, value] of Object.entries(filterData)) {
                //debugger;
                if (value != "" && value != null && key != "currentPage") {
                    setFilterElement(oldArray => [...oldArray, {key: key, value: value}]);
                }
            }
        }
    }, [])

    return (
        <div className={"selectedFilterElementContainer"}>
            {renderFilterElement()}
        </div>
    );
};

export default memo(SelectedFilterElements);