import React, { useState, useEffect, useRef } from 'react';

import { Icon, Image } from "semantic-ui-react";
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import TranslateHelper from "../../../helper/TranslateHelper";


const FileInputComp = (props: any) => {
    var { translate } = TranslateHelper();
    const [file, setFile] = useState<any>(undefined);
    const [objectUrl, setObjectUrl] = useState<any>(undefined);
    const [videoUrl, setVideoUrl] = useState<any>(undefined);
    const [subtitleUrl, setSubtitleUrl] = useState<any>(undefined);
    const [subtitleFile, setSubtitleFile] = useState<any>(undefined);
    const fileInputRef = useRef<any>();
    const [fileType, setFileType] = useState("");

    const handleDrop = (e) => {
        e.preventDefault();
        setFile(e.dataTransfer.files[0]);
    };

    const fileInputClicked = (event): any => {
        event.stopPropagation()
        fileInputRef.current.click();
    }

    const inputChangeHandler = (e): any => {
        if (fileInputRef.current.files.length) {
            setFile(fileInputRef.current.files[0]);
        }
    }

    useEffect(() => {
        //console.log(file);
        if (file != undefined) {
            const objectUrl = URL.createObjectURL(file);
            handleFileExtention(file.type.split("/")[0]);
            setObjectUrl(objectUrl);
            if (props.type == "image") {
                if (props.updateBackgroundImage != undefined) {
                    props.updateBackgroundImage(file, props.section);
                    return;
                }
                if (props.setBackgroundImage != undefined) {
                    props.setBackgroundImage(file)
                    return;
                } else if (props.setTaskDocUrl != undefined && props.setTaskDocFile != undefined) {
                    props.setTaskDocUrl(file);
                    props.setTaskDocFile(objectUrl);
                    return;
                }
                else if (props.setDocumentFile != undefined && props.setDocumentUrl != undefined) {
                    props.setDocumentFile(objectUrl);
                    props.setDocumentUrl(file);
                }
                else if (props.setImage != undefined && props.setPhotoUrl != undefined) {
                    props.setPhotoUrl(file);
                    props.setImage(objectUrl);
                }
            } else if (props.type == "video") {
                props.setVideoFile(objectUrl);
                props.setVideoUrl(file)
                return;
            } else if (props.type == "subtitle") {
                props.setSubtitleFile(objectUrl);
                props.setSubtitleUrl(file)
                return;
            }
            else if (props.type == "scorm") {
                props.setScormFile(objectUrl);
                props.setScormUrl(file)
                return;
            }

        }
    }, [file]);

    const handleFileExtention = (type: string) => {
        switch (type) {
            case "video":
                setFileType("video");
                break;

            case "image":
                setFileType("image")
                break;

            case "application":
                setFileType("file")
                break;
        }
    }

    const styles = {
        dropZone: {
            color: file != undefined && file.name != undefined ? "#2185d0" : "#555555",
            border: file != undefined && file.name != undefined ? "2px #2185d0 dashed" : "2px #c3c3c3 dashed",
            backgroundColor: "#f8f8f8",
            cursor: "pointer"
        },

        previewImageContainer: {
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: ".5%"
        },
        fileInput: {
            display: "none"
        }
    }

    useEffect(() => {
        if (objectUrl && fileType == "video") {
            setVideoUrl(displayProtectedAppDoc(objectUrl));
        }
    }, [objectUrl])

    return (
        <div>
            <div
                style={styles.dropZone}
                onClick={(event) => {
                    fileInputClicked(event)
                }}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                className='filesDragAndDropArea'>
                <div>
                    <Icon name={"file outline"} />
                </div>
                <div>
                    {file != undefined && file.name != undefined ?
                        file.name.length >= 40 ? file.name.substr(0, 40) + "..." : file.name
                        : translate("Dosyayı sürükle bırak")}
                </div>

                <input
                    ref={fileInputRef}
                    style={styles.fileInput}
                    onChange={inputChangeHandler}
                    id="photoUploader"
                    type={"file"}
                    accept={props.accept != undefined ? props.accept : "*"}
                />
            </div>
            {props.dontShowPreview != true &&
                <div style={styles.previewImageContainer}>
                    {objectUrl && fileType == "image"
                        &&
                        <Image id="objeImage" width="100%" source={displayProtectedAppDoc(objectUrl, "objeImage")} />
                    }
                    {videoUrl != undefined
                        &&
                        <video style={{ position: "absolute" as "absolute", left: "32%", top: "4%" }} width="40%" height="400" controls>
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    }
                    {objectUrl && fileType == "application"
                        &&
                        <iframe src={objectUrl} width={"100%"} height={"auto"} />
                    }
                </div>
            }
        </div>

    );
};

export default FileInputComp;