import React, {useEffect, useState} from "react";
import {IDataTable} from "../../../interfaces/interfaces";
import {Checkbox, Loader, Transition} from "semantic-ui-react";
import DataTable from "../../../components/DataTable";
import { NetworkRequest} from "../../../helper/NetworkRequest";
import { prepareDataForTable, datableFilterChange, downloadFile} from "../../../helper/HelperFunctions";

import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from "../../../helper/DataHelper";

const SurveyReport = (props) => {
    var {translate} = TranslateHelper();
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterData, setFilterData] = useState<any>({});
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    //const setAllSelected = (data) => {
    //    setIsAllSelected(data.checked);
    //}

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTable, data.checked, 0, setDataTable, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTable, data.checked, 1, setDataTable, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(dataTableModel);
        }, 100);
    }
    const surveyExcelReport = () => {
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        NetworkRequest("api/Report/GetSurveyReportToExcel", data).then((response) => {
            downloadFile(response.data, translate("Anket Raporu"));
        });
    }

    let tableHeader = [
        translate("Anket Adı"),
        translate("Soru Sayısı"),
        translate("Kayıtlı Olduğu Modül Sayısı"),
        translate("Tanımlı Kişi Sayısı"),
        translate("Cevaplayan Sayısı"),
        translate("Oluşturma Tarihi"),
        translate("İşlemler")
    ];
    let hiddenProps = [
        'id',
        'searchField',
        'createdDate',
        'isDeleted',
        'isActive'
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(1, 0, translate("Şirket"));
    } else {
        hiddenProps.push("tenantName");
    }

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "chart bar",
                desc: translate("Rapor"),
                color: "orange",
                link: "/srvyPersonDetailReport",
                type: "report"
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        addRowLink: "",
        setRowSelected: setRowSelected,
        isAttachingPage:true
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response;
        //hidden column
        let model = prepareDataForTable(data.list, hiddenProps);
        ////console.log(model);
        setTimeout(() => {
            setDataTable({
                data: model,
                header: dataTable.header,
                transactions: dataTable.transactions,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox,
                pagination: {
                    count: Math.ceil(
                        data.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
            setIsLoading(false);
        }, 200);
    }

    const getSurveys = (filterData?) => {
      /*  debugger;*/
        NetworkRequest("api/Report/GetSurveyReport", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    //filter's every change
    useEffect(() => {
        setIsLoading(true);
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1"
        }

        setFilterData(filterData);
        getSurveys(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, props.selectedTenantId]);

    return(
        <div>
            <Transition visible={false} animation='scale' duration={500}>
                <div>
                    <Loader size={"huge"} active inline='centered' />
                </div>
            </Transition>
            <Transition visible={true} animation='scale' duration={500}>
                <div>
                    <DataTable dataTable={dataTable} isAllSelected={isAllSelected} selectAllHandler={selectAllHandler} exportExcelFunction={surveyExcelReport} isLoading={isLoading} />
                </div>
            </Transition>
        </div>
    );
};

export default SurveyReport;