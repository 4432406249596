import * as React from 'react';
import {useState, useEffect} from 'react';
import {NetworkRequest } from '../../../helper/NetworkRequest';
import {Loader, Transition} from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import {IDataTable} from '../../../interfaces/interfaces'
import {prepareDataForTable, datableFilterChange, downloadFile} from '../../../helper/HelperFunctions';

import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Modules {
    id?: string,
    title?: string,
    description?: string,
    createdDate?: Date
}

const ModuleReport = (props) => {
    var {translate} = TranslateHelper();
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState({});
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState(true);

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox

            });
            setDataTable(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTable, data.checked, 0, setDataTable, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTable, data.checked, 1, setDataTable, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const multipleDelete = (selectedData) => {
        ////console.log(selectedData);
    }

    let tableHeader = [
        translate("Adı"),
        translate("Açıklama"),
        translate("Ortalama Puan"),
        translate("Yükleyen Çalışan"),
        translate("Yüklenme Tarihi"),
        translate("Kayıtlı Çalışan Sayısı"),
        translate("İşlemler")
    ];
    let hiddenProps = [
        'id',
        'isActive',
        'isWelcomeModule'
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(1, 0, translate("Şirket"));
    } else {
        hiddenProps.push("tenantName");
    }

    const exportExcelReport = () => {
        let filterData = {
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
        };

        NetworkRequest("api/Report/GetModulesWithReportDetailsExcel", filterData).then(response => {
            downloadFile(response.data, translate("Modül Raporu"));
        });
    }

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: [
            {
                icon: "line graph",
                desc: translate("Rapor"),
                color: "orange",
                link: "/capsuleReport",
                type: "report"
            },

        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        addRowLink: "",
        setRowSelected: setRowSelected,
        isAttachingPage: true
    });

    //prepare data for table
    const prepareData = (data) => {
        //hidden column      
        var model = prepareDataForTable(data, hiddenProps);

        setTimeout(() => {
            setDataTable({
                data: model,
                header: dataTable.header,
                transactions: dataTable.transactions,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox,
                pagination: {
                    count: Math.ceil(
                        data.length / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
            setIsLoading(false);
        }, 500);
    }

    //get table data from database
    const getReportModuleList = (filterData?) => {
        NetworkRequest("api/Modules/GetModulesWithReportDetails", filterData).then(response => {
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    //onLoad page function
    useEffect(() => {
    }, []);

    //search function
    useEffect(() => {
        ////console.log(filterText);
    }, [filterText]);

    useEffect(() => {
        ////console.log(dataTable);
    }, [dataTable]);

    //filter's every change
    useEffect(() => {
        setIsLoading(true);
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1"
        };
        setFilterData(filterData);
        getReportModuleList(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, props.selectedTenantId]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable
                            dataTable={dataTable}
                            isAllSelected={isAllSelected}
                            selectAllHandler={selectAllHandler}
                            isLoading={isLoading}
                            exportExcelFunction={exportExcelReport}
                        />
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default ModuleReport;