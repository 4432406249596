import React, {useState, useEffect} from 'react';
import {
    IQuestionReport,
    IQuestionReportCard,
    ISurveyQuestionReport
} from "../interfaces/interfaces";
import parse from "html-react-parser";
import { Label, Button,Popup } from 'semantic-ui-react';
import TranslateHelper from "../helper/TranslateHelper";

const SurveyQuestionReportCard = ({questionJson,userAnswer,surveyQuestionType}: ISurveyQuestionReport) => {
    const [selectColor] = useState("#00800054");
    const [questionBody,setQuestionBody] = useState("");
    const [answerDetail, setAnswerDetail] = useState("");
    var { translate } = TranslateHelper();
    
    useEffect(()=> {
        var div = document.createElement('div');
        div.innerHTML = questionJson.body;
        let images = div.getElementsByTagName('img');
        
        if(typeof  images == "object") {
            for (let i =0; i < images.length; i++) {
                ////console.log(images[i]);
                images[i].setAttribute("style","width:100%;");
            }

            setQuestionBody(div.innerHTML);
            if (userAnswer == "A") {
                setAnswerDetail(questionJson.a)
            } else if (userAnswer == "B") {
                setAnswerDetail(questionJson.b)

            } else if (userAnswer == "C") {
                setAnswerDetail(questionJson.c)

            } else if (userAnswer == "D") {
                setAnswerDetail(questionJson.d)

            } else if (userAnswer == "E") {
                setAnswerDetail(questionJson.e)

            }

        }
    },[])
    
    const styles = {
        questionContainer: {
            display: "flex",
            flexDirection:"column" as "column",
            gap: "0.5em",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            padding: "20px",
            borderRadius: "10px",
            marginTop: "20px",
            width: "350px",
            justifyContent: "center",
            alignItems: "center"
        },

        option: {
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            margin: "10px",
            borderRadius: "10px",
            width: "100%",
            height: "auto",
            padding: "18px",
            display: "flex",
            alignItems:"center",
            gap: "1em",
            fontFamily: "sans-serif",
        },
        questionBody: {
            fontSize: "1.3em",
            width: "100%"
        }
    }
    return (
        <div style={ styles.questionContainer}>
            <div style = {{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
                <div style={styles.questionBody}>{parse(questionBody)}</div>
            </div>

            {surveyQuestionType === 1 ?
                <>
                    <div 
                        style={{...styles.option, backgroundColor: "A" === userAnswer.toUpperCase() ? selectColor : "unset"}}
                    >
                        <Label color={"black"}>A</Label>
                        {questionJson.a}
                    </div>

                    <div
                        style={{...styles.option, backgroundColor: "B" === userAnswer.toUpperCase() ? selectColor : "unset"}}
                    >
                        <Label color={"black"}>B</Label>
                        {questionJson.b}
                    </div>

                    <div
                        style={{...styles.option, backgroundColor: "C" === userAnswer.toUpperCase() ? selectColor : "unset"}}
                    >
                        <Label color={"black"}>C</Label>
                        {questionJson.c}
                    </div>

                    <div
                        style={{...styles.option, backgroundColor: "D" === userAnswer.toUpperCase() ? selectColor : "unset"}}
                    >
                        <Label color={"black"}>D</Label>
                        {questionJson.d}
                    </div>

                    <div
                        style={{...styles.option, backgroundColor: "E" === userAnswer.toUpperCase() ? selectColor : "unset"}}
                    >
                        <Label color={"black"}>E</Label>
                        {questionJson.e}
                    </div>
                </>:
                <>
                    <div style={styles.option}>
                        <Label color={"black"}>{translate("Cevap")}</Label>
                        {userAnswer} : {answerDetail}
                    </div>
                   
                </>
            }

            

        </div>
    )
}

export default SurveyQuestionReportCard;