import React, {useState, useEffect} from 'react';
import {IQuestionReport, IQuestionReportCard} from "../interfaces/interfaces";
import parse from "html-react-parser";
import { Label, Button,Popup } from 'semantic-ui-react';
import TranslateHelper from '../helper/TranslateHelper';

const QuestionReportCard = ({questionJson,isDeleted,userAnswer,isActive}: IQuestionReport) => {
    var {translate} = TranslateHelper();
    const [trueColor] = useState("#00800054");
    const [wrongColor] = useState("#f9101078");
    
    const styles = {
        questionContainer: {
            display: "flex",
            flexDirection:"column" as "column",
            gap: "0.5em",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            padding: "20px",
            borderRadius: "10px",
            marginTop: "20px",
            width: "350px",
            justifyContent: "center",
            alignItems: "center"
        },
        
        option: {
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            margin: "10px",
            borderRadius: "10px",
            width: "100%",
            height: "auto",
            padding: "18px",
            display: "flex",
            alignItems:"center",
            gap: "1em",
            fontFamily: "sans-serif",
        },
        questionBody: {
            fontSize: "1.3em",
            width: "100%",
            overflow: "auto",
            textoverflow: "ellipsis",
            whitespace: "nowrap"
        }
    }
    return (
        <div className={"questionReportCardContainer"} style={styles.questionContainer}>
            <div style = {{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
                <div style={styles.questionBody}>{parse(questionJson.body)}</div>
                <div>
                    <Popup 
                        content={
                            userAnswer.toUpperCase() === questionJson.correctAnswer.toUpperCase() 
                            ? translate("Doğru İşaretlenmiş"): translate("Yanlış İşaretlenmiş")
                        } 
                        trigger={
                            <Button
                                circular
                                inverted
                                size={"big"}
                                color={userAnswer.toUpperCase() === questionJson.correctAnswer.toUpperCase()
                                    ? "green" : "red"}
                                icon={userAnswer.toUpperCase() === questionJson.correctAnswer.toUpperCase()
                                    ? "check" : "close"}
                            />
                        } 
                    />
                </div>
            </div>
            
        

            <div style={{...styles.option, backgroundColor: "A" === questionJson.correctAnswer 
                    ? trueColor:  
                userAnswer.toUpperCase() === "A" && userAnswer != questionJson.correctAnswer ? wrongColor : "white"
            }}>
                <Label circular color={"black"}>A</Label> 
                {questionJson.a}
            </div>
            
            <div style={{...styles.option, backgroundColor: "B" === questionJson.correctAnswer 
                    ? trueColor:
                    userAnswer.toUpperCase() === "B" && userAnswer != questionJson.correctAnswer ? wrongColor : "white"}}>
                <Label circular color={"black"}>B</Label>
                {questionJson.b}
            </div>
            
            <div style={{...styles.option, backgroundColor: "C" === questionJson.correctAnswer 
                    ? trueColor:
                    userAnswer.toUpperCase() === "C" && userAnswer != questionJson.correctAnswer ? wrongColor : "white"}}>
                <Label circular color={"black"}>C</Label>
                {questionJson.c}
            </div>
            
            <div style={{...styles.option, backgroundColor: "D" === questionJson.correctAnswer 
                    ? trueColor:
                    userAnswer.toUpperCase() === "D" && userAnswer != questionJson.correctAnswer ? wrongColor : "white"}}>
                <Label circular color={"black"}>D</Label>
                {questionJson.d}
            </div>
            
            <div style={{...styles.option, backgroundColor: "E" === questionJson.correctAnswer 
                    ? trueColor:
                    userAnswer.toUpperCase() === "E" && userAnswer != questionJson.correctAnswer ? wrongColor : "white"}}>
                <Label circular color={"black"}>E</Label>
                {questionJson.e}
            </div>
            
        </div>
    )
}

export default QuestionReportCard;