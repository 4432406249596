import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
    Table,
    Button,
    Popup,
    Input,
    Dropdown,
    Checkbox,
    Icon,
    Transition,
    Header,
    Pagination,
    Loader,
    Segment
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { DateFormat, DateFormatDatePicker } from '../helper/DateFormat';
import { renderByItemType, styleByItemType, prepareDataForTable } from '../helper/HelperFunctions';
import { NetworkRequest } from '../helper/NetworkRequest';
import { DataTableProps, IDataTable } from '../interfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';

import TranslateHelper from '../helper/TranslateHelper';
import { underDampedSpring } from "framer-motion/types/animation/utils/default-transitions";
import { DataHandler } from '../helper/DataHelper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DataTable: React.FC<DataTableProps> = ({
    dataTable,
    isAllSelected,
    selectAllHandler,
    useFactoryRequest,
    filterData,
    setAllSelected,
    exportExcelFunction,
    isLoading,
    dataTableSetter,
    isDateTimeFormat
}: DataTableProps) => {
    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const hash = require('object-hash');
    const [today] = useState(new Date().toISOString().split('T')[0]);
    const [isOpenFilterPanel, setIsOpenFilterPanel] = useState(false);
    //const [isLoading, setIsLoading] = useState(false);
    //const [dataTableModel, setDataTableModel] = useState<IDataTable>(dataTable);
    const [filterElement, setFilterElement] = useState<Array<any>>([]);
    const [focusedInput, setFocusedInput] = useState(false);
    const [inputTimeout, setInputTimeout] = useState<any>();
    const [search, setSearch] = useState<string>("");

    const renderByHtml = (data) => {

        let rt = renderByItemType(data, null, isDateTimeFormat);
     
        if (typeof rt == "object" && rt.html != undefined) {
            let item = <div className="htmlHover">
                <div className="clearText">
                    {rt.clearText}
                </div>
                <Icon name="zoom" className="zoomIcon" color="blue" style={{ width: "100%" }} />
                <div className="html">
                    <div className="htmlTextZoom">{rt.html}</div>
                </div>
            </div>
            return item;
        }

        return rt;
    }

    let styles = {
        filterSection: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "40px"
        },
        closeBtnStyle: {
            position: "absolute" as "absolute",
            right: "10px",
            top: "10px",
            padding: "12px",
            cursor: "pointer",
        },
        filterSectionLeft: {
            display: "flex",
            gap: "1em",
            alignItems: "center"
        },

        filterSectionRight: {
            display: "flex"
        },

        filterPanelContainer: {
            position: "relative" as "relative"
        },

        filterPanel: {
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            position: "absolute" as "absolute",
            left: "100%",
            top: "132%",
            zIndex: 4,
            width: "583px",
            height: "auto" as "auto",
            backgroundColor: "#ffffff"
        },

        filterElement: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px"
        },

        filterElementDate: {
            display: "flex",
            gap: "2em"
        },

        paginationContainer: {
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "40px"
        },
        blocker: {
            position: "fixed" as "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            content: ' ',
            background: "rgba(0, 0, 0, 0)"
        }
    }

    const headerRender = () => {
        return (
            <Table.Header>
                <Table.Row>
                    {dataTable.noCheckBox != true
                        &&
                        <Table.HeaderCell>
                            <Checkbox
                                className={"userListCheckboxClass"}
                                onChange={(e, data) => {
                                    setAllSelected(data);
                                }}
                                checked={isAllSelected}
                            />
                        </Table.HeaderCell>
                    }
                    {dataTable.header?.map((header, index) => (
                        typeof (header) != 'object'
                        &&
                        <Table.HeaderCell
                            key={uuidv4()}
                        >
                            {header}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );
    }

    const renderTransactions = (id, isDeleted, isActive?) => {
        var transactions: any = dataTable.transactions;

        if (dataTable.transactions != undefined && dataTable.transactions.length < 1) return <Table.Cell key={uuidv4()}
            style={{ padding: "1%" }}></Table.Cell>;

        return (
            <Table.Cell key={uuidv4()}>
                {transactions?.map((item, index) => (
                    <Link
                        key={uuidv4()}
                        onClick={item.link == null || isDeleted ? e => e.preventDefault() : undefined}
                        to={item.link + "/" + id}
                    >
                        {isDeleted == true && item.type == "undo" ?
                            <Popup
                                key={uuidv4()}
                                content={"Geri al"}
                                trigger={
                                    <Button
                                        onClick={() => {
                                            item.function && item.function(id, filterData);
                                        }}
                                        id={id}
                                        icon={"redo"}
                                    />
                                }
                            /> : item.type != "undo" && item.type != "onoff" &&

                            <Popup
                                key={uuidv4()}
                                content={item.desc}
                                trigger={
                                    <Button
                                        onClick={() => {
                                            if (item.functionRequest == undefined) {
                                                item.function && item.function(id);
                                            } else if (item.functionRequest != undefined && item.function != undefined) {
                                                item.function(id, item.functionRequest);
                                            }
                                        }}
                                        id={id}
                                        color={item.color}
                                        icon={item.icon}
                                        disabled={isDeleted && (!dataTable.isAttachingPage)}
                                    />
                                }
                            />
                        }

                        {isActive == true && item.type == "onoff" ?
                            <Popup
                                key={uuidv4()}
                                content={"Açık"}
                                trigger={
                                    <Button
                                        color="green"
                                        onClick={() => {
                                            item.function && item.function(id, filterData);
                                        }}
                                        id={id}
                                        icon={"power"}
                                    />
                                }
                            /> : isActive == false && item.type == "onoff" ?

                                <Popup
                                    key={uuidv4()}
                                    content={"Kapalı"}
                                    trigger={
                                        <Button
                                            onClick={() => {
                                                item.function && item.function(id, filterData);
                                            }}
                                            id={id}
                                            icon={"power"}
                                        />
                                    }
                                /> : <></>
                        }

                    </Link>
                ))}


            </Table.Cell>
        );
    };

    const dataRender = () => {
        return (
            <Table.Body>
                {dataTable.data?.length == 0
                    &&
                    <Table.Row>
                        <Table.Cell>
                            {translate("Hiçbir veri bulunamadı.")}
                        </Table.Cell>
                    </Table.Row>
                }
                {dataTable.data != undefined && dataTable.data?.filter(x => x.showData.length > 0).length > 0 && dataTable.data?.map((data, index) => (
                    <Table.Row key={uuidv4()}>
                        {dataTable.noCheckBox != true
                            &&
                            <Table.Cell
                                key={uuidv4()}
                            >
                                <Checkbox
                                    className={"userListCheckboxClass"}
                                    // value={data.secretData[0].isSelected}
                                    checked={data.secretData[0].isSelected}
                                    onChange={(e, eventData) => {
                                        dataTable.setRowSelected ? dataTable.setRowSelected(index, eventData, dataTable) : console.log(data);
                                    }}
                                />
                            </Table.Cell>
                        }
                        {Object.keys(data.showData[0]).map((key, i) => (
                            <Table.Cell
                                key={uuidv4()}
                                style={{
                                    ...styleByItemType(data.showData[0][key], key),
                                    overflowWrap: "break-word",
                                    maxWidth: "200px"
                                }}
                            >
                                {/*{data.showData[key]}*/}
                                {key != "rank" &&
                                    renderByHtml(data.showData[0][key])
                                }
                                {key == "rank" && dataTable.orderTransactions != undefined
                                    &&
                                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                                        <Button.Group size='large'>
                                            <Button
                                                onClick={() => dataTable.orderTransactions
                                                    ? dataTable.orderTransactions[0](data.secretData[0].id)
                                                    : console.log("dataTable.orderTransactions[0] undefined")}
                                                disabled={!(index > 0)} icon>
                                                <Icon name="chevron up" />
                                            </Button>
                                            <Button.Or text={data.showData[0][key]} />
                                            <Button
                                                onClick={() => dataTable.orderTransactions
                                                    ? dataTable.orderTransactions[1](data.secretData[0].id)
                                                    : console.log("dataTable.orderTransactions[0] undefined")}
                                                disabled={!((dataTable.data != undefined && dataTable?.data?.length - 1) != index)}
                                                icon>
                                                <Icon name="chevron down" />
                                            </Button>
                                        </Button.Group>
                                    </div>
                                }
                            </Table.Cell>
                        ))}
                        {renderTransactions(data.secretData[0].id, data.secretData[0].isDeleted, data.secretData[0].isActive)}
                    </Table.Row>
                ))
                }
            </Table.Body>
        );
    }

    const renderTable = () => {
        return (
            <div style={{ overflowX: "auto" }}>
                <Table
                    size={"small"}
                    sortable={true}
                    textAlign={'center'}
                    selectable
                    basic='very'
                >
                    {headerRender()}
                    {dataRender()}
                </Table>
            </div>
        );
    }

    const renderByFilterType = (element) => {
        if (element.type == "boolean") {
            return <div key={uuidv4()} style={styles.filterElement}>
                <span>{element.desc}</span>
                <Checkbox
                    name={element.key}
                    defaultChecked={element.defaultValue}
                    onChange={element.onChange}
                    disabled={isLoading}
                    slider
                />
            </div>
        } else if (element.type == "date") {
            return <div key={uuidv4()} style={styles.filterElement}>
                <span>{element.desc}</span>
                <div style={styles.filterElementDate}>
                    {/*<Input*/}
                    {/*    onChange={(e) => {*/}
                    {/*        var lastValue: any = e.target.value;*/}
                    {/*        lastValue = e.target.value == "" ? undefined : e.target.value;*/}
                    {/*        element.onChange[0](lastValue)*/}
                    {/*    }}*/}
                    {/*    value={element.defaultValue[0]}*/}
                    {/*    type="datetime-local"*/}
                    {/*    disabled={isLoading}*/}

                    {/*/>*/}
                    {/*<Input*/}
                    {/*    onChange={(e) => {*/}
                    {/*        var lastValue: any = e.target.value;*/}
                    {/*        lastValue = e.target.value == "" ? undefined : e.target.value;*/}
                    {/*        element.onChange[1](lastValue)*/}
                    {/*    }}*/}
                    {/*    value={element.defaultValue[1]}*/}
                    {/*    type="datetime-local"*/}
                    {/*    disabled={isLoading}*/}
                    {/*/>*/}
                    <div>
                        <DatePicker
                            isClearable
                            type="date"
                            className={"greyInput datePickerCustomClass"}
                            name="workStartDateRangeEnd"
                            id="workStartDateRangeEnd"
                            maxLength="8"
                            selected={element.defaultValue[0]}
                            value={DateFormatDatePicker(element.defaultValue[0], false) || null}
                            onChange={date => {
                                element.onChange[0](date)
                            }}
                            placeholderText="dd-mm-yyyy"
                            dateFormat="dd-MM-yyyy"
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <DatePicker
                            isClearable
                            type="date"
                            className={"greyInput datePickerCustomClass"}
                            name="workStartDateRangeEnd"
                            id="workStartDateRangeEnd"
                            maxLength="8"
                            selected={element.defaultValue[1]}
                            value={DateFormatDatePicker(element.defaultValue[1], false) || null}
                            onChange={date => {
                                element.onChange[1](date)
                            }}
                            placeholderText="dd-mm-yyyy"
                            dateFormat="dd-MM-yyyy"
                            disabled={isLoading}
                        />
                    </div>




                </div>
            </div>
        } else if (element.type == "dropdown") {
            return <div key={uuidv4()} style={styles.filterElement}>
                <span>{element.desc}</span>
                <Dropdown
                    onChange={(data: any, value) => {
                        element.onChange(value.value);
                    }}
                    value={element.defaultValue}
                    placeholder='State'
                    search
                    selection
                    options={element.options}
                />
            </div>


        }
    }

    const filterPanelRender = () => {
        let filterElements = dataTable.filter.filterOption;

        if (filterElements == undefined || filterElement == null)
            return;

        let renderedFilterElements: any = [];
        filterElements.map(element => {
            renderedFilterElements.push(renderByFilterType(element));
        });
        setFilterElement(renderedFilterElements);

    }

    const prepareData = (response) => {
        let data = response.list;
        let model = prepareDataForTable(data, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'searchField',
            'settings',
            'time',
            'description',
        ]);

        //setDataTableModel({
        //    ...dataTableModel,
        //    data: model,
        //    pagination: {
        //        count: Math.ceil(response.count / 25),
        //        setCurrentPage: useFactoryRequest?.setCurrentPage
        //    }
        //});
        useFactoryRequest?.setIsLoading(false);
    }

    const getQuizes = () => {
        if (Object.keys(filterData).length == 0) {
            return;
        }
        NetworkRequest("/Quiz/GetQuizes", filterData).then((response) => {

            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    const factory = () => {
        if (useFactoryRequest?.typeName == "GetQuizes") {
            getQuizes();
        }
    }

    useEffect(() => {
        if (useFactoryRequest != undefined) {
            factory();
        }
    }, [useFactoryRequest]);

    //useEffect(() => {
    //    if (dataTableSetter != undefined) {
    //        dataTableSetter(dataTable);
    //    }
    //}, [dataTable]);


    useEffect(() => {
        setFilterElement([]);
        filterPanelRender();

    }, [dataTable, isLoading]);

    useEffect(() => {

    }, [dataTable.data]);

    useEffect(() => {
        selectAllHandler(dataTable);
    }, [isAllSelected])
    //useEffect(() => {
    //    toast(dataTable.data.length, { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
    //}, [dataTable.data])

    useEffect(() => {
        if (dataTable.filter.search == search)
            return;

        let timeOut = setTimeout(() => {
            dataTable.filter.search = search;
            dataTable.filter.setSearch(search);
            /*toast(search, { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });*/
        }, 1000);

        setInputTimeout(timeOut);
    }, [search])

    return (
        <div>
            {isLoading && dataTable.noFilter &&
                <Loader size={"huge"} active inline='centered' />
            }
            <div style={styles.filterSection}>

                <div
                    style={{ ...styles.filterSectionLeft, visibility: dataTable.noFilter ? "hidden" : "visible" }}>
                    <Input
                        className="greyInput"
                        onClick={() => {
                            setFocusedInput(true)
                        }}
                        onMouseLeave={() => {
                            setFocusedInput(false)
                        }}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            clearTimeout(inputTimeout);
                            //dataTable.filter.search = e.target.value;
                            e.target.focus();
                        }}
                        value={search}
                        size="big"
                        icon='search'
                        placeholder={translate("Arama..")}
                        autoFocus={focusedInput ? "autofocus" : false}
                    />
                    {dataTable.searchFunc != undefined
                        &&
                        <Button
                            className="continueBlueButton"
                            size="big"
                            icon="search"
                            onClick={(e) => {
                                if (dataTable.searchFunc != undefined) {
                                    dataTable.searchFunc();
                                }
                            }}
                        >
                        </Button>
                    }

                    <div style={styles.filterPanelContainer}>
                        <Popup
                            content={translate("Filtrele")}
                            trigger={
                                <Button
                                    circular
                                    className={isOpenFilterPanel ? 'disabledGreyButton' : 'continueBlueButton'}
                                    icon='filter'
                                    size="big"
                                    onClick={() => {
                                        setIsOpenFilterPanel(!isOpenFilterPanel);
                                    }}
                                >
                                </Button>
                            }
                        />



                        <Transition visible={dataTable.filter != null && isOpenFilterPanel} animation='scale'
                            duration={500}>
                            <div style={isLoading ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                <div
                                    style={styles.filterPanel}>
                                    <Button
                                        style={styles.closeBtnStyle}
                                        onClick={() => {
                                            setIsOpenFilterPanel(!isOpenFilterPanel)
                                        }}
                                        className={"roundedButton"}
                                        color={"red"}
                                    >
                                        {translate("Kapat")}
                                    </Button>
                                    <Header as='h2'>{translate("Filtrele")}</Header>
                                    {filterElement}
                                    {isLoading &&
                                        <Loader style={{ position: "absolute", left: "45%", top: "30%" }} size={"huge"}
                                            active inline='centered' />
                                    }
                                </div>
                            </div>
                        </Transition>
                        {isOpenFilterPanel &&
                            <div style={styles.blocker} onClick={() => {
                                setIsOpenFilterPanel(false)
                            }}>
                            </div>
                        }
                    </div>


                    {dataTable.collectiveProgresses != undefined &&
                        <Dropdown
                            clearable
                            selection
                            placeholder={translate("Toplu İşlemler")}
                            options={dataTable.collectiveProgresses?.options}
                            onChange={(e, data) => {
                                dataTable.collectiveProgresses?.event(e, data, dataTable)
                            }}
                        />
                    }
                    {isLoading &&
                        <Loader size={"huge"} active inline='centered' />
                    }
                </div>

                <div style={styles.filterSectionRight}>
                    {exportExcelFunction != undefined && <Popup
                        content={translate("Excel Raporu")}
                        trigger={
                            <div style={{ display: "flex" }}>
                                <Button
                                    onClick={(e) => {
                                        exportExcelFunction(filterData)
                                    }}
                                    color={"green"}
                                    circular={true}
                                    icon='file excel'
                                    size="big"
                                />
                            </div>
                        }
                    />}
                    {!dataTable.isAttachingPage && !(dataTable.addExcelLink == "" || dataTable.addExcelLink == undefined)
                        &&
                        <Link to={dataTable.addExcelLink}>
                            <Popup
                                content={translate("Excel içeri aktar")}
                                trigger={
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            circular
                                            color="green"
                                            icon='file excel'
                                            size="big"
                                        />
                                    </div>
                                }
                            />
                        </Link>
                    }
                    {dataTable.addButons != null && dataTable.addButons.map((btn) => (
                        <Link className={"dataTableAddLink"} key={uuidv4()}
                            to={btn.link}
                        >
                            <Button size="big" color={btn.color}> {btn.text}
                            </Button>
                        </Link>
                    ))}
                    {!dataTable.isAttachingPage && dataTable.addRowLink != "" && !isLoading
                        &&
                        <Link className={"dataTableAddFunc"} to={dataTable.addRowLink}>
                            <Popup
                                content={translate("Yeni Ekle")}
                                trigger={
                                    <Button
                                        color="green"
                                        circular
                                        icon='plus'
                                        size="big"
                                    />
                                }
                            />
                        </Link>
                    }
                </div>
            </div>
            {!isLoading &&
                <p style={{ position: 'relative', top: '-2em', left: '1em' }}>
                    {dataTable != undefined && dataTable.data != undefined && dataTable.data[0] != undefined && dataTable.data[0].showData != undefined ? (dataTable.data[0].showData.length == 0 ? 0
                        : dataTable.data.length) : 0} {translate("Kayıt Bulundu")}
                </p>
            }

            {!isLoading &&
                renderTable()
            }

            {(dataTable.noFilter != true && !isLoading) &&
                <div style={styles.paginationContainer}>
                    <Pagination
                        activePage={dataTable.pagination != undefined ? dataTable.pagination?.currentPage : 1}
                        firstItem={null}
                        lastItem={null}
                        pointing
                        secondary
                        totalPages={dataTable.pagination != undefined ? dataTable.pagination?.count : 0}
                        onPageChange={
                            (event, data) =>
                                dataTable.pagination?.setCurrentPage != undefined && dataTable.pagination?.setCurrentPage(data.activePage == undefined ? 1 :
                                    parseInt(data.activePage.toString())
                                )
                        }
                    />
                </div>}
        </div>
    );
}

export default DataTable;