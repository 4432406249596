import * as React from 'react';
import { useState } from 'react';
import MenuItem, { Item } from '../../components/MenuItem';
import { Link } from 'react-router-dom';
import TranslateHelper from '../../helper/TranslateHelper';

const SuperAdminDashboard = () => {

    var { translate } = TranslateHelper();
    //console.log("SuperAdminDashboard: ", 1);
    let styles = {
        dashboardStyle: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
        },

        menuStyle: {
            width: "50%",
            padding: "0 20px",
            flexWrap: "wrap" as "wrap",
            display: "flex",
            gap: "1em"
        },

        graphStyle: {
            width: "50%",
            padding: "20px"
        },

        itemBoxStyle: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexWrap: "wrap" as "wrap",
            gap: "2em",
            marginTop:"80px"

        }
    };

    let menuList: Array<Item> = [
        {
            IconName: "group",
            Text: translate("Aboneler"),
            Url: "/tenants",
            IsButton: true,
            ButtonLink: "/tenants/2"
        },
        {
            IconName: "language",
            Text: translate("Dil Ayarları"),
            Url: "/languageSettings",
            IsButton: true,
            ButtonLink: "/addTranslationKeyword"
        }
    ];

    return (
        <div >
            <div style={styles.menuStyle}>
                <div style={styles.itemBoxStyle}>
                    {menuList.map(menu => (
                        <Link
                            to={menu.Url}
                            key={menu.Text}
                        >
                            <MenuItem
                                IconName={menu.IconName}
                                Text={menu.Text}
                                IsButton={menu.IsButton}
                                ButtonLink={menu.ButtonLink}
                                Url={menu.Url}
                            />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SuperAdminDashboard;