import * as React from 'react';
import { useState, useEffect, useDeferredValue, useMemo } from 'react';
import {
    Transition,
    Loader,
    Table,
    Button,
    Icon,
    Pagination,
    Flag,
    Input,
    Popup,
    Header,
    Checkbox
} from 'semantic-ui-react';

import { DateFormat } from '../../../../helper/DateFormat';
import { NetworkRequest } from '../../../../helper/NetworkRequest';
import { digitallicaConfirm, prepareDataForTable } from '../../../../helper/HelperFunctions';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import TranslateHelper from "../../../../helper/TranslateHelper";


const TenantList = (props) => {

    var { translate } = TranslateHelper();
    // styles 
    let styles = {
        tableSection: {
            marginTop: "20px",
        },
        paginationBox: {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
        activeColor: {
            color: 'green',
            position: 'relative',
            left: '9%'
        },
        passiveColor: {
            color: 'red',
            position: 'relative',
            left: '9%'
        },
        editIcon: {
            position: 'relative',
            color: 'blue'
        },
        removeIcon: {
            position: 'relative',
            left: '1%',
            color: 'red'
        },
        filterPanelContainer: {
            position: "relative" as "relative",
            marginLeft: "1rem"
        },

        filterPanel: {
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            position: "absolute" as "absolute",
            left: "100%",
            top: "132%",
            zIndex: 4,
            width: "583px",
            height: "auto" as "auto",
            backgroundColor: "#ffffff"
        },
        blocker: {
            position: "fixed" as "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            content: ' ',
            background: "rgba(0, 0, 0, 0)"
        },
        closeBtnStyle: {
            position: "absolute" as "absolute",
            right: "10px",
            top: "10px",
            padding: "12px",
            cursor: "pointer",
        }
    }

    const [filterText, setFilterText] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [tenants, setTenants] = useState<any>([]);
    const [isOpenFilterPanel, setIsOpenFilterPanel] = useState(false);

    const tableHeader = [translate("Icon"), translate("Ad"), translate("Görünen Ad"), translate("Üst Şirket"), translate("Kullanıcı Sayısı"), translate("Oluşturma Tarihi"), translate("Varsayılan Dil"), translate("Aktif Mi?"), translate("İşlemler")];
    const [paginationHtml, setPaginationHtml] = useState<any>();
    const [filter, setFilter] = useState({
        filterText: filterText,
        currentPage: 1,
        isDeleted: false
    });


    //get table data from api
    const getTenants = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/api/Tenants/GetList", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let count = response.data.detail.count;
                setTenants(response.data.detail.list);

                // tenantList = response.data.detail.list;
                setPaginationHtml(<Pagination
                    defaultActivePage={1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={Math.ceil(count / 25)}
                    onPageChange={
                        (event, data) => {
                            setCurrentPage(Number(data.activePage));
                        }
                    }
                />);
                setIsLoading(false);
            }
        });
    }

    function filterTextInputChange(e) {
        setFilterText(e.target.value);
    }

    const updateTenantStatus = (tenant, isDeleted, successMessage) => {
        digitallicaConfirm(successMessage, () => {
            tenant.isDeleted = isDeleted;
            tenant.isActive = !isDeleted;
            props.Update(tenant).then((response) => {
                if (response.data.resultCode === 0) {
                    let filterData = {
                        filterText: filterText,
                        currentPage: currentPage,
                        isDeleted: filter.isDeleted,
                        isActive: !filter.isDeleted
                    }
                    getTenants(filterData);
                }
            });
        },
            () => {
                toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            });
    }

    const removeTenant = (tenant) => {
        updateTenantStatus(tenant, true, translate("Silmek istediğinize emin misiniz?"));
    }

    const recoverTenant = (tenant) => {
        updateTenantStatus(tenant, false, translate("Geri getirmek istediğinize emin misiniz?"));
    }
    const editTenantIcon = (tenant) => {
        // //console.log('edit clicked'); 
        props.EditTenant(tenant);
    }

    const changeActive = (tenant) => {
        var msg = tenant.isActive ? "pasif" : "aktif";
        digitallicaConfirm("Aboneyi " + msg + " yapmak istediğinize emin misiniz?", () => {
            tenant.isActive = !tenant.isActive;
            props.Update(tenant).then((response) => {
                if (response.data.resultCode == 0) {
                    let filterData = {
                        filterText: filterText,
                        currentPage: currentPage
                    }
                    getTenants(filterData);
                    toast("İşlem başarılı, " + tenant.displayName + " artık " + msg + ".", {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            })
        },
            () => {
                toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        )
    }

    //filter's every change
    useEffect(() => {

        const timer = setTimeout(() => {
            let filterData = {
                filterText: filterText,
                currentPage: currentPage,
                isDeleted: filter.isDeleted,
                isActive: !filter.isDeleted
            }

            getTenants(filterData);
        }, 500);

        return () => clearTimeout(timer);

    }, [filterText, currentPage, filter.isDeleted]);
    //filter's every change


    return (
        <div>

            <div className={"tenantListSearchSection"}>
                <div>
                    <Input onChange={filterTextInputChange} className={"greyInput50"} size={"big"} loading={isLoading}
                        icon='search'
                        placeholder='Search...' />


                </div>
                <div style={styles.filterPanelContainer}>
                    <Popup
                        content={translate("Filtrele")}
                        trigger={
                            <Button
                                circular
                                className={isOpenFilterPanel ? 'disabledGreyButton' : 'continueBlueButton'}
                                icon='filter'
                                size="big"
                                onClick={() => {
                                    setIsOpenFilterPanel(!isOpenFilterPanel);
                                }}
                            >
                            </Button>
                        }
                    />



                    <Transition visible={isOpenFilterPanel} animation='scale'
                        duration={500}>
                        <div style={isLoading ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                            <div
                                style={styles.filterPanel}>
                                <Button
                                    style={styles.closeBtnStyle}
                                    onClick={() => {
                                        setIsOpenFilterPanel(!isOpenFilterPanel)
                                    }}
                                    className={"roundedButton"}
                                    color={"red"}
                                >
                                    {translate("Kapat")}
                                </Button>
                                <Header as='h2'>{translate("Filtrele")}</Header>
                                <Checkbox
                                    defaultChecked={filter.isDeleted}
                                    disabled={isLoading}
                                    slider
                                    onChange={(e, data) => {
                                        setFilter({ ...filter, isDeleted: data.checked != undefined ? data.checked : false })
                                    }}
                                    label="Silinenleri Getir"


                                />
                                {isLoading &&
                                    <Loader style={{ position: "absolute", left: "45%", top: "30%" }} size={"huge"}
                                        active inline='centered' />
                                }
                            </div>
                        </div>
                    </Transition>
                    {isOpenFilterPanel &&
                        <div style={styles.blocker} onClick={() => {
                            setIsOpenFilterPanel(false)
                        }}>
                        </div>
                    }
                </div>
            </div>

            <div className={isLoading ? "opacityAnimationIsActive" : ""} style={styles.tableSection}>
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            {tableHeader.map(header => (
                                <Table.HeaderCell key={uuidv4()}>{header}</Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tenants?.length > 0 && tenants.map((tenant) => (
                            <Table.Row key={uuidv4()}>
                                <Table.Cell>
                                    <img id="favIconSuperAdmin" src={tenant.favIcon} />
                                </Table.Cell>
                                <Table.Cell>{tenant.tenantName}</Table.Cell>
                                <Table.Cell>{tenant.displayName}</Table.Cell>
                                <Table.Cell>{tenant.parentDisplayName != null ? tenant.parentDisplayName : "-"}</Table.Cell>
                                <Table.Cell>{tenant.userCount != null ? tenant.userCount : "-"}</Table.Cell>

                                <Table.Cell>{DateFormat(tenant.createDateTime) || "-"}</Table.Cell>
                                <Table.Cell><Flag name={tenant.defaultLanguage} /> {tenant.defaultLanguage}
                                </Table.Cell>
                                <Table.Cell>
                                    <Icon name="circle"
                                        style={tenant.isActive ? styles.activeColor : styles.passiveColor}
                                        onClick={() => {
                                            changeActive(tenant);
                                        }} />
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        color='blue'
                                        icon='edit'
                                        onClick={() => {
                                            editTenantIcon(tenant);
                                        }}
                                    />
                                    {tenant.isDeleted ?
                                        <Button
                                            color='green'
                                            icon='redo'
                                            onClick={() => {
                                                recoverTenant(tenant);
                                            }}
                                        />
                                        :
                                        <Button
                                            color='red'
                                            icon='trash'
                                            onClick={() => {
                                                removeTenant(tenant);
                                            }}
                                        />
                                    }
                                    
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>


            {tenants.length > 0
                &&
                <div style={styles.paginationBox}>
                    {paginationHtml}
                </div>
            }
        </div>
    );
}

export default TenantList;