import React, { FC, useEffect, useState, createContext } from 'react';
import { DataHandler } from './helper/DataHelper';
import { NetworkRequest } from "./helper/NetworkRequest";

export const translationListContext = createContext<any>([]);
export const authUserListContext = createContext<any>([]);
export const selectedLanguageContext = createContext<any>({});
export const apiUrlContext = createContext<any>({});


type StoreType = {
    children: React.ReactNode; // 👈️ type children
};

const Store = ({ children }: any) => {
    const [translationList, setTranslationList] = useState([]);
    const [authUserList, setAuthUserList] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [apiUrl, setApiUrl] = useState("");


    const getGroupedTranslationData = () => {
        if (translationList.length > 0) {
            return;
        }

        NetworkRequest("/api/Language/GetGroupedTranslationList", null).then((response) => {
            if (typeof response != "string" && response.data.resultCode == 0) {
                let data = response.data.detail;
                setTranslationList(data);
            }
        });
    };

    useEffect(() => {
        getGroupedTranslationData();
    }, []);

    return (
        <translationListContext.Provider value={[translationList, setTranslationList]}>
            <authUserListContext.Provider value={[authUserList, setAuthUserList]}>
                <selectedLanguageContext.Provider value={[selectedLanguage, setSelectedLanguage]}>
                    <apiUrlContext.Provider value={[apiUrl, setApiUrl]}>
                        {children}
                    </apiUrlContext.Provider>
                </selectedLanguageContext.Provider>
            </authUserListContext.Provider>
        </translationListContext.Provider>
    );
}

export default Store;