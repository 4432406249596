import * as React from 'react';
import {useState, useEffect} from 'react';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import {IDataTable} from '../../../interfaces/interfaces';
import {datableFilterChange, digitallicaConfirm, prepareDataForTable} from '../../../helper/HelperFunctions';
import {Loader, Transition} from 'semantic-ui-react';
import {toast} from 'react-toastify';
import DataTable from '../../../components/DataTable';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const CategoryList = () => {
    var {translate} = TranslateHelper();
    const [tenantId, setTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);

    const getList = (filterData) => {
        setIsLoading(true);
        NetworkRequest("api/Categories/GetList", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
                setIsLoading(false);
            }
        });
    }

    const deleteCategory = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz ?"),
            () => {
                NetworkRequest("api/Categories/Delete", {id: id}).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: tenantId
                        }
                        getList(filterData);
                        toast(translate("Başarılı."), {type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000});
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeStartDate = (date) => {
        setStartingDate(date);
    }

    const onChangeDueDate = (date) => {
        setDueDate(date);
    }

    const [dataTableModel, setDataTableModel] = useState<IDataTable>({

        header: [
            translate("Adı"),
            translate("Üst Kategori"),
            translate("Kodu"),
            translate("Derinlik"),
            translate("Yaratma Tarihi"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {
                        id: "",
                        isDeleted: false,
                        isSelected: false
                    }
                ]
            }
        ],
        transactions: [
            {
                icon: "edit",
                desc: translate("Düzenle"),
                color: "blue",
                link: "/editCategory",
                type: "edit",

            },
            {
                icon: "trash",
                desc: translate("Sil"),
                color: "red",
                link: null,
                type: "delete",
                function: deleteCategory
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeStartDate, onChangeDueDate]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        setRowSelected: setRowSelected,
        addRowLink: "/addCategory"
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response.list;
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);

            return;
        }
        ////console.log(data);
        //hidden column
        let model = prepareDataForTable(data, [
            'id',
            'tenantId',
            'updateDateTime',
            'deleteDateTime',
            'isDeleted',
            'seniorCategoryId'
        ]);
        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeStartDate, onChangeDueDate]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(data.length / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);
        setIsLoading(false);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: tenantId
        }
        getList(filterData);
    }, [tenantId, filterText])
    useEffect(() => {
        if (dueDate == null || startingDate == null)
            return;

        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: tenantId
        }
        
        getList(filterData);
    }, [dueDate, startingDate])
    
    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                                   selectAllHandler={selectAllHandler} isLoading={isLoading}/>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default CategoryList;