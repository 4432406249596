import * as React from 'react';
import './SiteOff.css';

const SiteOff = (props) => {
    return (
        <section className="page_404">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                            <div className="four_zero_four_bg">
                            </div>
                            <div className="contant_box_404">
                                <p className="textStyle">Site erişime kapatıldı. Lütfen yöneticinizle iletişime geçin.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SiteOff;