import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Checkbox, Dropdown, Grid, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { checkFieldsIsFilled, renderByItemType } from '../../../helper/HelperFunctions';
import { IFirstLoginPopup } from '../../../interfaces/interfaces';

import { NetworkRequest } from '../../../helper/NetworkRequest';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import parse from "html-react-parser";
import 'react-quill/dist/quill.snow.css';
import EditorComponent from '../../../components/EditorComponent';
import { v4 as uuidv4 } from 'uuid';
import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from "../../../helper/TranslateHelper";
import QuillEditor from '../../../components/Quill/QuillEditor';

const AddTOUCPopup = (props) => {
    var { translate } = TranslateHelper();
    const [showMailPreview, setShowMailPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormOk, setIsFormOk] = useState(false);
    const [value, setValue] = useState('');
    const [editorContent, setEditorContent] = useState("");
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [sendAllUsers, setSendAllUsers] = useState(false);
    const [toucPopup, setTOUCPopup] = useState({
        notificationId: null,
        isActive: false,
        notificationTypeId: "",
        body: "",
        subject: "",
        tenantId: tenantId
    });

    let styles = {
        inputStyle: {
            width: "100%"
        },
        btnDark: style({
            width: "228px",
            right: "0px",
            top: "700px",
            borderRadius: "8px",
            padding: "10px 0px !important"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
    };

    const getFirstLoginPopup = () => {
        NetworkRequest('/api/Notifications/GetTOUCPopup', { id: tenantId }).then((response) => {
            ////console.log("respons: ", response);
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                setTOUCPopup({
                    notificationId: result.notificationId,
                    body: result.body,
                    isActive: result.isActive,
                    notificationTypeId: result.notificationTypeId,
                    subject: result.subject,
                    tenantId: result.tenantId
                });
                setEditorContent(result.body);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        });
    }

    const addFirstLoginPopup = () => {
        let popupData = {
            body: editorContent,
            subject: toucPopup.subject,
            notificationId: toucPopup.notificationId,
            tenantId: tenantId
        }
        NetworkRequest('/api/Notifications/AddTOUCPopup', popupData, undefined, undefined, true).then((response) => {
            if (response.data.resultCode == 0) {
                toast("İşlem başarılı", { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                settingSaveToBeSendUsers();

            } else {
                toast("Hata, lütfen daha sonra tekrar deneyin", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
        }, (error) => {
            ////console.log(error)
        })
    }

    const settingSaveToBeSendUsers = () => {
        let model = {
            toucSendAllUsers: sendAllUsers
        };
      
        NetworkRequest('/api/Settings/AddOrUpdateSetting', {
            tenantId: tenantId,
            key: 'toucSendAllUsers',
            value: JSON.stringify(sendAllUsers)
        }).then((response) => {
            if (response.data.resultCode == 0) {
                toast(translate("Başarılı"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
        });
    }

    const getSetting = () => {
        let apiData = {
            tenantId: tenantId,
            key: 'toucSendAllUsers',
        };

        NetworkRequest('/api/Settings/GetSetting', apiData).then((response) => {

            if (response.data.resultCode == 0 && response.data.detail) {
                setSendAllUsers(response.data.detail.settingValue == "true");
            }
        });
    }

    const handleChangeMail = (e) => {
        setTOUCPopup({
            ...toucPopup,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                editorContent,
                toucPopup.subject
            )
        );
    }, [toucPopup, editorContent]);

    useEffect(() => {
        getFirstLoginPopup();
        getSetting();
    }, []);

    useEffect(() => {
        //console.log("editorContent: ", editorContent);
    }, [editorContent]);

    return (
        <Grid columns={1}>
            <Grid.Row>
                <Grid.Column>
                    <Input
                        className={"greyInput"}
                        autoFocus
                        value={toucPopup.subject}
                        name="subject"
                        required="required"
                        style={styles.inputStyle}
                        size="big"
                        placeholder={translate('Başlık')}
                        onChange={handleChangeMail}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    {!isLoading
                        &&

                        <QuillEditor
                            value={editorContent}
                            setValue={setEditorContent}
                        />
                    }
                    <Modal
                        centered={false}
                        open={showMailPreview}
                        onClose={() => setShowMailPreview(false)}
                        onOpen={() => setShowMailPreview(true)}
                    >
                        <Modal.Content>
                            <Modal.Description>
                                <div >
                                    <Header>
                                        {translate("Başlık")}: {toucPopup.subject}
                                    </Header>
                                    {parse(editorContent)}
                                </div>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setShowMailPreview(false)}>{translate("Kapat")}</Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Checkbox toggle
                        label={translate("Tüm Kullanıcılara Gönder")}

                        onChange={(e, data) => {
                            setSendAllUsers(data.checked != undefined ? data.checked : false)
                        }}
                        checked={sendAllUsers}
                    />
                
                  
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={{ textAlign: "right" }}>
                    <Button color='blue' className={"roundedButton"} disabled={!isFormOk} onClick={() => { setShowMailPreview(true) }} >{translate("Önizleme")}</Button>
                    <Button className={"roundedButton"} color='green' disabled={!isFormOk} onClick={() => { addFirstLoginPopup() }}>
                        {toucPopup.notificationId != null ? translate("Güncelle") : translate("Ekle")}
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default AddTOUCPopup;