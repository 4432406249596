import React, { useState } from 'react';
import {Button, Header, Input, TextArea} from "semantic-ui-react";
import { toast } from "react-toastify";
import { NetworkRequest} from "../../../helper/NetworkRequest";

import { digitallicaConfirm } from '../../../helper/HelperFunctions';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const AddDictionary = (props) => {
    var {translate} = TranslateHelper();
    const [dictionaryName, setDictionaryName] = useState("");
    const [dictionaryDesc, setDictionaryDesc] = useState("");
    
    const saveDictionary = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {

        if(dictionaryName.trim().length < 1) {
            toast(translate("Sözlük adı zorunlu alandır!"), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 10000 });
        } else {
            let dicData = {
                Name: dictionaryName,
                Description: dictionaryDesc,
                TenantId: DataHandler.getUserDataWithKey("tenantId")
            };
            NetworkRequest("/api/Dictionary/Insert", dicData).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    toast(translate("Sözlük başarılı bir şekilde eklendi!"), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 10000 });

                    setTimeout(() => {
                        window.location.href = "/dictionary"
                    }, 1000);

                }
                else {
                    toast(translate("Sözlük eklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 10000 });
                }
            }).catch(error => {
                toast(translate("Sözlük eklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 10000 });
            });
        }
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }
    
    return (
        <div style={{ display: "flex", gap: "1em", flexDirection: "column", width: "50%", marginTop:"3rem" }}>
            <Header as='h2'>{translate("Sözlük Ekle")}</Header>
            <Input 
                onChange={(e)=> {setDictionaryName(e.target.value)}} 
                value={dictionaryName}
                placeholder={translate("Sözlük Adı (*)")}
            />
            <TextArea
                onChange={(e)=> {setDictionaryDesc(e.target.value)}}
                value={dictionaryDesc}
                placeholder={translate("Sözlük Açıklaması")}
            />
            <Button
                onClick={saveDictionary}
                style={{width:"100px"}} 
                secondary
            >{translate("Kaydet")}</Button>
        </div>
    );
};

export default AddDictionary;