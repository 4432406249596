
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { Button, Checkbox, Dropdown, Header, Icon, Input, Modal, Tab, TextArea, Grid, Table, Flag, Message, Progress } from 'semantic-ui-react';
import parse from "html-react-parser";
import { NetworkRequest } from '../helper/NetworkRequest';
import { isVideoFile,checkFieldsIsFilled, digitallicaConfirm, downloadFileWithEx, getRandomIntInclusive, giveMePreview, giveMePreviewWithBlob, isNullOrEmpty, giveMeIconForDocType, extractIframe, extractAllElements } from '../helper/HelperFunctions';
import QuizAttachToContent from './QuizAttachToContent';
import SurveyAttachToContent from './SurveyAttachToContent';
import ActivityAttachToContent from './ActivityAttachToContent';
import { IContent, IVideo, IContentTask, ISettings, IDropdown, IRole, IAccessibilityRule, ILanguageDropdown } from '../interfaces/interfaces';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import FileInputComp from "../pages/AdminDashboard/components/FileInputComp";
import TranslateHelper from '../helper/TranslateHelper';
import { DataHandler } from '../helper/DataHelper';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import VideoGallery from './VideoGallery';
import QuillEditor from './Quill/QuillEditor';

enum UserOptions {
    "Buddy" = 1,
    "İnsan Kaynakları" = 2,
    "User" = 4,
    "Yönetici" = 8
}

enum MailTypeOptions {
    "Sistem" = 1,
    "Tanımlama" = 2,
    "Hatırlatma" = 4
}

interface IItem {
    name: string;
    type: string;
}

const ContentAdd = props => {

    var { translate } = TranslateHelper();
    const _ = require('lodash');

    const [page, setPage] = useState(1);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [qrValue, setQrValue] = useState("");
    const [progressGallery, setProgressGallery] = useState(0);
    const [progressText, setProgressText] = useState("Video Yükleniyor");
    const [isUploading, setIsUploading] = useState(false);
    const [subtitle, setSubtitle] = useState<any>();
    const [subtitleList, setSubtitleList] = useState<any>([]);
    const [videoList, setVideoList] = useState<any>([]);
    const [showLoadHtml, setShowLoadHtml] = useState(false);
    const [loadHtmlCode, setLoadHtmlCode] = useState("");
    const [showVideoGalleryPreview, setShowVideoGalleryPreview] = useState(false);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
    const [selectedDefaultLanguageObj, setSelectedDefaultLanguageObj] = useState<any>("");
    const [contentImage, setContentImage] = useState("");
    const [videoFile, setVideoFile] = useState(""); //COMMENT ???
    const [videoUrl, setVideoUrl] = useState("");
    const [subtitleFile, setSubtitleFile] = useState(""); //COMMENT ???
    const [videoGalleryFile, setVideoGalleryFile] = useState(""); //COMMENT ???
    const [subtitleUrl, setSubtitleUrl] = useState<any>("");
    const [videoGalleryUrl, setVideoGalleryUrl] = useState<any>("");
    const [taskDocFile, setTaskDocFile] = useState("");
    const [taskDocUrl, setTaskDocUrl] = useState<any>("");
    const [scormFile, setScormFile] = useState("");
    const [scormUrl, setScormUrl] = useState<any>("");
    const [openpenEmbedHTML, setOpenEmbedHTML] = useState<any>("");
    const [htmlEmbedCode, setHtmlEmbedCode] = useState<any>("");
    const [selectedTo, setSelectedTo] = useState("");
    const [selectedCcs, setSelectedCcs] = useState<Array<string>>([]);
    const [editorContent, setEditorContent] = useState("");
    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [taskPaneActiveIndex, setTaskPaneActiveIndex] = useState(0);
    const [showAddVideoGallery, setShowAddVideoGallery] = useState(false);
    const [showAddSubtitle, setShowAddSubtitle] = useState(false);
    const [isRateEnabled, setIsRateEnabled] = useState(false);
    const [showMailPreview, setShowMailPreview] = useState(false);
    const [showHtmlPreview, setShowHtmlPreview] = useState(false);
    const [showEmbedVideoPreview, setShowEmbedVideoPreview] = useState(false);
    const [isFormOk, setIsFormOk] = useState(false);
    const [isTaskActivityAdded, setIsTaskActivityAdded] = useState(false);
    const [isSurveyAdded, setIsSurveyAdded] = useState(false);
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [photoThumbnailEx, setPhotoThumbnailEx] = useState(false);
    const [showFormHtmlIframe, setShowFormHtmlIframe] = useState(false);
    const [iframeID, setIframeID] = useState('');
    const [inputID, setInputID] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');


    const [startAccessRule, setStartAccessRule] = useState<IAccessibilityRule>({
        accessibilityRuleId: undefined,
        moduleSectionId: undefined,
        sectionContentId: undefined,
        accessibilityEventId: undefined,
        value: undefined,
        valueType: undefined,
        displayName: undefined,
        isStart: true
    });
    const [finishAccessRule, setFinishAccessRule] = useState<IAccessibilityRule>({
        accessibilityRuleId: undefined,
        moduleSectionId: undefined,
        sectionContentId: undefined,
        accessibilityEventId: undefined,
        value: undefined,
        valueType: undefined,
        displayName: undefined,
        isStart: false
    });
    const [content, setContent] = useState<IContent>({
        id: props.id == "" ? undefined : props.id,
        moduleSectionId: props.sectionId,
        statusId: props.startStatusId,
        contentType: 1,
        title: "",
        description: "",
        coverPhoto: "",
        rank: null,
        responsibleRoleId: "",
        isDeleted: false,
        isActive: true,
        createdDate: null,
        deletedDate: null
    });
    const [contentAccessModel, setContentAccessModel] = useState({})
    const [video, setVideo] = useState<IVideo>({
        id: "",
        sectionContentId: "",
        title: "",
        videoType: undefined,
        uri: "",
        embedCode: "",
        stopWhenTabChanges: false,
        autoPlay: false,
        tenantId: "",
        time: ""
    });
    const [isActiveSubtitles, setIsActiveSubtitles] = useState(false);
    const [task, setTask] = useState<IContentTask>({
        id: "",
        sectionContentId: "",
        title: "",
        type: 1,
        taskJson: "",
        description: "",
        fileUri: "",
        tenantId: "",
        time: "",
        showQr: false,

    });
    const [taskSettings, setTaskSettings] = useState({ showComplateButton: true, isQr: false, whoCanSeeUploadeds: [], fileName: null });
    const [quiz, setQuiz] = useState({ id: "", contentId: "", quizId: "", tenantId: "" });
    const [survey, setSurvey] = useState({ id: "", contentId: "", surveyId: "", tenantId: "" });
    const [activity, setActivity] = useState({ id: "", contentId: "", activityId: "" });
    const [html, setHtml] = useState({
        id: "",
        embedCode: "",
        embedValue:"",
        sectionContentId: "",
        showComplateButton: true,
        assignTime: false,
        time: "",
        tenantId
    });
    const [settings, setSettings] = useState<ISettings>({
        id: "",
        startDate: undefined,
        finishDate: undefined,
        delayDayOfStartDate: 0,
        delayDayOfFinishDate: 0
    });
    const [dropdownStartAccessibilityEvents, setDropdownStartAccessibilityEvents] = useState<Array<IDropdown>>(props.dropdownStartAccessibilityEvents);
    const [dropdownFinishAccessibilityEvents, setDropdownFinishAccessibilityEvents] = useState<Array<IDropdown>>(props.dropdownFinishAccessibilityEvents);
    const [accessibilityEvents, setAccessibilityEvents] = useState<Array<IDropdown>>(props.accessibilityEvents);
    const [videoTypes] = useState<Array<IDropdown>>([{
        key: '1',
        value: 1,
        text: translate('Link Ekle')
    },
    {
        key: '2',
        value: 2,
        text: translate('Dosya Yükle')
    },
    {
        key: '4',
        value: 4,
        text: translate('Embed Code Ekle')
    },
    {
        key: '5',
        value: 5,
        text: translate('Video Galeri')
    }
    ]);
    const [whoCanUploadeds, setWhoCanUploadeds] = useState<Array<IDropdown>>([{
        key: '1',
        value: 1,
        text: translate('Herkes')
    },
    {
        key: '2',
        value: 2,
        text: translate('Admin')
    },
    {
        key: '4',
        value: 4,
        text: translate('HR')
    },
    {
        key: '5',
        value: 5,
        text: translate('Buddy')
    },
    {
        key: '6',
        value: 6,
        text: translate('Manager')
    },
    ]);

    let styles = {
        moduleSection: {
            display: 'flex',
            width: isMobile ? "100%" : "20%",
            padding: "20px",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column" as "column",
            gap: isMobile ? "2em" : "unset"
        },
        inputStyle: {
            width: "100%"
        },
        button: {
            boxShadow: '-2px 10px 11px 1px rgba(0,0,0,0.5)',
            borderBottom: "2.5px solid black"
        },
        fileUploadContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1rem",


        },
        fileUploadLeftSide: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: "0.5rem",
            width: "37%",
            padding: "12px"

        },
        fileUploadRightSide: { width: "100%" },
        htmlBody: {
            minWidth: "800px",
            width: "100%"
        },
        subtitles: {
            width: "100%"
        },
        addSubtitleForm: {
            display: "flex",
            gap: "2rem",
            alignItems: "center"
        },
        language: {},
        dropdownWithIcon: {
            width: "130%",
            // height: "50px",
            display: "flex",
            alignItems: "center"
        },
    }

    const downloadQRCode = () => {
        // Generate download with use canvas and stream
        const canvas: any = document.getElementById("qr-gen");
        const pngUrl = canvas?.toDataURL("image/png").replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr${Math.floor(100000 + getRandomIntInclusive() * 900000).toString().slice(0, 2)}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        ////console.log("qrValue: ", qrValue);
    };


    const searchLanguageHandler = (e, list, listSetter) => {
        var text = e == null ? "" : e.target.value;
        var listClone = _.cloneDeep(list);
        for (var i = 0; i < listClone.length; i++) {
            listClone[i] = { ...listClone[i], hidden: listClone[i].text.toLowerCase().indexOf(text.toLowerCase()) == -1 }
        }
        listSetter(listClone);
    }

    const addSubtitle = () => {
        var vId = uuidv4()
        setIsUploading(true);

        toast(translate("Yükleniyor..."), { type: 'info', theme: 'dark', position: 'top-center', isLoading: true });

        var formData = new FormData();
        let subtitle: any = {
            tenantId: tenantId,
            appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',
        }
        formData.append('Document', JSON.stringify(subtitle));
        formData.append('Data', subtitleUrl);

        NetworkRequest("/api/Subtitle/Upload", formData, true, null, undefined).then((response) => {
            toast.dismiss();
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                setProgressText("Altyazı Başarıyla Yüklendi.");
                var _clone = _.clone(subtitleList);
                //debugger;
                _clone.push({
                    subtitleId: vId,
                    file: subtitleFile,
                    subtitleUrl: result.url,
                    flag: selectedDefaultLanguageObj.flag,
                    name: selectedDefaultLanguageObj.text,
                    languageId: selectedDefaultLanguageObj.value,
                    createdDate: moment().format("DD/MM/YYYY HH:mm"),
                    isSaved: true,
                    fileName: subtitleUrl?.name,
                    isDeleted: false,
                    mimeType: result.mimeType
                });

                setSubtitleList(_clone);
                setShowAddSubtitle(false);
                setSelectedDefaultLanguage("")
            }
        });

    }


    const addVideoToGallery = () => {

        if (!isVideoFile(videoGalleryUrl)) {
            toast(translate("Lütfen video formatında bir dosya seçiniz."), { type: 'error', theme: 'dark', position: 'top-center', isLoading: false, autoClose: 5000 });
            return;
        }

        var vId = uuidv4()
        setIsUploading(true);


        setShowAddVideoGallery(false);


        toast(translate("Yükleniyor..."), { type: 'info', theme: 'dark', position: 'top-center', isLoading: true });
        //let appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';


        var formData = new FormData();
        let videoData: any = {
            tenantId: tenantId,
            appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',
        }
        formData.append('ContentVideo', JSON.stringify(videoData));
        formData.append('Data', videoGalleryUrl);



        NetworkRequest("/api/Video/UploadForGallery", formData, true, null, undefined, setProgressGallery).then((response) => {
            toast.dismiss();

            if (response.data.resultCode == 0) {
                let result = response.data;

                setProgressText("Video Başarıyla Yüklendi.");
                var _clone = _.clone(videoList);
                _clone.push({
                    fileUrl: result.detail,
                    createdDate: moment().format("DD/MM/YYYY HH:mm"),
                    isSaved: true,
                    fileName: videoGalleryUrl?.name,
                    isActive: true,
                    id: vId
                });
                setVideoList(_clone);
                setShowAddVideoGallery(false);
                setTimeout(() => {
                    setIsUploading(false);
                    setProgressGallery(0);
                }, 2000)
            } else {
                setIsUploading(false);
                setIsUploading(false);
                setProgressGallery(0);
                toast(translate("Lütfen video formatında bir dosya seçiniz."), { type: 'error', theme: 'dark', position: 'top-center', isLoading: false, autoClose:5000 });
                return;
            }
            

        });

    }

    const getActiveLanguages = () => {
        NetworkRequest("/api/Language/GetActiveLanguages").then((response) => {
            if (response.data.resultCode == 0) {
                const activeLanguages = response.data.detail;

                activeLanguages.map((l) => {
                    let languageForDropdown: ILanguageDropdown = {
                        flag: l.flag,
                        key: l.languageId,
                        text: l.text,
                        value: l.languageId
                    }
                    setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                });
            }
        });
    }


    const videoIsActiveHandler = (data, video) => {
        var _clone = _.clone(videoList);
        for (var i = 0; i < videoList.length; i++) {
            if (videoList[i].id == video.id)
                videoList[i].isActive = data.checked;
        }
        setVideoList(_clone);
    }

    const subtitleIsDeletedHandler = (data, subtitle) => {
        var _clone = _.clone(subtitleList);
        for (var i = 0; i < _clone.length; i++) {
            if (_clone[i].subtitleId == subtitle.subtitleId)
                _clone[i].isDeleted = !data.checked;
        }
        setVideoList(_clone);
    }
    const handleChanges = (event: any, data: any) => {
        setSelectedDefaultLanguage(data.value);
        var language = languageListDropdown.find(x => x.key == data.value);

        setSelectedDefaultLanguageObj(language);
    }


    const whoCanUploadedsHandler = (e, data) => {
        var taskSettingsClone = _.clone(taskSettings);
        if (data.checked) {
            if (data.value == "All")
                taskSettingsClone.whoCanSeeUploadeds = ['Admin', 'HR', 'Manager', "Buddy", "ItManager", "AdminManager", 'All']
            else
                taskSettingsClone.whoCanSeeUploadeds.push(data.value);
        } else {
            if (data.value == "All")
                taskSettingsClone.whoCanSeeUploadeds = [];
            else
                taskSettingsClone.whoCanSeeUploadeds = taskSettingsClone.whoCanSeeUploadeds.filter(x => x != data.value);
        }

        setTaskSettings(taskSettingsClone);
        //console.log(taskSettingsClone);
    }
    useEffect(() => {
        //console.log(html);
    }, [html]);

    useEffect(() => {
        /*     console.log("content",content);*/
    }, []);


    const taskPanes = [
        {
            menuItem: { key: 'photo', icon: 'photo', content: translate('Foto Ekle') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate("Foto Ekle")}</Header>
                    <TextArea
                        className={"greyInput"}
                        rows={10}
                        placeholder={translate('Açıklama')}
                        value={task.description}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                            setTask({ ...task, description: e.target.value })
                        }}
                    />
                </div>

            </Tab.Pane>
        },
        {
            menuItem: { key: 'file', icon: 'eye', content: translate('Dosya Görüntüle') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate('Dosya Görüntüle')}</Header>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <FileInputComp
                                    setTaskDocFile={setTaskDocFile}
                                    setTaskDocUrl={setTaskDocUrl}
                                    type={"image"}
                                    accept={".xlsx,.xls,.pptx,.ppt,.doc,.docx,.pdf,.gif,.jpeg,.jpg,.png,.tiff"}
                                    dontShowPreview={false}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Input
                                    className={"greyInput"}
                                    type="number"
                                    autoFocus={false}
                                    icon='time'
                                    min={0}
                                    value={task.time}
                                    onChange={(e) => {
                                        setTask({ ...task, time: e.target.value.slice(0, 5) })
                                    }}
                                    placeholder={translate('Süre (dakika)')}
                                    style={{ width: "180px" }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {(task.fileUri != "" || taskDocUrl != "")
                        &&
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    width: "100%"
                                }}>

                                    {((!isNullOrEmpty(task?.fileUri) && task?.fileUri?.startsWith("http")) || (!isNullOrEmpty(task?.taskJson?.fileName) && taskDocUrl?.name?.startsWith("http")))
                                        &&

                                        <div
                                            className={"userDetailReportFileDesign"}
                                        >
                                            <div className={"userDetailReportFileHeaderDesign"}>
                                                <img
                                                    width="20px"
                                                    height="auto"
                                                    id="reportTaskIMage-2"
                                                    src={giveMeIconForDocType(task.taskJson.fileName)}
                                                />

                                                <div className={"userDetailReportFileNameDesign"}>{task.taskJson.fileName}</div>
                                            </div>
                                            <div>
                                                <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                    downloadFileWithEx(task.fileUri, task.taskJson.fileName || "file", task?.taskJson?.fileName?.split(".")[task?.taskJson?.fileName?.split(".")?.length - 1] || "txt")
                                                }}>
                                                   {/* {translate("İndir")}*/}
                                                    <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                                </div>
                                            </div>
                                        </div>






                                    }
                                    {(!isNullOrEmpty(task.fileUri) && !task.fileUri.startsWith("http"))
                                        &&

                                        <div
                                            className={"userDetailReportFileDesign"}
                                        >
                                            <div className={"userDetailReportFileHeaderDesign"}>
                                                <img
                                                    width="20px"
                                                    height="auto"
                                                    id="reportTaskIMage-2"
                                                    src={giveMeIconForDocType(task.taskJson.fileName)}
                                                />

                                                <div className={"userDetailReportFileNameDesign"}>{task.taskJson.fileName}</div>
                                            </div>
                                            <div>
                                                <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                    downloadFileWithEx(
                                                        task?.fileUri,
                                                        task?.taskJson?.fileName || "file",
                                                        task?.taskJson?.fileName?.split(".")[task?.taskJson?.fileName?.split(".")?.length - 1] || "txt")
                                                }}>
                                                    {/*{translate("İndir")}*/}
                                                    <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                                </div>
                                            </div>
                                        </div>



                                    }
                                </Grid.Column>
                            </Grid.Row>


                        </Grid>
                    }
                    <Message
                        style={{ borderRadius: "45px" }}
                        icon='exclamation circle'
                        header={translate('Uyarı')}
                        size={'small'}
                        content={translate("Bu görev tipine sadece: xlsx, xls, pptx, ppt, doc, docx, pdf, gif, jpeg, jpg, png, tiff dosyalarını yükleyebilirsiniz.")}
                        color={"orange"}
                    />
                </div>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'event', icon: 'chess knight', content: translate('Etkinliğe Katıl') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate('Etkinliğe Katıl')}</Header>
                    <ActivityAttachToContent id={content.id} attachActivity={attachActivity}
                        setIsTaskActivityAdded={setIsTaskActivityAdded} />
                </div>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'files', icon: 'file', content: translate('Dosya Yükle') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate("Dosya Yükle")}</Header>
                    <div style={styles.fileUploadContainer}>
                        <div style={styles.fileUploadLeftSide}>
                            <p><strong>{translate("Dosyaları görüntüleyebilir")} </strong>
                            </p>
                            <Checkbox toggle label={translate("Herkes")} value="All" onChange={whoCanUploadedsHandler}
                                checked={taskSettings?.whoCanSeeUploadeds?.filter(x => x == "All").length != 0} />
                            <Checkbox toggle
                                disabled={taskSettings?.whoCanSeeUploadeds?.filter(x => x == 'All').length > 0}
                                label="Admin" value="Admin" onChange={whoCanUploadedsHandler}
                                checked={taskSettings.whoCanSeeUploadeds?.filter(x => x == "Admin").length != 0} />
                            <Checkbox toggle
                                disabled={taskSettings?.whoCanSeeUploadeds?.filter(x => x == 'All').length > 0}
                                label="Buddy" value="Buddy" onChange={whoCanUploadedsHandler}
                                checked={taskSettings.whoCanSeeUploadeds?.filter(x => x == "Buddy").length != 0} />
                            <Checkbox toggle
                                disabled={taskSettings?.whoCanSeeUploadeds?.filter(x => x == 'All').length > 0}
                                label={translate("Yönetici")} value="Manager" onChange={whoCanUploadedsHandler}
                                checked={taskSettings.whoCanSeeUploadeds?.filter(x => x == "Manager").length != 0} />
                            <Checkbox toggle
                                disabled={taskSettings?.whoCanSeeUploadeds?.filter(x => x == 'All').length > 0}
                                label={translate("İnsan Kaynakları Sorumlusu")} value="HR" onChange={whoCanUploadedsHandler}
                                checked={taskSettings?.whoCanSeeUploadeds?.filter(x => x == "HR").length != 0} />
                            <Checkbox toggle
                                disabled={taskSettings?.whoCanSeeUploadeds?.filter(x => x == 'All').length > 0}
                                label={translate("İdari İşler Sorumlusu")} value="AdminManager"
                                onChange={whoCanUploadedsHandler}
                                checked={taskSettings?.whoCanSeeUploadeds?.filter(x => x == "AdminManager").length != 0} />
                            <Checkbox toggle
                                disabled={taskSettings?.whoCanSeeUploadeds?.filter(x => x == 'All').length > 0}
                                label={translate("BT Sorumlusu")} value="ItManager" onChange={whoCanUploadedsHandler}
                                checked={taskSettings?.whoCanSeeUploadeds?.filter(x => x == "ItManager").length != 0} />
                        </div>
                        <div style={styles.fileUploadRightSide}>
                            <TextArea rows={10}
                                className={"greyInput"}
                                placeholder={translate('Açıklama')}
                                style={{ width: "100%" }}
                                value={task.description}
                                onChange={(e) => {
                                    setTask({ ...task, description: e.target.value })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Tab.Pane>

        },
        {
            menuItem: { key: 'free', icon: 'tasks', content: translate('Serbest Görev Ekle') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate("Serbest Görev Ekle")}</Header>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row" as "row",
                            gap: "1rem",
                            justifyContent: "space-between"
                        }}>
                        <div>
                            <TextArea
                                className={"greyInput"}
                                rows={10}
                                placeholder={translate('Açıklama')}
                                style={{ width: "100%" }}
                                value={task.description}
                                onChange={(e) => {
                                    setTask({ ...task, description: e.target.value })
                                }}
                            />
                            <Checkbox
                                style={{ marginTop: "1%" }}
                                toggle
                                checked={task.showQr}
                                onChange={(e, data) => {
                                    /*        debugger;*/
                                    setTask({ ...task, showQr: data.checked != undefined ? data.checked : false });
                                    setTaskSettings({
                                        ...taskSettings,
                                        isQr: data.checked != undefined ? data.checked : false
                                    });

                                    if (content.id != undefined && content.id != "") {
                                        setQrValue(window.location.origin + "/course/" + props.moduleId);
                                    }
                                }}
                                label={translate("QR İle Tamamla")}
                            />
                        </div>
                        <div>
                            {task.showQr && content.id != undefined && content.id != ""
                                &&
                                <>
                                    <QRCode
                                        id="qr-gen"
                                        value={qrValue}
                                        size={290}
                                    />
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row" as "row",
                                        gap: "1rem",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                        marginTop: "1%"
                                    }}>
                                        <Button onClick={(e) => {
                                            downloadQRCode();
                                        }} color={"green"}>{translate("İndir")}</Button>
                                    </div>
                                </>
                            }
                        </div>

                    </div>
                </div>
            </Tab.Pane>
        }
    ]

    const panes = [
        {
            menuItem: { key: 'video', icon: 'video', content: 'Video' },
            render: () =>
                <Tab.Pane>
                    <div className={"contentAddVideoPane"}>
                        <div className={"contentAddVideoPaneContent"}>
                            <div className={"contentAddVideoPaneLeftContent"}>
                                <Header as={"h3"}>Video</Header>
                                <div>
                                    <div><label htmlFor="timeInput"><Header as={"h4"}>{translate("Seçenekler")}</Header></label></div>
                                    <Dropdown
                                        className={"greyInput"}
                                        value={video.videoType}
                                        name="videotypes"
                                        required="required"
                                        clearable
                                        selection
                                        options={videoTypes}
                                        placeholder={translate("Video Tipi Seçiniz")}
                                        onChange={(e, data) => {
                                            setVideoType(e, data);
                                        }}
                                    />
                                </div>

                                {video.videoType == 1 &&
                                    <div className={"contentAddVideoTypeContainer"}>
                                        <div>
                                            <div><label htmlFor="timeInput"><Header as={"h4"}>Link</Header></label></div>
                                            <Input
                                                className={"greyInput"}
                                                style={{ width: "100%" }}
                                                autoFocus={true}
                                                value={video.uri}
                                                onChange={(e) => {
                                                    setVideo({ ...video, uri: e.target.value })
                                                }}
                                                action={{ content: translate('Ara'), color: 'blue' }}
                                                placeholder={translate('Link Ekleyin')} />
                                        </div>


                                        <div>
                                            <div><label htmlFor="timeInput"><Header as={"h4"}>{translate("Süre (dakika)")}</Header></label>
                                            </div>
                                            <Input
                                                className={"greyInput"}
                                                name={"timeInput"}
                                                autoFocus={true}
                                                type="number"
                                                icon='time'
                                                min="1"
                                                value={video.time}
                                                onChange={(e) => {
                                                    if (Number(e.target.value) > 0) {
                                                        setVideo({ ...video, time: e.target.value.slice(0, 5) })
                                                    } else {
                                                        setVideo({ ...video, time: "" })
                                                    }
                                                }}
                                                placeholder={translate('Süre (dakika)')}
                                                style={{ width: "150px" }} />
                                        </div>

                                    </div>
                                }
                                {video.videoType == 2 &&
                                    <div className={"contentAddVideoTypeContainer"}>
                                        <div>
                                            <FileInputComp
                                                setVideoUrl={setVideoUrl}
                                                setVideoFile={setVideoFile}
                                                type={"video"}
                                            />
                                        </div>

                                        <div>
                                            <Input
                                                autoFocus
                                                min="1"
                                                type="number"
                                                icon='time'
                                                value={video.time}
                                                onChange={(e) => {
                                                    if (Number(e.target.value) > 0) {
                                                        setVideo({ ...video, time: e.target.value.slice(0, 5) })
                                                    } else {
                                                        setVideo({ ...video, time: "" })
                                                    }
                                                }}
                                                placeholder={translate('Süre (dakika)')}
                                                style={{ width: "150px" }} />
                                        </div>


                                    </div>
                                }
                                {video.videoType == 4 &&
                                    <div className={"contentAddVideoTypeContainer"}>
                                        <div>
                                            <div><label htmlFor="embedCode"><Header as={"h4"}>{translate("Gömülü Kod")}</Header></label>
                                            </div>
                                            <TextArea
                                                className={"greyInput"}
                                                name={"embedCode"}
                                                autoFocus
                                                rows={6}
                                                style={{ width: '100%' }}
                                                value={video.embedCode}
                                                onChange={(e) => {
                                                    setVideo({ ...video, embedCode: e.target.value })
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <div><label htmlFor="timeNumber"><Header as={"h4"}>{translate("Süre (dakika)")}</Header></label></div>
                                            <Input
                                                name={"timeNumber"}
                                                autoFocus
                                                type="number"
                                                icon='time'
                                                min="1"
                                                value={video.time}
                                                onChange={(e) => {
                                                    if (Number(e.target.value) > 0) {
                                                        setVideo({ ...video, time: e.target.value.slice(0, 5) })
                                                    } else {
                                                        setVideo({ ...video, time: "" })
                                                    }
                                                }}
                                                placeholder={translate('Süre (dakika)')}
                                                style={{ width: "150px" }} />
                                        </div>


                                    </div>
                                }
                                {video.videoType == 5 &&
                                    <div className={"contentAddVideoTypeContainer"}>
                                        <div style={styles.subtitles}>
                                            <Header as='h2'>{translate("Video Galeri")}</Header>
                                            <Table compact definition>
                                                <Table.Header fullWidth>
                                                    <Table.Row>
                                                        <Table.HeaderCell />
                                                        <Table.HeaderCell>{translate("Video Adı")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{translate("Eklenme Tarihi")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{translate("İşlem")}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {videoList != undefined && videoList.map((v) => (
                                                        <Table.Row key={uuidv4()}>
                                                            <Table.Cell collapsing>
                                                                <Checkbox slider checked={v.isActive}
                                                                    onChange={(e, data) => { videoIsActiveHandler(data, v) }}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell>{v.fileName}</Table.Cell>
                                                            <Table.Cell>{v.createdDate} </Table.Cell>
                                                            <Table.Cell>
                                                                {v.isSaved &&
                                                                    <a href={v.fileUrl} target="_blank" >
                                                                        <Button animated>
                                                                            <Button.Content visible>İzle</Button.Content>
                                                                            <Button.Content hidden>
                                                                                <Icon name='video' />
                                                                            </Button.Content>
                                                                        </Button>
                                                                    </a>
                                                                }

                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                    {videoList != undefined && videoList.length == 0 &&
                                                        <Table.Row>
                                                            <Table.Cell collapsing>

                                                            </Table.Cell>
                                                            <Table.Cell>{translate("Ekli Video Mevcut Değil")}</Table.Cell>

                                                        </Table.Row>
                                                    }


                                                </Table.Body>

                                                <Table.Footer fullWidth>
                                                    <Table.Row>
                                                        <Table.HeaderCell />
                                                        <Table.HeaderCell colSpan='4'>
                                                            {isUploading &&
                                                                <Progress percent={progressGallery} inverted indicating progress>
                                                                    {progressText}
                                                                </Progress>}
                                                            {(videoList.length > 0 && !isUploading) &&
                                                                <Button
                                                                    floated='right'
                                                                    icon
                                                                    labelPosition='left'
                                                                    disabled={showVideoGalleryPreview}
                                                                    primary
                                                                    size='small'
                                                                    onClick={() => {
                                                                        setShowVideoGalleryPreview(true)
                                                                    }}
                                                                >
                                                                    <Icon name='unhide' /> Önizle
                                                                </Button>}
                                                            {!isUploading &&
                                                                <Button
                                                                    floated='right'
                                                                    icon
                                                                    labelPosition='left'
                                                                    disabled={showAddVideoGallery}
                                                                    primary
                                                                    color='green'
                                                                    size='small'
                                                                    onClick={() => {
                                                                        setShowAddVideoGallery(true)
                                                                    }}
                                                                >
                                                                    <Icon name='plus' /> {translate("Yeni Ekle")}
                                                                </Button>
                                                            }




                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Footer>
                                            </Table>
                                            {showAddVideoGallery &&
                                                <div style={styles.addSubtitleForm}>
                                                    <div>
                                                        <FileInputComp
                                                            setVideoUrl={setVideoGalleryUrl}
                                                            setVideoFile={setVideoGalleryFile}
                                                            type={"video"}
                                                            accept={".mp4"}
                                                            dontShowPreview={true}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Button className={"roundedButton"} color={"grey"}
                                                            onClick={() => {
                                                                setShowAddVideoGallery(false)
                                                            }}
                                                        >
                                                            <Icon name='cancel' />
                                                            {translate("Vazgeç")}
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button className={"roundedButton"} color={"green"}
                                                            onClick={addVideoToGallery}

                                                        >
                                                            <Icon name='add' />
                                                            {translate("Galeriye Video Ekle")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>



                                    </div>
                                }

                                <div>
                                    <Checkbox toggle
                                        checked={video.autoPlay}
                                        onChange={(e, data) => {
                                            setVideo({
                                                ...video,
                                                autoPlay: data.checked != undefined ? data.checked : false
                                            })
                                        }}
                                        label={translate("Oto Oynat")}
                                    />
                                </div>
                                {video.videoType != 4 &&
                                    <div>
                                        <Checkbox toggle
                                            checked={video.stopWhenTabChanges}
                                            onChange={(e, data) => {
                                                setVideo({
                                                    ...video,
                                                    stopWhenTabChanges: data.checked != undefined ? data.checked : false
                                                })
                                            }}
                                            label={translate("Sekme Değişince Durdur")}
                                        />
                                    </div>
                                }
                                {video.videoType == 2 &&
                                    <div>
                                        <Checkbox toggle
                                            checked={isActiveSubtitles}
                                            onChange={(e, data) => {
                                                setIsActiveSubtitles(data.checked != undefined ? data.checked : false);
                                            }}
                                            label={translate("Altyazılar")}
                                        />
                                    </div>
                                }
                            </div>
                            {(video.videoType == 2 && isActiveSubtitles) &&
                                <div style={styles.subtitles}>
                                    <Header as='h2'>{translate("Altyazılar")}</Header>
                                    <Table celled compact definition>
                                        <Table.Header fullWidth>
                                            <Table.Row>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell>{translate("Dil")}</Table.HeaderCell>
                                                <Table.HeaderCell>{translate("Dosya Adı")}</Table.HeaderCell>
                                                <Table.HeaderCell>{translate("Eklenme Tarihi")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {subtitleList != undefined && subtitleList.map((sub) => (
                                                <Table.Row key={uuidv4()}>
                                                    <Table.Cell collapsing>
                                                        <Checkbox slider checked={!sub.isDeleted}
                                                            onChange={(e, data) => { subtitleIsDeletedHandler(data, sub) }}
                                                        />

                                                    </Table.Cell>
                                                    <Table.Cell><Flag name={sub.flag} /> {sub.flag}</Table.Cell>
                                                    <Table.Cell>{sub.fileName}</Table.Cell>
                                                    <Table.Cell>{sub.createdDate} </Table.Cell>
                                                </Table.Row>
                                            ))}
                                            {subtitleList != undefined && subtitleList.length == 0 &&
                                                <Table.Row>
                                                    <Table.Cell collapsing>

                                                    </Table.Cell>
                                                    <Table.Cell>Ekli Altyazı Mevcut Değil</Table.Cell>

                                                </Table.Row>
                                            }


                                        </Table.Body>

                                        <Table.Footer fullWidth>
                                            <Table.Row>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell colSpan='4'>

                                                    <Button
                                                        floated='right'
                                                        icon
                                                        labelPosition='left'
                                                        disabled={showAddSubtitle}
                                                        primary
                                                        size='small'
                                                        onClick={() => {
                                                            setShowAddSubtitle(true)
                                                        }}
                                                    >
                                                        <Icon name='plus' /> {translate("Yeni Ekle")}
                                                    </Button>


                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                    {showAddSubtitle
                                        &&
                                        <>
                                            <Message warning>
                                                <Message.Header>{translate("Sadece .vtt uzantılı altyazılar eklenebilir.")}</Message.Header>
                                            </Message>
                                            <div style={styles.addSubtitleForm}>
                                                <div style={styles.language}>
                                                    <Dropdown
                                                        className="greyInput"
                                                        placeholder={translate('Dil Seçiniz')}
                                                        clearable
                                                        search
                                                        selection
                                                        value={selectedDefaultLanguage}
                                                        options={languageListDropdown}
                                                        onChange={(event, data) => {
                                                            handleChanges(event, data)
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <FileInputComp
                                                        setSubtitleUrl={setSubtitleUrl}
                                                        setSubtitleFile={setSubtitleFile}
                                                        type={"subtitle"}
                                                        accept={".vtt,.srt"}
                                                    />
                                                </div>
                                                <div>
                                                    <Button className={"roundedButton"} color={"grey"}
                                                        onClick={() => {
                                                            setShowAddSubtitle(false)
                                                        }}
                                                    >
                                                        <Icon name='cancel' />
                                                        {translate("Vazgeç")}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button className={"roundedButton"} color={"green"}
                                                        onClick={addSubtitle}

                                                    >
                                                        <Icon name='add' />
                                                        Altyazı Ekle
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                            }
                            <div>
                                {video.uri != "" && video.uri != undefined && video.videoType != 4
                                    &&
                                    <ReactPlayer controls={true} url={video.uri} />
                                }
                            </div>
                            <div>
                                {video.embedCode != "" && video.embedCode != undefined && video.videoType == 4
                                    &&
                                    parse(video.embedCode)
                                }
                            </div>
                        </div>
                    </div>
                </Tab.Pane>
        },
        {
            menuItem: { key: 'html', icon: 'code', content: 'Html' },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>Html</Header>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Checkbox toggle
                                    checked={html.showComplateButton}
                                    onChange={(e, data) => {
                                        setHtml({
                                            ...html,
                                            showComplateButton: data.checked != undefined ? data.checked : false
                                        })
                                    }}
                                    label={translate("Tamamlandı Butonu Göster")}
                                />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ alignItems: "center" }}>
                            <Grid.Column style={{ width: "250px" }}>
                                <Checkbox toggle
                                    checked={html.assignTime}
                                    label={translate("Süre Ata")}
                                    onChange={(e, data) => {
                                        setHtml({
                                            ...html,
                                            assignTime: data.checked != undefined ? data.checked : false
                                        })
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                {html.assignTime
                                    &&
                                    <>
                                        <Input
                                            autoFocus
                                            type="number"
                                            icon='time'
                                            min="1"
                                            value={html.time}
                                            onChange={(e) => {
                                                if (Number(e.target.value) > 0) {
                                                    setHtml({ ...html, time: e.target.value.slice(0, 5) })
                                                } else {
                                                    setHtml({ ...html, time: "" })
                                                }
                                            }}
                                            placeholder={translate('Süre (dakika)')}
                                            style={{ width: "150px" }}
                                        />
                                    </>
                                }
                            </Grid.Column>



                        </Grid.Row>
                    </Grid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>

                                <QuillEditor
                                    value={html.embedCode}
                                    setValue={(value) => 
                                        setHtml({ ...html, embedCode: value, embedValue: extractAllElements(value) })
                                    }
                                />

                            </Grid.Column>

                        </Grid.Row>
                       
                    </Grid>
                </div>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'exam', icon: 'question', content: translate('Sınav') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate("Sınav")}</Header>
                    <QuizAttachToContent id={content.id} attachQuiz={attachQuiz} setQuiz={setQuiz} />
                </div>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'survey', icon: 'list ul', content: translate('Anket') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate("Anket")}</Header>
                    <SurveyAttachToContent id={content.id} attachSurvey={attachSurvey}
                        setIsSurveyAdded={setIsSurveyAdded} />
                </div>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'task', icon: 'flag checkered', content: translate('Görev') },
            render: () => <Tab.Pane>
                <div className={"contentAddVideoPane"}>
                    <Header as={"h3"}>{translate("Görev")}</Header>
                    <Checkbox toggle
                        checked={taskSettings.showComplateButton}
                        style={{ marginBottom: '3%' }}
                        onChange={(e, data) => {
                            setTaskSettings({
                                ...taskSettings,
                                showComplateButton: data.checked != undefined ? data.checked : false
                            })
                        }}
                        label={translate("Tamamlandı Butonu Göster")}
                    />
                    <Tab
                        menu={{ secondary: true }}
                        className="contentsTab"
                        panes={taskPanes}
                        activeIndex={taskPaneActiveIndex}
                        onTabChange={(e, data) => {
                            handleTaskTabChange(e, data);
                        }}
                    />
                </div>
            </Tab.Pane>
        },
        {
            menuItem: { key: 'scorm', icon: 'cloud', content: translate('Scorm') },
            render: () => <Tab.Pane>
                {/*<div className={"addScormPane"}>*/}
                {/*    <Header as={"h3"}>{translate("Scorm")}</Header>*/}
                {/*    <FileInputComp*/}
                {/*        setScormFile={setScormFile}*/}
                {/*        setScormUrl={setScormUrl}*/}
                {/*        type={"scorm"}*/}
                {/*        accept={".zip"}*/}
                {/*        dontShowPreview={true}*/}
                {/*    />*/}
                {/*</div>*/}
                <div>
                    <Message
                        warning
                        header={translate('Uyarı')}
                        content={translate('Bu özellik bu versiyonda kullanım dışıdır.')}
                    />
                </div>
            </Tab.Pane>
        }


    ]

    const setVideoType = (e, data) => {

        setVideo({
            ...video, videoType: data.value,
            uri: ""
        });
    }

    const rulesChangeDropdown = (event, data, isStart) => {
        if (data.value == "" && isStart) {
            setStartAccessRule({
                accessibilityRuleId: undefined,
                moduleSectionId: undefined,
                sectionContentId: undefined,
                accessibilityEventId: undefined,
                value: undefined,
                valueType: undefined,
                displayName: undefined,
                isStart: true
            });
            return;
        } else if (data.value == "" && !isStart) {
            setFinishAccessRule({
                accessibilityRuleId: undefined,
                moduleSectionId: undefined,
                sectionContentId: undefined,
                accessibilityEventId: undefined,
                value: undefined,
                valueType: undefined,
                displayName: undefined,
                isStart: false
            });

            return;
        }
        var event = _.find(accessibilityEvents, (doc) => {
            return doc?.accessibilityEventId == data.value;
        });
        if (!event.canChooseAnytime && (props.apiModule.sectionList.length == 0 || props.apiModule.sectionList[0].contentList.length == 0 || props.apiModule.sectionList[0].contentList[0].id == content.id)) {
            toast("İlk kayıt için, lütfen başka bir kural seçiniz.", {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 5000
            });
            return;
        }
        if (isStart) {
            setStartAccessRule({
                accessibilityRuleId: startAccessRule.accessibilityRuleId,
                moduleSectionId: undefined,
                sectionContentId: content.id,
                accessibilityEventId: data.value,
                valueType: event.valueType,
                displayName: event.displayName,
                isStart: true
            });
        } else {
            setFinishAccessRule({
                accessibilityRuleId: finishAccessRule.accessibilityRuleId,
                moduleSectionId: undefined,
                sectionContentId: content.id,
                accessibilityEventId: data.value,
                valueType: event.valueType,
                displayName: event.displayName,
                isStart: false
            });
        }
    }

    const contentHandleChange = (e) => {
        let name = e.target.name;
        setContent({
            ...content,
            [name]: e.target.value
        });
    }

    const settingsHandleChange = (e) => {
        let name = e.target.name;
        setSettings({
            ...settings,
            [name]: e.target.value
        });
    }

    const attachImage = (contentId) => {
        let contentData = {
            id: contentId,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
        };
        ////console.log(contentId, contentImage);
        if (contentImage != "") {
            let store = DataHandler.getStore();
            var formData = new FormData();
            formData.append('Data', contentImage);
            formData.append('ModuleContent', JSON.stringify(contentData));
            formData.append('Authorization', store != undefined ? store.token : undefined);

            NetworkRequest("/api/SectionContent/AttachImage", formData, true).then((response) => {
                if (response.data.resultCode == 0) {
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                } else {
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        }
    }

    const addContent = (e: any) => {
        // e.stopPropagation();
        // e.preventDefault();
        //debugger;
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            props.setCurrentContentIndex(props.currentContentIndex);
            let contentData = {
                moduleSectionId: content.moduleSectionId,
                contentType: content.contentType,
                title: content.title,
                description: content.description,
                details: content.details,
                responsibleRoleId: content.responsibleRoleId,
                rank: content.rank,
                statusId: content.statusId,
                tenantId: tenantId
            }
            ////console.log("Valuee kontrolll", contentData.startEvent.value)
            if (
                contentData.responsibleRoleId == ""
                || contentData.title == ""
                || contentData.contentType == null
                || startAccessRule?.accessibilityEventId == undefined
                || finishAccessRule?.accessibilityEventId == undefined
                || (
                    startAccessRule.valueType != undefined && startAccessRule.value == undefined
                )
                || (
                    finishAccessRule.valueType != undefined && finishAccessRule.value == undefined
                )
            ) {
                toast("Lütfen tüm alanları doldurun", {
                    type: 'warning',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
                return;
            }
            if (startAccessRule.value != undefined && finishAccessRule.value != undefined && startAccessRule.value > finishAccessRule.value && startAccessRule.valueType == 2 && finishAccessRule.valueType == 2) {
                toast("Başlangıç tarihi bitiş tarihinden büyük olamaz", {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 4500
                });
                return;
            }
            let uri = "/api/SectionContent/Add";
            NetworkRequest(uri, {
                content: contentData,
                startAccessRule: startAccessRule,
                finishAccessRule: finishAccessRule
            }).then((responseContent) => {
                if (responseContent.data.resultCode == 0) {

                    toast("Ekleme başarılı.", {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    let resultContent = responseContent.data.detail;
                    attachImage(resultContent.id);
                    switch (content.contentType) {
                        case 1: {
                            let videoData: any = {
                                autoPlay: video.autoPlay,
                                sectionContentId: resultContent.id,
                                embedCode: video.embedCode,
                                stopWhenTabChanges: video.stopWhenTabChanges,
                                title: video.title,
                                uri: video.uri,
                                videoType: video.videoType,
                                tenantId: video.tenantId,
                                appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',
                                time: video.time,
                                uriList: JSON.stringify(videoList)
                            }
                            addVideo(videoData);
                            break;
                        }
                        case 2: {
                            let htmlData = {
                                sectionContentId: resultContent.id,
                                embedCode: html.embedCode,
                                tenantId: html.tenantId,
                                htmlJson: JSON.stringify({
                                    embedValue: html.embedValue,
                                    assignTime: html.assignTime,
                                    showComplateButton: html.showComplateButton,
                                    time: html.time,
                                    showFormHtmlIframe: showFormHtmlIframe,
                                    iframeID: iframeID,
                                    inputID: inputID,
                                    searchInputValue: searchInputValue

                                })
                            }
                          
                            addHtml(htmlData);
                            break;
                        }
                        case 4: {
                            let quizData = {
                                quizId: quiz.id,
                                contentId: resultContent.id,
                                tenantId: quiz.tenantId
                            }
                            addQuiz(quizData);
                            break;
                        }
                        case 8: {

                            let surveyData = {
                                surveyId: survey.surveyId,
                                contentId: resultContent.id,
                                tenantId: survey.tenantId
                            }
                            addSurvey(surveyData);
                            break;
                        }
                        case 15: {
                            let taskData = {
                                sectionContentId: resultContent.id,
                                description: task.description,
                                type: task.type,
                                taskJson: JSON.stringify(taskSettings),
                                tenantId: task.tenantId,
                                time: task.time,
                                appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
                            }
                            /*  debugger;*/
                            addTask(taskData);
                            break;
                        }
                        case 16: {
                            let scormData: any = {

                                sectionContentId: resultContent.id,
                                title: "Scorm 1.2 test",

                                tenantId: video.tenantId,
                                appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',

                            }
                            addScorm(scormData);
                            break;
                        }
                        default: {
                            //statements; 
                            break;
                        }
                    }
                    let rt = { ...responseContent.data, operation: "c" };
                    onComplate(rt);
                }
            });
        }, () => {
            toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    };

    const attachQuiz = (id) => {
        setQuiz({ ...quiz, quizId: id, contentId: content.id });
    }

    const attachSurvey = (id) => {
        setSurvey({ ...survey, surveyId: id, contentId: content.id });
        setIsSurveyAdded(true);
    }

    const attachActivity = (id) => {
        setActivity({ ...activity, activityId: id, contentId: content.id });
    }

    const addQuiz = (quizData: any) => {
        let req = {
            quizId: quizData.quizId,
            contentId: quizData.contentId
        }
        NetworkRequest("/api/SectionContent/AddQuiz", req);
    }

    const addSurvey = (surveyData: any) => {
        let req = {
            surveyId: surveyData.surveyId,
            contentId: surveyData.contentId
        }
        NetworkRequest("/api/SectionContent/AddSurvey", req);
    }

    const addActivity = (activityData: object) => {
        NetworkRequest("/api/ActivityAndContent/Add", activityData).then(response => {
            let result = response.data.detail;
        });
    }

    const resolveHandler = (value) => {
        return value;
    }

    const promiseMaker = (value) => {
        return new Promise(function (resolve, reject) {
            resolve(resolveHandler(value));
            reject('Price Promise Hata');
        });
    };

    const updateContent = () => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            props.setCurrentContentIndex(props.currentContentIndex);
            let contentData = {
                id: content.id,
                moduleSectionId: content.moduleSectionId,
                contentType: content.contentType,
                title: content.title,
                description: content.description,
                details: content.details,
                responsibleRoleId: content.responsibleRoleId,
                rank: content.rank,
                statusId: content.statusId,
                tenantId: tenantId
            }
            if (
                contentData.responsibleRoleId == ""
                || contentData.title == ""
                || contentData.contentType == null
                || startAccessRule?.accessibilityEventId == null
                || finishAccessRule?.accessibilityEventId == null
                || (
                    startAccessRule.valueType != null && startAccessRule.value == null
                )
                || (
                    finishAccessRule.valueType != null && finishAccessRule.value == null
                )
            ) {
                toast("Lütfen tüm alanları doldurun", {
                    type: 'warning',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
                return;
            }
            if (startAccessRule.value != undefined
                && finishAccessRule.value != undefined
                && startAccessRule.value > finishAccessRule.value
                && startAccessRule.valueType == 2
                && finishAccessRule.valueType == 2
            ) {
                toast("Başlangıç tarihi bitiş tarihinden büyük olamaz", {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 4500
                });
            } else {
              
                NetworkRequest("/api/SectionContent/Update", {
                    content: contentData,
                    startAccessRule: startAccessRule,
                    finishAccessRule: finishAccessRule
                }).then((responseContent) => {
                    if (responseContent.data.resultCode == 0) {
                        toast(translate("Güncelleme başarılı."), {
                            type: 'info',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });

                        let resultContent = responseContent.data.detail;
                        attachImage(resultContent.id);
                        switch (content.contentType) {
                            case 1: {
                                let videoData: any = {
                                    autoPlay: video.autoPlay,
                                    sectionContentId: resultContent.id,
                                    embedCode: video.embedCode,
                                    stopWhenTabChanges: video.stopWhenTabChanges,
                                    title: video.title,
                                    uri: video.uri,
                                    videoType: video.videoType,
                                    tenantId: video.tenantId,
                                    appType: window.location.origin.indexOf('local') || window.location.origin.indexOf('test') ? 'Test' : 'Prod',
                                    time: video.time,
                                    uriList: JSON.stringify(videoList)
                                }
                                if (video.id != "") {
                                    videoData.id = video.id;
                                    updateVideo(videoData);
                                } else {
                                    addVideo(videoData);
                                }
                                break;
                            }
                            case 2: {
                             
                                let htmlData: any = {
                                    sectionContentId: resultContent.id,
                                    embedCode: html.embedCode,
                                    htmlJson: JSON.stringify({
                                        assignTime: html.assignTime,
                                        showComplateButton: html.showComplateButton,
                                        time: html.time,
                                        showFormHtmlIframe: showFormHtmlIframe,
                                        iframeID: iframeID,
                                        inputID: inputID,
                                        searchInputValue: searchInputValue,
                                        embedValue: html.embedValue

                                    }),
                                    tenantId: html.tenantId
                                }
                                if (html.id != "") {
                                    htmlData.id = html.id;
                                    updateHtml(htmlData);
                                } else {
                                    addHtml(htmlData);
                                }
                                break;
                            }
                            case 4: {
                                let quizData = {
                                    quizId: quiz.id,
                                    contentId: content.id,
                                    tenantId: quiz.tenantId
                                }
                                addQuiz(quizData);
                                break;
                            }
                            case 8: {
                                let surveyData = {
                                    surveyId: survey.surveyId,
                                    contentId: resultContent.id,
                                    tenantId: survey.tenantId
                                }
                                addSurvey(surveyData);
                                break;
                            }
                            case 15: {
                                let taskData: any = {
                                    sectionContentId: resultContent.id,
                                    description: task.description,
                                    type: task.type,
                                    tenantId: task.tenantId,
                                    time: task.time,
                                    taskJson: JSON.stringify(taskSettings),
                                    appType: window.location.origin.indexOf('local') || window.location.origin.indexOf('test') ? 'Test' : 'Prod'
                                }
                                if (task.id != "") {
                                    taskData.id = task.id;
                                    updateTask(taskData);
                                } else {
                                    addTask(taskData);
                                }
                                break;
                            }

                            default: {
                                //statements; 
                                break;
                            }
                        }
                        let rt = { ...responseContent.data, operation: "u" };
                        onComplate(rt);
                        closePage();
                    }
                });
            }
        }, () => {
            toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const getVideoDataByContentId = (videoData: object) => {
        try {
            NetworkRequest("/api/Video/GetByContentId", videoData).then((response) => {
                if (response.data.resultCode == 0) {
                    let result = response.data.detail;
                    //debugger;
                    ////console.log("getVideoDataByContentId: ", result);
                    if (result != undefined) {
                        setVideo({
                            id: result.id,
                            autoPlay: result.autoPlay,
                            sectionContentId: result.sectionContentId,
                            embedCode: result.embedCode,
                            stopWhenTabChanges: result.stopWhenTabChanges,
                            title: result.title,
                            uri: result.uri,
                            videoType: result.videoType,
                            tenantId: result.tenantId,
                            time: result.time
                        });
                        let fileContent = result.uri.replace("data:video/mp4;base64, ", "");
                        let bytes = new Uint8Array(fileContent.length);

                        for (let i = 0; i < bytes.length; i++) {
                            bytes[i] = fileContent.charCodeAt(i);
                        }
                        //console.log(URL.createObjectURL(new Blob([bytes], { type: 'video/mp4' })));
                        //console.log(URL.createObjectURL(new Blob([result.uriBinary], { type: 'video/mp4' })));
                        if (result.subtitleListView.length > 0) {
                            //debugger;
                            setSubtitleList(
                                result.subtitleListView.map(x => (
                                    {
                                        createDateTime: x.createDateTime,
                                        isDeleted: x.isDeleted,
                                        languageId: x.languageId,
                                        subtitleId: x.subtitleId,
                                        subtitleUrl: x.subtitleUrl,
                                        videoId: x.videoId,
                                        fileName: x.subtitleUrl.slice(-16),
                                        flag: x.flag
                                    }
                                )))
                            setIsActiveSubtitles(true);
                            //new {
                            //    id: vId,
                            //    file: subtitleFile,
                            //    fileUrl: result.detail,
                            //    flag: selectedDefaultLanguageObj.flag,
                            //    name: selectedDefaultLanguageObj.text,
                            //    languageId: selectedDefaultLanguageObj.value,
                            //    createdDate: moment().format("DD/MM/YYYY HH:mm"),
                            //    isSaved: true,
                            //    fileName: subtitleUrl?.name,
                            //    isDeleted: false
                            //}
                        }
                        if (result.uriList)
                            setVideoList(JSON.parse(result.uriList));
                    }
                }

            });
        } catch (e) {
            ////
        }
    }

    const getQuizDataByContentId = (data: object) => {
        try {
            NetworkRequest("/Quiz/GetByContentId", data).then((response) => {
                if (response.data.resultCode == 0) {
                    let result = response.data.detail;
                    ////console.log("getVideoDataByContentId: ", result);
                    if (result != undefined) {
                        setQuiz(result);
                        window.setTimeout(() => {
                            setIsFormOk(true);
                        }, 1000);
                    }
                }
            });
        } catch (e) {
            ////
        }
    }

    const addVideo = (videoData: object) => {
        if (video.videoType == 2 && videoUrl != "") {
            var formData = new FormData();
            formData.append('Data', videoUrl);
            formData.append('ContentVideo', JSON.stringify(videoData));

            toast("Lütfen Bekleyin, Video Yükleniyor", {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                isLoading: true,
                autoClose: false
            });
            props.setIsUploading(true);
            NetworkRequest("/api/Video/AddWithFile", formData, true, null, undefined, props.setProgressContent).then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    var video = response.data.detail;

                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    props.setProgressContentText("Video Başarıyla Yüklendi.");
                    setTimeout(() => {
                        props.setIsUploading(false);
                    }, 1500)
                    if (subtitleList.length > 0) {
                        addSubtitles(video.id)
                    }
                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        } else {
            NetworkRequest("/api/Video/Add", videoData, undefined, undefined, true).then((response) => {
                //let result = response.data.detail;
                ////console.log(result);
            });
        }
    }

    const addScorm = (scormData: object) => {
        var formData = new FormData();
        formData.append('Data', scormUrl);
        formData.append('ContentScorm', JSON.stringify(scormData));

        toast("Lütfen Bekleyin, Paket Yükleniyor", {
            type: 'info',
            theme: 'dark',
            position: 'top-center',
            isLoading: true,
            autoClose: false
        });
        props.setIsUploading(true);
        NetworkRequest("/api/Scorm/AddWithFile", formData, true, null, undefined, props.setProgressContent).then((response) => {
            if (response.data.resultCode == 0) {
                toast.dismiss();
                var video = response.data.detail;

                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
                props.setProgressContentText("Paket Başarıyla Yüklendi.");
                setTimeout(() => {
                    props.setIsUploading(false);
                }, 1500)
                if (subtitleList.length > 0) {
                    addSubtitles(video.id)
                }
            } else {
                toast.dismiss();
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });

    }

    const addSubtitles = (videoId) => {
        //debugger;
        NetworkRequest("/api/Subtitle/Add", subtitleList.map((x) =>
        ({
            subtitleId: x.subtitleId,
            videoId: videoId,
            languageId: x.languageId,
            subtitleUrl: x.subtitleUrl,
            isDeleted: x.isDeleted,
            mimeType: x.mimeType
        })
        ), undefined, undefined, true).then((response) => {
            let result = response.data.detail;
            ////console.log(result);
        });
    }

    const updateVideo = (videoData: object) => {
        if (subtitleList.length > 0) {
            addSubtitles(video.id)
        }
        if (video.videoType == 2 && videoUrl != "") {
            var formData = new FormData();
            formData.append('Data', videoUrl);
            formData.append('ContentVideo', JSON.stringify(videoData));
            toast("Lütfen Bekleyin, Video Yükleniyor", {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 120000
            });
            NetworkRequest("/api/Video/UpdateWithFile", formData, true).then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    ////console.log(result);
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    //debugger;

                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        } else {
            NetworkRequest("/api/Video/Update", videoData, undefined, undefined, true).then((response) => {
                toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
            });
        }
    }

    const getHtmlDataByContentId = (htmlData: object) => {
        NetworkRequest("/api/Html/GetByContentId", htmlData).then((response) => {
            let result = response.data.detail;
            ////console.log(result);
            if (result != null) {
                let htmlJson = JSON.parse(result.htmlJson)
             
                setHtml({
                    id: result.id,
                    sectionContentId: result.sectionContentId,
                    embedCode: result.embedCode,
                    embedValue: result.embedCode,
                    showComplateButton: htmlJson.showComplateButton,
                    assignTime: htmlJson.assignTime,
                    time: htmlJson.time,
                    tenantId: result.tenantId,
                })
                setShowFormHtmlIframe(htmlJson.showFormHtmlIframe)
                setIframeID(htmlJson.iframeID)
                setInputID(htmlJson.inputID)
                setSearchInputValue(htmlJson.searchInputValue)
            }
        });
    }

    const addHtml = (htmlData) => {
        NetworkRequest("/api/Html/Add", htmlData, undefined, undefined, true);
    }

    const updateHtml = (htmlData: object) => {
        NetworkRequest("/api/Html/Update", htmlData, undefined, undefined, true);
    }

    const addTask = (taskData: object) => {
        if (task.type == 2 && taskDocUrl != "") {
            //debugger;
            var formData = new FormData();
            formData.append('Data', taskDocUrl);
            formData.append('Task', JSON.stringify(taskData));
            props.setIsUploading(true);
            toast("Lütfen Bekleyin, Dosya Yükleniyor", {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                isLoading: true,
                autoClose: false
            });
            NetworkRequest("/api/ContentTask/AddWithFile", formData, true, null, undefined, props.setProgressContent).then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    props.setProgressContentText("Dosya Başarıyla Yüklendi.");
                    setTimeout(() => {
                        props.setIsUploading(false);
                    }, 3000)
                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        } else if (task.type == 4) {
            NetworkRequest("/api/ContentTask/Add", taskData).then(response => {
                let result = response.data.detail;
                let activityData = {
                    activityId: activity.activityId,
                    contentId: result.sectionContentId
                }
                addActivity(activityData);
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
            });
        } else {
            NetworkRequest("/api/ContentTask/Add", taskData);
        }
    }

    const updateTask = (taskData: object) => {
        if (task.type == 2 && taskDocUrl != "") {
            ////debugger;
            let store = DataHandler.getStore();
            var formData = new FormData();
            formData.append('Data', taskDocUrl);
            formData.append('AuthToken', store != undefined ? store.token : undefined);
            formData.append('Task', JSON.stringify(taskData));

            NetworkRequest("/api/ContentTask/UpdateWithFile", formData, true).then((response) => {
                if (response.data.resultCode == 0) {
                    ////console.log(result);
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        } else {
            NetworkRequest("/api/ContentTask/Update", taskData).then((response) => {
                /* debugger;*/
                let result = response.data.detail;
                ////console.log(result);
                let activityData = {
                    activityId: activity.activityId,
                    contentId: result.sectionContentId
                }
                if (activityData.activityId != "") {
                    addActivity(activityData);
                }
            });
        }
    }

    const getTaskDataByContentId = (taskData: object) => {
        NetworkRequest("/api/ContentTask/GetByContentId", taskData).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail != null) {
                let result = response.data.detail;
                ////console.log(result);
                

                setTask({
                    id: result.id,
                    sectionContentId: result.sectionContentId,
                    description: result.description,
                    title: result.title,
                    type: result.type,
                    taskJson: JSON.parse(result.taskJson),
                    fileUri: result.fileUri,
                    mimeType: result.mimeType,
                    tenantId: result.tenantId,
                    time: result.time,
                    showQr: false
                });

                if (result.type == 1) {
                    setTaskPaneActiveIndex(0)
                } else if (result.type == 2) {
                    setTaskPaneActiveIndex(1)
                } else if (result.type == 4) {
                    setTaskPaneActiveIndex(2)
                } else if (result.type == 8) {
                    setTaskPaneActiveIndex(3)
                } else if (result.type == 15) {
                    setTaskPaneActiveIndex(4)
                }
                if (result.taskJson != null) {
                    let json = JSON.parse(result.taskJson);
                    setTaskSettings(json);
                    //debugger;
                    if (json.isQr) {
                        setTask({
                            id: result.id,
                            sectionContentId: result.sectionContentId,
                            description: result.description,
                            title: result.title,
                            type: result.type,
                            taskJson: JSON.parse(result.taskJson),
                            fileUri: result.fileUri,
                            mimeType: result.mimeType,
                            tenantId: result.tenantId,
                            time: result.time,
                            showQr: true,

                        });
                        setQrValue(window.location.origin + "/course/" + props.moduleId + "/" + result.sectionContentId + "/true");
                    }
                }
            }
        });
    }

    const onComplate = (content) => {
        props.contentDetailClosed(content);
        props.getAccessModel();
    }

    const closePage = () => {
        onComplate({ resultCode: -1 });
    }

    const onImageSelect = (imgs) => {
        ////console.log(imgs);
        setContentImage(imgs[0]);
    }

    const handleTabChange = (e, data) => {
        let index = data.activeIndex;
    
        setActiveIndex(index);
        if (index == 0) {
            setContent({ ...content, contentType: 1 });
        } else if (index == 1) {
            setContent({ ...content, contentType: 2 });
        } else if (index == 2) {
            setContent({ ...content, contentType: 4 });
        } else if (index == 3) {
            setContent({ ...content, contentType: 8 });
        } else if (index == 4) {
            setContent({ ...content, contentType: 15 });
        } else if (index == 5) {
            setContent({ ...content, contentType: 16 });
        }
    }

    const handleTaskTabChange = (e, data) => {
        let index = data.activeIndex;
        setTaskPaneActiveIndex(index);
        if (index == 0) {
            setTask({ ...task, type: 1, description: "" });
        } else if (index == 1) {
            setTask({ ...task, type: 2, description: "" });
        } else if (index == 2) {
            setTask({ ...task, type: 4, description: "" });
        } else if (index == 3) {
            setTask({ ...task, type: 8, description: "" });
        } else if (index == 4) {
            setTask({ ...task, type: 15, description: "" });
        }
    }

    const getRoles = () => {
        NetworkRequest('/api/UserRole/GetResponsibleRoles').then((response) => {
            if (response.data.resultCode == 0) {
                setRoles(response.data.detail);
            }
        });
    }

    const checkAndSetRateEnabled = () => {
        if (content.details != undefined) {
            var json = JSON.parse(content.details);
            if (json.isRateEnabled != undefined && isRateEnabled != json.isRateEnabled) {
                setIsRateEnabled(json.isRateEnabled)
            }
        }
    }

    const setContentDetails = (propName, data) => {
        let obj: any = {};
        if (content.details == undefined) {
            obj[propName] = data;
        } else {
            obj = JSON.parse(content.details);
            obj[propName] = data;
        }
        setContent({ ...content, details: JSON.stringify(obj) });
        if (propName == "isRateEnabled") {
            setIsRateEnabled(data);
        }
    }

    const getAccessEventDisplayName = (id, value) => {
        let control = _.find(accessibilityEvents, (event) => {
            return event?.accessibilityEventId == id;
        });
        if (control != undefined) {
            return control.displayName.replace("X", value);
        }
        return "";
    }

    useEffect(() => {
        if (props.id != "") {
            let req = {
                id: props.id
            }

            const runner = setTimeout(() => {
                NetworkRequest("/api/SectionContent/GetById", req).then((response) => {
                    let result = response.data.detail.content;
                    if (result != undefined) {
                        setStartAccessRule(response.data.detail.startAccessRule);
                        setFinishAccessRule(response.data.detail.finishAccessRule);
                        /*    debugger;*/
                        setContent({
                            ...content,
                            contentType: result.contentType,
                            moduleSectionId: result.moduleSectionId,
                            coverPhoto: result.coverPhoto,
                            description: result.description,
                            responsibleRoleId: result.responsibleRoleId,
                            details: result.details,
                            id: result.id,
                            rank: result.rank,
                            title: result.title,
                        });
                        if (result.contentType == 1) {
                            setActiveIndex(0);
                        } else if (result.contentType == 2) {
                            setActiveIndex(1);
                        } else if (result.contentType == 4) {
                            setActiveIndex(2);
                        } else if (result.contentType == 8) {
                            setActiveIndex(3);
                        } else if (result.contentType == 15) {
                            setActiveIndex(4);
                        }
                    }

                });
            }, 1000);

            return () => clearTimeout(runner);
            
        }
        getRoles();
        if (props.showNoti == true) {
            setPage(2);
        } else {
            setPage(1);
        }
    }, [props.id]);


    useEffect(() => {
        if (content.id == "" || content.rank == null) {
            return;
        }
        let contentData = {
            id: content.id
        }
        checkAndSetRateEnabled();


        const contentTimer = setTimeout(() => {
            switch (content.contentType) {
                case 1: {
                    getVideoDataByContentId(contentData);
                    break;
                }
                case 2: {
                    getHtmlDataByContentId(contentData);
                    break;
                }
                case 4: {
                    getQuizDataByContentId(contentData);
                    break;
                }
                case 15: {
                    getTaskDataByContentId(contentData);
                    break;
                }
                default: {
                    break;
                }
            }
        }, 1000);

        return () => clearTimeout(contentTimer)



    }, [content]);

    useEffect(() => {
        console.log("scorm file", scormFile);
        try {
            if (startAccessRule != undefined
                && finishAccessRule != undefined
                && checkFieldsIsFilled(content.contentType, content.responsibleRoleId, content.title, startAccessRule?.accessibilityEventId, finishAccessRule?.accessibilityEventId)

            ) {

                if (content.contentType == 1
                    && video != undefined
                    && (
                        video.videoType == undefined || !(video.videoType == 1 || video.videoType == 2 || video.videoType == 4 || video.videoType == 5)
                        || (video.videoType == 1 && (video.uri == undefined || video.uri == '' || video.uri == null))
                        || (video.videoType == 2 && videoUrl == "" && video.uri == "")
                        || (video.videoType == 4 && (video.embedCode == undefined || video.embedCode == '' || video.embedCode == null))
                        || (video.videoType == 5 && (videoList == undefined || videoList.length == 0))
                    )
                ) {
                    setIsFormOk(false);
                } else if (content.contentType == 2) {
                    if (html.assignTime) {
                        setIsFormOk(checkFieldsIsFilled(html.embedCode, html.time))
                    } else {
                        setIsFormOk(checkFieldsIsFilled(html.embedCode))
                    }
                } else if (content.contentType == 4) {
                    setIsFormOk(quiz.id != "")
                } else if (content.contentType == 8) {
                    setIsFormOk(isSurveyAdded)
                } else if (content.contentType == 15) {
                    if (task.type == 1 || task.type == 8 || task.type == 15) {
                        setIsFormOk(checkFieldsIsFilled(task.description))
                    } else if (task.type == 4) {
                        setIsFormOk(isTaskActivityAdded);
                    } else if (task.type == 2) {
                        if (task.fileUri != "" || taskDocFile != "") {
                            setIsFormOk(true);
                        }
                    }
                }
                //scorm
                else if (content.contentType == 16) {
                    if (scormFile != "" && scormUrl != "")
                        setIsFormOk(true);
                }
                else {
                    setIsFormOk(true);
                }
            }
        } catch (e) {
            ////
        }
        ////console.log("content changed: ", content);
    }, [content, settings, startAccessRule, finishAccessRule, content.contentType, video, html, task, survey, quiz, activity, taskDocFile, quiz.id, isTaskActivityAdded, isSurveyAdded, videoUrl, videoList, scormFile]);

    useEffect(() => {
        if (tenantId == undefined) {
            return;
        }
        setVideo({ ...video, tenantId: tenantId });
        setHtml({ ...html, tenantId: tenantId });
        setTask({ ...task, tenantId: tenantId });
        setQuiz({ ...quiz, tenantId: tenantId });
        setSurvey({ ...survey, tenantId: tenantId });
    }, [tenantId]);

    useEffect(() => {
        //console.log(video.videoType);
    }, [video.videoType]);

    useEffect(() => {
        if (video != undefined) {
            //console.log(video);
            //console.log(parse(video.embedCode));
        }
    }, [video.embedCode]);

    useEffect(() => {
        if (video.videoType == 2)
            getActiveLanguages();
    }, [video.videoType]);

    useEffect(() => {
        if (progressGallery == 100)
            setProgressText("Lütfen Bekleyin...")
    }, [progressGallery]);

    return (
        <>
            {page == 1
                &&
                <>
                    <div className={"contentAddHeaderContainer"}>
                        <div>
                            <Header style={{ marginBottom: "3%" }} as={"h2"}>{translate("İçerik Ekle")}</Header>
                        </div>
                        <div>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.setShowModuleAddSectionId("");
                                    props.setShowUpdateContentId("");
                                }}
                                className={"roundedButton"}
                                color={"red"}
                            >{translate("Kapat")}
                            </Button>
                        </div>
                    </div>

                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h3'>{translate("İçerik Adı")}</Header>
                                <Input
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    size={"big"}
                                    className={"greyInput"}
                                    autoFocus
                                    value={content.title}
                                    name="title"
                                    required="required"
                                    style={styles.inputStyle}
                                    placeholder={translate("İçerik Adı")}
                                    onChange={contentHandleChange}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header as='h3'>{translate("Sorumlu")}</Header>
                                            <Dropdown
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                                className={"greyInput"}
                                                value={content.responsibleRoleId}
                                                name="responsibleRoleId"
                                                required="required"
                                                clearable={true}
                                                options={props.dropdownRoles}
                                                selection
                                                placeholder={translate("Sorumlu Seçiniz")}
                                                onChange={(e, data) => {
                                                    setContent({ ...content, responsibleRoleId: data.value?.toString() });
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h3'>{translate("Başlangıç")}</Header>
                                <Dropdown
                                    className={"greyInput"}
                                    style={styles.inputStyle}
                                    name="startOption"
                                    required="required"
                                    clearable={true}
                                    options={dropdownStartAccessibilityEvents}
                                    selection
                                    placeholder={translate("Başlangıç")}
                                    onChange={(e, data) => {
                                        rulesChangeDropdown(e, data, true);
                                    }}
                                    value={startAccessRule?.accessibilityEventId}
                                />
                                {startAccessRule.valueType == 2
                                    &&
                                    <Input
                                        className={"greyInput"}
                                        autoFocus
                                        timezone="[[timezone]]"
                                        value={startAccessRule.value}
                                        name="startDate"
                                        required="required"
                                        type="date"
                                        size="big"
                                        style={{ ...styles.inputStyle, marginTop: ".5em" }}
                                        placeholder='Başlangıç Tarihi'
                                        onChange={(e) => {
                                            setStartAccessRule({
                                                ...startAccessRule,
                                                value: e.target.value,
                                                displayName: e.target.value
                                            });
                                        }}
                                    />
                                }
                                {startAccessRule.valueType == 1
                                    &&
                                    <Input
                                        className={"greyInput"}
                                        autoFocus
                                        value={startAccessRule.value}
                                        name="delayDayOfStartDate"
                                        type="number"
                                        min="0"
                                        size="big"
                                        style={{ ...styles.inputStyle, marginTop: ".5em" }}
                                        placeholder={translate('Değer Giriniz')}
                                        onChange={(e) => {
                                            setStartAccessRule({
                                                ...startAccessRule,
                                                value: e.target.value,
                                                displayName: getAccessEventDisplayName(startAccessRule?.accessibilityEventId, e.target.value)
                                            });
                                        }}
                                    />
                                }
                            </Grid.Column>
                            <Grid.Column>
                                <Header as='h3'>{translate("Bitiş")}</Header>
                                <Dropdown
                                    className={"greyInput"}
                                    style={styles.inputStyle}
                                    name="finishOption"
                                    required="required"
                                    clearable={true}
                                    options={dropdownFinishAccessibilityEvents}
                                    selection
                                    placeholder={translate("Bitiş")}
                                    onChange={(e, data) => {
                                        rulesChangeDropdown(e, data, false);
                                    }}
                                    value={finishAccessRule?.accessibilityEventId}
                                />
                                {finishAccessRule.valueType == 2
                                    &&
                                    <Input
                                        className={"greyInput"}
                                        autoFocus
                                        timezone="[[timezone]]"
                                        value={finishAccessRule.value}
                                        name="contentFlowFinishEventValue"
                                        required="required"
                                        type="date"
                                        size="big"
                                        style={styles.inputStyle}
                                        placeholder={translate('Bitiş Tarihi')}
                                        onChange={(e) => {
                                            setFinishAccessRule({
                                                ...finishAccessRule,
                                                value: e.target.value,
                                                displayName: e.target.value
                                            });
                                        }}
                                    />
                                }
                                {finishAccessRule.valueType == 1
                                    &&
                                    <Input
                                        className={"greyInput"}
                                        autoFocus
                                        value={finishAccessRule.value}
                                        name="contentFlowFinishEventValue"
                                        type="number"
                                        min="0"
                                        size="big"
                                        style={{ ...styles.inputStyle, marginTop: ".5em" }}
                                        placeholder={translate('Değer Giriniz')}
                                        onChange={(e) => {
                                            setFinishAccessRule({
                                                ...finishAccessRule,
                                                value: e.target.value,
                                                displayName: getAccessEventDisplayName(finishAccessRule?.accessibilityEventId, e.target.value)
                                            });
                                        }}
                                    />
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h3'>{translate('Açıklama')}</Header>
                                <TextArea rows={3}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    className={"greyInput"}
                                    value={content.description}
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setContent({ ...content, description: e.target.value })
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Header as='h3'>{translate("İçerik Değerlendirme")}</Header>
                                <Checkbox
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    toggle
                                    checked={isRateEnabled}
                                    onChange={(e, data) => {
                                        setContentDetails("isRateEnabled", data.checked)
                                    }}
                                    label={translate("İçerik değerlendirilebilsin mi?")}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            }
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Tab
                           
                            panes={panes}
                           
                            activeIndex={activeIndex}
                           
                            onTabChange={(e, data) => {
                                handleTabChange(e, data)
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid columns={2}>
                <Grid.Row>
                    <div style={{ width: "100%", textAlign: "right" }}>
                        <Button className={"roundedButton"} color="grey" onClick={closePage}>
                            <Icon name='angle left' />{translate("Geri Dön")}
                        </Button>


                        {content.id == undefined
                            &&
                            <Button className={"roundedButton"} color={"green"}
                                onClick={addContent}
                                disabled={!isFormOk}
                            >
                                <Icon name='add' />
                                {translate("Ekle")}
                            </Button>
                        }
                        {content.id != undefined
                            &&
                            <Button className={"roundedButton"} color={"green"}
                                onClick={updateContent}
                                disabled={!isFormOk}
                            >
                                <Icon name='add' />
                                {translate("Güncelle")}
                            </Button>
                        }


                    </div>


                </Grid.Row>
            </Grid>
            <Modal
                open={showVideoGalleryPreview}
                centered={false}
                size={'fullscreen'}
                onClose={() => setShowVideoGalleryPreview(false)}
                onOpen={() => setShowVideoGalleryPreview(true)}

            >
                <Modal.Header>{translate("Video Galeri Ön İzleme")}</Modal.Header>
                <Modal.Content>
                    <VideoGallery
                        isPreview={true}
                        videoList={typeof videoList == 'string' ? JSON.parse(videoList).filter(x => x.isActive) : videoList.filter(x => x.isActive)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowVideoGalleryPreview(false)}>{translate("Kapat")}</Button>
                </Modal.Actions>
            </Modal>

        </>
    )
}

export default ContentAdd;
