import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import parse from "html-react-parser";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { media, style } from 'typestyle';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import TranslateHelper from '../../helper/TranslateHelper';
import { base64toBlob, scrollToTop } from "../../helper/HelperFunctions";
import VideoGallery from '../VideoGallery';

function RenderContentVideo(props) {
    var { translate } = TranslateHelper();
    const [coutdownFinished, setCoutdownFinished] = useState(false);
    const [isTabActive, setIsTabActive] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isTimeAssigned, setIsTimeAssigned] = useState(false);
    const [content, setContent] = useState(props.content);
    const [moduleId] = useState(props.moduleId);
    const [video, setVideo] = useState(props.content.data.contentVideoView);

    const [canComplate, setCanComplate] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const videoRef = React.useRef<any>(null);
    const playerRef = React.useRef(null);
    const [videoJsOptions, setVideoJsOptions] = useState(
        {
            autoPlay: false,
            controls: true,
            responsive: true,
            fluid: true,
            enableSourceset: true,
            preload: "auto",
            attributes: {
                controlsList: 'nodownload'
            },
            sources: [{
                src: video?.uri,
                type: 'video/mp4'
            }],

            playbackRates: [0.5, 1, 1.25, 1.5, 2],
            tracks: props.content.data.contentVideoView.subtitleListView.map((x) => (
                {
                    mode: 'showing',
                    kind: "captions",
                    label: x.flag,
                    language: x.flag,
                    src: URL.createObjectURL(base64toBlob(x.subtitleUrl, x.mimeType))
                })),

        }
    );
    //tracks: [{ mode: 'showing', kind: "captions", label: "English", language: "en", src:"https://kot-politiken.s3-eu-west-1.amazonaws.com/2019/114_en.vtt.txt" }] ,


    const stopVideo = (video) => {

        if (video == undefined)
            return;
        if (!video.stopWhenTabChanges)
            return;

        switch (video.videoType) {
            case 1:
                setIsTabActive(false);
                break;
            case 2:
                setIsTabActive(false);
                break;
            case 4:
                // embed videolarda videoyu durdurma seçeneği olmayacak
                //var iframe = document.querySelector('iframe');
                //if (iframe == null)
                //    return;
                ////console.log(iframe);
                //var src = iframe.getAttribute('src')
                //iframe.setAttribute('src', (src != null ? src : ""));
                break;
        }
    }

    const autoPlayIframe = () => {
        if (video.videoType == 4 && video.autoPlay) {
            var iframe = document.querySelector('iframe');
            if (iframe == null)
                return;
            var symbol = iframe.src.indexOf("?") > -1 ? "&" : "?";
            iframe.setAttribute('src', iframe.getAttribute('src') + symbol + "autoplay=1");
        }
    }

    const videoEnd = () => {
        if (isTimeAssigned)
            return;
        complateContent(true);
    }

    const complateContent = (isSuccess) => {
        props.onComplete();
        if (isSuccess) {
            setCoutdownFinished(true)
            setCanComplate(false);
        }

        props.setIsOpenTaskContentForMobile(true);
    }

    let styles = {
        videoContainerStyle: {
            justifyContent: "space-around",
            alignItems: "center",

            display: "flex",
            flexDirection: "column" as "column",
            gap: "1rem"
        },
        complateButton: {
            marginTop: "2rem",
            width: "90%",
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center" as "center",
            gap: "1rem"
        },

        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            borderRadius: "8px"
        },
        player: style({
            width: "100%",
            height: "100%"
        },
            media({ maxWidth: 820 }, { width: "auto !important", height: "auto !important" })
        ),
    }

    const children = (remainingTime) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60;
        let secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;
        let minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${minutesString}.${secondsString}`;
    }

    useEffect(() => {
        try {
            let c = props.content;
            setContent(c);
            let v = props.content.data.contentVideoView;
            setVideo(v != undefined ? v : undefined);
            //if (c.status != undefined && c.status.isCompleted == false && v.videoType == 4) {
            //    setCanComplate(true);
            //}
            let time = props.content.data.contentVideoView.time;
            setCoutdownFinished(props?.content?.status != undefined && props?.content?.status?.isCompleted == true);

            if (time != "" && time != "0" && time != undefined && time != null) {
                setIsTimeAssigned(true);

                setMinutes(time);
                setSeconds(0);
            } else {
                setIsTimeAssigned(false);
                ////debugger;
            }
        } catch (e) {
            ////
        }

    }, [props]);


    useEffect(() => {

        //Appointment of Scroll at the top when the content changes
        scrollToTop();

        setInterval(checkFocus, 200);

        function checkFocus() {
            if (document.hasFocus() == false) {
                stopVideo(video);
            } else {
                setIsTabActive(true);
            }
        }
    }, []);

    useEffect(() => {
        if (video == undefined)
            return;
        setIsPlaying(isTabActive ? video.autoPlay : false)
    }, [isTabActive]);

    useEffect(() => {
        var player: HTMLVideoElement = document.getElementById("vjs_video_3_html5_api") as HTMLVideoElement;
        if (player == undefined || player == null) {
            player = document.getElementsByClassName("vjs-tech")[0] as HTMLVideoElement;
            if (player == undefined || player == null)
                return;
        }
        //try {
        //    if (!isTabActive) {
        //        player.pause();
        //    }
        //    else {
        //        player.play();
        //    }
        //} catch (e) {
        //    //console.log(e)
        //}

    }, [isPlaying]);

    useEffect(() => {
        setVideoJsOptions({
            ...videoJsOptions,

            sources: [{
                src: video?.uri,
                type: 'video/mp4'
            }]
        })

    }, [video]);


    useEffect(() => {

        if (video.videoType != 5) {
            //debugger;
            setVideoJsOptions({
                ...videoJsOptions,
                autoPlay: video?.autoPlay,
                sources: [{
                    src: video?.uri,
                    type: 'video/mp4'
                }],
            });
        } else {
            var list = typeof video.uriList == 'string' ? JSON.parse(video.uriList) : video.uriList
            var srcs = list.map((x) => ({ src: x.fileUrl, type: 'video/mp4' }))

            setVideoJsOptions({
                ...videoJsOptions,
                autoPlay: video?.autoPlay,
                sources: srcs,

            })

        }
        if (video != undefined && video?.autoPlay) {
            setTimeout(() => {
                let player: HTMLVideoElement = document.getElementById("vjs_video_558_html5_api") as HTMLVideoElement;

                if (player == undefined || player == null) {
                    player = document.getElementsByClassName("vjs-tech")[0] as HTMLVideoElement;
                    if (player == undefined || player == null)
                        return;
                }
                //try {
                //    player.play();
                //} catch (e) {
                //    //
                //}
            }, 1000)
        }
    }, [video]);
    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
                videojs.log('player is ready');
                //onReady && onReady(player);
            });

            player.on('timeupdate', () => {
                //console.log("player.currentTime: ", player.currentTime());
            });
        }

        //console.log("videoJsOptions", videoJsOptions);
    }, [videoJsOptions, videoRef]);


    return (
        <div style={styles.videoContainerStyle} >
            {props.isLocked != true &&
                <>
                    <div className="videoContainer allPlayers" style={{
                        width: "100%", height: "100%"
                    }}>
                        {video != undefined && video.videoType == 1
                            &&
                            <ReactPlayer controls={true} url={video.uri} playing={isPlaying}
                                //onPlay={() => complateContent(false)}
                                onEnded={() => videoEnd()}
                                className={styles.player}

                            />
                        }
                        {video != undefined && (video.videoType == 2)
                            &&
                            //<ReactPlayer controls url={video.uri} playing={isPlaying}

                            //    onEnded={() => videoEnd()}
                            //    //onPlay={() => complateContent(false)}
                            //    className={styles.player}
                            ///>

                            <div className="fontVideoJS">
                                <div data-vjs-player >
                                    <video ref={videoRef}
                                        onContextMenu={(e) => { e.preventDefault() }}
                                        className='video-js vjs-big-play-centered vjs-matrix'
                                        onEnded={(e) => { videoEnd() }}
                                    >

                                    </video>
                                </div>
                            </div>
                        }
                        {video != undefined && (video.videoType == 5)
                            &&
                            <VideoGallery
                                isPreview={false}
                                videoEnd={complateContent}
                                videoList={typeof video.uriList == 'string' ? JSON.parse(video.uriList).filter(x => x.isActive) : video.uriList.filter(x => x.isActive)} />
                        }



                        {video != undefined && video.videoType == 4
                            &&
                            parse(video.embedCode)
                        }
                    </div>
                    {video != undefined && video.videoType == 4
                        &&
                        autoPlayIframe()
                    }
                    {(video != undefined && (video.time != null && video.time.replace(" ", "") != "") && canComplate == false && !coutdownFinished) &&

                        <CountdownCircleTimer
                            isPlaying
                            duration={minutes * 60 + seconds}

                            onComplete={() => {
                                setCanComplate(true)
                            }}
                            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                            colorsTime={[10, 5, 3, 0]}
                            size={100}
                        >
                            {({ remainingTime }) => children(remainingTime)}
                        </CountdownCircleTimer>
                    }

                    {(video != undefined && canComplate == true)
                        &&
                        <Button
                            circular={true}
                            color={"green"}
                            size="big"
                            onClick={() => complateContent(true)}
                            disabled={!canComplate}
                        >
                            {translate("Tamamla")}
                        </Button>
                    }
                </>
            }
        </div>
    )
}

export default RenderContentVideo;