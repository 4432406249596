import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Icon, Button, Transition, Modal, Table, Loader, Popup, Card } from 'semantic-ui-react';
import axios from 'axios';

import { toast } from 'react-toastify';
import DoneImage from "../images/visual_done.png";
import TranslateHelper from '../helper/TranslateHelper';
import { DataHandler } from '../helper/DataHelper';
import { NetworkRequest } from '../helper/NetworkRequest';

export const DropZone = (props: any) => {
    var {translate} = TranslateHelper();
    const [selectedFiles, setSelectedFiles] = useState<Array<IItem>>([]);
    const [validFileTypes] = useState(['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv']);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState<Array<IItem>>([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState<Array<IItem>>([]);
    const fileInputRef = useRef<any>();
    const [IsMessage, setIsMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageHeader, setMessageHeader] = useState("");
    const [startImport, setStartImport] = useState(false);
    const [showUnSuccessList, setShowUnSuccessList] = useState(false);
    const [unSuccessImported, setUnSuccessImported] = useState<Array<any>>([]);
    const [borderColor, setBorderColor] = useState("#ccc");
    const [properties] = useState(props.dropZoneProps);
    const [dataNameLower] = useState(properties.dataName.toLowerCase());
    const [dataName] = useState(properties.dataName);

    interface IItem {
        name: string;
        type: string;
    }

    useEffect(() => {
        if (selectedFiles != undefined) {
            var filtered = selectedFiles.filter((file) => {
                return validFileTypes.indexOf(file.type) != -1
            });
            setValidFiles([...filtered]);
        }
    }, [selectedFiles]);

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i])) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                // add a new property called invalid
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage('File type not permitted');
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    const uploadFiles = () => {
        setStartImport(true);
        if (validFiles.length === 1) {
            toast(translate("Yükleniyor, Lütfen bekleyin..."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 1000000 });
            let store = DataHandler.getStore();
            var formData = new FormData();
            formData.append('Data', validFiles[0] as any);
            formData.append('TenantId', DataHandler.getUserDataWithKey("tenantId"));



            //let checkData = {
            //    id: contentId
            //}

            NetworkRequest(properties.links.uploadLink, formData).then((response) => {
                toast.dismiss();
                if (response.data.resultCode == 0) {
                    if (properties.redirectToPage) {
                        window.location.href = properties.links.returnLink;
                        return;
                    }
                    let successImportedUser = response.data.detail.item1.length;
                    let unSuccessImportedUser = response.data.detail.item2.length;
                    if (response.data.detail.item3 != undefined)
                        setUnSuccessImported([...response.data.detail.item3, ...response.data.detail.item2]);
                    else
                        setUnSuccessImported(response.data.detail.item2);
                    setStartImport(false);
                    if (unSuccessImportedUser > 0 && successImportedUser > 0) {
                        setMessageHeader(translate("Başarılı"));
                        setMessage(successImportedUser + " " + dataNameLower + " içeri aktarıldı. Ama " + unSuccessImportedUser + " " + dataNameLower + " içeri aktarılamadı");
                        setShowUnSuccessList(true);
                    } else {
                        if (successImportedUser > 0) {
                            setMessageHeader(translate("Başarılı"));
                            setMessage(successImportedUser + " " + dataNameLower + translate(" içeri aktarıldı"));
                            setShowUnSuccessList(false);
                        } else {
                            setMessageHeader(translate("Uyarı"));
                            setMessage("Hiçbir " + dataNameLower + " içe aktarılamadı");
                            setShowUnSuccessList(true);
                        }
                    }
                    setIsMessage(true);
                } else {
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                }
            });







            //axios({
            //    method: 'POST',
            //    url: properties.links.uploadLink,
            //    data: formData,
            //    headers: {
            //        'Authorization': store != undefined ? store.token : undefined
            //    }
            //}).then((response: any) => {
            //    toast.dismiss();
            //    if (response.data.resultCode == 0) {
            //        if (properties.redirectToPage) {
            //            window.location.href = properties.links.returnLink;
            //            return;
            //        }
            //        let successImportedUser = response.data.detail.item1.length;
            //        let unSuccessImportedUser = response.data.detail.item2.length;
            //        if (response.data.detail.item3 != undefined)
            //            setUnSuccessImported([...response.data.detail.item3, ...response.data.detail.item2]);
            //        else
            //            setUnSuccessImported(response.data.detail.item2);
            //        setStartImport(false);
            //        if (unSuccessImportedUser > 0 && successImportedUser > 0) {
            //            setMessageHeader(translate("Başarılı"));
            //            setMessage(successImportedUser + " " + dataNameLower + " içeri aktarıldı. Ama " + unSuccessImportedUser + " " + dataNameLower + " içeri aktarılamadı");
            //            setShowUnSuccessList(true);
            //        } else {
            //            if (successImportedUser > 0) {
            //                setMessageHeader(translate("Başarılı"));
            //                setMessage(successImportedUser + " " + dataNameLower + translate(" içeri aktarıldı"));
            //                setShowUnSuccessList(false);
            //            } else {
            //                setMessageHeader(translate("Uyarı"));
            //                setMessage("Hiçbir " + dataNameLower + " içe aktarılamadı");
            //                setShowUnSuccessList(true);
            //            }
            //        }
            //        setIsMessage(true);
            //    } else {
            //        toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            //    }
            //});
        } else {
            setIsMessage(true);
            setMessageHeader(translate("Uyarı"));
            setMessage(translate("Lütfen yalnızca bir adet excel dosyası seçiniz"));
        }
    }

    const modalOkayEvent = () => {
        if (messageHeader == "Uyarı") {
            setIsMessage(false);
        } else {
            if (messageHeader == "Başarılı" && unSuccessImported.length == 0) {
                window.location.href = properties.links.returnLink;
            }
        }

        if (messageHeader == "Başarılı") {
            window.location.href = properties.links.returnLink;
        }
    }

    const fileInputClicked = (): any => {
        fileInputRef.current.click();
    }

    const filesSelected = (): any => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    const validateFile = (file) => {

        ////console.log(file);
        const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }

    const removeFile = (name) => {
        // find the index of the item
        // remove the item from array

        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array

        setSelectedFiles([...selectedFiles]);


        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }

    let styles = {
        dropZoneContainer: {
            height: "100%",
            cursor: "pointer",
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column",
            padding: "4rem 2rem"
        },

        dropContainer: {
            display: "flex",
            float: "left" as "left",
            alignItems: "center",
            justifyContent: "center",
            margin: "0",
            width: "100%",
            height: "350px",
            border: "9px dashed",
            borderColor: borderColor,
            borderRadius: "20px",
            transition: "border 0.2s",

            "&:hover": {
                border: "9px dashed #4aa1f3"
            }
        },

        uploadIcon: {
            textAlign: "center" as "center",
            margin: "0 auto",
            paddingTop: "10px"
        },

        dropMessage: {
            textAlign: "center" as "center",
            color: borderColor,
            fontWeight: "bold" as "bold",
            fontFamily: "Arial",
            fontSize: "1.5em",
            transition: "all 0.2s"
        },

        uploadTextField: {
            marginTop: "20px"
        },


        fileDisplayContainer: {
            display: "flex" as "flex",
            flexDirection: "column" as "column",
            float: "left" as "left",
            width: "100%"
        },


        fileStatusBar: {
            width: "100%",
            verticalAlign: "top",
            marginTop: "10px",
            marginBottom: "20px",
            position: "relative" as "relative",
            lineHeight: "50px",
            height: "50px"
        },


        fileStatusBarDiv: {
            overflow: "hidden"
        },


        fileName: {
            display: "inline-block",
            verticalAlign: "top",
            marginLeft: "30px",
            color: "#4aa1f3",
            fontSize: "1em"
        },


        fileError: {
            display: "inline-block",
            verticalAlign: "top",
            marginLeft: "50px",
            color: "#9aa9bb"
        },


        fileErrorMessage: {
            color: "red"
        },


        fileTypeLogo: {
            width: "100px",
            height: "100px",
            backgroundSize: "100%",
            position: "absolute" as "absolute"
        },


        fileSize: {
            display: "inline-block",
            verticalAlign: "top",
            color: "#30693d",
            marginLeft: "10px",
            marginRight: "5px",
            fontWeight: "bold" as "bold",
            fontSize: "14px"
        },


        uploadFileDropZone: {
            display: "flex",
            justifyContent: "space-between"
        },


        uploadFileFirstSection: {
            display: "flex",
            justifyContent: "flex-start"
        },


        fileUploadBtn: {
            padding: "8px 15px",

            float: "left",
            width: "40%"
        },


        fileInput: {
            display: "none"
        },


        uploadFileWarningMessage: {
            float: "left" as "left"
        },


        dropZoneImportingGif: {
            float: "left",
            marginTop: "-7%",
            display: "flex"
        },


        unSuccessImportUsers: {
            display: "flex",
            flexDirection: "row" as "row"
        },


        unSuccessTableHeader: {
            display: "flex",
            flexDirection: "row" as "row"
        },


        unSuccessTableItem: {
            width: "100px",
            marginRight: "10px",
            fontWeight: "bold" as "bold"
        },


        messageModal: {
            top: "5%",
            left: "25%",
            height: "auto"
        },


        unSuccessItem: {
            marginRight: "10px",
            width: "100px"
        },

        templateFileButton: {
            padding: ".7rem 3rem",
            borderRadius: "15px",
            border:"none",
            top: "20px",
            display: "flex",
            flexDirection: "row" as "row",
            width: "40%",
            textAlign: "center" as "center",
            backgroundColor: "antiquewhite",
            marginTop:"2%"
        },
        downloadContainer: {
            display: 'flex',
            flexDirection: "column" as "column",
            alignItems: "center",
            width: "100%"
        }

    }

    useEffect(() => {
        ////console.log(selectedFiles);
    }, [selectedFiles]);

    useEffect(() => {
        //console.log(properties);
    }, [properties]);

    return (
        <div style={styles.dropZoneContainer}>
            <img src={DoneImage} hidden/>
            <Transition
                visible={IsMessage}
                animation='scale'
                duration={500}
            >
                <Modal
                    style={styles.messageModal}
                    open={IsMessage}
                    onClose={() => setIsMessage(false)}
                >
                    <Modal.Header className="width100 thisIsCenter">{messageHeader}</Modal.Header>
                    <Modal.Content
                        image
                        scrolling
                    >
                        <Modal.Description style={{ width: "100%" }}>
                            <div className="width100 thisIsCenter">
                                {messageHeader == "Başarılı"
                                    &&
                                    <img src={DoneImage} />
                                }
                            </div>
                            <div className="width100 thisIsCenter">
                                <h1>{message}</h1>
                            </div>
                            {unSuccessImported && showUnSuccessList
                                &&
                                <div>
                                    <h3>Aktarılamayan {dataNameLower} listesi</h3>
                                    <Table basic='very'>
                                        <Table.Header>
                                            <Table.Row>
                                                {properties.table.headers.map((item) =>
                                                    <Table.HeaderCell>{item}</Table.HeaderCell>
                                                )}
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {unSuccessImported.map((item: any, index) =>
                                                <Table.Row key={index}>
                                                    {properties.table.propertyNames.map((propertyName) =>
                                                        <Table.Cell>{item[propertyName]}</Table.Cell>
                                                    )}
                                                    <Table.Cell>
                                                        {properties.messages[item.errorMessage]}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                </div>
                            }
                            <div className="width100 thisIsCenter">
                                <Button
                                    color="grey"
                                    onClick={() => setIsMessage(false)}
                                    className="roundedButton"
                                >
                                    <Icon name="chevron left" />
                                    {translate("Geri Dön")}
                                    
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={modalOkayEvent}
                        >
                            {translate("Kapat")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Transition>
            <div
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
                style={styles.dropContainer}
                onMouseEnter={() => setBorderColor("#4aa1f3")}
                onMouseLeave={() => setBorderColor("#ccc")}
            >

                {!startImport ?

                    <div style={styles.dropMessage}>
                        <div style={styles.uploadIcon}>
                            <Icon
                                size='massive'
                                name='file excel outline'
                            />
                        </div>
                        <div style={styles.uploadTextField}>
                            {translate("Excel Dosyanızı Buraya Sürükleyin")}
                            
                        </div>
                    </div>
                    :
                    <div style={styles.dropMessage}>
                        <Loader
                            style={styles.dropZoneImportingGif}
                            active={startImport}
                            size='huge'>
                            {translate("Aktarılıyor...")}
                        </Loader>
                    </div>
                }
                <input
                    ref={fileInputRef}
                    style={styles.fileInput}
                    type="file"
                    multiple
                    onChange={filesSelected}
                />

            </div>

            <div style={styles.fileDisplayContainer}>

                {
                    validFiles.map((data: any, i) =>

                        <div
                            style={styles.fileStatusBar}
                            key={i}
                        >
                            <div
                                style={styles.uploadFileDropZone}
                            >
                                <div style={styles.uploadFileFirstSection}>
                                    <div style={styles.fileTypeLogo}>
                                        <Icon
                                            size='big'
                                            name='file excel outline'
                                        />
                                    </div>

                                    <div>
                                        <span
                                            style={data.invalid ? styles.fileError : styles.fileName}
                                        >
                                            {data.name}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            style={styles.fileSize}
                                        >
                                            ({fileSize(data.size)})
                                        </span>
                                        {data.invalid &&
                                            <span
                                                style={styles.fileErrorMessage}
                                            >
                                                ({errorMessage})
                                            </span>
                                        }
                                    </div>

                                </div>
                                <div>
                                    <Button
                                        onClick={
                                            () => removeFile(data.name)
                                        }
                                        size='mini'
                                        className={"dangerRedButton"}
                                        icon
                                    >
                                        <Icon
                                            name='trash'
                                        />
                                    </Button>
                                </div>

                            </div>

                        </div>

                    )
                }

            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "1rem", alignItems: "center" }}>
                {(unsupportedFiles.length === 0 && validFiles.length > 0) &&
                    <Button
                        size="big"
                        
                        circular
                        className={"continueBlueButton"}
                        onClick={uploadFiles}
                    >
                        {translate("İçeri Aktar")}                        
                    </Button>
                }

                {unsupportedFiles.length ?
                    <p
                        style={styles.uploadFileWarningMessage}
                    >
                        {translate("Lütfen desteklenmeyen tüm dosyaları silin.")}
                    </p> :
                    ''
                }
                <div
                    style={styles.templateFileButton}
                >
                    <span style={styles.downloadContainer}>
                        <span>{translate("Excel şablon dosyasını indirmek için")}</span>

                        <a href={properties.excelTemplateFileLink} target="_blank" download>
                            {translate("tıklayınız")}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default DropZone;