import * as React from 'react';
import { useState, useEffect } from 'react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Checkbox, Form, TextArea } from 'semantic-ui-react';
import { ISurvey } from '../../../interfaces/interfaces';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../../helper/HelperFunctions';

import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const AddSurvey = (props) => {

    var { translate } = TranslateHelper();
    let { id } = useParams();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [loader, setLoader] = useState(true);
    const [surveyId] = useState<any>(id);
    const [survey, setSurvey] = useState<ISurvey>({
        id: "",
        title: "",
        description: "",
        isActive: true,
        settings: {
            canRepeat: false,
            dontForceForAnswer: false,
            navigateThroughQuestions: false,
            showAnswers: false,
            shuffleQuestionOrder: false
        }
    });

    const setSettings = (e, data) => {
        let settings = survey.settings;
        settings[data.name] = data.checked;
        setSurvey({ ...survey, settings: settings });

        ////console.log(settings);
        ////console.log(survey);
    }

    const checkActivityId = (surveyId: string) => {
        surveyId !== undefined ? getBySurveyId(surveyId) : setLoader(false);
    }

    const getBySurveyId = (surveyId) => {
        let request = {
            id: surveyId
        }
        NetworkRequest("/Surveys/GetById", request).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                let settings = JSON.parse(result.settingsJson);

                let settingsObject = [
                    { name: 'canRepeat', checked: settings.canRepeat },
                    { name: 'dontForceForAnswer', checked: settings.dontForceForAnswer },
                    { name: 'navigateThroughQuestions', checked: settings.navigateThroughQuestions },
                    { name: 'showAnswers', checked: settings.showAnswers },
                    { name: 'shuffleQuestionOrder', checked: settings.shuffleQuestionOrder }
                ];
                settingsObject.map(setting => {
                    setSettings(null, setting)
                })
                setSurvey({
                    ...survey,
                    id: result.id,
                    title: result.title,
                    description: result.description
                });
                setLoader(false);
            }
        });
    }


    const add = (redirectToQuestions) => {
        let request = {
            title: survey.title,
            isActive: survey.isActive,
            description: survey.description,
            settingsJson: JSON.stringify(survey.settings),
            tenantId: tenantId,
            createUserId: DataHandler.getUserDataWithKey("userId")
        }

        NetworkRequest("/Surveys/Add", request).then(response => {
            ////
            if (response.data.resultCode === 0) {
                if (redirectToQuestions == 3) {
                    window.location.href = "/surveyContentPool/" + response.data.detail.id;
                } else if (redirectToQuestions == 2) {
                    window.location.href = "/inquiryContentAdd/" + response.data.detail.id
                } else {
                    window.location.href = "/surveyList";
                }
            }
        });
    };

    const addAndAddQuestion = (redirectToQuestions) => {
        add(redirectToQuestions);
    }

    const update = (redirectToQuestions) => {
        let request = {
            id: survey.id,
            title: survey.title,
            isActive: survey.isActive,
            description: survey.description,
            settingsJson: JSON.stringify(survey.settings),
            tenantId: tenantId
        }

        NetworkRequest("/Surveys/Update", request).then(response => {
            ////
            if (response.data.resultCode === 0) {
                if (redirectToQuestions == 2) {
                    window.location.href = "/inquiryContentAdd/" + response.data.detail.id
                } else if (redirectToQuestions == 3) {
                    window.location.href = "/surveyContentPool/" + response.data.detail.id;
                } else {
                    window.location.href = "/surveyList";
                }
            }
        });
    }

    const updateAndAddQuestion = (redirectToQuestions) => {
        update(redirectToQuestions);
    }

    const handleChange = (e) => {
        setSurvey({
            ...survey,
            [e.target.name]: e.target.value
        });
    }

    const changeDropdown = (event, data) => {
        setSurvey({
            ...survey,
            [data.name]: data.value
        });
    }

    useEffect(() => {
        checkActivityId(surveyId);
    }, [surveyId]);

    let styles = {
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column"
        },

        flexDiv: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "baseline",
            gap: "1em",
            fontWeight: "bold" as "bold",
            flexDirection: "column" as "column"
        },

        w50: {
            width: "50%"
        },

        w60: {
            width: "60%"
        },
        w15: {
            width: "15%"
        },

        w30: {
            width: "30%"
        },
        w100: {
            width: "100%"
        },

        buttonGroup: {
            marginTop: "60px",
            alignSelf: "end"
        },
        form: {
            width: "100%",
            display: "flex",
            flexDirection: "row" as "row",
            gap: "2rem"
        }
    }

    const [isFormOk, setIsFormOk] = useState(false);
    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                survey.description,
                survey.title
            )
        );
    }, [survey]);


    useEffect(() => {
        if (surveyId != undefined)
            getBySurveyId(surveyId)
    }, [surveyId]);

    return (
        <div className={"generalContainerForWhiteBg"}>

            <div style={styles.container}>
                <Form size="big" style={styles.form}>
                    <div style={styles.w60}>
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Anket Adı: (*)")}</label>
                                <input
                                    className={"greyInput"}
                                    onChange={handleChange}
                                    name="title"
                                    style={styles.w100}
                                    placeholder={translate("Anket Adı")}
                                    value={survey.title}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Açıklama")} : (*)</label>
                                <TextArea
                                    className={"greyInput"}
                                    onChange={handleChange}
                                    name="description"
                                    style={styles.w100}
                                    placeholder={translate("Anket Açıklaması")}
                                    rows="12"
                                    value={survey.description}
                                />
                            </div>
                        </Form.Field>
                    </div>
                    <div>


                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={survey.settings.shuffleQuestionOrder}
                                    name="shuffleQuestionOrder"
                                    onChange={(e, data) => {
                                        setSettings(e, data);
                                    }
                                    }
                                    toggle
                                    label={translate("Soru Sırasını Karıştır")}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={survey.settings.canRepeat}
                                    name="canRepeat"
                                    onChange={(e, data) => {
                                        setSettings(e, data);
                                    }
                                    }
                                    toggle
                                    label={translate("Tekrara İzin ver")}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={survey.settings.showAnswers}
                                    name="showAnswers"
                                    onChange={(e, data) => {
                                        setSettings(e, data);
                                    }
                                    }
                                    toggle
                                    label={translate("Anket Sonunda Yanıtları Göster")}
                                />
                            </div>
                        </Form.Field>


                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={survey.settings.navigateThroughQuestions}
                                    name="navigateThroughQuestions"
                                    onChange={(e, data) => {
                                        setSettings(e, data);
                                    }
                                    }
                                    toggle
                                    label={translate("Çalışan Anket Esnasında Soruları Gezebilsin")}
                                />
                            </div>
                        </Form.Field>


                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={survey.settings.dontForceForAnswer}
                                    name="dontForceForAnswer"
                                    onChange={(e, data) => {
                                        setSettings(e, data);
                                    }
                                    }
                                    toggle
                                    label={translate("Çalışan Anket Sorularına Cevap Vermek Zorunda Olmasın")}
                                />
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={survey.isActive}
                                    name="isActive"
                                    onChange={(e, data) => {
                                        setSurvey({
                                            ...survey,
                                            isActive: data.checked != undefined ? data.checked : true
                                        });
                                    }
                                    }
                                    toggle
                                    label={translate("Aktif")}
                                />
                            </div>
                        </Form.Field>
                    </div>
                </Form>
                <div style={styles.buttonGroup}>
                    <Button className={"roundedButton"}
                        onClick={() => surveyId != null ? updateAndAddQuestion(2) : addAndAddQuestion(2)}
                        type='submit'
                        color='orange'
                        disabled={!isFormOk}
                    >
                        {translate("Kaydet ve Yeni Soru Ekle")}
                    </Button>
                    <Button className={"roundedButton"}
                        onClick={() => surveyId != undefined ? updateAndAddQuestion(3) : addAndAddQuestion(3)}
                        type='submit'
                        color='blue'
                        disabled={!isFormOk}
                    >
                        {translate("Kaydet ve Havuzdan Soru Seç")}
                    </Button>
                    <Button className={"roundedButton"}
                        onClick={() => surveyId != undefined ? updateAndAddQuestion(1) : addAndAddQuestion(1)}
                        type='submit'
                        color='green'
                        disabled={!isFormOk}
                    >
                        {translate("Kaydet")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default AddSurvey;