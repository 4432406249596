import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import { Button, Checkbox, Dropdown, Header, Icon, Input, Loader, Popup, Table, Transition } from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import { ICommonReportPage, IDataTable } from '../../../interfaces/interfaces'
import { downloadFile, prepareDataForTable } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Users {
    id?: string,
    title?: string,
    description?: string,
    createdDate?: Date
}

const UserReport = (props) => {

    var {translate} = TranslateHelper();
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [filterText, setFilterText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState<object>({});
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const collectiveProggress = (e, data) => {
        switch (data.value) {
            case 1:
                toast(translate("Toplu Sil"), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                break;
            case 2:
                toast(translate("Toplu Sil"), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                break;
            default:
        }
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink
            });
            setDataTable(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        setIsActive(data.checked);
    }

    const onChangeDeleted = (e, data) => {
        setIsDeleted(data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const userExcelReport = () => {
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        NetworkRequest("api/Report/GetUserReportToExcel", data).then((response) => {
            downloadFile(response.data, translate("Çalışan Raporu"));
        });
    }

    let tableHeader = [
        translate("Çalışan Adı"),
        translate("Adı"),
        translate("Soyadı"),
        translate("E Posta"),
        translate("Fonksiyon"),
        translate("Bölüm"),
        translate("Ünvan"),
        translate("Lokasyon"),
        translate("Aktif"),
        translate("İşlemler")
    ];
    let hiddenProps = [
        'id'
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(4, 0, translate("Şirket"));
    } else {
        hiddenProps.push("tenantName");
    }
    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "line graph",
                desc: translate("Rapor"),
                color: "orange",
                link: "/employeeReport",
                type: "report"
            },

        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        setRowSelected: setRowSelected,
        addRowLink: "",
        isAttachingPage: true,
    });

    //prepare data for table
    const prepareData = (data) => {

        //hidden column
        var model = prepareDataForTable(data.list, hiddenProps);
        setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                transactions: dataTable.transactions,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox,
                data: model,
                pagination: {
                    count: Math.ceil(
                        data.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });

            setIsLoading(false);
        }, 200)
    }

    //get table data from database
    const getUsers = (filterData?) => {

        NetworkRequest("api/User/GetReportUserList", filterData).then(response => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    useEffect(() => {
        ////console.log(dataTable);
    }, [dataTable]);

    //filter's every change
    useEffect(() => {
        setIsLoading(true);
        let filterDataObj = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1"
        }
        setFilterData(filterDataObj);
        getUsers(filterDataObj);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, props.selectedTenantId]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered' />
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable
                            dataTable={dataTable}
                            isAllSelected={isAllSelected}
                            selectAllHandler={selectAllHandler}
                            exportExcelFunction={userExcelReport}
                            isLoading={isLoading}
                        />
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default UserReport;