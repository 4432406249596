import * as React from 'react';
import { useState } from 'react';
import { Input, Button, Icon, Header, Transition } from 'semantic-ui-react'
import Message, { MessageTypes } from '../../components/Message';
import { isMobile, isTablet } from 'react-device-detect';
import { NetworkRequest } from '../../helper/NetworkRequest';
import { toast } from 'react-toastify';
import BuddyReCaptcha from '../../components/BuddyReCaptcha';
import { Link, useParams } from 'react-router-dom';
import '../CommonPages/Home.css';
import PasswordPolicyChecker from "../../components/PasswordPolicyChecker/PasswordPolicyChecker";
import { DataHandler } from '../../helper/DataHelper';
import TranslateHelper from '../../helper/TranslateHelper';
import Logo from '../../images/home/v2/logo_standart.png';

const Login = (props) => {
    var { translate } = TranslateHelper();
    var { translateFromEng } = TranslateHelper();
    let { email } = useParams();
    const [isFormOk, setIsFormOk] = useState(false);
    const [emailHook] = useState(email);
    const [page, setPage] = useState(1);

    const [user, setUser] = useState<any>({
        email: "",
        token: ""
    });
    const [isError, setIsError] = useState(false);

    const [message, setMessage] = useState<any>({
        Header: "",
        Description: "",
        MessageType: MessageTypes.Error
    });

    const [isShowPasswordPolicyChecker, setIsShowPasswordPolicyChecker] = useState(false);
    const [passwordPolicyResponseList, setPasswordPolicyResponseList] = useState<any>();
    const [isPasswordPolicyAllDone, setIsPasswordPolicyAllDone] = useState<any>(false);
    const [isCloseAllPasswordSettings, setIsCloseAllPasswordSettings] = useState(false);

    const [isSendMailLoading, setIsSendMailLoading] = useState(false);

    let styles = {
        loginBoxStyle: {
            width: isTablet ? "50%" : isMobile ? "90%" : "20%",
            backgroundColor: "#ffffffc4",
            backdropFilter: "blur(40px)",
            height: "fit-content",
            borderRadius: "10px",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            display: "flex",
            flexDirection: "column" as "column",
            margin: "0 auto",
            marginTop: isMobile ? "10px" : "50px",
            padding: isMobile ? "15px" : "30px",
            gap: "1em",
            position: "sticky" as "sticky",
            top: "1rem",
            minWidth: "300px"
        },

        inputElementBoxStyle: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "1rem",

        },

        loginButtonBoxStyle: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "1em"
        },
        ldapLoginHeaderStyle: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        ldapLoginHeader: {
            fontSize: "1.6em",
            fontWeight: "bold",
            marginTop: "15%",
            marginBottom: "10%"
        }
    }


    const showError = (message) => {
        setMessage({
            ...message,
            Header: "Hata",
            Description: message,
            MessageType: MessageTypes.Error
        });
        setIsError(true);
    }

    const sendResetPasswordMail = () => {
        setIsSendMailLoading(true);
        setIsError(false);

        let userData = {
            email: user.email
        }
        NetworkRequest("/Authentication/SendResetPasswordMail", userData).then(response => {
            //debugger;
            if (typeof (response.data) == "string") {
                try {
                    response.data = JSON.parse(response.data);
                } catch (e) { }
            }
            if (response.data.resultCode == 0) {
                setPage(2);
                let userData = response.data.detail;
            } else {
                showError(response.data.message)
            }

            setIsSendMailLoading(false)
        });
    };

    const resetPassword = () => {
        setIsError(false);
        if (!isFormOk || !isPasswordPolicyAllDone)
            return;


        if (user.password != user.passwordConfirm) {
            toast("Şifreleriniz uyuşmuyor.", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            return;
        }

        let userData = {
            email: user.email,
            password: user.password,
            passwordConfirm: user.passwordConfirm,
            token: user.token
        }

        NetworkRequest("/Authentication/ResetPassword", userData).then(response => {
            if (response.data.resultCode === 0) {
                toast(translate("Şifreniz sıfırlandı 3 saniye sonra giriş sayfasına yönelendirleceksiniz."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 4000 });

                setTimeout(() => {
                    window.location.href = "/homeLogin"
                },4000)
                
            } else {
                showError(response.data.message)
            }
        });
    };


    const checkCode = () => {
        setIsSendMailLoading(true);
        if (!isFormOk)
            return;
        setIsError(false);
        if (user.token.length != 6) {
            toast(translate("Lütfen 6 haneli kodunuzu giriniz."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            setIsSendMailLoading(false);
            return;
        }
        let userData = {
            email: user.email,
            token: user.token
        }
        NetworkRequest("/Authentication/CheckToken", userData).then(response => {
            if (response.data.resultCode === 0) {
                setPage(3);
            } else {
                showError(response.data.message)
            }

            setIsSendMailLoading(false);
        });
    };

    const handleChange = (e) => {
        let value = e.target.name != "Secret" ? e.target.value : e.target.value.substr(0, 6);
        setUser({
            ...user,
            [e.target.name]: value
        });

        if (e.target.name == "password") {
            let passwordData = {
                email: user.email,
                password: e.target.value.trim(),
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }

            NetworkRequest("api/Settings/CheckPasswordPolicyRequirementsStatus", passwordData, true).then((response) => {
                //
                if (response.data.resultCode == 0) {
                    let data = response.data.detail;
                    let list = data.passwordPolicyRequirementsStatusList;
                    let isAllPassed = data.isAllPassed;

                    setPasswordPolicyResponseList(list);
                    setIsPasswordPolicyAllDone(isAllPassed);
                    setIsCloseAllPasswordSettings(list != null);
                }
            });
        }
    }

    React.useEffect(() => {
        if (emailHook != undefined) {
            setUser({ ...user, email: emailHook });
            setPage(2);



        }

    }, [emailHook]);

    React.useEffect(() => {
        setIsFormOk(user.email != "");
    }, [user])

    return (
        <div style={styles.loginBoxStyle}>

            <div style={styles.ldapLoginHeaderStyle}>
                <Link to={"/HomeLogin"}><Icon name="arrow left" />{translateFromEng("Back")}</Link>
                <img width="30%" src={Logo} />
            </div>

            <div>
                <div style={styles.ldapLoginHeader}>{translateFromEng("Reset Password")}</div>
            </div>

    
            {page == 1 &&
                <>
                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className={"greyInput50"}
                            size="big"
                            fluid
                            icon='mail'
                            iconPosition='left'
                            placeholder='Email'
                            value={user.email}
                            onChange={handleChange}
                            name="email"
                        />
                        {/* <BuddyReCaptcha setIsFormOk={setIsFormOk} />*/}
                    </div>

                    <div style={styles.loginButtonBoxStyle}>
                        <Button
                            loading={isSendMailLoading}
                            circular={true}
                            size={isMobile ? "medium" : "big"}
                            color="green"
                            icon
                            labelPosition='right'
                            disabled={!isFormOk && !isSendMailLoading}
                            onClick={sendResetPasswordMail}
                    >
                        {translateFromEng("Send Mail")}
                            
                            <Icon
                                name={"send"}
                            />
                        </Button>
                    </div>
                </>

            }


            {
                page == 2 &&

                <>
                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className={"greyInput50"}
                            size="big"
                            fluid
                            icon='mail'
                            iconPosition='left'
                            placeholder='Email'
                            value={user.email}
                            onChange={handleChange}
                            name="email"
                            disabled
                        />
                    </div>
                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className={"greyInput50"}
                            size="big"
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Enter 6 Digit Code'
                            value={user.token}
                            onChange={handleChange}
                            name="token"


                        />
                    </div>

                    <div style={styles.loginButtonBoxStyle}>
                        <Button
                            loading={isSendMailLoading}
                            circular={true}
                            size={isMobile ? "medium" : "big"}
                            color="green"
                            icon
                            labelPosition='right'
                            onClick={checkCode}
                            disabled={!isFormOk && !isSendMailLoading}
                        >
                            {translateFromEng("Check Code")}
                        
                            <Icon
                                name={"send"}
                            />
                        </Button>
                    </div>
                </>
            }
            {
                page == 3
                &&
                <>
                    <div style={styles.inputElementBoxStyle}>
                        <div className={"newPasswordField"}>
                            <PasswordPolicyChecker
                                PasswordPolicyResponseList={passwordPolicyResponseList}
                                IsShow={isShowPasswordPolicyChecker}
                                Left={["100%", "110%"]}
                            />
                        </div>
                        <Input
                            className={"greyInput50"}
                            onFocus={() => { setIsShowPasswordPolicyChecker(true) }}
                            onBlur={() => { setIsShowPasswordPolicyChecker(false) }}
                            type="password"
                            size="big"
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Şifre'
                            value={user.password}
                            onChange={handleChange}
                            name="password"
                        />
                    </div>
                    <div style={styles.inputElementBoxStyle}>
                        <Input
                            className={"greyInput50"}
                            type="password"
                            size="big"
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Verify Password'
                            value={user.passwordConfirm}
                            onChange={handleChange}
                            name="passwordConfirm"
                        />
                    </div>
                    <div style={styles.loginButtonBoxStyle}>
                        <Button
                            circular={true}
                            size={isMobile ? "medium" : "big"}
                            color={"green"}
                            icon
                            labelPosition='right'
                            onClick={resetPassword}
                            disabled={!isFormOk && !isPasswordPolicyAllDone}
                        >
                            
                            {translateFromEng("Reset Password")}
                            <Icon
                                name={"refresh"}
                            />
                        </Button>
                    </div>
                </>
            }
            <div id="buddyCaptcha">
            </div>
            <Transition visible={isError} animation='scale' duration={500}>
                <div>
                    <Message
                        Header={message.Header}
                        Description={message.Description}
                        MessageType={message.MessageType}
                    />
                </div>
            </Transition>
        </div >
    );
}

export default Login;