import {Link} from 'react-router-dom';
import {Button, Header, Icon, Loader, Message, Modal, Popup, Transition} from 'semantic-ui-react';

import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import {useEffect, useState} from 'react';
import React from 'react';
import {digitallicaConfirm} from '../../../helper/HelperFunctions';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const Dictionary = () => {
    var {translate} = TranslateHelper();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [dictionary, setDictionary] = useState<any>();

    const getDictionary = () => {
        let dicData = {
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        NetworkRequest("/api/Dictionary/GetByTenantId", dicData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let dic = response.data.detail;
                setDictionary(dic);
            }
            setIsLoading(false);
        });
    }

    const deleteDic = () => {
        setShowDeleteModal(false);
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            //debugger;
            let dicData = {
                id: dictionary.buddyDictionaryId
            };

            NetworkRequest("/api/Dictionary/DeleteByDicId", dicData).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    toast(translate("Sözlük başarılı bir şekilde silindi!"), {
                        type: 'info',
                        theme: 'light',
                        position: 'top-center',
                        autoClose: 10000
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }

                setIsLoading(false);
            }).catch(error => {
                toast(translate("Beklenmedik bir hata meydana geldi. Lütfen daha sonra tekrar deneyiniz."), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 10000
                });
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
        });
    }

    useEffect(() => {
        getDictionary();
    }, [])
    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={showDeleteModal} animation='scale' duration={500}>
                    <Modal size={"mini"} open={showDeleteModal}>
                        <Modal.Header>{translate("Sözlüğü silmek istediğinizden emin misiniz?")}</Modal.Header>
                        <Modal.Content>
                            <p>
                                {translate("Sözlük silindiğinde altında oluşturulmuş tüm kelimeler silinecektir.")}
                            </p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                setShowDeleteModal(false)
                            }}>
                                {translate("iptal")}
                            </Button>
                            <Button negative onClick={deleteDic}>
                                {translate("Sil")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>
                {isLoading
                    ?
                    <Loader active inline='centered'/>
                    :
                    dictionary == undefined
                        ?
                        <Message warning>
                            <Message.Header>
                                {translate("Daha önce oluşturulmuş bir sözlük bulunamadı. Oluşturmak için 'Oluştur' butonuna tıklayınız.")}
                            </Message.Header>
                            <Link className="homeHeaderLink" to="/addDictionary">
                                <Button style={{marginTop: "2%"}} secondary>{translate("Oluştur")}</Button>
                            </Link>
                        </Message>
                        :
                        <div>
                            <Header as='h2'>{translate("Sözlük")}</Header>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: "solid 4px #fafafa",
                                    padding: "1%",
                                    borderRadius: "10px",
                                    fontWeight: "bold",
                                    fontSize: "1.2em",
                                    backgroundColor: "#fafafa"
                                }}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <div><Icon color={"grey"} size={"large"} name={"book"}/></div>
                                    <div>{dictionary.name}</div>
                                </div>
                                <div>
                                    <Popup content={translate("Kelime Listesi")}
                                           trigger={<Link to="/wordList"> <Button icon='text cursor'/></Link>}/>
                                    <Popup content={translate("Sözlük Ayarları")} trigger={
                                        <Link className="homeHeaderLink" to="/dictionarySettings">
                                            <Button icon='setting'/>
                                        </Link>
                                    }
                                    />

                                    <Popup content={translate("Sil")}
                                           trigger={
                                               <Button
                                                   onClick={() => {
                                                       setShowDeleteModal(true)
                                                   }}
                                                   color="red"
                                                   icon="trash"
                                               />
                                           }
                                    />
                                </div>
                            </div>
                        </div>

                }
            </div>
        </div>
    );
};

export default Dictionary;