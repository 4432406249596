import * as React from 'react';
import '../Home.css';


type IAboutUsBox = {
    title: string
}

const AboutUsBox = ({ title }: IAboutUsBox) => {

    return (
        <div className="aboutUsBox">
            <div className="pricingSubTitleAbout">{title}</div>
        </div>
    );
}

export default AboutUsBox;