import * as React from 'react';
import {Link} from 'react-router-dom';
import {Button, Grid, Icon, Image, Input, TextArea, Accordion} from 'semantic-ui-react';
import '../Home.css';
import HomeV2Footer from '../HomeV2Components/HomeV2Footer';
import HomeV2Header from '../HomeV2Components/HomeV2Header';
import PartnerBanner from '../../../images/home/v2/partner-banner.png'
import ContactUsForm from '../HomeV2Components/ContactUsForm';
import TranslateHelper from '../../../helper/TranslateHelper';
import {isLandscapeTablet} from "../../../helper/HelperFunctions";


const Partner = (props) => {
    var {translateFromEng} = TranslateHelper();

    const panels = [
        {
            key: 'how-much-money',
            title: translateFromEng('How much money can i make?'),
            content: translateFromEng("It depends on your account's number of signups and the conversion rate from free users to paid customers. There is no upper limit!")
        },
        {
            key: 'what-is-the',
            title: translateFromEng('What is the sales commision?'),
            content: translateFromEng('The basic commission is 20% on each sale. Assuming you can send many customers, we may discuss a special commission rate with you.')
        },
        {
            key: 'how-will-i',
            title: translateFromEng('How will i be paid?'),
            content: translateFromEng('You need a Paypal account to get paid. It is free to create one.')
        },
        {
            key: 'do-you-track',
            title: translateFromEng('Do you track re-visits from the same user?'),
            content: translateFromEng('Absolutely! If you send a user but decide to sign up at a later time (up to 60 days), we still count them as your sale. We use cookies to track re-visits.')
        },
    ]
    return (
        <Grid className={"homeV2Container"} columns={16}>
            <HomeV2Header/>

            <Grid.Row columns={2} className="homeV2PartnerBannerContainer">
                <Grid.Column computer={isLandscapeTablet() ? 16 : 8} mobile={16} className="homeV2BannerTextSectionPartnerContainer">
                    <Grid.Row centered>
                        <Grid.Column className="homeV2BannerTitle homeV2BannerTitlePartner">
                            <div>{translateFromEng("Join the Buddy App Affiliate Program")}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <div
                                className="homeV2BannerText mt4">{translateFromEng("Fill in the form and be a part of our program, today!")}</div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column computer={isLandscapeTablet() ? 16 : 8} mobile={16}>
                    <Image className={"partnerBannerImageClass"} src={PartnerBanner} size="massive"/>

                    <div className="homeV2PartnerFormContainer">

                        <ContactUsForm/>
                        <div id={"buddyCaptcha"}></div>
                        {/*<Input  className="parnerInput" type="text" placeholder="Full Name" size="huge" />*/}
                        {/* <Input  className="parnerInput" type="email" placeholder="Email" size="huge" />*/}
                        {/* <Input  className="parnerInput" type="text" placeholder="Country" size="huge" />*/}
                        {/* <TextArea className="parnerInput" placeholder='Start the conversation' style={{ minHeight: 160, with: "100%" }} />*/}
                        {/* <Button size="big" className="homeV2StartedButton textColorWhite" circular>Apply Now</Button>*/}
                    </div>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
                <div className="partnerPContainer">
                    <p>
                        {translateFromEng("")}
                    </p>

                    <p>
                        {translateFromEng("Our affiliation program is really simple. First of all, to become an affiliate you need to have a good grasp of the digital HQ industry. Are you a blogger interested in HR? Are you an HR Advisor? Do you contribute to some magazine? Do you offer any HR-related services? you very active on social media? If you've answered Yes to any of the questions above, you're probably the right fit for our affiliation program.")}
                    </p>
                </div>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column textAlign="left" className="alignCenterContent">
                    <Grid.Column className="homeV2BannerTitle homeV2BannerTitlePartner homeV2PartnerTextContent">
                        <div>{translateFromEng("How It Works?")}</div>
                    </Grid.Column>

                    <Grid.Column>
                        <p className="partnerTextStyle mt2">
                            {translateFromEng("It is really simple. Send us your application below. We will check your application and communicate with you directly As soon your application is accepted, you will be given access to the 'affiliators' area. There you will find a special URL. Use it to send traffic to our website. All visitors/signups/conversions will be tracked and be visible from your members' area. For every sale originated from a user you referred a recurring commission will be added to your account.")}
                        </p>
                    </Grid.Column>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column textAlign="left" className="alignCenterContent">
                    <Grid.Column className="homeV2BannerTitle homeV2BannerTitlePartner">
                        <div>{translateFromEng("Frequently Asked Questios")}</div>
                    </Grid.Column>

                    <Grid.Column>
                        <Accordion className="partnerAccordion" defaultActiveIndex={0} panels={panels}/>
                    </Grid.Column>
                </Grid.Column>
            </Grid.Row>
            <HomeV2Footer/>
        </Grid>
    )
}

export default Partner;