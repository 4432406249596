import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Checkbox, Grid, Input, Loader, Modal, TextArea, Transition } from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import { IDataTable, IDoc, IDropdown } from '../../../interfaces/interfaces'

import {
    prepareDataForTable,
    datableFilterChange,
    checkFieldsIsFilled,
    digitallicaConfirm,
    base64toBlob
} from '../../../helper/HelperFunctions';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Test {
    id?: string,
    testName?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const DocumentList = (props) => {
    var { translate } = TranslateHelper();
    let styles = {
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        },
        thumbnailPhoto: {
            width: "50vw" as "50vw",

        }
    }

    var _ = require('lodash');

    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [showFile, setShowFile] = useState(false);
    const [doc, setDoc] = useState("");
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedDocumentId, setSelectedDocumentId] = useState<any>();
    const [documentList, setDocumentList] = useState<Array<any>>();
    const [childTenants, setChildTenants] = useState<Array<any>>([]);
    const [baseChildTenants, setBaseChildTenants] = useState<Array<any>>([]);
    const [document, setDocument] = useState({
        name: "",
        description: "",
        createUserId: DataHandler.getUserDataWithKey("userId"),
        tenantId: DataHandler.getUserDataWithKey("tenantId"),
        appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
    });

    const deleteTest = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz ?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/api/Documents/Delete", test).then((response) => {
                    ////
                    if (response.data.resultCode == 0) {
                        getDocs({
                            tenantId: tenantId,
                            checkTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin",
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                        });
                    } else {
                        toast(response.data.detail, {
                            type: 'error',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2500
                        });
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
    }

    const showChildTenants = (id) => {
        setSelectedDocumentId(id);
        setShowModal(true);
    }

    const setVisibleTenants = () => {
        let list = _.cloneDeep(baseChildTenants);
        _.each(list, (elem) => {
            elem.isSelected = false;
        });
        NetworkRequest("/api/Documents/CheckChildTenants", { id: selectedDocumentId }).then((response) => {
            if (response.data.resultCode == 0) {
                list = _.filter(list, (elem) => {
                    return response.data.detail.indexOf(elem.tenantId) == -1;
                });
                setChildTenants(list);
            }
        });
    }

    const getSelectedDocument = () => {
        let docModel = _.find(documentList, (elem) => {
            return elem.id == selectedDocumentId;
        });
        return docModel;
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {

        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const zoomFile = (id) => {
        let data = { id: id };

        NetworkRequest("/api/Documents/GetById", data).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                let blob = base64toBlob(result.url, result.mimeType)
                setDoc(URL.createObjectURL(blob))
                setShowFile(true);
            }
        });
    }

    const selectChildTenant = (child) => {
        let clone = _.cloneDeep(childTenants);
        _.each(clone, (elem) => {
            if (elem.tenantId == child.tenantId) {
                elem.isSelected = true;
            }
        })
        setChildTenants(clone);
    }

    const unSelectChildTenant = (child) => {
        let clone = _.cloneDeep(childTenants);
        _.each(clone, (elem) => {
            if (elem.tenantId == child.tenantId) {
                elem.isSelected = false;
            }
        })
        setChildTenants(clone);
    }

    const checkEdit = (id) => {
        NetworkRequest("/api/Documents/IsEditEnabled", { id: id }).then((response) => {
            if (response.data.resultCode === 0) {
                window.location.href = "/editDocument/" + id;
            } else {
                toast(translate(response.data.detail), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });
    }

    let tableHeader = [
        translate("Adı"),
        translate("Açıklama"),
        translate("Yükleyen Kullanıcı"),
        translate("Dosya Formatı"),
        translate("Yüklenme Tarihi"),
        translate("İşlemler")
    ];
    let transactionArr = [
        {
            icon: "zoom",
            desc: translate("Görüntüle"),
            color: "black",
            link: null,
            type: "zoom",
            function: zoomFile
        },
        {
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: null,
            function: checkEdit,
            type: "edit",

        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: deleteTest
        }
    ];
    let hiddenProps = [
        "id",
        "tenantId",
        "documentId",
        "createUserId",
        "url",
        "appType",
        "selectedTenants",
        "convertedDocument",
        "mimeType"
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(4, 0, translate("Şirket"));
        transactionArr.splice(3, 0, {
            icon: "code branch",
            desc: translate("Tenanta Klonla"),
            color: "green",
            function: showChildTenants,
            link: null,
            type: "edit"
        });
    } else {
        hiddenProps.push("tenantName");
    }

    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: transactionArr,
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                // {
                //     desc: translate("Aktif"),
                //     defaultValue: true,
                //     key: "isActive",
                //     type: "boolean",
                //     onChange: onChangeActive
                // },
                // {
                //     desc: translate("Silinen"),
                //     defaultValue: false,
                //     key: "isDeleted",
                //     type: "boolean",
                //     onChange: onChangeDeleted
                // },
                {
                    desc: translate("Tarih"),
                    defaultValue: Date.now(),
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/addDocument",
        noCheckBox: true,
        noFilter: true
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response;
        let model = prepareDataForTable(data.documentViews, hiddenProps);
        ////console.log("dataTableModel.filter", dataTableModel.filter);
        setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: model,
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: {
                    count: Math.ceil(data.count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                },
                addRowLink: dataTableModel.addRowLink,
                noCheckBox: true,
                noFilter: true
            });
            setIsLoading(false);
        }, 1000);
    }

    //get table data from database
    const getDocs = (filterData?) => {
        /* console.log("getdocs")*/
        setIsLoading(true);

        NetworkRequest("/api/Documents/GetDocuments", filterData).then((response) => {
            //debugger;
            if (response.data.resultCode == 0) {

                let list = _.cloneDeep(response.data.detail.documentViews);
                setDocumentList(list);
                prepareData(response.data.detail);
            }
            setIsLoading(false);
        });
    }

    const addDocumentEntity = (data, displayName) => {
        data.selectedTenants = [];
        NetworkRequest('/api/Documents/AddEntity', data).then((response) => {
            if (response.data.resultCode == 0) {
                toast(displayName + " şirketiyle paylaşıldı.", {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
            }
        });
    }

    const shareDocument = () => {
        let filtered = _.filter(childTenants, (child) => {
            return child.isSelected == true;
        });
        if (filtered.length == 0) {
            toast(translate("Şirket seçmediniz."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2000
            });
            return;
        }
        let docModel = getSelectedDocument();
        let promises: any = [];
        _.each(filtered, (elem) => {
            docModel.tenantId = elem.tenantId;
            promises.push(addDocumentEntity(docModel, elem.displayName));
        });
        setSelectedDocumentId(undefined);
        setShowModal(false);
        Promise.all(promises).then((responses) => {
            getDocs({
                tenantId: tenantId,
                checkTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin",
                filterText: filterText,
                currentPage: currentPage,
                isActive: isActive,
                isDeleted: isDeleted,
                startingDate: startingDate,
                dueDate: dueDate,
            });
        });
    };

    useEffect(() => {
        if (selectedDocumentId != undefined) {
            setVisibleTenants();
        }
    }, [selectedDocumentId]);

    useEffect(() => {
        getDocs({
            tenantId: tenantId,
            checkTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin",
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate
        });
    }, [tenantId, filterText, startingDate, dueDate, currentPage, isActive, isDeleted]);

    useEffect(() => {
        if (userRole == "HeadAdmin") {
            NetworkRequest('/api/Tenants/GetChildTenants', { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
                if (response.data.resultCode == 0) {
                    let list = response.data.detail;
                    if (list.length > 0) {
                        setBaseChildTenants(list);
                    }
                }
            });
        }
    }, [userRole]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={isLoading} animation='scale' duration={0}>
                    <Loader size="huge" active inline='centered' />
                </Transition>
                <Transition visible={!isLoading} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                            selectAllHandler={selectAllHandler} isLoading={isLoading} />
                    </div>
                </Transition>
                <Modal
                    onClose={() => setShowFile(false)}
                    onOpen={() => setShowFile(true)}
                    open={showFile}
                    style={{ marginTop: "-42%" }}
                >
                    <Modal.Actions>
                        <Button circular color="red"
                            onClick={() => setShowFile(false)}>{translate("Kapat")}</Button>
                    </Modal.Actions>
                    <Modal.Content image>
                        <div className="iframeContainer">
                            <div id="iframe" className="iframe">
                                <iframe src={doc} style={{ width: "100%", height: "100%" }} />
                            </div>
                        </div>
                    </Modal.Content>
                </Modal>
                <Modal onClose={() => setShowModal(false)} open={showModal}>
                    <Modal.Actions>
                        <Button style={{ ...styles.btnDark, width: "auto", height: "auto" }} onClick={() => {
                            setSelectedDocumentId(undefined);
                            setShowModal(false);
                        }}>
                            {translate("Kapat")}
                        </Button>
                    </Modal.Actions>
                    <Modal.Content>
                        {childTenants.length > 0
                            &&
                            <>
                                {/*//TODO: translate*/}
                                <h3 className="textCenter">{getSelectedDocument()?.name} {translate("dokümanını aşağıdaki şirketlerle paylaşabilirsiniz.")}</h3>

                                <Grid columns={2}>
                                    {childTenants.map((child) => (
                                        <Grid.Row key={uuidv4()} className="borderBottom">
                                            <Grid.Column>
                                                {child.displayName}
                                            </Grid.Column>
                                            <Grid.Column>
                                                {child.isSelected != true
                                                    &&
                                                    <Button color="green" className="floatRight" onClick={() => {
                                                        selectChildTenant(child);
                                                    }}>
                                                        {translate("Seç")}
                                                    </Button>
                                                }
                                                {child.isSelected == true
                                                    &&
                                                    <Button color="red" className="floatRight" onClick={() => {
                                                        unSelectChildTenant(child);
                                                    }}>
                                                        {translate("Geri Al")}
                                                    </Button>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    ))}
                                </Grid>
                            </>
                        }
                        {childTenants.length > 0
                            &&
                            <Grid columns={1}>
                                <Grid.Row>
                                    <Grid.Column className="textCenter">
                                        <Button color="green" onClick={() => {
                                            shareDocument();
                                        }}>
                                            {translate("Kaydet")}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                        {childTenants.length == 0
                            &&
                            // TODO: translate
                            <h3 className="textCenter">{getSelectedDocument()?.name} {translate("dokümanını paylaşacak şirket bulunmamaktadır.")}</h3>
                        }
                    </Modal.Content>
                </Modal>
            </div>
        </div>
    );
}

export default DocumentList;