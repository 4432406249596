import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { ILeftNavbar, IMenuItem, ISubMenu } from "../interfaces/interfaces";
import { Link, useNavigate } from 'react-router-dom';
import LeftNavbarItems from "./LeftNavbarItems";
import { Icon, Image, Transition } from "semantic-ui-react";
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { NetworkRequest } from '../helper/NetworkRequest';

import TranslateHelper from '../helper/TranslateHelper';
import { displayProtectedAppDoc } from '../helper/DisplayProtectedDocument';
import { DataHandler } from '../helper/DataHelper';
import _ from "lodash";



function LeftNavbar(props: ILeftNavbar) {
    const navigate = useNavigate();
    var { translate } = TranslateHelper();
    let _ = require("lodash");
/*    const history = useNavigate();*/
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [roleId] = useState(DataHandler.getUserDataWithKey("roleId"));
    const [baseFileUrl] = useState("/files/images/icons/");
    const [isOpenSubPanel, setIsOpenSubPanel] = useState(false);
    const [currentSubMenu, setCurrentSetMenu] = useState("");
    const [currentSubMenuList, setCurrentSubMenuList] = useState<Array<ISubMenu>>([]);
    const [currentMouseEnter, setCurrentMouseEnterId] = useState("");
    const [menuItems, setMenuItems] = useState<Array<IMenuItem>>([]);



    const convertToTurkishSubMenu = (subMenu: string) => {
        switch (subMenu) {
            case "quizes":
                return translate("Sınavlar");

            case "surveyList":
                return translate("Anketler");

            case "reports":
                return translate("Raporlar");

            case "settings":
                return translate("Ayarlar");
        }
    }

    const createSubMenuItems = (subMenu: string) => {

        // setCurrentSubMenuList([]);
        switch (subMenu) {
            case "quizes":
                let menuModel = _.find(menuItems, (elem) => {
                    return elem.Id == "quizes";
                });
                if (menuModel != undefined && menuModel.menuDetails != undefined) {
                    let subList = JSON.parse(menuModel.menuDetails);
                    setCurrentSubMenuList(subList);
                }
                break;

            case "surveyList":
                let surveyListmenuModel = _.find(menuItems, (elem) => {
                    return elem.Id == "surveyList";
                });
                if (surveyListmenuModel != undefined && surveyListmenuModel.menuDetails != undefined) {
                    let subList = JSON.parse(surveyListmenuModel.menuDetails);
                    setCurrentSubMenuList(subList);
                }
                break;

            case "reports":
                let reportsmenuModel = _.find(menuItems, (elem) => {
                    return elem.Id == "reports";
                });
                if (reportsmenuModel != undefined && reportsmenuModel.menuDetails != undefined) {
                    let subList = JSON.parse(reportsmenuModel.menuDetails);
                    setCurrentSubMenuList(subList);
                }
                break;

            case "settings":
                let settingsmenuModel = _.find(menuItems, (elem) => {
                    return elem.Id == "settings";
                });
                if (settingsmenuModel != undefined && settingsmenuModel.menuDetails != undefined) {
                    let subList = JSON.parse(settingsmenuModel.menuDetails);
                    setCurrentSubMenuList(subList);
                }
                break;
                
            case "dictionaryandfaq":
                let dictAndFaqModel = _.find(menuItems, (elem) => {
                    return elem.Id == "dictionaryandfaq";
                });
                if (dictAndFaqModel != undefined && dictAndFaqModel.menuDetails != undefined) {
                    let subList = JSON.parse(dictAndFaqModel.menuDetails);
                    setCurrentSubMenuList(subList);
                }
                break;
        }
    }

    useEffect(() => {
        createSubMenuItems(currentSubMenu);
        setCurrentSetMenu(currentSubMenu);
    }, [currentSubMenu]);

    useEffect(() => {

        if (roleId != undefined) {
            NetworkRequest("/api/UserPages/GetMenuList", { roleId: roleId }).then((response) => {
                if (response.data.resultCode == 0) {
                    let list = response.data.detail;
                    list = _.orderBy(list, "menuRank");
                    let arr: any = [];
                    _.each(list, (doc) => {
                        arr.push(
                            {
                                Icon: doc.menuIcon,
                                Text: doc.menuText,
                                Link: doc.pageUrl,
                                Id: doc.menuTag,
                                Title: doc.menuTitle,
                                IsHaveSubCategory: doc.menuIsHaveSubCategory,
                                menuDetails: doc.menuDetails,
                                SetIsOpenSubPanel: setIsOpenSubPanel,
                                BgColor: props.BgColor,
                                MenuItemTextColor: props.MenuItemTextColor,
                                MenuItemTextColorHover: props.MenuItemIconColorHover,
                                MenuItemIconColor: props.MenuItemIconColor,
                                MenuItemIconColorHover: props.MenuItemIconColorHover,
                                MenuItemMarkerColor: props.MenuItemMarkerColor,
                                MenuItemBgColor: props.MenuItemBgColor,
                                MenuItemBgColorHover: props.MenuItemBgColorHover
                            }
                        );
                    });
                    setMenuItems(arr);
                }
            });
        }
    }, [roleId]);

    useEffect(() => {
        //console.log("currentSubMenuList", currentSubMenuList);
    }, [currentSubMenuList]);


    const styles = {
        leftPanel: {
            width: "100px",
            height: "100vh",
            position: "fixed" as "fixed",
            display: "flex",
            backgroundColor: props.BgColor,
            zIndex: "4",
            boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
            flexDirection: "column" as "column",
            transition: (props.Open && isMobile) ? "all 1.2s ease-in-out" : "all 0.5s ease-in-out",
            left: (props.Open && isMobile) ? "0" : "0",
            // paddingBottom: "3rem",
        },

        leftPanelMobile: {
            transition: "all 1.2s ease-in-out",
            left: props.Open ? "0px" : "-70%"
        },

        logo: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            width: "100%",
            /*marginLeft: props.Open ? "unset" : "-200px",*/
            transition: "all 0.5s ease-in-out",
        },

        navbarContainer: {
            padding: isMobile ? "20px" : "unset",
            overflow: "auto"
        },

        bottomLogo: {
            //marginLeft: "273px",
            opacity: props.Open ? "0" : "1",
            zIndex: "2",
            cursor: "pointer",
            //transform: !props.OpenLeftNavState ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 0.5s ease-in-out",
            position: "absolute" as "absolute",
            top: "36px",
            left: props.Open ? "-350px" : "202px"
        },

        subMenuContainer: {
            left: isOpenSubPanel ? "100px" : "-150px",
            backgroundColor: props.BgColor
        },
        icon: {
            marginLeft: "20px",
            margin: ".4em",
            // filter: isSelected ? "brightness(100)" : isSelected ? "#fff" : (menuBgColor === "#fff" || menuBgColor === "rgb(245, 245, 245)") ? "brightness(0)" : "brightness(100)",
            filter: "brightness(0)",
            transition: "all 0.2s ease-in-out",
            width: "25px"
        }

    }

    return (
        <>
            <div style={styles.subMenuContainer} className="subMenuContainer">
                <div className={"subMenuHeader"}>
                    <div className={"subMenuHeaderContent"}>
                        <div className={"subMenuHeaderText"}>{convertToTurkishSubMenu(currentSubMenu)}</div>
                        <div><Icon onClick={() => {
                            setIsOpenSubPanel(false)
                        }} name={"arrow left"} /></div>
                    </div>
                    <div className={"subMenuList"}>
                        {currentSubMenuList.map(subMenu => (
                            <div
                                onClick={() => {
                                   
                                    setIsOpenSubPanel(false);
                                    //reports#/reports?page=1
                                    //if (subMenu.Link.split("?")[0] === "/reports") {
                                    //    debugger;
                                    //    navigate({
                                    //        pathname: '/reports',
                                    //        search: subMenu.Link,
                                    //    });
                                    //    //navigate("/reports#" + subMenu.Link);
                                    //} else {

                                    //}

                                    navigate(subMenu.Link);
                                    
                     
                                    //currentSubMenu === "report" && setTimeout(() => {
                                    //    window.location.reload()
                                    //}, 500);
                                }}
                                key={uuidv4()}
                            >
                                <div
                                    id={subMenu.Icon}
                                    onMouseEnter={(event) => {
                                        let element = event.target as HTMLInputElement;
                                        setCurrentMouseEnterId(element.id);
                                    }}
                                    style={{
                                        transition: "all .5s",
                                        backgroundColor: currentMouseEnter != subMenu.Icon ? "#f3f3f357" : "#00000012"
                                    }}
                                    className={"subMenuContainerClass"}>
                                    <div>
                                        <Icon style={{ color: props.MenuItemIconColor }} size={"large"} name={subMenu.Icon} />
                                    </div>

                                    <div style={{ color: props.MenuItemTextColor }}>{translate(subMenu.Text)}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {isMobile ?
                <Transition visible={props.Open} animation='fade right' duration={600}>
                    <div className={"leftNavBarContainer"}>
                        <div className={"leftNavbarContainer"} style={styles.leftPanelMobile}>
                            <Link to="/">
                                <div style={styles.logo}>
                          

                                    <img id="logoImage" src={props.LogoUrl} style={{ width: "75%" }} alt="logo" />
                                    
                                </div>
                            </Link>

                            <div style={styles.navbarContainer}>
                                <LeftNavbarItems
                                    Open={props.Open}
                                    MenuItems={menuItems}
                                    SetIsOpenSubPanel={setIsOpenSubPanel}
                                    SetCurrentSetMenu={setCurrentSetMenu}
                                    BgColor={props.BgColor}
                                    MenuItemTextColor={props.MenuItemTextColor}
                                    MenuItemTextColorHover={props.MenuItemTextColorHover}
                                    MenuItemIconColor={props.MenuItemIconColor}
                                    MenuItemIconColorHover={props.MenuItemIconColorHover}
                                    MenuItemMarkerColor={props.MenuItemMarkerColor}
                                    MenuItemBgColor={props.MenuItemBgColor}
                                    MenuItemBgColorHover={props.MenuItemBgColorHover}
                                />
                            </div>
                        </div>
                    </div>
                </Transition>
                :
                <div className={"leftNavbarContainer"} style={styles.leftPanel}>
                    <Link to="/">
                        <div style={styles.logo}>
                            <Image id="lefNavLogo" src={props.LogoUrl} style={{ width: "75%" }} alt="logo" />
                        </div>
                    </Link>
                    <div className={"leftNavBarScrollContainer"} style={styles.navbarContainer}>
                        <LeftNavbarItems
                            Open={true}
                            MenuItems={menuItems}
                            SetIsOpenSubPanel={setIsOpenSubPanel}
                            SetCurrentSetMenu={setCurrentSetMenu}
                            BgColor={props.BgColor}
                            MenuItemTextColor={props.MenuItemTextColor}
                            MenuItemTextColorHover={props.MenuItemTextColorHover}
                            MenuItemIconColor={props.MenuItemIconColor}
                            MenuItemIconColorHover={props.MenuItemIconColorHover}
                            MenuItemMarkerColor={props.MenuItemMarkerColor}
                            MenuItemBgColor={props.MenuItemBgColor}
                            MenuItemBgColorHover={props.MenuItemBgColorHover}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default LeftNavbar;