import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Image, Input, Transition, TabPane, Tab, Icon, Header, Message } from 'semantic-ui-react';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../../helper/HelperFunctions';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../../helper/DataHelper';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import pkceChallenge from 'pkce-challenge';
import { v4 as uuidv4 } from 'uuid';

const AddCertificate = (props) => {
    const history = useNavigate();
    var { translate } = TranslateHelper();
    let { id } = useParams();
    var _ = require('lodash');

    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loader, setLoader] = useState(true);
    const [certificateUrl, setCertificateUrl] = useState<string | null | ArrayBuffer>("");
    const [certificateId] = useState<any>(id);
    const [certificateFile, setCertificateFile] = useState<any>();
    const [selectedCanvaDesingId, setSelectedCanvaDesingId] = useState<any>("");
    const [isFormOk, setIsFormOk] = useState(false);
    const [certificate, setCertificate] = useState({
        id: "",
        title: "",
        textColorCode: "#000000",
        tenantId: tenantId,
        signText: "",
        base64: "",
        appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
    });

    const [CLIENT_ID, setClientId] = useState("");
    const [CLIENT_SECRET, setClientSecret] = useState("");
    const [REDIRECT_URI, setRedirectUrl] = useState("");
    const [SCOPE] = useState("designs:read");

    const [isActiveCanvaTab, setIsActiveCanvaTab] = useState(false);

    const [accessToken, setAccessToken] = useState('');
    const [designs, setDesigns] = useState<any>([]);


    const [pageIndex, setPageIndex] = useState<number>(1);
    const queryParams = new URLSearchParams(window.location.search)
    const page = queryParams.get("page");


    type PKCEChallenge = {
        code_challenge: string;
        code_verifier: string;
    };

    let styles = {
        formContainer: {
            display: "flex",
            width: "100%",
            flexDirection: "row" as "row",
            gap: "3rem",
            marginBottom: "2.5rem"
        },
        inputStyle: {
            width: "100%"
        },
        btnDark: style({
            width: "228px",
            right: "0px",
            top: "700px",
            borderRadius: "8px",
            padding: "10px 0px !important"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        )
    }

    const panes = [
        {
            menuItem: { key: 'physicalUpload', icon: 'users', content: translate("Fiziksel Yükleme") },
            render: () => <TabPane attached={false}>

                <Input
                    className={"greyInput"}
                    name="Url"
                    style={styles.inputStyle}
                    placeholder={translate("Dosya Yükle")}
                    type="file"
                    accept="image/png"
                    onChange={handleChangeDoc}
                />

            </TabPane>,
        },
        {
            menuItem: { key: 'canvaUpload', icon: 'world', content: translate("Canva") },
            render: () => <TabPane attached={false}>

                <div>
                    {!isActiveCanvaTab ? <div> <Message
                        style={{borderRadius:"20px"}}
                        warning
                        header={translate("Uyarı")}
                        content={translate("Canva entegrasyonunu kullanabilmek için, lütfen ayarlar menüsünden Canva entegrasyon adımlarını tamamlayın.")}
                    /></div> : !accessToken ? (
                        <Button circular color="green" onClick={handleAuthorization}>Authorize with Canva</Button>
                    ) : (
                        <>
                            <Header as='h3'>{translate("Tasarımlar")}</Header>

                            <div style={{ display: "flex", justifyContent: "flex-start", gap: "1em", flexWrap: "wrap", marginTop: "4%", alignItems: "center" }}>
                                {designs.map((design) => (
                                    <div className="canva-desing-item" key={uuidv4()}>
                                        <img
                                            style={{ border: selectedCanvaDesingId == design ? "solid 5px red" : "none" }}
                                            onClick={() => {
                                                setSelectedCanvaDesingId(design);
                                                handleConvertToFileObject(design)
                                            }}
                                            src={design}
                                            width="300"
                                        />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </TabPane>,
        }
    ]

    const handleConvertToFileObject = async (imageUrl: string) => {
 
        const imgUrl = imageUrl;
        const imgName = uuidv4(); // veya istediğiniz dosya adı

        try {
            const file = await convertUrlToFile(imgUrl, imgName);
            setCertificateFile(file);
            setCertificateUrl(imgUrl);
            //console.log(file); // File objesi olarak kullanılabilir
        } catch (error) {
            //console.error('Görseli dosya olarak çevirmede hata:', error);
        }
    };

    const convertUrlToFile = async (url, filename) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], filename, { type: blob.type });
    };

    const handleChangeDoc = (e) => {
        if (e.target.files[0] == null) {
            setCertificateUrl("");
            setCertificateFile(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setCertificateFile(e.target.files[0]);
        setCertificateUrl(objectUrl);
    }

    const addCertificate = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            var formData = new FormData();
            formData.append('Data', certificateFile);
            formData.append('Certificate', JSON.stringify(certificate));

            toast(translate("Lütfen Bekleyin, Sertifika Yükleniyor"), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 120000 });
            NetworkRequest("/api/Certificate/Add", formData, true).then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    window.location.href = "/certificates"

                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const updateCertificate = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {

            var formData = new FormData();
            formData.append('Data', certificateFile);
            formData.append('Certificate', JSON.stringify(certificate));

            toast(translate("Lütfen Bekleyin, Sertifika Yükleniyor"), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 120000 });
            NetworkRequest("/api/Certificate/Update", formData, true).then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    window.location.href = "/certificates"

                } else {
                    toast.dismiss();
                    toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const checkCertificateId = (certificateId: string) => {
        certificateId !== undefined ? getById(certificateId) : setLoader(false);
    }

    const getById = (certificateId) => {
        let data = { id: certificateId };
        NetworkRequest("/api/Certificate/GetById", data).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;

                setCertificateUrl("data:image/png;base64," + result.base64);
                setCertificate({
                    ...certificate,
                    id: result.id,
                    title: result.title,
                    textColorCode: result.textColorCode,
                    tenantId: result.tenantId,
                    base64: result.base64,
                    signText: result.signText,
                });
                setLoader(false);
            }
        });
    }

    const pushQueryString = (value: number) => {
        setPageIndex(value);
    }

    const getAccessToken = async (code, verifier, clientId, clientSecret, redirectUri) => {

        let data = {
            Verifier: verifier,
            Code: code,
            RedirectUri: redirectUri,
            ClientId: clientId,
            ClientSecret: clientSecret,
            Scope: SCOPE
        }

        NetworkRequest("/api/Settings/GetCanvaToken", data).then((response) => {
            if (response.data.resultCode == 0) {
                var accessTokenData = JSON.parse(response.data.detail).access_token;
                setAccessToken(accessTokenData);
                
            } else {
                //console.log(response.data.detail);
                //toast(translate(response.data.detail), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            }
        });
    };

    const handleAuthorization = async () => {
        const challenge = await pkceChallenge(128);
        DataHandler.writePkceVerifier(challenge.code_verifier)
        const authUrl = `https://www.canva.com/api/oauth/authorize?code_challenge_method=s256&response_type=code&client_id=${CLIENT_ID}&&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=design:content:read%20design:meta:read%20design:permission:read%20folder:read%20folder:permission:read%20asset:read%20comment:read%20brandtemplate:meta:read%20brandtemplate:content:read%20profile:read&code_challenge=${challenge.code_challenge}`;
        window.location.href = authUrl;
    };

    const fetchDesigns = async (accessToken: string) => {

        let data = {
            AccessToken: accessToken
        }

        NetworkRequest("/api/Settings/FetchCanvaDesing", data).then((response) => {
            if (response.data.resultCode == 0) {

                var items = JSON.parse(response.data.detail)?.items;
                items.map((item: any) => {
                    if (item.hasOwnProperty("thumbnail")) {
                        //setDesigns([...designs, item.thumbnail.url]);
                        setDesigns(oldArray => [...oldArray, item.thumbnail.url]);
                    }
                });

            } else {
                //toast(translate(response.data.detail), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            }
        });
    };

    const getCanvaSettings = async () => {
        let canvaData = {
            Key: "Canva",
            TenantID: tenantId
        };

        await NetworkRequest("/api/Settings/GetSetting", canvaData).then((response) => {
            if (response.data.resultCode === 0) {
                let data = response.data.detail;

                if (data != null) {
                    let jsonObject = JSON.parse(data.settingValue);

                    setClientId(jsonObject?.ClientID);
                    setClientSecret(jsonObject?.ClientSecret);
                    setRedirectUrl(jsonObject?.RedirectUri);

             
                    if (jsonObject?.ClientID != "" && jsonObject?.ClientSecret != "") {
                        setIsActiveCanvaTab(true);
                    }
                    

                    const query = new URLSearchParams(window.location.search);
                    if (query?.size == 0) {
                        DataHandler.removePkceVerifier();
                    }
                    const code = query.get('code');

                    const verifier = DataHandler.getPkceVerifier();

                    if (code && verifier != null) {
                        getAccessToken(code, verifier, jsonObject?.ClientID, jsonObject?.ClientSecret, jsonObject?.RedirectUri);
                    }
                }

            }
        });
    };

    const selectMenu = (pageIndex: number) => {
        setPageIndex(pageIndex);
    }

    useEffect(() => {
        checkCertificateId(certificateId);
    }, [certificateId]);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        selectMenu(code ? 1 : 0);
    }, [location.search]);

    useEffect(() => {
        if (accessToken.length != 0 && accessToken != null && accessToken != undefined) {
            setIsActiveCanvaTab(true);
            fetchDesigns(accessToken);
        }
    }, [accessToken]);

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                certificate.title,
                certificate.textColorCode,
                certificateUrl,
                certificate.signText
            )
        );
    }, [certificate, certificateUrl]);

    useEffect(() => {
        getCanvaSettings();
    }, []);



    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={!isLoading} animation='scale' duration={500}>
                    <div >
                        <div style={styles.formContainer}>
                            <div style={{ display: "flex", flexDirection: "column" as "column", gap: "1rem", width: "50%", justifyContent: "flex-start", position: "static" }}>
                                <Input
                                    className={"greyInput"}
                                    autoFocus
                                    value={certificate.title}
                                    name="name"
                                    required="required"
                                    placeholder={translate("Adı")}
                                    onChange={(e) => { setCertificate({ ...certificate, title: e.target.value }) }}
                                />
                                <Input
                                    className={"greyInput"}
                                    value={certificate.signText}
                                    name="signText"
                                    required="required"
                                    placeholder={translate("Yetkili Adı")}
                                    onChange={(e) => { setCertificate({ ...certificate, signText: e.target.value }) }}
                                />


                                <span style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center"
                                }}>
                                    {translate("Metin Rengi")}
                                    <input
                                        className={"greyInput"}
                                        type="color"
                                        placeholder={translate("Metin Rengi")}
                                        value={certificate.textColorCode}
                                        onChange={e => setCertificate({ ...certificate, textColorCode: e.target.value })}
                                    />
                                </span>

                                <h4>{translate("Sertifika Görseli")}</h4>
                                <Tab
                                    activeIndex={pageIndex}
                                    menu={{ secondary: true }}
                                    panes={panes}
                                    onTabChange={
                                        (e, data) => {
                                            setPageIndex(Number(data.activeIndex));
                                            pushQueryString(Number(data.activeIndex));
                                        }
                                    }
                                />



                                <div style={{ bottom: "65px", right: "91px" }}>
                                    {certificateId !== undefined
                                        ?
                                        <Button
                                            circular
                                            onClick={updateCertificate}
                                            disabled={!isFormOk}
                                            color={"blue"}
                                            style={{ float: 'right' }}
                                        >
                                            {translate("Sertifika Güncelle")}
                                        </Button>
                                        :
                                        <Button circular
                                            onClick={addCertificate}
                                            disabled={!isFormOk}
                                            color='blue'
                                        >
                                            {translate("Sertifika Ekle")}
                                        </Button>
                                    }
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" as "column", gap: "1rem", width: "50%", alignItems: "flex-end" }}>
                                <Image id="addCert" width="100%" src={certificateUrl} />

                                {(certificateId !== undefined && certificateUrl != "")
                                    ?
                                    <Button
                                        circular
                                        onClick={updateCertificate}
                                        disabled={!isFormOk}
                                        color={"blue"}
                                        style={{ float: 'right' }}
                                    >
                                        {translate("Sertifika Güncelle")}
                                    </Button>
                                    :
                                    <Button circular
                                        onClick={addCertificate}
                                        disabled={!isFormOk}
                                        color='blue'
                                    >
                                        {translate("Sertifika Ekle")}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default AddCertificate;