import * as React from 'react';
import { useState, useEffect } from 'react';
import MenuItem, { Item } from '../../components/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import 'chart.js/auto';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';

import { NetworkRequest } from '../../helper/NetworkRequest';
import parse from "html-react-parser";
import { Button, Dropdown, Feed, Grid, Header, Icon, Label, Loader, Select, SemanticCOLORS, Transition } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import DashboardReportDatatable from '../../components/DashboardReportDatatable';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import LogCard from "./components/LogCard";
import TranslateHelper from '../../helper/TranslateHelper';
import { DataHandler } from '../../helper/DataHelper';
import { motion } from 'framer-motion';



const Dashboard = () => {
    const navigate = useNavigate();
    var { translate } = TranslateHelper();
    var _ = require('lodash');
    const [loginAndComplatePage, setLoginAndComplatePage] = useState<any>(0);
    const [logs, setLogs] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [lineData, setLineData] = useState<any>();
    const [dougnuthData, setDougnuthData] = useState<any>();
    const [reportModel, setReportModel] = useState<any>();
    const [traceLogModel, setTraceLogModel] = useState<any>();
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [selectedTenantId, setSelectedTenantId] = useState<string>(DataHandler.getUserDataWithKey("userRole") == "HeadAdmin" ? "1" : DataHandler.getUserDataWithKey("tenantId"));
    const [tenantList, setTenantList] = useState([{}]);
    let styles = {
        container: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: "1rem"

        },
        dashboardStyle: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between"
        },

        menuStyle: {
            width: "50%",
            padding: "20px",
            flexWrap: "wrap" as "wrap",
            display: "flex",
            gap: "1em"
        },

        graphStyle: {
            width: "100%",
            padding: "20px",
            minHeight: '350px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem"
        },

        itemBoxStyle: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexWrap: "wrap" as "wrap",
            gap: "2em"
        },
        logList: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: ".5rem",
            width: "40%"

        },
        logLabel: {
            textAlign: "center" as "center",
        },
        dropdownContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1rem",
            alignItems: "baseline"
        },
        dropdownReport: {
            minWidth: "170px",
            width: "10%"
        },
    }

    let menu: Array<Item> = [
        {
            IconName: "user",
            Text: translate("Çalışanlar"),
            Url: "/users",
            IsButton: true,
            ButtonLink: "/userAdd"
        },
        {
            IconName: "puzzle",
            Text: translate("Modüller"),
            Url: "/modules",
            IsButton: true,
            ButtonLink: "moduleAdd"
        },
        {
            IconName: "paper plane",
            Text: translate("Sınavlar"),
            Url: "/tests",
            IsButton: true,
            ButtonLink: "/testAdd"
        },
        {
            IconName: "question",
            Text: translate("Sınav Sorular"),
            Url: "/questions",
            IsButton: true,
            ButtonLink: "/questionAdd"
        },

        {
            IconName: "newspaper",
            Text: translate("Anketler"),
            Url: "/surveyList",
            IsButton: true,
            ButtonLink: "addSurvey"
        },
        {
            IconName: "question",
            Text: translate("Anket Sorular"),
            Url: "/inquiryContentList",
            IsButton: true,
            ButtonLink: "addSurveyQuestion"
        },
        {
            IconName: "mail",
            Text: translate("Bildirimler"),
            Url: "/mailTemplates",
            IsButton: true,
            ButtonLink: "/mailTemplateAdd"
        },
        {
            IconName: "game",
            Text: translate("Etkinlikler"),
            Url: "/activities",
            IsButton: true,
            ButtonLink: "/activityAdd"
        },
        {
            IconName: "chart pie",
            Text: translate("Raporlar"),
            Url: "/reports",
            IsButton: false,
            ButtonLink: ""
        },
        {
            IconName: "setting",
            Text: translate("Ayarlar"),
            Url: "/settings",
            IsButton: false,
            ButtonLink: ""
        }
    ];

    const lineOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: translate('Giriş ve Tamamlama Sayıları'),
            },
        },
        tension: 0.2,
        fill: true,
        scales: {
            y: {
                ticks: {
                    stepSize: 1
                }
            }
        }
    };

    const doughnutOptions = {
        plugins: {
            datalabels: {
                display: true,
                backgroundColor: '#ccc',
                borderRadius: 3,
                font: {
                    color: 'red',
                    weight: 'bold',
                }
            },
            doughnutlabel: {
                labels: [{
                    text: '550',
                    font: {
                        size: 20,
                        weight: 'bold'
                    }
                }, {
                    text: 'total'
                }]
            }
        }
    }

    function sortByRank(a, b) {
        return a - b;
    }

    const deDuplicateArray = (arr) => {
        var a = arr.concat();
        for (var i = 0; i < a.length; ++i) {
            for (var j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j])
                    a.splice(j--, 1);
            }
        }
        return a.sort(sortByRank);
    };

    const prepareLabelsAndData = (arr1, arr2) => {
        let labels: Array<any> = [];
        let arr1Data: Array<any> = [];
        let arr2Data: Array<any> = [];

        [...arr1, ...arr2].map((item) => {
            labels.push(item.data);
        })

        labels = deDuplicateArray(labels);

        labels.map((label) => {
            let arr1Item = arr1.find(x => x.data == label);
            if (arr1Item != undefined) {
                arr1Data.push(arr1Item.count);
            } else {
                arr1Data.push(0);
            }

            let arr2Item = arr2.find(x => x.data == label);
            if (arr2Item != undefined) {
                arr2Data.push(arr2Item.count);
            } else {
                arr2Data.push(0);
            }
        })
        if (loginAndComplatePage == 0)
            for (var i = 0; i < labels.length; i++) {
                labels[i] = labels[i].substr(labels[i].indexOf(' ') + 1, labels[i].length);
            }

        return {
            labels: labels,
            logins: arr1Data,
            completeds: arr2Data
        }

    }
    const convertPercent = (number, total) => {
        return 100 / total * number;
    }

    const getDashboardGraphs = () => {
        NetworkRequest("api/Report/GetDashboardGraphs", { id: selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : selectedTenantId, checkTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin" }).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;

                setTraceLogModel(result);
                setLogs(result.logs);
                let logins = result.loginLine[0];
                let completeds = result.completeds[0];
                let funcResult = prepareLabelsAndData(logins, completeds)

                let lineSettings = {
                    labels: funcResult.labels,

                    datasets: [
                        {
                            label: translate('Girişler'),
                            data: funcResult.logins,
                            fill: true,
                            backgroundColor: "rgba(75,192,192,0.2)",
                            borderColor: "rgba(75,192,192,1)",
                            pointHighlightStroke: "#75539e",
                            borderCapStyle: 'butt',
                            borderJoinStyle: "miter",
                            borderWidth: 4,
                            tension: 0.3,

                        },
                        {
                            label: translate('Tamamlamalar'),
                            data: funcResult.completeds,
                            fill: true,
                            // borderColor: 'rgb(53, 162, 235)',
                            // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            borderColor: "#742774",
                            pointHighlightStroke: "#75539e",
                            borderCapStyle: 'butt',
                            borderJoinStyle: "miter",
                            borderWidth: 4,
                            tension: 0.3,

                        },
                    ],

                };
                setLineData(lineSettings);
                setIsLoading(false);
            }
        });
    }

    const getDashboardReport = () => {
        NetworkRequest("api/Report/GetDashboardReport", {
            id: selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : selectedTenantId,
            checkTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin"
        }).then((response) => {
            if (response.data.resultCode == 0) {
                let report = response.data.detail;
                setReportModel(report);
                var sum = 0;
                var i;


                const data = {
                    labels: [translate("Tamamlanan Modül") + " (%) ", translate("Tamamlanmayan Modül ") + " (%) "],
                    datasets: [
                        {
                            label: translate("Tamamlananlar"),
                            data: [report.moduleComletingLook.completingRate, report.moduleComletingLook.notCompletingRate],
                            backgroundColor: [
                                'rgba(181, 204, 24,0.5)',
                                'rgba(251, 189, 8, 0.5)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(181, 204, 24,0.5)',
                                'rgba(251, 189, 8, 0.5)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                    options: {
                        plugins: {
                            datalabels: {
                                display: false,
                                backgroundColor: '#ccc',
                                borderRadius: 3,
                                font: {
                                    color: 'red',
                                    weight: 'bold',
                                },

                            },
                            doughnutlabel: {
                                labels: [{
                                    text: '550',
                                    font: {
                                        size: 20,
                                        weight: 'bold'
                                    }
                                }, {
                                    text: 'total'
                                }]
                            }
                        },
                        responsive: true,
                        legend: {
                            position: 'bottom',
                        },


                    },
                };
                setDougnuthData(data);
            }
            setIsLoading(false);
        });
    }
    const changeDropdown = (event, data) => {
        setSelectedTenantId(data.value);
    }

    const getSubTenantList = () => {
        NetworkRequest('/api/Tenants/GetChildTenants', { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            if (response.data.resultCode == 0) {
                let list = response.data.detail;
                if (list.length > 0) {
                    let arr: any = [{
                        text: translate("Tümü"),
                        key: "1",
                        value: "1"
                    }];
                    _.each(list, (doc) => {
                        arr.push({
                            text: doc.displayName,
                            key: doc.tenantId,
                            value: doc.tenantId
                        });
                    });
                    setTenantList(arr);
                }
            }
        });
    }

    useEffect(() => {
        // console.log(user)
        if (userRole == "HeadAdmin") {
            getSubTenantList()
        }
    }, [])

    useEffect(() => {
        setIsLoading(true);
        getDashboardGraphs();
        getDashboardReport();
    }, [selectedTenantId])

    useEffect(() => {
        if (traceLogModel == undefined)
            return;
        let logins = traceLogModel.loginLine[loginAndComplatePage]
        let completeds = traceLogModel.completeds[loginAndComplatePage]
        let funcResult = prepareLabelsAndData(logins, completeds)


        let lineSettings = {
            labels: funcResult.labels,
            datasets: [
                {
                    label: translate('Girişler'),
                    data: funcResult.logins,
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(75,192,192,1)",
                    pointHighlightStroke: "#75539e",
                    borderCapStyle: 'butt',
                    borderJoinStyle: "miter",
                    borderWidth: 4,
                    tension: 0.3
                },
                {
                    label: translate("Tamamlamalar"),
                    data: funcResult.completeds,
                    fill: true,
                    // borderColor: 'rgb(53, 162, 235)',
                    // backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    borderColor: "#742774",
                    pointHighlightStroke: "#75539e",
                    borderCapStyle: 'butt',
                    borderJoinStyle: "miter",
                    borderWidth: 4,
                    tension: 0.3
                },
            ]
        };

        setLineData(lineSettings);
    }, [loginAndComplatePage]);

    return (
        <div style={styles.container}>
            <div>
                {userRole == "HeadAdmin" &&
                    <span style={styles.dropdownContainer}>
                        <span>{translate("Şirket:")}</span> <Dropdown
                            fluid selection
                            value={selectedTenantId}
                            name="selectedTenantIds"
                            options={tenantList}
                            size="big"
                            placeholder={"Şirket Seçiniz"}
                            onChange={changeDropdown}
                            style={styles.dropdownReport}
                        /></span>
                }

            </div>
            <div style={styles.dashboardStyle}>
                {isLoading
                    &&
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Loader size={"huge"} active inline='centered' />
                    </div>
                }

                {!isLoading
                    &&
                    <Grid style={{ width: "100%" }} columns={2}>
                        <Grid.Row>
                            <Grid.Column>

                                <div className={"adminDashboardLineGraph"}>
                                    <Header style={{ fontSize: "1.5em", color: "#6c6c6c" }} as='h3'>
                                        {translate("Kullanım İstatistikleri")}
                                    </Header>

                                    {lineData != undefined
                                        ?
                                        <motion.div
                                            animate={lineData != undefined ? { opacity: [0, 1] } : { opacity: [1, 0] }}
                                            transition={{ duration: 1 }}
                                        >
                                            <Button.Group className={"adminDashboardLineButtonGroup"} fluid={true}>
                                                <Button
                                                    className={loginAndComplatePage == 0 ? "ingIndigo" : undefined}
                                                    active={loginAndComplatePage == 0}
                                                    onClick={() => setLoginAndComplatePage(0)}>{translate("Günlük")}</Button>
                                                <Button 
                                                    className={loginAndComplatePage == 1 ? "ingIndigo" : undefined}
                                                    active={loginAndComplatePage == 1}
                                                    onClick={() => setLoginAndComplatePage(1)}>{translate("Haftalık")}</Button>
                                                <Button className={loginAndComplatePage == 2 ? "ingIndigo" : undefined}
                                                    active={loginAndComplatePage == 2}
                                                    onClick={() => setLoginAndComplatePage(2)}>{translate("Aylık")}</Button>
                                            </Button.Group>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "1rem",
                                                marginBottom: "1rem",
                                                width: "100%"
                                            }}>
                                                <Line options={lineOptions} data={lineData} />
                                            </div>
                                        </motion.div>
                                        :
                                        <motion.div
                                            animate={lineData == undefined ? { opacity: [0, 1] } : { opacity: [1, 0] }}
                                            transition={{ duration: 1 }}
                                        >
                                            <Loader active inline='centered' />
                                        </motion.div>
                                    }
                                </div>

                                {reportModel != undefined
                                    &&
                                    <DashboardReportDatatable reportModel={reportModel} />
                                }


                            </Grid.Column>
                            <Grid.Column>
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <div className={"adminDashboardLineGraph"}>
                                            <Header style={{ fontSize: "1.5em", color: "#6c6c6c" }} as='h3'>
                                                {translate("Sistem Kayıtları")}
                                            </Header>


                                            <motion.div
                                                animate={logs != undefined ? { opacity: [0, 1] } : { opacity: [1, 0] }}
                                                transition={{ duration: 1 }}
                                            >
                                                <Grid columns={2} className="dashboardLogsContainer mt6">
                                                    {logs != undefined && logs.map((log) => (
                                                        <LogCard key={uuidv4()} Log={log} />
                                                    ))}

                                                </Grid>

                                                <Button
                                                    className={"ingSky"}
                                                    circular={true}
                                                    style={{ marginTop: "10%" }}
                                                    onClick={() => {
                                                        navigate("/reports?page=1")
                                                    }}

                                                >
                                                    {translate("Tüm Loglara Git")}
                                                </Button>

                                            </motion.div>

                                            <motion.div
                                                className={"mainPageGraphLoaderClass"}
                                                animate={logs == undefined ? { opacity: [0, 1] } : { opacity: [1, 0] }}
                                                transition={{ duration: 1 }}
                                            >
                                                <Loader active inline='centered' />
                                            </motion.div>



                                        </div>
                                    </Grid.Row>
                                    {dougnuthData != undefined && (dougnuthData.datasets[0].data[0] != 0 || dougnuthData.datasets[0].data[1] != 0) &&
                                        <Grid.Row>
                                            <Grid.Column style={{ padding: "unset" }}>
                                                <div style={{ marginTop: "-1.6%" }} className={"adminDashboardLineGraph"}>
                                                    <Header style={{ fontSize: "1.5em", color: "#6c6c6c" }} as='h3'>
                                                        {translate("Modül Tamamlama Oranları")}
                                                    </Header>

                                                    <div className="thisIsCenter">
                                                        <div style={{ width: "57%", marginTop: "1rem" }}>
                                                            <Pie data={dougnuthData} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }

            </div>
        </div>
    );
}

export default Dashboard;