import React, {useEffect, useState} from 'react';
import {Button, Container, Flag, Header, Icon, Input, Label, Loader} from "semantic-ui-react";
import {useNavigate, useParams} from "react-router-dom";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import TranslateHelper from "../../../helper/TranslateHelper";

const EditTranslationKeyword = () => {
    var {translate} = TranslateHelper();
    const {id} = useParams()
    const [isLoader, setIsLoader] = useState(true);
    const [isLoaderForSave, setIsLoaderForSave] = useState(false);
    const [keywordDetail, setKeywordDetail] = useState<any>([]);
    const [selectedWord, setSelectedWord] = useState();
    const navigate = useNavigate();

    const saveKeyword = () => {
        setIsLoaderForSave(true);
        NetworkRequest("/api/Translation/SaveSelectedKeyword", keywordDetail).then((response) => {
            //
            if (response.data.resultCode == 0) {
                toast(translate(response.data.message), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
            } else {
                toast(translate("Bir hata meydana geldi"), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
            }

            setIsLoaderForSave(false);
        });
    }

    const handleInputChange = (e, index) => {
        let newArr = [...keywordDetail]; // copying the old datas array
        newArr[index].translatedWord = e.target.value; //key and value
        setKeywordDetail(newArr);
    }

    useEffect(() => {
        let data = {
            Id: id
        }

        NetworkRequest("/api/Translation/GetKeywordByTranslationId", data).then((response) => {
            //
            if (response.data.resultCode == 0) {
                let data = response.data.detail;
                setSelectedWord(data.length > 0 && data[0].word)
                setKeywordDetail(data);
            }

            setIsLoader(false);
        });
    }, []);

    return (
        <Container>
            {isLoader ? <Loader size="huge" active inline='centered'/> :
                <div>
                    <Button
                        onClick={() => navigate(-1)}
                        color={"orange"}
                        icon
                        labelPosition='left'
                    >
                        <Icon name='long arrow alternate left'/>
                        {translate("Geri dön")}
                    </Button>
                    <Header as='h2'>{translate("Seçilen Kelime:")} {selectedWord}</Header>

                    <div style={{display: "flex", flexDirection: "column", gap: "1em", width: "100%", marginTop: "5%"}}>
                        {keywordDetail.map((keyword, index) => (
                            <div key={index}>

                                <Input
                                    fluid={true}
                                    labelPosition='left'
                                    type='text'
                                    placeholder={keyword.flag}
                                >
                                    <Label><Flag name={keyword.flag}/></Label>
                                    <input onChange={(e) => {
                                        handleInputChange(e, index)
                                    }} name={keyword.flag} value={keyword.translatedWord}/>
                                </Input>
                            </div>
                        ))}
                    </div>

                    <div style={{marginTop: "5%"}}>
                        <Button disabled={isLoaderForSave} loading={isLoaderForSave} color={"green"}
                            onClick={saveKeyword}>{translate("Kaydet")}</Button>
                    </div>


                </div>
            }

        </Container>
    );
};

export default EditTranslationKeyword;