import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Button, Dropdown, Grid, Icon, Image} from 'semantic-ui-react';
import Logo from '../../../images/home/v2/logo_standart.png';

import '../Home.css';
import {motion} from 'framer-motion';
import {selectedLanguageContext, translationListContext} from "../../../Store";
import {ILanguageDropdown} from "../../../interfaces/interfaces";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import TranslateHelper from "../../../helper/TranslateHelper";
import {toast} from "react-toastify";
import {isLandscapeTablet} from "../../../helper/HelperFunctions";


const HomeV2Header = (props) => {
    var {translateFromEng} = TranslateHelper();

    var _ = require('lodash');
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");

    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [translationList, setTranslationList] = useContext(translationListContext);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [languageList, setLanguageList] = useState<ILanguageDropdown[]>([]);


    const location = useLocation();
    const [activePage, setActivePage] = useState("/");
    const [isOpenMobileMenu, setIsOpenMobileMenuOpen] = useState(false);
    const setCurrentPage = (pageName) => {
        setActivePage(pageName);
    }

    const getActiveLanguages = () => {
        if (languageListDropdown.length == 0) {
            NetworkRequest("/api/Language/GetActiveLanguages", null).then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;
                    // setLanguageList(activeLanguages);
                    setLanguageList(oldArray => [...oldArray, activeLanguages]);


                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: "",
                            value: l.value
                        }

                        if (languageForDropdown.key == "tr-TR" || languageForDropdown.key == "en-US") {
                            setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                        }

                    })


                    checkClientClaim(activeLanguages);
                }
            });
        }
    }

    useMemo(getActiveLanguages, [selectedLanguage]);

    const checkClientClaim = (languageList: any) => {
        NetworkRequest('/api/Home/CheckClientClaim').then((response) => {
            if (response.data.resultCode == 0) {
                let lang = _.find(languageList, (doc) => {
                    return doc.key == response.data.detail.value;
                });

                if (lang != undefined) {
                    setSelectedDefaultLanguage(lang.value);
                    setSelectedLanguage(lang);
                } else {
                    setSelectedDefaultLanguage("us");
                }
            } else {
                setSelectedDefaultLanguage("us");
            }
        });
    }
    const selectLanguagePreference = (event, data) => {
        let lang = _.find(languageList[0], (doc) => {
            return doc.value == data.value;
        });

        if (lang == undefined) return;

        NetworkRequest('/api/Home/AddOrUpdateClientClaim', {
            claimType: 'Language',
            value: lang.key
        }).then((response) => {
            if (response.data.resultCode == 0) {
                setSelectedDefaultLanguage(lang.value);
                setSelectedLanguage(lang);

                toast("Your language preferences have been saved.", {
                    type: 'success',
                    theme: 'light',
                    position: 'top-center',
                    autoClose: 3000
                });

                // setTimeout(() => {
                //     window.location.reload();
                // }, 4000);

            }
        });
    }


    useEffect(() => {
        document.body.style.cssText = isOpenMobileMenu ? 'overflow: hidden !important' : 'overflow: auto !important';
    }, [isOpenMobileMenu]);


    return (
        <Grid.Row style={{
            minHeight: isOpenMobileMenu ? "95vh" : "86px",
            borderBottomRightRadius: isOpenMobileMenu ? "40px" : "unset",
            borderBottomLeftRadius: isOpenMobileMenu ? "40px" : "unset",
            paddingTop: isLandscapeTablet() ? "2%" : "auto"
        }} className={"homeV2Header"} centered columns={2} textAlign='center'>

            {/* logo section*/}
            <Grid.Column computer={3} mobile={6}>
                <Link to="/home">
                    <Image className={"homeV2HeaderLogoImage"} src={Logo} size='small'/>
                </Link>
            </Grid.Column>

            <Grid.Column mobile={3} className={"homeV2MobileBurger"}>
                <Button onClick={() => {
                    setIsOpenMobileMenuOpen(!isOpenMobileMenu)
                }} className={"homeV2MobileBurgerButton"} circular={true} size={"big"} color={"blue"}
                        icon='align justify'/>
            </Grid.Column>

            <motion.div
                className={"mobileBurgerMenuContainer"}
                animate={isOpenMobileMenu ? {
                    opacity: [0, 1],
                    display: "flex",
                    marginTop: ["0%", "3%"]
                } : {
                    opacity: [1, 0],
                    display: "none",
                    marginTop: ["3%", "0%"]
                }}
                transition={{duration: isOpenMobileMenu ? 1 : .1}}
            >

                {/*<div style={{*/}
                {/*    display: isOpenMobileMenu ? "inline-flex" : "none"*/}
                {/*}} className={"mobileBurgerMenuContainer"}>*/}
                <div style={{
                    color: location.pathname == "/platform" ? "white" : "#636363",
                    backgroundColor: location.pathname == "/platform" ? "#de1a6b66" : "auto"
                }} className={"mobileMenuItem"}>{translateFromEng("Platform")}</div>

                <Link style={{
                    color: location.pathname == "/pricing" ? "white" : "#636363",
                    backgroundColor: location.pathname == "/pricing" ? "#de1a6b66" : "auto"
                }} className={"mobileMenuItem"} to="/pricing">
                    {translateFromEng("Pricing")}
                </Link>

                <Link style={{
                    color: location.pathname == "/customers" ? "white" : "#636363",
                    backgroundColor: location.pathname == "/customers" ? "#de1a6b66" : "auto"
                }} className={"mobileMenuItem"} to="/customers">
                    {translateFromEng("Customers")}
                </Link>

                <Link style={{
                    color: location.pathname == "/partner" ? "white" : "#636363",
                    backgroundColor: location.pathname == "/partner" ? "#de1a6b66" : "auto"
                }} className={"mobileMenuItem"} to="/partner">
                    {translateFromEng("Partner")}
                </Link>

                <Link style={{
                    color: location.pathname == "/aboutUs" ? "white" : "#636363",
                    backgroundColor: location.pathname == "/aboutUs" ? "#de1a6b66" : "auto"
                }} className={"mobileMenuItem"} to="/aboutUs">
                    {translateFromEng("About Us")}
                </Link>

                <Link style={{
                    color: location.pathname == "/homeLogin" ? "white" : "#636363",
                    backgroundColor: location.pathname == "/homeLogin" ? "#de1a6b66" : "auto"
                }} className={"mobileMenuItem"} to="/homeLogin">
                    {translateFromEng("Login")}
                </Link>


                <label className={"languagePreferenceLabelClass"} htmlFor="languagesDropdown">
                    {translateFromEng("Language Preference")}

                </label>
                <div className={"languagePreferenceDropdownContainerClass"}>
                    <Dropdown
                        name={"languagesDropdown"}
                        fluid={true}
                        className="greyInputDropdown moduleDetailLanguageSelectDropdown"
                        placeholder={translateFromEng("Language Preference")}
                        clearable
                        search
                        selection
                        //value={selectedDefaultLanguage}
                        // value={selectedLanguage.value}
                        value={selectedDefaultLanguage}
                        // defaultValue={selectedDefaultLanguage}
                        options={languageListDropdown}
                        onChange={(event, data) => {
                            selectLanguagePreference(event, data)
                        }}
                    />
                </div>

                <Link style={{marginTop: "10%"}} onClick={() => {
                    setIsOpenMobileMenuOpen(false);
                }} to={"/requestdemo"}><Button className={"homeV2HeaderRequestDemoButtonClass"} size={"big"}
                                               fluid={true} color='violet' circular={true}>
                    {translateFromEng("Request a demo")}

                </Button></Link>

                <Link to={"/newTenant"} className={"mobileMenuItemStarted"}>{translateFromEng("Get started")}</Link>
            </motion.div>

            {/* menu section*/}
            <Grid.Column className={"homeV2HeaderMenuItems"} computer={13}>

                <Grid>
                    <Grid.Row className={"homeV2HeaderLinks"}>
                        <Grid.Column>
                            <span
                                style={{color: location.pathname == "/platform" ? "#d91173" : "black"}}
                                className={"homeV2Link"}>
                                          {translateFromEng("Platform")}
                            </span>
                        </Grid.Column>

                        <Grid.Column>
                            <Link className={"homeV2Link"} to="/pricing">
                                <span
                                    style={{color: location.pathname == "/pricing" ? "#d91173" : "black"}}
                                    className={"homeV2Link"}>
                                    {translateFromEng("Pricing")}
                                </span>
                            </Link>
                        </Grid.Column>

                        <Grid.Column>
                            <Link className={"homeV2Link"} to="/customers">
                            <span
                                style={{color: location.pathname == "/customers" ? "#d91173" : "black"}}
                                className={"homeV2Link"}>
                                {translateFromEng("Customers")}
                            </span>
                            </Link>
                        </Grid.Column>

                        <Grid.Column>
                            <Link className={"homeV2Link"} to="/partner">
                                <span
                                    className={"homeV2Link"}
                                    style={{color: location.pathname == "/partner" ? "#d91173" : "black"}}
                                >{translateFromEng("Partner")}</span>
                            </Link>
                        </Grid.Column>

                        <Grid.Column>
                            <Link className={"homeV2Link"} to="/aboutUs">
                            <span
                                style={{color: location.pathname == "/aboutUs" ? "#d91173" : "black"}}
                                className={"homeV2Link"}>
                                          {translateFromEng("About Us")}
                            </span>
                            </Link>
                        </Grid.Column>

                        <Grid.Column>
                            {/*<span className={"homeV2SelectLanguage homeV2Link"}>En <Icon size="large"*/}
                            {/*                                                             name="world"/> </span>*/}

                            <div className={"languagePreferenceDropdownContainerClass"}>
                                <Dropdown
                                    name={"languagesDropdown"}
                                    fluid={true}
                                    className="greyInputDropdown moduleDetailLanguageSelectDropdown"
                                    
                                    clearable
                                    // search
                                    selection
                                    //value={selectedDefaultLanguage}
                                    // value={selectedLanguage.value}
                                    value={selectedDefaultLanguage || "tr"}
                                    // defaultValue={selectedDefaultLanguage}
                                    options={languageListDropdown}
                                    onChange={(event, data) => {
                                        selectLanguagePreference(event, data)
                                    }}
                                />
                            </div>
                        </Grid.Column>

                        <Grid.Column>
                            <Link className={"homeV2Link"} to={"/homeLogin"}>
                                <span
                                    className={"homeV2Link"}
                                    style={{color: location.pathname == "/homeLogin" ? "#d91173" : "black"}}>
                                    {translateFromEng("Login")}
                                </span>
                            </Link>
                        </Grid.Column>

                        <Grid.Column className="homeV2dividerColumn">
                            <span>|</span>
                        </Grid.Column>

                        <Grid.Column>
                            <Link to={"/requestdemo"}><Button inverted color='violet' circular={true}>
                                {translateFromEng("Request a demo")}
                            </Button></Link>
                        </Grid.Column>

                        <Grid.Column className="homeV2dividerColumn">
                            <Button onClick={(e) => {
                                window.scrollTo({
                                    top: document.documentElement.scrollHeight,
                                    behavior: 'smooth',
                                });
                            }} className={"homeV2StartedButton"} circular={true}>
                                {translateFromEng("Get started")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>
    )
}

export default HomeV2Header;