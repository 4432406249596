import React, { useEffect, useState } from "react";
import $ from 'jquery';
import './VideoGallery.css';
import videojs from 'video.js';
import { Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import TranslateHelper from '../helper/TranslateHelper';
const VideoGallery = props => {
    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const videoRef = React.useRef<any>(null);
    const playerRef = React.useRef(null);
    const [videoList, setVideoList] = useState(props.videoList);
    const [currentUrl, setCurrentUrl] = useState("");

    const [videoJsOptions, setVideoJsOptions] = useState(
        {
            autoPlay: true,
            controls: true,
            responsive: true,
            fluid: true,
            attributes: {
                controlsList: 'nodownload'
            },
            sources: videoList.map((x) => ({ src: x.fileUrl, type: 'video/mp4' })),
            playbackRates: [0.5, 1, 1.5, 2],
            //tracks: [{ mode: 'showing', kind: "captions", label: "English", language: "en", src:"https://kot-politiken.s3-eu-west-1.amazonaws.com/2019/114_en.vtt.txt" }] //vtt olacak.
        }
    );

    useEffect(() => {
        setInterval(function () {
            var activeIndex = 0;

            var childs = $(".carousel-indicators").children()
            for (let index = 0; index < childs.length; index++) {
                if (childs[index].classList.value == "active") {
                    activeIndex = index;
                    $(".cardList").children(".active").removeClass("active")
                    $(".cardList").children("#i" + activeIndex).addClass("active");
                }
            }
        }
            , 200);
    }, []);

    const videoEnd = (videoUrl) => {
        var _clone = _.clone(videoList);
        var video = _clone.find(x => x.fileUrl == videoUrl);

        video.isWatched = true;
        setVideoList(_clone);

        var unWatcheds = _clone.filter(x => x.isWatched != true);
        if (unWatcheds.length == 0) {
            ////debugger;
            if (!props.isPreview)
                props.videoEnd(true);
        } else {
            toast(translate("Sonraki Videoya Geçiliyor..."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 1000 });
            ////debugger;
            setTimeout(() => {
                setCurrentUrl(unWatcheds[0].fileUrl)
            }, 1000)
        }
    }
    useEffect(() => {
        var _clone = _.clone(videoList);
        _clone[0].isSelected = true;
        setCurrentUrl(_clone[0].fileUrl);
        setVideoList(_clone);
    }, [])
    useEffect(() => {
        var list = typeof videoList == 'string' ? JSON.parse(videoList) : videoList
        var srcs = list.map((x) => ({ src: x.fileUrl, type: 'video/mp4' }))
        setVideoJsOptions({
            ...videoJsOptions,
            autoPlay: true,
            sources: srcs
        })
    }, [videoList]);

    useEffect(() => {

        if (videoJsOptions != undefined && videoJsOptions?.autoPlay) {
            setTimeout(() => {
                let player: HTMLVideoElement = document.getElementById("vjs_video_558_html5_api") as HTMLVideoElement;

                if (player == undefined || player == null) {
                    player = document.getElementsByClassName("vjs-tech")[0] as HTMLVideoElement;
                    if (player == undefined || player == null)
                        return;
                }
                //try {
                //    player.play();
                //} catch (e) {
                //    //
                //}
            }, 1000)
        }
    }, [videoJsOptions]);
    useEffect(() => {
        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
                videojs.log('player is ready');
                //onReady && onReady(player);
            });

            player.on('timeupdate', () => {
                //console.log("player.currentTime: ", player.currentTime());
            });
        }
    }, [videoJsOptions, videoRef]);

    return (
        <>
            <div className="fontVideoJS">
                <div data-vjs-player >
                    {/*currentSrc={videoList[0].fileUrl}*/}
                    {/*onEnded={(e) => { props.videoEnd() }}*/}
                    <video ref={videoRef}
                        src={currentUrl}
                        onEnded={() => { videoEnd(currentUrl) }}
                        onContextMenu={(e) => { e.preventDefault() }}
                        className='video-js vjs-big-play-centered vjs-matrix'
                    />
                </div>
            </div>
            <div className="bottomCards">

                <div className="cardList">
                    {videoList.map((video, i) => (
                        <div key={video.id} className={video.fileUrl == currentUrl ? "myCard active" : video.isWatched ? "myCard watched" : "myCard"} id={"i" + i} onClick={() => setCurrentUrl(video.fileUrl)}>
                            <div className="cardHeader">
                                <video muted src={video.fileUrl} width="150" />

                            </div>
                            <div className="cardBody">
                                <div className="cardLabel1">
                                    {video.name}
                                </div>

                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )

}

export default VideoGallery;

