import * as React from 'react';
import {ILanguageDropdown } from "../../interfaces/interfaces";
import {useState, useEffect, useCallback, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button, Checkbox, Dropdown, Icon, Image, Input, Loader, Message, Transition} from 'semantic-ui-react';
import './HomeLogin.css';
import {NetworkRequest} from '../../helper/NetworkRequest';
import {MessageTypes} from '../../components/Message';
import MobileHeader from './MobileHeader';
import Footer from './Footer';
import {toast} from 'react-toastify';
import {isMobile} from "react-device-detect";
/*import BuddyReCaptcha from '../../components/BuddyReCaptcha';*/
import Wave from "react-wavify";
import {selectedLanguageContext, translationListContext} from "../../Store";
import {displayProtectedAppDoc} from '../../helper/DisplayProtectedDocument';
import {motion} from 'framer-motion';
import TranslateHelper from '../../helper/TranslateHelper';
import { DataHandler } from '../../helper/DataHelper';
import Logo from '../../images/home/v2/logo_standart.png';
import People from '../../images/home/v2/login.png';
import DefaultLoginPhoto from '../../images/home/v2/login.png';
import secureLocalStorage from 'react-secure-storage';
import CaptchaEmojies from '../../components/CaptchaEmojies';
import {getRandomIntInclusive, isLandscapeTablet, tabTitle} from "../../helper/HelperFunctions";
import { useSearchParams } from 'react-router-dom';

const HomeLogin = (props) => {
    var _ = require('lodash');
    var { translateFromEng, translate } = TranslateHelper();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [languageList, setLanguageList] = useState<ILanguageDropdown[]>([]);


/*    const [translationList, setTranslationList] = useContext(translationListContext);*/
    const [isLoading, setIsLoading] = useState(false);
    const [useCaptcha, setUseCaptcha] = useState<boolean>(true);
    const [useLdap, setUseLdap] = useState<boolean>(true);
    const [isError, setIsError] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [isFormOk, setIsFormOk] = useState(false);
    const [showTenantName, setShowTenantName] = useState(true);
    const [tenantName, setTenantName] = useState("");
    const [loginPhotoUrl, setLoginPhotoUrl] = useState("");
    const [user, setUser] = useState<any>({email: "", password: ""});
    const [message, setMessage] = useState<any>({Header: "", Description: "", MessageType: MessageTypes.Error});
    const [isOpen2FactorScreen, setIsOpen2FactorScreen] = useState(false);
    const [twoFactorMessage, setTwoFactorMessage] = useState("");
    const [twoFactorCode, setTwoFactorCode] = useState("");
    const [isAllFieldFill, setIsAllFieldFill] = useState(false);
    const [emailAnimation, setEmailAnimation] = useState(false);
    const [isCheckCodeStarting, setIsCheckCodeStarting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [responseMessage, setResponseMessage] = useState<any>(null);
    const [sendMailAgainLoading, setSendMailAgainLoading] = useState<any>(false);
    const [logo, setLogo] = useState(Logo);


    const [twoFactorAuthCodeObject, setTwoFactorAuthCodeObject] = useState({
        twoFactor1: "",
        twoFactor2: "",
        twoFactor3: "",
        twoFactor4: "",
        twoFactor5: "",
        twoFactor6: ""
    })
    const styles = {
        loginPhotoUrl: {
            width: "100%",
            height: "99vh",
            objectFit: "cover" as "cover"
        }
    }

    //const getSelectedLanguageId = () => {
    //    let tenantId = DataHandler.getUserDataWithKey("tenantId");
    //    debugger;
    //    if (tenantId != undefined) {
    //        let languageData = {
    //            tenantId: tenantId,
    //            userId: DataHandler.getUserDataWithKey("userId")
    //        }
    //        NetworkRequest("/api/Language/GetUserClaimLanguage", languageData).then((response) => {
    //            if (response.data.resultCode == 0) {
    //                let language = response.data.detail;

    //                if (translationList.length == 0) {
    //                    debugger;
    //                    setSelectedLanguage(language);
    //                }
    //            }
    //        });
    //    }
    //}

    const getActiveLanguages = () => {
        if (languageListDropdown.length == 0) {
            NetworkRequest("/api/Language/GetActiveLanguages", null).then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;
                    // setLanguageList(activeLanguages);
                    setLanguageList(oldArray => [...oldArray, activeLanguages]);


                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: "",
                            value: l.value
                        }

                        if (languageForDropdown.key == "tr-TR" || languageForDropdown.key == "en-US") {
                            setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                        }

                    })


                    checkClientClaim(activeLanguages);
                }
            });
        }
    }

    React.useMemo(getActiveLanguages, [selectedLanguage]);

    const checkClientClaim = (languageList: any) => {
        NetworkRequest('/api/Home/CheckClientClaim').then((response) => {
            if (response.data.resultCode == 0) {
                let lang = _.find(languageList, (doc) => {
                    return doc.key == response.data.detail.value;
                });

                if (lang != undefined) {
                    setSelectedDefaultLanguage(lang.value);
                    setSelectedLanguage(lang);
                } else {
                    setSelectedDefaultLanguage("us");
                }
            } else {
                setSelectedDefaultLanguage("us");
            }
        });
    }

    const redirectByUserRole = (userRole: string, isActive: boolean, isHaveMoreThanOneModule: boolean, user?: any) => {
        if (userRole == "User" && !isActive) {
            window.location.href = "/siteOff";
            return;
        }
        if (isHaveMoreThanOneModule) {
            window.location.href = "/userdashboard";
            return;
        }

  
        if (userRole == "SuperAdmin") {
            DataHandler.pushSuperAdminLoginData(user);
        }
        
        setTimeout(() => {
            window.location.href = "/";
        }, 400)
        
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13 && isFormOk) {
            login();
        }
    }

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const setSiteUrl = () => {
        NetworkRequest("/api/Utilities/SetSiteUrl");
    }

    const getClientInformation = () => {

        NetworkRequest("/api/ClientInformations/GetClientInformation").then(response => {
            if (response.data.resultCode == 0) {
           
                setShowTenantName(true);
                let data = response.data.detail.rememberMe;
                setRememberMe(data);
                  setTenantName(response.data.detail.tenantName);
                if (data == true) {
                    setUser({...user, email: response.data.detail.email});
                }
            }
        });
    }

    //const getLoginImage = () => {
    //    NetworkRequest("/api/SiteSetting/GetLoginImage").then(response => {

    //        if (response.data.resultCode == 0) {
    //            setLoginPhotoUrl(response.data.detail);
    //        }
    //    });
    //}

    const getSiteSettings = (tenantName) => {
        let data = {
            tenantName: tenantName
        }
        NetworkRequest("/api/SiteSetting/GetForHomeLogin", data).then(response => {
       
            if (response.data.resultCode == 0) {
                var data = response.data.detail;

                if (data != null) {

                    setLoginPhotoUrl((data?.loginPhotoUrl == "" || data?.loginPhotoUrl == null) ? DefaultLoginPhoto : data?.loginPhotoUrl);
                    tabTitle(data?.settingTitle);
                    
                    setLogo(data?.logoUrl);

                    var link = document.getElementById("siteFavIcon");

                    var favicon = "data:image/x-icon;" + data.favIconUrl?.split(";")[1]?.trim();
                    if (favicon && link != undefined) {
                        link.setAttribute('href', favicon);
                    }
                } else {
                    setLoginPhotoUrl(DefaultLoginPhoto);
                }
            } else {
                setLoginPhotoUrl(DefaultLoginPhoto);
            }
        });
    }

    const login = () => {
        setIsLoading(true);
        setIsError(false);
        try {
            if (rememberMe) {
                window.localStorage.setItem("rememberMeEmail", user.email);
                window.localStorage.setItem("rememberMeEmail", user.password);
            } else {
                window.localStorage.removeItem("rememberMeEmail");
            }
        } catch (e) {

        }
        let userData = {
            Email: user.email,
            Password: user.password,
            rememberMe: rememberMe,
            tenantName: tenantName
        }
        NetworkRequest("/Authentication/Login", userData).then(response => {
            /* console.log("response", response);*/
           /* debugger;*/
            if (response.data.resultCode === 0) {
             
                DataHandler.removePages();
                let userData = response.data.detail;
       
                setSelectedLanguage(userData.userLanguage);
                DataHandler.pushLoginData(userData);

                setTimeout(() => {
                    redirectByUserRole(userData.userRole[0], userData.isActive, userData.isHaveMoreThanOneModule, userData);
                }, 2000);

            } else if (response.data.resultCode == 7) {
                window.location.href = "/resetPassword/" + user.email;
                setIsLoading(false);
            } else if (response.data.resultCode == 15) {
                //2factorAuthEnabled
                setIsLoading(false);
                setIsOpen2FactorScreen(true);
                setTwoFactorMessage(translateFromEng(response.data.message));
            } 
            else {
                //showError();
                //showError(response.data.message);

                toast(translateFromEng(response.data.message || response.data.detail.response.data), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
                setIsLoading(false);
            }
        });
    }

    const handlerTwoFactor = (e: any) => {
        ////console.log(e.target.name, " - ", e.target.value);
        let inputValue = e.target.value.trim();
        if (inputValue != "" && inputValue != undefined && inputValue != null) {


            let objectName = e?.target?.name?.split("-");
            objectName = objectName[0] + objectName[1];

            setTwoFactorAuthCodeObject({
                ...twoFactorAuthCodeObject,
                [objectName]: e.target.value
            });


            let inputNumber = parseInt(e?.target?.name?.split("-")[1]) + 1;
            let nextInputElement: any = document.getElementsByName("twoFactor-" + inputNumber);
            if (nextInputElement[0] != undefined && nextInputElement[0] != null) {
                nextInputElement[0].focus();
            }
        }


    }

    const check2FactorCode = () => {
        setResponseMessage(null);
        setErrorMessage(null);
        setIsCheckCodeStarting(true);
        let twoFactorAuthData = {
            EmailOrUsername: user.email,
            Code: twoFactorCode.toUpperCase()
        }
        NetworkRequest("/api/UserAuthenticationCode/CheckCode", twoFactorAuthData).then(response => {
            //
            if (response.data.resultCode === 0) {
                DataHandler.removePages();
                let userData = response.data.detail;
                setSelectedLanguage(userData.userLanguage);
                DataHandler.pushLoginData(userData);

                redirectByUserRole(userData.userRole[0], userData.isActive, userData.isHaveMoreThanOneModule);
            } else {
                let message = response.data.message;
                setErrorMessage(message);
            }

            setIsCheckCodeStarting(false);
        });
    }

    const sendCodeMailAgain = () => {
        setEmailAnimation(false);
        setResponseMessage(null);
        setSendMailAgainLoading(true);
        setErrorMessage(null);
        let twoFactorAuthData = {
            EmailOrUsername: user.email,
            Code: twoFactorCode.toUpperCase()
        }
        NetworkRequest("/api/UserAuthenticationCode/SendCodeMailAgain", twoFactorAuthData).then(response => {
            //
            if (response.data.resultCode === 0) {
                setResponseMessage("Code sent again");
            } else {
                let message = response.data.message;
                setErrorMessage(message);
            }

            setSendMailAgainLoading(false);
        });
    }

    const pasteHandler = (e) => {
        var clipboardData, pastedData;

        clipboardData = e.clipboardData || (window as any).clipboardData;
        pastedData = clipboardData.getData('Text').trim().toUpperCase();

        if (pastedData != "" && pastedData.trim != undefined && pastedData.length == 6) {
            let pastedDataArray = pastedData?.split("");
            setTwoFactorCode(pastedData);

            pastedDataArray.map((character, index) => {
                var selectedInput: any = document.getElementsByName("twoFactor-" + (index + 1))

                if (selectedInput != undefined) {
                    selectedInput[0].value = character;
                    selectedInput[0].focus();
                }
            });

            setIsAllFieldFill(true);
        }
    }

    const selectLanguagePreference = (event, data) => {

        let lang = _.find(languageList[0], (doc) => {
            return doc.value == data.value;
        });

        if (lang == undefined) return;

        NetworkRequest('/api/Home/AddOrUpdateClientClaim', {
            claimType: 'Language',
            value: lang.key
        }).then((response) => {
            if (response.data.resultCode == 0) {
                setSelectedDefaultLanguage(lang.value);
                setSelectedLanguage(lang);

                toast("Your language preferences have been saved.", {
                    type: 'success',
                    theme: 'light',
                    position: 'top-center',
                    autoClose: 3000
                });

                // setTimeout(() => {
                //     window.location.reload();
                // }, 4000);

            }
        });
    }

    useEffect(() => {
        if (!useCaptcha) {
            setIsFormOk(true);
        }
    }, [useCaptcha])

    useEffect(() => {
        //console.log("twoFactorAuthCodeObject", twoFactorAuthCodeObject);
        setTwoFactorCode("");
        if (twoFactorAuthCodeObject != undefined && twoFactorAuthCodeObject != null) {
            for (const [key, value] of Object.entries(twoFactorAuthCodeObject)) {
                setTwoFactorCode(oldString => oldString + value);
            }
        }
    }, [twoFactorAuthCodeObject])

    useEffect(() => {
        if (twoFactorCode.trim().length == 6) {
            setIsAllFieldFill(true);

            setTimeout(() => {
                check2FactorCode();
            }, 500)

        } else {
            setIsAllFieldFill(false);
        }

        // setIsAllFieldFill(twoFactorCode.length == 6) 
    }, [twoFactorCode])

    useEffect(() => {
        try {

            const tenantName = searchParams.get('tenant');
            
            if(tenantName != "" && tenantName != null && tenantName.length != 0) {
                setTenantName(tenantName);
            }
            
            setSiteUrl();
            /*getSiteSettings();*/
            // getClientInformation();
            //getLoginImage();
            var rememberMeEmail = window.localStorage.getItem("rememberMeEmail");
            var password = window.localStorage.getItem("rememberMeEmail");
            if (rememberMeEmail != undefined) {
                setUser({password: password == null ? "" : password, email: rememberMeEmail})
            }
        } catch (e) {

        }
        /*console.log(DataHandler.getUseCaptcha())*/
        setUseCaptcha(DataHandler.getUseCaptcha());
        setUseLdap(DataHandler.getUseLdap());
    }, []);

    useEffect(() => {
        setTimeout(() => {
            getSiteSettings(tenantName);
        }, 1000)
    }, [tenantName])



    return (
        <div>
            <div className="loginMobileBg">
                <div className="loginBgImageContent">
                    <motion.div
                        style={{ width:"100%"}}
                        animate={loginPhotoUrl != "" ? { opacity: [0, 1] } : { opacity: [1, 0] }}
                        transition={{ duration: 1 }}
                    >
                        <img src={loginPhotoUrl} style={styles.loginPhotoUrl} />
                    </motion.div>

                    <motion.div
                        className={"loginPageGraphLoaderClass"}
                        animate={loginPhotoUrl == "" ? { opacity: [0, 1] } : { opacity: [1, 0] }}
                        transition={{ duration: 1 }}
                    >
                        <Loader active inline='centered' />
                    </motion.div>
                    
                </div>
                {!isMobile
                    &&
                    <MobileHeader/>
                }
                <div style={{ width: isLandscapeTablet() || isMobile ? "100%" : "70%" }}>
                    <div className="formContainerLogin">
                        <div className="formStyleLogin">
                            <div className="loginScreenForm">
                                <motion.div
                                    className={"loginFormElements"}
                                    animate={{
                                        opacity: [0, 1],
                                        marginLeft: ["-10px", "0px"]
                                    }}
                                    transition={{duration: 1, delay: .4}}
                                >
                                    <div className="logoContainerLogin">
                                        <a href="/home">
                                            <Image id="logoLoginImage"
                                                   src={logo}
                                                   className="homeBuddyLogoLogin"/>
                                        </a>
                                    </div>
                                </motion.div>


                                {(isMobile || isLandscapeTablet()) &&
                                    <Link to={"/"}>
                                        <div className={"homeLoginLogoContainerClass"} style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "100%",
                                            marginTop: "5%"
                                        }}>
                                            <Image id="logoLoginImage-2"
                                                   src={logo}
                                                   width={"60%"}
                                            />
                                        </div>
                                    </Link>
                                    
                                }
                                {/*{isMobile &&*/}
                                {/*    <Wave fill='#ffffff'*/}
                                {/*          paused={false}*/}
                                {/*          options={{*/}
                                {/*              height: 20,*/}
                                {/*              amplitude: 20,*/}
                                {/*              speed: 0.15,*/}
                                {/*              points: 4*/}
                                {/*          }}*/}
                                {/*    />*/}
                                {/*}*/}
                                <div className={"loginFormElements"}>
                                    <motion.div
                                        style={{zIndex: !isOpen2FactorScreen ? "9" : "1"}}
                                        className={"loginFormElements loginScreenInputsContainer"}
                                        animate={!isOpen2FactorScreen ? {
                                            opacity: [0, 1],
                                            marginTop: ["-10px", "0px"]
                                        } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                                        transition={{duration: !isOpen2FactorScreen ? .6 : .8}}
                                    >

                                        <div className={"homeLoginSelectLanguageContainer"}>
                                            <div
                                                className="loginScreenInputText">{translateFromEng("Language")}</div>
                                            <Dropdown
                                                name={"languagesDropdown"}
                                                fluid={true}
                                                className={"greyInputDropdown homeLoginLanguageSelectBox"}
                                                placeholder={translateFromEng("Language Preference")}
                                                clearable
                                                selection
                                                value={selectedDefaultLanguage}
                                                options={languageListDropdown}
                                                onChange={(event, data) => {
                                                    selectLanguagePreference(event, data)
                                                }}
                                            />
                                        </div>

                                        {showTenantName
                                            &&
                                            <div className={"homeLoginShowTenantNameContainer"}>
                                                <div
                                                    className="loginScreenInputText">{translateFromEng("Company name")}</div>
                                                <Input
                                                    disabled={false}
                                                    className={"greyInput"}
                                                    size="huge"
                                                    value={tenantName}
                                                    onChange={(e) => {
                                                        setTenantName(e.target.value);
                                                    }}
                                                    name="tenantName"
                                                    onKeyUp={onKeyUp}
                                                    id="tenantInput"
                                                    placeholder={translateFromEng('Company name')}
                                                />

                                            </div>
                                        }
                                        
                                        <div>

                                            <div className={"loginScreenInputText"}>
                                                {translateFromEng('Username') + " - " + translateFromEng("Email")}
                                            </div>
                                            <Input
                                                className={"greyInput"}
                                                size={"huge"}
                                                value={user.email}
                                                onChange={handleChange}
                                                name="email"
                                                onKeyUp={onKeyUp}
                                                id="loginInput"
                                                placeholder={translateFromEng('Username')}
                                            />
                                        </div>

                                        <div>
                                            <div className={"loginScreenInputText"}>{translateFromEng('Password')}</div>
                                            <Input
                                                className={"greyInput"}
                                                size={"huge"}
                                                value={user.password}
                                                onChange={handleChange}
                                                name="password"
                                                onKeyUp={onKeyUp}
                                                type="password"
                                                id="loginInputPasswordField"
                                                placeholder={translateFromEng('Password')}
                                            />
                                        </div>

                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <Checkbox
                                                className="roundedButton"
                                                label={translateFromEng("Remember me")}
                                                onChange={(e, data) => {
                                                    setRememberMe(data.checked != undefined ? data.checked : false)
                                                }}
                                                checked={rememberMe}
                                            />
                                        </div>

                                        {/*<div id={"buddyReCaptchaID"}>*/}
                                        {/*    <BuddyReCaptcha setIsFormOk={setIsFormOk}/>*/}
                                        {/*    <div style={{width: "100%", display: "none"}}>*/}
                                        {/*        <div id="buddyCaptcha">*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                        <div className={"loginScreenInputContainer"}>
                                            <Button
                                                circular={true}
                                                fluid={isMobile}
                                                color="blue"
                                                size={"huge"}
                                                onClick={login}
                                                disabled={useCaptcha && (isLoading || !isFormOk)}
                                                loading={isLoading}
                                            >
                                                {translateFromEng('Login')}
                                            </Button>
                                        </div>


                                        <div className={"homeLoginForgotyPasswordClass"}>
                                            {useLdap && 
                                                <div>
                                                    <Link to={'/ldapLogin'}>{translateFromEng("LDAP")} {translateFromEng("Login")}</Link>
                                                </div>
                                            }
                                           
                         
                                            <Link style={{ marginTop: "5%" }}
                                                to={"/resetPassword"}>{translateFromEng("I forgot my password")}</Link>

                                            <div className={"tenantVersionInfoClass"}>v:1.0.20</div>
                                            {isMobile
                                                &&
                                                <div>
                                                    <div style={{marginTop: "5%"}}>
                                                        <Icon title="Sayfa Yenile"
                                                              name="redo"
                                                              style={{color: "#4183c4"}}
                                                              onClick={() => {
                                                                  window.location.reload();
                                                              }}
                                                        /> {translateFromEng("Refresh page")}
                                                    </div>

                                                    <Link style={{marginTop: "5%"}}
                                                          to={"/"}>{translateFromEng("Back")}</Link>

                                                </div>
                                            }
                                        </div>

                                    </motion.div>

                                    {isOpen2FactorScreen &&
                                        <motion.div
                                            style={{zIndex: isOpen2FactorScreen ? "9" : "1"}}
                                            className="twoFactorAuthCodeSpace"
                                            animate={isOpen2FactorScreen ? {
                                                opacity: [0, 1],
                                                marginTop: ["-10px", "0px"]
                                            } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                                            transition={{duration: isOpen2FactorScreen ? .6 : 0}}
                                        >
                                            <div className="twoFactorCheckButtonSpace">
                                                <Button
                                                    icon
                                                    labelPosition='left'
                                                    onClick={() => {
                                                        setIsOpen2FactorScreen(false);
                                                    }}
                                                    size="big"
                                                    circular={true}
                                                    disabled={isCheckCodeStarting || sendMailAgainLoading}
                                                >
                                                    <Icon name='long arrow alternate left'/>
                                                    {translateFromEng("Back")}
                                                </Button>
                                            </div>


                                            <div className="twoFactorMessageBox">{twoFactorMessage}</div>

                                            <motion.div
                                                onClick={sendCodeMailAgain}
                                                onMouseEnter={() => {
                                                    if (sendMailAgainLoading) return null;

                                                    setEmailAnimation(true)
                                                }}
                                                onMouseLeave={() => {
                                                    setEmailAnimation(false)
                                                }}
                                                animate={emailAnimation ? {
                                                    marginLeft: ["0%", "5%"], color: ["#cccccc", "#0000ff"]
                                                } : {marginLeft: ["5%", "0%"], color: ["#0000ff", "#cccccc"]}}
                                                transition={{duration: emailAnimation ? .6 : .8}}
                                                className="sendEmailAgainText">
                                                <motion.div
                                                    animate={emailAnimation ? {
                                                        rotate: [0, 360]
                                                    } : sendMailAgainLoading ? {rotate: [0, 360, 0]} : {rotate: [360, 0]}}
                                                    transition={{
                                                        duration: emailAnimation ? .6 : .8,
                                                        repeat: sendMailAgainLoading ? Infinity : 0
                                                    }}
                                                >
                                                    <Icon name="repeat"/>
                                                </motion.div>
                                                {translateFromEng("Resend verification code")}

                                            </motion.div>

                                            <div className="twoFactorAuthInputsSpace">
                                                <input
                                                    onPaste={pasteHandler}
                                                    disabled={isCheckCodeStarting}
                                                    onChange={handlerTwoFactor} name="twoFactor-1" maxLength={1}
                                                    className="twoFactorAuthCode"/>
                                                <input disabled={isCheckCodeStarting} onChange={handlerTwoFactor}
                                                       name="twoFactor-2" maxLength={1}
                                                       className="twoFactorAuthCode"/>
                                                <input disabled={isCheckCodeStarting} onChange={handlerTwoFactor}
                                                       name="twoFactor-3" maxLength={1}
                                                       className="twoFactorAuthCode"/>
                                                <input disabled={isCheckCodeStarting} onChange={handlerTwoFactor}
                                                       name="twoFactor-4" maxLength={1}
                                                       className="twoFactorAuthCode"/>
                                                <input disabled={isCheckCodeStarting} onChange={handlerTwoFactor}
                                                       name="twoFactor-5" maxLength={1}
                                                       className="twoFactorAuthCode"/>
                                                <input disabled={isCheckCodeStarting} onChange={handlerTwoFactor}
                                                       name="twoFactor-6" maxLength={1}
                                                       className="twoFactorAuthCode"/>
                                            </div>

                                            <div className="twoFactorCheckButtonSpace">
                                                <Button
                                                    icon
                                                    labelPosition='left'
                                                    disabled={!isAllFieldFill || isCheckCodeStarting || sendMailAgainLoading}
                                                    loading={isCheckCodeStarting}
                                                    onClick={check2FactorCode}
                                                    size="big"
                                                    color="green"
                                                    circular={true}
                                                >
                                                    <Icon name='thumbs up outline'/>
                                                    {translateFromEng("Verify")}
                                                </Button>
                                            </div>

                                            <div className={"errorMessageContainer"}>
                                                {errorMessage != null &&
                                                    <motion.div
                                                        className={"errorMessageFor2FactorAuth"}
                                                        animate={errorMessage != null ? {
                                                            opacity: [0, 1],
                                                        } : {opacity: [1, 0]}}
                                                        transition={{duration: errorMessage != null ? 1.1 : 0}}
                                                    >
                                                        <Icon size={"big"} name={"exclamation triangle"}/>
                                                        {translateFromEng(errorMessage)}
                                                    </motion.div>
                                                }

                                                {responseMessage != null &&
                                                    <motion.div
                                                        className={"responseMessageFor2FactorAuth"}
                                                        animate={responseMessage != null ? {
                                                            opacity: [0, 1],
                                                        } : {opacity: [1, 0]}}
                                                        transition={{duration: responseMessage != null ? 1.1 : 0}}
                                                    >
                                                        <Icon size={"big"} name={"check"}/>
                                                        {translateFromEng(responseMessage)}
                                                    </motion.div>
                                                }
                                            </div>
                                        </motion.div>
                                    }

                                </div>
                                <Transition visible={isError} animation='scale' duration={500}>
                                    <div>
                                        <Message
                                            Header={translateFromEng(message.Header)}
                                            Description={translateFromEng(message.Description)}
                                            MessageType={message.MessageType}
                                        />
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!isMobile &&
                <div className="footerLogin">
                    {/*<Footer />*/}
                </div>
            }
        </div>
    );
}

export default HomeLogin;