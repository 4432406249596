import * as React from 'react';
import {useState, useEffect} from 'react';

import {
    Button,
    Card,
    Checkbox,
    Dropdown,
    Header,
    Icon,
    Input,
    Loader,
    Popup,
    Table,
    Transition
} from 'semantic-ui-react';
import {datableFilterChange, digitallicaConfirm, prepareDataForTable} from '../helper/HelperFunctions';
import {NetworkRequest} from '../helper/NetworkRequest';
import DataTable from './DataTable';
import {IDataTable, IQuestion, ISurveyQuestion} from '../interfaces/interfaces';
import {toast} from 'react-toastify';

import {DataHandler} from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

enum SuveyQuestionType {
    "Çoktan Seçmeli" = 1,
    "Serbest Metin" = 2,
    "2 Şık" = 4
}

const SurveyQuestionAttachToSurvey = (props) => {
    var {translate} = TranslateHelper();
    const _ = require('lodash');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterText, setFilterText] = useState("");
    const [surveyId] = useState(props.id);
    const [survey, setSurvey] = useState({title: ""});
    const [question, setQuestion] = useState({id: "", nameSurname: "", email: ""});
    const [surveyAndSurveyQuestion, setSurveyAndSurveyQuestion] = useState({id: "", surveyId: "", questionId: ""});
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isSigned, setIsSigned] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const [filterData] = useState({
        filterText: filterText,
        currentPage: currentPage,
        isActive: isActive,
        isDeleted: isDeleted,
        startingDate: startingDate,
        dueDate: dueDate,
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });

    const onComplate = (id) => {
        let questionData = {surveyQuestionId: id, surveyId: surveyId};
        if (!isSigned) {
            digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
                NetworkRequest("/api/SurveyAndSurveyQuestion/Add", questionData).then(response => {
                    toast("Eklendi", {type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500});
                    getQuestions(filterData);
                });
            }, () => {
                toast("İşlem iptal edildi.", {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            });
        } else {

            digitallicaConfirm(
                translate("Silmek istediğinize emin misiniz?"),
                () => {
                    NetworkRequest("/api/SurveyAndSurveyQuestion/Delete", questionData).then(response => {
                        toast("Silindi", {type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500});
                        getQuestions(filterData);
                    });
                },
                () => {
                    toast("İşlem iptal edildi.", {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2500
                    });
                }
            );
        }
    }

    const onChangeSigned = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsSigned);
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const moveDown = (id) => {
        let requestData = {
            surveyQuestionId: id,
            surveyId: surveyId
        }
        NetworkRequest("/api/SurveyAndSurveyQuestion/MoveDown", requestData).then(response => {
            if (response.data.resultCode === 0) {
                getQuestions(filterData);
            }
        });
    }

    const moveUp = (id) => {
        let requestData = {
            surveyQuestionId: id,
            surveyId: surveyId
        }
        NetworkRequest("/api/SurveyAndSurveyQuestion/MoveUp", requestData).then(response => {
            if (response.data.resultCode === 0) {
                getQuestions(filterData);
            }
        });
    }

    const collectiveProggress = (e, data, dtm) => {
        if (data.value == "")
            return;
        dtm = dtm == undefined ? dataTableModel : dtm;
        digitallicaConfirm(
            "Toplu işlem yapacaksınız, emin misiniz?",
            () => {
                let rows: Array<any> = [];
                ////debugger;
                let datatableClone = _.cloneDeep(dtm);
                let filtereeds = datatableClone.data.filter(x => x.secretData[0].isSelected == true);

                filtereeds.map(x => {
                    rows.push({surveyQuestionId: x.secretData[0].id, surveyId: surveyId})
                })


                ////console.log(rows);

                switch (data.value) {
                    case 1:
                        NetworkRequest("/api/SurveyAndSurveyQuestion/AddCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                getQuestions(filterData);
                                toast("Ankete " + response.data.detail.length + " Soru eklendi!", {
                                    type: 'info',
                                    theme: 'dark',
                                    position: 'top-center',
                                    autoClose: 3500
                                });

                            }
                        });
                        break;
                    case 2:
                        NetworkRequest("/api/SurveyAndSurveyQuestion/DeleteCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                getQuestions(filterData);
                                toast("Anketten silinen soru sayısı" + " :" + response.data.detail.length, {
                                    type: 'info',
                                    theme: 'dark',
                                    position: 'top-center',
                                    autoClose: 3500
                                });
                            }
                        });
                        break;
                    default:
                }
            },
            () => {
                toast("İşlem iptal edildi.", {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );

    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            <Checkbox
                onChange={setAllSelected}
            />,
            translate("Sıra Numarası"),
            translate("Soru Metni"),
            translate("Soru Tipi"),
            translate("Şıklar"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {
                        id: "",
                        isDeleted: false
                    }
                ]
            }
        ],
        transactions: [
            {
                icon: isSigned ? "delete" : "plus",
                desc: isSigned ? translate("Çalışan Sil") : translate("Çalışan ekle"),
                color: isSigned ? "red" : "green",
                function: onComplate,
                type: isSigned ? "delete" : "add",
            },
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Ekli Sorular"),
                    defaultValue: isSigned,
                    key: "isSigned",
                    type: "boolean",
                    onChange: onChangeSigned
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "",
        isAttachingPage: true,
        setRowSelected: setRowSelected,
        collectiveProgresses:
            {
                options: [{key: 2, text: translate('Toplu Kaldır'), value: 2}],
                event: collectiveProggress
            },
        orderTransactions: [moveUp, moveDown]
    });

    //prepare data for table
    const prepareData = (data, count) => {
        let model = prepareDataForTable(data, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'userId',
            'Id',
            'id',
            'UpdatedDate',
            'DeletedDate',
            'IsDeleted',
            "A",
            "B",
            "C",
            "D",
            "E",
            !isSigned ? 'rank' : ''
        ]);

        //setDataTableModel({
        //    header: dataTableModel.header,
        //    data: [],
        //    transactions: dataTableModel.transactions,
        //    filter: dataTableModel.filter,
        //    pagination: dataTableModel.pagination,
        //    addRowLink: dataTableModel.addRowLink
        //});

        window.setTimeout(() => {
            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Ekli Sorular"),
                            defaultValue: isSigned,
                            key: "isSigned",
                            type: "boolean",
                            onChange: onChangeSigned
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);
    }

    //get table data from database
    const getQuestions = (filterData?) => {
        let uri = !isSigned ? "/api/SurveyAndSurveyQuestion/GetNotAssignedQuestions" : "/api/SurveyAndSurveyQuestion/GetAssignedQuestions";
        NetworkRequest(uri, {
            pagination: filterData,
            id: surveyId
        }).then((response) => {
            setIsLoading(false);
            ////

            if (response.data.resultCode == 0) {
                //toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                ////
                let designedQuestionArray = Array<ISurveyQuestion>();

                let questions = response.data.detail.list;
                questions.map(question => {
                    let questionJSON = question.questionJSON;
                    let options =
                        "<b>A: </b>" + questionJSON.a + ", " +
                        "<b>B: </b>" + questionJSON.b
                    options += question.surveyQuestionType == 1 ?
                        ", <b>C: </b>" + questionJSON.c + ", " +
                        "<b>D: </b>" + questionJSON.d + ", " +
                        "<b>E: </b>" + questionJSON.e : "";

                    let newSurveyFormat = {
                        id: question.id,
                        Id: question.id,
                        rank: isSigned ? question.rank : -1,
                        Body: questionJSON?.body?.embedCode,
                        A: (question.surveyQuestionType == 1 || question.surveyQuestionType == 4) ? question.questionJSON.a : "",
                        B: (question.surveyQuestionType == 1 || question.surveyQuestionType == 4) ? question.questionJSON.b : "",
                        C: question.surveyQuestionType == 1 ? questionJSON.c : "",
                        D: question.surveyQuestionType == 1 ? questionJSON.d : "",
                        E: question.surveyQuestionType == 1 ? questionJSON.e : "",
                        SurveyQuestionType: SuveyQuestionType[question.surveyQuestionType],
                        IsDeleted: question.isDeleted,
                        UpdatedDate: question.updatedDate,
                        DeletedDate: question.deletedDate,
                        Options: (question.surveyQuestionType == 1 || question.surveyQuestionType == 4) ? options : "",
                        IsActive: question.isActive,
                    }

                    designedQuestionArray.push(newSurveyFormat);
                });

                ////console.log(designedQuestionArray);
                //debugger;

                prepareData(designedQuestionArray, response.data.detail.count);
            } else {
                toast(translate(response.data.message), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000});
            }
        });
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getQuestions(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, isSigned]);

    //search function
    useEffect(() => {
        ////console.log(filterText);
    }, [filterText]);

    useEffect(() => {
        ////console.log("dataTableModel: ", dataTableModel);
    }, [dataTableModel]);

    useEffect(() => {
        if (!isSigned) {
            dataTableModel.transactions = [
                {
                    icon: "plus",
                    desc: translate("Soru ekle"),
                    color: "green",
                    function: onComplate,
                    type: "add",
                },
            ];
            dataTableModel.collectiveProgresses = {
                options: [{key: 1, text: translate('Toplu Ekle'), value: 1}],
                event: collectiveProggress
            }
            dataTableModel.header = [
                <Checkbox
                    onChange={setAllSelected}
                />,
                translate("Soru Metni"),
                translate("Soru Tipi"),
                translate("Şıklar"),
                translate("Aktif"),
                translate("İşlemler")
            ]
            dataTableModel.orderTransactions = undefined;
            setDataTableModel({...dataTableModel});
        } else {
            dataTableModel.transactions = [
                {
                    icon: "delete",
                    desc: translate("Soru sil"),
                    color: "red",
                    function: onComplate,
                    type: "delete",
                }
            ];
            dataTableModel.collectiveProgresses = {
                options: [{key: 2, text: translate('Toplu Sil'), value: 2}],
                event: collectiveProggress
            }
            dataTableModel.header = [
                <Checkbox
                    onChange={setAllSelected}
                />,
                translate("Sıra Numarası"),
                translate("Soru Metni"),
                translate("Soru Tipi"),
                translate("Şıklar"),
                translate("Aktif"),
                translate("İşlemler")
            ]
            dataTableModel.orderTransactions = [moveUp, moveDown];
            setDataTableModel({...dataTableModel});
        }


    }, [isSigned]);

    useEffect(() => {
        ////console.log(surveyAndSurveyQuestion);
    }, [surveyAndSurveyQuestion]);

    useEffect(() => {
        ////console.log("SurveyQuestionAttachToSurvey surveyId: ", surveyId);
        if (surveyId != "" && surveyId != undefined) {
            let surveyData = {
                id: surveyId
            }
            NetworkRequest("/Surveys/GetById", surveyData).then((response) => {
                let result = response.data;
                ////console.log("Get Survey result", result);
                if (result.resultCode == 0 && result.detail != undefined) {
                    setSurvey({title: result.detail.title});
                }
            });
        }
    }, [surveyId]);

    return (
        <>
            <div>
                <Header style={{
                    fontSize: "2em",
                    margin: "auto",
                    marginBottom: "2%",
                    textAlign: "center"
                }}
                        disabled
                        as="h3"
                >
                    {survey.title} - {isSigned ? translate("Ekli Sorular") : translate("Eklenmemiş Sorular")}
                </Header>

                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <DataTable
                        dataTable={dataTableModel}
                        isAllSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        selectAllHandler={selectAllHandler}
                        isLoading={isLoading}
                    />
                </Transition>
            </div>
        </>

    );
}

export default SurveyQuestionAttachToSurvey;