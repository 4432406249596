import * as React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTable from '../../../components/DataTable';

import { DataHandler } from '../../../helper/DataHelper';
import { digitallicaConfirm, prepareDataForTable } from '../../../helper/HelperFunctions';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';
import { IDataTable } from '../../../interfaces/interfaces';

const ApplicationHelpers = () => {

    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const [helperListCurrentPage, setHelperListCurrentPage] = useState(1);
    const [helperListFilterText, setHelperListFilterText] = useState("");

    const removeApplicationHelper = (id) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            NetworkRequest("api/ApplicationHelpers/RemoveAppHelper", { id: id }).then((response) => {
                if (response.data.resultCode === 0) {
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 3000 });
                    getAppHelperList();
                } else {
                    toast(translate("Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
        });        
    }

    const [appHelpers, setAppHelpers] = useState<IDataTable>({
        header: [
            translate("Tip"),
            translate("Soru"),
            translate("Cevap"),
            translate("Aktif Mi?"),
            translate("İşlemler")
        ],
        data: [{
            showData: [],
            secretData: []
        }],
        transactions: [{
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/editApplicationHelper",
            type: "edit"
        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: removeApplicationHelper
        }],
        filter: {
            setSearch: setHelperListFilterText,
            search: helperListFilterText,
        },
        pagination: {
            count: 1,
            setCurrentPage: setHelperListCurrentPage, currentPage: helperListCurrentPage
        },
        addRowLink: "/addApplicationHelper",
        noCheckBox: true,
        addButons: []
    });

    //prepare data for table
    const appHelperListPrepareData = (data, count) => {
        if (data.length == 0 && helperListCurrentPage > 1) {
            setHelperListCurrentPage(1);
            return;
        }
        //hidden column
        let model = prepareDataForTable(data, [
            "id",
            "tenantId",
            "applicationHelperTypeId",
            "details",
            "createDateTime"
        ]);

        setAppHelpers({
            ...appHelpers,
            data: model,
            filter: {
                setSearch: setHelperListFilterText,
                search: helperListFilterText
            },
            pagination: {
                count: Math.ceil(count / 25),
                setCurrentPage: setHelperListCurrentPage, currentPage: helperListCurrentPage
            },
            addRowLink: appHelpers.addRowLink
        });
    }

    //get table data from database
    const getAppHelperList = () => {
        let filterData = {
            filterText: helperListFilterText,
            currentPage: helperListCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        ////debugger;
        NetworkRequest("api/ApplicationHelpers/GetAppHelpersDataTable", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;

                ////console.log("getNotiList: ", result);
                appHelperListPrepareData(result.list, result.count);
            } else {
                toast(translate("Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const selectAllHandler = (model) => {

    }

    useEffect(() => {
        getAppHelperList();
    }, [helperListFilterText, helperListCurrentPage])

    return (
        <div className="generalContainerForWhiteBg">
            <DataTable dataTable={appHelpers}
                isAllSelected={false}
                selectAllHandler={selectAllHandler}
                isLoading={false}
            />
        </div>
    );
}

export default ApplicationHelpers;