import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dropdown, Checkbox, Button, Form, TextArea } from 'semantic-ui-react'
import { ITest, ShowCorrectAnswerSettings } from '../../../interfaces/interfaces';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const EditQuiz = (props) => {
    var {translate} = TranslateHelper();
    let { id } = useParams();
    const [testId] = useState(id);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isFormOk, setIsFormOk] = useState(true);
    const [test, setTest] = useState<ITest>({
        title: "",
        description: "",
        time: 0,
        successScore: 0,
        settings: {
            shuffleQuestionOrder: false,
            shuffleAnswerOrder: false,
            repeatAnswer: {
                isActive: false,
                numberOfRepetitions: 0,
                type: 1
            },
            showPoint: false,
            showCorrectAnswer: null,
            navigateThroughQuestions: false
        }
    });

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [time, setTime] = useState("");
    const [successPoint, setSuccessPoint] = useState("");

    const [shuffleQuestionOrder, setShuffleQuestionOrder] = useState(false);
    const [shuffleAnswerOrder, setShuffleAnswerOrder] = useState(false);
    const [showPoint, setShowPoint] = useState(false);
    const [navigateThroughQuestions, setNavigateThroughQuestions] = useState(false);

    const [repeatSettings, setRepeatSettings] = useState({
        isActive: false,
        numberOfRepetitions: 0,
        type: 2
    });

    const [repeatIsActive, setRepeatIsActive] = useState("");
    const [repeatType, setRepeatType] = useState("");
    const [showCorrectAnswerSettings, setShowCorrectAnswerSettings] = useState<ShowCorrectAnswerSettings>({
        isActive: false,
        type: 0
    });
    const [correctIsActive, setCorrectIsActive] = useState("");
    const [correctType, setCorrectType] = useState("");
    const [numberOfRepetitions, setNumberOfRepetitions] = useState(0);
    const [allowRepeatAnswerOption, setAllowRepeatAnswerOption] = useState({
        options: [
            { key: 1, text: translate('Kapalı'), value: 1 },
            { key: 2, text: translate('Sınırlı Tekrar Sayısı'), value: 2 },
            { key: 3, text: translate('Başarılı Olana Kadar'), value: 3 }
        ],
        selected: 1
    });
    const [showCorrectAnswerFinalOption, setShowCorrectAnswerFinalOption] = useState({
        options: [
            { key: 1, text: translate('Kapalı'), value: 1 },
            { key: 2, text: translate('Her zaman'), value: 2 },
            { key: 3, text: translate('Başarılı olduğunda'), value: 3 }
        ],
        selected: ""
    });

    let styles = {
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column"
        },

        flexDiv: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "baseline",
            gap: "1em",
            fontWeight: "bold" as "bold"
        },

        w50: {
            width: "50%"
        },

        w60: {
            width: "60%"
        },
        w15: {
            width: "15%"
        },

        w30: {
            width: "30%"
        },
        w100: {
            width: "100%"
        },

        buttonGroup: {
            marginTop: "60px"
        },
        form: {
            width: "100%",
            display: "flex",
            flexDirection: "row" as "row",
            gap: "2rem"
        }
    }

    const handleChange = (e) => {
        setTest({
            ...test,
            [e.target.name]: e.target.value
        });
    }

    const getTestById = (id: string) => {
        let testData = {
            id: testId
        }

        NetworkRequest("/Quiz/GetById", testData).then((response) => {
            if (response.data.resultCode == 0) {
                let testData = response.data.detail;
                let settings = JSON.parse(testData.settings);
                setTitle(testData.title);
                setDesc(test.description);
                setTime(testData.time);

                ////console.log('testData', testData);
                setTest({
                    ...test,
                    title: testData.title,
                    description: testData.description,
                    time: testData.time,
                    successScore: testData.successScore,
                });

                setSuccessPoint(testData.successPoint);

                ////console.log(settings);

                setShuffleQuestionOrder(settings.shuffleQuestionOrder);
                setShuffleAnswerOrder(settings.shuffleAnswerOrder);
                setShowPoint(settings.showPoint);
                setNavigateThroughQuestions(settings.navigateThroughQuestions);
                setShowCorrectAnswerSettings(settings.showCorrectAnswer);
                setRepeatSettings(settings.repeatAnswer);
                setNumberOfRepetitions(settings.repeatAnswer.numberOfRepetitions);
                setAllowRepeatAnswerOption({
                    options: allowRepeatAnswerOption.options,
                    selected: settings.repeatAnswer.type
                });
                setShowCorrectAnswerFinalOption({
                    options: showCorrectAnswerFinalOption.options,
                    selected: settings.showCorrectAnswer.type
                });
                ////console.log(settings);
                ////console.log(test);
            }
        });
    }

    const save = (isSaveAndAddQuestion?) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            test.settings.navigateThroughQuestions = navigateThroughQuestions;
            test.settings.shuffleAnswerOrder = shuffleAnswerOrder;
            test.settings.shuffleQuestionOrder = shuffleQuestionOrder;
            test.settings.showPoint = showPoint;
            test.settings.repeatAnswer = repeatSettings;
            test.settings.showCorrectAnswer = showCorrectAnswerSettings;

            setTest(test);
            let testData = {
                id: testId,
                title: test.title.trim(),
                description: test.description.trim(),
                successScore: parseInt(test.successScore.toString()),
                time: parseInt(test.time.toString()),
                settings: JSON.stringify(test.settings),
                searchField: "-"
            };
            ////console.log(testData);
            NetworkRequest("/Quiz/Update", testData).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    if (isSaveAndAddQuestion == 2) {
                        window.location.href = "/questionAdd/" + response.data.detail.id;
                    } else if (isSaveAndAddQuestion == 3) {
                        window.location.href = "/quizContentPool/" + response.data.detail.id;
                    } else {
                        window.location.href = "/quizes"
                    }

                    toast(translate("Sınav başarılı bir şekilde güncellendi."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                } else {
                    toast(translate(response.data.message), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2000 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const selectCorrentAnswers = (e, data) => {
        let control = false;
        if (data.value != undefined && data.value > 1) {
            control = true;
        }
        setShowCorrectAnswerSettings({ ...showCorrectAnswerSettings, isActive: control, type: data.value });
    }

    useEffect(() => {
        if (testId != undefined)
            getTestById(testId)
    }, [testId]);

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                test.successScore,
                test.title,
                test.time
            )
        );
    }, [test]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={styles.container}>
                <Form size="big" style={styles.form}>
                    <div style={styles.w60}>
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Sınav Adı: (*)")}</label>
                                <input
                                    className={"greyInput"}
                                    onChange={handleChange}
                                    name="title"
                                    style={styles.w100}
                                    placeholder={translate("Sınav Adı")}
                                    value={test.title}

                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Açıklama:")}</label>
                                <TextArea
                                    className={"greyInput"}
                                    onChange={handleChange}
                                    name="description"
                                    style={styles.w100}
                                    placeholder={translate("Sınav Açıklaması")}
                                    rows="12"
                                    value={test.description}
                                />
                            </div>
                        </Form.Field>
                    </div>
                    <div>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Tekrara İzin Ver:")}</label>
                                <Dropdown
                                    className={"greyInput"}
                                    onChange={(e, { name, value }) => {
                                        repeatSettings.isActive = false;

                                        if (value != undefined) {
                                            repeatSettings.isActive = true;
                                            repeatSettings.type = Number(value);
                                            setAllowRepeatAnswerOption({ ...allowRepeatAnswerOption, selected: repeatSettings.type })
                                        }
                                    }
                                    }
                                    clearable
                                    options={allowRepeatAnswerOption.options}
                                    selection
                                    placeholder={translate("Seçiniz")}
                                    style={styles.w50}
                                    value={allowRepeatAnswerOption.selected}
                                />
                            </div>
                        </Form.Field>
                        {allowRepeatAnswerOption.selected == 2 &&
                            <Form.Field>
                                <div style={styles.flexDiv}>
                                    <label style={styles.w30}>{translate("Tekrar Sayısı:")} </label>
                                    <input
                                        className={"greyInput"}
                                        value={numberOfRepetitions}
                                        type="number"
                                        min="1"
                                        onChange={e => {
                                            setNumberOfRepetitions(parseInt(e.target.value));
                                            setRepeatSettings({ ...repeatSettings, numberOfRepetitions: parseInt(e.target.value) });
                                        }}
                                        placeholder={translate("Tekrar sayısı")}
                                        style={styles.w50}
                                    />
                                </div>
                            </Form.Field>}


                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Süresi (dk):")}</label>
                                <input
                                    className={"greyInput"}
                                    type="number"
                                    onChange={handleChange}
                                    name="time"
                                    placeholder={translate("Süresi (dk)")}
                                    min="1"
                                    style={styles.w50}
                                    value={test.time}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Başarı Puanı: (*)")}</label>
                                <input
                                    className={"greyInput"}
                                    type="number"
                                    onChange={handleChange}
                                    name="successScore"
                                    style={styles.w50}
                                    placeholder={translate("Başarı Puanı")}
                                    min="0"
                                    value={test.successScore}

                                />
                            </div>

                        </Form.Field>
                        <Form.Field>
                            <div style={{ ...styles.flexDiv, alignItems: "center" }}>
                                <label style={styles.w30}>{translate("Sınav Sonunda Doğru Yanıtları Göster:")}</label>
                                <Dropdown
                                    className={"greyInput"}
                                    onChange={(e, data) => { selectCorrentAnswers(e, data); }}
                                    options={showCorrectAnswerFinalOption.options}
                                    clearable
                                    selection
                                    placeholder={translate("Seçiniz")}
                                    style={styles.w50}
                                    value={showCorrectAnswerSettings.type}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={shuffleQuestionOrder}
                                    onChange={() => {
                                        setShuffleQuestionOrder(!shuffleQuestionOrder);
                                    }
                                    }
                                    toggle
                                    label={translate("Soru Sırasını Karıştır")}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={shuffleAnswerOrder}
                                    onChange={() => {
                                        setShuffleAnswerOrder(!shuffleAnswerOrder)
                                    }
                                    }
                                    toggle
                                    label={translate("Cevap Sırasını Karıştır")}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={showPoint}
                                    name="showPoint"
                                    onChange={() => {
                                        setShowPoint(!showPoint)
                                    }
                                    }
                                    toggle
                                    label={translate("Sınav Sonunda Puanı Göster")}
                                />
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={navigateThroughQuestions}
                                    name="navigateThroughQuestions"
                                    onChange={() => {
                                        setNavigateThroughQuestions(!navigateThroughQuestions)
                                    }
                                    }
                                    toggle
                                    label={translate("Çalışan Sınav Esnasında Sorular Arasında Gezinebilsin")}
                                />
                            </div>
                        </Form.Field>


                    </div>
                </Form >
                <div style={styles.buttonGroup}>
                    <Button className={"roundedButton"}
                        onClick={() => { save(1) }}
                        color='green'
                        disabled={!isFormOk}
                    >
                        {translate("Kaydet")}
                    </Button>

                    <Button className={"roundedButton"}
                        onClick={() => { save(2) }}
                        type='submit'
                        color='blue'
                        disabled={!isFormOk}
                    >
                        {translate("Kaydet ve Yeni Soru Ekle")}
                    </Button>

                    <Button className={"roundedButton"}
                        onClick={() => { save(3) }}
                        type='submit'
                        color='orange'
                        disabled={!isFormOk}
                    >
                        {translate("Kaydet ve Havuzdan Soru Seç")}
                    </Button>
                </div>
            </div >
        </div>

    );

}

export default EditQuiz;