import React, {useEffect, useState} from "react";
import {IDataTable} from "../../../interfaces/interfaces";
import {Checkbox, Loader, Transition} from "semantic-ui-react";
import DataTable from "../../../components/DataTable";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {datableFilterChange, downloadFile, prepareDataForTable} from "../../../helper/HelperFunctions";
import {useParams} from 'react-router-dom';

import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from "../../../helper/DataHelper";

const QuizQuestionDetail = (props) => {
    var {translate} = TranslateHelper();
    const {id} = useParams();
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterData, setFilterData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);

    //const setAllSelected = (data) => {
    //    setIsAllSelected(data.checked);
    //}

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTable, data.checked, 0, setDataTable, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTable, data.checked, 1, setDataTable, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(dataTableModel);
        }, 100);
    }

    const getTests = (filterData?) => {
        NetworkRequest("api/Report/GetQuizDetailReports", filterData).then((response) => {
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    const quizDetailExcelReport = () => {
        let quizData = {
            QuizId: id
        }
        NetworkRequest("api/Report/GetQuizDetailReportToExcel", filterData).then((response) => {
            //console.log("Excelll Çıktı Response", response.data);
            downloadFile(response.data, translate("Sınav Detay Raporu"));
        });
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            quizId: id,
            checkSubTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin"
        }
        setFilterData(filterData);
        getTests(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: [
            translate("Sınav Adı"),
            translate("Modül Adı"),
            translate("Bölüm Adı"),
            translate("Ad"),
            translate("Soyad"),
            translate("Email"),
            translate("İşe Başlama Tarihi"),
            translate("Tamamlama Tarihi"),
            translate("Son Giriş Tarihi"),
            translate("Deneme Sayısı"),
            translate("Puan"),
            translate("Aktif mi"),
            translate("Doğru Yanıt Sayısı"),
            translate("Yanlış Yanıt Sayısı"),
            translate("Başarı Durumu"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: [
            {
                icon: "chart bar",
                desc: translate("Rapor"),
                color: "orange",
                link: "/examReportDetail",
                type: "report"
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        addRowLink: "",
        setRowSelected: setRowSelected,
        isAttachingPage: true
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response;

        //hidden column
        let model = prepareDataForTable(data, [
            'id',
            'searchField',
            'dateOfStartContent'
        ]);
        setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                transactions: dataTable.transactions,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox,
                data: model,
                pagination: {
                    count: Math.ceil(
                        data.length / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
            setIsLoading(false);
        }, 200);
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        {/*{dataTableHtml}*/}
                        <DataTable dataTable={dataTable} isAllSelected={isAllSelected}
                                   exportExcelFunction={quizDetailExcelReport} selectAllHandler={selectAllHandler}
                                   isLoading={isLoading}/>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default QuizQuestionDetail;