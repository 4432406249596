import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Icon, Loader, Modal, Rating, Transition, Image } from 'semantic-ui-react';
import RenderContentVideo from './Content/RenderContentVideo';
import RenderContentHtml from './Content/RenderContentHtml';
import RenderContentQuiz from './Content/RenderContentQuiz';
import RenderContentSurvey from './Content/RenderContentSurvey';
import RenderContentTask from './Content/RenderContentTask';
import { NetworkRequest } from '../helper/NetworkRequest';

import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import moment from 'moment'

import WelcomeBanner from '../pages/CommonPages/WelcomeBanner';
import { isMobile } from 'react-device-detect';
import TranslateHelper from '../helper/TranslateHelper';
import { DataHandler } from '../helper/DataHelper';
import { motion } from 'framer-motion';
/*import loadingGif from '../../../images/loading-gif.gif';*/
import loadingGif from '../images/loading-gif.gif';
import RenderContentScorm from './Content/RenderContentScorm';
enum StartOption {
    'Serbest' = 1,
    'Tarih Seç' = 2,
    'İşe Başladığı Gün' = 4,
    'İşe Başlamadan X Gün önce' = 8,
    'İşe Başladıktan X Gün Sonra' = 9,
    'Önceki İle Birlikte' = 10,
    'Önceki Bittikten Sonra' = 11,
    'Önceki Bittikten X Gün Sonra' = 12
}

enum FinishOption {
    'Serbest' = 1,
    'Tarih Seç' = 2,
    'Işe Başladığı Gün' = 4,
    'Işe Başladıktan x Gün Sonra' = 8,
    'Sisteme Kaydolduğu gün' = 9,
    'Aktif olduktan x hafta sonra' = 10
}

enum UserTypeMessage {
    "İlgili içeriği sizin tamamlamanız gerekmektedir." = 1,
    "Bu içerik Buddy tarafından tamamlanacaktır." = 2,
    "Bu içerik Yönetici tarafından tamamlanacaktır." = 4,
    "Bu içerik İnsan Kaynakları Sorumlusu tarafından tamamlanacaktır." = 8,
    "Bu içerik BT Sorumlusu tarafından tamamlanacaktır." = 10,
    "Bu içerik İdari İşler Sorumlusu tarafından tamamlanacaktır." = 12,
}

enum UserOptions {
    "User" = 1,
    "Buddy" = 2,
    "Yönetici" = 4,
    "İnsan Kaynakları" = 8

}

function RenderContent(props) {

    var { translate } = TranslateHelper();

    let styles = {
        ratingContainer: {
            display: "flex",
            alignContent: "space-around",
            flexDirection: "column" as "column",
            alignItems: "center",
            gap: "1rem"
        },
        contentContainer: {
            display: "flex",
            flexDirection: isMobile ? "column-reverse" as "column-reverse" : "column" as "column",
            minHeight: "300px",
            height: "auto",
            margin: isMobile ? "0 1rem 0 1rem" : "3rem 1rem 5rem 1rem",
            padding: "0 0 2rem 0 "
        },
        startBtnContainer: {
            display: "flex",
            justifyContent: "center",
            minHeight: "300px",
            height: "auto",
            flexDirection: "column" as "column",
            alignItems: "center"
        },
        contentHeader: style({
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "flex-start",
            padding: ".25rem 2rem",
            alignItems: "center",
            position: "relative",
            gap: "1rem",
            height: "100px"
        },
            media({ maxWidth: 820 }, {
                flexDirection: "column" as "column",
                justifyContent: "flex-start",
                marginTop: "1rem",
                height: "auto"
            })
        ),
        ratingBox: style({
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            position: "absolute" as "absolute",
            right: "1rem"

        },
            media({ maxWidth: 820 }, { position: "relative" as "relative", })
        ),
        headerStyle: {
            color: "black !important",
            fontSize: "24px",
            fontWeight: "700"
        },
        bold700: {
            fontWeight: "700"
        },
        contentSettingHeader: style({
            width: "10%",

            alignItems: "center" as "center",

        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        contentSettingDescription: style({
            width: "90%",
            padding: "0 46px 0px"
        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        contentDescriptionStyle: {
            borderRadius: "8px",

            width: "100%",
            minHeight: "100px",
            padding: "25px 31px",
            color: "rgba(41, 40, 40, 1)",
            fontWeight: "400",
            fontSize: "14px"

        },
        contentSettingsContainer: style({
            width: "100%",
            display: "flex",
            padding: ".25rem 2rem",
            marginBottom: "2rem",
            alignItems: "center",
            flexDirection: "row" as "row",
            gap: "1rem"

        },
            media({ maxWidth: 820 }, { flexDirection: "column" })
        ),
        contentTimingContainer: style({
            width: "50%",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center"
        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        contentTimingHeader: {
            width: "30%",
            flexWrap: "wrap" as "wrap"
        },
        contentTimingDetailBox: {
            borderRadius: "5px",
            border: "1px #E9E9E9 solid",
            width: "70%",
            padding: ".5rem 1rem"
        },
        ratingEmojiesContainer: style({
            display: "flex",
            justifyContent: "space-evenly",

            marginTop: "2rem",
            flexWrap: "wrap",
            flexDirection: "row"
        },
            media({ maxWidth: 820 }, { gap: "0.5rem", width: "100%" })
        ),
        emojiesBox: style({
            display: "flex",
            flexDirection: "column" as "column",
            gap: "19px",
            alignItems: "center",
            borderRadius: "8px",
            backgroundColor: "rgba(251, 251, 251, 1)",
            padding: ".5rem .75rem",
            minWidth: "140px",
            textAlign: "center",
            flexWrap: "wrap",
            width: "18%",
            cursor: "pointer"
        },
            media({ maxWidth: 820 }, { width: "100%", minWidth: "0", flexDirection: "column", gap: "8px" })
        ),
        emojieStyle: style({
            width: "45px"
        },
            media({ maxWidth: 820 }, { width: "25px" })
        ),

        fullSizeEmojieStyle: style({
            display: "flex",
            flexDirection: "column" as "column",
            gap: "19px",
            alignItems: "center",
            borderRadius: "8px",
            backgroundColor: "rgba(251, 251, 251, 0.6)",
            padding: ".5rem .75rem",
            minWidth: "140px",
            textAlign: "center",
            flexWrap: "wrap",

        },
            media({ maxWidth: 820 }, { width: "90%", minWidth: "0", flexDirection: "column", gap: "8px" })
        ),
        closeBtnStyle: {
            backgroundColor: "rgba(255, 255, 255,1)",
            boxShadow: "0px 4px 4px rgba(160, 160, 160, 0.25)",
            position: "absolute" as "absolute",
            right: "-12px",
            top: "-12px",
            borderRadius: "100%",
            height: "48px",
            width: "48px",
            display: "flex",
            padding: "12px"
        },
        alignRight: style({

            textAlign: "right" as "right",
            paddingRight: "1rem",
            textIndent: "2rem",
        },
            media({ maxWidth: 820 }, {
                textAlign: "left" as "left",
                textIndent: "0 !important",
            })
        ),
        responsibleUserLabel: style({
            width: "15%"
        },
            media({ maxWidth: 820 }, {
                width: "30%"
            })
        ),
        responsibleUser: style({
            width: "85% !important"
        },
            media({ maxWidth: 820 }, {
                width: "70% !important"
            })
        ),
        cursorNotAllowed: {
            cursor: "not-allowed !important"
        },
        mobileSectionDescriptionContainer: style({
            display: "block",
            marginTop: isMobile ? "4%" : "unset"
        },
            media({ minWidth: 820 }, {
                display: "none"
            })
        ),
        transparent: {
            background: 'rgba(255,255,255,.60)',
        },
        boxBorder: {
            borderRadius: "5px",
            border: "1px #E9E9E9 solid",
        },
        btnDarkOutline: {
            background: "#fff",
            border: "1px solid #292828 !important",

            borderRadius: "8px",
            cursor: "pointer"
        },
        btnDark: {
            backgroundColor: "rgb(41, 40, 40) !important",
            borderRadius: "8px",
            color: "white !important",
            cursor: "pointer"
        }
    }

    var _ = require('lodash');

    const [operationCount, setOperationCount] = useState(0);
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [userRole, setUserRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [htmlContent, setHtmlContent] = useState<any>(<></>);
    const [contentLoading, setContentLoading] = useState(true);
    const [showWelcome, setShowWelcome] = useState(false);
    const [showContentInfo, setShowContentInfo] = useState(false);
    const [loginUser] = useState(props.loginUser);
    const [transactionUser] = useState(props.transactionUser);
    const [showRating, setShowRating] = useState(false);
    const [fromComplete, setFromComplete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [content, setContent] = useState(props.content);
    const [contentId, setContentId] = useState("");
    const [section, setSection] = useState(props.section);
    const [moduleId, setModuleId] = useState(props.moduleId);
    const [history, setHistory] = useState(props.history);
    const [rateModel, setRateModel] = useState<any>();
    const [loginUserId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [transactionUserId] = useState(props.transactionUserId);
    const [eventRequest, setEventRequest] = useState({
        selectedDate: '',
        user: {},
        moduleSection: {},
        sectionContent: {},
    });
    const [isLoading, setIsLoading] = useState(true);

    const getResponsibleUserMessage = (role) => {
        switch (role) {
            case "User":
                return translate(UserTypeMessage[1]);
            case "Buddy":
                return translate(UserTypeMessage[2]);
            case "Manager":
                return translate(UserTypeMessage[4]);
            case "HumanResources":
                return translate(UserTypeMessage[8]);
            case "ItManager":
                return translate(UserTypeMessage[10]);
            case "AdminManager":
                return translate(UserTypeMessage[12]);
        }
        return "";
    }

    const getStartOptionUserMessage = () => {

        let accessModel = getContentAccess(content.id, content.moduleSectionId);
        ////debugger;
        if (accessModel.startEvent != undefined) {
            let rtValue = accessModel.startEvent.eventDisplayName;
            if (accessModel.startEvent.value != undefined && accessModel.startEvent.value != "" && accessModel.startEvent.value != null) {
                if (accessModel.startEvent.eventDisplayName == "Önceki Bittikten X Gün Sonra") {
                    rtValue = translate("Önceki Bittikten Sonra") + ": " + accessModel.startEvent.value + " " + translate("gün");
                } else if (accessModel.startEvent.eventDisplayName == "İşe Başlamadan X Gün Önce") {
                    rtValue = translate("İşe Başlamadan Önce") + ": " + accessModel.startEvent.value + " " + translate("gün");
                } else if (accessModel.startEvent.eventDisplayName == "İşe Başladıktan X Gün Sonra") {
                    rtValue = translate("İşe Başladıktan Sonra") + ": " + accessModel.startEvent.value + " " + translate("gün");
                }
                else
                    rtValue = accessModel.startEvent.eventDisplayName.replace('X', accessModel.startEvent.value);
            }
            if (accessModel.startEvent.valueType == 2) {
                return moment(accessModel.startEvent.value).format("DD/MM/YYYY");
            }
            return rtValue;
        }
        return translate("Serbest");
    }

    const getFinishOptionUserMessage = () => {
        // //debugger;
        let accessModel = getContentAccess(content.id, content.moduleSectionId);
        if (accessModel.finishEvent != undefined) {
            let rtValue = accessModel.finishEvent.eventDisplayName;
            if (accessModel.finishEvent.value != undefined
                && accessModel.finishEvent.value != ""
                && accessModel.finishEvent.value != null) {
                if (accessModel.finishEvent.eventDisplayName == "İşe Başladıktan X Gün Sonra") {
                    //işe başladıktan itibaren : x gün
                    rtValue = translate("İşe Başladıktan Sonra") + ": " + accessModel.finishEvent.value + " " + translate("gün");
                } else if (accessModel.finishEvent.eventDisplayName == "Aktif olduktan X hafta sonra") {
                    rtValue = translate("Aktif Olduktan Sonra") + ": " + accessModel.finishEvent.value + " " + translate("hafta");
                }

                else {
                    rtValue = accessModel.finishEvent.eventDisplayName.replace('X', accessModel.finishEvent.value)
                }

            }
            if (accessModel.finishEvent.valueType == 2) {
                return moment(accessModel.finishEvent.value).format("DD/MM/YYYY");
            }
            return rtValue;
        }
        return translate("Serbest");
    }

    const getDateForStart = () => {
        let accessModel = getContentAccess(content.id, content.moduleSectionId);
        let data = accessModel.startEvent;
        if (data != undefined) {

            var _user = loginUser == undefined ? transactionUser : loginUser;

            switch (data.eventDisplayName) {
                case "Önceki Bittikten X Gün Sonra":
                    var previousContent = props.getBeforeSectionContent(content.id);
                    if (previousContent != undefined && previousContent.finishStatusDate != undefined)
                        return moment(previousContent.finishStatusDate).add(data.value, "days").format("DD/MM/YYYY");
                    return ""
                case "Önceki Bittikten Sonra":
                    var previousContent = props.getBeforeSectionContent(content.id);
                    if (previousContent != undefined && previousContent?.status?.isCompleted)
                        return previousContent.finishStatusDate;
                    return ""
                case "Önceki İle Birlikte":
                    var previousContent = props.getBeforeSectionContent(content.id);
                    if (previousContent != undefined && previousContent?.status?.isCompleted)
                        return previousContent.startStatusDate;
                    return ""
                case "İşe Başladığı Gün":
                    return moment(_user.workStartDate).format("DD/MM/YYYY")
                case "İşe Başlamadan X Gün önce":
                    return moment(_user.workStartDate).add(data.value * -1, "days").format("DD/MM/YYYY")
                case "İşe Başladıktan X Gün Sonra":
                    return moment(_user.workStartDate).add(data.value, "days").format("DD/MM/YYYY")
                case "Tarih Seç":
                    return moment(data.value).format("DD/MM/YYYY");
                default:
                    return ""
            }

        }
    }

    const showContentTypeEvenLocked = (pContent) => {
        if (pContent != undefined && pContent.status != undefined && pContent?.status?.isCompleted) {
            if (pContent.contentType == 15) {
                var taskType = pContent.data.contentTaskResponse.contentTask.type;
                if (taskType == 2 || taskType == 4)
                    return false;
                return true;
            }
        }
        return false;
    }

    const getDateForFinish = () => {
        let accessModel = getContentAccess(content.id, content.moduleSectionId);
        let data = accessModel.finishEvent;
        if (data != undefined) {
            var _user = loginUser == undefined ? transactionUser : loginUser;
            switch (data.eventDisplayName) {
                case "İşe Başladıktan X Gün Sonra":
                    return moment(_user.workStartDate).add(data.value, "days").format("DD/MM/YYYY")
                case "Aktif olduktan X hafta sonra":
                    moment(data.startStatusDate).add(data.value, "weeks").format("DD/MM/YYYY")
                    return "";
                case "İşe Başladığı Gün":
                    return moment(_user.workStartDate).format("DD/MM/YYYY")
                case "Tarih Seç":
                    return data.value;
                default:
                    return ""
            }

        }
    }

    const resolveHandler = (value) => {
        return value;
    }

    const promiseMaker = (value) => {
        return new Promise(function (resolve, reject) {
            resolve(resolveHandler(value));
            reject('Promise Hata');
        });
    };

    const startContent = (content) => {
        ////console.log("startContent content:", content);
        ////debugger;
        if (!isDisabled(content)
            && content.status.isInProgress == false
            && content != undefined
            && content.status != undefined
            && content?.status?.isCompleted == false) {
            props.updateContentNextStatus(content, showRating).then((apiModuleClone) => {
                if (apiModuleClone != undefined && apiModuleClone != false) {
                    let data = {};
                    _.each(apiModuleClone.sectionList, (sec) => {
                        if (sec.id == content.moduleSectionId) {
                            _.each(sec.contentList, (con) => {
                                if (con.id == content.id) {
                                    setContent(con);
                                }
                            });
                        }
                    });
                }
                setShowConfirm(false);
                setShowWelcome(false);
                setOperationCount(operationCount + 1);
            });
        } else if (getContentAccess(content.id, content.moduleSectionId).isDisabled) {
            toast.dismiss();
            toast(getContentAccess(content.id, content.moduleSectionId).message == "" ? translate("Bu içeriğe erişemezsiniz.") : getContentAccess(content.id, content.moduleSectionId).message, {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 5000
            });
        }
    }

    const addOrUpdateModuleHistory = (complatetionJson) => {
        let contentHistory: any = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            moduleId: moduleId,
            moduleSectionId: content.moduleSectionId,
            sectionContentId: content.id,
            completionJson: JSON.stringify(complatetionJson)
        };
        if (history != undefined) {
            contentHistory.id = history.id;
        }
        return NetworkRequest("/ModuleHistory/AddOrUpdate", contentHistory).then((response) => {
            if (response.data.resultCode === 0) {
                ////
                //props.getHistory(props.moduleId, transactionUserId != "" ? transactionUserId : loginUserId);
                var result = response.data.detail;
                setHistory(result);
            } else {
                toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            }
            setOperationCount(operationCount + 1);
            return response;
        });
    }

    const onComplete = (complatetionJson = {}, contentData?) => {
        setShowContent(false);
        let data = content;
        if (contentData != undefined) {
            data = contentData;
        }
        if (isDisabled(data) == true || (data != null && data.status != null && data?.status?.isCompleted == true) || showRating) {
            toast.dismiss();
            return;
        }
        ////debugger;
        if (data && getContentAccess(data.id, data.moduleSectionId).isDisabled == true) {
            toast(getContentAccess(data.id, data.moduleSectionId).message, {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 5000
            });
            return;
        }
        addOrUpdateModuleHistory(complatetionJson).then((response) => {
            toast.dismiss();
            if (response.data.resultCode == 0) {
                setHistory(response.data.detail);
                ////debugger;

                if (response.data.detail.rating == 0 && !showRating && isRateVisible()) {
                    setShowContent(true);
                    setShowRating(true);
                    setFromComplete(true);
                } else {
                    if (data != null && data.status != null && data.status.isInProgress) {
                        ////debugger;
                        props.updateContentNextStatus(data, true).then((apiModuleClone) => {
                            if (apiModuleClone != false) {
                                _.each(apiModuleClone.sectionList, (sec) => {
                                    _.each(sec.contentList, (con) => {
                                        if (con.id == data.id) {
                                            con.finishStatusDate = moment();
                                            setContent(con);
                                        }
                                    });
                                });
                                props.nextContent(data, apiModuleClone);
                            }
                        });
                    }
                }
            }
            setOperationCount(operationCount + 1);
        });
    }

    const saveLogToCourseHistory = () => {
        if (isDisabled(content) == true || (content != undefined
            && content?.status != undefined && content?.status?.isCompleted == true)) {
            return;
        }
        addOrUpdateModuleHistory({});
    }

    const rateContentHandler = () => {
        let contentHistory: any = {
            rating: rateModel.rate,
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            moduleId: moduleId,
            moduleSectionId: content.moduleSectionId,
            sectionContentId: content.id
        };
        if (history != undefined) {
            contentHistory.id = history.id;
        }
        NetworkRequest("/ModuleHistory/Rate", contentHistory).then((response) => {
            if (response.data.resultCode === 0) {
                setHistory(response.data.detail);
                if (rateModel.isNextContent != undefined && rateModel.isNextContent && !fromComplete) {
                    props.nextContent(content);
                }
                if (fromComplete) {
                    onComplete(JSON.parse(history.completionJson));
                }
            } else {
                toast.dismiss();
                toast(response.data.detail, { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            }
            setOperationCount(operationCount + 1);
            //setRateModel(undefined);
        });
    }

    const rateThisContent = (rate, maxRate, isNextContent?) => {
        setShowRating(false);
        setRateModel({
            rate: rate,
            maxRate: maxRate,
            isNextContent: isNextContent
        });
        setOperationCount(operationCount + 1);
    }

    const isDisabled = (model) => {

        let authorizedRole = _.find(loginUser.userRole, (doc) => {
            return doc.roleId == model.responsibleRoleId;
        });
        //yönetici bitmiş işi görebilsin
        if ((
            (authorizedRole != undefined
                && userRole != authorizedRole.role.roleName
            )
            ||
            (authorizedRole == undefined)
        )
            && model.sectionModuleId != undefined
            && model.contentType == 15
            && model.data != undefined
            && model.data.contentTaskResponse != undefined
            && model.data.contentTaskResponse.contentTask.type == 1
            && model.status != undefined
            && model?.status?.isCompleted) {
            return false;
        }
        if ((
            (authorizedRole != undefined
                && userRole != authorizedRole.role.roleName
            )
            ||
            (authorizedRole == undefined)
        )) {
            return true;
        }
        if (model != undefined && getContentAccess(model.id, model.moduleSectionId).isDisabled) {
            return true;
        }
        if (model?.status != undefined && model?.status?.isCompleted && getContentAccess(model.id, model.moduleSectionId).accessMessage != undefined) {
            return true;
        }
        return false;
    }

    const checkTaskLocked = () => {
        if (content != undefined
            && content?.status != undefined && content?.status?.isCompleted
            && content.responsibleRole.roleName != DataHandler.getUserDataWithKey("userRole")
            && content.contentType == 15
            && content.data.contentTaskResponse != undefined
            && content.data.contentTaskResponse.contentTask.type == 1
        ) {
            return false;
        }
        return props.isLocked;
    }

    const canTransaction = () => {
        if (content != undefined
            && content?.status != undefined && content?.status?.isCompleted
            && content.responsibleRole.roleName != DataHandler.getUserDataWithKey("userRole")
            && content.contentType == 15
            && content.data.contentTaskResponse != undefined
            && content.data.contentTaskResponse.contentTask.type == 1
        ) {
            return false;
        }
        return true;
    }

    //const renderContentComponent = () => {
    //    setTimeout(() => {
    //        switch (content.contentType) {
    //            case 1:
    //                setHtmlContent(
    //                    <RenderContentVideo
    //                        content={content}
    //                        moduleId={moduleId}
    //                        onComplete={onComplete}
    //                        showContentInfo={true}
    //                        isLocked={props.isLocked}
    //                        setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
    //                    />
    //                );
    //                break;
    //            case 2:
    //                setHtmlContent(
    //                    <RenderContentHtml
    //                        content={content}
    //                        moduleId={moduleId}
    //                        onComplete={onComplete}
    //                        showContentInfo={true}
    //                        isLocked={props.isLocked}
    //                        setContentLoading={setContentLoading}
    //                        setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
    //                    />
    //                )
    //                break;
    //            case 4:
    //                setHtmlContent(
    //                    <RenderContentQuiz
    //                        content={content}
    //                        moduleId={moduleId}
    //                        onComplete={onComplete}
    //                        showContentInfo={true}
    //                        transactionUserId={transactionUserId}
    //                        transactionUser={transactionUser}
    //                        setIsFocusContent={props.setIsFocusContent}
    //                        isLocked={props.isLocked}
    //                        saveLogToCourseHistory={saveLogToCourseHistory}
    //                        setContentLoading={setContentLoading}
    //                        setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
    //                    />
    //                )
    //                break;
    //            case 8:
    //                setHtmlContent(
    //                    <RenderContentSurvey
    //                        content={content}
    //                        moduleId={moduleId}
    //                        onComplete={onComplete}
    //                        showContentInfo={true}
    //                        transactionUserId={transactionUserId}
    //                        transactionUser={transactionUser}
    //                        setIsFocusContent={props.setIsFocusContent}
    //                        isLocked={props.isLocked}
    //                        saveLogToCourseHistory={saveLogToCourseHistory}
    //                        setContentLoading={setContentLoading}
    //                        setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
    //                    />
    //                )
    //                break;
    //            case 15:
    //                setHtmlContent(
    //                    <RenderContentTask
    //                        content={content}
    //                        moduleId={moduleId}
    //                        onComplete={onComplete}
    //                        user={props.loginUser}
    //                        history={history}
    //                        showContentInfo={true}
    //                        transactionUserId={transactionUserId}
    //                        transactionUser={transactionUser}
    //                        isDisabled={isDisabled}
    //                        isLocked={checkTaskLocked()}
    //                        canTransaction={canTransaction()}
    //                        isQr={props.isQr}
    //                        showRating={showRating}
    //                        getSectionWithDetails={props.getSectionWithDetails}
    //                        getContentHistory={getContentHistory}
    //                        setContentLoading={setContentLoading}
    //                        setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
    //                    />
    //                );
    //                break;
    //        }
    //        // setContentLoading(false);
    //        setOperationCount(operationCount + 1);
    //    }, 100)
    //}

    const isRateVisible = () => {
        if (content != undefined && content.details != undefined) {
            let json = JSON.parse(content.details);
            return json != undefined && json.isRateEnabled;
        }
        return false;
    }

    const getContentHistory = (contentId, userId) => {
        setContentLoading(true);
        NetworkRequest('/ModuleHistory/GetContentHistory', {
            contentId: contentId,
            userId: userId
        }).then((response) => {
            if (response.data.resultCode == 0) {
                setHistory(response.data.detail);
                setContentLoading(false);
            }
        });
    }

    const isHistoryExistsOnDb = () => {
        return history == undefined && content.status != undefined && (content?.status?.isCompleted || content?.status?.isInProgress);
    }

    const getContentAccess = (contentId, sectionId) => {
        // //debugger;
        if (props.moduleAccessModel == undefined) {
            return {
                isDisabled: false
            };
        }
        let sectionAccess = _.find(props.moduleAccessModel.sectionList, (section) => {
            return section.sectionId == sectionId;
        });
        if (sectionAccess == undefined) {
            return {
                isDisabled: false
            };
        }
        let contentAccess = _.find(sectionAccess.contentList, (cont) => {
            return cont.contentId == contentId;
        });
        if (sectionAccess == undefined) {
            contentAccess = {
                isDisabled: false
            };
        }
        ////console.log("contentAccess: ", contentAccess);

        return contentAccess;
    }

    const getToastMessage = (content) => {
        let accessModel: any = getContentAccess(content.id, content.moduleSectionId);
        let mes = "";
        if (accessModel.message != undefined && accessModel.accessMessage != undefined) {
            mes = accessModel.message + " " + accessModel.accessMessage;
        } else if (accessModel.message != undefined && accessModel.accessMessage == undefined) {
            mes = accessModel.message;
        } else if (accessModel.message == undefined && accessModel.accessMessage != undefined) {
            mes = accessModel.accessMessage;
        }
        return mes;
    }

    useEffect(() => {
        ////console.log("htmlContent: ", htmlContent);
    }, [htmlContent]);

    useEffect(() => {
        ////console.log("content", content);
        if (content != undefined && content.id != "") {
            getContentHistory(content.id, transactionUserId != "" ? transactionUserId : loginUserId);
            setContentId(content.id);
            setShowContent(true);
            if (content?.status != undefined && content?.status?.isInProgress == false && content?.status?.isCompleted == false) {
                startContent(content);
            }
        } else {
            setShowContent(false);
        }
    }, [content]);

    useEffect(() => {
        props.setContentLoading(contentLoading);
    }, [contentLoading]);

    useEffect(() => {
        if (rateModel != undefined) {
            rateContentHandler();
        }
    }, [rateModel]);

    useEffect(() => {
        if (isHistoryExistsOnDb()) {
            getContentHistory(content.id, transactionUserId != "" ? transactionUserId : loginUserId);
        }
        if (history != undefined && history.rating != 0 && rateModel == undefined) {
            setRateModel({
                rate: history.rating,
                maxRate: 5
            });
        }
        if (history != undefined) {
            //setShowContent(true);
        }
    }, [history]);

    useEffect(() => {
        //console.log("content", content);
    }, [content]);

    useEffect(() => {
        //console.log("Boş Use effect props", props);
        ////console.log("content uef", content);
    }, []);

    return (
        <div>
            {isMobile
                &&
                <WelcomeBanner user={transactionUserId != "" ? transactionUser : loginUser} />
            }
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}

                className={"renderContentContentContainer"} style={{ ...styles.contentContainer }}>
                {!isMobile
                    &&
                    <WelcomeBanner
                        user={transactionUserId != "" ? transactionUser : loginUser}
                    />
                }
                {!isMobile &&
                    <div style={{ padding: ".25rem 2rem", display: "flex", flexDirection: "column", }}>
                        <span className={"renderContentModuleTitle"} style={{ ...styles.headerStyle, fontSize: "28px" }}>
                            {props.moduleTitle}
                        </span>
                    </div>
                }

                <div
                    style={{
                        textAlign: "center",
                        padding: "4%"
                    }}
                    className={"renderContentContentHeader " + styles.contentHeader}
                >
                    <div style={{
                        ...styles.headerStyle
                    }}>
                        {
                            (content.title != null && !isMobile) ? content.title.charAt(0).toUpperCase() + content.title.slice(1) : (content.title != null && isMobile) ? "Detaylar" : ""
                        }
                    </div>

                    <div style={{ display: "flex", flexDirection: "column-reverse", gap: "1em" }}>
                        {!isMobile
                            &&
                            <div>
                                <Button
                                    circular
                                    size={"large"}
                                    icon
                                    style={{display:"flex", gap:"1em"}}
                                    //className={showContentInfo ? "dangerRedButton" : "sweetPurpleButton"}
                                    color={showContentInfo ? "red" : "blue"}
                                    onClick={() => {
                                        setShowContentInfo(!showContentInfo)
                                    }}
                                >
                                    <Icon name={showContentInfo ? 'eye slash' : 'eye'} />
                                    {showContentInfo ? translate("İçerik detaylarını gizle")
                                        : translate("İçerik detaylarını göster")}
                                </Button>
                            </div>

                        }


                        {isRateVisible()
                            &&
                            <div
                                style={{ ...(history != undefined && history.rating != 0) ? styles.cursorNotAllowed : {} }}
                                className={"renderContentRateContainer " + styles.ratingBox}
                                key={operationCount}>
                                <Rating
                                    disabled={(history != undefined && history.rating != 0)}
                                    icon='star'
                                    maxRating={5}
                                    size="huge"
                                    rating={history != undefined ? history.rating : 0}
                                    onRate={(e, { rating, maxRating }) => {
                                        rateThisContent(rating, maxRating)
                                    }} />
                                {history != undefined && history.rating != 0
                                    &&
                                    <Icon color="green" size="large" name="checkmark" />
                                }
                            </div>
                        }
                    </div>


                    {isMobile &&
                        <Button
                            circular
                            size={"large"}
                            icon
                            style={{ display: "flex", gap: "1em" }}
                            //className={showContentInfo ? "dangerRedButton" : "sweetPurpleButton"}
                            color={showContentInfo ? "red" : "blue"}
                            onClick={() => {
                                setShowContentInfo(!showContentInfo)
                            }}
                        >
                            <Icon name={showContentInfo ? 'eye slash' : 'eye'} />
                            {showContentInfo ? translate("İçerik detaylarını gizle")
                                : translate("İçerik detaylarını göster")}
                        </Button>
                    }


                </div>
                <Transition visible={showContentInfo} animation='fade up' duration={500}>

                    <div>
                        <span className={styles.mobileSectionDescriptionContainer}>
                            <div className={"renderContentSettingsContainer " + styles.contentSettingsContainer}
                                style={{ flexDirection: "column", }}>
                                <div className={"renderContentSettingHeaderContainer " + styles.contentSettingHeader}
                                    style={{ ...styles.bold700, alignItems: "flex-start !important", width: "100%" }}>
                                    {translate("Bölüm Açıklama")}
                                </div>
                                <div
                                    className={"renderContentDescContainer"}
                                    style={{ ...styles.contentDescriptionStyle, ...styles.boxBorder }}>
                                    {section.description}
                                </div>
                            </div>
                        </span>

                        <div className={"renderContentSettingsContainer " + styles.contentSettingsContainer}>
                            <div className={"renderContentTimingContainer " + styles.contentTimingContainer}
                                style={{ width: "100%" }}>
                                <div style={{ ...styles.bold700, }}
                                    className={"renderContentSettingHeaderContainer " + styles.responsibleUserLabel}>
                                    {translate("Sorumlu")}
                                </div>
                                <div className={"renderContentDescContainer"}
                                    style={{ width: '450px', height: '35px', mixBlendMode: 'hard-light' }}>
                                    {translate(getResponsibleUserMessage(content.responsibleRole.roleName))}
                                </div>
                            </div>
                        </div>

                        <div className={"renderContentEventContainer " + styles.contentSettingsContainer}>
                            <div
                                className={"renderContentContainerFlex renderContentSettingsContainer " + styles.contentTimingContainer}>
                                <div
                                    className={"renderContentSettingHeaderContainer"}
                                    style={{ ...styles.contentTimingHeader, ...styles.bold700, ...styles.bold700 }}>
                                    {translate("Başlangıç Kuralı")}
                                </div>
                                <span className={"renderContentTaskConditionContainer"}
                                    style={styles.contentTimingDetailBox}>
                                    {translate(getStartOptionUserMessage())}
                                </span>
                            </div>
                            <div
                                className={"renderContentContainerFlex renderContentSettingsContainer " + styles.contentTimingContainer}>
                                <div
                                    style={{ ...styles.contentTimingHeader, ...styles.bold700, }}
                                    className={"renderContentSettingHeaderContainer " + styles.alignRight}>
                                    {translate("Bitiş Kuralı")}
                                </div>
                                <span className={"renderContentTaskConditionContainer"}
                                    style={styles.contentTimingDetailBox}>
                                    {translate(getFinishOptionUserMessage())}
                                </span>
                            </div>
                        </div>

                        {(
                            (getContentAccess(content.id, content.moduleSectionId).startEvent != undefined && getContentAccess(content.id, content.moduleSectionId).startEvent.startDate != undefined)
                            || (getContentAccess(content.id, content.moduleSectionId).finishEvent && getContentAccess(content.id, content.moduleSectionId).finishEvent.finishDate != undefined)
                        )
                            &&
                            <>
                                <div className={"renderContentEventContainer " + styles.contentSettingsContainer}>
                                    {getContentAccess(content.id, content.moduleSectionId).startEvent != undefined && getContentAccess(content.id, content.moduleSectionId).startEvent.startDate != undefined
                                        &&
                                        <div
                                            className={"renderContentContainerFlex renderContentSettingsContainer " + styles.contentTimingContainer}>
                                            <div
                                                className={"renderContentSettingHeaderContainer"}
                                                style={{ ...styles.contentTimingHeader, ...styles.bold700, ...styles.bold700 }}>
                                                {translate("Başlangıç Tarihi")}
                                            </div>
                                            <span className={"renderContentTaskConditionContainer"}
                                                style={styles.contentTimingDetailBox}>
                                                {moment(getContentAccess(content.id, content.moduleSectionId).startEvent.startDate).format('DD/MM/YYYY')}
                                            </span>
                                        </div>
                                    }
                                    {getContentAccess(content.id, content.moduleSectionId).finishEvent && getContentAccess(content.id, content.moduleSectionId).finishEvent.finishDate != undefined
                                        &&
                                        <div
                                            className={"renderContentContainerFlex renderContentSettingsContainer " + styles.contentTimingContainer}>
                                            <div
                                                style={{ ...styles.contentTimingHeader, ...styles.bold700, }}
                                                className={"renderContentSettingHeaderContainer " + styles.alignRight}>
                                                {translate("Bitiş Tarihi")}
                                            </div>
                                            <span className={"renderContentTaskConditionContainer"}
                                                style={styles.contentTimingDetailBox}>
                                                {moment(getContentAccess(content.id, content.moduleSectionId).finishEvent.finishDate).format('DD/MM/YYYY')}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </>
                        }

                        {content.description != "" &&
                            <div className={"renderContentSettingsContainer " + styles.contentSettingsContainer}
                                style={{ flexDirection: "column", }}>
                                <div className={"renderContentSettingHeaderContainer " + styles.contentSettingHeader}
                                    style={{ ...styles.bold700, alignItems: "flex-start !important", width: "100%" }}>
                                    {translate("İçerik Açıklama")}
                                </div>
                                <div className={"renderContentDescContainer"}
                                    style={{ ...styles.contentDescriptionStyle, ...styles.boxBorder }}>
                                    {content.description}
                                </div>
                            </div>
                        }
                    </div>
                </Transition>
                {(!isDisabled(content)
                    ||
                    (isDisabled(content)
                        && content != undefined
                        && content.responsibleRole.roleName != DataHandler.getUserDataWithKey("userRole")
                        && showContentTypeEvenLocked(content)
                    )
                ) && showContent
                    &&
                    <Transition key={operationCount} visible={!contentLoading} animation='vertical flip' duration={500}>
                        <>
                            {content.contentType == 1
                                &&
                                <RenderContentVideo
                                    content={content}
                                    moduleId={moduleId}
                                    onComplete={onComplete}
                                    showContentInfo={true}
                                    isLocked={props.isLocked}
                                    setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
                                />
                            }
                            {content.contentType == 2
                                &&
                                <RenderContentHtml
                                    content={content}
                                    moduleId={moduleId}
                                    onComplete={onComplete}
                                    showContentInfo={true}
                                    isLocked={props.isLocked}
                                    setContentLoading={setContentLoading}
                                    setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
                                />
                            }
                            {content.contentType == 4
                                &&
                                <RenderContentQuiz
                                    content={content}
                                    moduleId={moduleId}
                                    onComplete={onComplete}
                                    showContentInfo={true}
                                    transactionUserId={transactionUserId}
                                    transactionUser={transactionUser}
                                    setIsFocusContent={props.setIsFocusContent}
                                    isLocked={props.isLocked}
                                    saveLogToCourseHistory={saveLogToCourseHistory}
                                    setContentLoading={setContentLoading}
                                    setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
                                />
                            }
                            {content.contentType == 8
                                &&
                                <RenderContentSurvey
                                    content={content}
                                    moduleId={moduleId}
                                    onComplete={onComplete}
                                    showContentInfo={true}
                                    transactionUserId={transactionUserId}
                                    transactionUser={transactionUser}
                                    setIsFocusContent={props.setIsFocusContent}
                                    isLocked={props.isLocked}
                                    saveLogToCourseHistory={saveLogToCourseHistory}
                                    setContentLoading={setContentLoading}
                                    setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
                                />
                            }
                            {content.contentType == 15
                                &&
                                <RenderContentTask
                                    key={content.id}
                                    content={content}
                                    moduleId={moduleId}
                                    onComplete={onComplete}
                                    user={props.loginUser}
                                    history={history}
                                    showContentInfo={true}
                                    transactionUserId={transactionUserId}
                                    transactionUser={transactionUser}
                                    isDisabled={isDisabled}
                                    isLocked={checkTaskLocked()}
                                    canTransaction={canTransaction()}
                                    isQr={props.isQr}
                                    showRating={showRating}
                                    getSectionWithDetails={props.getSectionWithDetails}
                                    getContentHistory={getContentHistory}
                                    setContentLoading={setContentLoading}
                                    setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
                                    addOrUpdateModuleHistory={addOrUpdateModuleHistory}
                                />
                            }
                            {content.contentType == 16
                                &&
                                <RenderContentScorm
                                    content={content}
                                    moduleId={moduleId}
                                    transactionUserId={transactionUserId}
                                    transactionUser={transactionUser}
                                    user={props.loginUser}
                                    onComplete={onComplete}
                                    showContentInfo={true}
                                    isLocked={props.isLocked}
                                    setContentLoading={setContentLoading}
                                    setIsOpenTaskContentForMobile={props.setIsOpenTaskContentForMobile}
                                />
                            }
                            {(content.title != null && isMobile) &&
                                <div
                                    style={{ margin: "7% auto 7% auto", fontSize: "1.8em", fontWeight: "bold" }}
                                >
                                    {content.title.charAt(0).toUpperCase() + content.title.slice(1)}
                                </div>
                            }
                        </>
                    </Transition>
                }
                {(isDisabled(content) && !showContentTypeEvenLocked(content))
                    &&
                    <h3 className="textCenter warningResultMessage">
                        <Icon name={"info circle"} size={"big"} />
                        {translate(getToastMessage(content))}
                    </h3>
                }
            </motion.div>

            <Transition
                visible={contentLoading}
                animation='scale'
                duration={100}
            >
                <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "ceter", alignItems: "center" }}>
                    <div className="loaderContainerGif">
                        <Loader color="orange" size="big" active inline='centered' />
                    </div>
                </div>
            </Transition>

            <Transition visible={showRating} animation='scale' duration={1}>
                <div>
                    {!isMobile &&
                        <Modal
                            centered={false}

                            open={showRating}
                            onClose={() => {
                                setShowRating(false);
                                props.nextContent(content);
                            }}
                            onOpen={() => setShowRating(true)}
                        >
                            <Modal.Content>
                                <Modal.Description>
                                    <div style={styles.ratingContainer}>
                                        <div style={styles.closeBtnStyle}
                                            onClick={() => {
                                                setShowRating(false);
                                                props.nextContent(content);
                                            }}
                                        >
                                            <img width="24" height="24" src={"/files/images/icons/close.svg"} />
                                        </div>
                                        <div><Icon name="check circle outline" style={{ fontSize: "40px" }}
                                            color="green" /></div>
                                        <div style={{ fontSize: "22px" }}>
                                            {translate("Tebrikler Görev Tamamlandı!")}
                                        </div>
                                        <div style={{ fontSize: "22px", marginTop: "80px" }}>
                                            {translate("Bu Görevi Ne Kadar Faydalı Buldun?")}
                                        </div>
                                        <div className={styles.ratingEmojiesContainer}>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(1, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate1.svg"} />
                                                <div>{translate("Faydalı Bulmadım")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(2, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate2.svg"} />
                                                <div>{translate("Az Faydalı Buldum")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(3, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate3.svg"} />
                                                <div>{translate("Kararsızım")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(4, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate4.svg"} />
                                                <div>{translate("Faydalı Buldum")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(5, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate5.svg"} />
                                                <div>{translate("Çok Faydalı Buldum")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>

                    }

                    {isMobile &&
                        <Modal
                            centered={false}
                            className={"mobileCompleteTaskSurveyContainer"}
                            open={showRating}
                            onClose={() => {
                                setShowRating(false);
                                props.nextContent(content);
                            }}
                            onOpen={() => setShowRating(true)}
                        >
                            <Modal.Content>
                                <Modal.Description>
                                    <div style={styles.ratingContainer}>
                                        <div style={styles.closeBtnStyle}
                                            onClick={() => {
                                                setShowRating(false);
                                                props.nextContent(content);
                                            }}
                                        >
                                            <img width="24" height="24" src={"/files/images/icons/close.svg"} />
                                        </div>
                                        <div><Icon name="check circle outline" style={{ fontSize: "40px" }}
                                            color="green" /></div>
                                        <div style={{ fontSize: "1.3em" }}>
                                            {translate("Tebrikler Görev Tamamlandı!")}
                                        </div>
                                        <div style={{ fontSize: "1em", textAlign: "center", width: "100%" }}>
                                            {translate("Bu Görevi Ne Kadar Faydalı Buldun?")}
                                        </div>
                                        <div className={styles.ratingEmojiesContainer}>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(1, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate1.svg"} />
                                                <div>{translate("Faydalı Bulmadım")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(2, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate2.svg"} />
                                                <div>{translate("Az Faydalı Buldum")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(3, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate3.svg"} />
                                                <div>{translate("Kararsızım")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(4, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate4.svg"} />
                                                <div>{translate("Faydalı Buldum")}</div>
                                            </div>
                                            <div className={styles.emojiesBox + " emojieBox"} onClick={() => {
                                                rateThisContent(5, 5, true);
                                            }}>
                                                <img className={styles.emojieStyle}
                                                    src={"/files/images/emojies/" + "rate5.svg"} />
                                                <div>{translate("Çok Faydalı Buldum")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    }
                </div>
            </Transition>
        </div>
    )
}

export default RenderContent;