import * as React from 'react';
import {useState, useEffect} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Dropdown,
    Header,
    Icon,
    Input,
    Loader,
    Popup,
    Table,
    Transition
} from 'semantic-ui-react';
import {datableFilterChange, digitallicaConfirm, getQuestionContentForRender, prepareDataForTable} from '../helper/HelperFunctions';
import {NetworkRequest} from '../helper/NetworkRequest';
import DataTable from './DataTable';
import {IDataTable, IQuestion} from '../interfaces/interfaces';
import axios from 'axios';
import {toast} from 'react-toastify';

import {DataHandler} from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

interface QuestionAttachToQuiz {
    id?: string,
    QuestionAdd?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}


const QuestionAttachToQuiz = (props) => {
    const _ = require('lodash');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterText, setFilterText] = useState("");
    const [quizId] = useState(props.id);
    const [quiz, setQuiz] = useState({title: ""});
    const [question, setQuestion] = useState({id: "", nameSurname: "", email: ""});
    var {translate} = TranslateHelper();
    const [questionAndQuiz, setQuestionAndQuiz] = useState(
        {id: "", quizId: "", questionId: ""}
    );
    const [isAllSelected, setIsAllSelected] = useState(false);

    const onComplate = (id) => {
        let questionData = {questionId: id, quizId: quizId};
        if (!isSigned) {
            digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
                NetworkRequest("/api/QuestionAndQuiz/Add", questionData).then(response => {
                    if (response.data.resultCode == 0) {
                        toast("Eklendi", {type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500});
                        getQuestions(filterData);
                    }
                });
            }, () => {
                toast("İşlem iptal edildi.", {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            });
        } else {
            digitallicaConfirm(
                translate("Silmek istediğinize emin misiniz?"),
                () => {
                    NetworkRequest("/api/QuestionAndQuiz/Delete", questionData).then(response => {
                        if (response.data.resultCode == 0) {
                            toast("Silindi", {type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500});
                            getQuestions(filterData);
                        }
                    });
                },
                () => {
                    toast("İşlem iptal edildi.", {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2500
                    });
                }
            );
        }
        getQuestions(filterData);
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {

        let datatableClone = _.cloneDeep(dataTableModel);

        let value = data.value == undefined ? true : !data.value;
        datatableClone.data[index].secretData[0].isSelected = value;
        window.setTimeout(() => {
            setDataTableModel({
                ...datatableClone
            });
            //setDataTableModel(dataTableModel);
        }, 100);

        //debugger;
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isSigned, setIsSigned] = useState(false);

    const [filterData] = useState({
        filterText: filterText,
        currentPage: currentPage,
        isActive: isActive,
        isDeleted: isDeleted,
        startingDate: startingDate,
        dueDate: dueDate,
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeSigned = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsSigned);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const collectiveProggress = (e, data, dtm) => {
        //debugger;
        if (data.value == "")
            return;
        dtm = dtm == undefined ? dataTableModel : dtm;
        digitallicaConfirm(
            "Toplu işlem yapacaksınız? Emin misiniz?",
            () => {
                let rows: Array<any> = [];
                let datatableClone = _.cloneDeep(dtm);
                let filtereeds = datatableClone.data.filter(x => x.secretData[0].isSelected == true);

                filtereeds.map(x => {
                    rows.push({questionId: x.secretData[0].id, quizId: quizId})
                });

                ////console.log(rows);
                ////debugger;
                switch (data.value) {
                    case 1:
                        NetworkRequest("/api/QuestionAndQuiz/AddCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                getQuestions(filterData);
                                toast("Sınava eklenen soru adeti" + ": " + response.data.detail.length, {
                                    type: 'info',
                                    theme: 'dark',
                                    position: 'top-center',
                                    autoClose: 3500
                                });
                            }
                        });
                        break;
                    case 2:
                        NetworkRequest("/api/QuestionAndQuiz/DeleteCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                getQuestions(filterData);
                                toast(translate("Sınavdan silinen soru adeti: ") + response.data.detail.length, {
                                    type: 'info',
                                    theme: 'dark',
                                    position: 'top-center',
                                    autoClose: 3500
                                });
                            }
                        });
                        break;
                    default:
                }
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );

    }

    const moveDown = (id) => {
        //setLoader(true);
        let requestData = {
            questionId: id,
            quizId: quizId
        };
        NetworkRequest("/api/QuestionAndQuiz/MoveDown", requestData).then(response => {
            if (response.data.resultCode === 0) {
                getQuestions(filterData);
            }
        });
    }

    const moveUp = (id) => {
        //setLoader(true);
        let requestData = {
            questionId: id,
            quizId: quizId
        }
        NetworkRequest("/api/QuestionAndQuiz/MoveUp", requestData).then(response => {
            if (response.data.resultCode === 0) {
                getQuestions(filterData);
            }
        });
    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            <Checkbox
                onChange={setAllSelected} value={"false"}/>,
            translate("Sıra Numarası"),
            translate("Soru gövdesi"),
            translate("A"),
            translate("B"),
            translate("C"),
            translate("D"),
            translate("E"),
            translate("Doğru Seçenek"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: [
            {
                icon: isSigned ? "trash" : "plus",
                desc: isSigned ? translate("Çalışan Sil") : translate("Çalışan ekle"),
                color: isSigned ? "red" : "green",
                function: onComplate,
                type: isSigned ? "delete" : "add",
            },
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Ekli Sorular"),
                    defaultValue: isSigned,
                    key: "isSigned",
                    type: "boolean",
                    onChange: onChangeSigned
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "",
        isAttachingPage: true,
        setRowSelected: setRowSelected,
        collectiveProgresses:
            {
                options: [{key: 2, text: translate('Toplu Kaldır'), value: 2}],
                event: collectiveProggress
            },
        orderTransactions: [moveUp, moveDown],
    });
    //prepare data for table
    const prepareData = (data, count) => {
        //hidden column
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        let model = prepareDataForTable(data, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'userId',
            !isSigned ? 'rank' : ''
        ]);

        //setDataTableModel({
        //    header: dataTableModel.header,
        //    data: [],
        //    transactions: dataTableModel.transactions,
        //    filter: dataTableModel.filter,
        //    pagination: dataTableModel.pagination,
        //    addRowLink: dataTableModel.addRowLink
        //});

        window.setTimeout(() => {
            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Ekli Sorular"),
                            defaultValue: isSigned,
                            key: "isSigned",
                            type: "boolean",
                            onChange: onChangeSigned
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);

        ////
    }

    const deleteQuestionAndQuiz = () => {
        digitallicaConfirm(
            translate("Bu içerikten question kaldırılacak. Emin misiniz?"),
            () => {
                if (questionAndQuiz.id != "") {
                    let questionAndQuizData = {
                        id: questionAndQuiz.id
                    }
                    NetworkRequest("/api/QuestionAndQuiz/Delete", questionAndQuizData).then(response => {
                        if (response.data.resultCode == 0) {
                            setQuestionAndQuiz({id: "", quizId: quizId, questionId: ""});
                            getQuestions(filterData);
                        }
                    });
                } else {
                    setQuestionAndQuiz({
                        id: "",
                        questionId: "",
                        quizId: quizId
                    });
                    setQuestion({id: "", nameSurname: "", email: ""});
                }
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
    }

    //get table data from database
    const getQuestions = (filterData?) => {
        setIsLoading(true);
        let uri = !isSigned ? "/api/QuestionAndQuiz/GetNotAssignedQuestions" : "/api/QuestionAndQuiz/GetAssignedQuestions";
        let req = {
            id: quizId,
            pagination: filterData
        };
        NetworkRequest(uri, req).then((response) => {
            ////console.log("negeliyorkiiii", response);
            setIsLoading(false);
            if (response.data.resultCode == 0) {
                //toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });

                ////
                let designedQuestionArray = Array<any>();

                let questions = response.data.detail.list;
                questions.map(question => {
                    let questionJSON = question.questionJSON;
                    let newQuestionFormat = {
                        id: question.id,
                        rank: question.rank,
                        body: getQuestionContentForRender(questionJSON.body),
                        a: questionJSON.a,
                        b: questionJSON.b,
                        c: questionJSON.c,
                        d: questionJSON.d,
                        e: questionJSON.e,
                        correntOption: questionJSON.correctAnswer,
                        isDeleted: question.isDeleted,
                        updatedDate: question.updatedDate,
                        deletedDate: question.deletedDate,
                        userId: question.userId,
                        isActive: question.isActive,
                    }

                    designedQuestionArray.push(newQuestionFormat);
                });

                ////console.log(designedQuestionArray);
                prepareData(designedQuestionArray, response.data.detail.count);
                setIsLoading(false);
            } else {
                toast(translate(response.data.message), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000});
            }
        });
    }

    const checkAssignedQuestion = () => {
        let checkData = {
            quizId: quizId
        }
        NetworkRequest("/api/QuestionAndQuiz/GetAssignedQuestions", checkData).then(response => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                if (result == null)
                    return;
                setQuestionAndQuiz({
                    id: result.id,
                    questionId: result.questionId,
                    quizId: result.quizId
                });
                NetworkRequest("/api/Question/GetById", result.questionId).then(responseQuestion => {
                    if (responseQuestion.data.resultCode == 0) {
                        let resultQuestion = responseQuestion.data.detail;
                        setQuestion({
                            nameSurname: resultQuestion.name + " " + resultQuestion.surName,
                            id: resultQuestion.id,
                            email: resultQuestion.email
                        })
                    }
                });
            }
        });
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getQuestions(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, isSigned]);

    useEffect(() => {
        let rows: Array<any> = [];
        let datatableClone = _.cloneDeep(dataTableModel);
        let filtereeds = datatableClone.data.filter(x => x.secretData[0].isSelected == true);

        dataTableModel.data.filter(x => x.secretData[0].isSelected == true).map(x => {
            rows.push({questionId: x.secretData[0].id, quizId: quizId})
        });
        ////console.log(filtereeds);
    }, [dataTableModel]);

    useEffect(() => {
        ////
    }, [dataTableModel]);

    //onLoad page function
    useEffect(() => {
        getQuestions(filterData);
    }, []);

    useEffect(() => {
        if (!isSigned) {
            dataTableModel.transactions = [
                {
                    icon: "plus",
                    desc: translate("Soru ekle"),
                    color: "green",
                    function: onComplate,
                    type: "add",
                },
            ];
            dataTableModel.collectiveProgresses = {
                options: [{key: 1, text: translate('Toplu Ekle'), value: 1}],
                event: collectiveProggress
            }
            dataTableModel.orderTransactions = undefined;
            dataTableModel.header = [
                <Checkbox
                    onChange={setAllSelected}
                />,
                translate("Soru gövdesi"),
                translate("A"),
                translate("B"),
                translate("C"),
                translate("D"),
                translate("E"),
                translate("Doğru Seçenek"),
                translate("Aktif"),
                translate("İşlemler")
            ]

            setDataTableModel({...dataTableModel});
        } else {
            dataTableModel.transactions = [
                {
                    icon: "trash",
                    desc: translate("Soru sil"),
                    color: "red",
                    function: onComplate,
                    type: "delete",
                }
            ];
            dataTableModel.collectiveProgresses = {
                options: [{key: 2, text: translate('Toplu Sil'), value: 2}],
                event: collectiveProggress
            }
            dataTableModel.orderTransactions = [moveUp, moveDown];

            dataTableModel.header = [
                <Checkbox
                    onChange={setAllSelected}
                />,
                translate("Sıra Numarası"),
                translate("Soru gövdesi"),
                translate("A"),
                translate("B"),
                translate("C"),
                translate("D"),
                translate("E"),
                translate("Doğru Seçenek"),
                translate("Aktif"),
                translate("İşlemler")
            ]

            setDataTableModel({...dataTableModel});
        }
    }, [isSigned]);

    useEffect(() => {
        ////
    }, [dataTableModel]);

    useEffect(() => {
        if (quizId != "") {
            let quizData = {
                id: quizId
            };
            NetworkRequest("/Quiz/GetById", quizData).then((response) => {
                let result = response.data;
                if (result.resultCode == 0) {
                    setQuiz({title: result.detail.title});
                }
            })


        }
    }, [quizId]);

    return (
        <>
            <div className={"generalContainerForWhiteBg"}>
                <Header style={{
                    fontSize: "3.5em",
                    margin: "auto",
                    textAlign: "center"
                }}
                        disabled
                        as="h3"
                >
                    {quiz.title} - {isSigned ? translate("Ekli Sorular") : translate("Eklenmemiş Sorular")}
                </Header>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <DataTable
                        dataTable={dataTableModel}
                        isAllSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        isLoading={isLoading}
                        selectAllHandler={selectAllHandler}
                        dataTableSetter={setDataTableModel}
                    />
                </Transition>
            </div>
        </>
    );
}

export default QuestionAttachToQuiz;