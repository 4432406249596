import React, {useState, useEffect} from 'react';
import {IQuestionReport, IQuestionReportCard} from "../interfaces/interfaces";
import QuestionReportCard from './QuestionReportCard'
import { v4 as uuidv4 } from 'uuid';

const ReportQuestionList = ({questions}: IQuestionReportCard) => {
    //console.log("asd", questions);
    
    const styles = {
        questionContainer: {
            display: "flex",
            flexDirection:"row" as "row",
            flexWrap: "wrap" as "wrap",
            justifyContent: "space-around",
            alignItems: "flex-start"
        }
    }

    
    return (
        <div style={styles.questionContainer}>
            {questions.map(question => (
                <QuestionReportCard 
                    key={uuidv4()}
                    questionJson={question.questionJson}
                    userAnswer={question.userAnswer}
                    isDeleted={question.isDeleted}
                    isActive={question.isActive}
                />
            ))}
        </div>
    )
}

export default ReportQuestionList;