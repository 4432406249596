import * as React from 'react';
import DropZone from '../../../components/DropZone';
import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from '../../../helper/TranslateHelper';
import UserImportExcel from '../../../images/Buddy/ExcelImport/user-import.xlsx';

const UserImport = () => {

    let styles = {

        importContainer: {
            display: "flex",
            width: "100%"
        }
    }

    var { translate } = TranslateHelper();

    let dropZoneProps = {

        links: {
            uploadLink: "/api/User/ExcelImport",
            returnLink: "/users"
        },
        table: {
            headers: [translate("İsim"), translate("Soyisim"), translate("Email"), translate("Hata Nedeni")],
            propertyNames: ["name", "surname", "email"]
        },
        messages: {
            1: translate("İsim alanı boş bırakılamaz"),
            2: translate("Soyisim alanı boş bırakılamaz"),
            4: translate("Email alanı boş veya zaten daha önceden kayıtlı"),
            8: translate("İşe başlama tarihi boş bırakılamaz"),
            15: translate("İnsan kaynakları email adresi boş veya girmiş olduğunuz email adresi insan kaynakları uzmanına ait değil"),
            16: translate("Yönetici email adresi boş veya girmiş olduğunuz email adresi bir yöneticiye ait değil"),
            17: translate("Buddy email adresi boş veya girmiş olduğunuz email adresi bir buddyye ait değil"),
            18: translate("Bu çalışan daha önceden kayıt edilmiş (Email adresi ve telefon numarası benzersiz olmalıdır)"),
            19: translate("IsActive alanında tanımlanamayan bir değer tespit edildi"),
            20: translate("Yönetici, Buddy veya İnsan Kaynakları email adreslerinden biri bulunamadı veya çalışan silinmiş"),
            21: translate("Telefon numarası 11 haneyi geçemez"),
            22: translate("Aboneliğinizin lisans sayısı bitmiş ya da süresi bitmiş olabilir, Lütfen yöneticinize başvurun."),
            23: translate("Rol boş bırakılamaz."),
            24: translate("Çalışan kaydedildi, Ancak hoş geldin modülüne kaydedilemedi. Çalışana Buddy, Yönetici, İnsan Kaynakları Sorumlusu, BT Sorumlusu ve İdari İşler Sorumlusu tanımlanmış olmalıdır.")
        },
        dataName: "Kullanıcı",
        // excelTemplateFileLink: DataHandler.getDocmanagerUrl() + "/Documents/Buddy/ExcelImport/user-import-V1.xlsx"
        excelTemplateFileLink: UserImportExcel
    }

    return (
        <div style={styles.importContainer}>
            <DropZone
                dropZoneProps={dropZoneProps}
            />
        </div>
    );
}

export default UserImport;