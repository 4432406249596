import * as React from 'react';
import { useState, useEffect } from 'react';
import Scorm12API from '../../../node_modules/scorm-again/src/Scorm12API';
import { NetworkRequest } from '../../helper/NetworkRequest';

const RenderContentScorm = (props) => {
    const [contentScormView] = useState<any>(props.content.data.contentScormView);
    const [scormLiveFolder, setScormLiveFolder] = useState("");
    const [CMICoreData, setCMICoreData] = useState("");
    const settings = {
        autoProgress: false,
        autocommit: true,
        autocommitSeconds: 30,
        commitRequestDataType: "application/json;charset=UTF-8",
        dataCommitFormat: "json",
        logLevel: 1,
        mastery_override: false
    };


    //const downloadAndUnzip = async (zipUrl) => {
    //    try {
    //        const response = await fetch(`/download-and-unzip?zipUrl=${encodeURIComponent(zipUrl)}`);

    //        // HTTP durum kodunu kontrol edin
    //        if (!response.ok) {
    //            throw new Error(`HTTP error! status: ${response.status}`);
    //        }

    //        // Yanıtın JSON formatında olduğunu kontrol edin
    //        const contentType = response.headers.get("content-type");
    //        if (!contentType || !contentType.includes("application/json")) {
    //            throw new TypeError("Received response is not JSON");
    //        }

    //        const data = await response.json();

    //        if (response.ok) {
    //            var fullPath = `${data.path}`;
    //            fullPath = fullPath.replace("3001", "3000")
    //            setScormLiveFolder(fullPath);
    //        } else {
    //            console.error('Hata:', data.message);
    //        }
    //    } catch (error) {
    //        console.error('İstek sırasında bir hata oluştu:', error);
    //    }
    //};

    const downloadAndUnzip = async (zipUrl) => {
        try {
            const response = await fetch(`http://localhost:3001/download-and-unzip?zipUrl=${encodeURIComponent(zipUrl)}`);

            // HTTP durum kodunu kontrol edin
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Yanıtın JSON formatında olup olmadığını kontrol edin
            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
                const responseText = await response.text(); // Yanıt gövdesini metin olarak okuyun
                console.error('Received response text:', responseText);
                throw new TypeError(`Received response is not JSON: ${responseText}`);
            }

            // Yanıtı JSON olarak okuma
            const data = await response.json();
            console.log('Received data:', data);

            if (response.ok) {
                var fullPath = `${data.path}`;
                fullPath = fullPath.replace("3001", "3000");
                setScormLiveFolder(fullPath);
            } else {
                console.error('Hata:', data.message);
            }
        } catch (error) {
            if (error instanceof SyntaxError) {
                console.error('Yanıtı ayrıştırırken bir hata oluştu:', error);
            } else if (error instanceof TypeError) {
                console.error('Yanıt türünde bir hata var:', error);
            } else {
                console.error('İstek sırasında bir hata oluştu:', error);
            }
        }
    };

    useEffect(() => {

        var API: any;

        var coreData: any = {
            student_id: props.user.userName,
            student_name: props.user.name + " " + props.user.surName,
        };

        NetworkRequest('api/ScormStatus/Get', {
            SectionContentId: props?.content?.id,
            ScormId: contentScormView?.id,
            UserId: props?.user?.id,

        }).then((response) => {
            if (response.data.resultCode == 0
                && response.data.detail
                && response.data.detail.scromJson
            ) {
                coreData = new Scorm12API(JSON.parse(response.data.detail.scromJson));
            }
        });

        API = new Scorm12API(settings);
        API.storeData(true);

        let dataFromLms = { // this data is passed from the LMS
            cmi: {
                core: { ...coreData }
            }
        };

        API.loadFromJSON(dataFromLms, '');

        window.API = API;

        //setScormApi(API);

    }, [])

    useEffect(() => {
        const zipUrl = props?.content?.data?.contentScormView?.zipUrl;
        downloadAndUnzip(zipUrl);
    }, []);




    useEffect(() => {
        setInterval(() => {
            var API: any = window.API;
            if (API.cmi.core.lesson_status == "passed")
                props.onComplete();

            setCMICoreData(API.cmi.core);
        }, 1000)
    }, [])

    useEffect(() => {
        NetworkRequest('api/ScormStatus/Add', {
            SectionContentId: props?.content?.id,
            ScormId: contentScormView?.id,
            UserId: props?.user?.id,
            ScromJson: JSON.stringify(CMICoreData)
        }).then((response) => {
            //console.log(response.data);
        });
    }, [CMICoreData])


    return (
        <>
            <div style={{ width: '100%', height: '80vh' }}>
                <iframe
                    src={scormLiveFolder}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen
                    id="scorm12Player"
                ></iframe>
            </div>
        </>
    );
}

export default RenderContentScorm;