import React, { useState, useContext, useEffect } from 'react';
import { Header, Icon, Popup } from 'semantic-ui-react';

export const SystemReportCard = (props) => {

    const cardStyle = {
        height: props.Height || "auto",
        width: props.Width || "250px",
        backgroundColor: "white",
        boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
        padding: props.Padding || "20px",
        borderRadius: props.borderRadius || "10px",
        display: "flex",
        alignItems: props.AlignItems || "center",
        justifyContent: "center",
        flexDirection: props.Icon ? "row" as "row" : "column" as "column",
        fontSize: props.FontSize || "3em",
        fontWeight: "bold" as "bold",
        color: "#9c9c9c",
        marginRight: "30px",
        marginTop: props.MarginTop || "20px",
        margin: props.Margin || "10px",
        textAlign: "center" as "center",
        gap: "0.3em"
    };



    const headerStyle = {
        fontFamily: "sans-serif",
        fontWeight: "500"
    }

    const textStyle = {
        fontFamily: "sans-serif",
        fontWeight: "100"
    };

    return (
        <>
            {props.PopupText != undefined ?
                <Popup position="bottom center" wide='very' inverted
                    content={props.PopupText}
                    trigger={
                        <div style={cardStyle}>
                            {props.Icon &&
                                <Icon
                                    disabled
                                size={"mini"}
                                className="defaultCursor"
                                    style={{ cursor: "default !important" }}
                                    color={props.IconColor || "grey"}
                                    name={props.Icon}
                                />
                            }

                            <div>
                                <h2 style={headerStyle}>
                                    {props.Header}
                                </h2>
                            </div>

                            <div>
                                <h2 style={textStyle}>
                                    {props.Text}
                                </h2>
                            </div>
                        </div>
                    } /> :
                <div style={cardStyle}>
                    {props.Icon &&
                        <Icon
                        disabled
                        className="defaultCursor"
                            size={"mini"}
                            style={{ cursor: "default !important" }}
                            color={props.IconColor || "grey"}
                            name={props.Icon}
                        />
                    }

                    <div>
                        <h2 style={headerStyle}>
                            {props.Header}
                        </h2>
                    </div>

                    <div>
                        <h2 style={textStyle}>
                            {props.Text}
                        </h2>
                    </div>
                </div>

            }
        </>
    )
}