import * as React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import DataTable from '../../../components/DataTable';

import { DataHandler } from '../../../helper/DataHelper';
import { digitallicaConfirm, prepareDataForTable } from '../../../helper/HelperFunctions';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';
import { IDataTable } from '../../../interfaces/interfaces';

const ApplicationHelperTypes = () => {

    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = useState("");

    const removeHelperType = (id) => {
        digitallicaConfirm(translate("Bu tipi ve bu tipe bağlı bütün yardımcıları silmek üzeresiniz. Emin misiniz?"), () => {
            NetworkRequest("api/ApplicationHelperTypes/RemoveHelperType", { id: id }).then((response) => {
                if (response.data.resultCode === 0) {
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                    getHelperTypeList();
                } else {
                    toast(translate("Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
        });    
        
    }

    const [helperTypes, setHelperTypes] = useState<IDataTable>({
        header: [
            translate("Tip"),
            translate("Yaratma Tarihi"),
            translate("İşlemler")
        ],
        data: [{
            showData: [],
            secretData: [{ id: "" }]
        }],
        transactions: [{
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/editHelperType",
            type: "edit"
        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: removeHelperType
        }],
        filter: {
            setSearch: setFilterText,
            search: filterText,
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/addHelperType",
        noCheckBox: true,
        addButons: []
    });

    //prepare data for table
    const helperTypeListPrepareData = (data, count) => {
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        //hidden column
        let model = prepareDataForTable(data, [
            "id"
        ]);

        setHelperTypes({
            ...helperTypes,
            data: model,
            filter: {
                setSearch: setFilterText,
                search: filterText
            },
            pagination: {
                count: Math.ceil(count / 25),
                setCurrentPage: setCurrentPage, currentPage: currentPage
            },
            addRowLink: helperTypes.addRowLink
        });
    }

    //get table data from database
    const getHelperTypeList = () => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        };
        ////debugger;
        NetworkRequest("api/ApplicationHelperTypes/GetHelperTypesDatatable", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                //console.log("getHelperTypeList: ", result);
                helperTypeListPrepareData(result.list, result.count);
            } else {
                toast(translate("Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const selectAllHandler = (model) => {

    }

    useEffect(() => {
        getHelperTypeList();
    }, [filterText, currentPage])

    return (
        <div className="generalContainerForWhiteBg">
            <DataTable dataTable={helperTypes}
                isAllSelected={false}
                selectAllHandler={selectAllHandler}
                isLoading={false}
            />
        </div>
    );
}

export default ApplicationHelperTypes;