import React, {memo} from 'react';
import {Icon, Label, Popup, Progress} from "semantic-ui-react";
import TranslateHelper from "../../../helper/TranslateHelper";

const ModuleListCard = ({
                            cardTitle,
                            cardDesc,
                            videoCount,
                            htmlCount,
                            testCount,
                            surveyCount,
                            taskCount,
                            registerDate,
                            courseTotalSection,
                            courseTotalContent,
                            link,
                            status,
                            progress,
                            isDone
                        }) => {

    var {translate} = TranslateHelper();
    return (
        <div className={"moduleListViewCard"}>
            <div className={"moduleListViewCardTitle"}>
                <Label circular={true} size={"medium"} color={status == "newModules" ? "red" :status == "continueModules" ? "orange" : "green"}>
                    <Icon name={status == "newModules" ? "certificate" : status == "continueModules" ? "hourglass two" : "flag checkered"} />
                    {translate(status == "newModules" ? "Yeni" : status == "continueModules" ? translate("Devam ediyor") : translate("Tamamlandı"))}
                </Label>
                {cardTitle}
               
            </div>
            <div className={"moduleListViewCardContent"}>
                <div className={"moduleListViewProgress"}>
                    <Progress
                        className={"endUserModulesProgress"}
                        size={"medium"}
                        percent={progress.toFixed(2)} indicating progress
                    />

                </div>
                
                <Popup content={translate("video içerir")} trigger={
                    <Label className={"userDashboardImagelabel"} circular>
                        <Icon name='video'/> {videoCount}
                    </Label>
                }/>


                <Popup content={translate("html dökümanı içerir")} trigger={
                    <Label className={"userDashboardImagelabel"} circular>
                        <Icon name='html5'/> {htmlCount}
                    </Label>
                }/>


                <Popup content={translate("soru içerir")} trigger={
                    <Label className={"userDashboardImagelabel"} circular>
                        <Icon name='question'/> {testCount}
                    </Label>
                }/>

                <Popup content={translate("anket içerir")} trigger={
                    <Label className={"userDashboardImagelabel"} circular>
                        <Icon name='list'/> {surveyCount}
                    </Label>
                }/>

                <Popup content={translate("görev içerir")} trigger={
                    <Label className={"userDashboardImagelabel"} circular>
                        <Icon name='check'/> {taskCount}
                    </Label>
                }/>


            </div>
           
        </div>
    );
};

export default memo(ModuleListCard);