import { DataHandler } from "./DataHelper";
import { isNullOrEmpty } from "./HelperFunctions";

export const displayProtectedAppDoc = (imageUrl, imageId?, setter?) => {
 

    //for ing
    return imageUrl;

    //if (DataHandler.isAppAuth != true) {
    //    return "";
    //}
    //if (JSON.stringify(imageUrl) == "{}") {
    //    ////console.log("displayProtectedAppDoc imageUrl if", imageUrl)
    //    return "";
    //}

    //if (!isNullOrEmpty(imageUrl) && !imageUrl.startsWith("data:") && !imageUrl.startsWith("blob") && (!imageUrl.startsWith("http") || imageUrl.startsWith("/"))) {
    //    imageUrl = DataHandler.getDocmanagerUrl() + imageUrl;
    //    if (imageUrl.indexOf("null") != -1) {
    //        imageUrl = imageUrl.replace("null", "");
    //    }
    //}

    //setTimeout(function () {
    //    if (imageId != undefined) {
    //        let elem: any = document.getElementById(imageId);
    //        if (elem != undefined) {
    //            elem.src = imageUrl;
    //        }
    //    }
    //    if (setter != undefined) {
    //        setter(imageUrl);
    //    }
    //}, 300);
    //////console.log("displayProtectedAppDoc imageUrl", imageUrl)
    //return imageUrl == null ? "" : imageUrl;
}

const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

//const fetchWithApplicationAuthentication = (url) => {
    //if (url == null || url == "null" || url == "") {
    //    return promiseMaker("");
    //}
    //try {
    //    let docUrl = DataHandler.getDocmanagerUrl();
    //    if (url.indexOf("http") != -1) {
    //        url = url.replace(docUrl, '');
    //    }
    //    return fetch(docUrl + "/DocumentManagers/GetApplicationDocument?url=" + url, {
    //        headers: {
    //            'Authorization': DataHandler.applicationToken,
    //            'Access-Control-Allow-Origin': '*',
    //            'Cache-Control': 'no-cache, no-store, must-revalidate',
    //            'Pragma': 'no-cache',
    //            'Expires': '0'
    //        }
    //    }).catch((e) => {
    //        //console.log("error", e);
    //    });
    //} catch (e) {
    //    return null;
    //}
//}