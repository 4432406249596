import * as React from 'react';
import { useState, useEffect, useRef, LegacyRef, RefObject } from 'react';
import { Button, Form, Grid, Icon, Image, Input, InputProps, Modal, TextArea } from 'semantic-ui-react';
import { NetworkRequest } from '../../helper/NetworkRequest';

import { DateFormat } from '../../helper/DateFormat';
import { media, style } from 'typestyle';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { toast } from 'react-toastify';
import { QrReader } from 'react-qr-reader';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from "react-device-detect";
import TranslateHelper from '../../helper/TranslateHelper';
import { Base64Renderer, downloadFileWithEx, giveMePreview, giveMePreviewWithBlob, isNullOrEmpty, scrollToTop } from "../../helper/HelperFunctions";
import _ from "lodash";
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../helper/DataHelper';
import parse from "html-react-parser";

import docFile from '../../images/FileUploadIcons/new/doc-file.png';
import gifFile from '../../images/FileUploadIcons/new/gif-file.png';
import jpegFile from '../../images/FileUploadIcons/new/jpeg-file.png';
import pdfFile from '../../images/FileUploadIcons/new/pdf-file.png';
import pngFile from '../../images/FileUploadIcons/new/png-file.png';
import pptFile from '../../images/FileUploadIcons/new/ppt-file.png';
import xlsxFile from '../../images/FileUploadIcons/new/xlsx-file.png';
import folderFile from '../../images/FileUploadIcons/new/folder.png';
import unauthorizedFile from '../../images/FileUploadIcons/new/unauthorized.png';

enum TaskType {
    "Fotoğraf Ekle" = 1,
    "Doküman İncele" = 2,
    "Etkinliğe Katıl" = 4,
    "Toplantı" = 8,
    "Serbest Görev" = 15
}

function RenderContentTask(props) {

    var { translate } = TranslateHelper();
    let _ = require("lodash");

    const [showUploadPhoto, setShowUploadPhoto] = useState(false);
    const [isStatusTakenBack, setIsStatusTakenBack] = useState(true);
    const [isQrReaderShown, setIsQrReaderShown] = useState(false);
    const [isSavedComplatetionJson, setIsSavedComplatetionJson] = useState(false);
    const [coutdownFinished, setCoutdownFinished] = useState(false);
    const [photoThumbnail, setPhotoThumbnail] = useState(false);
    const [loginUserId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [adminUserId, setAdminUserId] = useState(DataHandler.getUserDataWithKey("adminUserId"));
    const [transactionUserId] = useState(props.transactionUserId);
    const [completationText, setCompletationText] = useState("");
    const [canComplate, setCanComplate] = useState(true);
    const [user, setUser] = useState(props.user);
    const [content, setContent] = useState(props.content);
    const [task, setTask] = useState(props?.content?.data?.contentTaskResponse?.contentTask);
    const [moduleHistory, setModuleHistory] = useState(props.history);
    const [activity, setActivity] = useState<any>();
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [fileUrlList, setFileUrlList] = useState<Array<any>>([]);
    const [images, setImages] = useState<Array<any>>([]);
    const [whoCanComplate, setWhoCanComplate] = useState<Array<any>>([]);
    const [showRenameFileModal, setShowRenameFileModal] = useState(false);
    const [renameFileText, setRenameFileText] = useState<any>({ fileName: "", src: "" });
    const [isPrepareIcsFile, setIsPrepareIcsFile] = useState<boolean>(false);
    const [fileUpload] = useState([".jpeg", ".jpg", ".gif", ".png", ".xlsx", ".pptx", ".docx", ".pdf"]);
    const [icons] = useState([
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/doc-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/gif-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/jpeg-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/pdf-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/png-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/ppt-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/xlsx-file.png",
        DataHandler.getDocmanagerUrl() + "/Documents/FileUploadIcons/new/folder.png",
    ]);
    const [iconsV2] = useState([
        docFile, gifFile, jpegFile, pdfFile, pngFile, pptFile, xlsxFile, folderFile
    ]);
    const [photoThumbnailEx, setPhotoThumbnailEx] = useState(false);
    const [fileUploadTaskUrlList, setFileUploadTaskUrlList] = useState<Array<any>>([]);
    const [fileUploadTaskFiles, setFileUploadTaskFiles] = useState<Array<any>>([]);

    let styles = {
        transparent: {
            background: 'rgba(255,255,255,.60)'
        },
        transparentImg: {
            opacity: "0.5"
        },
        pointerCursor: {
            cursor: "pointer !important"
        },
        inputStyle: {
            width: "100%"
        },
        complateButton: {
            marginTop: "2rem",
            width: "90%",
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center" as "center",
            gap: "1rem"
        },
        container: {
            display: "flex",
            flexDirection: "column" as "column",
            width: "100%",
            height: "100vh",
            paddingBottom: "1rem"
        },
        cardContainer: {
            width: "100%"
        },
        contentSettingsContainer: style({
            width: "100%",
            display: "flex",
            padding: ".25rem 2rem",
            marginBottom: "2rem",
            alignItems: "center",
            flexDirection: "row" as "row",
            gap: "1rem"

        },
            media({ maxWidth: 820 }, { flexDirection: "column" })
        ),
        contentSettingHeader: style({
            width: "10%",

            alignItems: "center" as "center",

        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        contentSettingDescription: {
            width: "90%",
            margin: "0px 2rem",
            padding: "0px 31px"
        },
        contentTimingContainer: style({
            width: "50%",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center"
        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        contentTimingTeacherContainer: style({
            width: "50%",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center"
        },
            media({ maxWidth: 820 }, { width: "100%", marginTop: "3rem" })
        ),
        contentTimingHeader: {
            width: "30%",
            flexWrap: "wrap" as "wrap"
        },
        contentTimingDetailBox: {
            borderRadius: "5px",
            border: "1px #E9E9E9 solid",
            width: "70%",
            padding: ".5rem 1rem",

        },
        contentTimingLocationDetailBox: {
            borderRadius: "5px",
            border: "1px #E9E9E9 solid",
            width: "115%",
            padding: ".5rem 1rem",

        },
        btnDark: {
            width: "228px",
            height: "53px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        },
        contentDescriptionStyle: {
            borderRadius: "8px",
            width: "100%",
            minHeight: "100px",
            padding: "25px 31px",
            color: "rgba(41, 40, 40, 1)",
            fontWeight: "400",
            fontSize: "14px",
            border: "1px #E9E9E9 solid"
        },
        btnDarkOutline: {
            width: "228px",
            height: "53px",
            right: "350px",
            top: "700px",
            background: "#fff",
            border: "1px solid #292828",
            boxSizing: "border-box" as "border-box",
            borderRadius: "8px"
        },
        alignRight: style({},
            media({ minWidth: 820 }, {
                textAlign: "right" as "right",
                paddingRight: "1rem",
                textIndent: "2rem",
                width: "20%"
            })
        ),
        timeCounter: {
            width: "58px",
            height: "58px",
            background: "rgba(196, 196, 196, 0.6)",
            borderRadius: "50%",
            fontSize: "14px",
            fontWeight: "700",
            alignItems: "center",
            justifyContent: "center",
            color: "#292828",
            display: "flex"

        },
        complateContainer: {
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            marginTop: "1rem",
            flexDirection: "column" as "column",
        },
        bold700: {
            fontWeight: "700"
        },
        boxBorder: {
            borderRadius: "5px",
        },
        thumbnailPhoto: {
            width: "50vw" as "50vw",

        }
    }

    const sendPhotosToManagers = (complatetionJson) => {
        NetworkRequest("/api/SectionContent/SendPhotosToManagers", {
            jsonModel: complatetionJson,
            userId: DataHandler.getUserDataWithKey("userId"),
            contentTitle: content.title
        });
    }

    const complateContent = (complatetionJson = {}) => {
        setIsStatusTakenBack(false);
        props.onComplete(complatetionJson, content);
        ////debugger;
        setCanComplate(false);
        setCoutdownFinished(true);
        if (complatetionJson != undefined && Object.keys(complatetionJson).length > 0) {
            setIsSavedComplatetionJson(true);
        }
        if (content.data.contentTaskResponse.contentTask.type == 1) {
            sendPhotosToManagers(complatetionJson);
        }
    }

    const handleChangeImage = (e) => {
        if (task.type != 1)
            return;

        if (e == undefined || e.target.files.length == 0) {
            setImages([]);
            setFileUrlList([]);
            setIsSavedComplatetionJson(false);
            ////debugger;
            setCanComplate(false);
            let photoInput: any = document.getElementsByName("photo")[0];
            photoInput.value = "";
            return;
        }
        //setImages(e.target.files);
        setImages(oldArray => [...oldArray, ...e.target.files]);
        let arr: Array<any> = [];
        _.each(e.target.files, (doc) => {
            arr.push(URL.createObjectURL(doc));
        });

        let clone = _.cloneDeep(fileUrlList);
        clone.push(...arr);
        setFileUrlList(clone);

        setShowUploadPhoto(true);
    }

    const handleChangeFile = (e) => {

        if (task.type != 8)
            return;
        var accept = false;
        fileUpload.map(x => {
            accept = e.target.value.indexOf(x) == -1 ? accept : true;
        })
        if (!accept) {
            toast(translate("Lütfen sadece ") + fileUpload.join(", ") + translate(" dosyalarını yükleyin"), {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                autoClose: 10000
            });
            return;
        }

        if (e == undefined || e.target.files.length == 0) {
            setFileUploadTaskFiles([]);
            // debugger;
            setFileUploadTaskUrlList([]);
            setIsSavedComplatetionJson(false);
            ////debugger;
            setCanComplate(false);
            let photoInput: any = document.getElementsByName("photo")[0];
            photoInput.value = "";
            return;
        }
        //setImages(e.target.files);
        setFileUploadTaskFiles(oldArray => [...oldArray, ...e.target.files]);
        let arr: Array<any> = [];
        _.each(e.target.files, (doc) => {
            arr.push({ src: URL.createObjectURL(doc), fileName: doc.name });
        });

        setFileUploadTaskUrlList(oldArray => [...oldArray, ...arr]);

        setShowUploadPhoto(true);
    }

    const uploadImage = () => {
        if (fileUrlList.length == 0) {
            return;
        }
        toast(translate("Yükleniyor..."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 120000 });
        let appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';
        let usr = JSON.stringify({ id: transactionUserId != "" ? transactionUserId : loginUserId, appType: appType });

        var formData = new FormData();

        for (let i = 0; i < images.length; i++) {
            formData.append("files", images[i]);
        }
        formData.append('request', usr);
        formData.append('courseHistory', usr);
        NetworkRequest("/ModuleHistory/AttachImages", formData, true).then((response) => {
            toast.dismiss();
            let result = response.data;
            if (result.resultCode == 0) {
                setIsStatusTakenBack(false);

                setShowUploadPhoto(false);
                toast(translate("Yüklendi"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                setIsSavedComplatetionJson(true);
                setCanComplate(true);
                ////debugger;
                //let clone = _.cloneDeep(images);
                //clone.push(...result.detail);
                //clone = _.filter(clone, (doc) => {
                //    return doc.indexOf('blob') == -1;
                //});
                setImages([]);

                let cloneFileUrlList = _.cloneDeep(fileUrlList);
                cloneFileUrlList.push(...result.detail);
                cloneFileUrlList = _.filter(cloneFileUrlList, (doc) => {
                    return doc.indexOf('blob') == -1;
                });
                setFileUrlList(cloneFileUrlList);

                props.addOrUpdateModuleHistory({ fileUrlList: cloneFileUrlList, text: completationText });
            } else {
                toast(translate(result.message), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
            }
        });
    }

    const checkCanISeeUploadeds = () => {
        var role = DataHandler.getUserDataWithKey("userRole");
        role = role == "HumanResources" ? "HR" : role;
        if (role == "User") {
            return true;
        } else if (whoCanComplate.filter(x => x == "All").length > 0 || whoCanComplate.filter(x => x == role).length > 0) {
            return true;
        }
        return false;
    }

    const uploadFile = () => {
        if (fileUploadTaskUrlList.length == 0) {
            return;
        }
        toast(translate("Yükleniyor..."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 120000 });
        let appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';
        let usr = JSON.stringify({ id: transactionUserId != "" ? transactionUserId : loginUserId, appType: appType });

        var formData = new FormData();

        // debugger;
        for (let i = 0; i < fileUploadTaskFiles.length; i++) {
            formData.append("files", fileUploadTaskFiles[i]);
        }
        formData.append('request', usr);
        formData.append('courseHistory', usr);

        NetworkRequest("/ModuleHistory/AttachFiles", formData, true).then((response) => {
            toast.dismiss();
            let result = response.data;
            if (result.resultCode == 0) {
                setIsStatusTakenBack(false);

                setShowUploadPhoto(false);
                toast(translate("Yüklendi"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                setIsSavedComplatetionJson(true);
                setCanComplate(true);
                let clone = _.cloneDeep(fileUrlList);
                clone.push(...result.detail);
                clone = _.filter(clone, (doc) => {
                    return typeof doc.src == 'string' && doc.src.indexOf('blob') == -1;
                });
                debugger;
                setFileUploadTaskUrlList(clone);
                ////console.log("clone", clone)
                props.addOrUpdateModuleHistory({ fileUrlList: clone, text: completationText });
            } else {
                toast(translate(result.message), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
            }
        });
    }

    const changeFileName = () => {
        let clone = _.cloneDeep(fileUploadTaskUrlList);
        var index = clone.map(x => x.src).indexOf(renameFileText.src)
        //var index = clone.find(x => x.src == renameFileText.src)
        clone[index].fileName = renameFileText.fileName + clone[index].fileName.slice(clone[index].fileName.lastIndexOf("."));

        setFileUploadTaskUrlList(clone);

        setShowRenameFileModal(false);
    }

    const isBlob = (path) => {
        return path.indexOf("blob") == -1 ? false : true;
    }

    const giveMeIconForDocType = (path) => {

        var i = fileUploadTaskUrlList.map(x => x.src).indexOf(path);
        var file;
        file = fileUploadTaskUrlList[i];
        path = file?.fileName?.slice(file?.fileName?.lastIndexOf(".") + 1)?.toLowerCase() || path?.slice(path?.lastIndexOf(".") + 1)?.toLowerCase()

        // if (fileUploadTaskFiles.length > 0) {
        //     file = fileUploadTaskUrlList[i]
        //     debugger
        //     // if(file === undefined) return;
        //    
        //     path = file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase()
        // } else {
        //     file = fileUploadTaskUrlList[i];
        //     path = file.fileName.slice(file.fileName.lastIndexOf(".") + 1).toLowerCase()
        // }

        switch (path) {
            case "png":
                return iconsV2[4];
            case "jpeg":
            case "jpg":
                return iconsV2[2];
            case "ppt":
            case "pptx":
                return iconsV2[5];
            case "pdf":
                return iconsV2[3];
            case "xls":
            case "xlsx":
                return iconsV2[6];
            case "doc":
            case "docx":
                return iconsV2[0];
            case "gif":
                return iconsV2[1];
            default:
                return iconsV2[7];

        }
    }

    const removeImage = (index) => {
        if (props.isDisabled(content)) {
            return;
        }
        let clone = _.cloneDeep(fileUrlList);
        clone.splice(index, 1);
        setFileUrlList(clone);
        if (clone.length == 0)
            setIsStatusTakenBack(true);
        if (moduleHistory != undefined) {
            toast(translate("Lütfen bekleyin..."), {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                autoClose: 15000
            });
            props.addOrUpdateModuleHistory({ fileUrlList: clone, text: completationText }).then((response) => {
                props.getSectionWithDetails(props.moduleId, content.moduleSectionId, props.user.id);
            });

            toast.dismiss();
        }
    }

    const removeFile = (index) => { //fileUploadTask
        if (props.isDisabled(content)) {
            return;
        }
        let clone = _.cloneDeep(fileUploadTaskUrlList);
        clone.splice(index, 1);
        setFileUploadTaskUrlList(clone);
        if (clone.length == 0)
            setIsStatusTakenBack(true);
        if (moduleHistory != undefined) {
            toast(translate("Lütfen bekleyin..."), {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                autoClose: 15000
            });
            props.addOrUpdateModuleHistory({ fileUrlList: clone, text: completationText }).then((response) => {
                props.getSectionWithDetails(props.moduleId, content.moduleSectionId, props.user.id);
            });

            toast.dismiss();
        }
    }

    const logMe = () => {
        ////console.log("image", image);
        ////console.log("moduleHistory", moduleHistory);
        ////console.log("moduleHistory.isCompleted", moduleHistory.isCompleted);
        ////console.log("isSavedComplatetionJson", isSavedComplatetionJson);
    }

    const children = (remainingTime) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60
        let secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`
        let minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
        return `${minutesString}.${secondsString}`
    }

    const downloadIcs = (activity) => {
        setIsPrepareIcsFile(true);
        if (isMobile) {
            const formData = new FormData();
            formData.append("toEmail", DataHandler.getUserDataWithKey("email"));
            formData.append("activityView", JSON.stringify(activity));

            NetworkRequest("api/User/SendIcsFileToUserByEmail", formData, true).then((response) => {
                let result = response.data;
                if (result.resultCode == 0) {
                    toast(translate(result.message), {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                } else {
                    toast(translate(result.message), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                }
                setIsPrepareIcsFile(false);
                return;
            });

        }


        // Event location
        var eventLocation = activity.locationName;
        // Summary
        var summary = activity.title;
        // Description
        var description: any = activity.description;
        // Split string in lines with max length 75 characters to comply with the ICS format
        description = description.match(/.{1,75}/g).join("\\n");
        // Build ics file contents
        var b: any = [];
        b.push("BEGIN:VCALENDAR");
        b.push("PRODID:-//DFFS//SPJSBlog.com//EN");
        b.push("VERSION:2.0");
        b.push("BEGIN:VEVENT");
        b.push("ORGANIZER;CN=\"" + activity.teacherName + "\":mailto:" + DataHandler.getUserDataWithKey("email"));
        b.push("DTSTAMP:" + new Date().toISOString());
        b.push("STATUS:CONFIRMED");
        b.push("UID:DFFS_" + "_DFFSID");
        b.push("SEQUENCE:0");
        b.push("DTSTART:" + activity.startDate);
        b.push("DTEND:" + activity.endDate);
        b.push("SUMMARY:" + summary);
        b.push("DESCRIPTION:" + description);
        b.push("LOCATION:" + eventLocation);
        b.push("END:VEVENT");
        b.push("END:VCALENDAR");

        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/text;charset=utf-8,' + encodeURI(b.join("\n")));
        element.setAttribute('hidden', "hidden");
        element.setAttribute('download', activity.title + ".ics");
        element.click();

        setTimeout(() => {
            setIsPrepareIcsFile(false);
        }, 1000);

    }

    const isAddPhotoVisible = () => {
        ////console.log("isAddPhotoVisible", fileUrlList.length == 0 || (props.content != undefined && props.content.status != undefined && !props.content.status.isCompleted));

        return props.content != undefined && props.content?.status != undefined && !props.content?.status?.isCompleted;
    }

    const giveMeFileNameWithoutPath = (fileName) => {
        if (!fileName)
            return "";
        return fileName.indexOf(".") == -1 ? fileName : fileName.slice(0, fileName.lastIndexOf("."));
    }

    const isFileAddVisible = () => {

        ////console.log("isAddPhotoVisible", fileUrlList.length == 0 || (props.content != undefined && props.content.status != undefined && !props.content.status.isCompleted));
        var rt = fileUploadTaskUrlList.length == 0 || (props.content != undefined && props.content?.status != undefined && (!props.content?.status?.isCompleted || isStatusTakenBack));
        if (!rt)
            //debugger;
            return rt;
    }

    const renderTask = () => {

        switch (task.type) {
            case 1: //foto
                return <div className="photoTaskContainer">
                    {props.showContentInfo
                        &&
                        <div
                            style={{ flexDirection: "column", alignItems: "flex-start" }}
                            className={"renderContentSettingsContainer " + styles.contentSettingsContainer}
                        >
                            <div
                                className={"renderContentSettingHeaderContainer renderContentTaskMissionHeader " + styles.contentSettingHeader}
                                style={{ ...styles.bold700, width: "30%" }}>
                                {translate("Görev Açıklama")}
                            </div>
                            <div className="renderContentDescContainer"
                                style={{ ...styles.contentDescriptionStyle, wordBreak: "break-all" }}>
                                {task.description}
                            </div>
                        </div>
                    }
                    <Form className="freeTaskTextareaContent marginBottom2 width100">
                        <TextArea
                            className="textareaStyle"
                            rows={5}
                            id="textareaDesc"
                            disabled={!canComplate && content?.status != undefined && content?.status?.isCompleted}
                            placeholder={translate("Açıklama")}
                            style={{ ...styles.inputStyle, ...styles.transparent }}
                            value={completationText}
                            onChange={(e) => {
                                if (content?.status != undefined && content?.status?.isCompleted) {
                                    return;
                                }
                                setCompletationText(e.target.value);
                            }}
                        />
                    </Form>
                    <Grid>
                        <Grid.Row>
                            {fileUrlList.length > 0 && fileUrlList.map((fileUri, index) => (
                                <Grid.Column
                                    key={uuidv4()}
                                    className={isMobile ? "width100" : "width19 marginRight1"}
                                >
                                    <img
                                        id={"taskUrl" + index}
                                        className="imgStyle"
                                        src={fileUri}
                                        onClick={() => {
                                            setPhotoThumbnail(true)
                                        }}
                                    />
                                    {fileUri != "" && !props.isDisabled(content)
                                        &&
                                        <div className="closeBtnStyle thisIsCenter"
                                            onClick={() => {
                                                removeImage(index)
                                            }}
                                        >
                                            <Icon name="trash alternate" className="colorRed" />
                                        </div>
                                    }
                                </Grid.Column>
                            ))}
                        </Grid.Row>
                    </Grid>


                    <div className="width100 thisIsCenter">
                        {isAddPhotoVisible()
                            &&
                            <div
                                className="imageAddContainer"
                                onClick={() => document.getElementsByName("photo")[0].click()}
                            >
                                <img
                                    width="38"
                                    src={"/files/images/icons/coolicon.svg"}
                                    style={{ cursor: "pointer !important" }}
                                />
                            </div>
                        }
                    </div>
                    <div className="width100 thisIsCenter marginTop1"
                        onClick={() => uploadImage()}>
                        {images.length > 0 && showUploadPhoto
                            &&
                            <Button
                                circular={true}
                                style={{ marginTop: isMobile ? "2%" : "unset" }}
                                color="green"
                            >
                                {fileUrlList.length == 1 ? translate('Fotoğrafı Yükle') : translate('Fotoğrafları Yükle')}
                            </Button>
                        }
                    </div>

                    <Input
                        name="photo"
                        size="large"
                        style={{ ...styles.inputStyle, display: "none" }}
                        width="300px"
                        height="300px"
                        type="file"
                        multiple
                        accept="image/*"
                        onInput={handleChangeImage}
                        disabled={props.isDisabled(content)}
                    />
                    <Modal
                        onClose={() => setPhotoThumbnail(false)}
                        onOpen={() => setPhotoThumbnail(true)}
                        open={photoThumbnail}
                        centered={false}
                    >
                        <Modal.Actions>
                            <Button
                                className={"dangerRedButton"}
                                onClick={() => setPhotoThumbnail(false)}
                            >{translate("Kapat")}</Button>
                        </Modal.Actions>
                        <Modal.Content
                            image
                            style={{ display: "flex", flexDirection: "column" as "column", alignItems: "center", gap: "1em", with: "100%", justifyContent: "center", overflowY: "auto", height: "70vh", padding: "3%" }}>
                            {fileUrlList.map((fileUri, index) => (
                                <div key={uuidv4()} style={{ position: "relative" }}>
                                    <div>
                                        <img
                                            id={"taskUrlImage" + index}
                                            width={"50%"}
                                            src={fileUri}
                                            onClick={() => {
                                                setPhotoThumbnail(true)
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Modal.Content>
                    </Modal>
                </div>

            case 2: //döc

                if (task.taskJson != null && typeof task.taskJson == 'string')
                    task.taskJson = JSON.parse(task.taskJson);

                return <>
                    <div className="iframeContainer">
                        <div id="iframe" className="iframe">



                            {((!isNullOrEmpty(task?.fileUri) && task?.fileUri?.startsWith("http")) || (!isNullOrEmpty(task?.taskJson?.FileName) && task?.taskJson?.fileName?.startsWith("http")))
                                &&

                                <div
                                    className={"userDetailReportFileDesign"}
                                >
                                    <div className={"userDetailReportFileHeaderDesign"}>
                                        <img
                                            width="20px"
                                            height="auto"
                                            id="reportTaskIMage-2"
                                            src={giveMeIconForDocType(task.taskJson.fileName || task?.taskJson?.FileName)}
                                        />

                                        <div className={"userDetailReportFileNameDesign"}>{task.taskJson.fileName || task?.taskJson?.FileName}</div>
                                    </div>
                                    <div>
                                        <div className={"userDetailReportDownloadButton"} onClick={() => {
                                            downloadFileWithEx(
                                                task?.fileUri,
                                                task?.taskJson?.fileName || task?.taskJson?.FileName || "file",
                                                task?.taskJson?.fileName?.split(".")[task?.taskJson?.fileName?.split(".")?.length - 1] ||
                                                task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] ||
                                                "txt")
                                        }}>
                                            <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                        </div>
                                    </div>
                                </div>






                            }
                            {(!isNullOrEmpty(task.fileUri) && !task.fileUri.startsWith("http"))
                                &&
                                <>
                                    <div
                                        className={"userDetailReportFileDesign"}
                                    >
                                        <div className={"userDetailReportFileHeaderDesign"}>
                                            <img
                                                width="20px"
                                                height="auto"
                                                id="reportTaskIMage-2"
                                                src={giveMeIconForDocType(task.taskJson.fileName || task?.taskJson?.FileName)}
                                            />

                                        <div className={"userDetailReportFileNameDesign"}>{task.taskJson.fileName || task?.taskJson?.FileName}</div>
                                        </div>
                                        <div>
                                            <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                downloadFileWithEx(
                                                    task?.fileUri,
                                                    task?.taskJson?.fileName || task?.taskJson?.FileName || "file",
                                                    task?.taskJson?.fileName?.split(".")[task?.taskJson?.fileName?.split(".")?.length - 1] ||
                                                    task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] ||
                                                    "txt")
                                            }}>
                                                {/*<div><Icon name="save" /></div>*/}
                                                {/*{translate("İndir")}*/}

                                                <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />
                                            </div>
                                        </div>
                                    </div>


                                    {/*preview document*/}
                                        <div className="imgOrPdfRendererClassName">
                                    {Base64Renderer(task?.fileUri || task?.FileUri)}
                                        </div>
                                    {/*preview document*/}
                                </>
                            }



                            {/*{task.fileUri.startsWith("http") &&*/}
                            {/*    (*/}
                            {/*        task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] != "pptx"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] != "ppt"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "xlsx"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "xls"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "docx"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "doc"*/}
                            {/*    )*/}
                            {/*    &&*/}
                            {/*    giveMePreview(*/}
                            {/*        task.fileUri,*/}
                            {/*        DataHandler.getDocmanagerUrl(),*/}
                            {/*        true,*/}
                            {/*        photoThumbnailEx,*/}
                            {/*        setPhotoThumbnailEx,*/}
                            {/*        task.taskJson.FileName*/}
                            {/*    )*/}
                            {/*}*/}
                            {/*{!task.fileUri.startsWith("http") &&*/}
                            {/*    (*/}
                            {/*        task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] != "pptx"*/}
                            {/*        && task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] != "ppt"*/}
                            {/*        && task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "xlsx"*/}
                            {/*        && task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "xls"*/}
                            {/*        && task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "docx"*/}
                            {/*        && task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName.split(".")?.length - 1] != "doc"*/}
                            {/*    )*/}
                            {/*    &&*/}
                            {/*    giveMePreviewWithBlob(*/}
                            {/*        task.mimeType,*/}
                            {/*        task.fileUri,*/}
                            {/*        DataHandler.getDocmanagerUrl(),*/}
                            {/*        true,*/}
                            {/*        photoThumbnailEx,*/}
                            {/*        setPhotoThumbnailEx,*/}
                            {/*        task.taskJson.FileName*/}
                            {/*    )*/}
                            {/*}*/}

                            {/*{!task.fileUri.startsWith("http") &&*/}
                            {/*    (*/}
                            {/*        task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] == "pptx"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] == "ppt"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] == "xlsx"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] == "xls"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] == "docx"*/}
                            {/*        || task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] == "doc"*/}
                            {/*    )*/}
                            {/*    &&*/}
                            {/*    <div*/}
                            {/*        className={"userDetailReportFileDesign"}*/}
                            {/*    >*/}
                            {/*        <div className={"userDetailReportFileHeaderDesign"}>*/}
                            {/*            <img*/}
                            {/*                width="20px"*/}
                            {/*                height="auto"*/}
                            {/*                id="reportTaskIMage-2"*/}
                            {/*                src={giveMeIconForDocType(task?.taskJson?.FileName)}*/}
                            {/*            />*/}

                            {/*            <div className={"userDetailReportFileNameDesign"}>{task?.taskJson?.FileName}</div>*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <div className={"userDetailReportDownloadButton"} onClick={() => {*/}
                            {/*                downloadFileWithEx(*/}
                            {/*                    task?.fileUri,*/}
                            {/*                    task?.taskJson?.FileName || "file",*/}
                            {/*                    task?.taskJson?.FileName?.split(".")[task?.taskJson?.FileName?.split(".")?.length - 1] || "txt")*/}
                            {/*            }}>*/}
                            {/*                {translate("İndir")}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                    </div>
                    <div style={styles.complateContainer}>
                        {!canComplate && !coutdownFinished &&
                            <CountdownCircleTimer
                                isPlaying={!coutdownFinished}
                                duration={minutes * 60}
                                onComplete={() => {
                                    setCanComplate(true);
                                }}
                                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                colorsTime={[10, 5, 3, 0]}
                                size={100}
                            >
                                {({ remainingTime }) => children(remainingTime)}
                            </CountdownCircleTimer>
                        }
                    </div>
                </>

            case 4: //etkinlik
                //console.log("case 4");
                return <>
                    {props.showContentInfo &&
                        <>
                            {activity != undefined
                                &&
                                <>
                                    <div className={"renderContentEventContainer " + styles.contentSettingsContainer}>
                                        <div
                                            className={"renderContentContainerFlex renderContentSettingsContainer " + styles.contentTimingContainer}>
                                            <div
                                                className={"renderContentSettingHeaderContainer"}
                                                style={{ ...styles.contentTimingHeader, ...styles.bold700 }}>
                                                {translate("Etkinlik Başlangıç Tarihi")}
                                            </div>
                                            {activity != undefined && activity.startDate != undefined &&
                                                <span style={{ width: "100%" }}>
                                                    {DateFormat(activity.startDate)}
                                                </span>
                                            }
                                        </div>
                                        <div
                                            className={"renderContentContainerFlex renderContentSettingsContainer " + styles.contentTimingContainer}>
                                            <div
                                                style={{ ...styles.contentTimingHeader, ...styles.bold700, }}
                                                className={"renderContentSettingHeaderContainer " + styles.alignRight}
                                            >
                                                {translate("Etkinlik Bitiş Tarihi")}
                                            </div>
                                            {activity != undefined && activity.endDate != undefined &&
                                                <span style={{ width: "100%" }}>
                                                    {DateFormat(activity.endDate)}
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className={"renderContentEventContainer " + styles.contentSettingsContainer}>

                                        <div
                                            className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>

                                            <div
                                                className={"renderContentSettingHeaderContainer " + styles.contentTimingContainer}
                                                style={{ position: "relative" }}>
                                                {translate("Lokasyon Adı")}
                                            </div>
                                            <span style={{ width: "100%" }}>
                                                {activity.locationName}
                                            </span>
                                            {activity.isOnlineActivity &&
                                                <div style={{ width: '100%', textAlign: 'right', pointerEvents: props.isDisabled(props.content) ? "none" : "auto" }}>
                                                    <a href={activity.onlineActivityLink}
                                                        className="continueBlueButton"
                                                        target="_blank"
                                                    >
                                                        {translate("Eğitime Git")}
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                        {activity.isOnlineActivity
                                            ?
                                            <div
                                                className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingTeacherContainer}>
                                                <div
                                                    style={{ ...styles.contentTimingHeader, ...styles.bold700, }}
                                                    className={"renderContentSettingHeaderContainer " + styles.alignRight}
                                                >
                                                    {translate("Eğitmen Adı")}
                                                </div>
                                                <span style={{ width: "100%" }}>
                                                    {activity.teacherName}
                                                </span>
                                            </div>
                                            :
                                            <div
                                                className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                                <div
                                                    style={{ ...styles.contentTimingHeader, ...styles.bold700, }}
                                                    className={"renderContentSettingHeaderContainer " + styles.alignRight}
                                                >
                                                    {translate("Eğitmen Adı")}
                                                </div>
                                                <span style={{ width: "100%" }}>
                                                    {activity.teacherName}
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    <div
                                        className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentSettingsContainer}
                                        style={{ flexDirection: "column", gap: "18px", alignItems: "flex-start" }}>
                                        {props.showContentInfo
                                            &&
                                            <>
                                                <div
                                                    className={"renderContentSettingHeaderContainer " + styles.contentSettingHeader}
                                                    style={{ ...styles.bold700, width: "30%" }}
                                                >
                                                    {translate("Etkinlik Açıklama")}
                                                </div>
                                                <div
                                                    className={"renderContentDescContainer"}
                                                    style={{ ...styles.contentDescriptionStyle, wordBreak: "break-all" }}>
                                                    {activity.description}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            }

                            {activity != undefined && activity.mapHtml != undefined && !activity.isOnlineActivity
                                &&
                                <div
                                    className={"renderContentSettingsContainer renderContentContainerFlex eventLocationClass " + styles.contentSettingsContainer}>
                                    <div
                                        className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}
                                        style={{ position: "relative" }}
                                    >
                                        <div
                                            className={"renderContentSettingHeaderContainer"}
                                            style={{ ...styles.contentTimingHeader, ...styles.bold700, ...styles.bold700 }}
                                        >
                                            {translate("Etkinlik Lokasyonu")}
                                        </div>
                                        <div
                                            className="userMapContainer"
                                            dangerouslySetInnerHTML={{ __html: activity.mapHtml }}
                                        ></div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>

            case 8: //DOSYA YUKLE
                return <div className="photoTaskContainer">
                    {props.showContentInfo
                        &&
                        <div
                            style={{ flexDirection: "column", alignItems: "flex-start" }}
                            className={"renderContentSettingsContainer " + styles.contentSettingsContainer}
                        >
                            <div
                                className={"renderContentSettingHeaderContainer renderContentTaskMissionHeader " + styles.contentSettingHeader}
                                style={{ ...styles.bold700, width: "30%" }}>
                                {translate("Görev Açıklama")}
                            </div>
                            <div className={"renderContentDescContainer"}
                                style={{ ...styles.contentDescriptionStyle, wordBreak: "break-all" }}>
                                {task.description}
                            </div>
                        </div>
                    }
                    <Form className="freeTaskTextareaContent marginBottom2 width100">
                        <TextArea
                            className={"textareaStyle"}
                            rows={5}
                            id="textareaDesc"
                            disabled={!canComplate && content.status != undefined && content?.status?.isCompleted}
                            placeholder={translate("Açıklama")}
                            style={{ ...styles.inputStyle, ...styles.transparent }}
                            value={completationText}
                            onChange={(e) => {
                                if (content.status != undefined && content?.status?.isCompleted) {
                                    return;
                                }
                                setCompletationText(e.target.value);
                            }}
                        />
                    </Form>




                    <>
                        {checkCanISeeUploadeds() && fileUploadTaskUrlList.length > 0 && fileUploadTaskUrlList.map((fileUri, index) => (

                            <div key={index} className="mb-3">
                                {fileUri != null && fileUri?.fileName.startsWith("http") &&

                                    <div
                                        className={"userDetailReportFileDesign"}
                                    >
                                        <div className={"userDetailReportFileHeaderDesign"}>
                                            <img
                                                width="20px"
                                                height="auto"
                                                id="reportTaskIMage-2"
                                                src={giveMeIconForDocType(fileUri.fileName)}
                                            />

                                            <div className={"userDetailReportFileNameDesign"}>{fileUri.fileName}</div>
                                        </div>
                                        <div>
                                            <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                downloadFileWithEx(fileUri.src, fileUri.fileName || "file", fileUri.fileName?.split(".")[fileUri.fileName?.split(".")?.length - 1] || "txt")
                                            }}>
                                                {/*{translate("İndir")}*/}
                                                <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                            </div>
                                        </div>
                                    </div>



                                }

                                {fileUri != null && !fileUri?.fileName.startsWith("http") &&

                                    <div
                                        className={"userDetailReportFileDesign"}
                                    >
                                        <div className={"userDetailReportFileHeaderDesign"}>
                                            <img
                                                width="20px"
                                                height="auto"
                                                id="reportTaskIMage-2"
                                                src={giveMeIconForDocType(fileUri.fileName)}
                                            />

                                            <div className={"userDetailReportFileNameDesign"}>{fileUri.fileName}</div>
                                        </div>
                                        <div>
                                            <div className={"userDetailReportDownloadButton"} onClick={() => {
                                                downloadFileWithEx(
                                                    fileUri.src,
                                                    fileUri.fileName || "file",
                                                    fileUri.fileName?.split(".")[fileUri.fileName?.split(".")?.length - 1] || "txt")
                                            }}>
                                                {/*{translate("İndir")}*/}
                                                <Button circular color="violet" content={translate("İndir")} icon='save' labelPosition='left' />

                                            </div>
                                        </div>
                                    </div>


                                }
                            </div>

                        ))}

                        {!checkCanISeeUploadeds() &&
                            <div className="unauthorizedFileUploadedsContainer">
                                <img id="unauthorizedImage"
                                    src={unauthorizedFile}
                                    width="150" />
                                <p>{translate("Yüklenen dosyaları görüntülemeye yetkiniz bulunmamaktadır")}</p>
                            </div>
                        }
                    </>





                    <div className="width100 thisIsCenter">
                        {isAddPhotoVisible()
                            &&
                            <div
                                className="imageAddContainer"
                                onClick={() => {
                                    document.getElementsByName("photo")[0].click();
                                }}
                            >
                                <img
                                    width="38"
                                    src={"/files/images/icons/coolicon.svg"}
                                    style={{ cursor: "pointer !important" }}
                                />
                            </div>
                        }
                    </div>
                    <div className="width100 thisIsCenter marginTop1"
                        onClick={() => uploadFile()}>
                        {fileUploadTaskFiles.length > 0 && showUploadPhoto
                            &&
                            <Button
                                circular={true}
                                style={{ marginTop: isMobile ? "2%" : "unset" }}
                                color="green"
                                size={"big"}
                            >
                                {translate("Yükle")}
                            </Button>
                        }
                    </div>

                    <Input
                        name="photo"
                        size="large"
                        style={{ ...styles.inputStyle, display: "none" }}
                        width="300px"
                        height="300px"
                        type="file"
                        multiple
                        accept=".jpeg, .jpg, .gif, .png, .xlsx, .pptx, .docx, .pdf"
                        onInput={handleChangeFile}
                        disabled={props.isDisabled(content)}
                    />
                    <Modal
                        onClose={() => setPhotoThumbnail(false)}
                        onOpen={() => setPhotoThumbnail(true)}
                        open={photoThumbnail}
                        centered={false}
                        size={"small"}
                    >
                        <Modal.Actions>
                            <Button
                                className={"dangerRedButton"}
                                onClick={() => setPhotoThumbnail(false)}
                            >{translate("Kapat")}</Button>
                        </Modal.Actions>
                        <Modal.Content
                            style={{ display: "flex", flexDirection: "column" as "column", alignItems: "center" }} image>
                            {fileUrlList.map((fileUri, index) => (
                                <div key={uuidv4()} style={{ position: "relative" }}>
                                    <img
                                        id={"taskUrlIma" + index}
                                        className="imgStyle"
                                        src={fileUri}
                                        onClick={() => {
                                            setPhotoThumbnail(true)
                                        }}
                                    />
                                </div>
                            ))}
                        </Modal.Content>
                    </Modal>
                </div>


            case 15: //serbest
                return <div
                    className={"renderContentEventContainer " + styles.contentSettingsContainer}
                    style={{ flexDirection: "column", gap: "18px", alignItems: "flex-start" }}
                >
                    {props.showContentInfo && !props.isQr
                        &&
                        <div className={"renderContentSettingsContainer renderContentContainerFlex"}>
                            <div className={"renderContentSettingHeaderContainer " + styles.contentSettingHeader}
                                style={{ ...styles.bold700, width: "30%" }}>
                                {translate("Görev Açıklama")}
                            </div>
                            <div
                                className={"renderContentDescContainer"}
                                style={{ ...styles.contentDescriptionStyle, wordBreak: "break-all" }}>
                                {task.description}
                            </div>
                        </div>
                    }
                    {!props.isQr
                        &&
                        <Form className={"freeTaskTextareaContent"} style={{ width: "100%" }}
                            id="freeTaskTextareaContent">
                            <TextArea
                                className={"textareaStyle"}
                                rows={5}
                                id="textareaDesc"
                                disabled={!canComplate && content.status != undefined && content?.status?.isCompleted}
                                placeholder={translate("Açıklama")}
                                style={{ ...styles.inputStyle, ...styles.transparent }}
                                value={completationText}
                                onChange={(e) => {

                                    if (content.status != undefined && content?.status?.isCompleted) {
                                        return;
                                    }
                                    if (!canComplate && e.target.value != "" && e.target.value != null && e.target.value != undefined) {
                                        setCanComplate(true);
                                    }
                                    setCompletationText(e.target.value);
                                    try {

                                        let json = JSON.parse(moduleHistory.completionJson);
                                        json.text = e.target.value;
                                        setModuleHistory({ ...moduleHistory, completionJson: JSON.stringify(json) });
                                    } catch (e) {

                                    }
                                }}
                            />
                        </Form>
                    }
                </div>
        }
    }

    const setShowQrReader = () => {
        setIsQrReaderShown(true);
    }

    const isCompleteButtonShown = () => {
        return canComplate
            && content != undefined
            && content.status != undefined
            && content?.status?.isCompleted == false
            && !showUploadPhoto
            && !task.showQr
            && !props.isQr;
    }

    const checkModuleHistory = () => {

        if (moduleHistory != undefined) {
            if (content != undefined
                && content.data != undefined
                && content.data.contentTaskResponse != undefined
                && content.data.contentTaskResponse.contentTask != undefined
                && moduleHistory.completionJson != ""
                && moduleHistory.completionJson != undefined
                && moduleHistory.completionJson != null) {
                let json = JSON.parse(moduleHistory.completionJson);
                if (content.data.contentTaskResponse.contentTask.type == 1) {
                    if (json.fileUrlList != undefined && json.fileUrlList.length > 0) {
                        setFileUrlList(json.fileUrlList);

                        setCanComplate(true);
                    } else {
                        ////debugger;
                        setCanComplate(false);
                    }
                    if (json.text != undefined) {
                        setCompletationText(json.text);
                    }
                }

                if (content.data.contentTaskResponse.contentTask.type == 8) {
                    if (json.fileUrlList != undefined && json.fileUrlList.length > 0) {
                        setFileUploadTaskUrlList(json.fileUrlList);

                        setCanComplate(true);
                    } else {
                        ////debugger;
                        setCanComplate(false);
                    }
                    if (json.text != undefined) {
                        setCompletationText(json.text);
                    }
                }
                if (content.data.contentTaskResponse.contentTask.type == 15 && !content.data.contentTaskResponse.contentTask.taskJson.isQr) {
                    if (json.text != ""
                        && json.text != undefined
                        && json.text != null
                    ) {
                        setCompletationText(json.text);
                    } else {
                        ////debugger;
                        setCanComplate(false);
                    }
                }
            }
        }
    }

    //useEffect(() => {
    //    console.log("canComplate", canComplate);
    //}, [canComplate]);

    useEffect(() => {
        ////console.log(isStatusTakenBack);
    }, [isStatusTakenBack]);

    useEffect(() => {
        ////console.log(props);
        try {
            if (props.content.status != undefined) {
                setCoutdownFinished(props.content != undefined
                    && props.content?.status != undefined && props.content?.status?.isCompleted == true);
            }
            let task = props.content.data.contentTaskResponse.contentTask;
            if (task == undefined)
                return;
            task.taskJsonModel = JSON.parse(task.taskJson);
            ////debugger;
            setCanComplate(task.type != 1 && task.type != 8 && task.taskJsonModel.showComplateButton);

            if (task.type == 8)
                setWhoCanComplate(task.taskJsonModel.whoCanSeeUploadeds);

            let activity = task.type == 4 ? props.content.data.contentTaskResponse.activity : undefined;
            // //console.log("vshdjkzfmdaslnçfvsdlk", activity)

            setActivity(activity);

            setTask(task);
            setUser(props.user);

            if (task.type == 2 && task.time != "" && task.time != "0" && task.time != undefined && task.time != null) {
                setMinutes(task.time);
                setSeconds(0);
                ////debugger;
                setCanComplate(false);
            }

            props.setContentLoading(false);
        } catch (e) {

        }

    }, [props.content]);

    useEffect(() => {
        if (task != undefined && task.type == 15 && task.taskJsonModel.isQr && task.showQr != true) {

            setTask({ ...task, showQr: true });
        }
    }, [task]);

    useEffect(() => {
        ////console.log("canComplate euf: ", canComplate);
        checkModuleHistory();
    }, [canComplate]);

    useEffect(() => {
        /*console.log("fileUploadTaskUrlList: ", fileUploadTaskUrlList);*/
    }, [fileUploadTaskUrlList]);

    useEffect(() => {
        ////console.log("isQrReaderShown: ", isQrReaderShown);
    }, [isQrReaderShown]);

    useEffect(() => {
        //console.log("moduleHistory: ", moduleHistory);
        //if (isSavedComplatetionJson)
        //    return;
        checkModuleHistory();
    }, [moduleHistory]);

    //useEffect(() => {
    //    //console.log("props.history: ", props.history);
    //    setModuleHistory(props.history);
    //}, [props.history]);

    // useEffect(() => {
    //     ////console.log("canComplate:", canComplate);
    //     ////console.log("content:", content);
    //     ////console.log("showUploadPhoto:", showUploadPhoto);
    //     ////console.log("task.showQr:", task.showQr);
    //     ////console.log("props.isQr:", props.isQr);
    // }, [canComplate, content, showUploadPhoto, task?.showQr, props.isQr]);

    useEffect(() => {
        //console.log("content rct:", content.status);
    }, [content]);

    useEffect(() => {
        //console.log("rct uef");
        //Appointment of Scroll at the top when the content changes
        scrollToTop();
        if (props.content.data.contentTaskResponse != undefined && props.content.data.contentTaskResponse.contentTask != undefined && props.content.data.contentTaskResponse.contentTask.type == 4) {
            setActivity(props.content.data.contentTaskResponse.activity);
        }
    }, []);

    return (
        <div className={"renderContentTaskContainer"} style={styles.container} onClick={() => logMe()}>
            {task != undefined && props.isLocked != true
                &&
                <>
                    {!props.isQr
                        &&
                        renderTask()
                    }
                    <Grid className="marginTop0"
                        columns={content.data.contentTaskResponse.contentTask.type == 4 ? 2 : 1}>
                        <Grid.Row className={"renderContentTasktBottomButtonsContainer"}>
                            {content.data.contentTaskResponse.contentTask.type == 4
                                &&
                                <Grid.Column className="thisIsCenter">
                                    <Button
                                        circular={true}
                                        loading={isPrepareIcsFile}
                                        className={isMobile ? "w100  callenderButton" : "callenderButton"}
                                        color={"blue"}
                                        onClick={(e) => {
                                            downloadIcs(activity);
                                        }}
                                    >
                                        {isMobile ? translate("Takvim uzantısını mail gönder") : translate("Takvim Uzantısı İndir")}
                                    </Button>
                                </Grid.Column>
                            }
                            <Grid.Column className="thisIsCenter">
                                {content?.status != undefined && content?.status?.isCompleted == false && !showUploadPhoto && !task.showQr && !props.isQr
                                    &&
                                    <Button
                                        circular={true}
                                        color={"green"}
                                        style={{ marginTop: isMobile ? "2%" : "0" }}
                                        disabled={!canComplate}
                                        onClick={(e) => {
                                            complateContent(task.type == 1
                                                ?
                                                { fileUrlList: fileUrlList, text: completationText }
                                                : (task.type == 8) ? {
                                                    fileUrlList: fileUploadTaskUrlList,
                                                    text: completationText
                                                } :
                                                    (task.type == 15)
                                                        ?
                                                        { text: completationText }
                                                        :
                                                        {}
                                            );
                                            props.setIsOpenTaskContentForMobile(true);
                                        }}
                                    >
                                        {translate("Tamamla")}
                                    </Button>
                                }
                                {canComplate && true && content?.status != undefined && content?.status?.isCompleted == false && task.showQr && !props.isQr && !isQrReaderShown
                                    &&
                                    <>
                                        <p>{translate("Bu içeriği tamamlamak için sizinle paylaşılan QR Kodu, QR Kod okuyucu veya kameranızla okumanız gerekir.")}</p>
                                        <Button
                                            style={{ width: "50%" }}
                                            circular
                                            color="green"
                                            onClick={setShowQrReader}
                                            disabled={!canComplate}
                                        >
                                            {translate("QR Oku")}
                                        </Button>
                                    </>
                                }
                                {isQrReaderShown
                                    &&
                                    <QrReader
                                        constraints={{ facingMode: 'environment' }}
                                        onResult={(result: any, error: any) => {
                                            if (result != undefined
                                                && result.text.indexOf("https") != -1
                                                && result.text.indexOf(props.moduleId) != -1
                                            ) {
                                                setIsQrReaderShown(false);
                                                complateContent(moduleHistory != undefined && moduleHistory.completionJson != undefined ? JSON.parse(moduleHistory.completionJson) : undefined)
                                            }
                                        }}
                                        containerStyle={{ width: '300px' }}
                                    />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            }

            <Modal
                size={"mini"}
                open={showRenameFileModal}

            >
                <Modal.Header>Dosyayı İsimlendirin</Modal.Header>
                <Modal.Content>
                    <Input
                        type="text"
                        fluid
                        value={giveMeFileNameWithoutPath(renameFileText.fileName)}
                        onChange={(e) => {
                            setRenameFileText({ ...renameFileText, fileName: e.target.value })
                        }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setShowRenameFileModal(false)}>
                        {translate("Hayır")}
                    </Button>
                    <Button positive onClick={() => changeFileName()}>
                        {translate("Evet")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default RenderContentTask;