import * as React from 'react';
import { useState, useEffect } from 'react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Checkbox, Dropdown, Form, Header, Icon, Input, Loader, Modal, Grid, Transition } from 'semantic-ui-react';
import { checkFieldsIsFilled, digitallicaConfirm, renderByItemType } from '../../../helper/HelperFunctions';
import { IScheduledJob, IDropdown, IRole, INotificationReceiver } from '../../../interfaces/interfaces';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import EditorComponent from '../../../components/EditorComponent';
import { useParams, useNavigate } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
import QuillEditor from '../../../components/Quill/QuillEditor';

const AddNotification = (props) => {

    var { translate } = TranslateHelper();
    let { id } = useParams();
    const navigate = useNavigate();
    const _ = require('lodash');
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [editorContent, setEditorContent] = useState("");
    const [selectedTo, setSelectedTo] = useState("");
    const [selectedCc, setSelectedCc] = useState("");

    const [notificationTypeId, setNotificationTypeId] = useState<any>();
    const [showPreview, setShowPreview] = useState(false);
    const [isFormOk, setIsFormOk] = useState(false);
    const [loader, setLoader] = useState(true);
    const [showFreq, setShowFreq] = useState(false);
    const [scheduledJobId] = useState<any>(id);
    const [allNotiTypes, setAllNotiTypes] = useState<Array<any>>([]);
    const [allFreqs, setAllFreqs] = useState<Array<any>>([]);
    const [dropdownFreqs, setDropdownFreqs] = useState<Array<IDropdown>>([]);
    const [dropdownRoles, setDropdownRoles] = useState<Array<IDropdown>>([]);
    const [dropdownNotiTypes, setDropdownNotiTypes] = useState<Array<IDropdown>>([]);
    const [dropdownModules, setDropdownModules] = useState<Array<IDropdown>>([]);
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [moduleIds, setModules] = useState<Array<any>>([]);
    const [selectedCcs, setSelectedCcs] = useState<Array<string>>([]);
    const [selectedModules, setSelectedModules] = useState<Array<string>>([]);
    const [dontAddUser, setDontAddUser] = useState<boolean>(false);
    const [scheduledJob, setScheduledJob] = useState<IScheduledJob>({
        tenantId: tenantId,
        notification: {
            body: "",
            subject: "",
            tenantId: tenantId,
            notificationReceivers: [],
            notificationTypeId: notificationTypeId,
            sectionContentId: undefined,
            hrMailId: null,
            fromMail: null,
            fromName: null
        },
        frequency: {
            type: "",
            value: "",
        },
        isActive: true,
        isInformEnabled: false
    });

    const editorModules = {
        toolbar: {
            container: "#toolbar"
        }
    };

    const [isUpdate, setIsUpdate] = useState(false);

    const freqOptions = [
        /*{ key: 'hour', text: translate('saat'), value: 'hour' },*/
        { key: 'day', text: translate('gün'), value: 'day' },
        { key: 'month', text: translate('ay'), value: 'month' },
    ]
    const [freqValue, setFreqValue] = useState<string | number>("");
    const [selectedFrequencyType, setSelectedFrequencyType] = useState("day");

    let styles = {
        scheduledJobAddSection: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        },

        inputStyle: {
            width: "100%"
        },
        mailBody: {
            backgroundColor: 'white',
            boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            padding: "1%",
            margin: "1%",
            borderRadius: "15px",
            display: "flex",
            flexDirection: 'column' as 'column',
            gap: "2em"
        }
    }

    const getRoles = () => {
        NetworkRequest('/api/UserRole/GetResponsibleRoles').then((response) => {
            if (response.data.resultCode == 0) {
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (role) => {
                    list.push({
                        key: role.roleId,
                        value: role.roleId,
                        text: translate(role.displayName)
                    });
                });
                setDropdownRoles(list);
                setRoles(response.data.detail);
            }
        });
    }

    const getAllNotificationTypes = () => {
        NetworkRequest('/api/ScheduledJobs/GetNotificationTypes').then((response) => {
            if (response.data.resultCode == 0) {
                setAllNotiTypes(response.data.detail);
            }
        });
    }

    const getNotificationTypes = () => {
        NetworkRequest('/api/ScheduledJobs/GetMailNotificationTypes').then((response) => {
            if (response.data.resultCode == 0) {
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (doc) => {
                    list.push({
                        key: doc.notificationTypeId,
                        value: doc.notificationTypeId,
                        text: translate(doc.displayName)
                    });
                });
                setDropdownNotiTypes(list);
            }
        });
    }

    const checkScheduledJobId = (scheduledJobId: string) => {
        scheduledJobId !== undefined ? getByScheduledJobId(scheduledJobId) : setLoader(false);
    }

    const getByScheduledJobId = (scheduledJobId) => {
        let scheduledJobData = {
            id: scheduledJobId
        }

        NetworkRequest("/api/ScheduledJobs/GetById", scheduledJobData).then((response) => {
            if (response.data.resultCode === 0) {

                let result = response.data.detail;

                setDontAddUser(result.dontAddUser == undefined ? true : result.dontAddUser);

                let receiversList = result.notification.notificationReceivers;

                if (receiversList != null) {
                    let toRole = _.find(receiversList, (item) => {
                        return item.receiverType == "TO"
                    });
                    setSelectedTo(toRole.value);

                    let ccRoles = receiversList.filter(r => r.receiverType == "CC");

                    ccRoles.map((cc) => {
                        setSelectedCcs(oldArray => [...oldArray, cc.value]);
                    })

                }
                setScheduledJob({
                    tenantId: result.tenantId,
                    notification: {
                        body: result.notification.body,
                        subject: result.notification.subject,
                        tenantId: result.tenantId,
                        notificationReceivers: result.notification.notificationReceivers,
                        notificationTypeId: result.notification.notificationTypeId,
                        fromMail: result.notification.fromMail,
                        fromName: result.notification.fromName,
                    },
                    frequency: {
                        type: "",
                        value: ""
                    },
                    title: result.title,
                    isActive: result.isActive,
                    isInformEnabled: result.isInformEnabled,
                    scheduledJobId: result.scheduledJobId,
                    moduleIds: result.moduleIds
                });
                setNotificationTypeId(result.notification.notificationTypeId);
                if (result.moduleIds != undefined && result.moduleIds.length > 0) {
                    setSelectedModules(result.moduleIds);
                }
                setEditorContent(result.notification.body);
                let notiType = _.find(allNotiTypes, (type) => {
                    return type.notificationTypeId == result.notification.notificationTypeId;
                });
                if (notiType.type == "RememberenceMail") {
                    setShowFreq(true);
                    setTimeout(() => {
                        setSelectedFrequencyType(result.frequency.type);
                        setFreqValue(result.frequency.value)
                    }, 500)
                }
                setLoader(false);
            }
        });
    }

    const addScheduledJob = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            setIsUpdate(true);
            let scheduledJobData = _.cloneDeep(scheduledJob);
            if (editorContent == "" || editorContent == null || editorContent == undefined) {
                toast(translate("Mail içeriği girdiğinizden emin olun."), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setIsUpdate(false);
                return;
            }
            if (notificationTypeId == undefined) {
                toast(translate("Bildirim Tipi seçtiğinizden emin olun."), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setIsUpdate(false);
                return;
            }
            scheduledJobData.notification.body = editorContent;
            scheduledJobData.notification.notificationTypeId = notificationTypeId;
            scheduledJobData.moduleIds = selectedModules;
            scheduledJobData.dontAddUser = !showFreq

            //scheduledJob.frequency = {
            //    type: selectedFrequencyType,
            //    value: freqValue
            //}


            if (scheduledJobData?.moduleIds?.length == 0 && showFreq) {
                toast(translate("Modül seçimi yapmak zorunludur."), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setIsUpdate(false);
                return;
            }


            NetworkRequest("/api/ScheduledJobs/Add", scheduledJobData).then((response) => {

                if (response.data.resultCode === 0) {
                    setIsUpdate(false);
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                    setTimeout(() => {
                        navigate("/scheduledJobList");
                    }, 1000);
                } else {
                    toast(translate(response.data.detail), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
                    setIsUpdate(false);
                }
            });
        }, () => { setIsUpdate(false); toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    };

    const updateScheduledJob = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            setIsUpdate(true);
            let scheduledJobData = _.cloneDeep(scheduledJob);
            if (editorContent == "" || editorContent == null || editorContent == undefined) {
                toast(translate("Mail içeriği girdiğinizden emin olun."), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
                return;
            }
            if (notificationTypeId == undefined) {
                toast(translate("Bildirim Tipi seçtiğinizden emin olun."), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
                return;
            }
            scheduledJobData.notification.body = editorContent;
            scheduledJobData.notification.notificationTypeId = notificationTypeId;
            scheduledJobData.moduleIds = selectedModules;

            scheduledJobData.frequency = {
                type: selectedFrequencyType,
                value: freqValue
            }

            toast(translate("Lütfen bekleyin, bildirim güncelleniyor..."), {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                autoClose: false,
                isLoading: true
            });
            NetworkRequest("/api/ScheduledJobs/UpdateContentScheduledJob", scheduledJobData).then((response) => {
                ////
                if (response.data.resultCode === 0) {
                    setIsUpdate(false);
                    toast.dismiss();
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                    setTimeout(() => {
                        navigate("/scheduledJobList");
                    }, 1000);
                }
            });
        }, () => { setIsUpdate(false); toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const handleChange = (e) => {
        if (scheduledJob != undefined) {
            setScheduledJob({ ...scheduledJob, [e.target.name]: e.target.value });
        }
    }

    const getNotiTypeName = (id) => {
        let type = _.find(allNotiTypes, (x) => {
            return x.notificationTypeId == id;
        })
        if (type != undefined) {
            return type.displayName;
        }
        return '';
    }

    const selectReceiver = (e, data, type, isMultiple?: boolean) => {
        let clone = _.cloneDeep(scheduledJob.notification);
        if (clone.notificationReceivers == undefined) {
            clone.notificationReceivers = [];
        }
        if (typeof (data.value) == "string") {
            let model: INotificationReceiver = {
                receiverType: type,
                value: data.value
            };
            if (clone.notificationReceivers.length == 0) {
                clone.notificationReceivers.push(model);
            } else {
                let index = clone.notificationReceivers.indexOf(x => x.receiverType == type);
                if (index == -1) {
                    clone.notificationReceivers.push(model);
                } else {
                    if (type == "TO") {
                        clone.notificationReceivers[index] = model;
                    }
                }
            }
            if (type == 'TO') {
                setSelectedTo(data.value);
            }
        } else {
            let models: Array<INotificationReceiver> = [];
            _.each(data.value, (doc) => {
                models.push({
                    receiverType: type,
                    value: doc
                });
            });
            //TO olmayanları kaldırıyor
            clone.notificationReceivers = _.remove(clone.notificationReceivers, (doc) => {
                return doc.receiverType == "TO";
            });
            clone.notificationReceivers.push(...models);
            setSelectedCcs(data.value);
        }
        setScheduledJob({ ...scheduledJob, notification: clone, frequency: scheduledJob.frequency });
    }

    const selectModule = (e, data) => {
        let arr: Array<string> = [];
        _.each(data.value, (doc) => {
            arr.push(doc);
        })
        setSelectedModules(arr);
    }

    const selectFreq = (e, data) => {
        setSelectedFrequencyType(data.value);

        let freq = _.find(freqOptions, (fr) => {
            return fr.key == data.value;
        });

        let clone = _.cloneDeep(scheduledJob);

        clone.frequency = {
            type: freq.key,
            value: freqValue.toString()
        };

        setScheduledJob(clone);

    }

    const freqValueHandler = (valueData) => {

        var value = parseInt(valueData);
        if (value > 99) {
            toast(translate("Uyarı. Frekans değeri 99'dan büyük olamaz"), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
            value = 99;
        }
        else if (value < 1) {
            toast(translate("Uyarı. Frekans değeri 1'den küçük olamaz"), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000 });
            value = 1;
        }

        let clone = _.cloneDeep(scheduledJob);

        clone.frequency = {
            type: selectedFrequencyType,
            value: value.toString()
        };
        setFreqValue(value);


        setScheduledJob(clone);
    }

    const getReceiverName = (id) => {
        let role = _.find(dropdownRoles, (doc) => {
            return doc.value == id;
        })
        if (role != undefined) {
            return role.text;
        }

        return '';
    }

    const getReceiverNames = (ids) => {
        var returnStr = "";
        if (ids.length > 0) {
            ids.map((id) => {
                returnStr += getReceiverName(id) + ", ";
            });

            return returnStr.slice(0, returnStr.length - 2);
        } else {
            return ""
        }

    }

    const setSubject = (value) => {
        let clone = _.cloneDeep(scheduledJob.notification);
        clone.subject = value;
        setScheduledJob({ ...scheduledJob, notification: clone, frequency: scheduledJob.frequency });
    }

    const setFromName = (value) => {
        let clone = _.cloneDeep(scheduledJob.notification);
        clone.fromName = value;
        setScheduledJob({ ...scheduledJob, notification: clone });
    }

    const setFromMail = (value) => {
        let clone = _.cloneDeep(scheduledJob.notification);
        clone.fromMail = value;
        setScheduledJob({ ...scheduledJob, notification: clone });
    }

    const getModules = () => {
        NetworkRequest("/api/ScheduledJobs/GetNotiModules", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (module) => {
                    list.push({
                        key: module.id,
                        value: module.id,
                        text: module.title
                    });
                });
                setDropdownModules(list);
                setModules(response.data.detail);
            }
        });
    }

    const getFrequencies = () => {
        NetworkRequest("/api/Frequencies/GetList").then((response) => {
            ////
            if (response.data.resultCode == 0) {
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (freq) => {
                    list.push({
                        key: freq.frequencyId,
                        value: freq.frequencyId,
                        text: freq.title
                    });
                });
                setDropdownFreqs(list);
                setAllFreqs(response.data.detail);
                let freq = _.find(response.data.detail, (fr) => {
                    return fr.isDefault;
                });
                if (freq != undefined) {
                    let clone = _.cloneDeep(scheduledJob);
                    //TODO:
                    //clone.frequency = {
                    //    type: selectedFrequencyType,
                    //    value: value,
                    //    FrequencyID: null
                    //};
                    //clone.frequency = { frequencyId: freq.frequencyId, repeatCount: freq.repeatCount };
                    /*setScheduledJob(clone);*/
                }
            }
        });
    }

    const selectNotiType = (e, data) => {
        setNotificationTypeId(data.value);
        let clone = _.cloneDeep(scheduledJob);
        clone.notification.notificationTypeId = data.value;
        setScheduledJob(clone);
        let notiType = _.find(allNotiTypes, (type) => {
            return type.notificationTypeId == data.value;
        });
        if (notiType.type == "RememberenceMail") {
            setShowFreq(true);
        } else {
            setShowFreq(false);
        }
    }

    const isAssignmentMail = (id) => {
        return _.some(allNotiTypes, (type) => {
            return type.type == "AssignmentMail" && type.notificationTypeId == id;
        });
    }

    useEffect(() => {
        ////console.log("notificationTypeId: ", notificationTypeId);
    }, [notificationTypeId]);

    useEffect(() => {
        let bise = renderByItemType(editorContent);
        ////console.log("renderByItemType: ", bise);
    }, [editorContent]);

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                scheduledJob.title,
                scheduledJob.notification != undefined && scheduledJob.notification.subject,
                selectedTo,
                editorContent,
                scheduledJob.notification != undefined && scheduledJob.notification.notificationTypeId
            )
        );
        ////console.log(scheduledJob);
    }, [scheduledJob, editorContent]);

    useEffect(() => {
        getNotificationTypes();
        getAllNotificationTypes();
        getRoles();
        getModules();
        getFrequencies();
    }, []);

    useEffect(() => {
        if (roles != undefined && roles.length > 0 && allNotiTypes.length > 0) {
            checkScheduledJobId(scheduledJobId);
        }
    }, [roles, allNotiTypes]);

    useEffect(() => {
        ////console.log("selectedTo: ", selectedTo);
        if (selectedTo != "" && scheduledJob != undefined && scheduledJob.notification != undefined) {
            let control = _.some(scheduledJob.notification.notificationReceivers, (doc) => {
                return doc.receiverType == "TO" && doc.value.indexOf('@') != -1;
            });
            if (control == true) {
                scheduledJob.notification.notificationReceivers = _.remove(scheduledJob.notification.notificationReceivers, (doc) => {
                    return doc.receiverType == "CC";
                });
                let role = _.find(props.dropdownRoles, (doc) => {
                    return doc.value == selectedTo;
                });
                scheduledJob.notification.notificationReceivers.push({
                    receiverType: "TO",
                    value: selectedTo
                });
            }
        }
    }, [selectedTo, scheduledJob]);

    useEffect(() => {
        ////console.log("selectedCcs: ", selectedCcs);
        if (selectedCcs.length > 0 && scheduledJob != undefined && scheduledJob.notification != undefined) {
            let control = _.some(scheduledJob.notification.notificationReceivers, (doc) => {
                return doc.receiverType == "CC" && doc.value.indexOf('@') != -1;
            });
            if (control == true) {
                scheduledJob.notification.notificationReceivers = _.remove(scheduledJob.notification.notificationReceivers, (doc) => {
                    return doc.receiverType == "TO";
                });
                _.each(selectedCcs, (selectedCc) => {
                    scheduledJob.notification.notificationReceivers.push({
                        receiverType: "CC",
                        value: selectedCc
                    });
                });
            }
        }
    }, [selectedCcs, scheduledJob]);


    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={styles.scheduledJobAddSection}>
                <Transition visible={loader} animation='scale' duration={500}>
                    <div>
                        <Loader size="huge" active inline='centered' />
                    </div>
                </Transition>

                <Transition visible={!loader} animation='scale' duration={500}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Planlı Bildirim Adı: (*)")}</Header>
                                    <Input
                                        value={scheduledJob?.title || ""}
                                        name="title"
                                        required="required"
                                        style={styles.inputStyle}
                                        className="greyInput"
                                        placeholder={translate("Bildirim Adı")}
                                        onChange={handleChange}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Header as='h3'>{translate("E-Posta Konusu")}: (*)</Header>
                                    <Input
                                        className="greyInput"
                                        value={scheduledJob?.notification.subject || ""}
                                        name="mailSubject"
                                        required="required"
                                        style={styles.inputStyle}
                                        placeholder={translate("E-Posta Konusu")}
                                        onChange={(e) => { setSubject(e.target.value) }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {!dontAddUser &&
                                <Grid.Row>
                                    <Grid.Column>
                                        <Header as='h3'>{translate("Kime: (*)")}</Header>
                                        <Dropdown
                                            className="greyInput"
                                            fluid
                                            selection
                                            value={selectedTo}
                                            name="to"
                                            required="required"
                                            clearable
                                            options={dropdownRoles}
                                            placeholder={translate("Kime")}
                                            onChange={(e, data) => { selectReceiver(e, data, 'TO'); }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Header as='h3'>{translate("Bilgi:")}</Header>
                                        <Dropdown
                                            className="greyInput"
                                            fluid
                                            selection
                                            value={selectedCcs}
                                            name="ccs"
                                            multiple
                                            required="required"
                                            clearable
                                            options={dropdownRoles}
                                            placeholder={translate("CC")}
                                            onChange={(e, data) => { selectReceiver(e, data, 'CC', true); }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }

                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Aksiyon: (*)")}</Header>
                                    <Dropdown
                                        className="greyInput"
                                        fluid
                                        selection
                                        value={notificationTypeId}
                                        name="notificationTypeId"
                                        clearable
                                        options={dropdownNotiTypes}
                                        placeholder={translate("Aksiyon")}
                                        onChange={(e, data) => { selectNotiType(e, data); }}
                                    />
                                </Grid.Column>
                                {(isAssignmentMail(notificationTypeId) || showFreq)
                                    &&
                                    <Grid.Column>
                                        <Header as='h3'>{translate("Modül Listesi:")}</Header>
                                        <Dropdown
                                            className="greyInput"
                                            fluid
                                            selection
                                            value={selectedModules}
                                            name="moduleIds"
                                            search
                                            searchInput={{ type: 'string' }}
                                            multiple
                                            clearable
                                            options={dropdownModules}
                                            placeholder={translate("Filtrele")}
                                            onChange={(e, data) => { selectModule(e, data); }}
                                        />
                                    </Grid.Column>
                                }
                                {showFreq
                                    &&
                                    //<Grid.Column style={{ marginTop: "1.5%" }}>
                                    //    <Header as='h3'>{translate("Frekans:")}</Header>
                                    //    <Dropdown
                                    //        className="greyInput"
                                    //        fluid
                                    //        selection
                                    //        value={selectedFrequencyId}
                                    //        name="frequencies"
                                    //        options={dropdownFreqs}
                                    //        placeholder={translate("Frekans")}
                                    //        onChange={(e, data) => { selectFreq(e, data); }}
                                    //    />
                                    //</Grid.Column>

                                    <Grid.Column style={{ marginTop: "1.5%" }}>
                                        <Header as='h3'>{translate("Frekans:")}</Header>
                                        <Input
                                            onChange={(e) => {
                                                freqValueHandler(e.target.value)
                                            }}
                                            value={freqValue || 1}
                                            max="99"
                                            maxLength="2"
                                            type="number"
                                            className="greyInput"
                                            label={
                                                <Dropdown
                                                    onChange={(e, data) => { selectFreq(e, data); }}
                                                    className="greyInput"
                                                    value={selectedFrequencyType}
                                                    options={freqOptions}
                                                />
                                            }
                                            labelPosition='right'
                                            placeholder={translate("Frekans")}
                                        />

                                        <span> {translate("aralıklar ile çalışır.")}</span>
                                    </Grid.Column>
                                }

                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Gönderici E-mail:")}</Header>
                                    <Input
                                        className="greyInput"
                                        value={scheduledJob?.notification.fromMail || ""}
                                        name="mailSubject"
                                        required="required"
                                        style={styles.inputStyle}
                                        placeholder={translate("Gönderici E-mail")}
                                        onChange={(e) => { setFromMail(e.target.value) }}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <Header as='h3'>{translate("Gönderici İsmi:")}</Header>
                                    <Input
                                        className="greyInput"
                                        value={scheduledJob?.notification.fromName || ""}
                                        name="mailSubject"
                                        required="required"
                                        style={styles.inputStyle}
                                        placeholder={translate("Gönderici İsmi")}
                                        onChange={(e) => { setFromName(e.target.value) }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("İçerik: (*)")}</Header>
                                    <EditorComponent
                                        setEditorContent={setEditorContent}
                                        value={editorContent}
                                        showVairant={true}
                                        actionType={dropdownNotiTypes.filter(x => x.key == notificationTypeId)}
                                    />

                                    {/*<QuillEditor*/}
                                    {/*    value={editorContent}*/}
                                    {/*    setValue={setEditorContent}*/}
                                    {/*    showVairant={true}*/}
                                    {/*/>*/}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Modal
                                        centered={false}
                                        open={showPreview}
                                        onClose={() => setShowPreview(false)}
                                        onOpen={() => setShowPreview(true)}
                                        trigger={<Button className={"roundedButton"} color={"orange"} style={{ float: 'right' }}>{translate("Önizleme")}</Button>}
                                    >
                                        <Modal.Content>
                                            <Modal.Description>
                                                <div >
                                                    <p><b>{translate("Tipi:")}</b> {getNotiTypeName(scheduledJob.notification.notificationTypeId)}</p>
                                                    <p><b>{translate("Kime:")}</b> {getReceiverName(selectedTo)}</p>
                                                    <p><b>{translate("Bilgi (CC):")}</b> {getReceiverNames(selectedCcs)}</p>
                                                    <Header>{translate("Başlık:")} {scheduledJob?.title}</Header>
                                                    {/*{editorContent.replace(/<[^>]*>?/gm, '')}*/}
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: editorContent
                                                        }}></div>
                                                </div>
                                            </Modal.Description>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button className={"roundedButton"} color={"red"} onClick={() => setShowPreview(false)}>{translate("Kapat")}</Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {scheduledJob.scheduledJobId !== undefined ?

                                        <Button
                                            loading={isUpdate}
                                            color={"blue"}
                                            className={"roundedButton"}
                                            onClick={updateScheduledJob}
                                            disabled={!isFormOk}
                                            style={{ float: 'right' }}
                                        >
                                            {translate("Bildirim Güncelle")}
                                        </Button>
                                        :
                                        <Button
                                            loading={isUpdate}
                                            className={"roundedButton"}
                                            color='green'
                                            onClick={addScheduledJob}
                                            disabled={!isFormOk}
                                            style={{ float: 'right' }}
                                        >
                                            {translate("Bildirim Ekle")}
                                        </Button>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Transition>
            </div>
        </div>

    )
}

export default AddNotification;