import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { digitallicaConfirm, logoutApi } from '../helper/HelperFunctions';
import { DataHandler } from '../helper/DataHelper';
import { useNavigate } from 'react-router-dom';

const IdleTimeOutHandler = (props) => {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [isLogout, setLogout] = useState(false)
    let timer;
    const events = ['click', 'scroll', 'load', 'keydown']
    const [defaultTime] = useState(3600000);
    const [expiresTime, setExpireTime] = useState<number>(10);

    let time = 10;

    const eventHandler = (eventType) => {
        ////console.log(eventType)
        try {
            window.localStorage.setItem('lastInteractionTime', moment().format())
        } catch (e) {

        }
        if (timer) {
            props.onActive();
            startTimer();
        }
    };

    const startTimer = () => {
        if (DataHandler.getUserDataWithKey("loginTimestamp") == undefined) {
            return;
        }
        timer = setTimeout(() => {
            props.onIdle();
            setShowModal(true);
        }, moment(new Date(Number(DataHandler.getUserDataWithKey("loginTimestamp")))).add(60, 'm').diff(moment()));
    }

    const addEvents = () => {
        events.forEach(eventName => {
            window.addEventListener(eventName, eventHandler);
        })
        startTimer();
    }

    const removeEvents = () => {
        events.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler);
        })
    };

    const handleContinueSession = () => {
        setShowModal(false);
        setLogout(false);
        time = 10;
        setExpireTime(10);
        DataHandler.resetLoginExpireTime();
        startTimer();
    }

    const handleLogout = () => {
        logoutApi();
        removeEvents();
        clearTimeout(timer);
        setLogout(true);
        props.onLogout();
        setShowModal(false);
        DataHandler.logOutUser();
        props.history.push('/homeLogin');
        navigate("/homeLogin");
    }

    useEffect(() => {
        addEvents();
        ////console.log("moment diff", moment() > moment("2022-01-07").add(5, "days"));
        return (() => {
            removeEvents();
        })
    }, []);

    useEffect(() => {
        let timeStamp = DataHandler.getUserDataWithKey("loginTimestamp");
        if (timeStamp != undefined && moment(new Date(Number(timeStamp))).add(70, 'm').diff(moment()) < 0) {
            handleLogout();
            return;
        }

        if (showModal) {
            digitallicaConfirm("Oturumunuzun zamanı doldu. Oturuma devam etmek istiyor musunuz?", handleContinueSession, handleLogout, { seconds: 60, rejectOnTimeEnd: true, onTimeEndMessage: "Çıkış Yapılıyor" }, "Devam Et", "Çıkış Yap");
        }
    }, [showModal])

    return (
        <Modal
            size={"mini"}
            open={false}
        >
            <Modal.Header>Oturum süresi doldu</Modal.Header>
            <Modal.Content>
                <p>Oturumunuzun zamanı doldu. Devam etmek istiyor musunuz ?</p>
                <p>{expiresTime} saniye sonra oturumunuz sonlandıralacak</p>
            </Modal.Content>
            <Modal.Actions>
                <Button className={"dangerRedButton"} onClick={handleLogout}>
                    Çıkış Yap
                </Button>
                <Button className={"positiveGreenButton"} onClick={handleContinueSession}>
                    Oturuma Devam Et
                </Button>
            </Modal.Actions>
        </Modal>
    )

}

export default IdleTimeOutHandler;