import React, {useState, useEffect, memo} from 'react';
import TranslateHelper from "../../../helper/TranslateHelper";
import UserDashboardCourseCard from "./UserDashboardCourseCard";
import {Button, Icon, Pagination} from "semantic-ui-react";
import {v4 as uuidv4} from 'uuid';
import NotFoundModuleMessage from "./NotFoundModuleMessage";
import SelectedFilterElements from "./SelectedFilterElements";
import {motion} from 'framer-motion';
import {auto} from "framer-motion/types/render/dom/value-types/type-auto";
import {useNavigate, useParams, useLocation, useSearchParams} from "react-router-dom";
import {isMobile} from "react-device-detect";

interface RenderModules {
    modules: any,
    title: string,
    category: number | string
    filterData?: any | undefined
    setFilterData?: any | undefined
    getAssignedCoursesByFilter?: any
    getAssignedCourses?: any
    isLoader?: boolean
}

const RenderModules = ({
                           modules,
                           title,
                           category,
                           filterData,
                           setFilterData,
                           getAssignedCoursesByFilter,
                           getAssignedCourses,
                           isLoader
                       }: RenderModules) => {
    var {translate} = TranslateHelper();

    // const [selectedFilterData, setSelectedFilterData] = useState();

    const navigate = useNavigate();

    const showAllModulesByCategory = (category) => {
        navigate("/home?moduleCategory=" + category);
    }
    
    const styles = {
        modulesContainerStyle: {
            padding: "2%",
            borderLeft: "solid 5px",
            borderColor: category == "filter" ? "black" : category == 1 ? "#db2828" : category == 3 ? "#f2711c" : "#21ba44",
            marginBottom: isMobile ? "10%" : "2%",
            pointerEvents: isLoader ? "none" as "none" : "auto" as "auto"
        }
    }
    
    return (
        <motion.div
            animate={{opacity: isLoader ? [0.3, 0.7, 0.3] : [1, 1, 1]}}
            transition={{repeat: Infinity, repeatDelay: 0.2, duration: 1.3}}
            style={styles.modulesContainerStyle}>

            {/*render by category */}
            {category != "filter" &&
                <div>

                    <div className={"homePageV2SubModuleTitle"}>{translate(title)}</div>

                    <div className={"homePageCourseContainerSection"}>
                        {modules.filter(x => x.category == category).length > 0 && modules.filter(x => x.category == category).map((module, index) => (
                            <div style={{width:"100%", display:"flex", gap:"1em", flexWrap:"wrap"}} key={uuidv4()}>
                                {module.modules.length > 0 ? module.modules.map((item, index) => (
                                    <UserDashboardCourseCard
                                        key={uuidv4()}
                                        cardTitle={item.courseTitle}
                                        cardDesc={item.courseDescription}
                                        videoCount={item.videoCount}
                                        htmlCount={item.htmlCount}
                                        testCount={item.testCount}
                                        surveyCount={item.surveyCount}
                                        taskCount={item.taskCount}
                                        registerDate={item.registerDate}
                                        courseTotalSection={item.courseTotalSection}
                                        courseTotalContent={item.courseTotalContent}
                                        link={"/course/" + item.moduleId + "/" + item.registrationId}
                                        status={item.status}
                                        isDone={item.isDone}
                                        coverPhoto={item.coverPhoto }
                                        progress={category == 1 ? 0 : category == 3 ? item.complatetionRate : 100}
                                    />
                                )) : <NotFoundModuleMessage/>}
                            </div>
                            

                        ))}
                    </div>

                    {modules.filter(x => x.category == category)[0].modules.isEnabledShowAllButton &&
                        <Button
                            onClick={(e) => {
                                showAllModulesByCategory(category)
                            }}
                            className={"mt2"}
                            circular={true}
                            color={"green"}>{translate("Tümünü göster")}
                        </Button>
                    }

                </div>
            }

            {/*filter result page*/}
            {modules.list != undefined && category == "filter" &&
                <div>
                    <div className={"homePageV2SubModuleTitle"}>{translate("Filtreleme sonuçları")}</div>

                    {filterData != undefined &&
                        <div className={"homePageV2SubModuleTitle"}>
                            <SelectedFilterElements
                                filterData={filterData}
                                setFilterData={setFilterData}
                                getAssignedCoursesByFilter={getAssignedCoursesByFilter}
                                getAssignedCourses={getAssignedCourses}
                            />
                        </div>
                    }

                    <div className={"homePageCourseContainerSection"}>
                        {modules.list.length > 0 && modules.list.map((course, index) => (
                            <UserDashboardCourseCard
                                key={uuidv4()}
                                cardTitle={course.courseTitle}
                                coverPhoto={course.coverPhoto }
                                cardDesc={course.courseDescription}
                                videoCount={course.videoCount}
                                htmlCount={course.htmlCount}
                                testCount={course.testCount}
                                surveyCount={course.surveyCount}
                                taskCount={course.taskCount}
                                registerDate={course.registerDate}
                                courseTotalSection={course.courseTotalSection}
                                courseTotalContent={course.courseTotalContent}
                                link={"/course/" + course.moduleId + "/" + course.registrationId}
                                status={course.status}
                                isDone={course.isDone}
                                progress={course.complatetionRate}
                            />
                        ))}
                    </div>
                    {modules.list.length > 0 &&
                        <Pagination
                            className={"mt3 renderModulePaginationComp"}
                            defaultActivePage={1}
                            firstItem={null}
                            lastItem={null}
                            pointing
                            secondary
                            totalPages={Math.ceil(modules.count / 25)}
                            onPageChange={
                                (event, data) => {
                                    setFilterData({...filterData, currentPage: Number(data.activePage)});
                                }
                            }
                        />

                        // <Button className={"mt2"} circular={true} color={"green"}>{translate("Pagination")}</Button>
                    }


                </div>
            }
        </motion.div>
    );
};

export default memo(RenderModules);