import * as React from 'react';
import { Image, Loader } from 'semantic-ui-react';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';

const BuddyPagination = (props) => {
    const [current, setCurrent] = React.useState(props.page);
    const style = {
        container: {
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1vw",
            alignItems: "center"
        },
        prevBtn: {
            transform: "rotate(180deg)",
        },
        nextPrevBtns: {
            cursor: "pointer"
        },
        selected: {
   
            backgroundColor:"#e1e1e1",
            marginTop: "4px",
            fontSize: "25px"
        },
        paginationItem: {
            borderRadius: "4px",
            padding: ".5rem",
            cursor: "pointer"
        },
        opacity50: {
            opactity: "0.50"
        }
    }

    React.useEffect(() => {
        if (props.setter)
            props.setter(current);
        if (props.setterWithEntity)
            props.setterWithEntity(current, props.entity)
    }, [current]);

    return (
        <div style={style.container}>
            {props.paginationLoader &&
                <Loader size={"small"} active inline='centered' />
            }
            <Image id="prevImage" source={displayProtectedAppDoc("/Documents/Buddy/next-btn.png", "prevImage")}
                style={{ ...style.prevBtn, ...style.nextPrevBtns, ...(current == 1 && style.opacity50) }}
                onClick={() => { current != 1 ? setCurrent(current - 1) : setCurrent(current) }}
            />
            {Array.from({ length: props.pageCount }).map((item, index) => (
                <div key={index}
                    style={{ ...style.paginationItem, ...((index + 1) == current && style.selected) }} onClick={() => { setCurrent(index + 1) }} >
                    {index + 1}
                </div>
            ))}
            <Image id="nextImage" source={displayProtectedAppDoc("/Documents/Buddy/next-btn.png", "nextImage")}
                style={{ ...style.nextPrevBtns, ...(current == props.pageCount && style.opacity50) }}
                onClick={() => { current != props.pageCount ? setCurrent(current + 1) : setCurrent(current) }}
            />
        </div>
    );
}

export default BuddyPagination;