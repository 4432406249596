import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Icon, Input, Label } from 'semantic-ui-react';

import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';
import { v4 as uuidv4 } from 'uuid';
import { DataHandler } from '../../../helper/DataHelper';

const DocumentSettings = () => {

    let _ = require("lodash");
    var { translate } = TranslateHelper();
    const [supportedImageTypes, setSupportedImageTypes] = useState([".png", ".jpg", ".jpeg", ".ico", ".gif", ".PNG", ".JPG", ".JPEG", ".ICO", ".GIF"]);
    const [supportedVideoTypes, setSupportedVideoTypes] = useState([".mp4", ".WebM"]);
    const [supportedDocTypes, setSupportedDocTypes] = useState([".pdf", ".docx"]);
    const [newImageType, setNewImageType] = useState("");
    const [newVideoType, setNewVideoType] = useState("");
    const [newDocType, setNewDocType] = useState("");
    const [tenantId, setTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [settingsJson, setSettingsJson] = useState<any>({});

    const getDocumentSettings = () => {
        NetworkRequest('/api/Settings/GetSetting', { tenantId: tenantId, key:'DocumentSettings' }).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail != null) {
                let json = JSON.parse(response.data.detail.settingValue)
                setSettingsJson(json);
                if (json.supportedImageTypes != undefined && json.supportedImageTypes.length > 0) {
                    setSupportedImageTypes(json.supportedImageTypes);
                }
                if (json.supportedVideoTypes != undefined && json.supportedVideoTypes.length > 0) {
                    setSupportedVideoTypes(json.supportedVideoTypes);
                }
                if (json.supportedDocTypes != undefined && json.supportedDocTypes.length > 0) {
                    setSupportedDocTypes(json.supportedDocTypes);
                }
            }
        });
    }

    const saveDocumentSettings = () => {
        let model = {
            supportedImageTypes: supportedImageTypes,
            supportedVideoTypes: supportedVideoTypes,
            supportedDocTypes: supportedDocTypes
        };
        NetworkRequest('/api/Settings/AddOrUpdateSetting', {
            tenantId: tenantId,
            key: 'DocumentSettings',
            value: JSON.stringify(model)
        }).then((response) => {
            if (response.data.resultCode == 0) {
                toast(translate("Başarılı"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
        });
    }

    const removeImageType = (data) => {
        setSupportedImageTypes(_.remove(supportedImageTypes, (type) => { return type != data }));
    }

    const removeVideoType = (data) => {
        setSupportedVideoTypes(_.remove(supportedVideoTypes, (type) => { return type != data }));
    }

    const removeDocType = (data) => {
        setSupportedDocTypes(_.remove(supportedDocTypes, (type) => { return type != data }));
    }

    useEffect(() => {
        getDocumentSettings();
    }, []);

    return (
        <div className="documentSettingsContainer">
            <h1>{translate("Doküman ayarları")}</h1>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Label>
                            {translate("Desteklenen Görsel Uzantıları")}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row>
                            <Grid.Column>
                                <i>
                                    {supportedImageTypes.map((imageType, i) => (
                                        <span key={uuidv4()}>
                                            {imageType}
                                            <Icon name="trash alternate outline" color="red" onClick={() => { removeImageType(imageType); }} />
                                        </span>
                                    ))}
                                </i>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    value={newImageType}
                                    onChange={(e) => { setNewImageType(e.target.value); }}
                                />
                                {newImageType != ""
                                    &&
                                    <Icon className="marginLeft2" name="plus" color="green" onClick={() => { setSupportedImageTypes([...supportedImageTypes, !newImageType.startsWith(".") ? "." + newImageType : newImageType]); setNewImageType(""); }} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Label>
                            {translate("Desteklenen Video Uzantıları")}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row>
                            <Grid.Column>
                                <i>
                                    {supportedVideoTypes.map((videoType, i) => (
                                        <span key={uuidv4()}>
                                            {videoType}
                                            <Icon name="trash alternate outline" color="red" onClick={() => { removeVideoType(videoType); }} />
                                        </span>
                                    ))}
                                </i>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    value={newVideoType}
                                    onChange={(e) => { setNewVideoType(e.target.value); }}
                                />
                                {newVideoType != ""
                                    &&
                                    <Icon className="marginLeft2" name="plus" color="green" onClick={() => { setSupportedVideoTypes([...supportedVideoTypes, !newVideoType.startsWith(".") ? "." + newVideoType : newVideoType]); setNewVideoType(""); }} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Label>
                            {translate("Desteklenen Doküman Uzantıları")}
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row>
                            <Grid.Column>
                                <i>
                                    {supportedDocTypes.map((docType, i) => (
                                        <span key={uuidv4()}>
                                            {docType}
                                            <Icon name="trash alternate outline" color="red" onClick={() => { removeDocType(docType); }} />
                                        </span>
                                    ))}
                                </i>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input
                                    value={newDocType}
                                    onChange={(e) => { setNewDocType(e.target.value); }}
                                />
                                {newDocType != ""
                                    &&
                                    <Icon className="marginLeft2" name="plus" color="green" onClick={() => { setSupportedDocTypes([...supportedDocTypes, !newDocType.startsWith(".") ? "." + newDocType : newDocType]); setNewDocType(""); }} />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button color="green" className="floatRight" onClick={() => { saveDocumentSettings(); }}>
                            {translate("Ayarları Kaydet")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );

}

export default DocumentSettings;