import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Input, Label, Icon } from 'semantic-ui-react';
import { NetworkRequest } from '../../../../helper/NetworkRequest';

const EditSubscription = (props) => {

    //styles
    let styles = {
        activeColor: {
            color: 'green'
        },
        passiveColor: {
            color: 'red'
        }
    }

    const [subscriptionType, setSubscriptionType] = useState(props.SubscriptionType);

    useEffect(() => {
        setSubscriptionType(props.SubscriptionType);
    }, [props.SubscriptionType]);

    const updateHandler = () => {
        props.Update(subscriptionType).then(() => {
            props.showList();
        });
    }

    return (
        <div>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Label>
                            Adı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input value={subscriptionType.displayName} onChange={(e) => { setSubscriptionType({ ...subscriptionType, displayName: e.target.value }); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Label>
                            Silindi Mi?:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Icon name="circle" style={subscriptionType != undefined && subscriptionType.isDeleted ? styles.activeColor : styles.passiveColor} onClick={(e) => { setSubscriptionType({ ...subscriptionType, isDeleted: !subscriptionType.isDeleted }); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button className={"dangerRedButton"} onClick={props.showList}>
                            <Icon name="arrow left" />
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button className={"positiveGreenButton"} onClick={() => { updateHandler(); }}>
                            <Icon name="check" />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default EditSubscription;