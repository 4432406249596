import React, { memo, useEffect, useState } from 'react';
import { Button, Header, Icon, Label, Popup, Progress, Transition } from "semantic-ui-react";
import { DateFormat } from "../../../helper/DateFormat";
import TranslateHelper from "../../../helper/TranslateHelper";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const UserDashboardCourseCard = ({
    cardTitle,
    cardDesc,
    coverPhoto,
    videoCount,
    htmlCount,
    testCount,
    surveyCount,
    taskCount,
    registerDate,
    courseTotalSection,
    courseTotalContent,
    link,
    status,
    progress,
    isDone
}) => {

    var { translate } = TranslateHelper();

    const [isOpenInfoBox, setIsOpenInfoBox] = useState(false);


    return (

        <div className={"userDashboardCourseCard"}>

            <div className={"userDashboardCourseCardImageContainer"}>
                {status &&
                    <div className={"userDashboardImageNewContainer"}>
                        <Label circular={true} size={"medium"} color={status == "newModules" ? "red" : status == "continueModules" ? "orange" : "green"}>
                            <Icon name={status == "newModules" ? "certificate" : status == "continueModules" ? "hourglass two" : "flag checkered"} />
                            {translate(status == "newModules" ? "Yeni" : status == "continueModules" ? translate("Devam ediyor") : translate("Tamamlandı"))}
                        </Label>

                        <Progress
                            className={"endUserModulesProgress"}
                            size={"medium"}
                            percent={progress.toFixed(2)} indicating progress
                        />

                    </div>
                }
                <div className={"userDashboardImageSpanContainer"}>
                    <Button
                        onClick={() => {
                            setIsOpenInfoBox(!isOpenInfoBox)
                        }}
                        color={"blue"}
                        size={"mini"}
                        circular={true}
                        icon='info'
                    />
                </div>

                <Transition visible={isOpenInfoBox} animation='scale' duration={500}>
                    <div className={"userDashboardSpanContainer"}>
                        <span className='userDashboardCourseCardDate'>{DateFormat(registerDate)} {translate("tarihinden itibaren kayıtlısınız.")}</span>
                        <p className='userDashboardCourseCardDate'>{translate("Toplam")} {courseTotalSection} {translate("bölüm")}
                            {translate("ve")} {courseTotalContent} {translate("içerik")} </p>
                    </div>
                </Transition>

                <div className={"userDashboardImagelabelContainer"}>
                    <Popup content={translate("video içerir")} trigger={
                        <Label className={"userDashboardImagelabel"} circular>
                            <Icon name='video' /> {videoCount}
                        </Label>
                    } />


                    <Popup content={translate("html dökümanı içerir")} trigger={
                        <Label className={"userDashboardImagelabel"} circular>
                            <Icon name='html5' /> {htmlCount}
                        </Label>
                    } />


                    <Popup content={translate("soru içerir")} trigger={
                        <Label className={"userDashboardImagelabel"} circular>
                            <Icon name='question' /> {testCount}
                        </Label>
                    } />

                    <Popup content={translate("anket içerir")} trigger={
                        <Label className={"userDashboardImagelabel"} circular>
                            <Icon name='list' /> {surveyCount}
                        </Label>
                    } />

                    <Popup content={translate("görev içerir")} trigger={
                        <Label className={"userDashboardImagelabel"} circular>
                            <Icon name='check' /> {taskCount}
                        </Label>
                    } />


                </div>

                <img className={"userDashboardCourseCardImage"} src={coverPhoto ? coverPhoto : '/orientation.jpg'} />

            </div>


            <Link to={link}>
                <div className={"userDashboardCourseCardTitle"}>
                    <span>{cardTitle}</span>
                </div>
                <div className={"userDashboardCourseCardDesc"}>
                    {cardDesc}
                </div>

                <div style={{ marginTop: "3%" }}>
                    <Button circular={true} className="ingIndigo">{translate("Devam Et")}</Button>
                </div>
            </Link>


        </div>


    );
};

export default memo(UserDashboardCourseCard);