import * as React from 'react';
import { useEffect, useState } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import { NetworkRequest } from '../../src/helper/NetworkRequest';
import { Button } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from '../helper/TranslateHelper';

const EditorComponent = (props) => {
    var { translate } = TranslateHelper();
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState("");
    const [selection, setSelection] = useState({ index: 0 });
    const [variants, setVariants] = useState([]);

    const { quill, quillRef, Quill } = useQuill({
        modules: { blotFormatter: {} }
    });

    if (Quill && !quill) {
        Quill.register('modules/blotFormatter', BlotFormatter);
    }

    const getNotificationVariants = () => {
        NetworkRequest('/api/ScheduledJobs/GetVariants').then((response) => {
            if (response.data.resultCode == 0) {
                setVariants(response.data.detail);
            }
        });
    }

    const selectVariant = (variant) => {
        quill.insertText(selection.index, variant.variant);
        //let newData = '';
        //if (editorContentIndex > 0) {
        //    let variantWord = getSelectVariantWord();
        //    let startIndex = editorContent.indexOf(variantWord);
        //    let selectedFirstPart = editorContent.slice(0, startIndex);
        //    let rawData = selectedFirstPart + " " + variant.variant;
        //    newData = editorContent.replace(selectedFirstPart, rawData);
        //} else {
        //    newData = editorContent + " " + variant.variant;
        //}
        setEditorContent(quill.root.innerHTML);
    }

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldContents) => {
                ////console.log('Text change!', quill.root.innerHTML);
                setEditorContent(quill.root.innerHTML);
                setSelection({ ...selection, index: delta.ops[0].retain + 1 });
            });
            quill.on('selection-change', (delta, oldContents) => {
                ////console.log('Text index:', quill.getSelection(true).index);
                setSelection(quill.getSelection(false));
            });
            if (quill != undefined && props != undefined && props.value != undefined && quill.root.innerHTML != editorContent) {
                quill.root.innerHTML = editorContent;
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }, [quill, Quill, editorContent]);

    useEffect(() => {
        if (!isLoading) {
            props.setEditorContent(editorContent);
        }
    }, [editorContent]);

    useEffect(() => {
        if ((
            props.value != undefined
            && props.value != ""
            && editorContent == ""
        )
            ||
            (
                props.value == ""
                && editorContent != ""
            )
        ) {
            setEditorContent(props.value);
        }
    }, [props]);

    useEffect(() => {
        if (props.showVairant == true)
            getNotificationVariants();



    }, []);

    useEffect(() => {

        if (props.actionType && props.showVairant == true) {
            var selectedAction = props?.actionType[0];

            if (selectedAction && selectedAction.key == "0a1819c6-3803-42fd-b011-14ff312e4bcf") {
                const variantList = variants.filter(item => item.variant != "##AuthCode##");
                /*console.log("variantList", variantList);*/
                setVariants(variantList)
            } else {
                getNotificationVariants();
            }
        }
    }, [props.actionType]);

    return (
        <div>
            <div ref={quillRef} />
            {props.showVairant == true
                &&
                <div style={{ display: "flex", flexWrap: "wrap", gap: ".5em", marginTop: "1%" }}>
                    {variants.length > 0 && variants.map((variant) => (
                        <Button
                            color={"yellow"}
                            key={uuidv4()}
                            className={"roundedButton"}
                            onClick={(e) => { selectVariant(variant); }}
                        >
                            {translate(variant.displayName)}
                        </Button>
                    ))}
                </div>
            }
        </div>
    );
};

export default EditorComponent;
