import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Button, Dropdown, Loader, Modal, Transition } from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import { IDataTable, IDropdown } from '../../../interfaces/interfaces'
import { datableFilterChange, digitallicaConfirm, prepareDataForTable } from '../../../helper/HelperFunctions';
import { toast } from 'react-toastify';

import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Modules {
    id?: string,
    title?: string,
    description?: string,
    createdDate?: Date
}

const Modules = (props) => {
    var hash = require('object-hash');
    var { translate } = TranslateHelper();

    const _ = require('lodash');
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState<any>({})
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showIsCloneTenant, setShowIsCloneTenant] = useState<boolean>(false);
    const [selectedTenantIds, setSelectedTenantIds] = useState<Array<string>>([]);
    const [tenantList, setTenantList] = useState<Array<IDropdown>>([]);
    const [selectedModuleId, setSelectedModuleId] = useState<any>();

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    //delete selected data
    const deleteModule = (id) => {
        //debugger;
        digitallicaConfirm(translate("Silmek istediğinize emin misiniz ?"),
            () => {
                let module = {
                    id: id
                }
                NetworkRequest("api/Modules/Delete", module).then((response) => {
                    toast(translate("Başarılı."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        ////console.log(filterData);
                        getModules(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi"), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const cloneRow = (id) => {
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz ?"),
            () => {
                let module = {
                    id: id
                }
                toast(translate("Modülünüz Klonlanıyor Lütfen Bekleyiniz"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2500 });

                NetworkRequest("api/Modules/Clone", module).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        ////
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }

                        getModules(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );

    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Geri döndürmek istediğinize emin misiniz ?"),
            () => {
                let module = {
                    id: id
                }
                NetworkRequest("api/Modules/UndoDelete", module).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {

                        let index = dataTableModel.data.findIndex(
                            data => data.secretData[0].id === id
                        )

                        if (index > -1) {
                            dataTableModel.data.splice(index, 1);

                            setDataTableModel({
                                header: dataTableModel.header,
                                data: [],
                                transactions: dataTableModel.transactions,
                                filter: dataTableModel.filter,
                                pagination: dataTableModel.pagination,
                                addRowLink: dataTableModel.addRowLink
                            });

                            setDataTableModel(dataTableModel);

                        }
                    }
                });
            },
            () => {
                toast(translate("İşem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
        setIsDeleted(!data.checked);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
        setIsActive(!data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const changeDropdownTenant = (event, data) => {
        setSelectedTenantIds(data.value);
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        ////console.log(filterData);
        setFilterData(filterData);
        getModules(filterData);
        //getDataCount(filterData);

    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    const showChildTenants = (id) => {
        setSelectedModuleId(id);
        setShowIsCloneTenant(true);
    }

    let transactionArr = [
        {
            icon: "chart bar",
            desc: translate("Rapor"),
            color: "black",
            link: "/capsuleReport",
            type: "report"
        },
        {
            icon: "clone",
            desc: translate("Klonla"),
            color: undefined,
            link: null,
            function: userRole == "HeadAdmin" ? showChildTenants : cloneRow,
            type: "clone",
        },
        {
            icon: "add user",
            desc: translate("Çalışan Ekle"),
            color: "green",
            link: "/moduleEdit/4",
            type: "edit"
        },

        {
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/moduleEdit",
            type: "edit"
        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: deleteModule
        },
        {
            icon: "redo",
            desc: translate("Geri al"),
            color: undefined,
            link: null,
            type: "undo",
            function: undoDelete
        }
    ]

    let tableHeader = [
        translate("Modül Adı"),
        translate("Açıklama"),
        translate("Ortalama Puan"),
        translate("Modülü Oluşturan Kişi"),
        translate("Oluşturma Tarihi"),
        translate("Aktif"),
        translate("Hoş Geldin Modülü Mü?"),
        translate("Kayıtlı Çalışan Sayısı"),
        translate("İşlemler")
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(1, 0, "Şirket");
    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                //showData: [< Checkbox />, 'test1', 'test2', '2021-12-28 09:47:00', 0, false],
                secretData: [
                    {
                        id: "",
                        isDeleted: false,
                        isSelected: false
                    }
                ]
            }
        ],
        transactions: transactionArr,
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            currentPage: currentPage,
            setCurrentPage: setCurrentPage,
        },
        noCheckBox: true,
        setRowSelected: setRowSelected,
        addRowLink: "/moduleAdd"
    });

    const getTenants = () => {
        NetworkRequest("api/Tenants/GetTenantListCanClone").then(response => {

            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                const roleToDropdownType = result.map(elem => (
                    {
                        text: elem.displayName,
                        key: elem.tenantId,
                        value: elem.tenantId
                    }
                ));
                setTenantList(roleToDropdownType);
            }
        });
    }

    //prepare data for table
    const prepareData = (response) => {
        let data = response.list;
        if (data.length == 0 && currentPage > 1)
            setCurrentPage(1);
        ////console.log(data);
        //hidden column
        let model = prepareDataForTable(data, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'coverPhoto',
            'continueAccessAfterDone',
            (userRole != "HeadAdmin") ? "tenantName" : ""
        ]);

        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    search: filterText,
                    setSearch: setFilterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    currentPage: currentPage,
                    count: Math.ceil(response.count / 25),
                    setCurrentPage: setCurrentPage,
                }
            });
        }, 100);
        setIsLoading(false);
    }


    const cloneModule = () => {
        setShowIsCloneTenant(false);
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz ?"),
            () => {
                let moduleView = {
                    id: selectedModuleId
                }
                let store = DataHandler.getStore();
                var formData = new FormData();
                formData.append('ModuleView', JSON.stringify(moduleView));
                formData.append('List', JSON.stringify(selectedTenantIds));


                toast(translate("Modülünüz Klonlanıyor Lütfen Bekleyiniz"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2500 });

                NetworkRequest("api/Modules/CloneModuleByTenant", formData).then((response) => {
                    if (response.data.resultCode === 0) {
                        //
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }

                        getModules(filterData);
                        toast(translate("Tamamlandı!"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 1000 });
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    //get table data from database
    const getModules = (filterData?) => {
        filterData.isAll = DataHandler.getUserDataWithKey("userRole") == "HeadAdmin";
        setIsLoading(true);
        NetworkRequest("api/Modules/GetModules", filterData).then(response => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    useEffect(() => {
        if (showIsCloneTenant)
            getTenants();
    }, [showIsCloneTenant]);


    //useEffect(() => {
    //    debugger;
    //}, []);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <Transition visible={false} animation='scale' duration={500}>
                <div>
                    <Loader size={"huge"} active inline='centered' />
                </div>
            </Transition>
            <Transition visible={true} animation='scale' duration={500}>
                <div>
                    <DataTable
                        dataTable={dataTableModel}
                        isAllSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        selectAllHandler={selectAllHandler}
                        isLoading={isLoading}
                    />
                </div>

            </Transition>
            <Modal
                size={"mini"}
                open={showIsCloneTenant}
            >
                <Modal.Header>Modülü Klonla</Modal.Header>
                <Modal.Content>
                    <Dropdown
                        fluid multiple selection
                        value={selectedTenantIds}
                        name="tenantId"
                        clearable
                        options={tenantList}
                        size="big"
                        placeholder={translate("Şirketleri Seçiniz")}
                        onChange={changeDropdownTenant}
                    />
                    {/*style={{ ...styles.multipleDropdown }}*/}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setShowIsCloneTenant(false)}>
                        {translate("Hayır")}
                    </Button>
                    <Button positive onClick={() => cloneModule()}>
                        {translate("Evet")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default Modules;