import * as React from 'react';
import {useState, useEffect} from 'react';
import {NetworkRequest } from '../../../helper/NetworkRequest';
import {Button, Checkbox, Dropdown, Loader, Modal, Transition} from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import {IDataTable, IDropdown} from '../../../interfaces/interfaces'

import {prepareDataForTable, datableFilterChange, digitallicaConfirm} from '../../../helper/HelperFunctions';
import {toast} from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Test {
    id?: string,
    testName?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const Quizes = (props) => {
    var {translate} = TranslateHelper();
    var _ = require('lodash');
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [selectedQuizId, setSelectedQuizId] = useState<any>();
    const [showIsCloneTenant, setShowIsCloneTenant] = useState<boolean>(false);
    const [tenantList, setTenantList] = useState<Array<IDropdown>>([]);
    const [selectedTenantIds, setSelectedTenantIds] = useState<Array<string>>([]);

    //delete selected data

    const deleteTest = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Quiz/Delete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId"),
                            checkSubTenants: userRole == "HeadAdmin"
                        }
                        getQuizes(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const cloneRow = (id) => {
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Quiz/Clone", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        ////
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId"),
                            checkSubTenants: userRole == "HeadAdmin"
                        }
                        getQuizes(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );

    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Geri döndürmek istediğinize emin misiniz ?"),
            () => {
                let test = {
                    id: id
                }

                NetworkRequest("/Quiz/UndoDelete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {

                        let index = dataTableModel.data.findIndex(
                            data => data.secretData[0].id === id
                        )

                        if (index > -1) {
                            dataTableModel.data.splice(index, 1);

                            setDataTableModel({
                                header: dataTableModel.header,
                                data: [],
                                transactions: dataTableModel.transactions,
                                filter: dataTableModel.filter,
                                pagination: dataTableModel.pagination,
                                addRowLink: dataTableModel.addRowLink
                            });
                            setDataTableModel(dataTableModel);
                        }
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const getTenants = () => {
        NetworkRequest("api/Tenants/GetTenantListCanClone").then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                const roleToDropdownType = result.map(elem => (
                    {
                        text: elem.displayName,
                        key: elem.tenantId,
                        value: elem.tenantId
                    }
                ));
                setTenantList(roleToDropdownType);
            }
        });
    }


    const changeDropdownTenant = (event, data) => {
        setSelectedTenantIds(data.value);
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
        setIsDeleted(!data.checked);
    }

    const onChangeDeleted = (e, data) => {

        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
        setIsActive(!data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            checkSubTenants: userRole == "HeadAdmin"
        }
        setFilterData(filterData);
        getQuizes(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    const showChildTenants = (id) => {
        setSelectedQuizId(id);
        setShowIsCloneTenant(true);
    }

    let transactionArr = [
        {
            icon: "chart bar",
            desc: translate("Rapor"),
            color: "black",
            link: "/qizQestionReport",
            type: "report"
        },
        {
            icon: "clone",
            desc: translate("Klonla"),
            color: undefined,
            link: null,
            type: "clone",
            function: userRole == "HeadAdmin" ? showChildTenants : cloneRow,
        },
        {
            icon: "question",
            desc: translate("Havuzdan Soru ekle"),
            color: "blue",
            link: "/quizContentPool",
            type: "add"
        },
        {
            icon: "plus",
            desc: translate("Yeni Soru ekle"),
            color: "green",
            link: "/questionAdd",
            type: "add",

        },

        {
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/EditQuiz",
            type: "edit",

        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: deleteTest
        },
        {
            icon: "redo",
            desc: translate("Geri al"),
            color: "black",
            link: null,
            type: "undo",
            function: undoDelete
        }
    ]

    let tableHeader = [
        <Checkbox
            onChange={setAllSelected} checked={isAllSelected}/>,
        translate("Test adı"),
        translate("Açıklama"),
        translate("Başarı Puanı"),
        translate("Oluşturan Kişi"),
        translate("Oluşturma Tarihi"),
        translate("Süre (dakika)"),
        translate("Soru Sayısı"),
        translate("Aktif"),
        translate("İşlemler")
    ];


    if (userRole == "HeadAdmin") {
        tableHeader.splice(4, 0, "Şirket");
    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: transactionArr,
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/AddQuiz",
        setRowSelected: setRowSelected
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response.list;

        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }

        let model = prepareDataForTable(data, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'searchField',
            'settings',
            'userId',
            userRole == "HeadAdmin" ? "" : "companyName"
        ]);
        ////console.log("dataTableModel.filter", dataTableModel.filter);
        //setDataTableModel({
        //    header: dataTableModel.header,
        //    data: [],
        //    transactions: dataTableModel.transactions,
        //    filter: dataTableModel.filter,
        //    pagination: dataTableModel.pagination,
        //    addRowLink: dataTableModel.addRowLink
        //});
        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(
                        response.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });

        }, 100);


        setIsLoading(false);
    }


    const cloneQuiz = () => {
        setShowIsCloneTenant(false);
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz ?"),
            () => {
                let quizView = {
                    id: selectedQuizId
                }

                var formData = new FormData();
                formData.append('QuizView', JSON.stringify(quizView));
                formData.append('List', JSON.stringify(selectedTenantIds));


                toast(translate("Sınavınız Klonlanıyor Lütfen Bekleyiniz"), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });

                NetworkRequest("Quiz/CloneQuizByTenant", formData).then((response) => {
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId"),
                            checkSubTenants: userRole == "HeadAdmin"
                        }
                        getQuizes(filterData);
                        toast(translate("Tamamlandı!"), {type: 'success', theme: 'dark', position: 'top-center', autoClose: 1000});

                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }


    useEffect(() => {
        if (showIsCloneTenant)
            getTenants();
    }, [showIsCloneTenant]);


    //get table data from database
    const getQuizes = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/Quiz/GetQuizes", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                                   setAllSelected={setAllSelected} selectAllHandler={selectAllHandler}
                                   isLoading={isLoading}/>
                    </div>
                </Transition>
                <Modal
                    size={"mini"}
                    open={showIsCloneTenant}
                >
                    <Modal.Header>{translate("Sınavı Klonla")}</Modal.Header>
                    <Modal.Content>
                        <Dropdown
                            fluid multiple selection
                            value={selectedTenantIds}
                            name="tenantId"
                            clearable
                            options={tenantList}
                            size="big"
                            placeholder={translate("Şirketleri Seçiniz")}
                            onChange={changeDropdownTenant}
                        />
                        {/*style={{ ...styles.multipleDropdown }}*/}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => setShowIsCloneTenant(false)}>
                            {translate("Hayır")}
                        </Button>
                        <Button positive onClick={() => cloneQuiz()}>
                            {translate("Evet")}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    );
}

export default Quizes;