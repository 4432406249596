import React, {useState, useEffect} from 'react';

import {Icon, Header, Input, Button, Loader, Image} from "semantic-ui-react";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import {digitallicaConfirm} from "../../../helper/HelperFunctions";
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from "../../../helper/TranslateHelper";

const ThemeSettings = () => {
    var { translate } = TranslateHelper();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [contentHTML, setContentHTML] = useState<any>(<div></div>);
    const [selectedSectionTitle, setSelectedSectionTitle] = useState("Sol Menu");
    const [logoUrl, setLogoUrl] = useState(DataHandler.getUserDataWithKey("logoUrl"));
    const [leftNavbarBgColor, setLeftNavbarBgColor] = useState("#ffffff");
    const [headerBgColor, setHeaderBgColor] = useState("#ffffff");

    const [menuItemBgColor, setMenuItemBgColor] = useState("transparent");
    const [menuItemBgColorHover, setMenuItemBgColorHover] = useState("transparent");
    const [menuItemTextColor, setMenuItemTextColor] = useState("#000000");
    const [menuItemTextColorHover, setMenuItemTextColorHover] = useState("#000000");
    const [menuItemIconColor, setMenuItemIconColor] = useState("#000000");
    const [menuItemIconColorHover, setMenuItemIconColorHover] = useState("#000000");

    const [menuItemMarkerColor, setMenuItemMarkerColor] = useState("#000000");

    const [isSelectedResult, setIsSelectedResult] = useState(false);

    const [selectedSection, setSelectedSection] = useState("leftNavbar");
    const [onMouseOverSection, setOnMouseOverSection] = useState("");
    const [onMouseEnterSection, setOnMouseEnterSection] = useState(false);

    const styles = {
        themeContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
            padding:"2%"
        },

        container: {
            width: "600px",
            height: "500px",
            backgroundColor: "white",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            marginTop: "3%",
            position: "relative" as "relative",
            zIndex: 0
        },
        header: {
            width: "100%",
            height: "60px",
            boxShadow: "rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px",
            backgroundColor: headerBgColor,
            border: (selectedSection == "header" || onMouseOverSection == "header") ? "2px red solid" : "unset",
            cursor: "pointer",
            transition: "border .2s",
        },
        leftNavbar: {
            width: "60px",
            height: "100%",
            boxShadow: "rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px",
            position: "absolute" as "absolute",
            zIndex: "2",
            top: "0",
            backgroundColor: leftNavbarBgColor,
            cursor: "pointer",
            transition: "border .2s",
            border: (selectedSection == "leftNavbar" || onMouseOverSection == "leftNavbar") ? "2px red solid" : "unset"
        },
        logoContainer: {
            height: "60px",
            width: "100%",
            padding: "5%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        menuItem: {
            display: "flex",
            flexDirection: "column" as "column",
            height: "60px",
            width: "100%",
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid rgb(243, 243, 243)",
            fontSize: ".8em",
            cursor: "pointer",
            backgroundColor: menuItemBgColor,
            color: menuItemTextColor,
            border: (selectedSection == "menuItem" || onMouseOverSection == "menuItem") ? "2px red solid" : "unset",
            transition: "border .2s",
        },

        menuItemSelected: {
            display: "flex",
            flexDirection: "column" as "column",
            height: "60px",
            width: "100%",
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid rgb(243, 243, 243)",
            fontSize: ".8em",
            cursor: "pointer",
            backgroundColor: menuItemBgColor,
            color: menuItemTextColor,
            borderLeft: "3px black solid",
            border: (selectedSection == "menuItemSelected" || onMouseOverSection == "menuItemSelected") ? "2px red solid" : "unset",
            transition: "border .2s",
        },
        forSelectedLeftNavbar: {
            width: "100%",
            height: "316px"
        },
        selectedSectionTitleStyle: {
            width: "100%",
            textAlign: "center"
        },
        selectedSectionContentContainer: {
            display: "flex",
            flexDirection: "column" as "column",
            width: "50%",
            paddingLeft: "5%",
            justifyContent: "space-between",
            height: "560px"
        },
        menuItemColorSettingsContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: "1em",
            marginTop: "5%"
        },
        menuItemColorSettingColumn: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column" as "column",
            gap: "1em"
        },
        menuItemColorSettingBox: {
            boxShadow: "rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px",
            padding: "15%",
            // borderRadius:"10px",
            backgroundColor: menuItemBgColor
        },
        menuItemColorSettingBoxHover: {
            boxShadow: "rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px",
            padding: "15%",
            // borderRadius:"10px",
            backgroundColor: menuItemBgColorHover
        },
        menuItemColorSettingBoxMarker: {
            boxShadow: "rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px",
            padding: "15%",
            backgroundColor: menuItemBgColor,
            borderLeft: "8px solid " + menuItemMarkerColor
        },
        menuItemIcon: {
            color: menuItemIconColor
        },
        menuItemIconHover: {
            color: menuItemIconColorHover
        },
        menuItemTextColor: {
            color: menuItemTextColor
        },
        menuItemTextColorHover: {
            color: menuItemTextColorHover
        },
        resultContainer: {
            marginTop: "15%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column" as "column"
        },
        saveButton: {
            marginTop: "5%",
            width: "100%",
            textAlign: "right" as "right",
            marginBottom:"5%"
        }
    }

    const convertSelectedSectionTitleToTurkish = (sectionText: string) => {
        switch (sectionText) {
            case "leftNavbar":
                return translate("Sol Menu")

            case "header":
                return translate("Üst Şerit")

            case "menuItem":
                return translate("Menu Elemanı")

            default:
                return ""
        }
    }

    const createContentBySelectedContent = (sectionText: string) => {
        switch (sectionText) {
            case "Sol Menu":
                return <div style={{marginTop: "5%"}}>
                    <div className={"dictionary-label"}>{translate("Arkaplan Rengi")}</div>
                    <Input
                        size="big"
                        value={leftNavbarBgColor}
                        onChange={o => {
                            setLeftNavbarBgColor(o.target.value);
                        }}
                        type="color"
                        fluid={true}
                        placeholder={translate("Tema rengi")}
                        className={"dictionary-input-text"}
                        id={"dictionary-select-color-input"}
                    />
                </div>

            case "Üst Şerit":
                return <div style={{marginTop: "5%"}}>
                    <div className={"dictionary-label"}>{translate("Arkaplan Rengi")}</div>
                    <Input
                        size="big"
                        value={headerBgColor}
                        onChange={o => {
                            setHeaderBgColor(o.target.value);
                        }}
                        type="color"
                        fluid={true}
                        placeholder={translate("Tema rengi")}
                        className={"dictionary-input-text"}
                        id={"dictionary-select-color-input"}
                    />
                </div>


            case "Menu Elemanı":
                return <div style={styles.menuItemColorSettingsContainer}>
                    <div style={styles.menuItemColorSettingColumn}>
                        <div>{translate("İlk Görüntü")}</div>
                        <div style={styles.menuItemColorSettingBox}>
                            <Icon style={styles.menuItemIcon} name={"home"} size={"huge"}/>
                            <div style={styles.menuItemTextColor}>
                                {translate("Anasayfa")}
                            </div>
                        </div>
                        <div>
                            <div>{translate("Arka plan rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemBgColor}
                                onChange={o => {
                                    setMenuItemBgColor(o.target.value);
                                    setMenuItemBgColorHover(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate("Tema rengi")}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>
                        <div>
                            <div>{translate("İkon rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemIconColor}
                                onChange={o => {
                                    setMenuItemIconColor(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate('İkon rengi')}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>
                        <div>
                            <div>{translate("Yazı rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemTextColor}
                                onChange={o => {
                                    setMenuItemTextColor(o.target.value);
                                    // setMenuItemTextColorHover(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate('Yazı rengi')}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>
                    </div>
                    <div style={styles.menuItemColorSettingColumn}>
                        <div>{translate("Üzerine gelince")}</div>
                        <div style={styles.menuItemColorSettingBoxHover}>
                            <Icon style={styles.menuItemIconHover} name={"home"} size={"huge"}/>
                            <div style={styles.menuItemTextColorHover}>{translate("Anasayfa")}</div>
                        </div>
                        <div>
                            <div>{translate("Arka plan rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemBgColorHover}
                                onChange={o => {
                                    setMenuItemBgColorHover(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate('Arka plan rengi')}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>
                        <div>
                            <div>{translate("İkon rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemIconColorHover}
                                onChange={o => {
                                    setMenuItemIconColorHover(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate("İkon rengi")}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>
                        <div>
                            <div>{translate("Yazı rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemTextColorHover}
                                onChange={o => {
                                    setMenuItemTextColorHover(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate('Yazı rengi')}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>
                    </div>
                    <div style={styles.menuItemColorSettingColumn}>
                        <div>{translate("Seçilme belirteçi")}</div>
                        <div style={styles.menuItemColorSettingBoxMarker}>
                            <Icon style={styles.menuItemIcon} name={"home"} size={"huge"}/>
                            <div style={styles.menuItemTextColor}>{translate("Anasayfa")}</div>
                        </div>
                        <div>
                            <div>{translate("Belirteç rengi")}</div>
                            <Input
                                size="big"
                                value={menuItemMarkerColor}
                                onChange={o => {
                                    setMenuItemMarkerColor(o.target.value);
                                }}
                                type="color"
                                fluid={true}
                                placeholder={translate('Belirteç rengi')}
                                className={"dictionary-input-text"}
                                id={"dictionary-select-color-input"}
                            />
                        </div>

                        <div style={styles.resultContainer}>
                            <div>{translate("Sonuç")}</div>
                            <div style={styles.menuItemColorSettingColumn}>
                                <div
                                    onClick={() => {
                                        setIsSelectedResult(!isSelectedResult)
                                    }}
                                    onMouseEnter={() => {
                                        setOnMouseEnterSection(true);
                                    }}
                                    onMouseLeave={() => {
                                        setOnMouseEnterSection(false);
                                    }}
                                    style={{
                                        transition: "all .2s",
                                        cursor: "pointer",
                                        boxShadow: "rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px",
                                        padding: "15%",
                                        backgroundColor: onMouseEnterSection ? menuItemBgColorHover : menuItemBgColor,
                                        borderLeft: isSelectedResult ? "8px solid " + menuItemMarkerColor : "8px solid transparent"
                                    }}>
                                    <Icon style={{
                                        transition: "all .2s",
                                        color: !onMouseEnterSection ? menuItemIconColor : menuItemIconColorHover
                                    }} name={"home"} size={"huge"}/>
                                    <div style={{
                                        transition: "all .2s",
                                        color: !onMouseEnterSection ? menuItemTextColor : menuItemTextColorHover
                                    }}>
                                        {translate("Anasayfa")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            default:
                return <div></div>
        }
    }

    const handleSelectedSection = (section: string, event) => {
        event.stopPropagation();
        setSelectedSection(section);
        setSelectedSectionTitle(convertSelectedSectionTitleToTurkish(section == "menuItemSelected" ? "menuItem" : section));
    }

    const handleOnMouseEnter = (section: string, event) => {
        setOnMouseOverSection(section);
    }

    const handleOnMouseLeave = () => {
        setOnMouseOverSection("");
    }

    const saveTheme = () => {
        setIsSaving(true);
        let data = {
            SiteThemeModel: {
                LeftNavbarBgColor: leftNavbarBgColor,
                HeaderBgColor: headerBgColor,
                MenuItemBgColor: menuItemBgColor,
                MenuItemBgColorHover: menuItemBgColorHover,
                MenuItemTextColor: menuItemTextColor,
                MenuItemTextColorHover: menuItemTextColorHover,
                MenuItemIconColor: menuItemIconColor,
                MenuItemIconColorHover: menuItemIconColorHover,
                MenuItemMarkerColor: menuItemMarkerColor
            },
            SiteSettingModel: {
                TenantId: DataHandler.getUserDataWithKey("tenantId").toString()
            }

        };

        NetworkRequest("/api/SiteSetting/UpdateTheme", data).then((response) => {
            //
            if (response.data.resultCode === 0) {
                toast(translate("Tema ayarlarınız kaydedildi."), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
                setIsSaving(false);

                //setTimeout(()=> {window.location.reload();},1500)
                
            }
        });
    }

    const resetThemeSettings = () => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let data = {
                    SiteThemeModel: {
                        leftNavbarBgColor: "#ffffff",
                        headerBgColor: "#ffffff",
                        menuItemBgColor: "#ffffff",
                        menuItemBgColorHover: "#ffffff",
                        menuItemTextColor: "#000000",
                        menuItemTextColorHover: "#000000",
                        menuItemIconColor: "#000000",
                        menuItemIconColorHover: "#000000",
                        menuItemMarkerColor: "#353268"
                    },
                    SiteSettingModel: {
                        TenantId: DataHandler.getUserDataWithKey("tenantId").toString()
                    }
                }

                NetworkRequest("/api/SiteSetting/UpdateTheme", data).then((response) => {
                    //
                    if (response.data.resultCode === 0) {
                        toast(translate("Tema ayarlarınız kaydedildi."), {
                            type: 'success',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });
                        setIsSaving(false);
                        getTheme();

                        setTimeout(()=> {window.location.reload();},1500)
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }

    const getTheme = () => {
        setIsLoading(true);
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId").toString()
        };

        NetworkRequest("/api/SiteSetting/GetTheme", data).then((response) => {
            if (response.data.resultCode === 0) {
                
                if(response.data.detail != null) {
                    let themeJSON = JSON.parse(response.data.detail);

                    setLeftNavbarBgColor(themeJSON.LeftNavbarBgColor);
                    setHeaderBgColor(themeJSON.HeaderBgColor);
                    setMenuItemBgColor(themeJSON.MenuItemBgColor);
                    setMenuItemBgColorHover(themeJSON.MenuItemBgColorHover);
                    setMenuItemTextColor(themeJSON.MenuItemTextColor);
                    setMenuItemTextColorHover(themeJSON.MenuItemTextColorHover);
                    setMenuItemIconColor(themeJSON.MenuItemIconColor);
                    setMenuItemIconColorHover(themeJSON.MenuItemIconColorHover);
                    setMenuItemMarkerColor(themeJSON.MenuItemMarkerColor);
                }
                

                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        let newHTML: any = "";
        newHTML = createContentBySelectedContent(selectedSectionTitle);
        setContentHTML(newHTML);

    }, [selectedSectionTitle, menuItemBgColor, menuItemIconColor, menuItemTextColor, leftNavbarBgColor, headerBgColor, menuItemBgColorHover, menuItemTextColorHover, menuItemIconColorHover, menuItemMarkerColor, isSelectedResult, onMouseEnterSection])


    useEffect(() => {
        getTheme();
    }, [])
    return (
        <div>
            {isLoading ? <Loader size={"big"} active inline='centered'/> :
                <div>

                    <Header as={"h3"} content={translate("Tema Ayarları")}/>

                    <div style={styles.themeContainer}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Header style={styles.selectedSectionTitleStyle} as={"h3"} content={translate("Ön İzleme")}/>
                            <div style={styles.container}>
                                <div
                                    onMouseEnter={(e) => {
                                        handleOnMouseEnter("header", e)
                                    }}
                                    onMouseLeave={handleOnMouseLeave}
                                    onClick={(e) => {
                                        handleSelectedSection("header", e)
                                    }}
                                    style={styles.header}
                                ></div>
                                <div
                                    onMouseEnter={(e) => {
                                        handleOnMouseEnter("leftNavbar", e)
                                    }}
                                    onMouseLeave={handleOnMouseLeave}
                                    onClick={(e) => {
                                        handleSelectedSection("leftNavbar", e)
                                    }}
                                    style={styles.leftNavbar}>
                                    <div
                                        onMouseEnter={(e) => {
                                            handleOnMouseEnter("leftNavbar", e)
                                        }}
                                        onMouseLeave={handleOnMouseLeave}
                                        onClick={(e) => {
                                            handleSelectedSection("leftNavbar", e)
                                        }}
                                        style={styles.logoContainer}>
                                        <img id="company_logo" width="90%" src={logoUrl} alt="company_logo" />
                                    </div>
                                    <div
                                        onMouseEnter={(e) => {
                                            handleOnMouseEnter("menuItem", e)
                                        }}
                                        onMouseLeave={handleOnMouseLeave}
                                        onClick={(e) => {
                                            handleSelectedSection("menuItem", e)
                                        }}
                                        style={styles.menuItem}
                                    >
                                        <Icon style={styles.menuItemIcon} size={"large"} name={"home"}/>
                                        <div>{translate("Anasayfa")}</div>
                                    </div>
                                    <div
                                        onMouseEnter={(e) => {
                                            handleOnMouseEnter("menuItemSelected", e)
                                        }}
                                        onMouseLeave={handleOnMouseLeave}
                                        onClick={(e) => {
                                            handleSelectedSection("menuItemSelected", e)
                                        }}
                                        style={styles.menuItemSelected}>
                                        <Icon style={styles.menuItemIcon} size={"large"} name={"newspaper"}/>
                                        <div>{translate("Modüller")}</div>
                                    </div>
                                    <div
                                        onMouseEnter={(e) => {
                                            handleOnMouseEnter("leftNavbar", e)
                                        }}
                                        onMouseLeave={handleOnMouseLeave}
                                        onClick={(e) => {
                                            handleSelectedSection("leftNavbar", e)
                                        }}
                                        style={styles.forSelectedLeftNavbar}
                                    ></div>

                                </div>
                            </div>
                        </div>
                        <div style={styles.selectedSectionContentContainer}>
                            <div>
                                <Header style={styles.selectedSectionTitleStyle} as={"h3"}
                                    content={selectedSectionTitle}
                                />
                                <div>
                                    {contentHTML}
                                </div>
                            </div>

                            <div style={styles.saveButton}>
                                <Button className={"roundedButton"} onClick={resetThemeSettings}>{translate("Sıfırla")}</Button>
                                <Button
                                    className={"roundedButton"}
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={saveTheme}
                                    primary
                                >
                                    {translate("Kaydet")}
                                </Button>
                            </div>


                        </div>
                    </div>
                </div>
            }
        </div>


    );
};

export default ThemeSettings;