import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Icon, Label, Progress, TextArea, Transition } from 'semantic-ui-react';
import parse from "html-react-parser";
import "./QuestionCard.css";
import { NetworkRequest } from '../../helper/NetworkRequest';
import {checkFieldsIsFilled, getRandomIntInclusive01, scrollToTop} from '../../helper/HelperFunctions';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import ReportSurveyQuestionList from '../ReportSurveyQuestionList';
import { isMobile } from 'react-device-detect';
import TranslateHelper from '../../helper/TranslateHelper';
import { v4 as uuidv4 } from 'uuid';
import { DataHandler } from '../../helper/DataHelper';

interface IActiveClasses {
    activeInfoBox: boolean;
    activeSuryeyBox: boolean;
    activeResultBox: boolean;
}

interface ISurveyQuestion {
    Id: string,
    Body: any,
    A: string,
    B: string,
    C: string,
    D: string,
    E: string,
    surveyQuestionType: number,
    Rank: number
}

interface IQuestionView {
    id: string,
    questionJson: ISurveyQuestion,
    rank: number
}

interface IQuestionAnswer {
    surveyId: string;
    surveyQuestionId: string;
    moduleId: string;
    courseContent: string;
    userEmail: string;
    userId: string;
    isCorrect: boolean;
    noAnswer: boolean;
    createdDate: Date;
}

function RenderContentSuryey(props) {
    var { translate } = TranslateHelper();
    const [surveySessionId, setSuryeySessionId] = useState("");
    const [localProps] = useState(props);
    const [canBack, setCanBack] = useState(false);
    const [loginUserId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [transactionUserId] = useState(props.transactionUserId);
    const [surveyId, setSuryeyId] = useState(props.content.data.contentSurveyResponse.survey.id);
    const [moduleId, setModuleId] = useState(props.moduleId);
    const [sectionContentId, setSectionContentId] = useState(props.content.id);
    const [surveySessions, setSuryeySessions] = useState<any[]>([]);
    const [lastSurveySession, setLastSurveySession] = useState<any[]>([]);
    const [lastSurveySessionAnswers, setLastSurveySessionAnswers] = useState<any[]>([]);
    const [showLastSurveySessionAnswers, setShowLastSurveySessionAnswers] = useState(false);
    const [showConfirmEnd, setShowConfirmEnd] = useState(false);
    const [visibleResult, setVisibleResult] = useState(false);
    const [content, setContent] = useState(props.content);
    const [survey, setSuryey] = useState<any>({});
    const [question, setQuestion] = useState<ISurveyQuestion>({
        Id: "",
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        Rank: 0,
        surveyQuestionType: 0
    });
    const [questionList, setQuestionList] = useState<any[]>([])

    const [answer, setAnswer] = useState("");
    const shuffle = (array) => {
        let currentIndex = array.length;
        let randomIndex;

        while (currentIndex != 0) {
            randomIndex = Math.floor(getRandomIntInclusive01() * currentIndex);
            currentIndex = currentIndex - 1;

            let currentItem = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = currentItem;

        }

        return array;
    }
    const [notRandomOptions] = useState(["A", "B", "C", "D", "E"]);
    const [options, setOpitons] = useState(["A", "B", "C", "D", "E"]);
    const [questionAnswerList, setQuestionAnswerList] = useState<Array<IQuestionAnswer>>(
        []
    )

    const [activeClasses, setActiveClasses] = useState<IActiveClasses>(
        { activeInfoBox: true, activeSuryeyBox: false, activeResultBox: false }
    );

    useEffect(() => {
        try {
            setContent(localProps.content);
            let surveyData = localProps.content.data.contentSurveyResponse.survey;
            if (surveyData == undefined)
                return
            setSuryeyId(surveyData.id)
            surveyData.settingsJsonModel = JSON.parse(surveyData.settingsJson);
            setSuryey(surveyData);

            let questionListData = localProps.content.data.contentSurveyResponse.surveyQuestionList;
         
            questionListData.map((question, i) => {
                question.questionJson =
                    typeof question.questionJson == 'string' ?
                        JSON.parse(question.questionJson)
                        : question.questionJson;
            });
            setQuestionList(questionListData);

            if (surveyData.settingsJsonModel.shuffleQuestionOrder)
                setQuestionList(shuffle(questionListData));


            props.setContentLoading(false);
        } catch (e) {
            ////console.log(e)
        }
    }, [localProps]);


    const addOrUpdate = (question) => {
  
        let userAnswer = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            userAnswer: answer,
            surveyId: surveyId,
            surveyQuestionId: question.id,
            moduleId: moduleId,
            sectionContentId: content.id,
            surveySessionId: surveySessionId
        };
        NetworkRequest("/SurveyAnswer/AddOrUpdate", userAnswer).then(response => {
            if (response.data.resultCode === 0) {

            }
        });
    }

    const didUserAnswerBefore = (question, sessionId?: string) => {
        if (!checkFieldsIsFilled(transactionUserId != "" ? transactionUserId : loginUserId, surveyId, moduleId, sectionContentId))
            return;

        if (!(checkFieldsIsFilled(surveySessionId) || checkFieldsIsFilled(sessionId)))
            return;

        let userAnswer = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            surveyId: surveyId,
            surveyQuestionId: question.id,
            moduleId: moduleId,
            sectionContentId: content.id,
            surveySessionId: surveySessionId
        };
   
        NetworkRequest("/SurveyAnswer/DidUserAnswerBefore", userAnswer).then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                if (result != null) {
                    setAnswer(result.userAnswer);
                }
            }
        });
    }

    const onComplete = (isComplete) => {
        props.onComplete()
    }

    const reviewQuestions = () => {
        const q = questionList[0];
        setCanBack(false);
    
        setQuestion({
            Id: q?.id,
            Body: renderSurveyByType(q.questionJson.Body),
            A: q.questionJson.A,
            B: q.questionJson.B,
            C: q.questionJson.C,
            D: q.questionJson.D,
            E: q.questionJson.E,
            Rank: 0,
            surveyQuestionType: q.surveyQuestionType
        });

        didUserAnswerBefore(q);
    }

    const endsurvey = (isEnd) => {

        if (isEnd) {
            showResultAndEndSuryey();
        } else {
            reviewQuestions();
        }
    }

    const showResultAndEndSuryey = () => {
        props.setIsFocusContent(false)
        setActiveClasses({ ...activeClasses, activeInfoBox: false, activeSuryeyBox: false, activeResultBox: true });

        onComplete(true);
        setSessionCompleted(true);
        setAnswer("");
    }

    const getOldSessions = (userId) => {
        let surveySession = {
            userId: userId,
            surveyId: surveyId,
            sectionContentId: sectionContentId,
            moduleId: moduleId
        }

        NetworkRequest("/api/SurveySession/GetSurveySessions", surveySession).then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setSuryeySessions(result);
                let lstSession = [result[result.length - 1]];

                setLastSurveySession(lstSession);
               
                if (lstSession != undefined && lstSession.length > 0 && lstSession[0] != undefined && lstSession[0].isComplated) {
                    setVisibleResult(true);
                } else {
                    setVisibleResult(false);

                }
            }
        });
    }

    const getSessionAnswers = (sessionId) => {
        let data = {
            id: sessionId
        };
        NetworkRequest("/api/SurveySession/GetSessionAnswers", data).then(
            response => {
                if (response.data.resultCode === 0) {
                    let result = response.data.detail;
                    setLastSurveySessionAnswers(result);
                    setShowLastSurveySessionAnswers(true);
                }
            }
        );
    }

    const createSession = () => {
        let surveySession = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            surveyId: surveyId,
            sectionContentId: sectionContentId,
            moduleId: moduleId
        }

        NetworkRequest("/api/SurveySession/Add", surveySession).then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setSuryeySessionId(result.id);
                setVisibleResult(false);

            }
        });
    }


    const setSessionCompleted = (isSuccess: boolean) => {
        let surveySession = {
            id: surveySessionId
        }
        NetworkRequest("/api/SurveySession/SetCompleted", surveySession).then((response) => {
            if (response.data.resultCode === 0) {
                setVisibleResult(true);
            }
        });
    }

    const startBtn = () => {

        //Appointment of Scroll at the top when the content changes
        scrollToTop();

        setShowLastSurveySessionAnswers(false);
        createSession();
        setActiveClasses({ ...activeClasses, activeInfoBox: false, activeSuryeyBox: true });
        const q = questionList[question.Rank];
        //didUserAnswerBefore(q);

        setQuestion({
            Id: q?.id,
            surveyQuestionType: q.surveyQuestionType,
            Body: renderSurveyByType(q.questionJson.Body),
            A: q.questionJson.A,
            B: q.questionJson.B,
            C: q.questionJson.C,
            D: q.questionJson.D,
            E: q.questionJson.E,
            Rank: 0
        })
    }

    const renderSurveyByType = (questionBody) => {
     
        return typeof (questionBody) == "object" ? questionBody?.embedValue : question.Body;
    }

    const continueSuryeySession = (id) => {
        props.setIsFocusContent(true)
        setSuryeySessionId(id);

        setActiveClasses({ ...activeClasses, activeInfoBox: false, activeSuryeyBox: true });

        const q = questionList[question.Rank];
     
        setQuestion({
            Id: q?.id,

            Body: renderSurveyByType(q.questionJson.Body),
            A: q.questionJson.A,
            B: q.questionJson.B,
            C: q.questionJson.C,
            D: q.questionJson.D,
            E: q.questionJson.E,
            Rank: 0,
            surveyQuestionType: q.surveyQuestionType
        })
        didUserAnswerBefore(q, id);
    }

    const nextQuestion = (isNext: boolean) => {

        if (survey.settingsJsonModel.dontForceForAnswer == false && isNext && answer == "") {
            toast(translate("Sorular Boş Geçilemez. Lütfen Soruyu Cevaplayınız!"), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 4000
            });
            return;
        }

        addOrUpdate(questionList[question.Rank]);

        if (isNext && (question.Rank == questionList.length - 1)) {
            showResultAndEndSuryey();
            return;
        }
        let rank = isNext ? question.Rank + 1 : question.Rank - 1
        setCanBack(rank > 0)

        const q = questionList[rank];
        if (q.surveyQuestionType == 4) {
            setOpitons(notRandomOptions);
        } else if (survey.settingsJsonModel.ShuffleAnswerOrder) {
            setOpitons(shuffle(options))
        }

        
       // let questionBody = renderQuizByType(q.questionJson.Body);
       // var div = document.createElement('div');
       // div.innerHTML = questionBody;
       // let images = div.getElementsByTagName('img');
       ///* let questionBody = q.questionJson.Body;*/
       // if (typeof images == "object") {
       //     for (let i = 0; i < images.length; i++) {
       //         images[i].setAttribute("style", "width:100%;");
       //     }
       //     questionBody = div.innerHTML;
       // }

       // let cloneQuestion = question;

       // cloneQuestion.Id = q.id;
       // cloneQuestion.Body = q.questionJson.Body;
       // cloneQuestion.A = q.questionJson.A;
       // cloneQuestion.B = q.questionJson.B;
       // cloneQuestion.C = q.questionJson.C;
       // cloneQuestion.D = q.questionJson.D;
       // cloneQuestion.E = q.questionJson.E;
       // cloneQuestion.Rank = rank;
       // cloneQuestion.surveyQuestionType = q.surveyQuestionType;

       // setQuestion(cloneQuestion);




       // //setQuestion({
       // //    Id: q.id,
       // //    Body: renderSurveyByType(q.questionJson.Body),
       // //    A: q.questionJson.A,
       // //    B: q.questionJson.B,
       // //    C: q.questionJson.C,
       // //    D: q.questionJson.D,
       // //    E: q.questionJson.E,
       // //    Rank: rank,
       // //    surveyQuestionType: q.surveyQuestionType
       // //})

       // setAnswer("");
       // didUserAnswerBefore(q);











        let questionBody = renderSurveyByType(q.questionJson.Body);
        var div = document.createElement('div');
        div.innerHTML = questionBody;
        let images = div.getElementsByTagName('img');
        if (typeof images == "object") {
            for (let i = 0; i < images.length; i++) {
                images[i].setAttribute("style", "width:100%;");
            }

            questionBody = div.innerHTML;
        }

        let cloneQuestion = question;

        cloneQuestion.Id = q.id;
        cloneQuestion.Body = questionBody;
        cloneQuestion.A = q.questionJson.A;
        cloneQuestion.B = q.questionJson.B;
        cloneQuestion.C = q.questionJson.C;
        cloneQuestion.D = q.questionJson.D;
        cloneQuestion.E = q.questionJson.E;
        cloneQuestion.Rank = rank;
        cloneQuestion.surveyQuestionType = q.surveyQuestionType;

        setQuestion(cloneQuestion);

        setAnswer("");
        didUserAnswerBefore(q);
    }

    const closeSurveyDynamicIsland = () => {
        setActiveClasses({ ...activeClasses, activeResultBox: false });
    }



    let styles = {
        sesionDescription: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem"
        },
        header: {
            textAlign: "center"
        },
        startBox: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            gap: "1rem"
        },

        contentSettingHeader: {
            width: "10%",
            fontWeight: "700",
            alignItems: "center" as "center"
        },
        contentSettingDescription: {
            width: "90%"
        },
        contentDescriptionStyle: {
            borderRadius: "8px",
            backgroundColor: "rgba(251, 251, 251, 0.6)",
            width: "100%",
            minHeight: "100px"
        },

        contentTimingDetailBox: {
            borderRadius: "5px",
            border: "1px #E9E9E9 solid",
            width: "70%",
            padding: ".5rem 1rem"
        },
        contentTimingHeader: {
            width: "30%",
            flexWrap: "wrap" as "wrap",
            fontWeight: "700"
        },
        contentContainer: {
            display: "flex",
            flexDirection: "column " as "column",
            height: "auto"
        },
        surveyBtnsContainer: {
            width: "100%",
            marginTop: "2rem",
            minHeight: "53px",
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: " flex-end",
            gap: "1rem",
            marginBottom: "1rem"
        },
        btnDarkOutline: style({
            width: "228px",
            background: "rgb(255,255,255)",
            right: "350px",
            top: "700px",
            border: "1px solid #292828 !important",
            boxSizing: "border-box",
            borderRadius: "8px",
            padding: "10px 0px !important",
            cursor: "pointer"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
        btnDark: style({
            width: "228px",
            right: "0px",
            top: "700px",
            backgroundColor: "rgb(41, 40, 40) !important",
            borderRadius: "8px",
            color: "white !important",
            padding: "10px 0px !important",
            cursor: "pointer"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
        surveyContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column"
        },
        questionContainer: {
            padding: "1rem .75rem",
            fontSize: "14px",
            display: "flex"
        },
        optionsContainer: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            gap: "1rem",
            background: "rgba(251,251,251,0.6)",
            borderRadius: "8px",
            padding: "2rem .75rem",
            marginTop: "20px",
            margin: "0 1rem"
        },
        option: {
            width: "95%",
            height: "40px",
            left: "233px",
            top: "381px",
            background: "rgba(240,240,240, 0.6)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "flex-start",
            color: "#292828",
            gap: "1rem"
        },
        questionExplorerBtnsContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            width: "100%",
            justifyContent: "center",
            gap: "1em",
            padding: "2rem .75rem"
        },
        prevBtn: {
            background: "rgb(41, 40, 40)",
            borderRadius: "8px",
            color: "white",
            width: "228px",
            height: "53px",

        },
        nextBtn: {
            background: "rgb(41, 40, 40)",
            borderRadius: "8px",
            color: "white",
            width: "228px",
            height: "53px",

        },
        confirmEndContainer: {
            width: "100%",
            height: "100em",
            position: "absolute" as "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            top: "0",
            left: "0",
            padding: "1.5rem .75rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column" as "column",
            zIndex: "100"
        },
        confirmEnd: style({
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            width: "300px",
            minHeight: "120px",
            position: "absolute" as "absolute",
            top: "25%",
            background: "rgba(255,255,255,1)",
            boxShadow: "8px 4px 10px rgba(187, 187, 187, 0.25)",
            gap: "20px",
            padding: "1rem 0",
            justifyContent: "space-evenly"
        },
            media({ maxWidth: 820 }, { width: "90%" })
        ),
        confirmBtnsContainer: {
            height: "53px",
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "center",
            gap: "1rem",
            alignItems: "center",
            width: "100%",
            padding: "0 1rem",

        },
        processBar: {
            width: "349px",
            height: "26px",
            left: "550px",
            top: "482px",

            display: "flex"
        },
        successRate: {
            backgroundColor: "rgba(44, 183, 135, 0.6)",
            height: "100%",
            borderRadius: "4px 0 0 4px",

        },
        failRate: {
            backgroundColor: "rgba(252, 72, 72,0.6)",
            height: "100%",
            borderRadius: "0 4px 4px 0",
        },
        questionRateContainer: {
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "center"
        },
        trueAnswersBox: {
            width: "160px",
            height: "75px",
            left: "555px",
            top: "640px",
            border: "1px solid #E9E9E9",

            borderRadius: "4px",
            color: "#2CB787",
            fontSize: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        falseAnswersBox: {
            width: "160px",
            height: "75px",
            left: "555px",
            top: "640px",
            border: "1px solid #E9E9E9",

            borderRadius: "4px",
            color: "#FC4848",
            fontSize: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        timeContainer: {
            color: "#F47820",
            fontSize: "20px",
            textWeight: "900",
            justifyContent: "space-between"
        },
        closeBtnStyle: {
            background: "rgba(255,255,255,1)",
            boxShadow: "0px 4px 4px rgba(160, 160, 160, 0.25)",
            position: "absolute" as "absolute",
            right: "-12px",
            top: "-12px",
            borderRadius: "100%",
            height: "48px",
            width: "48px",
            display: "flex",
            padding: "12px",
            cursor: "pointer"
        },
        contentSettingsContainer: style({
            width: "100%",
            display: "flex",
            padding: ".25rem 2rem",
            marginBottom: "2rem",
            alignItems: "center",
            flexDirection: "row" as "row",
            gap: "1rem"

        },
            media({ maxWidth: 820 }, { flexDirection: "column" })
        ),
        contentTimingContainer: style({
            width: "50%",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center"
        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        textArea: {
            width: "100%",
            height: "220px",
            background: "rgba(240, 240, 240, 0.6)",
            borderRadius: "8px",
            padding: "24px 31px",

            fontSize: "14px",
            lineHeight: "16px",
            color: "#6C6D6E",
            border: "0",
            resize: "none"
        },
        alignRight: style({},
            media({ minWidth: 820 }, {
                textAlign: "right" as "right",
                paddingRight: "1rem",
                textIndent: "2rem",
                width: "20%"
            })
        ),
        transparent: {
            background: 'rgba(255,255,255,.60)'
        },

    }

    useEffect(() => {
        if (transactionUserId != "" ? transactionUserId : loginUserId != "")
            getOldSessions(transactionUserId != "" ? transactionUserId : loginUserId);
    }, [loginUserId]);

    return (
        <div>
            {(questionList.length > 0) &&
                <div>
                    <div>
                        {activeClasses.activeInfoBox &&
                            <>
                                {props.showContentInfo
                                    &&
                                    <div style={styles.startBox}>
                                        <div style={{ ...styles.contentContainer, width: "100%" }}>
                                            <div
                                                className={"renderContentEventContainer " + styles.contentSettingsContainer}>
                                                <div
                                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                                    <div
                                                        className={"renderContentSettingHeaderContainer"}
                                                        style={{ ...styles.contentTimingHeader }}>
                                                        {translate("Soru Sayısı")}
                                                    </div>
                                                    <span style={{ width: "100%" }}>
                                                        {questionList.length}
                                                    </span>
                                                </div>
                                                <div
                                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                                    <div
                                                        style={{ ...styles.contentTimingHeader }}
                                                        className={"renderContentSettingHeaderContainer " + styles.alignRight}>
                                                        {translate("Tekrar Hakkı")}
                                                    </div>
                                                    <span style={{ width: "100%" }}>
                                                        {survey.settingsJsonModel.canRepeat ?
                                                            <>{translate("Anketi tekrar etme hakkınız var.")}</> :
                                                            <><b>{translate("Bu anket tekrar edilemez.")}</b> </>
                                                        }
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </>
                        }
                        <Transition visible={(activeClasses.activeSuryeyBox && props.isLocked != true)}
                            animation='scale' duration={500}>
                            <div
                                className={"surveyQuestionAllContainer"}
                                style={styles.surveyContainer}>
                                <div style={{ ...styles.questionContainer, justifyContent: "space-between" }}>
                                    <Grid columns={1}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Label style={{ marginBottom: "1%" }} color="blue" size="large" circular={true}>
                                                    <span
                                                        style={{ padding: ".5em" }}
                                                    >{translate("Soru")} {question.Rank + 1}/{questionList.length}
                                                    </span>
                                                </Label>

                                                <div className={"renderContentSurveyImage"}>
                                                    {parse(question?.Body)}
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                                <div style={styles.optionsContainer}>
                                    {(question.surveyQuestionType == 1 || question.surveyQuestionType == 4) ?

                                        <>
                                            {options.slice(0, question.surveyQuestionType == 1 ? 5 : 2).map((option, i) => (

                                                <div key={uuidv4()} style={{ width: "100%" }}>
                                                    {!isMobile &&
                                                        <div className={"renderContentSurveyOptionContainer"}>

                                                            <div
                                                                style={{
                                                                    backgroundColor: answer != option ? "#e7e7e799" : "rgb(109, 220, 135)"
                                                                }}
                                                                className={"renderContentQuestionOptionMobileContainer"}
                                                                onClick={() => setAnswer(option)}
                                                            >
                                                                <b className={"renderContentQuestionOptionTextMobile"}>{notRandomOptions[i]} </b>
                                                                {/*<img*/}

                                                                {/*    width="22"*/}
                                                                {/*    height="22"*/}
                                                                {/*    src={answer != option ? "/files/images/icons/option-non-selected.svg" : "/files/images/icons/option-selected.svg"}*/}
                                                                {/*/>*/}
                                                                <span>{question[option]}</span>
                                                            </div>
                                                        </div>

                                                    }

                                                    {isMobile &&
                                                        <div className={"renderContentSurveyOptionContainer"}>

                                                            <div
                                                                style={{
                                                                    backgroundColor: answer != option ? "#e7e7e799" : "rgb(109, 220, 135)"
                                                                }}
                                                                className={"renderContentQuestionOptionMobileContainer"}
                                                                onClick={() => setAnswer(option)}
                                                            >
                                                                <b className={"renderContentQuestionOptionTextMobile"}>{notRandomOptions[i]} </b>
                                                                {/*<img*/}

                                                                {/*    width="22"*/}
                                                                {/*    height="22"*/}
                                                                {/*    src={answer != option ? "/files/images/icons/option-non-selected.svg" : "/files/images/icons/option-selected.svg"}*/}
                                                                {/*/>*/}
                                                                <span>{question[option]}</span>
                                                            </div>
                                                        </div>

                                                    }

                                                </div>

                                            )
                                            )
                                            }


                                        </> :
                                        <div style={{ width: "100%" }}>
                                            <TextArea size="big" value={answer}
                                                placeholder={translate("Cevap Yaz")}
                                                style={styles.textArea}
                                                onChange={(e) => setAnswer(e.target.value)} />
                                        </div>
                                    }
                                </div>
                                <div style={styles.questionExplorerBtnsContainer}>

                                    <Button
                                        circular={true}
                                        size="big"
                                        className={"disabledGreyButton"}
                                        disabled={!(canBack && survey.settingsJsonModel.navigateThroughQuestions)}
                                        onClick={() => {
                                            nextQuestion(false);
                                            scrollToTop();
                                        }}
                                        content={translate("Önceki Soru")}
                                    />

                                    <Button
                                        circular={true}
                                        size="big"
                                        color={(question.Rank == questionList.length - 1) ? "green" : "blue"}
                                        // className={(question.Rank == questionList.length - 1) ? "positiveGreenButton" : "continueBlueButton"}
                                        onClick={() => {
                                            nextQuestion(true);
                                            scrollToTop();
                                            // props.setIsOpenTaskContentForMobile(true);
                                        }}
                                        content={(question.Rank == questionList.length - 1) ? translate("Bitir") : translate("Sonraki Soru")} />
                                </div>
                            </div>
                        </Transition>

                        {/*<!-- Result Box -->*/}

                        <Transition
                            visible={(activeClasses.activeResultBox && props.isLocked != true)}
                            animation='drop'
                            duration={300}
                            onComplete={() => {
                                setTimeout(() => {
                                    closeSurveyDynamicIsland()
                                }, 3000)
                            }}
                        >
                            <div>

                                {isMobile &&
                                    <div className={"dynamicIslandToatsContainer"}>
                                        <div className={"dynamicIslandContent"}>
                                            <div>
                                                <Icon
                                                    color="green"
                                                    name="check circle outline"
                                                    size="massive"
                                                />
                                            </div>
                                            <div className={"dynamicIslandContentText"}>
                                                {translate("Tamamlandı")}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {!isMobile &&

                                    // <div style={styles.confirmEndContainer}>
                                    //     <div className={styles.confirmEnd}
                                    //          style={{width: "500px", height: "200px", top: "40%"}}>
                                    //         <img width="60" height="60" src={"/files/images/icons/checkmark.svg"}/>
                                    //         <span style={{fontSize: "30px"}}><b>Anket Tamamlandı!</b></span>
                                    //         <div style={styles.closeBtnStyle}
                                    //              onClick={() => {
                                    //                  setActiveClasses({
                                    //                      ...activeClasses,
                                    //                      activeInfoBox: true,
                                    //                      activeResultBox: false
                                    //                  });
                                    //                  getOldSessions(transactionUserId != "" ? transactionUserId : loginUserId)
                                    //              }}
                                    //         >
                                    //             <img width="24" height="24" src={"/files/images/icons/close.svg"}/>
                                    //         </div>
                                    //     </div>
                                    // </div>

                                    <div className={"dynamicIslandToatsContainer"}>
                                        <div className={"dynamicIslandContent"}>
                                            <div>
                                                <Icon
                                                    color="green"
                                                    name="check circle outline"
                                                    size="massive"
                                                />
                                            </div>
                                            <div className={"dynamicIslandContentText"}>
                                                {translate("Tamamlandı")}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Transition>
                        {showConfirmEnd && props.isLocked != true &&
                            <div style={styles.confirmEndContainer}>
                                <div className={styles.confirmEnd}>
                                    <span style={{ fontSize: "18px" }}><b>{translate("Anket Bitirilecek")}</b></span>
                                    <span style={{ fontSize: "18px" }}>{translate("Emin Misiniz?")}</span>
                                    <div style={styles.confirmBtnsContainer}>
                                        <Button
                                            color="green"
                                            circular
                                            onClick={() => {
                                                setShowConfirmEnd(false);
                                                endsurvey(true)
                                            }}
                                            content={translate("Bitir")}
                                        />
                                        <Button
                                            color="green"
                                            circular
                                            onClick={() => {
                                                setShowConfirmEnd(false);
                                                endsurvey(false)
                                            }}
                                            content={translate("Soruları Gözden Geçir")}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {visibleResult && lastSurveySession[0] != undefined && props.isLocked != true &&
                            lastSurveySession.map((surveySession, index) => (
                                <div key={uuidv4()}
                                    className={"renderContentEventContainer " + styles.contentSettingsContainer}
                                    style={{ alignItems: "stretch", flexDirection: "column" }}
                                >
                                    {surveySession != undefined && surveySession.isComplated == true
                                        ?
                                        <>
                                            {isMobile
                                                &&
                                                <div
                                                    className={"renderContentContentHeader renderContentContainerFlex"}
                                                    style={{
                                                        backgroundColor: "#ceedd094"
                                                    }}
                                                >
                                                    <span>
                                                        <p className={"renderContentSettingHeaderContainer"}>
                                                            {translate("Anket tamamlandı")}
                                                        </p>
                                                    </span>

                                                    <span className={"progressNotRadius"}>
                                                        <Progress
                                                            size="large"
                                                            progress
                                                            className={"userDashboardProgressPercentage"}
                                                            indicating
                                                            percent={(surveySession.answeredCount / surveySession.questionCount) * 100}

                                                        />
                                                    </span>
                                                    {survey.settingsJsonModel.showAnswers == true
                                                        &&
                                                        <Button
                                                            style={{ marginTop: "1rem" }}
                                                            className={
                                                                showLastSurveySessionAnswers
                                                                    ? "dangerRendButton"
                                                                    : "continueBlueButton"
                                                            }
                                                            onClick={() => {

                                                                if (showLastSurveySessionAnswers) {
                                                                    setShowLastSurveySessionAnswers(false);
                                                                    return;
                                                                }

                                                                getSessionAnswers(surveySession.id)
                                                            }}
                                                        >
                                                            {showLastSurveySessionAnswers
                                                                ?
                                                                translate("Cevapları Gizle")
                                                                :
                                                                translate("Cevapları Getir")
                                                            }
                                                        </Button>
                                                    }
                                                </div>
                                            }

                                            {!isMobile
                                                &&
                                                <div style={{ backgroundColor: "#ceedd094" }}
                                                    className={"renderContentSurveyInfoBox"}>
                                                    <span><p style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                                                        {translate("Anket tamamlandı")}
                                                    </p>   </span>

                                                    <span className={"progressNotRadius"}>
                                                        <Progress
                                                            size="large"
                                                            progress
                                                            className={"userDashboardProgressPercentage"}
                                                            indicating
                                                            percent={(surveySession.answeredCount / surveySession.questionCount) * 100}

                                                        />

                                                    </span>

                                                    {(survey.settingsJsonModel.showAnswers == true) &&
                                                        <Button
                                                            size={"big"}
                                                            style={{ top: "1rem", width: "170px" }}
                                                            className={showLastSurveySessionAnswers ? "warningYellowButton" : "continueBlueButton"}
                                                            onClick={() => {

                                                                if (showLastSurveySessionAnswers) {
                                                                    setShowLastSurveySessionAnswers(false);
                                                                } else {
                                                                    getSessionAnswers(surveySession.id);
                                                                }
                                                            }}>
                                                            {showLastSurveySessionAnswers ? translate("Cevapları Gizle") : translate("Cevapları Getir")}
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            {!isMobile &&
                                                <div style={{ backgroundColor: "#ffeba95c" }}
                                                    className={"renderContentSurveyInfoBox"}>
                                                    <p style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                                                        {translate("Bu anketi yarıda bıraktınız")}
                                                    </p>

                                                    <span className={"progressNotRadius"}>
                                                        <Progress
                                                            size="large"
                                                            progress
                                                            className={"userDashboardProgressPercentage"}
                                                            indicating
                                                            percent={(surveySession.answeredCount / surveySession.questionCount) * 100}
                                                        />
                                                    </span>

                                                </div>
                                            }

                                            {isMobile &&
                                                <div
                                                    className={"renderContentContentHeader renderContentContainerFlex"}
                                                    style={{
                                                        backgroundColor: "#ffeba95c"
                                                    }}
                                                >
                                                    <span><p className={"renderContentSettingHeaderContainer"}>
                                                        {translate("Bu anketi yarıda bıraktınız")}
                                                    </p>   </span>

                                                    <span className={"progressNotRadius"}>
                                                        <Progress
                                                            size="large"
                                                            progress
                                                            className={"userDashboardProgressPercentage"}
                                                            indicating
                                                            percent={(surveySession.answeredCount / surveySession.questionCount) * 100}
                                                        />
                                                    </span>

                                                    {(lastSurveySession[0] != undefined && lastSurveySession[0].isComplated == false) &&
                                                        <Button
                                                            circular={true}
                                                            className={"warningYellowButton"}
                                                            size={"big"}
                                                            onClick={() => {
                                                                setSuryeySessionId(lastSurveySession[0].id);
                                                                continueSuryeySession(lastSurveySession[0].id);
                                                            }}>
                                                            {translate("Ankete Devam Et")}
                                                        </Button>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            ))
                        }

                        {activeClasses.activeInfoBox && props.isLocked != true
                            &&
                            <div style={styles.surveyBtnsContainer}>
                                {(lastSurveySession[0] == undefined || survey.settingsJsonModel.canRepeat)
                                    &&
                                    <Button
                                        circular={true}
                                        color="green"
                                        onClick={startBtn}
                                        content={translate("Başla")}
                                    />
                                }
                                {lastSurveySession[0] != undefined && lastSurveySession[0].isComplated == false && !isMobile
                                    &&
                                    <Button
                                        circular={true}
                                        size={"big"}
                                        className={"warningYellowButton"}
                                        onClick={() => {
                                            setSuryeySessionId(lastSurveySession[0].id);
                                            continueSuryeySession(lastSurveySession[0].id);
                                        }}>
                                        {translate("Ankete Devam Et")}
                                    </Button>
                                }
                            </div>
                        }
                        <Transition
                            visible={showLastSurveySessionAnswers && !props.isLocked}
                            animation='scale'
                            duration={500}
                        >
                            <div>
                                <ReportSurveyQuestionList questions={lastSurveySessionAnswers} />
                            </div>
                        </Transition>
                    </div>
                </div>
            }
        </div>
    );
}

export default RenderContentSuryey;