import * as React from 'react';
import { useState, useEffect } from 'react';
import { Dropdown, Checkbox, Button, Form, TextArea } from 'semantic-ui-react'
import { ITest } from '../../../interfaces/interfaces';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';


const AddQuiz = (props) => {
    var {translate} = TranslateHelper();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [userId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [test, setTest] = useState<ITest>({
        title: "",
        description: "",
        time: 0,
        successScore: 0,
        settings: {
            shuffleQuestionOrder: false,
            shuffleAnswerOrder: false,
            repeatAnswer: {
                isActive: false,
                numberOfRepetitions: 0,
                type: 1
            },
            showPoint: false,
            showCorrectAnswer: {
                isActive: false,
                type: 1
            },
            navigateThroughQuestions: false
        },
    });

    const [shuffleQuestionOrder, setShuffleQuestionOrder] = useState(false);
    const [shuffleAnswerOrder, setShuffleAnswerOrder] = useState(false);
    const [showPoint, setShowPoint] = useState(false);
    const [navigateThroughQuestions, setNavigateThroughQuestions] = useState(false);
    const [repeatSettings, setRepeatSettings] = useState({
        isActive: false,
        numberOfRepetitions: 0,
        type: 1
    });

    const [showCorrectAnswerSettings, setShowCorrectAnswerSettings] = useState({
        isActive: false,
        type: 1
    });
    const [numberOfRepetitions, setNumberOfRepetitions] = useState(0);

    const allowRepeatAnswerOption = [
        { key: 1, text: translate('Kapalı'), value: 1 },
        { key: 2, text: translate('Sınırlı Tekrar Sayısı'), value: 2 },
        { key: 3, text: translate('Başarılı Olana Kadar'), value: 3 }

    ]

    const showCorrectAnswerFinalOption = [
        { key: 1, text: translate('Kapalı'), value: 1 },
        { key: 2, text: translate('Her zaman'), value: 2 },
        { key: 3, text: translate('Başarılı olduğunda'), value: 3 }
    ]

    const handleChange = (e) => {
        setTest({
            ...test,
            [e.target.name]: e.target.value
        });
    }

    const save = (isSaveAndAddQuestion?) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            test.settings.navigateThroughQuestions = navigateThroughQuestions;
            test.settings.shuffleAnswerOrder = shuffleAnswerOrder;
            test.settings.shuffleQuestionOrder = shuffleQuestionOrder;
            test.settings.showPoint = showPoint;
            test.settings.repeatAnswer = repeatSettings;
            test.settings.showCorrectAnswer = showCorrectAnswerSettings;

            setTest(test);

            let testData = {
                title: test.title.trim(),
                description: test.description.trim(),
                successScore: parseInt(test.successScore.toString()),
                time: parseInt(test.time.toString()),
                settings: JSON.stringify(test.settings),
                userId: userId,
                searchField: "searchfield",
                tenantId: tenantId
            };

            ////console.log(testData);

            NetworkRequest("/Quiz/Add", testData).then(response => {
                ////
                if (response.data.resultCode === 0) {
                    if (isSaveAndAddQuestion == 2) {
                        window.location.href = "/questionAdd/" + response.data.detail.id;
                    } else if (isSaveAndAddQuestion == 3) {
                        window.location.href = "/quizContentPool/" + response.data.detail.id;
                    } else {
                        window.location.href = "/quizes";
                    }
                    toast(translate("Sınav başarılı bir şekilde kaydedildi."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                } else {
                    toast(translate(response.data.message), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    let styles = {
        container: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column"
        },

        flexDiv: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "baseline",
            gap: "1em",
            fontWeight: "bold" as "bold"
        },

        w50: {
            width: "50%"
        },

        w60: {
            width: "60%"
        },
        w15: {
            width: "15%"
        },

        w30: {
            width: "30%"
        },
        w100: {
            width: "100%"
        },

        buttonGroup: {
            marginTop: "60px",
            alignSelf: "end"
        },
        form: {
            width: "100%",
            display: "flex",
            flexDirection: "row" as "row",
            gap: "2rem",
            paddingTop: "1% !important"
        }
    }

    const [isFormOk, setIsFormOk] = useState(false);
    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                test.successScore,
                test.title,
                test.time
            )
        );
        ////console.log(test);
    }, [test]);

    return (
        <div className={"generalContainerForWhiteBg"}>
        <div style={styles.container}>
            <Form size="big" style={styles.form}>
                <div style={styles.w60}>
                    <Form.Field>
                        <div style={styles.flexDiv}>
                            <label style={styles.w30}>{translate("Sınav Adı (*):")}</label>
                            <input
                                className={"greyInput"}
                                onChange={handleChange}
                                name="title"
                                style={styles.w100}
                                placeholder={translate("Sınav Adı")}
                                value={test.title}
                            />
                        </div>
                    </Form.Field>

                    <Form.Field>
                        <div style={styles.flexDiv}>
                            <label style={styles.w30}>{translate("Açıklama:")}</label>
                            <TextArea
                                className={"greyInput"}
                                onChange={handleChange}
                                name="description"
                                style={styles.w100}
                                placeholder={translate("Sınav Açıklaması")}
                                rows="12"
                                value={test.description}
                            />
                        </div>
                    </Form.Field>


                </div>
                <div>

                    <Form.Field>
                        <div style={styles.flexDiv}>
                            <label style={styles.w30}>{translate("Tekrara İzin Ver (*):")}</label>
                            <Dropdown
                                className={"greyInput"}
                                onChange={(e, { name, value }) => {
                                    repeatSettings.isActive = false;
                                    ////debugger;
                                    if (value != undefined && value >= 1) {
                                        setRepeatSettings({ ...repeatSettings, isActive: true, type: Number(value) })
                                    }
                                    else {
                                        setRepeatSettings({ ...repeatSettings, isActive: false, type: Number(1) })

                                    }
                                }
                                }
                                clearable
                                options={allowRepeatAnswerOption}
                                selection
                                placeholder={translate("Seçiniz")}
                                style={styles.w50}
                            />
                        </div>
                    </Form.Field>
                    {repeatSettings.type == 2 &&
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <label style={styles.w30}>{translate("Tekrar Sayısı:")}</label>
                                <input
                                    className={"greyInput"}
                                    value={numberOfRepetitions}
                                    type="number"
                                    min="1"
                                    onChange={e => {
                                        setNumberOfRepetitions(parseInt(e.target.value));
                                        repeatSettings.numberOfRepetitions = parseInt(e.target.value);
                                        setRepeatSettings(repeatSettings);
                                    }
                                    }

                                    placeholder={translate("Tekrar sayısı")}
                                    style={styles.w50}
                                />
                            </div>
                        </Form.Field>}


                    <Form.Field>
                        <div style={styles.flexDiv}>
                            <label style={styles.w30}>{translate("Süresi (dk) (*):")}</label>
                            <input
                                className={"greyInput"}
                                type="number"
                                onChange={handleChange}
                                name="time"
                                placeholder={translate("Süresi (dk)")}
                                min="0"
                                style={styles.w50}
                            />
                        </div>
                    </Form.Field>

                    <Form.Field>
                        <div style={styles.flexDiv}>
                            <label style={styles.w30}>{translate("Başarı Puanı (*):")}</label>
                            <input
                                className={"greyInput"}
                                type="number"
                                onChange={handleChange}
                                name="successScore"
                                style={styles.w50}
                                placeholder={translate("Başarı Puanı")}
                                min="0"

                            />
                        </div>
                    </Form.Field>
                    <Form.Field>
                        <div style={{ ...styles.flexDiv, alignItems: "center" }}>
                            <label style={styles.w30}>{translate("Sınav Sonunda Doğru Yanıtları Göster (*):")}</label>
                            <Dropdown
                                className={"greyInput"}
                                onChange={(e, { name, value }) => {
                                    showCorrectAnswerSettings.isActive = false;

                                    if (value != undefined && value > 1) {
                                        showCorrectAnswerSettings.isActive = true;
                                        showCorrectAnswerSettings.type = value == 2 ? 2 : 3;
                                    }
                                }}
                                options={showCorrectAnswerFinalOption}
                                clearable
                                selection
                                placeholder={translate("Seçiniz")}
                                style={styles.w50}
                            />
                        </div>
                    </Form.Field>

                    <div style={{marginTop: "7%"}}>
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={shuffleQuestionOrder}
                                    onChange={() => { setShuffleQuestionOrder(!shuffleQuestionOrder); }}
                                    toggle
                                    label={translate("Soru Sırasını Karıştır")}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={shuffleAnswerOrder}
                                    onChange={() => { setShuffleAnswerOrder(!shuffleAnswerOrder); }}
                                    toggle
                                    label={translate("Cevap Sırasını Karıştır")}
                                />
                            </div>
                        </Form.Field>

                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={showPoint}
                                    name="ShowPoint"
                                    onChange={() => { setShowPoint(!showPoint); }}
                                    toggle
                                    label={translate("Sınav Sonunda Puanı Göster")}
                                />
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div style={styles.flexDiv}>
                                <Checkbox
                                    checked={navigateThroughQuestions}
                                    name="NavigateThroughQuestions"
                                    onChange={() => { setNavigateThroughQuestions(!navigateThroughQuestions); }}
                                    toggle
                                    label={translate("Çalışan Sınav Esnasında Sorular Arasında Gezinebilsin")}
                                />
                            </div>
                        </Form.Field>
                    </div>
                    
                </div>

            </Form >
            <div style={styles.buttonGroup}>

                <Button
                    color={"blue"}
                    className={"roundedButton"}
                    onClick={() => { save(2) }}
                    type='submit'
                    disabled={!isFormOk}
                >
                    {translate("Kaydet ve Yeni Soru Ekle")}
                </Button>

                <Button
                    color={"orange"}
                    className={"roundedButton"}
                    onClick={() => { save(3) }}
                    type='submit'
                    disabled={!isFormOk}
                >
                    {translate("Kaydet ve Havuzdan Soru Seç")}
                </Button>
                <Button
                    color={"green"}
                    className={"roundedButton"}
                    onClick={() => { save(1) }}
                    disabled={!isFormOk}
                >
                    {translate("Kaydet")}
                </Button>
            </div>
        </div>
        </div>
    )
};

export default AddQuiz;