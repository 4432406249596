import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Input, Label, Icon, Dropdown, Transition, Loader, Table, Flag } from 'semantic-ui-react';

import { DataHandler } from '../../../../helper/DataHelper';
import { NetworkRequest } from '../../../../helper/NetworkRequest';
import { IAdmin, IDropdown, ILanguageDropdown } from '../../../../interfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { digitallicaConfirm } from '../../../../helper/HelperFunctions';
import { toast } from 'react-toastify';
import TranslateHelper from '../../../../helper/TranslateHelper';

const EditTenant = (props) => {
    var { translate } = TranslateHelper();
    const [parentTenantList, setParentTenantList] = useState<Array<IDropdown>>([]);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const tableHeader = ["Grup Admin", "Adı", "Kullanıcı Adı", "E-Mail", "İşlem"];
    const [isLoading, setIsLoading] = useState(true);
    const [admins, setAdmins] = useState<Array<IAdmin>>();
    //styles
    //styles
    let styles = {
        tableSection: {
            marginTop: "20px",

        },
        paginationBox: {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
        activeColor: {
            color: 'green'
        },
        passiveColor: {
            color: 'red'
        },
        inputContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "450px",
            alignItems: "center"
        },
        label: {
            minWidth: "130px",
            display: "flex",
            alignItems: "center",
            height: "70%"
        },
        input: {
            width: "202px"
        },
        row: {
            gap: "2rem",
            display: "flex",
            alignItems: "center"
        },
        formContainer: {
            gap: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start"
        }
    }

    const [tenant, setTenant] = useState(props.Tenant);

    useEffect(() => {
        setTenant(props.Tenant);
    }, [props.Tenant]);

    const updateHandler = () => {

        tenant.defaultLanguage = selectedDefaultLanguage;

        props.Update(tenant).then((response) => {
            //debugger;
            var result = response.data;
            if (result.resultCode == 0) {
                props.showList();
            } else {
                toast(result.message, {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
            }
        });
    }

    const getParentTenants = (filterData?) => {
        NetworkRequest("/api/Tenants/GetParentTenants", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let count = response.data.detail.length;
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response.data.detail.length; i++) {
                    list.push({
                        key: response.data.detail[i].tenantId,
                        value: response.data.detail[i].tenantId,
                        text: response.data.detail[i].displayName
                    });
                }
                setParentTenantList(list);
            }
        });
    }

    const changeDropdown = (event, data) => {
        setTenant({ ...tenant, parentTenantId: data.value == "" ? null : data.value });
    }


    const getActiveLanguages = () => {

        NetworkRequest("/api/Language/GetActiveLanguages", null)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;

                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: l.text,
                            value: l.value
                        }

                        //console.log(languageForDropdown);

                        setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                    })
                }
            });
    }

    const getAdmins = () => {

        NetworkRequest("/api/Tenants/GetAdmins", { id: tenant.tenantId })
            .then((response) => {
                if (response.data.resultCode == 0) {
                    var _admins: Array<IAdmin> = [];
                    response.data.detail.forEach(x => {
             
                        var isHeadAdmin = x.userRole.find(y => y.role.roleName == "HeadAdmin");
                        _admins.push(
                            {
                                id: x.id,
                                name: x.name + " " + x.surname,
                                userName: x.userName,
                                email: x.email,
                                isHeadAdmin: isHeadAdmin != undefined
                            }
                        )
                    })
                    setAdmins(_admins);
                    setIsLoading(false);
                }
            });
    }

    const addNewAdmin = () => {
        setIsLoading(true);
        toast(translate("Lütfen bekleyin."), {
            type: 'info',
            theme: 'dark',
            position: 'top-center',
            isLoading: true,
            autoClose: false
        });

        NetworkRequest("/api/Tenants/AddNewAdmin", { id: tenant.tenantId })
            .then((response) => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    toast(translate("Kullanıcı başarıyla oluşturuldu."), {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',

                        autoClose: 3000
                    });
                    getAdmins();
                }
            });
    }
    function loginUserAccount(userId) {

        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let userData = {
                userId: userId,
                transactionUserId: DataHandler.getUserDataWithKey("userId")
            }

            if (userData.userId == userData.transactionUserId) {
                toast(translate("Login olduğunuz kullanıcıya zorlu giriş yapamazsınız."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
            }

            NetworkRequest("/Authentication/ForceLogin", userData, true).then((response) => {
                let result = response.data;
            
                if (result.resultCode == 0) {
                    ////debugger;
                    let user = result.detail;
                    let newUser = {
                        id: user.userId,
                        name: user.name,
                        surname: user.surname,
                        fullname: user.fullname,
                        token: user.token,
                        userRole: user.userRole[0],
                        userRoleList: user.userRoleList,
                        email: user.email,
                        photo: user.photo,
                        tenantId: user.tenantId,
                        siteTitle: user.siteTitle,
                        logoUrl: user.logoUrl,
                        favIconUrl: user.favIconUrl,
                        isDefaultSetting: user.isDefaultSetting,
                        isActive: user.isActive,
                        workStartDate: user.workStartDate,
                        isBaseTenant: DataHandler.getUserDataWithKey("isBaseTenant")
                    };
                  /*  debugger;*/
                    let store = DataHandler.getStore();

                    let oldUser = {
                        fullName: DataHandler.getUserDataWithKey("fullname"),
                        token: store.token,
                        name: DataHandler.getUserDataWithKey("name"),
                        surname: DataHandler.getUserDataWithKey("surname"),
                        userRole: DataHandler.getUserDataWithKey("userRole"),
                        id: DataHandler.getUserDataWithKey("userId"),
                        email: DataHandler.getUserDataWithKey("email"),
                        photo: DataHandler.getUserDataWithKey("photo"),
                        userRoleList: DataHandler.getUserDataWithKey("userRoleList"),
                        tenantId: DataHandler.getUserDataWithKey("tenantId"),
                        workStartDate: DataHandler.getUserDataWithKey("workStartDate"),
                        logoUrl: DataHandler.getUserDataWithKey("logoUrl")
                    }

                    resetCookieEvent(newUser, oldUser);
                } else {
                    toast(translate("Hata oluştu. Lütfen sistem yöneticinize başvurun."), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }
    const removeSpaces = (e) => {
        setTenant({ ...tenant, tenantName: e.target.value.split(' ').join('') });
    }


    function resetCookieEvent(userData, user) {
        //bence olmamalı
        //dispatch(logOutUser({ token: getCookie("Secret"),isStopRedirect:false }));
        DataHandler.removePages();
        DataHandler.pushForceLoginData(userData, user);
        window.location.href = "/";
    }


    const getSelectedLanguageByTenantId = () => {

        let tenantData = {
            tenantId: tenant.tenantId
        };

        NetworkRequest("/api/Language/GetSelectedLanguageByTenantId", tenantData)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    var language = response.data.detail;

                    if (language != null) {
                        setSelectedDefaultLanguage(language.value);
                    }
                }
            });
    }

    const handleChanges = (event: any, data: any) => {
        setSelectedDefaultLanguage(data.value);
    }


    useEffect(() => {
        getParentTenants();
        getActiveLanguages();
        getSelectedLanguageByTenantId();
        getAdmins();
    }, []);




    return (
        <div style={{ marginTop: "2rem" }}>
            <Grid columns="2">
                <Grid.Column style={styles.formContainer}>
                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Label style={styles.label}>
                                Adı: (*)
                            </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input value={tenant.tenantName} onChange={(e) => { setTenant({ ...tenant, tenantName: e.target.value }); }} onBlur={removeSpaces} />
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Label style={styles.label}>
                                Görünen Adı: (*)
                            </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Input value={tenant.displayName} onChange={(e) => { setTenant({ ...tenant, displayName: e.target.value }); }} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Label style={styles.label}>
                                Üst Şirket:
                            </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Dropdown
                                style={styles.input}
                                value={tenant.parentTenantId}
                                name="parentTenantId"
                                required="required"
                                clearable
                                options={parentTenantList}
                                selection
                                placeholder="Üst Şirket Seçiniz"
                                onChange={changeDropdown}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Label style={styles.label}>
                                Varsayılan Dil:
                            </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Dropdown
                                style={styles.input}
                                placeholder='Dil Seçiniz'
                                clearable
                                search
                                selection
                                value={selectedDefaultLanguage}
                                defaultValue={selectedDefaultLanguage}
                                options={languageListDropdown}
                                onChange={(event, data) => {
                                    handleChanges(event, data)
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Label style={styles.label}>
                                Aktif Mi?:
                            </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name="circle" style={tenant != undefined && tenant.isActive ? styles.activeColor : styles.passiveColor} onClick={(e) => { setTenant({ ...tenant, isActive: !tenant.isActive }); }} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Label style={styles.label}>
                                Silindi Mi?:
                            </Label>
                        </Grid.Column>
                        <Grid.Column>
                            <Icon name="circle" style={tenant != undefined && tenant.isDeleted ? styles.activeColor : styles.passiveColor} onClick={(e) => { setTenant({ ...tenant, isDeleted: !tenant.isDeleted }); }} />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row style={styles.row}>
                        <Grid.Column >
                            <Button className={"dangerRedButton"} onClick={props.showList}>
                                <Icon name="arrow left" />
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className={"positiveGreenButton"} onClick={() => { updateHandler(); }}>
                                <Icon name="check" />
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Column style={{ padding: "0px" }}>

                    <div style={{ width: "100%" }}>
                        <Transition visible={isLoading} animation='scale' duration={500}>
                            <div>
                                <Loader size="huge" active inline='centered' />
                            </div>
                        </Transition>

                        <Transition visible={!isLoading} animation='scale' duration={500}>
                            <div style={styles.tableSection}>
                                <Table basic='very'>
                                    <Table.Header>
                                        <Table.Row>
                                            {tableHeader.map(header => (
                                                <Table.HeaderCell key={uuidv4()}>{header}</Table.HeaderCell>
                                            ))}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {(admins != null && admins.length > 0) && admins.map((admin) => (
                                            <Table.Row key={uuidv4()}>
                                                <Table.Cell>{admin.isHeadAdmin && <Icon name='star' color="red" />}</Table.Cell>
                                                <Table.Cell>{admin.name}</Table.Cell>
                                                <Table.Cell>{admin.userName}</Table.Cell>
                                                <Table.Cell>{admin.email}</Table.Cell>
                                                <Table.Cell>
                                                    <Button
                                                        onClick={() => loginUserAccount(admin.id)}
                                                        color={admin.isHeadAdmin ? "red" : "yellow"}
                                                        icon='sign in'
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}


                                    </Table.Body>
                                    <Table.Footer fullWidth>
                                        <Table.Row>

                                            <Table.HeaderCell colSpan='4'>
                                                <Button color='green' floated='right' onClick={() => { addNewAdmin() }}>
                                                    <Icon name='plus' /> {translate("Yeni Ekle")}
                                                </Button>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </Transition>

                    </div>

                </Grid.Column>
            </Grid>
        </div>
    );
}

export default EditTenant;
