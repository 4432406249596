import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Button, Icon } from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TenantSubscriptionList from "./Components/TenantSubscriptionList";
import AddTenantSubscription from "./Components/AddTenantSubscription";
import EditTenantSubscription from "./Components/EditTenantSubscription";
import { useParams } from 'react-router-dom';

const TenantSubscriptions = (props) => {
    let { id, page } = useParams();
    //styles
    let styles = {
        activeColor: {
            color: 'green'
        }
    }

    const [isList, setIsList] = useState(true);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedTenantSubscription, setSelectedTenantSubscription] = useState();

    const addTenantSubscription = () => {
        setIsCreate(true);
        setIsEdit(false);
        setIsList(false);
    }

    const editTenant = (tenantSubscription) => {
        setSelectedTenantSubscription(tenantSubscription);
        setIsCreate(false);
        setIsEdit(true);
        setIsList(false);
    }

    const showList = () => {
        setIsCreate(false);
        setIsEdit(false);
        setIsList(true);
    }

    const update = (tenant) => {
        return NetworkRequest("/api/TenantSubscriptions/Update", tenant).then((response) => {
            ////
            return response
        });
    }

    useEffect(() => {
        if (page == "2") {
            addTenantSubscription()
        }
    }, [page])


    return (
        <Container>
            {isList
                &&
                <>
                <Button className={"positiveGreenButton marginBottom2"} onClick={addTenantSubscription}>
                        <Icon name="plus" />
                        Yeni
                    </Button>
                    <TenantSubscriptionList AddTenantSubscription={addTenantSubscription} EditTenant={editTenant} Update={update} />
                </>
            }
            {isCreate
                &&
                <AddTenantSubscription showList={showList} />
            }
            {isEdit
                &&
                <EditTenantSubscription showList={showList} TenantSubscription={selectedTenantSubscription} Update={update} />
            }
        </Container>
    );
}

export default TenantSubscriptions;