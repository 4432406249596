import * as React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import ReCAPTCHA from "react-google-recaptcha";
import {DataHandler} from "../helper/DataHelper";
import {memo, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import {NetworkRequest} from "../helper/NetworkRequest";
import {toast} from "react-toastify";

const BuddyReCaptcha = (props) => {

   /* const [googleReCaptchaHtml, setGoogleReCaptchaHtml] = useState<any>();*/

    //function onChange(value) {
    //    if (value) {
    //        NetworkRequest("/api/SiteSetting/VerifyGoogleReCaptcha", {
    //            Token: value
    //        }).then((response) => {
    //            if (response.data.resultCode == 0 && response.data.detail === true) {
    //                props.setIsFormOk(true);
    //            } else {
    //                props.setIsFormOk(false);
    //            }
    //        });
    //    } else {
    //        return;
    //    }
    //}

    useEffect(() => {

        //setGoogleReCaptchaHtml(
        //    // <GoogleReCaptchaProvider
        //    //     reCaptchaKey={DataHandler.getGAKey()}
        //    //     language="turkish"
        //    //     useRecaptchaNet={true}
        //    //     useEnterprise={false}
        //    //     scriptProps={{
        //    //         async: false, // optional, default to false,
        //    //         defer: false, // optional, default to false
        //    //         appendTo: 'head', // optional, default to "head", can be "head" or "body",
        //    //         nonce: undefined // optional, default undefined
        //    //     }}
        //    //     container={{ // optional to render inside custom element
        //    //         element: "buddyCaptcha",
        //    //         parameters: {
        //    //             badge: 'inline', // optional, default undefined
        //    //             theme: 'dark', // optional, default undefined
        //    //         }
        //    //     }}
        //    // >
        //    //     <ReCAPTCHA
        //    //         sitekey={DataHandler.getSiteKey()}
        //    //         onChange={onChange}
        //    //         ref={captchaRef}
        //    //
        //    //     />
        //    // </GoogleReCaptchaProvider>

        //    <ReCAPTCHA
        //        sitekey={DataHandler.getSiteKey()}
        //        onChange={onChange}
        //    />
        //)


    }, []);

    return (
        <div>
            {/*{googleReCaptchaHtml}*/}
            {/*<div key={uuidv4()} id={"buddyCaptcha"}></div>*/}

        </div>
    );
}

export default memo(BuddyReCaptcha);