import * as React from 'react';
import { useState, useEffect } from 'react';

import { Button, Card, Checkbox, Header, Table } from 'semantic-ui-react';
import { datableFilterChange, digitallicaConfirm, prepareDataForTable } from '../helper/HelperFunctions';
import { NetworkRequest } from '../helper/NetworkRequest';
import DataTable from './DataTable';
import { IDataTable } from '../interfaces/interfaces';

import { toast } from 'react-toastify';
import { DataHandler } from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

interface ActivityAttachToContent {
    id?: string,
    ActivityAdd?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const ActivityAttachToContent = (props) => {
    var { translate } = TranslateHelper();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterText, setFilterText] = useState("");
    const [contentId] = useState(props.id);
    const [activity, setActivity] = useState({ id: "", title: "" });
    const [activityAndContent, setActivityAndContent] = useState(
        { id: "", contentId: "", activityId: "" }
    );
    const [isAllSelected, setIsAllSelected] = useState(false);

    const onComplate = (id) => {
        let etkinlikData = { id: id };
        NetworkRequest("/api/Activities/GetById", etkinlikData).then((response) => {
            let result = response.data.detail;
            if (response.data.resultCode == 0) {
                setActivity({ id: id, title: result.title });
            }
            setIsLoading(false);
        });
        setActivityAndContent({ ...activityAndContent, activityId: id });
        props.attachActivity(id);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);

    const [filterData] = useState({
        filterText: filterText,
        currentPage: currentPage,
        isActive: isActive,
        isDeleted: isDeleted,
        startingDate: startingDate,
        dueDate: dueDate,
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });


    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    //filter's every change
    useEffect(() => {

        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        getActivitys(filterData);
        getDataCount(filterData);

    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            translate("Adı"),
            translate("Konum"),
            translate("Eğitmen"),
            translate("Başlangıç Tarihi"),
            translate("Bitiş Tarihi"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "plus",
                desc: "Etkinlik ekle",
                color: undefined,
                function: onComplate,
                type: "add",

            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/btnClickAndAddAdd",
        isAttachingPage: true,
        noCheckBox: true,
    });

    //prepare data for table
    const prepareData = (data) => {
        //hidden column
        if (data.list.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }

        let model = prepareDataForTable(data.list, [
            'id',
            'updatedDate',
            'deletedDate',
            'createdDate',
            'startTime',
            'endTime',
            'isDeleted',
            'searchField',
            'isOnlineActivity',
            'onlineActivityLink',
            'description',
            'tenantId',
            'tenant',
            'tenantName',
            'activityAndContent',
            'mapHtml',
        ]);
        //console.log(model);
        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(data.count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                },
                addRowLink: ""
            });
        }, 100);
    }

    const deleteActivityAndContent = () => {


        digitallicaConfirm(
            "Bu içerikten etkinlik kaldırılacak. Emin misiniz?",
            () => {
                if (activityAndContent.id != "") {
                    let activityAndContentData = {
                        id: activityAndContent.id
                    }
                    NetworkRequest("/api/ActivityAndContent/Delete", activityAndContentData).then((response) => {
                        if (response.data.resultCode == 0) {
                            setActivityAndContent({ id: "", contentId: contentId, activityId: "" });
                            setActivity({ id: "", title: "" });
                            props.attachActivity("");
                        }
                    });
                } else {
                    setActivityAndContent({
                        id: "",
                        activityId: "",
                        contentId: contentId
                    });
                    setActivity({ id: "", title: "" });
                    props.attachActivity("");
                }
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    //get table data from database
    const getActivitys = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/api/Activities/GetActivities", filterData).then(response => {
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
            }
            setIsLoading(false);
        });
    }

    //get data count for pagination
    const getDataCount = (filterData?): any => {

        NetworkRequest("/api/Activities/GetFilteredCount", filterData).then(response => {
            ////
            if (response.data.detail != null && response.data.detail > 0) {
                let count: number = Math.ceil(
                    response.data.detail / 25
                );

                dataTableModel.pagination.count = count;
                setDataTableModel(dataTableModel);
            }
            return 1;
        });
    }


    const checkAssignedActivity = () => {
        let checkData = {
            id: contentId
        }
        NetworkRequest("/api/ActivityAndContent/GetAssignedActivity", checkData).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                if (result == null)
                    return;
                setActivityAndContent({
                    id: result.id,
                    activityId: result.activityId,
                    contentId: result.contentId
                });

                let etkinlikData = {
                    id: result.activityId
                }
                NetworkRequest("/api/Activities/GetById", etkinlikData).then((responseActivities) => {
                    if (responseActivities.data.resultCode == 0) {
                        let resultActivities = responseActivities.data.detail;
                        setActivity({ title: resultActivities.title, id: resultActivities.id })
                    }
                });
            }
        });
    }

    //onLoad page function
    useEffect(() => {
        getActivitys(filterData);
    }, []);

    useEffect(() => {
        if (activityAndContent.activityId != "") {
            props.setIsTaskActivityAdded(true);
        }
    }, [activityAndContent]);

    useEffect(() => {
        if (contentId != "")
            checkAssignedActivity();
    }, [contentId]);

    return (
        <>
            {activityAndContent.activityId == "" &&
                <div style={{ display: "flex", alignContent: "space-around", width: "100%", "flexDirection": "column" }}>
                    <DataTable
                        dataTable={dataTableModel}
                        isAllSelected={isAllSelected}
                        setAllSelected={setAllSelected}
                        selectAllHandler={selectAllHandler}
                        isLoading={isLoading}
                    />
                </div>
            }
            {(activityAndContent.activityId != "" && activityAndContent.activityId != null) &&
                <div style={{ width: "100%" }}>
                    {/*<Header>Bu göreve atanmış bir etkinlik bulunmaktadır.</Header>*/}
                    <Header>{translate("İçeriğe eklenen etkinlik")}</Header>
                    <Table celled fluid>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{translate("Etkinlik Adı")}</Table.HeaderCell>
                                <Table.HeaderCell>{translate("İşlem")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{activity.title}</Table.Cell>
                                <Table.Cell>

                                    <Button color={"red"} className={"roundedButton"}
                                        onClick={deleteActivityAndContent}
                                    >
                                        {translate("Kaldır")}
                                    </Button>

                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p style={{ opacity: '.8' }}>
                        {translate("Bu içeriğe farklı bir etkinlik atamak istiyorsanız, öncelikle var olan etkinliğin atamasını kaldırmanız gerekir.")}
                    </p>
                </div>
            }
        </>
    );
}

export default ActivityAttachToContent;