import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import '../Home.css';
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from '../../../helper/TranslateHelper';
import {Link} from "react-router-dom";


type IPricingCard = {
    pricingHeader: string
    pricingText: string
    price: string
    priceFrequency: string
    noPrice: boolean
    billedYearly: boolean
    pricingCardInfoHeader: string
    pricingCardInfoDetailList: Array<string>
    buttonText: string
}

const PricingCard = ({ pricingHeader, pricingText, price, priceFrequency, noPrice, billedYearly, pricingCardInfoHeader, pricingCardInfoDetailList, buttonText }: IPricingCard) => {
    var {translateFromEng} = TranslateHelper();

    return (
        <div className="pricingCard">

            <div className="pricingCardHeader">
                <div className="pricingHeader">{pricingHeader}</div>
                <div className="pricingText">{pricingText}</div>
            </div>

            <div className="pricingCardPRiceContainer">
                <div className="pricingCardPrice">
                    {noPrice ? <span className="pricingCardPriceContent">{translateFromEng("Contact Us")}</span> : <><span className="pricingCardPriceContent">$ {price}</span> / {priceFrequency}</>}
                   
                </div>
                {billedYearly && <div className="pricingCardBilled">{translateFromEng("Billed yearly")}</div>}

            </div>


            <div className="pricingCardInfo">
                <div className="pricingCardInfoHeader">{pricingCardInfoHeader}</div>
                <div className="pricingCardInfoDetailList">
                    {pricingCardInfoDetailList.map((item) => (
                        <div key={uuidv4()}><Icon color="green" name="check circle outline" /> {translateFromEng(item)}</div>
                    ))}
                </div>
            </div>


            <div className="pricingCardButtonContent">
                <Link to={"/newTenant"}>
                    <Button color="green" fluid circular>{buttonText}</Button>
                </Link>
               
            </div>
        </div>
    )
}

export default PricingCard;