import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Header, Icon, Image, Input, Loader, Modal, Transition } from 'semantic-ui-react';

import { NetworkRequest } from '../../../helper/NetworkRequest';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { checkFieldsIsFilled, getRandomIntInclusive } from '../../../helper/HelperFunctions';
import EditorComponent from '../../../components/EditorComponent';

import TranslateHelper from '../../../helper/TranslateHelper';
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import 'semantic-ui-css/semantic.min.css'
import { DataHandler } from '../../../helper/DataHelper';
import { v4 as uuidv4 } from 'uuid';
import DefaultProfileImage from '../../../images/user.png';


const TeamContactList = (props) => {
    var { translate } = TranslateHelper();
    const [userId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [teamUserImage, setTeamUserImage] = useState();
    const [responsibleUsers, setResponsibleUsers] = useState<any>([]);
    const [editorContent, setEditorContent] = useState("");
    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [selectedUserForMail, setSelectedUserForMail] = useState({ email: "", name: "", surname: "", photo: "" });
    const [showSendMessage, setShowSendMessage] = useState(false);
    const [mailTitle, setMailTitle] = useState<string>("");
    const [isMailOk, setIsMailOk] = useState(false);
    const [loader, setLoader] = useState(true);
    const [randomKey] = useState(getRandomIntInclusive());

    const getResponsibleUsersByUserId = (id: string) => {
        setLoader(true);
        let user = {
            id: id
        }
        NetworkRequest("/api/User/GetResponsibleUsersByUserId", user).then((response) => {
            if (response.data.resultCode === 0) {
                let list = response.data.detail;
                list.map((item) => {
                    if (item.photo == null) {
                        item.photo = "/Documents/Buddy/profil.png";
                        item.key = uuidv4();
                    }
                })
                setResponsibleUsers(list);
                setLoader(false);
            }
        });
    }

    const styles = {
        inputSectionBox: {
            display: "flex",
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: isMobile ? "column" as "column" : "row" as "row",
            marginBottom: "1rem",
            height: "auto",
            width: "100%"
        },
        emailListBox: {
            width: "100%",
            heigth: "2rem",
            padding: "5px 3px",
            borderRadius: "5px",
            display: "flex",
            backgroundColor: "#CAF7E302",
            flexWrap: "wrap" as "wrap"
        },
        inputStyle: {
            width: "100%"
        },
        emailBox: {
            width: "auto",
            heigth: "1rem",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row" as "row",
            gap: "1rem",
            padding: "0.5rem 1rem",
            border: "0",
            background: "#E8E8E8",
            color: "#080808",
        },
        borderColors: [{ borderLeft: "10px solid #05afec" }, { borderLeft: "10px solid #72309e" }, { borderLeft: "10px solid #ff0163" }],
        userContainer: {
            display: "flex",
            flexDirection: "column" as "column",
            minHeight: "100px",
            gap: "1rem"
        },
        user: {
            display: "flex",
            flexDirection: "row" as "row",
            border: "1px solid #c1c1c1",
            padding: ".5rem"
        },
        leftSide: {
            display: "flex",
            flexDirection: "row" as "row",

            width: "83%",
            gap: ".5rem",
            alignItems: "center"

        },
        rightSide: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            width: "17%",
            justifyContent: "center",
            gap: ".2rem",

        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            borderRadius: "50%",
            width: "3em"
            // width: "65%"
        },
        nameAndTitleContainer: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        name: {
            color: "purple"
        },
        title: {
            color: "green"
        },
        profileImage: {
            borderRadius: "50%"
        },
        btnDarkOutline: {
            width: "228px",
            height: "53px",
            right: "350px",
            top: "700px",
        },
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            backgroundColor: "rgb(52,150,81)",
            color: "white"
        },
    }

    const sendMail = (e) => {
        setLoader(true);
        let mail = {
            UserList: [{ email: selectedUserForMail.email }],
            Title: mailTitle,
            Body: DataHandler.getUserDataWithKey("fullname") + "(" + DataHandler.getUserDataWithKey("email") + ")" + " Tarafından Gönderilmiştir" + "<br>" + editorContent
        };
        NetworkRequest("/api/User/SendMail", mail).then((response) => {
            setLoader(false);
            if (response.data.resultCode == 0) {
                setShowSendMessage(false);
                toast.dismiss();
                toast(translate("Mail başarıyla gönderildi"), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
                setMailTitle("");
                setEditorContent("");
            }
        });
    }

    useEffect(() => {
        getResponsibleUsersByUserId(userId);
    }, [userId]);

    useEffect(() => {
        ////console.log(selectedUserForMail.photo);
    }, [selectedUserForMail.photo]);

    useEffect(() => {
        ////console.log(teamUserImage);
    }, [teamUserImage]);

    useEffect(() => {
        ////debugger;
        displayProtectedAppDoc(selectedUserForMail.photo, "teamUserImage", setTeamUserImage)
    }, [selectedUserForMail.photo]);

    const showSendMailModal = (user) => {
        setSelectedUserForMail(user);
        setShowSendMessage(true);
    }

    useEffect(() => {
        setIsMailOk(checkFieldsIsFilled(editorContent, mailTitle))
    }, [editorContent, mailTitle]);

    return (
        <div style={styles.userContainer}>
            <>
                <Transition visible={!loader} animation='scale' duration={500}>
                    <div>
                        {responsibleUsers.map((item, i) => (
                            <div className={"teamContactListUserContainer"} key={uuidv4()}
                                style={{ ...styles.user, ...styles.borderColors[i] }}>
                                <div style={{ ...styles.leftSide }}>
                                    <div style={styles.imgContainer}>
                                        <Image circular={true} id={"profilImage" + i}
                                            src={item.photo == undefined ? DefaultProfileImage : item.photo}
                                            onError={({ currentTarget }) => {
                                                currentTarget.src = DefaultProfileImage
                                            }}
                                            style={{
                                                borderRadius: "50%",
                                                width: "35px",
                                                height: "35px",
                                                display: "block"
                                            }}
                                        />
                                    </div>
                                    <div style={styles.nameAndTitleContainer}>
                                        <div className={"teamContactListName"} style={styles.name}>
                                            {item.name + " " + item.surname}
                                        </div>
                                        <div className={"teamContactListRole"} style={styles.title}>
                                            {translate(item.role)}
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.rightSide}>
                                    {/*<a href={"https://wa.me/" + item.phone + "?message=Merhaba " + item.name + ","}*/}
                                    {/*    target="_blank">*/}
                                    {/*    */}{/*<Button*/}
                                    {/*    */}{/*    className={"wpIconButton"} circular>*/}
                                    {/*    */}{/*    <img style={{width:"20px", height:"20px"}} src={WhatsappIcon} alt="whatsapp-icon" />*/}
                                    {/*    */}{/*</Button>*/}
                                    {/*    <Button*/}
                                    {/*        // className={"wpIconButton"}*/}
                                    {/*        circular*/}
                                    {/*        icon='whatsapp'*/}
                                    {/*    />*/}
                                    {/*</a>*/}
                                    <div>
                                        <Button
                                            // className={"wpIconButton"}
                                            circular
                                            icon='mail outline'
                                            onClick={(e) => {
                                                setShowSendMessage(true);
                                                setSelectedUserForMail(item);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                    </div>

                </Transition>

                <Transition visible={showSendMessage} animation='scale' duration={500}>
                    <Modal
                        centered={false}
                        open={showSendMessage && selectedUserForMail.email != ""}
                        onClose={() => setShowSendMessage(false)}
                    >
                        <div className={"teamContactListMailSenderHeader"}>{translate("Mail Gönder")}</div>
                        <Modal.Content>
                            <Modal.Description>
                                <div>
                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.emailListBox}>
                                            <div key={randomKey} style={{ ...styles.emailBox }}>
                                                {/*<Image id="teamUserImage" style={styles.profileImage}*/}
                                                {/*    source={teamUserImage}*/}
                                                {/*    src={teamUserImage}*/}
                                                {/*    width="30" />*/}
                                                <Icon name="user" />
                                                <b>  {selectedUserForMail.name + " " + selectedUserForMail.surname}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={styles.inputSectionBox}>
                                        <Input
                                            className="greyInput"
                                            style={styles.inputStyle}
                                            control={Input}
                                            label={translate("Başlık")}
                                            placeholder={translate("Başlık")}
                                            value={mailTitle}
                                            onChange={(e) => {
                                                setMailTitle(e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <EditorComponent
                                            value={editorContent}
                                            setEditorContent={setEditorContent}
                                            showVairant={false}
                                        />
                                    </div>
                                </div>

                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                circular={true}
                                color="red"

                                onClick={() => setShowSendMessage(false)}
                            >
                                {translate("Kapat")}
                            </Button>
                            <Button
                                color="green"
                                circular={true}
                                disabled={!isMailOk}
                                onClick={sendMail}
                            >
                                {translate("Gönder")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>
            </>
        </div>
    );
}

export default TeamContactList;