import React, {memo} from 'react';
import {Button} from 'semantic-ui-react';
import TranslateHelper from "../../../helper/TranslateHelper";

const ModuleListCardItem = ({moduleTitle, clickFunc}) => {
    var { translate } = TranslateHelper();
    
    return (
        <div className={"menuListCardItem"}>
            <div>{moduleTitle}</div>
            <div>
                <Button onClick={clickFunc} circular={true} color={"blue"}>
                    {translate("Seç")}
                </Button>
            </div>
        </div>
    );
};

export default memo(ModuleListCardItem);