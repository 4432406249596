import React, { MouseEventHandler, useEffect } from 'react';
import { Breadcrumb, Icon } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';
import TranslateHelper from '../helper/TranslateHelper';

interface IProps {
    BreadcrumbProps: {
        Menu: string;
        Link: string | null;
        Function?: MouseEventHandler<HTMLSpanElement>;
    }[],
    BreadcrumbContainerStyle: any
}

const BreadcrumbComp: React.FC<IProps> = ({ BreadcrumbProps, BreadcrumbContainerStyle }) => {
    var { translate } = TranslateHelper();

    const styles = {
        link: {
            color: "#292828"
        }
    }


    return (
        <div className={"breadcrumbContainer"} style={BreadcrumbContainerStyle}>
            <div>
                <Icon color="blue" name={"code branch"} />
            </div>

            <Breadcrumb
                size="big"
            >
                {BreadcrumbProps != undefined && BreadcrumbProps.map((breadcrump, index) => (
                    <span id={index.toString()} key={index}>
                        {breadcrump.Link != null ?

                            <Breadcrumb.Section
                                style={styles.link}
                            >
                                
                                    <span
                                        className={"breadcrumbMenuElement"}
                                        style={{
                                            color: (index + 1) === (BreadcrumbProps.length) ? "#2185d0" : "#292828",
                                            fontWeight: (index + 1) === (BreadcrumbProps.length) ? "bold" : "normal",
                                            fontSize: (index + 1) === (BreadcrumbProps.length) ? "1.1em" : "1em"
                                        }}
                                    >
                                    <Link to={breadcrump.Link}>{translate(breadcrump.Menu)}</Link>
                                    </span>
                                
                            </Breadcrumb.Section>
                            :

                            <span onClick={breadcrump.Function}>
                                <Breadcrumb.Section
                                    style={styles.link}
                                    link
                                >
                                    <span
                                        className={"breadcrumbMenuElement"}
                                        style={{
                                            color: (index + 1) === (BreadcrumbProps.length) ? "#2185d0" : "#292828",
                                            fontWeight: (index + 1) === (BreadcrumbProps.length) ? "bold" : "normal",
                                            fontSize: (index + 1) === (BreadcrumbProps.length) ? "1.1em" : "1em"
                                        }}
                                    >
                                        {translate(breadcrump.Menu)}
                                    </span>
                                </Breadcrumb.Section>
                            </span>
                        }

                        {index !== ((BreadcrumbProps.length) - 1) &&
                            <Breadcrumb.Divider style={{ color: "#2185d0" }} icon='long arrow alternate right' />
                        }
                    </span>
                ))}
            </Breadcrumb>
        </div>

    );
}

export default BreadcrumbComp;