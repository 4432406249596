import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import {
    Input,
    Button,
    Form,
    Card,
    Radio
} from 'semantic-ui-react';
import { ISurveyQuestion, ITest } from '../../../interfaces/interfaces';
import { checkFieldsIsFilled, digitallicaConfirm, extractAllElements, getQuestionContentForRender } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import EditorComponent from '../../../components/EditorComponent';
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
import QuillEditor from '../../../components/Quill/QuillEditor';

const QuestionAdd = (props) => {
    var { translate } = TranslateHelper();
    const history = useNavigate();
    let { id, page } = useParams();

    let styles = {
        questionAddContainer: {
            width: "60%",
            margin: "0 auto",
            marginTop: "3%",
            height: "auto",
            padding: "3%"
        },
        optionsContainer: {
            display: "flex",
            gap: "1em",
            flexDirection: "column" as "column",
            marginTop: "20px",
            marginBottom: "20px"
        },
        option: {
            display: "flex",
            width: "100%",
            gap: "2em",
            alignItems: "center",
            justifyContent: "center",
        },
        input: {
            width: "100%"
        }
    };

    const questionType = {
        options: [
            { key: 1, text: translate('Test'), value: 1 },
            { key: 2, text: translate('Serbest Metin'), value: 2 }
        ]
    }
    const [question, setQuestion] = useState<ISurveyQuestion>({
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        SurveyQuestionType: 1,
        DeletedDate: null,
        Id: "",
        IsActive: true,
        IsDeleted: false,
        UpdatedDate: null,
        Options: ""
    });
    const [idHook, setIdHook] = useState<any>(id);
    const [pageHook, setPageHook] = useState<any>(page);
    //const [editorContent, setEditorContent] = useState("");
    const [editorContent, setEditorContent] = useState({
        embedCode: "",
        embedValue: ""
    });

    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [options, setOptions] = useState(["A", "B", "C", "D", "E"]);
    const [survey, setSurvey] = useState({
        title: "",
        description: ""
    });

    const handleChangeOption = (e) => {
        switch (e.target.name) {
            case "A":
                setQuestion({ ...question, A: e.target.value });
                break;
            case "B":
                setQuestion({ ...question, B: e.target.value });
                break;
            case "C":
                setQuestion({ ...question, C: e.target.value });
                break;
            case "D":
                setQuestion({ ...question, D: e.target.value });
                break;
            case "E":
                setQuestion({ ...question, E: e.target.value });
                break;
            default:
        }
    }

    const saveQuestion = (type) => {
        let newJson = {
            Body: editorContent,
            SurveyQuestionType: question.SurveyQuestionType,
            A: question.A,
            B: question.B,
            C: question.C,
            D: question.D,
            E: question.E
        }
        let uri;
        if (pageHook == "edit") {
            uri = "/SurveyQuestion/Update";
        } else {
            uri = "/SurveyQuestion/Add";
        }

        let questionData = {
            id: idHook,
            questionJson: JSON.stringify(newJson),
            surveyQuestionType: question.SurveyQuestionType,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        NetworkRequest(uri, questionData, undefined, undefined, true).then(response => {
            if (response.data.resultCode === 0) {
                toast(translate("Soru başarılı bir şekilde kaydedildi"), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
                let result = response.data.detail;
                if (pageHook != "edit" && !(idHook == "" || idHook == undefined)) {
                    attachQuestionToSurvey(result.id);
                }
                if (type === 1) {
                    setQuestion({
                        ...question,
                        Body: "",
                        A: "",
                        B: "",
                        C: "",
                        D: "",
                        E: ""
                    });
                    setEditorContent({ embedCode: "", embedValue: "" });
                } else {
                    history("/inquiryContentList");
                }
            } else {
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });
    }

    const attachQuestionToSurvey = (questionId: string) => {

        let surveyQuestion = {
            surveyQuestionId: questionId,
            surveyId: idHook
        };

        NetworkRequest("/api/SurveyAndSurveyQuestion/Add", surveyQuestion).then(response => {
            ////
            if (response.data.resultCode === 0) {
                toast("Soru başarılı bir şekilde eklendi" + ": " + survey.title, {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            } else {
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });
    }

    const getSurveyById = (surveyId: string) => {
        if (surveyId == null || surveyId == "" || surveyId == undefined)
            return;

        let questionData = {
            id: surveyId
        }
        NetworkRequest("/Surveys/GetById", questionData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;

                setSurvey({
                    ...survey,
                    title: result.title,
                    description: result.description
                });
            }
        });

    }

    const changeRadio = (event, data) => {
        setQuestion({
            ...question,
            [data.name]: data.value,
            A: data.value == 4 ? "" : "",
            B: data.value == 4 ? "" : "",
            C: "",
            D: "",
            E: "",
        });
    }

    const getQuestionById = (questionId: string) => {
        let questionData = {
            id: questionId
        }

        NetworkRequest("/SurveyQuestion/GetQuestionJsonById", questionData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let question = JSON.parse(response.data.detail.questionJson);

                setEditorContent({ embedCode: getQuestionContentForRender(question.Body), embedValue: extractAllElements(getQuestionContentForRender(question.Body))});
                setQuestion({
                    ...question,
                    A: question.A,
                    B: question.B,
                    C: question.C,
                    D: question.D,
                    E: question.E,
                    SurveyQuestionType: question.SurveyQuestionType
                });
            }
        });
    }

    useEffect(() => {
        if (pageHook == "edit") {
            getQuestionById(idHook);
        } else {
            getSurveyById(idHook);
        }
    }, [idHook]);


    const [isFormOk, setIsFormOk] = useState(false);
    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                editorContent
            )
        );
    }, [editorContent]);

    return (

        <div style={styles.questionAddContainer} className={"generalContainerForWhiteBg"}>

            <span>{translate("İçeriğe tablo veya resim ekleneceği zaman eni (width) maksimum 200 olmalıdır!")}</span>

            <br />
            <br />
            <Form>
                <Form.Field style={{ gap: "2rem", display: "flex", marginBottom: "1rem" }}>
                    <Radio
                        label={translate("Çoktan Seçmeli")}
                        name='SurveyQuestionType'
                        value={1}
                        checked={question.SurveyQuestionType == 1}
                        onChange={changeRadio}
                    />

                    <Radio
                        label={translate("2 Şık")}
                        name='SurveyQuestionType'
                        value={4}
                        checked={question.SurveyQuestionType == 4}
                        onChange={changeRadio}
                    />

                    <Radio
                        label={translate("Serbest Metin")}
                        name='SurveyQuestionType'
                        value={2}
                        checked={question.SurveyQuestionType == 2}
                        onChange={changeRadio}
                    />
                </Form.Field>
            </Form>

            {/*<QuillEditor*/}
            {/*    value={editorContent}*/}
            {/*    setValue={setEditorContent}*/}
            {/*/>*/}

            <QuillEditor

                value={getQuestionContentForRender(editorContent)}
                setValue={(value) => { setEditorContent({ embedCode: value, embedValue: extractAllElements(value) })}}
            />

            {(question.SurveyQuestionType == 1 || question.SurveyQuestionType == 4) &&
                <div style={styles.optionsContainer}>

                    {options.slice(0, question.SurveyQuestionType == 1 ? 5 : 2).map(option => (
                        <div
                            key={option}
                            style={styles.option}
                        >
                            <Input
                                style={styles.input}
                                name={option}
                                fluid
                                className="greyInput"
                                label={{ content: option }}
                                labelPosition='left'
                                placeholder={option}
                                onChange={handleChangeOption}
                                value={question[option]}
                            />

                        </div>
                    ))}
                </div>}


            {pageHook != "edit" && !((idHook == undefined) || (idHook == "") || (idHook == null))
                &&
                <Card style={{ width: "100%" }}>
                    <Card.Content>
                        <Card.Header>{translate("Anket Başlığı:") + survey.title}</Card.Header>
                        <Card.Description>
                            TODO:translate-survey-title
                            {translate("Yukarıda detaylarını girdiğiniz soru ankete ve soru havuzuna eklenecektir.")}
                            {translate("Anket")} : <b>{survey.title}</b><br />
                            {translate("Sadece soru havuzuna eklemek istiyorsanız, eklemeden önce temizle butonuna tıklayınız.")}

                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <div style={{ padding: "0 .5rem" }}>
                            <Button basicclassName={"dangerRedButton"} onClick={() => setIdHook(undefined)}>
                                {translate("Temizle")}
                            </Button>
                        </div>
                    </Card.Content>
                </Card>}
            <div className="mt4">
                <Button
                    color={"blue"}
                    disabled={!isFormOk}
                    circular
                    onClick={() => {
                        saveQuestion(1)
                    }}
                >
                    {translate("Soruyu Kaydet ve Yeni Soru Ekle")}
                </Button>

                <Button
                    color={"green"}
                    disabled={!isFormOk}
                    circular
                    onClick={() => {
                        saveQuestion(2)
                    }}
                >
                    {translate("Soruyu Kaydet ve Çık")}
                </Button>
            </div>
        </div>

    );
}

export default QuestionAdd;