import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

import { NetworkRequest } from '../../../helper/NetworkRequest';
import { IDoc } from '../../../interfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';
import { base64toBlob } from '../../../helper/HelperFunctions';

const DocumentsList = (props) => {
    var { translate } = TranslateHelper();
    let _ = require('lodash');
    const [docs, setDocs] = React.useState<Array<IDoc>>([]);
    const [documentUrl, setDocumentUrl] = useState("");
    const [showFile, setShowFile] = useState(false);
    const [openFiles, setOpenFiles] = useState(false);

    const getDocs = () => {
        NetworkRequest("/api/Documents/GetDocuments",
            {
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }).then((response) => {
            if (response.data.resultCode == 0) {
                setDocs(response.data.detail.documentViews);
            }
        });
    }

    const styles = {

        header: {
            backgroundColor: "#ff0066",
            color: "#fff",
            textAlign: "center" as "center",
            width: "100%",
            padding: "1rem 0",
            fontSize: "1.5rem",
            fontWeight: 700
        },
        doc: {
            height: "228px",
            overflow: "auto"
        },
        imgContainer: {
            display: "flex",
            alignItems: "center"
        },
        nameAndTitleContainer: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        leftSide: {
            display: "flex",
            flexDirection: "row" as "row",


            gap: "1rem",
            alignItems: "center",
            padding: ".5rem",
            width: "100%",
            border: "1px solid #6e7e98",
            cursor: "pointer"
        },
        name: {
            color: "black",
            fontSize: "1.2rem",
            fontWeight: "700"
        },
        title: {
            color: "#838383"
        },
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        },
    }

    const zoomFile = (document) => {
        if (document != undefined) {
            if (!document.url.startsWith("http")) {
                let blob = base64toBlob(document.url, document.mimeType);
                setDocumentUrl(URL.createObjectURL(blob));
            } else {
                setDocumentUrl(document.url);
            }            
            setShowFile(true);
        }
    }

    const getExtention = (document) => {
        if(document != null && document?.url != null) {
            let arr = document.url.split('/');
            return arr[arr.length - 1].split('.')[1];
        }
        else return translate("tanımsız");
       
    }

    useEffect(() => {
        getDocs();
    }, []);

    return (
        <>
            {docs.length > 0
                &&
                <div
                    style={{
                        height: openFiles ? "400px" : "100px",
                    }}
                    className={"documentListContainerBox"}
                >
                    <div className={"documentListHeader"}>
                        <div>{translate("Dokümanlar")}</div>
                        <Button
                            className={"documentListOpenButton"}
                            style={{
                                transform: openFiles ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                            onClick={() => {
                                setOpenFiles(!openFiles);
                            }} icon={"down arrow"}
                        />
                    </div>
                    <div
                        style={{
                            visibility: openFiles ? "visible" : "hidden"
                        }}
                        className={"documentListBox"}>
                        {docs.map((item, i) => (
                            <div
                                className={"documentListContainer"}
                                key={uuidv4()}
                                onClick={() => { zoomFile(item) }}>
                                <div style={styles.imgContainer}>
                                    <Icon
                                        
                                        color="grey"
                                        name="file alternate"
                                    />
                                </div>
                                <div style={styles.nameAndTitleContainer} onClick={() => { zoomFile(item) }}>
                                    <div className={"documentListDocumentName"}>
                                        {item.name}
                                    </div>
                                    <div style={styles.title}>
                                        {getExtention(item)} {translate("Dosyası")}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Modal
                        onClose={() => setShowFile(false)}
                        onOpen={() => setShowFile(true)}
                        open={showFile}
                        style={{ top: "16px" }}
                    >
                        <Modal.Actions>
                            <Button
                                circular
                                color="red"
                                onClick={() => setShowFile(false)}>
                                {translate("Kapat")}
                            </Button>
                        </Modal.Actions>
                        <Modal.Content style={{ display: "flex", flexDirection: "column" as "column", alignItems: "center" }} image>
                            <div className="iframeContainer">
                                <div id="iframe" className="iframe">
                                    {!isMobile
                                        &&
                                        <iframe src={documentUrl} style={{ width: "100%", }} height="100%" />
                                    }
                                    {isMobile
                                        &&
                                        <iframe src={"https://drive.google.com/viewerng/viewer?embedded=true&url=" + documentUrl} style={{ width: "100%", }} height="100%" />
                                    }
                                </div>
                            </div>
                        </Modal.Content>
                    </Modal>
                </div>
            }
        </>
    );
}

export default DocumentsList;