import React, {useEffect, useState} from 'react';
import {Button, Icon, Input, Label, Popup} from "semantic-ui-react";
import {DateFormat} from "../../../helper/DateFormat";
import TranslateHelper from "../../../helper/TranslateHelper";
import {motion} from "framer-motion";
import HomePageFilterDate from "./HomePageFilterDate";
import {v4 as uuidv4} from 'uuid';
import HomePageFilterStatusLabel from "./HomePageFilterStatusLabel";
import {isMobile} from "react-device-detect";

const HomePageFilterPanel = ({
                                 filterModules,
                                 // getAssignedCoursesByFilter,
                                 setFilterData,
                                 isLoader,
                                 filterData,
                                 setFilterLock
                             }) => {
    var {translate} = TranslateHelper();

    const [filterText, setFilterText] = useState("");
    const [isOpenFilterPanel, setIsOpenFilterPanel] = useState(false);
    const [currentSelectedLabel, setCurrentSelectedLabel] = useState("");
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);


    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const [filterDates, setFilterDates] = useState([
        { title: translate("Başlangıç tarihi"), date: startingDate, setDate: onChangeDate1},
        { title: translate("Bitiş tarihi"), date: dueDate, setDate: onChangeDate2}
    ]);
    
    const filterStatus = [
        {
            status: "newModules",
            title: translate("Yeni"),
            icon: "certificate",
            currentSelectedLabel: currentSelectedLabel,
            setter: setCurrentSelectedLabel
        },
        {
            status: "continueModules",
            title: translate("Devam ediyor"),
            icon: "hourglass half",
            currentSelectedLabel: currentSelectedLabel,
            setter: setCurrentSelectedLabel
        },
        {
            status: "doneModules",
            title: translate("Tamamlandı"),
            icon: "check",
            currentSelectedLabel: currentSelectedLabel,
            setter: setCurrentSelectedLabel
        }
    ]

    const renderFilterDate = () => (
        filterDates.map((date) => (
            <HomePageFilterDate
                key={uuidv4()}
                isLoader={isLoader}
                title={date.title}
                date={date.date}
                setDate={date.setDate}
            />
        ))
    )

    const renderFilterStatus = () => (
        filterStatus.map((label) => (
            <HomePageFilterStatusLabel
                key={uuidv4()}
                currentSelectedLabel={label.currentSelectedLabel}
                status={label.status}
                setterStatus={label.setter}
                title={label.title}
                icon={label.icon}
                isLoader={isLoader}
                setFilterLock={setFilterLock}
            />
        ))
    )

    const clearSelectedDate = () => {
        setStartingDate(null);
        setDueDate(null);
    }

    const clearAllFilters = () => {
        setCurrentSelectedLabel("");
        setStartingDate(null);
        setDueDate(null);

        let filterData = {
            filterText: "",
            startingDate: null,
            dueDate: null,
            status: "",
            currentPage: 1
        }

        setFilterData(filterData);

        //getAssignedCoursesByFilter(filterData);
    }

    useEffect(() => {
        if (currentSelectedLabel != ""  || startingDate != null || dueDate != null)
            setFilterLock(true);
    }, [currentSelectedLabel,startingDate,dueDate])

    useEffect(() => {
        //change every filter data
        let filterData = {
            filterText: filterText,
            startingDate: startingDate,
            dueDate: dueDate,
            status: currentSelectedLabel,
            currentPage: currentPage
        }
        
        // getAssignedCoursesByFilter(filterData);
        
        setFilterData(filterData);

    }, [filterText, startingDate, dueDate, currentSelectedLabel, currentPage])

    useEffect(() => {
       // //console.log("filterData----",filterData)

        if(filterData != undefined) {

            
            setFilterDates([
                { title: translate("Başlangıç tarihi"), date: filterData.startingDate, setDate: onChangeDate1},
                { title: translate("Bitiş tarihi"), date: filterData.dueDate, setDate: onChangeDate2}
            ]);

            
            setStartingDate(filterData.startingDate)
            setDueDate(filterData.dueDate)
        }
    }, [filterData])

    return (
        <div className={"homePageFilterPanelContainer"}>
            <Popup content={translate("Filtrele")}
                   trigger={
                       <Button
                           disabled={isLoader}
                           onClick={() => {
                               setIsOpenFilterPanel(!isOpenFilterPanel)
                           }}
                           size={"big"}
                           circular={true}
                           icon={"filter"}
                           className={"ingIndigo" }
                       />}
            />

            <motion.div
                style={{
                    display: isOpenFilterPanel ? "block" : "none"
                }}
                className={"homePageModuleRendererFilterBox"}
                animate={isOpenFilterPanel ? {
                    opacity: [0, 1],
                    marginTop: ["-10px", "20px"]
                } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                transition={{duration: .3}}
            >
                <div>

                    <div className={"homePageModuleRendererFilterBoxHeader"}>
                        <div><Icon name={"filter"}/></div>
                        <div className={"filterRendererMainHeader"}>
                            <div>{translate("Filtrele")}</div>
                            <div className={"filterRendererMainHeaderRight"}>
                                {(currentSelectedLabel != "" || startingDate != null || dueDate != null) &&
                                    <div>
                                        <Button disabled={isLoader} onClick={clearAllFilters} inverted size={"mini"}
                                                color={"red"} circular={true}>
                                            {translate("Tümünü temizle")}
                                        </Button>
                                    </div>
                                }
                                <Button onClick={() => {
                                    setIsOpenFilterPanel(false);
                                }} size={"mini"} color={"red"} circular={true} icon='close'/>
                            </div>
                        </div>
                    </div>

                    <div className={"homePageModuleRendererFilterBoxContent"}>
                        <div className={"filterRendererSectionHeader"}>
                            <div>{translate("İlerleme durumu")}</div>
                            {currentSelectedLabel != "" &&
                                <div>
                                    <Button
                                        disabled={isLoader}
                                        onClick={() => {
                                            setCurrentSelectedLabel("");
                                        }}
                                        inverted
                                        size={"mini"}
                                        color={"red"}
                                        circular={true}
                                    >{translate("Temizle")}</Button>
                                </div>
                            }
                        </div>


                        <div className={"rendererFilterLabelContainer"}>
                            {renderFilterStatus()}
                        </div>
                    </div>

                    <div className={"filterBracket"}></div>

                    <div className={"homePageModuleRendererFilterBoxContent"}>
                        <div className={"filterRendererSectionHeader"}>
                            <div>{translate("Kayıt olma tarihi")}</div>
                            {(startingDate != null || dueDate != null) &&
                                <div>
                                    <Button disabled={isLoader} onClick={clearSelectedDate} inverted size={"mini"}
                                            color={"red"} circular={true}>{translate("Temizle")}</Button>
                                </div>
                            }
                        </div>

                        <div className={"rendererFilterDateContainer"}>
                            {renderFilterDate()}
                        </div>
                    </div>

                    <Button
                        disabled={isLoader}
                        loading={isLoader}
                        onClick={filterModules}
                        fluid={true} size={"large"} circular={true} className="ingIndigo" icon labelPosition='left'>
                        <Icon name='filter'/>
                        {translate("Filtrele")}
                    </Button>

                </div>
            </motion.div>

            <Input
                onChange={(e) => {
                    setFilterLock(true);
                    setFilterText(e.target.value)
                }}
                value={filterData?.filterText || ""}
                loading={isLoader}
                size={"big"}
                className={"greyInput"}
                icon='search'
                placeholder={translate("Ara..")}
            />
        </div>
    );
};

export default HomePageFilterPanel;