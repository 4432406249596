import * as moment from "moment";


function leftFillNum(num, targetLength) {
    return num.toString().padStart(targetLength, "0");
}

export const DateFormat = (databaseDate, isYearly?: boolean | undefined) => {

    if (!databaseDate)
        return "-";

    const date = new Date(databaseDate);

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();


    //console.log("day", day);
    //console.log("month", month);
    //console.log("year", year);

    if (!day || !month || !year) return null;

    // add a leading 0 if the number is less than 10. like 9 to 09

    day < 10 && (day = `0${day}`)
    month < 10 && (month = `0${month}`)
    year = leftFillNum(year, 4);

    //const newFormat = `${day}-${month}-${year}`;
    if (isYearly) {
        return yearlyFormat(year, month, day);
    }
    let newFormat = `${day}/${month}/${year}`;

/*    console.log("newFormat", newFormat);*/
    return newFormat;
}

export const DateTimeFormat = (databaseDate) => {
 
    if (!databaseDate)
        return "-";

    const date = new Date(databaseDate);

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();
    let hour: any = date.getHours();
    let minute: any = date.getMinutes();

    date.getFullYear

    if (!day || !month || !year) return null;

    day < 10 && (day = `0${day}`)
    month < 10 && (month = `0${month}`)
    year = leftFillNum(year, 4);
    

    let newHoursAndMinutes = (hour <= 9 ? "0" : "") + hour + ":" + (minute <= 9 ? "0" : "") + minute
    let newFormat = `${day}/${month}/${year} ${newHoursAndMinutes}`;

    return newFormat;
}

export const DateFormatDatePicker = (databaseDate, isYearly?: boolean | undefined) => {

    if (!databaseDate)
        return "-";

    const date = new Date(databaseDate);

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();

    if (!day || !month || !year) return null;

    // add a leading 0 if the number is less than 10. like 9 to 09

    day < 10 && (day = `0${day}`)
    month < 10 && (month = `0${month}`)
    year = leftFillNum(year, 4);

    //const newFormat = `${day}-${month}-${year}`;
    if (isYearly) {
        return yearlyFormat(year, month, day);
    }
    let newFormat = `${day}/${month}/${year}`;


    return newFormat;
}

export const DateFormatDatePickerRead = (databaseDate, isYearly?: boolean | undefined) => {

    if (!databaseDate)
        return "-";

    const date = new Date(databaseDate);

    let day: any = date.getDate() + 1;
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();

    if (!day || !month || !year) return null;

    // add a leading 0 if the number is less than 10. like 9 to 09

    day < 10 && (day = `0${day}`)
    month < 10 && (month = `0${month}`)
    year = leftFillNum(year, 4);

    //const newFormat = `${day}-${month}-${year}`;
    if (isYearly) {
        return yearlyFormat(year, month, day);
    }
    let newFormat = `${day}-${month}-${year}`;


    return newFormat;
}

const yearlyFormat = (year, month, day) => {
    let newFormat = `${year}-${month}-${day}`;
/*    console.log(newFormat);*/
    return newFormat;
}



export const DateAndHourFormat = (databaseDate) => {

    if (!databaseDate)
        return "-";

    const date = new Date(databaseDate);

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();
    let hour: any = date.getHours();
    let min: any = date.getMinutes();

    // add a leading 0 if the number is less than 10. like 9 to 09

    day < 10 && (day = `0${day}`)
    month < 10 && (month = `0${month}`)

    hour < 10 && (hour = `0${hour}`)
    min < 10 && (min = `0${min}`)

    //const newFormat = `${day}-${month}-${year}`;

    const newFormat = `${day}/${month}/${year} ${hour}:${min}`;

    return newFormat;
}