import * as React from 'react';

import { DataHandler } from '../../helper/DataHelper';

const NotAuthorized = () => {

    let tenantId = DataHandler.getUserDataWithKey("tenantId");

    React.useEffect(() => {
        if (tenantId != undefined) {
            DataHandler.logOutUser(true);
        }
    }, []);

    return (
        <div className="container bgWhite">
            {tenantId == undefined
                &&
                <div className="row thisIsCenter height80vh bgWhite">
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="four_zero_four_bg"></div>
                                </div>
                            </div>
                            <div className="row textCenter">
                                <div className="col-sm-6 col-sm-offset-3">
                                    <h1>Uygulamayı görüntülemeye yetkiniz yok. Lütfen yöneticinizle irtibata geçin</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default NotAuthorized;