import React, { useEffect, useState } from "react";
import { IDataTable } from "../interfaces/interfaces";
import { Checkbox, Dimmer, Loader, Segment, Transition } from "semantic-ui-react";
import { downloadFile, prepareDataForTable } from "../helper/HelperFunctions";

import { NetworkRequest } from "../helper/NetworkRequest";
import DataTable from "./DataTable";
import { toast } from "react-toastify";
import { DataHandler } from "../helper/DataHelper";
import TranslateHelper from "../helper/TranslateHelper";

const ModuleDetailUserListReport = (props) => {
    var { translate } = TranslateHelper();
    const [filterText, setFilterText] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState<any>({});
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isStartExcelReport, setIsStartExcelReport] = useState<boolean>(false);


    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const onChangeActive = (e, data) => { // TODO
        setIsActive(data.checked);
    }

    const onChangeDeleted = (e, data) => {
        setIsDeleted(data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const multipleExcelExport = (filterData:any) => {
        const excelExportToast = toast.loading("Dosyanız Hazırlanıyor...", { position: 'top-center', theme: 'dark' });

        NetworkRequest("api/Report/GetUserDetailReportToExcelByModuleId", filterData).then((response) => {
            downloadFile(response.data, "Çalışan Raporu");

            toast.update(excelExportToast, {
                render: "Dosyanız indirildi",
                type: "success",
                isLoading: false,
                autoClose: 2000
            });

        });
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                exportExcelFunction: dataTable.exportExcelFunction
            });
            setDataTable(dataTableModel);
        }, 100);
    }

    const selectAllHandler = (model) => {

        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                exportExcelFunction: dataTable.exportExcelFunction
            });
            setDataTable(model);
        }, 100);
    }

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: [
            
            translate("İsim"),
            translate("Soyisim"),
            translate("Email"),
            translate("İlerleme Yüzdesi"),
            translate("Tamamlama Bilgisi"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "line graph",
                desc: translate("Rapor"),
                color: "orange",
                link: "/employeeReport",
                type: "report"
            },

        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: true,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: false,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        setRowSelected: setRowSelected,
        addRowLink: "",
        isAttachingPage: false,
        exportExcelFunction: multipleExcelExport

    });

    //prepare data for table
    const prepareData = (data) => {

        //hidden column
        var model = prepareDataForTable(data.list, [
            'userId',
            'status',
            'statusId',
            'searchField',
            'id'
        ]);
        setTimeout(() => {

            //setDataTable({
            //    header: dataTable.header,
            //    data: [],
            //    transactions: dataTable.transactions,
            //    filter: dataTable.filter,
            //    pagination: dataTable.pagination,
            //    //addRowLink: dataTable.addRowLink,
            //    exportExcelFunction: dataTable.exportExcelFunction
            //});
            
            setDataTable({
                ...dataTable,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(
                        data.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                },
                exportExcelFunction: dataTable.exportExcelFunction,
                noCheckBox:true

            });
        }, 400);
    }

    const getModuleById = async (filterData?) => {  
        NetworkRequest("api/Report/GetEnrolledUserByModuleId", filterData).then((response) => {
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                let data = response.data.detail;
                prepareData(data);
                setIsLoading(false);
            }
        });
    }

    //filter's every change
    useEffect(() => {
        let filterDataObj = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            moduleId: props.moduleId
        }
        ////console.log(filterDataObj);
        setFilterData(filterDataObj);
        getModuleById(filterDataObj);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    useEffect(() => {
        ////console.log(filterData);
    }, [filterData])


    return (
        <div>
            {/*content*/}
            <Transition visible={true} animation='scale' duration={500}>
                <DataTable
                    dataTable={dataTable}
                    isAllSelected={isAllSelected}
                    setAllSelected={setAllSelected}
                    selectAllHandler={selectAllHandler}
                    isLoading={isLoading}
                    exportExcelFunction={dataTable.exportExcelFunction}
                    filterData={filterData}
                    
                    
                />
            </Transition>
        </div>
    )
};

export default ModuleDetailUserListReport;