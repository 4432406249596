import React, {useEffect, useState} from 'react';
import {Button, Container, Flag, Header, Icon, Input, Label, Loader} from "semantic-ui-react";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from "../../../helper/TranslateHelper";


const AddTranslationKeyword = () => {
    let _ = require("lodash");
    var {translate} = TranslateHelper();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaderForSave, setIsLoaderForSave] = useState(false);
    const [languages, setLanguages] = useState<any>([]);
    const [wordTitle, setWordTitle] = useState("");
    const [keyword, setKeyword] = useState<any>([]);

    const getCurrentLanguages = () => {
        setIsLoading(true);

        NetworkRequest("/api/Translation/GetCurrentLanguages", null).then((response) => {
            //
            if (response.data.resultCode == 0) {
                setLanguages(response.data.detail);
            }

            setIsLoading(false);
        });
    }

    const handleInputChange = (e, index) => {
        let newElement = {
            languageId: e.target.name,
            word: wordTitle,
            translatedWord: e.target.value
        };
        
        let clone = _.cloneDeep(keyword);
        
        if(clone.filter(x=>x.languageId == newElement.languageId).length > 0) {
        // if(clone[index] != undefined && clone[index].translatedWord != undefined) {
            
            // clone[index].translatedWord = newElement.translatedWord;
            clone.filter(x=>x.languageId == newElement.languageId).translatedWord = newElement.translatedWord;
        }
        else {
            clone.push(newElement);
        }
        
        setKeyword(clone);
        
    }

    const saveKeyword = () => {
        setIsLoaderForSave(true);
        keyword.map(key => key.word = wordTitle);
  
        NetworkRequest("/api/Translation/AddTranslationWord", keyword).then((response) => {
            //
            if (response.data.resultCode == 0) {
                toast(translate(response.data.message), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
            } else {
                toast(translate("Bir hata meydana geldi"), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }

            setIsLoaderForSave(false);
        });
    }

    useEffect(() => {
        getCurrentLanguages();
    }, [])
    
    return (
        <Container>
            {isLoading ? <Loader size="huge" active inline='centered'/> :
                <div>
                    <Button
                        onClick={() => navigate(-1)}
                        color={"orange"}
                        icon
                        labelPosition='left'
                    >
                        <Icon name='long arrow alternate left'/>
                        {translate("Geri dön")}
                    </Button>
                    <Header as='h2'>{translate("Kelime Ekle")}</Header>

                    <div style={{display: "flex", flexDirection: "column", gap: "1em", width: "100%", marginTop: "5%"}}>
                        <Input placeholder={translate("Kelime")} value={wordTitle} onChange={(e) => {
                            setWordTitle(e.target.value)
                        }}/>
                        {languages.map((keywordData, index) => (
                            <div key={index}>
                                
                                <Input
                                    fluid={true}
                                    labelPosition='left'
                                    type='text'
                                    placeholder={keywordData.flag}
                                >
                                    <Label><Flag name={keywordData.flag}/></Label>
                                    <input
                                        onChange={(e) => {
                                            handleInputChange(e, index)
                                        }}
                                        id={keywordData.flag}
                                        name={keywordData.languageId}
                                    />
                                </Input>
                            </div>
                        ))}
                    </div>

                    <div style={{marginTop: "5%"}}>
                        <Button disabled={isLoaderForSave} loading={isLoaderForSave} color={"green"}
                            onClick={saveKeyword}>{translate("Kaydet")}</Button>
                    </div>


                </div>
            }
        </Container>
    );
};

export default AddTranslationKeyword;