import * as React from 'react';
import { useState, useEffect } from 'react';
import { Loader, Transition, Grid, Input, Icon, Button, Header, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { ICategory } from '../../../interfaces/interfaces';
import { NetworkRequest } from '../../../helper/NetworkRequest';

import { digitallicaConfirm } from '../../../helper/HelperFunctions';
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';


const AddCategory = (props) => {
    var { translate } = TranslateHelper();
    let { id } = useParams();
    const [categoryId, setCategoryId] = useState(id);
    const [categoryList, setCategoryList] = useState<any>();
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [seniorCategoryId, setSeniorCategoryId] = useState();
    const [category, setCategory] = useState<ICategory>();

    const save = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let url = "api/Categories/Add";
            if (categoryId != undefined) {
                url = "api/Categories/Update"
            }
            var control = checkCategoryCode();
            if (!control) {
                return;
            }
            if (category?.seniorCategoryId == "") {
                category.seniorCategoryId = undefined;
            }
            NetworkRequest(url, category).then((response) => {
                ////
                if (response.data.resultCode === 0) {
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                    window.location.href = "/categoryList";
                } else {
                    toast(response.data.detail, {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const getCategory = () => {
        NetworkRequest("api/Categories/Get", { id: categoryId }).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                setCategory(response.data.detail);
            }
        });
    }


    const getCategories = () => {

        NetworkRequest("api/Categories/GetAllList", { id: tenantId }).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                var _categoryList: any = [];
                var _list = response.data.detail.list;
                for (var i = 0; i < _list.length; i++) {
                    var item: any = _list[i];
                    _categoryList.push({
                        key: item.id,
                        value: item.id,
                        text: translate(item.displayName)
                    })
                }
                setCategoryList(_categoryList);
            }
        });
    }

    const changeDropdownSeniorCategory = (event, data) => {
        setCategory({ ...category, seniorCategoryId: data.value });
        //setSeniorCategoryId(data.value);
    }


    const checkCategoryCode = () => {
        if (category?.categoryHierarchyCode != undefined
            && (
                category?.categoryHierarchyCode.length == 0
                || (
                    category?.categoryHierarchyCode.length > 0
                    && category?.categoryHierarchyCode.length % 3 != 0
                )
            )
        ) {
            toast(translate("Kategori kodu uzunluğu 3 ve katları (001/123456/001234098 vs) olmalıdır."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 5000
            });
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (categoryId != undefined) {
            getCategory();
        } else {
            setCategory({ ...category, tenantId: DataHandler.getUserDataWithKey("tenantId") })
        }
    }, [categoryId]);

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Header as="h3">{translate("Adı")}</Header>
                        <Input
                            className={"greyInput"}
                            autoFocus
                            value={category?.displayName}
                            name="displayName"
                            required="required"
                            size="big"
                            placeholder={translate("Kategori Adı")}
                            onChange={(e) => {
                                setCategory({ ...category, displayName: e.target.value });
                            }}
                        />
                        {categoryList != null && categoryList.length > 0 &&
                            <>
                                <Header as="h3">{translate("Üst Kategori")}</Header>
                                <Dropdown
                                    className={"greyInput"}
                                    value={category?.seniorCategoryId}
                                    name="seniorCategoryId"
                                    required="required"
                                    clearable
                                    selection
                                    options={categoryList}
                                    placeholder={translate("Üst Kategori Seçiniz")}
                                    onChange={changeDropdownSeniorCategory}
                                />
                            </>
                        }
                        {/*<Input*/}
                        {/*    className={"greyInput"}*/}
                        {/*    autoFocus*/}
                        {/*    value={category?.categoryHierarchyCode}*/}
                        {/*    name="categoryHierarchyCode"*/}
                        {/*    required="required"*/}
                        {/*    size="big"*/}
                        {/*    placeholder={translate("Kategori Kodu")}*/}
                        {/*    onChange={(e) => {*/}
                        {/*        setCategory({...category, categoryHierarchyCode: e.target.value});*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button className={"roundedButton"} color="blue"
                            onClick={save}
                            disabled={category?.displayName == undefined }
                        >
                            <Icon name="save" />
                            {translate("Kaydet")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default AddCategory;