import * as React from 'react';
import { useState } from 'react';
import logo from './logo.svg';
import DashboardHeader from '../../components/DashboardHeader';
import Navbar from '../../components/Navbar';
import { media, style } from 'typestyle';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import StoreUserDashboard from "../../StoreUserDashboard";

function Layout(props) {
    const [headerBgColor, setHeaderBgColor] = useState("#ffffff");
    let styles = {
        content: {
            width: "100%",
            height: "100%"
        },
        footer: style({
            width: "100%",
            backgroundColor: "#292828",
            textAlign: "center" as "center",
            bottom: "0",
            color: "white",

            display: "flex",
            alignItems: "center",
            flexDirection: "column" as "column",
            justifyContent: "center",
            position: "absolute" as "absolute",
            height: "2.5rem",

        },
            media({ maxWidth: 820 })
        ),
        container: {
            position: "relative" as "relative",
            minHeight: "100vh"
        }
    }

    return (
        <StoreUserDashboard>
            <div className="layoutClass">
                <Navbar
                    LogoUrl={props.LogoUrl}
                    SettingTitle={props.SettingTitle}
                    IsDefaultSetting={props.IsDefaultSetting}
                    SetIsDefaultSetting={props.SetIsDefaultSetting}
                    FavIconUrl={props.FavIconUrl}
                    SiteTitle={props.SiteTitle}
                    UserRole={props.UserRole}
                    BgColor={"#fff"}
                />

                <div style={styles.content}>
                    {props.children}
                </div>


            </div>
        </StoreUserDashboard>
    );
}

export default Layout;

