import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import {Checkbox, Loader, Transition} from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import {IDataTable} from '../../../interfaces/interfaces'
import {datableFilterChange, digitallicaConfirm, prepareDataForTable} from '../../../helper/HelperFunctions';
import {toast} from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

interface Activity {
    id?: string,
    title?: string,
    description?: string,
    createdDate?: Date
}

const Activities = (props) => {
    var {translate} = TranslateHelper();
    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    //delete selected data
    const deleteActivity = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let module = {
                    id: id
                }
                NetworkRequest("api/Activities/Delete", module).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        getActivities(filterData);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );

    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Geri döndürmek istediğinize emin misiniz ?"),
            () => {
                let module = {
                    id: id
                }
                NetworkRequest("/Activities/UndoDelete", module).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {

                        let index = dataTableModel.data.findIndex(
                            data => data.secretData[0].id === id
                        )

                        if (index > -1) {
                            dataTableModel.data.splice(index, 1);

                            setDataTableModel({
                                header: dataTableModel.header,
                                data: [],
                                transactions: dataTableModel.transactions,
                                filter: dataTableModel.filter,
                                pagination: dataTableModel.pagination,
                                addRowLink: dataTableModel.addRowLink,
                                addExcelLink: dataTableModel.addExcelLink
                            });

                            setDataTableModel(dataTableModel);

                        }
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );

    }
    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }


    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
        setIsDeleted(!data.checked);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
        setIsActive(!data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    let tableHeader = [
        translate("Adı"),
        translate("Açıklama"),
        translate("Konum"),
        translate("Eğitmen"),
        translate("Başlangıç Tarihi"),
        translate("Bitiş Tarihi"),
        translate("Oluşturma Tarihi"),
        translate("Aktif"),
        translate("İşlemler")
    ];
    let transactionArr = [
        {
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/activityEdit",
            type: "edit",

        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: deleteActivity
        },
        {
            icon: "redo",
            desc: translate("Geri al"),
            color: "black",
            link: null,
            type: "undo",
            function: undoDelete
        }
    ];
    let hiddenProps = [
        "id"
    ];
    if (userRole == "HeadAdmin") {
        tableHeader.splice(4, 0, translate("Şirket"));
    } else {
        hiddenProps.push("tenantName");
    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: tableHeader,
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false},
                    {isOnlineActivity: false}
                ]
            }
        ],
        transactions: transactionArr,
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/activityAdd",
        addExcelLink: "/activityImport",
        setRowSelected: setRowSelected,
        noCheckBox: true

    });

    //prepare data for table
    const prepareData = (data) => {
        //hidden column
        if (data.list.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }

        var model = prepareDataForTable(data.list, hiddenProps);

        setTimeout(() => {
            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: translate("Silinen"),
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: translate("Tarih"),
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {

                    count: Math.ceil(data.count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                },
            });
        }, 100);

        setIsLoading(false);
    }

    //get table data from database
    const getActivities = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("api/Activities/GetActivities", filterData).then(response => {
            ////console.log("GetActivities: ", response);
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        ////
    }, [dataTableModel]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        setFilterData(filterData);
        getActivities(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    return (
        <div className="generalContainerForWhiteBg">
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered'/>
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                                   setAllSelected={setAllSelected} selectAllHandler={selectAllHandler}
                                   isLoading={isLoading}/>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default Activities;