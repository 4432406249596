import * as React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Icon, Input, Transition } from 'semantic-ui-react';
import TranslateHelper from '../../../helper/TranslateHelper';

const HelperSearch = (props) => {

    var { translate } = TranslateHelper();
    const [searchField, setSearchField] = useState<string>("");

    const onKeyUpHelper = (e) => {
        if (e.keyCode === 13) {
            props.setShowHelperViewer(true);
            props.setShowFabIcon(false);
        }
    }

    useEffect(() => {
        if (searchField != "" && !isMobile) {
            props.setHelperSearchWord(searchField);
        }
    }, [searchField]);

    return (
        <Transition visible={true} animation='scale' duration={500}>
            <div className={"dictionarySearchBox"}>
                <div className="dictionaryContainer helperContainer">
                    <div className="helperHeader">
                        {translate("Yardım")}
                    </div>
                    <div className="helperBody">
                        <Input
                            className="dictionarySearchInputContainer"
                            size="big"
                            disabled={props.showHelperViewer}
                            onKeyUp={onKeyUpHelper}
                            value={searchField}
                            onChange={(e) => {
                                setSearchField(e.target.value)
                            }}
                            icon={
                                <Icon name='search' inverted circular link onClick={() => {
                                    props.setShowHelperViewer(true)
                                }} />
                            }
                            placeholder={translate("Yardım...")}
                        />

                    </div>
                    <div className="helperFooter">
                        <Button
                            size="big"
                            className="dictionaryListAllKeywordsButton helperFooterButton"
                            onClick={() => {
                                props.setShowHelperViewer(!props.showHelperViewer)
                                props.setShowFabIcon(!props.showFabIcon);
                                props.setShowDictionaryViewer(false);
                            }}
                        >
                            {props.showHelperViewer ? translate("Yardımcıları Kapat") : translate("Tüm Yardımcıları Listele")}
                        </Button>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default HelperSearch;