import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Card, Checkbox, Dropdown, Header, Icon, Input, Loader, Popup, Table, Transition } from 'semantic-ui-react';
import { datableFilterChange, digitallicaConfirm, prepareDataForTable } from '../helper/HelperFunctions';
import { NetworkRequest} from '../helper/NetworkRequest';
import DataTable from './DataTable';
import { IDataTable } from '../interfaces/interfaces';

import { toast } from 'react-toastify';
import { DataHandler } from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

interface QuizAttachToContent {
    id?: string,
    QuizAdd?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const QuizAttachToContent = (props) => {
    var {translate} = TranslateHelper();
    const [filterText, setFilterText] = useState("");
    const [contentId] = useState(props.id);
    const [quiz, setQuiz] = useState({ id: "", title: "" });
    const [quizAndContent, setQuizAndContent] = useState({ contentId: "", quizId: "" });
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isDatatableSetted, setIsDatatableSetted] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [filterData, setFilterData] = useState<any>({});

    const onComplate = (id) => {
        let testData = { id: id };
        NetworkRequest("/Quiz/GetById", testData).then((response) => {
            let result = response.data.detail;
            if (response.data.resultCode == 0 && result != undefined) {
                setQuiz({ id: id, title: result.title });
            }
        });
        setQuizAndContent({ ...quizAndContent, quizId: id });
        props.attachQuiz(id);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    //search function
    useEffect(() => {
        ////console.log(filterText);
    }, [filterText]);

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [

            translate("Adı"),
            translate("Başarı Puanı"),
            translate("Şirket Adı"),
            translate("Oluşturan Kişi"),
            translate("Oluşturma Tarihi"),
            translate("Soru Sayısı"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "plus",
                desc: translate("Sınav Ekle"),
                color: undefined,
                function: onComplate,
                type: "add",

            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/btnClickAndAddAdd",
        isAttachingPage: true,
        noCheckBox: true
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response.list;
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        let model = prepareDataForTable(data, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'searchField',
            'settings',
            'time',
            'description',
        ]);

        ////debugger;
        //setDataTableModel({
        //    header: dataTableModel.header,
        //    data: [],
        //    transactions: dataTableModel.transactions,
        //    filter: dataTableModel.filter,
        //    pagination: dataTableModel.pagination,
        //    addRowLink: dataTableModel.addRowLink
        //});
        setTimeout(() => {

        setDataTableModel({
            ...dataTableModel,
            data: model,
            filter: {
                setSearch: setFilterText,
                search: filterText,
                filterOption: [
                    {
                        desc: translate("Aktif"),
                        defaultValue: isActive,
                        key: "isActive",
                        type: "boolean",
                        onChange: onChangeActive
                    },
                    {
                        desc: translate("Silinen"),
                        defaultValue: isDeleted,
                        key: "isDeleted",
                        type: "boolean",
                        onChange: onChangeDeleted
                    },
                    {
                        desc: translate("Tarih"),
                        defaultValue: [startingDate, dueDate],
                        key: "date",
                        type: "date",
                        onChange: [onChangeDate1, onChangeDate2]
                    }
                ]
            },
            pagination: {
                count: Math.ceil(response.count / 25),
                setCurrentPage: setCurrentPage, currentPage: currentPage
            }
        });
        }, 100);
        setIsLoading(false);
    }

    const deleteQuizAndContent = () => {
        digitallicaConfirm(
            translate("Bu içerikten test kaldırılacak. Emin misiniz?"),
            () => {
                if (contentId != "") {
                    let quizAndContentData = {
                        quizId: quizAndContent.quizId,
                        contentId: contentId
                    }
                    NetworkRequest("/api/SectionContent/RemoveQuiz", quizAndContentData).then((response) => {
                        if (response.data.resultCode == 0) {
                            setQuiz({ id: "", title: "" });
                            setQuizAndContent({ contentId: contentId, quizId: "" });
                            props.attachQuiz("");
                        }
                    });
                } else {
                    setQuizAndContent({
                        quizId: "",
                        contentId: contentId
                    });
                    setQuiz({ id: "", title: "" });
                    props.attachQuiz("");
                }
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );


    }

    //get table data from database
    const getQuizes = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/Quiz/GetQuizes", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
                setIsLoading(false);
            }
        });
    }

    const checkAssignedQuiz = () => {
        let checkData = {
            id: contentId
        }
        NetworkRequest("/Quiz/GetByContentId", checkData).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                if (result != undefined) {
                    setQuiz({ title: result.title, id: result.id })
                    setQuizAndContent({ contentId: contentId, quizId: result.id });
                    setIsLoading(false);
                }
            }
        });
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getQuizes(filterData);
        setFilterData(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    useEffect(() => {
        ////console.log(quiz);
        props.setQuiz(quiz);
    }, [quiz]);

    useEffect(() => {
        if (contentId != "")
            checkAssignedQuiz();
    }, [contentId]);

    return (
        <>
            {quiz.id == ""
                &&
                <div style={{ display: "flex", alignContent: "space-around", width: "100%", flexDirection: "column" }}>
                    <Transition visible={false} animation='scale' duration={500}>
                        <div>
                            <Loader size={"huge"} active inline='centered' />
                        </div>
                    </Transition>
                    <Transition visible={true} animation='scale' duration={500}>
                        <DataTable
                            dataTable={dataTableModel}
                            isAllSelected={isAllSelected}
                            setAllSelected={setAllSelected}
                            selectAllHandler={selectAllHandler}
                            isLoading={isLoading}
                        />
                    </Transition>
                </div>
            }
            {quiz.id != ""
                &&
                <div style={{ width: "100%" }}>
                    <Header>{translate("Bu içeriğe atanmış bir sınav bulunmaktadır.")}</Header>
                    <Table celled fluid={"true"}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{translate("Sınav Adı")}</Table.HeaderCell>
                                <Table.HeaderCell>{translate("İşlem")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{quiz.title}</Table.Cell>
                                <Table.Cell>

                                    <Button basic className={"dangerRedButton"}
                                        onClick={deleteQuizAndContent}
                                    >
                                        {translate("Kaldır")}
                                        
                                    </Button>

                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p style={{ opacity: '.8' }}>
                        {translate("Bu içeriğe farklı bir sınav atamak istiyorsanız, öncelikle var olan sınavın atamasını kaldırmanız gerekir.")}
                        
                    </p>
                </div>
            }
        </>
    );
}

export default QuizAttachToContent;