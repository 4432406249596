import * as React from 'react';
import { useState, useEffect } from 'react';
import { Container, Button, Icon } from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import SubscriptionList from "./Components/SubscriptionList";
import AddSubscription from "./Components/AddSubscription";
import EditSubscription from "./Components/EditSubscription";
import { useParams } from 'react-router-dom';

const Subscriptions = (props) => {
    let { id, page } = useParams();
    //styles
    let styles = {
        activeColor: {
            color: 'green'
        }
    }

    const [isList, setIsList] = useState(true);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState();

    const addSubscriptionType = () => {
        setIsCreate(true);
        setIsEdit(false);
        setIsList(false);
    }

    const editSubscriptionType = (subscriptionType) => {
        setSelectedSubscriptionType(subscriptionType);
        setIsCreate(false);
        setIsEdit(true);
        setIsList(false);
    }

    const showList = () => {
        setIsCreate(false);
        setIsEdit(false);
        setIsList(true);
    }

    const update = (subscriptionType) => {
        return NetworkRequest("/api/SubscriptionTypes/Update", subscriptionType).then((response) => {
            ////
            return response
        });
    }
    useEffect(() => {
        if (page == "2") {
            addSubscriptionType()
        }
    }, [page])

    return (
        <Container>
            {isList
                &&
                <>
                <Button className={"positiveGreenButton marginBottom2"} onClick={addSubscriptionType}>
                        <Icon name="plus" />
                        Yeni
                    </Button>
                <SubscriptionList AddSubscriptionType={addSubscriptionType} EditSubscriptionType={editSubscriptionType} Update={update} IsList={isList} />
                </>
            }
            {isCreate
                &&
                <AddSubscription showList={showList} />
            }
            {isEdit
                &&
                <EditSubscription showList={showList} SubscriptionType={selectedSubscriptionType} Update={update} />
            }
        </Container>
    );
}

export default Subscriptions;