import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Header, Table } from 'semantic-ui-react';
import { datableFilterChange, digitallicaConfirm, prepareDataForTable } from '../helper/HelperFunctions';
import { NetworkRequest} from '../helper/NetworkRequest';
import DataTable from './DataTable';
import { IDataTable } from '../interfaces/interfaces';

import { toast } from 'react-toastify';
import { DataHandler } from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

interface SurveyAttachToContent {
    id?: string,
    SurveyAdd?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}

const SurveyAttachToContent = (props) => {
    var { translate } = TranslateHelper();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filterText, setFilterText] = useState("");
    const [contentId] = useState(props.id);
    const [survey, setSurvey] = useState({ id: "", title: "" });
    const [surveyAndContent, setSurveyAndContent] = useState({ contentId: "", surveyId: "" });
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);

    const [filterData] = useState({
        filterText: filterText,
        currentPage: currentPage,
        isActive: isActive,
        isDeleted: isDeleted,
        startingDate: startingDate,
        dueDate: dueDate,
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });

    const onComplate = (id) => {
        let surveyData = { id: id };
        NetworkRequest("/Surveys/GetById", surveyData).then((response) => {
            let result = response.data.detail;
            if (response.data.resultCode == 0) {
                setSurvey({ id: id, title: result.title });
            }
        });
        setSurveyAndContent({ ...surveyAndContent, surveyId: id });
        props.attachSurvey(id);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        getSurveys(filterData);

    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    //search function
    useEffect(() => {
        ////console.log(filterText);
    }, [filterText]);

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            translate("Adı"),
            translate("Oluşturan Kişi"),
            translate("Oluşturma Tarihi"),
            translate("Soru Sayısı"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: "plus",
                desc: translate("Anket ekle"),
                color: undefined,
                function: onComplate,
                type: "add",

            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: true,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: false,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: Date.now(),
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage,
            currentPage: currentPage
        },
        addRowLink: "/btnClickAndAddAdd",
        isAttachingPage: true, noCheckBox: true,

    });

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    //prepare data for table
    const prepareData = (data) => {
        //hidden column
        dataTableModel.data = prepareDataForTable(data.list, [
            'id',
            'description',
            'companyName',
            'settingsJson',
            'isDeleted',
            'updatedDate',
            'deletedDate'
        ]);
        setTimeout(() => {

        setDataTableModel({
            ...dataTableModel,
            pagination: {
                count: Math.ceil(data.count / 25),
                setCurrentPage: setCurrentPage,
                currentPage: currentPage
            },
            addRowLink: ""
        });
        }, 100);

        //dataTableModel.pagination = getDataCount();
        ////
        setDataTableModel(dataTableModel);
    }

    const deleteSurveyAndContent = () => {

        digitallicaConfirm(
            translate("Bu içerikten anket kaldırılacak. Emin misiniz?"),
            () => {
                if (contentId != "") {
                    let req = {
                        surveyId: survey.id,
                        contentId: contentId
                    }
                    NetworkRequest("/api/SectionContent/RemoveSurvey", req).then((response) => {
                        if (response.data.resultCode == 0) {
                            setSurveyAndContent({ contentId: contentId, surveyId: "" });
                            setSurvey({ id: "", title: "" });
                            props.attachSurvey("");
                        }
                    });
                } else {
                    setSurveyAndContent({
                        surveyId: "",
                        contentId: contentId
                    });
                    setSurvey({ id: "", title: "" });
                    props.attachSurvey("");
                }
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    useEffect(() => {
        ////
    }, [dataTableModel]);

    //get table data from database
    const getSurveys = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/Surveys/GetSurveys", filterData).then(response => {
            ////
            if (response.data.resultCode === 0) {
                prepareData(response.data.detail);
                setIsLoading(false);
            }
        });
    }

    const checkAssignedSurvey = () => {
        let checkData = {
            id: contentId
        }
        NetworkRequest("/Surveys/GetByContentId", checkData).then((response) => {
            if (response.data.resultCode == 0) {
                let resultSurvey = response.data.detail;
                if (resultSurvey != undefined)
                    setSurvey({ title: resultSurvey.title, id: resultSurvey.id })
                setIsLoading(false);
            }
        });
    }

    //onLoad page function
    useEffect(() => {
        getSurveys(filterData);
    }, []);

    useEffect(() => {
        ////console.log(surveyAndContent);
    }, [surveyAndContent]);

    useEffect(() => {
        if (contentId != "")
            checkAssignedSurvey();
    }, [contentId]);

    useEffect(() => {
        if (survey.id != "")
            props.setIsSurveyAdded(true);
    }, [survey]);

    return (
        <>
            {survey.id == "" &&
                <div style={{ display: "flex", alignContent: "space-around", width: "100%", "flexDirection": "column" }}>
                    <DataTable
                    dataTable={dataTableModel}
                    isAllSelected={isAllSelected}
                    setAllSelected={setAllSelected}
                    selectAllHandler={selectAllHandler}
                    isLoading={isLoading}
                    />
                </div>
            }
            {survey.id != "" &&
                <div style={{ width: "100%" }}>
                    <Header>{translate("Bu içeriğe atanmış bir anket bulunmaktadır")}.</Header>
                    <Table celled fluid>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{translate("Anket Adı")}</Table.HeaderCell>
                                <Table.HeaderCell>{translate("İşlem")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{survey.title}</Table.Cell>
                                <Table.Cell>

                                    <Button basic className={"dangerRedButton"}
                                        onClick={deleteSurveyAndContent}
                                    >
                                        {translate("Kaldır")}
                                    </Button>

                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <p style={{ opacity: '.8' }}>
                        {translate("Bu içeriğe farklı bir anket atamak istiyorsanız, öncelikle var olan anketin atamasını kaldırmanız gerekir.")}</p>

                </div>
            }
        </>

    );
}

export default SurveyAttachToContent;