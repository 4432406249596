import React, {useState, useEffect} from 'react';
import {ISurveyQuestionReportCard} from "../interfaces/interfaces";
import SurveyQuestionReportCard from './SurveyQuestionReportCard'
import { v4 as uuidv4 } from 'uuid';

const ReportSurveyQuestionList = ({questions}: ISurveyQuestionReportCard) => {
    //console.log(questions);

    const styles = {
        questionContainer: {
            display: "flex",
            flexDirection:"row" as "row",
            flexWrap: "wrap" as "wrap",
            justifyContent: "space-around",
            alignItems: "flex-start",
            width: "100%"
        }
    }

    return (
        <div style={styles.questionContainer}>
            {questions.map(question => (
                <SurveyQuestionReportCard
                    key={uuidv4()}
                    questionJson={question.questionJson}
                    userAnswer={question.userAnswer}
                    isActive={question.isActive}
                    isDeleted={question.isDeleted}
                    surveyQuestionType={question.surveyQuestionType}
                    createdDate={question.createdDate}
                    
                />
            ))}
        </div>
    )
}

export default ReportSurveyQuestionList;