import React, { useEffect, useState } from 'react';
import { TabPane, Tab } from 'semantic-ui-react'
import TranslateHelper from '../../../helper/TranslateHelper';
import CanvaSettingsComp from '../components/CanvaSettingsComp';

const IntegrationSettings = () => {

    var { translate } = TranslateHelper();

    const panes = [
        {
            menuItem: 'Canva',
            render: () => <TabPane attached={false}><CanvaSettingsComp /></TabPane>,
        }
    ]

    return (
        <div>
            <h1>{translate("Entegrasyonlar")}</h1>

            <div>
                <Tab menu={{ secondary: true }} panes={panes} />
            </div>
        </div>
    );
};

export default IntegrationSettings;