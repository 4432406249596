import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { IDashboard, IDoc, ILanguageDropdown } from "../interfaces/interfaces";
import {
    Dropdown,
    Icon,
    Button,
    Popup,
    SemanticCOLORS,
    Transition,
    Accordion,
    Label,
    Header,
    Image
} from "semantic-ui-react";
import { Link, useLocation } from 'react-router-dom';

import { media, style } from 'typestyle';
import { isMobile } from 'react-device-detect';
import { NetworkRequest } from '../helper/NetworkRequest';
import { toast } from 'react-toastify';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { v4 as uuidv4 } from 'uuid';
import CustomModal from './CustomModal';
import moment from "moment/moment";
import TranslateHelper from '../helper/TranslateHelper';
import { rightSlidePanelMobileContext } from "../StoreUserDashboard";
import { displayProtectedAppDoc } from '../helper/DisplayProtectedDocument';
import { selectedLanguageContext } from "../Store";
import { DataHandler } from '../helper/DataHelper';
import { base64toBlob, getRandomIntInclusive } from "../helper/HelperFunctions";
import { DateFormat, DateFormatDatePicker, DateFormatDatePickerRead } from '../helper/DateFormat';

interface IOption {
    key: SemanticICONS,
    text: string,
    value: string,
    iconColor: SemanticCOLORS
}

function Navbar(props: IDashboard) {

    const location = useLocation();
    var { translate } = TranslateHelper();
    var _ = require('lodash');
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);

    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);

    const { isOpenRightSlidePanelMobile, setIsOpenRightSlidePanelMobile } = useContext(rightSlidePanelMobileContext);

    const [showFile, setShowFile] = useState(false);
    const [documentUrl, setDocumentUrl] = useState<any>("");
    const [pathName, setPathName] = useState(window.location.pathname);
    const [dropdown, setDropdown] = useState(false);
    const [options, setOptions] = useState<Array<IOption>>([{
        key: 'sign-out',
        text: translate('Çıkış Yap'),
        value: "sign-out",
        iconColor: "red"
    }]);
    const [showDocs, setShowDocs] = useState(false);
    const [userPhoto, setUserPhoto] = useState(DataHandler.getUserDataWithKey("photo"));
    const [documentManagerUrl, setDocumentManagerUrl] = useState(DataHandler.getDocmanagerUrl());
    const [activeIndex, setActiveIndex] = useState<any>(-1);
    const [isOpenRightSlidePanel, setIsOpenRightSlidePanel] = useState<boolean>(false);
    const [docs, setDocs] = useState<Array<IDoc>>([]);

    const styles = {
        DashboardHeaderStyle: style({
            width: "100%",
            height: "100px",
            padding: "20px",
            backgroundColor: props.BgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: isMobile ? "space-around" : "center",
            gap: isMobile ? "1.5rem" : "unset",
            boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
        },
            media({
                maxWidth: 820
            })
        ),
        profileDropdownStyle: {
            padding: "9px 19px",
            borderRadius: "20px",
            background: "linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
            color: "white",
            fontWeight: "bolder" as "bolder"
        },

        forceLoginStyle: {
            position: "sticky" as "sticky",
            top: "0",
            padding: "9px 19px",
            borderRadius: "10px",
            background: "linear-gradient(0deg, rgba(216,83,83,1) 30%, rgba(77,254,255,1) 100%)",
            color: "#151515",
            fontWeight: "bolder" as "bolder"
        },

        logoStyle: style({
            height: "50px"
        }, media({ maxWidth: 820 }, { height: "50px", marginLeft: "-31%" })
        ),


        bottomLogo: style({
            marginLeft: !DataHandler.getUserDataWithKey("isLogin") ? "20px" : "273px",
            opacity: !DataHandler.getUserDataWithKey("isLogin") ? "1" : props.OpenLeftNavState ? "1" : "0",
            cursor: "pointer",
            transition: "all 0.5s ease-in-out",
        }, media({ maxWidth: 820 }, { marginLeft: "20px", })
        ),
        dropdownContainer: style({
            display: "flex!important",
            gap: "1rem",
            borderRadius: "8px",
            background: "transparent",
            flexDirection: "column" as "column",
            alignItems: "center",
            width: "auto"
        },
            media({ maxWidth: 820 }, { width: "auto", })
        ),
        userContainer: {
            display: "flex",
            alignItems: "center",
            gap: ".3em"
        },
        dropdownVisible: {
            background: "rgb(255 255 255 / 69%)",
            borderRadius: "12px",
            padding: "14%",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "10px",
            position: "absolute" as "absolute",
            top: "90px",
            right: "8px",
            zIndex: "100",
            width: "210px",
            marginTop: "5px",
            boxShadow: "rgb(149 157 165 / 28%) 0px 8px 24px",
            backdropFilter: "blur(24px)"
        },
        borderBottom: {
            borderBottom: "1px solid #EBEBEB"

        },
        logo: {
            height: "50px",
        },
        DashboardHeaderStyleMobile: style({
            width: "100%",
            height: "54px",
            padding: "10px",
            backgroundColor: "#FFFFFF",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "none",
            boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
            position: "relative"
        },
            media({ maxWidth: 820 })
        ),
        docsContainer: {
            background: "#F0F0F0",

            borderRadius: "8px",
            padding: "10px 25px 10px",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "10px",
            position: "absolute" as "absolute",
            top: "80px",
            zIndex: "100",
            width: "270px",
            marginTop: "5px",
            marginLeft: "-110px"
        },
        blocker: {
            position: "fixed" as "fixed",
            top: "0",
            left: "0",
            bottom: "0",
            right: "0",
            content: ' ',
            background: "rgba(0, 0, 0, 0)"
        },
        btnDark: {
            width: "228px",
            height: "53px",
            right: "0px",
            top: "700px",
            background: "rgb(41, 40, 40)",
            color: "white",
            borderRadius: "8px",
        },
        thumbnailPhoto: {
            width: "50vw" as "50vw",

        },

        NavBarMobile: {
            transition: "all 1.2s ease-in-out",
            right: isOpenRightSlidePanel ? "0px" : "-70%"
        },


    }

    const openRightSlidePanel = () => {
        if (isMobile) {
            setIsOpenRightSlidePanel(!isOpenRightSlidePanel);
            //global store variable = rightSlidePanelMobileContext
            setIsOpenRightSlidePanelMobile(!isOpenRightSlidePanelMobile);
        }
    }

    const closeRightSlidePanel = (e: any) => {
        if (e.firstChild?.className == "rightSlidePanel") {
            setIsOpenRightSlidePanel(false);
            setIsOpenRightSlidePanelMobile(false);
        }
    }

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    }

    const trigger = (
        <span className={"navbarProfileDropdownUserPhotoContainer"} style={styles.userContainer}>
            <Icon color="blue" onClick={openRightSlidePanel} name="user circle" size="huge" />
            {/*<Image*/}
            {/*    id="navuserPhotoImage-1"*/}
            {/*    onClick={openRightSlidePanel}*/}
            {/*    className={"navbarProfileDropdownUserPhoto"}*/}
            {/*    width="70px"*/}
            {/*    height="70px"*/}
            {/*    style={{ borderRadius: "50%" }}*/}
            {/*    source={displayProtectedAppDoc(userPhoto, "navuserPhotoImage-1")}*/}
            {/*    onError={({ currentTarget }) => {*/}
            {/*        currentTarget.onerror = null; // prevents looping*/}
            {/*        currentTarget.src = displayProtectedAppDoc("/Documents/DefaultUserImage/user.png", "navuserPhotoImage-1");*/}
            {/*    }}*/}
            {/*/>*/}
            {DataHandler.getUserDataWithKey("isLogin") && DataHandler.getUserDataWithKey("forceLogin") && !isMobile
                &&
                <Icon name="exclamation" color="red" />
            }
        </span>
    );

    const zoomFile = (document) => {
        if (document != undefined) {
            if (!document.urlstartsWith("http")) {
                let blob = base64toBlob(document.url, document.mimeType);
                setDocumentUrl(URL.createObjectURL(blob));
            } else {
                setDocumentUrl(displayProtectedAppDoc(document.url));
            }
            setShowFile(true);
        }
    }

    const addHours = function (h) {
        var date = new Date();
        date.setTime(date.getTime() + (h * 60 * 60 * 1000));
        return date;
    }

    const changeDropdown = (event, data) => {
        switch (data.value) {
            case 'sign-out':
                DataHandler.logOutUser();
                break;
            case 'change-role':
                var optionsCopy = _.cloneDeep(options);
                optionsCopy.find(x => x.key == "toggle on").iconColor = "black";
                optionsCopy.find(x => x.key == "toggle on").key = "toggle off";
                optionsCopy.find(x => x.text == data.text).key = "toggle on";
                optionsCopy.find(x => x.text == data.text).iconColor = "green";
                setOptions(optionsCopy);

                setTimeout(() => {
                    //debugger;
                    DataHandler.setDataWithKey("userRole", data.roleName);
                    DataHandler.setDataWithKey("roleId", data.roleId);
                    window.location.href = "/";
                }, 500)
                break;
            default:
                toast(data.value + translate(" Sayfası henüz yapım aşamasındadır."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
                break;
        }
    }

    const getDocs = () => {
        // //debugger;
        if (docs.length == 0) {
            NetworkRequest("/api/Documents/GetDocuments", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
                ////
                if (response.data.resultCode == 0) {
                    const files = response.data.detail;
                    setDocs(files);
                }
            });
        }
    }

    const leftPanel = () => {
        let extensions = ["pdf", "png", "jpeg", "jpg", "png", "gif", "webm", "pptx", "xl"]
        props.CloseLeftNavbar(true);
    }

    const getGreeting = () => {
        let hour = moment().format("HH");
        if (hour.startsWith("0")) {
            hour = hour.replace("0", "");
        }
        let parsed = JSON.parse(hour);
        if (parsed <= 10) {
            return <span style={{ width: "100%", textAlign: "center", display: "flex", gap: "0.3em" }}>
                <Label className={"navbarProfileIconSun"} style={{ backgroundColor: "unset" }}>
                    <Icon style={{ margin: "unset" }} size="large" color="yellow" name='sun' />
                </Label>
                {translate("Günaydın")}
            </span>;
        } else if (parsed > 10 && parsed < 15) {
            return <><Icon className={"navbarProfileIconHand"} name={"hand paper outline"}
                color={"yellow"} />{translate("Merhaba")}</>;
        } else if (parsed >= 15) {
            return <span style={{ width: "100%", textAlign: "center", display: "flex", gap: "0.3em" }}>
                <Icon className={"navbarProfileIconMoon"} name={"moon"}
                    color={"yellow"} />{translate("İyi Akşamlar")}</span>;
        }
    }

    const createIconByFileType = (url: string | undefined) => {

        let extension = url?.split(".").pop();

        switch (extension) {
            case 'txt':
            case 'doc':
            case 'docx':
            case 'pdf':
                return <><Icon name={"file pdf outline"} size={"large"} color={"yellow"} /></>
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'svg':
                return <><Icon name={"images outline"} size={"large"} color={"yellow"} /></>
                break;
            case 'mp3':
            case 'wav':
            case 'wma':
            case 'ogg':
                return <><Icon name={"music"} size={"large"} color={"yellow"} /></>
                break;
            case 'mp4':
            case 'avi':
            case 'wmv':
            case 'mkv':
            case 'flv':
                return <><Icon name={"box"} size={"large"} color={"yellow"} /></>
                break;
            case 'zip':
            case 'rar':
            case '7z':
            case 'gz':
                return <><Icon name={"file video outline"} size={"large"} color={"yellow"} /></>
                break;
            default:
                // Handle other file typess
                return <><Icon name={"file outline"} size={"large"} color={"yellow"} /></>
        }
    }

    useEffect(() => {
        setPathName(window.location.pathname)
    }, [window.location.pathname]);

    useEffect(() => {
        ////console.log("props.IsOpenRightSlidePanel: ", isOpenRightSlidePanel);
    }, [isOpenRightSlidePanel]);

    useEffect(() => {
        isMobile && setIsOpenRightSlidePanel(isOpenRightSlidePanelMobile);
    }, [isOpenRightSlidePanelMobile])

    const getActiveLanguages = () => {
        if (languageListDropdown.length == 0) {
            NetworkRequest("/api/Language/GetActiveLanguages", null).then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;

                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: "",
                            value: l.value
                        }
                        setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                    })
                }
            });
        }
    }

    const getUserClaimLanguage = () => {
        if ((selectedLanguage.languageId == null || selectedLanguage.languageId == "") && DataHandler.getUserDataWithKey("userRole") == "User" && isMobile) {
            let tenantData = {
                tenantId: DataHandler.getUserDataWithKey("tenantId"),
                userId: DataHandler.getUserDataWithKey("userId")
            };

            NetworkRequest("/api/Language/GetUserClaimLanguage", tenantData).then((response) => {
                if (response.data.resultCode == 0) {
                    //console.log("selectedLanguage çalıştı", selectedLanguage);
                    var language = response.data.detail;
                    if (language != null) {
                        //setSelectedDefaultLanguage(language.value);
                        setSelectedLanguage(language);
                    }
                }
            });
        }


    }

    const handleChanges = (event: any, data: any) => {
        setSelectedDefaultLanguage(data.value);
        setSelectedLanguage(data.value);

        let userClaimData = {
            userId: DataHandler.getUserDataWithKey("userId"),
            languageFlagCode: data.value
        }

        NetworkRequest("/api/Language/AddUserClaimLanguage", userClaimData).then((response) => {

            if (response.data.resultCode == 0) {
                var language = response.data.detail;

                if (language != null) {
                    //setSelectedDefaultLanguage(language);
                    setSelectedLanguage(language);
                }

                toast(translate(response.data.message), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 10000
                });

                setTimeout(() => {
                    window.location.reload()
                }, 2000);

            } else {
                toast(translate(response.data.message), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 10000
                });
            }
        });
    }


    useEffect(() => {
        (async function asyncFunc() {
            await getDocs();
            // getActiveLanguages();
            // getUserClaimLanguage();

            var rolesObject = DataHandler.getUserDataWithKey("userRoleList");
            if (rolesObject != undefined) {
                if (rolesObject != undefined && rolesObject.length > 1) {
                    let roleList: Array<any> = [];
                    var userRole = DataHandler.getUserDataWithKey("userRole");
                    rolesObject.map((item) => {
                        var newItem;
                        newItem = {
                            key: userRole == item.roleName ? 'toggle on' : 'toggle off',
                            text: item.displayName,
                            roleName: item.roleName,
                            roleId: item.roleID,
                            value: "change-role",
                            iconColor: userRole == item.roleName ? "green" : "grey",
                        };
                        roleList.push(newItem);
                    })
                    options.map((item) => {
                        roleList.push(item);
                    })

                    setOptions(roleList)
                }
            }

            let title = DataHandler.getUserDataWithKey("settingTitle");
            if (title != undefined) {
                document.title = title;
            }

            var link = document.getElementById("siteFavIcon");
            if (link != undefined) {
                if (DataHandler.getUserDataWithKey("favIconUrl") == null) {
                    // link.setAttribute('href', displayProtectedAppDoc("/Documents/Orientation/Test/DefaultFavIcon/DigitallicaFavIcon.ico"));
                } else {
                    var favicon = "data:image/x-icon;" + DataHandler.getUserDataWithKey("favIconUrl")?.split(";")[1]?.trim();
                    link.setAttribute('href', favicon);
                }
            }


        })();
    }, []);

    useEffect(() => {
        getActiveLanguages();
        getUserClaimLanguage();
    }, [selectedLanguage.languageId])

    return (
        <>
            {isMobile
                &&
                <Transition visible={isOpenRightSlidePanel} animation='fade left' duration={800}>
                    <div
                        onClick={(e) => {
                            closeRightSlidePanel(e.target);
                        }}
                        className={"rightSlidePanelContainer"}
                    >

                        <div
                            style={styles.NavBarMobile}
                            className={"rightSlidePanel"}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column" as "column",
                                gap: "1.2em",
                                padding: "10%"
                            }}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column" as "column"
                                }}>
                                    <Image
                                        id="navbarUserImage"
                                        className={"navbarProfileDropdownUserPhoto"}
                                        width="37px"
                                        height="37px"
                                        style={{ borderRadius: "50%" }}
                                        source={displayProtectedAppDoc(userPhoto, "navbarUserImage")}
                                        onError={({ currentTarget }) => {
                                            currentTarget.src = displayProtectedAppDoc("/Documents/DefaultUserImage/user.png", "navbarUserImage")
                                        }}
                                    />
                                    <span style={{ fontSize: "1.2em", padding: "5%" }}>{DataHandler.getUserDataWithKey("fullname")}</span>
                                    <p>
                                        <Popup
                                            content={translate("İşe giriş tarihi")}
                                            trigger={
                                                <Label
                                                    style={{ marginTop: "2%" }}
                                                    size={"large"}
                                                    content={DataHandler.getUserDataWithKey("workStartDate").toString()}
                                                    icon='calendar alternate'
                                                />
                                            }
                                        />
                                    </p>
                                    {/*<p>{DateFormat(, false)}</p>*/}

                                </div>
                                <div>
                                    {(DataHandler.getUserDataWithKey("isLogin") && DataHandler.getUserDataWithKey("forceLogin")) &&
                                        <div style={{
                                            backgroundColor: "#7e79ef",
                                            padding: "8%",
                                            borderRadius: "30px",
                                            color: "#fafafa",
                                            fontSize: "1.1em"
                                        }}>
                                            <span style={{ cursor: "pointer" }} onClick={() => { DataHandler.bringOldLogin(); }}>
                                                <Icon
                                                    name="arrow left" /> {(DataHandler.getUserDataWithKey("adminFullName")) + " " + translate("Geri Dön")}
                                            </span>
                                        </div>
                                    }
                                </div>
                                {DataHandler.getUserDataWithKey("userRole") != "User"
                                    &&
                                    <Accordion styled>
                                        <Accordion.Title
                                            active={activeIndex === 0}
                                            index={0}
                                            onClick={handleClick}
                                        >
                                            <Icon name='dropdown' />
                                            <div style={{ fontSize: "1.2em" }}>{translate("Dokümanlar")}</div>
                                        </Accordion.Title>
                                        <Transition visible={activeIndex === 0} animation='slide down'
                                            duration={500}>
                                            <div>
                                                <Accordion.Content active={activeIndex === 0}>
                                                    {docs.map((doc, i) => (
                                                        <Popup key={uuidv4()}
                                                            position="left center"
                                                            wide='very'
                                                            inverted
                                                            content={
                                                                <>
                                                                    <p> {doc.description}</p>
                                                                    <p>{doc.createdUserName} {translate("Tarafından Yüklendi")}</p>
                                                                </>
                                                            }
                                                            trigger={
                                                                <p style={{
                                                                    display: "flex",
                                                                    alignItems: "baseline",
                                                                    gap: ".5rem"
                                                                }}>
                                                                    <span className={"docListItemStyle"}
                                                                        onClick={() => {
                                                                            zoomFile(doc)
                                                                        }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            fontWeight: "700"
                                                                        }}>
                                                                        {doc.name}
                                                                    </span>
                                                                </p>
                                                            }
                                                        />
                                                    ))}
                                                </Accordion.Content>
                                            </div>

                                        </Transition>
                                    </Accordion>
                                }

                                <div>
                                    <div
                                        style={{ fontSize: "1.4em", margin: "3%", marginBottom: "6%", color: "#606060" }}>
                                        <Icon name={"language"} /> {translate("Dil Seçimi")}</div>

                                    <div style={{ width: isMobile ? "76%" : "auto", margin: "0 auto" }}>
                                        <Dropdown
                                            fluid={true}
                                            className="greyInputDropdown moduleDetailLanguageSelectDropdown"
                                            placeholder={translate("Dil Seçiniz")}
                                            clearable
                                            search
                                            selection
                                            //value={selectedDefaultLanguage}
                                            value={selectedLanguage.value}
                                            // defaultValue={selectedDefaultLanguage}
                                            options={languageListDropdown}
                                            onChange={(event, data) => {
                                                handleChanges(event, data)
                                            }}
                                        />
                                    </div>

                                </div>


                                {options.map((option, i) => (
                                    <div key={uuidv4()}
                                        onClick={(e) => {
                                            changeDropdown(e, option)
                                        }}
                                        style={{ backgroundColor: "#f5f5f5", padding: "4%", borderRadius: "30px" }}
                                    >
                                        <span style={{ cursor: "pointer", fontSize: "1.1em" }}>
                                            <Icon size="big" name={option.key} color={option.iconColor} />
                                            {option.text}
                                        </span>
                                        {i != (options.length - 1) &&
                                            <span style={styles.borderBottom}></span>
                                        }
                                    </div>
                                ))}

                                <span onClick={() => {
                                    window.location.reload();
                                }} style={{ color: "#4183c4" }}>
                                    Sayfayı yenilemek için tıklayınız
                                    <Icon title={translate("Sayfa Yenile")} name="redo" style={{ marginLeft: '7px' }} />
                                </span>

                            </div>
                        </div>
                    </div>
                </Transition>

            }

            {
                (
                    (isMobile && (DataHandler.getUserDataWithKey("userRole") == "User" || DataHandler.getUserDataWithKey("userRole") == "Admin"))
                    || (!isMobile && (DataHandler.getUserDataWithKey("userRole") == "Admin") || DataHandler.getUserDataWithKey("userRole") == "SuperAdmin" || DataHandler.getUserDataWithKey("userRole") == "HeadAdmin")
                    || (((!isMobile && (DataHandler.getUserDataWithKey("userRole") == "Buddy") || DataHandler.getUserDataWithKey("userRole") == "HumanResources" || DataHandler.getUserDataWithKey("userRole") == "ItManager" || DataHandler.getUserDataWithKey("userRole") == "AdminManager" || DataHandler.getUserDataWithKey("userRole") == "Manager") || DataHandler.getUserDataWithKey("userRole") == "PersonalAffairs") && location.pathname.indexOf("course/management") == -1)
                )
                &&
                <div className={"dashboardNavbarContainer " + styles.DashboardHeaderStyle}>
                    {isMobile && DataHandler.getUserDataWithKey("userRole") != "User" &&
                        <div className={"navbarHamburgerIconContainer"} onClick={leftPanel}><Icon
                            style={{ color: "#818181" }}
                            size="big" name="list" />
                        </div>}


                    {DataHandler.getUserDataWithKey("userRole") != "Admin" && DataHandler.getUserDataWithKey("userRole") != "HeadAdmin" && DataHandler.getUserDataWithKey("userRole") != undefined
                        &&
                        <Link to="/">
                            <div>
                                <Image id="navbarLogoImage"
                                    className={"userDashboardBrandLogo " + styles.logoStyle}
                                    src={displayProtectedAppDoc(props.LogoUrl, "navbarLogoImage")}
                                    alt="logo"
                                />
                            </div>
                        </Link>
                    }

                    {showDocs
                        &&
                        <div style={styles.blocker} onClick={() => {
                            setShowDocs(false)
                        }}>
                        </div>
                    }
                    {DataHandler.getUserDataWithKey("isLogin") && !isMobile
                        ? <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            alignItems: "center",
                            position: isMobile ? "unset" : "absolute" as "absolute",
                            right: isMobile ? "3rem" : "2rem"
                        }}>
                            {docs.length > 0 && props.IsSuperAdmin != true && DataHandler.getUserDataWithKey("userRole") == "Admin"
                                &&
                                <span>
                                    {!isMobile
                                        &&
                                        <Button
                                            className={"navbarDocumentButton"}
                                            icon
                                            size="big"
                                            onClick={() => {
                                                setShowDocs(!showDocs)
                                                setDropdown(prevState => false);
                                            }}
                                        >
                                            <Icon name='file pdf' />
                                        </Button>
                                    }

                                    <Transition visible={showDocs} animation={"fade down"} duration={500}>
                                        <div className={"dropdownVisible dropdownFile"}>
                                            <h3>{translate("Dokümanlar")}</h3>
                                            {docs.map((doc, i) => (
                                                <Popup key={uuidv4()}
                                                    position="left center"
                                                    wide='very'
                                                    inverted
                                                    content={
                                                        <>
                                                            <p> {doc.description}</p>
                                                            <p>{doc.createdUserName} {translate("Tarafından Yüklendi")}</p>
                                                        </>
                                                    }
                                                    trigger={
                                                        <div
                                                            onClick={() => {
                                                                zoomFile(doc)
                                                            }}
                                                            className={"dropdownFileItem"}>

                                                            <div>{createIconByFileType(doc.url)}</div>


                                                            <div style={{ cursor: "pointer", fontWeight: "700" }}>
                                                                {i + 1}. {doc.name}
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </Transition>


                                </span>
                            }
                            <div
                                className={"navbarProfileDropdownItemContainer"}
                                style={{
                                    display: "flex",
                                    gap: "5px",
                                    flexDirection: "column" as "column",
                                    alignItems: "center"
                                }}
                            >
                                {!isMobile &&
                                    <div
                                        className={"dropdownContainerClassVersion " + styles.dropdownContainer}
                                    >

                                        <Dropdown
                                            trigger={trigger}
                                            onChange={changeDropdown}
                                            icon={false}
                                            onClick={() => {
                                                setDropdown(!dropdown)
                                                setShowDocs(false);
                                            }}
                                        />


                                    </div>
                                }


                                <div>
                                    <Transition visible={dropdown && !isMobile} animation='fade down' duration={500}>
                                        <div className={"dropdownVisible"}>
                                            {(DataHandler.getUserDataWithKey("isLogin") && DataHandler.getUserDataWithKey("forceLogin"))
                                                &&
                                                <>
                                                    <span className={"navbarBacktoAccountClass"} onClick={() => { DataHandler.bringOldLogin(); }}>
                                                        <Icon name="arrow left" />
                                                        {(DataHandler.getUserDataWithKey("adminFullName")) + " " + translate("Geri Dön")}
                                                    </span>
                                                    <span style={styles.borderBottom}></span>
                                                </>
                                            }

                                            <div className={"headerProfileContainer"}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column" as "column",
                                                    alignItems: "center"
                                                }}>
                                                    <h3 style={{ padding: "unset" }}>
                                                        {/*<Icon name={"hand paper outline"} color={"yellow"} />*/}
                                                        {getGreeting()}
                                                    </h3>
                                                    {DataHandler.getUserDataWithKey("fullname")}

                                                    {/*:TODO work start date is undefined !*/}
                                                    <Popup
                                                       
                                                        content={translate("İşe giriş tarihi")}
                                                            trigger={
                                                                <Button className={"workStartDateLabelClass"} labelPosition='left' icon circular>
                                                                    <Icon name='calendar alternate' />

                                                                    {DataHandler.getUserDataWithKey("workStartDate")}
                                                            </Button>
                                                                
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <Transition visible={dropdown} animation="fade">
                                                <div>
                                                    {options.map((option, i) => (
                                                        <div
                                                            onClick={(e) => {
                                                                changeDropdown(e, option)
                                                            }}
                                                            className="navbarOptionMatterContent"
                                                            key={uuidv4()}
                                                        >
                                                            <span
                                                                style={{ cursor: "pointer" }}
                                                                onClick={(e) => {
                                                                    changeDropdown(e, option)
                                                                }}
                                                            >
                                                                <Icon size={"large"} name={option.key}
                                                                    color={option.iconColor} />
                                                                {option.text}
                                                            </span>
                                                            {i != (options.length - 1) &&
                                                                <span style={styles.borderBottom}></span>
                                                            }
                                                        </div>
                                                    )
                                                    )}
                                                </div>
                                            </Transition>


                                        </div>
                                    </Transition>
                                </div>


                            </div>
                        </div>
                        : <div></div>
                    }


                </div>
            }
            {showFile
                &&
                <CustomModal
                    setShow={setShowFile}
                    isFullScreen={true}
                    docUrl={documentUrl}
                />
            }
            <div className={styles.DashboardHeaderStyleMobile}>
                <div style={{
                    position: "absolute", right: "10px", alignItems: "center", display: "flex",
                }}>
                    <span style={{ color: "rgb(40,40,40)", fontWeight: "400", fontSize: "13px", marginRight: "9px" }}> {translate("Hoş geldiniz")} {DataHandler.getUserDataWithKey("name")} </span>
                    <Image
                        id="navUserPhotoImage-2"
                        width="37px"
                        height="37px"
                        style={{ borderRadius: "50%", border: "1px solid #292828" }}
                        source={displayProtectedAppDoc(userPhoto, "navUserPhotoImage-2")}
                    />
                </div>
            </div>
        </>
    );
}

export default memo(Navbar);
