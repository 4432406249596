import React, { useEffect, useState } from 'react';
import { datableFilterChange, downloadFile, prepareDataForTable } from "../../../helper/HelperFunctions";
import { NetworkRequest } from "../../../helper/NetworkRequest";

import { IDataTable } from "../../../interfaces/interfaces";
import { Checkbox, Loader, Transition } from "semantic-ui-react";
import DataTable from "../../../components/DataTable";
import { useParams } from 'react-router-dom';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const SurveyQuestionDetailReport = () => {
    var {translate} = TranslateHelper();
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>("");
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterData, setFilterData] = useState<any>({});
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { id } = useParams();

    //const setAllSelected = (data) => {
    //    setIsAllSelected(data.checked);
    //}

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTable, data.checked, 0, setDataTable, setIsActive);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTable, data.checked, 1, setDataTable, setIsDeleted);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTable({
                header: dataTable.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTable({
                header: dataTableModel.header,
                data: [],
                transactions: dataTable.transactions,
                filter: dataTable.filter,
                pagination: dataTable.pagination,
                addRowLink: dataTable.addRowLink,
                noCheckBox: dataTable.noCheckBox
            });
            setDataTable(dataTableModel);
        }, 100);
    }

    //prepare data for table
    const prepareData = (response) => {
        let data = response;

        if (data.list != undefined && data.list.length > 0) {
            data.list.map(data => {
                var div = document.createElement('div');
                div.innerHTML = data.questionBody;
                let images = div.getElementsByTagName('img');

                if (typeof images == "object") {
                    for (let i = 0; i < images.length; i++) {
                        ////console.log(images[i]);
                        images[i].setAttribute("style", "width:100%;");
                    }

                    data.questionBody = div.innerHTML;
                }
            });
        }

        //hidden column
        let model = prepareDataForTable(data.list, [
            'id',
            'searchField',
            'sectionContentId',
            'userId'
        ]);

        //setDataTableModel({
        //    data: [],

        //});

        setDataTable({
            data: model,
            header: dataTable.header,
            transactions: dataTable.transactions,
            filter: {
                setSearch: setFilterText,
                search: filterText,
                filterOption: [
                    {
                        desc: translate("Aktif"),
                        defaultValue: isActive,
                        key: "isActive",
                        type: "boolean",
                        onChange: onChangeActive
                    },
                    {
                        desc: translate("Silinen"),
                        defaultValue: isDeleted,
                        key: "isDeleted",
                        type: "boolean",
                        onChange: onChangeDeleted
                    },
                    {
                        desc: translate("Tarih"),
                        defaultValue: [startingDate, dueDate],
                        key: "date",
                        type: "date",
                        onChange: [onChangeDate1, onChangeDate2]
                    }
                ]
            },
            noCheckBox: dataTable.noCheckBox,
            addRowLink: dataTable.addRowLink,
            pagination: {
                count: Math.ceil(
                    data.count / 25
                ), setCurrentPage: setCurrentPage, currentPage: currentPage
            }
        });
        setIsLoading(false);
    }

    const getSurveyQuestionReport = (filterData?) => {
        NetworkRequest("api/Report/GetSurveyQuestionReport", filterData).then((response) => {
            let resultCode = response.data.resultCode
            if (resultCode === 0) {
                prepareData(response.data.detail);
            }
        });
    }
    const getSurveyQuestionExcelReport = () => {
        let data = {
            SurveyId: id
        }
        NetworkRequest("api/Report/GetSurveyQuestionExcelReport", data).then((response) => {
            downloadFile(response.data, translate("Anket Soru Detay Raporu"));
        });
    }


    //filter's every change
    useEffect(() => {

        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            surveyId: id
        }
        setFilterData(filterData);
        getSurveyQuestionReport(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    //table data default
    const [dataTable, setDataTable] = useState<IDataTable>({
        header: [
            translate("Soru Metni"),
            translate("Soru Tipi"),
            translate("Anket Adı"),
            translate("Modül Adı"),
            translate("Ad"),
            translate("Soyad"),
            translate("E-Posta"),
            translate("Son Giriş Tarihi"),
            translate("İşe Başlama Tarihi"),
            translate("Seçenek A"),
            translate("Seçenek B"),
            translate("Seçenek C"),
            translate("Seçenek D"),
            translate("Seçenek E"),
            translate("Verdiği Cevap"),
            translate("Cevaplama Tarihi"),
            translate("Oluşturma Tarihi"),
            translate("Cevaplama Sayısı")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            // {
            //     icon: "chart bar",
            //     desc: "Rapor",
            //     color: "orange",
            //     link: "/srvyQstnDetailReport",
            //     type: "report"
            // }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: isDeleted,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        noCheckBox: true,
        addRowLink: "",
        setRowSelected: setRowSelected,
        isAttachingPage: true
    });
    return (
        <div className={"generalContainerForWhiteBg"}>
            <Transition visible={false} animation='scale' duration={500}>
                <div>
                    <Loader size={"huge"} active inline='centered' />
                </div>
            </Transition>
            <Transition visible={true} animation='scale' duration={500}>
                <div>
                    <DataTable dataTable={dataTable} isAllSelected={isAllSelected} selectAllHandler={selectAllHandler} exportExcelFunction={getSurveyQuestionExcelReport} isLoading={isLoading} />
                </div>
            </Transition>
        </div>
    );
};

export default SurveyQuestionDetailReport;