import * as React from 'react';
import { useState, useContext } from 'react';
import { Grid, Label, Button, Icon, Input, Image } from 'semantic-ui-react';
import { NetworkRequest } from '../../helper/NetworkRequest';
import { ISiteSettingCreateUpdateComp } from '../../interfaces/interfaces';

import { toast } from 'react-toastify';
import {digitallicaConfirm, getRandomIntInclusive} from '../../helper/HelperFunctions';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../helper/DataHelper';
import TranslateHelper from "../../helper/TranslateHelper";


export const SiteSettingCreateUpdateComponent = (props: ISiteSettingCreateUpdateComp) => {
    var { translate } = TranslateHelper();
    var _ = require('lodash');
    let styles = {
        font20: {
            fontSize: '20px'
        },
        siteOn: {
            color: 'green',
            position: 'relative',
            top: '3px'
        },
        siteOff: {
            color: 'red',
            position: 'relative',
            top: '3px'
        },
        marginR1: {
            marginRight: '1%'
        },
        fileInputStyle: {
            width: "100%"
        },
        siteLogo: {
            maxWidth: '250px',
            maxHeight: '200px'
        },
        infoLoginLabel: {
            marginLeft: "1%",
            color: "white",
            backgroundColor: "black"
        }
    }

    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [siteSetting, setSiteSetting] = useState(props.siteSetting);
    const [title, setTitle] = useState(siteSetting?.settingTitle ?? '');
    const [logoFile, setLogoFile] = useState<any>();
    const [iconFile, setIconFile] = useState<any>();
    const [loginPhotoFile, setLoginPhotoFile] = useState<any>();



    const handleChangeLogo = (e) => {
        setIsFileUploaded(true);
        setLogoFile(e.target.files[0]);
    }

    const handleChangeIcon = (e) => {
        setIsFileUploaded(true);
        setIconFile(e.target.files[0]);
    }

    const handleChangeLoginPhoto = (e) => {
        setIsFileUploaded(true);
        setLoginPhotoFile(e.target.files[0]);
    }

    const inputChanged = (value) => {
        setTitle(value);
        let cloneModel = siteSetting;
        if (cloneModel != undefined) {
            cloneModel.settingTitle = value;
            setSiteSetting(cloneModel);
        }
    }

    const requester = (url: string, data: any, isFormData: any) => {
        return NetworkRequest(url, data, isFormData).then((response) => {
            ////

            if (response.data.resultCode == 5) {
                toast("Logo veya favicon boş bırakılamaz.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
            if (response.data.resultCode == 0) {
                //TODO: maybe change the success message
                toast(translate("Başarılı"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2500 });
                props.getSiteSetting();
                props.setIsCreateEnabled(false);
                props.setIsEditEnabled(false);
                props.setSettingTitle(title);
                DataHandler.setDataWithKey('siteTitle', title);
                if (isFormData == true) {
                    DataHandler.updateLogoInfo(response.data.detail.logoUrl, response.data.detail.favIconUrl, false);
                    let logoUrl = response.data.detail.logoUrl;
                    let iconUrl = response.data.detail.favIconUrl;
                
                    DataHandler.setDataWithKey('isDefaultSetting', false);

                    DataHandler.removeLogoImage();

                    DataHandler.setDataWithKey('logoUrl', props.siteSetting?.logoUrl);



                    DataHandler.setDataWithKey('favIconUrl', iconUrl);
                    var link = document.getElementById("siteFavIcon");
                    if (link != undefined) {
                        link.setAttribute('href', DataHandler.getUserDataWithKey("favIconUrl") + "?v=" + getRandomIntInclusive());
                    }
                    props.setLogoUrl(logoUrl);
                    props.setFavIconUrl(iconUrl);
                }
            }
            return response;
        });
    }

    const save = () => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            let url = 'api/SiteSetting/Add';
            if (!siteSetting?.siteSettingId?.startsWith('00000000')) {
                url = 'api/SiteSetting/Update';
            }
            if (!isFileUploaded && !siteSetting?.siteSettingId?.startsWith('00000000')) {
                return requester('api/SiteSetting/UpdateTitle', siteSetting, false);
            }
            let data = _.cloneDeep(siteSetting);
            data.appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';
            var formData = new FormData();
       
            formData.append('LogoFile', logoFile);
            formData.append('IconFile', iconFile);
            formData.append('LoginPhotoFile', loginPhotoFile);
            formData.append('SiteSetting', JSON.stringify(data));
            return requester(url, formData, true);
        }, () => { toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }



    return (
        <Grid columns={1}>
            <Grid.Row>
                <Grid.Column>
                    <Label style={styles.marginR1}>{translate("Site Başlığı")}</Label>
                    <Input value={title} onChange={(e) => { inputChanged(e.target.value); }} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Label style={styles.marginR1}>{translate("Logo")}</Label>
                    <Input
                        name="photo"
                        size="small"
                        placeholder={translate('Logo yükle')}
                        width="300px"
                        height="300px"
                        type="file"
                        accept="image/*"
                        onChange={handleChangeLogo}
                    />
                </Grid.Column>
            </Grid.Row>
            {props.isEditEnabled
                &&
                <Grid.Row>
                    <Grid.Column>
                        <Image id="siteSettLogoImage" style={styles.siteLogo} source={props.siteSetting?.logoUrl} />
                    </Grid.Column>
                </Grid.Row>
            }
            <Grid.Row>
                <Grid.Column>
                    <Label style={styles.marginR1}>{translate("İkon")}</Label>
                    <Input
                        name="favIcon"
                        size="small"
                        placeholder={translate('İkon yükle')}
                        width="300px"
                        height="300px"
                        type="file"
                        accept="image/*"
                        onChange={handleChangeIcon}
                    />
                </Grid.Column>
            </Grid.Row>
            {props.isEditEnabled
                &&
                <Grid.Row>
                    <Grid.Column>
                        <Image id="siteSettFavImage" style={styles.siteLogo} source={props.siteSetting?.favIconUrl} />
                    </Grid.Column>
                </Grid.Row>
            }
            <Grid.Row>
                <Grid.Column>
                    <Label style={styles.marginR1}>{translate("Login Görsel")}</Label>
                    <Input
                        name="favIcon"
                        size="small"
                        placeholder={translate('Login Görseli Ekle')}
                        width="300px"
                        height="300px"
                        type="file"
                        accept="image/*"
                        onChange={handleChangeLoginPhoto}
                    />
                    <Label style={styles.infoLoginLabel}>{translate("Yükleyeceğiniz login görseli, 1:1 (kare) formatı ve minimum HD kalitede olmalı.")}</Label>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <Button style={{float:"right"}} circular className={"ingOrange"} onClick={save}>
                        <Icon name='save' />
                        {translate("Kaydet")}
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}