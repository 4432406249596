import * as React from 'react';
import DropZone from '../../../components/DropZone';
import TranslateHelper from '../../../helper/TranslateHelper';

const ActivityImport = () => {
    var {translate} = TranslateHelper();
    let dropZoneProps = {
        links: {
            uploadLink: "api/Activities/ExcelImport",
            returnLink: "/activities"
        },
        table: {
            headers: [translate("Başlık"), translate("Açıklama"), translate("Eğitmen"), translate("Hata Nedeni")],
            propertyNames: ["title", "description", "teacherName"]
        },
        messages: {
            1:translate("Başlangıç tarihi bitiş tarihinden büyük olamaz."),
            2:translate("Başlık boş geçilemez"),
            4:translate("Başlangıç tarihi boş geçilemez"),
            8:translate("Bitiş tarihi boş geçilemez"),
            16:translate("Ekleme hatası.")
        },
        dataName: translate("Etkinlik"),
        //TODO: bu link kırık
        // excelTemplateFileLink:"/files/docs/excel-import/activityImport.xlsx"
        excelTemplateFileLink:"/files/docs/excel-import/activityImport.xlsx"
    }
    let styles = {

        importContainer: {
            display: "flex",
            width: "100%",
            marginTop:"3%"
        }
    }
    
    return (
        <div style={styles.importContainer}>
            <DropZone
                dropZoneProps={dropZoneProps}
            />
        </div>
    )
}

export default ActivityImport;