import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Input, Label, Icon, Dropdown } from 'semantic-ui-react';
import { NetworkRequest } from '../../../../helper/NetworkRequest';
import { IDropdown } from '../../../../interfaces/interfaces';
import { DateFormat } from '../../../../helper/DateFormat';

const EditTenantSubscription = (props) => {

    let styles = {
        labelWidth: {
            width: '15%'
        }
    }
    const [displayName, setDisplayName] = useState(props.TenantSubscription.displayName);
    const [subscriptionKey, setSubscriptionKey] = useState(props.TenantSubscription.subscriptionKey);
    const [licenseCount, setLicenseCount] = useState(props.TenantSubscription.licenseCount);
    const [startDate, setStartDate] = useState(DateFormat(props.TenantSubscription.startDate, true));
    const [endDate, setEndDate] = useState(DateFormat(props.TenantSubscription.endDate, true));
    const [tenantId, setTenantId] = useState(props.TenantSubscription.tenantId);
    const [subscriptionTypeId, setSubscriptionTypeId] = useState(props.TenantSubscription.subscriptionTypeId);
    const [tenants, setTenants] = useState<Array<IDropdown>>([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState<Array<IDropdown>>([]);
    //console.log("startDate: ", startDate);
    //console.log("endDate: ", endDate);

    const save = () => {
        let model = {
            tenantSubscriptionId: props.TenantSubscription.tenantSubscriptionId,
            displayName: displayName,
            subscriptionKey: subscriptionKey,
            licenseCount: licenseCount,
            startDate: startDate,
            endDate: endDate,
            tenantId: tenantId,
            subscriptionTypeId: subscriptionTypeId
        };
        ////console.log("model:", model);
        NetworkRequest("/api/TenantSubscriptions/Update", model).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                props.showList();
            }
        });
    }

    const getTenants = () => {
        NetworkRequest("/api/Tenants/GetAll").then((response) => {
            ////
            if (response.data.resultCode === 0) {
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response.data.detail.list.length; i++) {
                    list.push({
                        key: response.data.detail.list[i].tenantId,
                        value: response.data.detail.list[i].tenantId,
                        text: response.data.detail.list[i].displayName
                    })
                }
                setTenants(list);
            }
        });
    }

    const getSubscriptionTypes = () => {
        NetworkRequest("/api/SubscriptionTypes/GetAll").then((response) => {
            ////
            if (response.data.resultCode === 0) {
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response.data.detail.list.length; i++) {
                    list.push({
                        key: response.data.detail.list[i].subscriptionTypeId,
                        value: response.data.detail.list[i].subscriptionTypeId,
                        text: response.data.detail.list[i].displayName
                    })
                }
                setSubscriptionTypes(list);
            }
        });
    }

    useEffect(() => {
        getTenants();
        getSubscriptionTypes();
    }, []);

    return (
        <div>
            <Grid>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Adı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input placeholder='Adı'
                            value={displayName}
                            onChange={(e) => { setDisplayName(e.target.value); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Abonelik Anahtarı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input placeholder='Abonelik Anahtarı'
                            value={subscriptionKey}
                            onChange={(e) => { setSubscriptionKey(e.target.value); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Lisans Sayısı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input type="number"
                            value={licenseCount}
                            placeholder='Lisans Sayısı'
                            onChange={(e) => { setLicenseCount(JSON.parse(e.target.value)); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Abone:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Dropdown
                            value={tenantId}
                            required="required"
                            clearable
                            options={tenants}
                            selection
                            placeholder="Abone Seçiniz"
                            onChange={(e, data) => { setTenantId(data.value); }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Abonelik Tipi:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Dropdown
                            value={subscriptionTypeId}
                            required="required"
                            clearable
                            options={subscriptionTypes}
                            selection
                            placeholder="Abonelik Tipi Seçiniz"
                            onChange={(e, data) => { setSubscriptionTypeId(data.value); }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Başlama Tarihi:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            value={startDate}
                            timezone="[[timezone]]"
                            name="workStartDateRangeStart"
                            required="required"
                            type="date"
                            placeholder='Başlama Tarihi'
                            onChange={(e) => { setStartDate(e.target.value) }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Bitiş Tarihi:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            value={endDate}
                            timezone="[[timezone]]"
                            name="workStartDateRangeStart"
                            required="required"
                            type="date"
                            placeholder='Bitiş Tarihi'
                            onChange={(e) => { setEndDate(e.target.value) }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button className={"dangerRedButton"} onClick={props.showList}>
                            <Icon name="arrow left" />
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button className={"positiveGreenButton"} onClick={save}>
                            <Icon name="check" />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default EditTenantSubscription;