import axios from 'axios';
import { INetworkHeaders } from '../interfaces/interfaces';
import { isNullOrEmpty, sessionControlForLogout } from "./HelperFunctions";
import * as DOMPurify from 'dompurify';
import { toast } from "react-toastify";
import { DataHandler } from './DataHelper';

const ResultCode = {
    Success: 0,
    Fail: 1,
    Unauthorized: 2,
    UnexpectedError: 3,
    InvalidCredentials: 4,
    MissingMandatoryFields: 5,
    AlreadyExists: 6
};

const getHeaders = (formData?: any) => {
    /*    debugger;*/
    var store = DataHandler.getStore();
    var headers = {
        'Authorization': store != undefined ? store.token : undefined,
        'Access-Control-Allow-Origin': "*"
    } as INetworkHeaders | undefined | any;
    if (formData != undefined) {
        headers['Content-Type'] = undefined;
    }
    return headers;
}

const controlObjectString = (object: any) => {
    if (object == null) return;

    for (let key in object) {
        if (object[key] == undefined) continue;
        if (typeof object[key] === "string") {
            if (object[key] != undefined) {
                let purifyString = DOMPurify.sanitize(object[key]);

                if (purifyString != object[key]) {

                    if (!(key == "embedCode" || key == "body" || key == "answer" || key == "questionJSON")) {
                        toast("Uygulama devam etmenize izin vermedi", {
                            type: 'error',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 5000
                        });
                        return false;
                    }

                    return true;

                }
                object[key] = DOMPurify.sanitize(object[key]);
            }
        } else if (typeof object[key] === "object") {

            if (Array.isArray(object[key])) {
                for (let arrayItem in object[key]) {
                    for (let arrayItemKey in Object.keys(arrayItem)) {
                        if (object[key][arrayItemKey] == undefined) continue;
                        if (typeof arrayItem[arrayItemKey] == "string") {
                            if (object[key][arrayItemKey] != undefined) {
                                let purifyString = DOMPurify.sanitize(arrayItem[arrayItemKey]);
                                if (purifyString != object[key][arrayItemKey]) {
                                    // toast("Uygulama devam etmenize izin vermedi", {
                                    //     type: 'error',
                                    //     theme: 'dark',
                                    //     position: 'top-center',
                                    //     autoClose: 5000
                                    // });
                                    // return Promise.reject();
                                }
                                // object[key][arrayItemKey] = DOMPurify.sanitize(arrayItem[arrayItemKey]);
                            }
                        } else if (typeof arrayItem[arrayItemKey] === "object") {
                            return controlObjectString(arrayItem[arrayItemKey]);
                        }
                    }
                }
            } else {
                return controlObjectString(object[key]);
            }
        }
    }
}

const resolveHandler = (value) => {
    return value as any;
}

const promiseMaker = (value) => {
    return new Promise(function (resolve, reject) {
        resolve(resolveHandler(value));
        reject('String Kontrol Promise Hata');
    });
};

export const NetworkRequest = (url, data?, isFormData?: boolean, customHeaders?: any, keepApart?: boolean, setProgress?) => {
    if (data != undefined && data.htmlJson == undefined && keepApart != true) {
        var resultControl = controlObjectString(data);
        if (resultControl == false) {
            return promiseMaker({ data: { resultCode: 1, detail: "Data is corrupted." } }) as any;
        }
    }
    let apiUrl = DataHandler.getApiUrl();
    // debugger;
    if (isNullOrEmpty(apiUrl)) {
        return promiseMaker("No Api Url");
    }
    url = !url.startsWith("/") && !url.startsWith("http") ? "/" + url : url;
    url = apiUrl + url;
    let headers = getHeaders(isFormData);

    if (customHeaders != undefined) {
        headers = customHeaders;
    }
    return axios.post(url, data, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
            if (!setProgress)
                return;
            const progress = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2);
            //setProgress(progress);
            setProgress(progress)
        },
    }).then((response: any) => {
        if (response.resultCode === ResultCode.Unauthorized) {
            ////debugger;
            //alert("ResultCode.Unauthorized")

            /*      debugger;*/
       
            DataHandler.removeSecret();
            window.location.href = '/homeLogin';
            return;
        }
        return response;
    }).catch((error) => {
        /*console.log("error",error);*/
        if (error.toString().indexOf("Request failed with status code 401") != -1) {
        /*     debugger;*/


            DataHandler.removeSecret();
            window.location.href = '/homeLogin';
        } else {
            /*  debugger;*/
       
            sessionControlForLogout();
            //console.log("DATA", data);
            //console.log("url", url);
        }
        ////console.log("NetworkRequestWithoutData error: ", error);
        return {
            data: { resultCode: 1, detail: error }
        };
    });
}

