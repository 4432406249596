import {motion} from 'framer-motion';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {Icon} from "semantic-ui-react";

const PasswordPolicyChecker = (props: any) => {
    return (
        props != undefined
        && props.PasswordPolicyResponseList != undefined
        && props.PasswordPolicyResponseList.length > 0
        && props.IsShow
            ?
            <motion.div
                animate={props.PasswordPolicyResponseList.length > 0 ? {
                    opacity: [0, 1],
                    left: props.Left == undefined ? ["-170%", "-150%"] : props.Left
                } : {opacity: [1, 0], left: props.Left == undefined ? ["-170%", "-150%"] : props.Left}}
                transition={{duration: .8}}
                className={"passwordPolicyCheckerContainer"}>
                {props.PasswordPolicyResponseList.map((controlItem) => (
                    <motion.div
                        key={uuidv4()}
                        className={"passwordPolicyCheckerItem"}
                    >
                        <Icon
                            color={controlItem.isPassed ? "green" : "red"}
                            name={controlItem.isPassed ? "check" : "cancel"}
                        />
                        <span style={{color: controlItem.isPassed ? "green" : "red"}}>
                            {controlItem.requirementsInfoText}
                        </span>
                    </motion.div>
                ))}
            </motion.div>
            : <div></div>
    )
}

export default PasswordPolicyChecker;