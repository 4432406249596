import * as React from 'react';
import { Button, Grid, Icon, Image } from 'semantic-ui-react';
import WhiteLogo from '../../../images/home/v2/white_logo.png';
import InstagramThreads from '../../../images/home/v2/threads-app-icon.png';
import TranslateHelper from "../../../helper/TranslateHelper";
import {isLandscapeTablet} from "../../../helper/HelperFunctions";
const HomeV2Footer = (props) => {
    var {translateFromEng} = TranslateHelper();
    return (
        <>
            <Grid className="homeV2FooterFirstSection">
                <Grid.Row stretched centered={true} columns={6} className={"homeV2FooterSection"}>
                    <Grid.Column textAlign="left" mobile={16} computer={3} tablet={2} className={"homeV2FooterSectionLogo"}>
                        <Image className="footerLogo" src={WhiteLogo} />
                    </Grid.Column>
                    <Grid.Column className="commonFooterSectionClassName" textAlign="left" computer={isLandscapeTablet() ? 3 : 2} tablet={3}>
                        <Grid.Row>
                            <Grid.Column computer={12} mobile={16} className="homeV2FooterTitle">
                                {translateFromEng("Product")}
                                
                            </Grid.Column>

                            <Grid.Column computer={12} mobile={16} className="homeV2FooterSubLinks">
                                <Grid.Row>
                                    <Grid.Column computer={12}>{translateFromEng("Overview")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Onboarding")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Offboarding")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Integration")}</Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>

                    <Grid.Column className="commonFooterSectionClassName" textAlign="left" computer={isLandscapeTablet() ? 3 : 2} tablet={4}>
                        <Grid.Row>
                            <Grid.Column computer={12} mobile={16} tablet={3} className="homeV2FooterTitle">
                                {translateFromEng("Resources")}
                            </Grid.Column>

                            <Grid.Column computer={12} mobile={16} className="homeV2FooterSubLinks">
                                <Grid.Row>
                                    <Grid.Column computer={12}>{translateFromEng("Help Center")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Blog")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("API Doc.")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Glossary")}</Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>

                    <Grid.Column className="commonFooterSectionClassName" textAlign="left" computer={3} tablet={3}>
                        <Grid.Row>
                            <Grid.Column computer={12} mobile={16}  className="homeV2FooterTitle">
                                {translateFromEng("Legal Compliance")}
                            </Grid.Column>

                            <Grid.Column computer={12} mobile={16} className="homeV2FooterSubLinks">
                                <Grid.Row>
                                    <Grid.Column computer={12}>{translateFromEng("Privacy Policy")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Terms of Service")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Security")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Service Level Agreement")}</Grid.Column>
                                    <Grid.Column computer={12}>{translateFromEng("Data Privacy Framework")}</Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>

                    <Grid.Column className="commonFooterSectionClassName" textAlign="left" computer={2} tablet={3}>
                        <Grid.Row>
                            <Grid.Column computer={12} mobile={16} tablet={3} className="homeV2FooterTitle">
                                {translateFromEng("Partner")}
                            </Grid.Column>

                            <Grid.Column computer={12} mobile={16} tablet={3} className="homeV2FooterSubLinks">
                                <Grid.Row>
                                    <Grid.Column computer={12}>{translateFromEng("Affilliates")}</Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>

                    <Grid.Column textAlign="left" computer={2} tablet={2}>
                        <Grid.Row>
                            <Grid.Column computer={12} mobile={16} className="homeV2FooterTitle">
                                {translateFromEng("Quick Links")}
                            </Grid.Column>

                            <Grid.Column computer={12} mobile={16} className="homeV2FooterSubLinks">
                                <Grid.Row>
                                    <Grid.Column computer={12} mobile={16}>{translateFromEng("Login")}</Grid.Column>
                                    <Grid.Column computer={12} mobile={16}>{translateFromEng("Contact Us")}</Grid.Column>
                                    <Grid.Column computer={12} mobile={16}>{translateFromEng("Book a Demo")}</Grid.Column>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Grid className="homeV2FooterSecondSection">
                <Grid.Row className={"displayFlexCenter"} columns={3} mobile={16} tablet={16}>
                    <Grid.Column mobile={16} tablet={16} computer={isLandscapeTablet() ? 16 : 5}>
                        <Grid columns={4} mobile={16} className="homeV2FooterSecondLinks">
                            {/*<Grid.Column mobile={3} tablet={3}>{translateFromEng("FAQ")}</Grid.Column>*/}
                            {/*<Grid.Column mobile={3} tablet={3}>{translateFromEng("Privacy")}</Grid.Column>*/}
                            {/*<Grid.Column mobile={4} tablet={3}>{translateFromEng("Security")}</Grid.Column>*/}
                            {/*<Grid.Column  mobile={3} tablet={3} computer={6}>{translateFromEng("T.O.S.")}</Grid.Column>*/}
                        </Grid>
                    </Grid.Column>

                    <Grid.Column className="homeV2BuddyAppCopyRight" textAlign="center" mobile={16} tablet={16} computer={isLandscapeTablet() ? 16 : 5}>
                        <div><Icon name="copyright outline" /> {translateFromEng("Oriento App.")}</div>
                        <div style={{fontSize:"0.8em", color:"#ccc"} }>version:1.0.13</div> 
                    </Grid.Column>

                    <Grid.Column textAlign="right" className="homeV2SocialMediaIconsSection" mobile={16} computer={isLandscapeTablet() ? 16 : 5} tablet={16}>
                        <Grid columns={4} mobile={16} className="homeV2SocialMediaIcons" textAlign="right">
                            <Grid.Column mobile={4} computer={3} tablet={3}><Icon name="youtube" /></Grid.Column>
                            <Grid.Column mobile={4} computer={3} tablet={3}><Icon name="linkedin" /></Grid.Column>
                            <Grid.Column mobile={4} computer={3} tablet={3}><Icon name="instagram" /></Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default HomeV2Footer;