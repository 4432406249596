import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QuestionAttachToQuiz from '../../../components/QuestionAttachToQuiz';

const QuestionAndQuiz = (props) => {

    let { quizId } = useParams();
    const [quizIdHook] = useState(quizId);

    return (
        <div>
            <QuestionAttachToQuiz id={quizIdHook} />
        </div>
    );
}

export default QuestionAndQuiz;

