import React, { useState, useEffect } from 'react';
import { Input, Icon, Button, Grid, Flag, Transition, Image, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { DataHandler } from '../../../helper/DataHelper';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { v4 as uuidv4 } from 'uuid';
import { isNullOrEmpty } from '../../../helper/HelperFunctions';
import TranslateHelper from "../../../helper/TranslateHelper";

const HelperViewer = (props) => {

    let _ = require("lodash");
    const [loader, setLoader] = useState(true);
    const [searchText, setSearchText] = useState<any>(props.helperSearchWord);
    const [appHelper, setAppHelper] = useState<any>({});
    const [appHelpers, setAppHelpers] = useState<Array<any>>([]);
    const [groups, setGroups] = useState<Array<any>>([]);
    var { translate } = TranslateHelper();

    const getHelpers = () => {

        setAppHelper({});
        var req = {
            id: DataHandler.getUserDataWithKey("tenantId")
        };
        NetworkRequest('api/ApplicationHelpers/GetAppHelpers', req).then((response) => {
            if (response.data.resultCode == 0) {
                let groups = _.groupBy(response.data.detail, "typeName");
                groups = _.toArray(groups);
                setGroups(groups);
                setAppHelpers(response.data.detail);
            }
            setLoader(false);
        });
    }

    const search = (text) => {
        var req = {
            text: text,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        };
        NetworkRequest('api/ApplicationHelpers/Search', req).then((response) => {
            if (response.data.resultCode == 0) {
                setTimeout(() => {
                    if (response.data.detail.length > 1) {
                        setAppHelpers(response.data.detail);
                    } else if (response.data.detail.length == 1) {
                        setAppHelper(response.data.detail[0]);
                    }
                }, 480);
            } else {
                toast("'" + text + "' " + translate("aramasına sonuç bulunamadı."), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
            setLoader(false);
        });
    }

    useEffect(() => {
        if (!isNullOrEmpty(searchText)) {
            search(searchText);
        } else {
            getHelpers();
        }
    }, [searchText]);

    useEffect(() => {
        getHelpers();
    }, []);

    return (
        <Transition visible={!loader} animation='fade left' duration={200}>
            <div className="dictionaryViewerContainer">
                <Grid className="dictionaryViewerContainer" divided="vertically">
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <div className="dictionary-container-box">
                                <div className="width100 textCenter">
                                    {appHelper.id != undefined
                                        &&
                                        <Button circular icon='angle left' onClick={() => { setAppHelper({ ...appHelper, id: undefined }); setSearchText(undefined); }} />
                                    }
                                    <Button circular icon='close' onClick={() => { setLoader(true); setTimeout(() => { props.setShowHelperViewer(false) }, 250) }} />
                                </div>
                                {appHelper.id != undefined
                                    &&
                                    <>
                                        <div className="helperQuestion">
                                            {appHelper.question}
                                        </div>
                                        <div className="helperAnswer">
                                            <div dangerouslySetInnerHTML={{ __html: appHelper.answer }}></div>
                                        </div>
                                    </>
                                }
                                {appHelper.id == undefined
                                    &&
                                    <Grid className="helperGrid" columns={1}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <div className="dictionary-searchbar">
                                                    <Input
                                                        icon='search'
                                                        placeholder={translate('Arama..')}
                                                        fluid
                                                        className="dictionary-searchbar-input"
                                                        value={searchText}
                                                        onChange={o => { setSearchText(o.target.value) }}
                                                    />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {groups.map((group) => (
                                            <React.Fragment key={uuidv4()}>
                                                <Grid.Row>
                                                    <Label className="helperTypeLabel">{group[0].typeName}</Label>
                                                </Grid.Row>
                                                {group.map((helper) => (
                                                    <Grid.Row key={uuidv4()}
                                                        className="helperItemRow cusrsorPointer"
                                                    >
                                                        <Grid.Column width={16}
                                                            onClick={(e) => { setAppHelper(helper); }}
                                                        >
                                                            {helper.question}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Transition>
    );
}

export default HelperViewer;