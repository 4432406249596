import { IAuthentication } from "../interfaces/interfaces";
import { isNullOrEmpty, redirectByUserRole } from "./HelperFunctions";
import secureLocalStorage from "react-secure-storage";

export class DataHandler {

    static test = undefined;
    static isAppAuth = undefined;
    static savePhysical = undefined;
    static appSiteTitle = "Buddy";
    static useCaptcha = true;
    static useLdap = true;

    static writeTest(data) {
        this.test = data;
    }

    static writeDocmanagerUrl(data) {
        secureLocalStorage.setItem("documentManagerUrl", data);
    }

    static writePkceVerifier(data) {
        secureLocalStorage.setItem("pkceVerifier", data);
    }

    static getPkceVerifier() {
        return secureLocalStorage.getItem("pkceVerifier");
    }

    static removePkceVerifier() {
        secureLocalStorage.removeItem("pkceVerifier");
    }

    static writeGAKey(data) {
        secureLocalStorage.setItem("GAKey", data);
    }

    static writeSiteKey(data) {
        secureLocalStorage.setItem("SiteKey", data);
    }

    static getDocmanagerUrl() {
        return secureLocalStorage.getItem("documentManagerUrl");
    }

    static writeApiUrl(data) {
        secureLocalStorage.setItem("ApiUrl", data);
    }

    static writeUseCaptcha(data) {
        secureLocalStorage.setItem("UseCaptcha", data);
        this.useCaptcha = data;
    }

    static writeUseLdap(data) {
        secureLocalStorage.setItem("UseLdap", data);
        this.useLdap = data;
    }

    static removeLogoImage() {
        secureLocalStorage.removeItem("logoUrl");
    }

    static getApiUrl(): string | undefined {
        /*        debugger;*/
        return secureLocalStorage.getItem("ApiUrl")?.toString();
    }

    static getUseCaptcha(): boolean {
        return this.useCaptcha;
    }

    static getUseLdap(): boolean {
        return this.useLdap
    }

    static getGAKey(): string {

        var key = secureLocalStorage.getItem("GAKey");
        key = key != null ? key.toString() : key;

        if (typeof key === "string" && key.trim().length > 0)
            return key.toString();
        else
            return "";
    }

    static getSiteKey(): string {

        var key = secureLocalStorage.getItem("SiteKey");
        key = key != null ? key.toString() : key;

        if (typeof key === "string" && key.trim().length > 0)
            return key.toString();
        else
            return "";
    }

    static writeAppSiteTitle(data) {
        this.appSiteTitle = data;
    }

    static writeIsAppAuth(data) {
        this.isAppAuth = data;
    }

    static writeSavePhysical(data) {
        this.savePhysical = data;
    }

    static pushLoginData(userData) {
        let newModel: IAuthentication = {
            token: userData.token,
            name: userData.name,
            surname: userData.surname,
            fullname: userData.fullname,
            userId: userData.userId,
            email: userData.email,
            userRole: userData.userRole[0],
            userRoleList: userData.userRoleList,
            roleId: userData.userRoleList[0].roleID,
            isLogin: true,
            photo: userData.photo,
            tenantId: userData.tenantId,
            logoUrl: userData.logoUrl,
            favIconUrl: userData.favIconUrl,
            siteTitle: userData.siteTitle,
            isDefaultSetting: userData.isDefaultSetting,
            isActive: userData.isActive,
            isBaseTenant: userData.isBaseTenant,
            isFirstLogin: userData.isFirstLogin,
            shouldAcceptTOUC: userData.shouldAcceptTOUC,
            workStartDate: userData.workStartDate,
            loginTimestamp: new Date().getTime(),
            adminEmail: "",
            forceLogin: false,
            adminToken: "",
            adminName: "",
            adminSurname: "",
            adminFullName: "",
            adminUserId: "",
            adminUserRole: "",
            adminPhoto: "",
            adminWorkStartDate: "",
            adminUserRoleList: "",
            adminTenantId: "",
            adminLogoUrl: ""
        };

        /*debugger;*/
        secureLocalStorage.setItem("Secret", newModel);
    }

    static pushSuperAdminLoginData(superAdmin) {
     
        let item: any = {};

        item.userId = superAdmin.userId;

        item.token = superAdmin.token;
        item.name = superAdmin.name;
        item.surname = superAdmin.surname;
        item.fullname = superAdmin.fullname;
        item.email = superAdmin.email;
        item.userRole = superAdmin.userRole;
        item.userRoleList = superAdmin.userRoleList;
       
        item.roleId = superAdmin.userRoleList[0].roleID;
        item.isLogin = true;
        item.photo = superAdmin.photo;
        item.tenantId = superAdmin.tenantId;
        item.logoUrl = superAdmin.logoUrl;
        item.favIconUrl = superAdmin.favIconUrl;
        item.siteTitle = superAdmin.siteTitle;
        item.isDefaultSetting = superAdmin.isDefaultSetting;
        item.isActive = superAdmin.isActive;
        //item.isBaseTenant = newUser.isBaseTenant;
        //item.isFirstLogin = newUser.isFirstLogin;
        item.workStartDate = superAdmin.workStartDate;
        item.loginTimestamp = new Date().getTime();
        //item.adminEmail = oldUser.email;
        item.forceLogin = false;
        //item.adminToken = oldUser.token;
        //item.adminName = oldUser.name;
        //item.adminSurname = oldUser.surname;
        //item.adminFullName = oldUser.name + " " + oldUser.surname;
        //item.adminUserId = oldUser.userId;

        //item.adminUserId = oldUser.id != undefined ? oldUser.id : oldUser.userId;
        //item.adminUserRole = oldUser.userRole;
        //item.adminPhoto = oldUser.photo;
        //item.adminWorkStartDate = oldUser.workStartDate;
        //item.adminUserRoleList = oldUser.userRoleList;
        //item.adminTenantId = oldUser.tenantId;
        //item.adminWorkStartDate = oldUser.workStartDate;
        //item.adminLogoUrl = oldUser.logoUrl;

        //secureLocalStorage.removeItem("Secret");
        secureLocalStorage.setItem("SuperAdminSecret", item);
    }

    static pushForceLoginData(newUser, oldUser) {

        if (oldUser.token == newUser.token) {
            return;
        }

        let item: any = secureLocalStorage.getItem("Secret");
        secureLocalStorage.setItem("OldSecret", item);

        item.userId = newUser.id != undefined ? newUser.id : newUser.userId;

        // item.userId = newUser.userId;
        // item.userId = newUser.id;
        item.token = newUser.token;
        item.name = newUser.name;
        item.surname = newUser.surname;
        item.fullname = newUser.fullname;
        item.email = newUser.email;
        item.userRole = newUser.userRole;
        item.userRoleList = newUser.userRoleList;
        //item.roleId = newUser.roleId;
        item.roleId = newUser.userRoleList[0].roleID;
        item.isLogin = true;
        item.photo = newUser.photo;
        item.tenantId = newUser.tenantId;
        item.logoUrl = newUser.logoUrl;
        item.favIconUrl = newUser.favIconUrl;
        item.siteTitle = newUser.siteTitle;
        item.isDefaultSetting = newUser.isDefaultSetting;
        item.isActive = newUser.isActive;
        item.isBaseTenant = newUser.isBaseTenant;
        item.isFirstLogin = newUser.isFirstLogin;
        item.workStartDate = newUser.workStartDate;
        item.loginTimestamp = new Date().getTime();
        item.adminEmail = oldUser.email;
        item.forceLogin = true;
        item.adminToken = oldUser.token;
        item.adminName = oldUser.name;
        item.adminSurname = oldUser.surname;
        item.adminFullName = oldUser.name + " " + oldUser.surname;
        //item.adminUserId = oldUser.userId;
   
        item.adminUserId = oldUser.id != undefined ? oldUser.id : oldUser.userId;
        item.adminUserRole = oldUser.userRole;
        item.adminPhoto = oldUser.photo;
        item.adminWorkStartDate = oldUser.workStartDate;
        item.adminUserRoleList = oldUser.userRoleList;
        item.adminTenantId = oldUser.tenantId;
        item.adminWorkStartDate = oldUser.workStartDate;
        item.adminLogoUrl = oldUser.logoUrl;

        secureLocalStorage.removeItem("Secret");
        secureLocalStorage.setItem("Secret", item);
    }

    static logOutUser(isStopRedirect?) {

        //alert("logoutuser");
        //removeCookies();
        ////debugger;
        secureLocalStorage.removeItem("Secret");
        //secureLocalStorage.removeItem("Pages");
    
        if (isStopRedirect != true && window.location.href.indexOf("notAuthorized") == -1) {
            redirectByUserRole(undefined);
        }
    }

    static removeSecret() {

        secureLocalStorage.removeItem("Secret");
    }

    static bringOldLogin() {
  
        let oldItem: any = secureLocalStorage.getItem("OldSecret") || secureLocalStorage.getItem("SuperAdminSecret");
        secureLocalStorage.removeItem("Secret");
        secureLocalStorage.removeItem("OldSecret");
        secureLocalStorage.setItem("Secret", oldItem);

        redirectByUserRole(oldItem.userRole);
    }

    static setDataWithKey(key, value) {
        let item: any = secureLocalStorage.getItem("Secret");
        item[key] = value;
        secureLocalStorage.setItem("Secret", item);
    }

    static resetLoginExpireTime() {
        let item: any = secureLocalStorage.getItem("Secret");
        if (item) {
            item.loginTimestamp = new Date().getTime();
            secureLocalStorage.setItem("Secret", item);
        }

    }

    static updateLogoInfo(logoUrl, favIconUrl, isDefSetting) {
        let item: any = secureLocalStorage.getItem("Secret");
        item.logoUrl = logoUrl;
        item.favIconUrl = favIconUrl;
        item.isDefaultSetting = isDefSetting;
        secureLocalStorage.setItem("Secret", item);
    }

    static updatePhotoInfo(photo) {
        let item: any = secureLocalStorage.getItem("Secret");
        item.photo = photo;
        secureLocalStorage.setItem("Secret", item);
    }

    static getUserDataWithKey(key) {
        
        let item: any = secureLocalStorage.getItem("Secret");
        if (item == null) {
            return undefined;
        }
        if (key == "siteTitle" && item[key] != undefined && item[key] == this.appSiteTitle) {
            return this.appSiteTitle;
        }

        // console.log(key + ":" + item[key]);
        return item[key];
    }

    static getStore() {
        /*    debugger;*/
        let item: any = secureLocalStorage.getItem("Secret");
        return item;
    }

    static getOldStore() {
        let item: any = secureLocalStorage.getItem("OldSecret");
        return item;
    }

    static isExpired() {
        //36 saat sonra false
        let item: any = secureLocalStorage.getItem("Secret");
        if (item != undefined && item?.loginTimestamp != undefined && new Date(item?.loginTimestamp) != undefined) {
            let now = new Date().valueOf();
            let hours = Math.abs(now - new Date(item?.loginTimestamp).valueOf()) / 36e5;
            if (hours > 36) {
                return false;
            }
            return true;
        }
        return false;
    }

    static addNotTranslatedWord(word) {
        let str: any = secureLocalStorage.getItem("NotTransLatedWords");
        if (isNullOrEmpty(str)) {
            str = word;
        } else if (str.toString().indexOf(word) == -1) {
            str = str + "," + word;
        }
        secureLocalStorage.setItem("NotTransLatedWords", str);
    }

    static addPages(routeComponents) {
        secureLocalStorage.setItem("Pages", routeComponents);
    }

    static getPages() {
        let bise = secureLocalStorage.getItem("Pages");
        if (bise != undefined) {
            return bise;
        }
        return undefined;
    }

    static removePages() {
        secureLocalStorage.removeItem("Pages");
    }


}