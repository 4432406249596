import * as React from 'react';
import { Button, Icon, Header } from 'semantic-ui-react';

import { Link }   from 'react-router-dom';

export interface Item {
    IconName: any;
    Text: string;
    IsButton: boolean;
    Url: string;
    Width?: string;
    ButtonLink: string;
}

const MenuItem: React.FC<Item> = ({ IconName, Text, IsButton, Url, Width, ButtonLink }: Item) => {

    let styles = {
        item: {
            display: "flex",
            justifyContent: !IsButton ? "flex-start" : "space-between",
            gap: "1em",
            width: Width || "260px",
            borderRadius: "10px",
            backgroundColor: "#fff",
            alignItems: "center",
            padding: "20px",
            boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
            cursor: "pointer"
        }
    }

    return (
        <div style={styles.item}>
            <Icon
                name={IconName}
                size={"big"}
            />

            <div>
                <Header
                    as='h3'
                >
                    {Text}
                </Header>
            </div>

            {IsButton &&
                <Link to={ButtonLink}>
                    <Button
                        icon='plus'
                    />
                </Link>
            }
        </div>
    );
}

export default MenuItem;