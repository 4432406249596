import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Input, Transition } from 'semantic-ui-react';
import { media, style } from 'typestyle';
import { toast } from 'react-toastify';
import { NetworkRequest } from '../../helper/NetworkRequest';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../helper/HelperFunctions';

import { MessageTypes } from '../../components/Message';
import { DataHandler } from '../../helper/DataHelper';
import TranslateHelper from "../../helper/TranslateHelper";


const LdapSettings = (props) => {
    var { translate } = TranslateHelper();
    var _ = require('lodash');
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loader, setLoader] = useState(true);
    const [isError, setIsError] = useState(false);

    const [message, setMessage] = useState<any>({
        Header: "",
        Description: "",
        MessageType: MessageTypes.Error
    });

    const [ldap, setLdap] = useState<any>({
        ldapSettingsId: null,
        ldapUrl: "",
        ldapBindDn: "",
        ldapSearchBase: "",
        ldapSearchFilter: "",
        port: false,
        sslPort: true,
        tenantId: tenantId,
        isSslPort: false,
        isPort: false,
    });

    const showError = (message) => {
        setMessage({
            ...message,
            Header: "Hata",
            Description: message,
            MessageType: MessageTypes.Error
        });
        setIsError(true);
    }
    //delete selected data

    let styles = {
        formContainer: {
            display: "flex",
            width: "100%",
            flexDirection: "row" as "row",
            gap: "1rem",
            marginBottom: "2.5rem"
        },
        w30: {
            width: "30%"
        },
        w30Check: {
            width: "77%",
            color: "#373737"

        },
        inputStyle: {
            width: "100%"
        },
        inputSectionBox: {
            display: "flex",
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row" as "row"
        },
        btnDark: style({
            width: "228px",
            right: "0px",
            top: "700px",
            borderRadius: "8px",
            padding: "10px 0px !important"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
    }

    const addLdap = () => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            let ldapData = {
                ldapUrl: ldap.ldapUrl,
                ldapBindDn: ldap.ldapBindDn,
                ldapSearchBase: ldap.ldapSearchBase,
                ldapSearchFilter: ldap.ldapSearchFilter,
                password: ldap.password,
                port: ldap.port,
                sslPort: ldap.sslPort,
                tenantId: ldap.tenantId,
                isSslPort: ldap.isSslPort,
                isPort: ldap.isPort,
            }

            NetworkRequest("api/Ldap/Add", ldapData).then(response => {
                ////
                if (response.data.resultCode != 0) {
                    toast(response.data.detail, { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 3500 });
                } else if (response.data.resultCode == 0) {
                    toast(response.data.detail, { type: 'success', theme: 'dark', position: 'top-center', autoClose: 3500 });
                    /*     window.location.href = "/settings"*/
                }
            });
        }, () => { toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });

    };

    const [isFormOk, setIsFormOk] = useState(false);

    const getLdapSettings = () => {
        return NetworkRequest("api/Ldap/GetByTenantId", { id: tenantId }).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail != null) {
                setLdap(response.data.detail);
                return response;
            }
        });
    }

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                ldap.ldapUrl,
                ldap.ldapBindDn,
                ldap.ldapSearchBase,
                ldap.ldapSearchFilter,
                ldap.port,
                ldap.sslPort,
                ldap.tenantId
            )
        );

    }, [ldap]);

    useEffect(() => {
        getLdapSettings();
    }, []);

    return (
        <div>

            <Transition visible={!isLoading} animation='scale' duration={500}>
                <div>
                    <div style={styles.formContainer}>
                        <div style={{ display: "flex", flexDirection: "column" as "column", gap: "1rem", width: "50%", justifyContent: "flex-start", position: "static" }}>
                            <label style={styles.w30}>{translate("Url")} (*):</label>
                            <Input
                                label={(ldap.isSslPort ? "LDAPS" : "LDAP") + "://"}
                                className={"greyInput"}
                                autoFocus
                                value={ldap.ldapUrl || ""}
                                name="ldapUrl"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Url")}
                                onChange={(e) => { setLdap({ ...ldap, ldapUrl: e.target.value }) }}


                            />
                            <label style={styles.w30}>{translate("Bind Dn")} (*):</label>

                            <Input
                                className={"greyInput"}
                                value={ldap.ldapBindDn || ""}
                                name="ldapBindDn"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Bind Dn")}
                                onChange={(e) => { setLdap({ ...ldap, ldapBindDn: e.target.value }) }}


                            />
                            <label style={styles.w30}>{translate("Search Base")} (*):</label>

                            <Input
                                className={"greyInput"}
                                value={ldap.ldapSearchBase || ""}
                                name="ldapSearchBase"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Search Base")}
                                onChange={(e) => { setLdap({ ...ldap, ldapSearchBase: e.target.value }) }}


                            />
                            <label style={styles.w30}>{translate("Search Filter")} (*):</label>

                            <Input
                                className={"greyInput"}
                                value={ldap.ldapSearchFilter || ""}
                                name="ldapSearchFilter"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Search Filter")}
                                onChange={(e) => { setLdap({ ...ldap, ldapSearchFilter: e.target.value }) }}

                            />
                            <label style={styles.w30}>{translate("Parola")}:</label>

                            <Input
                                type="password"
                                className={"greyInput"}
                                value={ldap.password || ""}
                                name="password"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Parola")}
                                onChange={(e) => { setLdap({ ...ldap, password: e.target.value }) }}


                            />
                            <label style={styles.w30}>{translate("Port Numarası")} (*):</label>

                            <Input
                                className={"greyInput"}
                                value={ldap.port || ""}
                                name="port"
                                type="number"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Port Numarası")}
                                onChange={(e) => { setLdap({ ...ldap, port: parseInt(e.target.value) }) }}
                            />
                            <label style={styles.w30}>{translate("Ssl Port Numarası")} (*):</label>

                            <Input
                                className={"greyInput"}
                                value={ldap.sslPort || ""}
                                name="sslPort"
                                type="number"
                                required="required"
                                style={styles.inputStyle}

                                placeholder={translate("Ssl Port Numarası")}
                                onChange={(e) => { setLdap({ ...ldap, sslPort: parseInt(e.target.value) }) }}

                            />
                            <br />

                            <label style={styles.w30Check}>{translate("Bağlanmak İstediğiniz Portu Seçiniz")} (*):</label>

                            <div style={styles.inputSectionBox}>

                                <span>SSl Port</span>
                                <Checkbox toggle
                                    checked={ldap.isSslPort}
                                    onChange={(e, data) => {

                                        //setLdap({ ...ldap, isSslPort: data.checked != undefined ? data.checked : false })

                                        //debugger;

                                        //setLdap((ldap) => (ldap.isSslPort = data.checked))
                                        setLdap((ldap) => Object.assign({}, ldap, { isSslPort: data.checked, isPort: !data.checked }));
                                        //setLdap(ldapClone)

                                    }}

                                />

                                <span>Port</span>
                                <Checkbox toggle
                                    checked={ldap.isPort}
                                    onChange={(e, data) => {

                                        setLdap((ldap) => Object.assign({}, ldap, { isPort: data.checked, isSslPort: !data.checked }));


                                    }}

                                />
                            </div>

                            <div>

                                <Button circular color='blue'
                                    onClick={addLdap}
                                    disabled={!isFormOk}
                                >
                                    {translate("Kaydet")}
                                </Button>

                            </div>


                        </div>
                    </div>

                </div>
            </Transition>
        </div>
    );
}

export default LdapSettings;