import * as React from 'react';
import DropZone from '../../../components/DropZone';
import TranslateHelper from '../../../helper/TranslateHelper';
import ExcelImportFile from '../../../images/Buddy/ExcelImport/SurveyQuestionImport.xlsx'

let styles = {

    importContainer: {
        display: "flex",
        width: "100%",
        marginTop:"3%"
    }
}

const QuestionImport = () => {
    var {translate} = TranslateHelper();

    let dropZoneProps = {
        links: {
            uploadLink: "Question/ExcelImport",
            returnLink: "/questions"
        },
        table: {
            headers: [translate("Başlık"),translate("Cevap"), translate("Hata Nedeni")],
            propertyNames: ["title", "answer"]
        },
        messages: {
            2: translate("Başlık boş geçilemez"),
            1: translate("A şıkkı boş geçilemez"),
            4: translate("B şıkkı boş geçilemez"),
            5: translate("C şıkkı boş geçilemez"),
            8: translate("D şıkkı boş geçilemez"),
            15: translate("E şıkkı boş geçilemez"),
            17: translate("Cevap  boş geçilemez"),
            16: translate("Ekleme hatası.")
        },
        dataName: translate("Sınav Sorusu"),
        // excelTemplateFileLink: "/files/docs/excel-import/question-import.xlsx"
        //TODO: bu link kırık
        excelTemplateFileLink: "/files/docs/excel-import/question-import.xlsx"
    }
    
    return (
        <div style={styles.importContainer}>
            <DropZone
                dropZoneProps={dropZoneProps}
            />
        </div>
    )
}

export default QuestionImport;