import * as React from 'react';
import { useState, useEffect, memo } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, redirect } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { IMenuItem } from "../interfaces/interfaces";
import { useNavigate } from 'react-router-dom';
import TranslateHelper from '../helper/TranslateHelper';

function LeftNavbarItem(props: IMenuItem) {
    var { translate } = TranslateHelper();
    const history = useNavigate();

    const [baseFileUrl] = useState("/files/images/icons/");
    const [isSelected, setIsSelected] = useState(false);
    const [onMouseEnterSection, setOnMouseEnterSection] = useState(false);

    const styles = {
        item: {
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column" as "column",
            width: "100%",
            fontSize: "16px",
            // borderRadius: "8px",
            // height: "45px",
            alignItems: "center",
            fontStyle: "roboto",
            // backgroundColor: isSelected ? darkBgColor : menuBgColor,
            borderLeft: onMouseEnterSection ? ".4em " + props.MenuItemBgColorHover + " solid"
                : (props.SelectedItem != props.Id) ? ".4em " + props.MenuItemBgColor + " solid" : ".4em " + props.MenuItemMarkerColor + " solid",

            cursor: "pointer",
            // marginRight: props.Open ? "unset" : "7px",
            borderBottom: "1px #f3f3f3 solid",
            paddingTop: "1em",
            paddingBottom: "1em",
            backgroundColor: !onMouseEnterSection ? props.MenuItemBgColor : props.MenuItemBgColorHover,
            transition: "all .5s"
        },

        icon: {
            marginLeft: "20px",
            margin: ".4em",
            // filter: isSelected ? "brightness(100)" : isSelected ? "#fff" : (menuBgColor === "#fff" || menuBgColor === "rgb(245, 245, 245)") ? "brightness(0)" : "brightness(100)",
            filter: "brightness(0)",
            transition: "all 0.2s ease-in-out",
            width: "25px"
        },

        text: {
            // margin: "14px 65px 12px 20px",
            // color: isSelected ? "#fff" : (menuBgColor === "#fff" || menuBgColor === "rgb(245, 245, 245)") ? "#292828" : "#fff",
            color: !onMouseEnterSection ? props.MenuItemTextColor : props.MenuItemTextColorHover,
            fontFamily: "sans-serif",
            fontSize: ".8em",
            transition: "all .5s"
        },

        link: {
            width: "100%",
            textDecoration: "none",
            marginRight: "6px"
        }
    }

    const handleClickMenu = () => {

        props.SetSelectedItem(props.Icon)
        //props.SetSelectedItem(props.Id);
        if (props.IsHaveSubCategory == true) {
            setIsSelected(true);

            if (props.SelectedItem == props.Id) {
                props.SetSelectedItem("");

                setTimeout(() => {
                    props.SetSelectedItem(props.Id);
                }, 500)
            }
            else {
                props.SetSelectedItem(props.Id);
            }

            props.SetIsOpenSubPanel(true);

        } else {
            props.SetIsOpenSubPanel(false);
            history(props.Link);

        }
    }

    useEffect(() => {
        let pathName = window.location.pathname.slice(1).split("/")[0];
        if (props.Link == "/") {
            setIsSelected(pathName == "");
        } else {
            setIsSelected(pathName.toLowerCase().indexOf(props.Title.toLowerCase()) != -1);
        }
    }, [window.location.pathname]);


    useEffect(() => {
        ////console.log("selected item: ", props.SelectedItem)
    }, [props.SelectedItem]);


    useEffect(() => {
        ////console.log("Id: ", props.Id)
    }, [props.Id]);

    useEffect(() => {
        ////console.log("props.MenuItemBgColorHover: ", props.MenuItemBgColorHover)
    }, [props.MenuItemBgColorHover]);

    useEffect(() => {
        ////console.log(" props.MenuItemMarkerColor: ", props.MenuItemMarkerColor)
    }, [props.MenuItemMarkerColor]);

    return (
        <>

            <div
                onMouseEnter={() => {
                    setOnMouseEnterSection(true);
                }}
                onMouseLeave={() => {
                    setOnMouseEnterSection(false);
                }}
                className={"leftNavBarMenuItem"}
                onClick={handleClickMenu}
                style={styles.item}>
                <Icon
                    onClick={handleClickMenu}
                    style={{
                        color: !onMouseEnterSection ? props.MenuItemIconColor : props.MenuItemIconColorHover
                    }}
                    size={"large"} name={props.Icon} />

                <div onClick={handleClickMenu} style={styles.text}>{translate(props.Text)}</div>
            </div>

        </>
    )
}

export default memo(LeftNavbarItem);