import React, { useState, useEffect } from 'react';
import { Header, Tab, Transition, Loader, Button, Popup, Message, Icon, Progress, MessageHeader } from 'semantic-ui-react';
import { SystemReportCard } from '../../../components/ReportCard';
import { ICommonReportPage, ISystemReport } from "../../../interfaces/interfaces";
import { NetworkRequest } from "../../../helper/NetworkRequest";

import { downloadFile } from "../../../helper/HelperFunctions";
import TranslateHelper from '../../../helper/TranslateHelper';
import { toast } from 'react-toastify';
import { DataHandler } from '../../../helper/DataHelper';

function SystemReport(props) {
    var { translate } = TranslateHelper();
    const [systemReport, setSystemReport] = useState<ISystemReport>();
    const [percentage, setPercentage] = useState(0);
    const [ratio, setRatio] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [passedSeconds, setPassedSeconds] = useState(0);
    const [loader, setLoader] = useState<boolean>(true);
    const [isAccessibilityJobTriggered, setIsAccessibilityJobTriggered] = useState<boolean>(false);
    const [tenantId, setTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));

    const getSystemReport = (data) => {
        setLoader(true);
        NetworkRequest("/api/Report/GetSystemReport", data).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                setSystemReport(response.data.detail);
                setLoader(false);
            }
        });
    }

    const checkPertence = () => {

        NetworkRequest("/api/UserAccesisibilities/CheckPercentageUATask").then((response) => {

            var result = response.data
            var data = JSON.parse(result.detail);

            if (result.resultCode == 0) {
               
                var detail = parseFloat(parseFloat(data.Percentage).toFixed(2));
                setRatio(data.Ratio);
                setSeconds(data.Seconds);
                setPassedSeconds(data.PassedSeconds);

              
                if (detail < 100) {
                    setIsAccessibilityJobTriggered(true);
                }
                else
                    setIsAccessibilityJobTriggered(false);

                setPercentage(detail);

            }

            if (result.resultCode == 1 || data.Percentage != 100) {
                setTimeout(() => {
                    if (window.location.href.split("?")[1] == "page=0") {
                        checkPertence();
                    }
                       
                }, 5000)
            }
        });
    }
    const digitFormat = ( number) => {
        var formattedNumber = ("0" + number).slice(-2);
        return formattedNumber;
    }
    const getSystemReportToExcel = () => {
        let data = {
            SystemReport: systemReport
        }
        NetworkRequest("/api/Report/GetSystemReportToExcel", data).then((response) => {
            downloadFile(response.data, translate("Sistem Raporu"));
        });
    }

    const triggerJobForTenant = () => {
        setIsAccessibilityJobTriggered(true);
        toast(translate("İşlem başlatıldı."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 4000 });
        toast(translate("Başlangıç/Bitiş durumlarının güncellenmesi devam ediyor."), { type: 'info', theme: 'dark', position: 'top-center', isLoading: true });
        let data = {
            id: tenantId
        }

        NetworkRequest("/api/UserAccesisibilities/TriggerJobForTenant", data).then((response) => {
            toast.dismiss();
            if (response.data.resultCode == 0) {
                toast(translate("Başlangıç/Bitiş durumlarının güncellenmesi tamamlandı!"), { type: 'success', theme: 'dark', position: 'top-center', autoClose: false, });
            }
            else {
                toast(translate("Başlangıç/Bitiş durumlarının güncellenmesinde hata ile karşılaşıldı, lütfen tekrar deneyin."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: false, });
            }
        });
        checkPertence();

    }

    useEffect(() => {
        let data = {
            Id: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            TenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1"
        };
        getSystemReport(data);
    }, [props.selectedTenantId]);

    useEffect(() => {
        checkPertence();

    }, []);



    const styles = {
        cardBoxStyle: {
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "space-evenly",
            width: "100%",
            gap: "2em",
            marginBottom: "2.5rem"
        },

        headerStyle: {
            fontSize: "3.5em",
            margin: "auto",
            textAlign: "center"
        },
        reportRow: {
            width: "100%"
        },

        cardContainer: {
            display: "flex",
            flexDirection: "row" as "row",
            flexWrap: "wrap" as "wrap"
        },
        lastAccessibilityJobLogContainer: {
            cursor: isAccessibilityJobTriggered ? "default" : "pointer"
        },
        loaderAccessibility: {
            marginRight: "0.6em",

        }
    }

    return (
        <div>
            <Transition visible={loader} animation='scale' duration={500}>
                <Loader active inline='centered' />
            </Transition>

            <Transition visible={!loader} animation='scale' duration={500}>
                <Tab.Pane key="system">
                    <div>
                        {isAccessibilityJobTriggered &&
                            "Tamamlama Oranı: " + ratio
                        }
                    </div>
                    <div>
                        {isAccessibilityJobTriggered &&
                            "Geçen Süre: " + digitFormat(Math.floor(passedSeconds / 60)) + ":" + digitFormat(passedSeconds % 60)
                        }
                    </div>
                    <div>
                        {isAccessibilityJobTriggered &&
                            "Tahmini Kalan: " + Math.ceil(seconds / 60) + " dk."
                        }
                    </div>
                    {isAccessibilityJobTriggered &&
                        <>
                            <Progress percent={percentage} indicating progress />
                        </>

                    }


                    {systemReport != undefined &&
                        <span onClick={(e) => { if (!isAccessibilityJobTriggered) triggerJobForTenant() }} style={styles.lastAccessibilityJobLogContainer}>

                            <Message
                                color="yellow"
                                style={{ borderRadius: "45px", display: "flex", flexDirection: "column" as "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                                <MessageHeader>{ translate("Bilgi")}</MessageHeader>
                                <div>
                                    {isAccessibilityJobTriggered ?
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div> <Icon size="small" name="check circle" color='green' /></div>
                                            <div>{translate("İşlem asenkron olarak başlatıldı. Sistemin yoğunluğuna göre 20 dakika sürebilir. Bu sayfadan çıkmanız işlemin devam etmesini engellemez.")}</div>
                                        </div>
                                        :
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <div><Icon size="small" name="refresh" color='green' /></div>
                                            <div>{translate("Çalışanların içerik başlangıç/bitiş durumları en son")} {systemReport.lastAccessibilityJobLog.createDateTimeToString} {translate("tarihinde çalışmıştır, yeniden çalıştırmak için tıklayın.")}</div>
                                        </div>
                                    }
                                </div>
                            </Message>

                            {/*<Message*/}
                            {/*    style={{ borderRadius: "45px" }}*/}
                            {/*    icon={'exclamation'}*/}
                                
                            {/*    color={"yellow"}*/}
                            {/*>*/}
                          
                        
                            {/*    {isAccessibilityJobTriggered ?*/}
                            {/*        <div>*/}
                            {/*            <div> <Icon size="small" name="check circle" color='green' /></div>*/}
                            {/*            <div>{translate("İşlem asenkron olarak başlatıldı. Sistemin yoğunluğuna göre 20 dakika sürebilir. Bu sayfadan çıkmanız işlemin devam etmesini engellemez.")}</div>*/}
                            {/*        </div>*/}
                            {/*        :*/}
                            {/*        <div>*/}
                            {/*            <div><Icon size="small" name="refresh" color='green' /></div>*/}
                            {/*            <div>{translate("Kullanıcıların içerik başlangıç/bitiş durumları en son")} {systemReport.lastAccessibilityJobLog.createDateTimeToString} {translate("tarihinde çalışmıştır, yeniden çalıştırmak için tıklayın.")}</div> */}
                            {/*        </div>*/}
                            {/*    }*/}


                            {/*</Message>*/}
                        </span>}
                    <div style={{ display: "flex", gap: "4em", flexDirection: "column" as "column", marginTop: "1rem" }}>
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Modüller")}</Header>
                                <Button onClick={getSystemReportToExcel} size={"large"} color={"green"} icon='excel file' />
                            </div>

                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Aktif")}
                                    Icon={"circle"}
                                    IconColor={"green"}
                                    Text={systemReport?.sectionReport.active}
                                />

                                <SystemReportCard
                                    Header={translate("Silinen")}
                                    Icon={"trash"}
                                    IconColor={"red"}
                                    Text={systemReport?.sectionReport.deleted}
                                />
                            </div>
                        </div>
                        <div>
                            <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Modüller ve Çalışanlar")}</Header>
                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Toplam Atama Sayısı")}
                                    Icon={"pencil"}
                                    IconColor={"black"}
                                    Text={systemReport?.registeredSectionReport.totalAssignCount}
                                />
                                <SystemReportCard
                                    Header={translate("Tamamlama Sayısı")}
                                    Icon={"checkmark"}
                                    IconColor={"green"}
                                    Text={systemReport?.registeredSectionReport.completionCount}
                                />
                                <SystemReportCard
                                    Header={translate("Devam Eden")}
                                    Icon={"play"}
                                    IconColor={"blue"}
                                    Text={systemReport?.registeredSectionReport.inProgress}
                                />
                                <SystemReportCard
                                    Header={translate("Hiç Başlamamış Kayıt")}
                                    Icon={"delete"}
                                    IconColor={"red"}
                                    Text={systemReport?.registeredSectionReport.notStarted}
                                />
                                <SystemReportCard
                                    Header={translate("Çalışan Atanmış modül sayısı")}
                                    Icon={"checkmark"}
                                    IconColor={"green"}
                                    Text={systemReport?.registeredSectionReport.registeredCourseCount}
                                    PopupText={systemReport?.registeredSectionReport.registeredCourseCount + translate(" Adet çalışan en az 1 modüle kayıtlıdır.")}
                                />
                                <SystemReportCard
                                    Header={translate("Modüllere Kayıtlı Çalışanlar")}
                                    Icon={"play"}
                                    IconColor={"blue"}
                                    Text={systemReport?.registeredSectionReport.registeredUserCount}
                                    PopupText={systemReport?.registeredSectionReport.registeredUserCount + translate(" Adet modülde en az 1 çalışan kayıtlıdır.")}
                                />
                            </div>
                        </div>
                        <div>
                            <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Çalışanlar")} </Header>
                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Aktif")}
                                    Icon={"circle"}
                                    IconColor={"green"}
                                    Text={systemReport?.userReport.active}
                                />
                                <SystemReportCard
                                    Header={translate("Pasif")}
                                    Icon={"circle"}
                                    IconColor={"grey"}
                                    Text={systemReport?.userReport.pasive}
                                />
                                <SystemReportCard
                                    Header={translate("Silinen")}
                                    Icon={"trash"}
                                    IconColor={"red"}
                                    Text={systemReport?.userReport.deleted}
                                />
                            </div>
                        </div>
                        <div>
                            <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Oturum Açma")} </Header>
                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Son 24 Saat")}
                                    Icon={"hourglass"}
                                    IconColor={"grey"}
                                    Text={systemReport?.loginReport.last24HLogins}
                                />
                                <SystemReportCard
                                    Header={translate("Son 7 Gün")}
                                    Icon={"calendar alternate"}
                                    IconColor={"grey"}
                                    Text={systemReport?.loginReport.last7DaysLogins}
                                />
                                <SystemReportCard
                                    Header={translate("Son 30 Gün")}
                                    Icon={"calendar"}
                                    IconColor={"grey"}
                                    Text={systemReport?.loginReport.last30DaysLogins}
                                    Width="273px"
                                />
                                <SystemReportCard
                                    Header={translate("Giriş Yok")}
                                    Icon={"calendar times"}
                                    IconColor={"orange"}
                                    Text={systemReport?.loginReport.neverLogins}
                                />
                            </div>
                        </div>
                        <div>
                            <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Sınavlar")}</Header>
                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Aktif")}
                                    Icon={"circle"}
                                    IconColor={"green"}
                                    Text={systemReport?.quizReport.active}
                                />

                                <SystemReportCard
                                    Header={translate("Silinen")}
                                    Icon={"trash"}
                                    IconColor={"red"}
                                    Text={systemReport?.quizReport.deleted}
                                />
                            </div>
                        </div>
                        <div>
                            <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Sorular")}</Header>
                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Aktif")}
                                    Icon={"circle"}
                                    IconColor={"green"}
                                    Text={systemReport?.questionReport.active}
                                />

                                <SystemReportCard
                                    Header={translate("Silinen")}
                                    Icon={"trash"}
                                    IconColor={"red"}
                                    Text={systemReport?.questionReport.deleted}
                                />
                            </div>
                        </div>
                        <div>
                            <Header disabled as='h2' style={{ fontWeight: "100" }}>{translate("Sorulara Verilen Cevaplar")}</Header>
                            <div style={styles.cardContainer}>
                                <SystemReportCard
                                    Header={translate("Doğru")}
                                    Icon={"checkmark"}
                                    IconColor={"green"}
                                    Text={systemReport?.questionAnswerReport.correctAnswerCount}
                                />
                                <SystemReportCard
                                    Header={translate("Yanlış")}
                                    Icon={"delete"}
                                    IconColor={"red"}
                                    Text={systemReport?.questionAnswerReport.wrongAnswerCount}
                                />
                                <SystemReportCard
                                    Header={translate("Cevapsız Geçilen")}
                                    Icon={"eye slash"}
                                    IconColor={"grey"}
                                    Text={systemReport?.questionAnswerReport.noAnswerCount}
                                />
                            </div>
                        </div>
                    </div>
                </Tab.Pane>
            </Transition>

        </div>

    );
}

export default SystemReport;