import * as React from 'react';
import {useState, useEffect} from 'react';
import parse from "html-react-parser";
import {Grid, Loader, Pagination, Transition, Tab, Menu, Label} from 'semantic-ui-react';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import LogCard from '../components/LogCard';
import {v4 as uuidv4} from 'uuid';
import TranslateHelper from '../../../helper/TranslateHelper';
import SystemLogs from "./SystemLogs";
import { DataHandler } from '../../../helper/DataHelper';

const LogsTimeLine = (props) => {
    var {translate} = TranslateHelper();
    let _ = require("lodash");
    const [loginAndComplatePage, setLoginAndComplatePage] = useState<any>(0);
    const [pageCount, setPageCount] = useState<any>(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [logList, setLogList] = useState<Array<any>>();


    const panes = [
        {
            menuItem: { key: 'userslog', icon: 'users', content: translate('Çalışan Logları')},
            render: () => <Tab.Pane>
                <div className="logsContainer">
                    {isLoading
                        &&
                        <div className="width100 thisIsCenter">
                            <Loader size={"huge"} active inline='centered'/>
                        </div>
                    }
                    {!isLoading
                        &&
                        <Grid columns={1} className="logsGrid width100">
                            <Grid.Row className="logsRow">
                                <Grid.Column>
                                    {logList != undefined && logList != undefined && logList.map((log) => (
                                        <LogCard key={uuidv4()} Log={log}/>
                                    ))}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Pagination
                                        activePage={currentPage}
                                        firstItem={null}
                                        lastItem={null}
                                        pointing
                                        style={{position: "absolute", right: "33px"}}
                                        secondary
                                        totalPages={pageCount}
                                        onPageChange={(event, data) => setCurrentPage(data.activePage == undefined ? 1 : parseInt(data.activePage.toString()))}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }
                </div>
            </Tab.Pane>,
        },
        {
            menuItem: {key: 'systemlogs', icon: 'bug', content: translate('Sistem Logları')},
            render: () => <Tab.Pane>
                <SystemLogs />
            </Tab.Pane>,
        },
    ]


    let styles = {
        menuStyle: {
            width: "50%",
            padding: "20px",
            flexWrap: "wrap" as "wrap",
            display: "flex",
            gap: "1em"
        },

        graphStyle: {
            width: "100%",
            padding: "20px",
            minHeight: '350px',
            display: "flex !important",
            flexDirection: "column" as "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem",
            position: "relative" as "relative"
        },

        itemBoxStyle: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexWrap: "wrap" as "wrap",
            gap: "2em"
        },
        logList: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: ".5rem",


        },
        logLabel: {
            borderRadius: "5%",
            textAlign: "center" as "center",
            backgroundColor: "#00C853",
            padding: "4px 8px",
            color: "white",
            letterSpacing: "2px",
            minWidth: "80px",
            fontSize: "12px"
        }
    }

    function sortByRank(a, b) {
        return a - b;
    }

    const getBgStyle = (log) => {
        if (log.eventType == "ForceLogin") {
            return {backgroundColor: "#ff6d04"};
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && log?.detailModel?.isCompleted) {
            return {backgroundColor: "#0091EA"};
        } else if (log.eventType == "ModuleProggress" && log?.detailModel != undefined && !log?.detailModel?.isCompleted) {
            return {backgroundColor: "#00378F"};
        } else if (log.eventType == "CertificateUpload") {
            return {backgroundColor: "#FBBC04"};
        }
        return {};
    }

    useEffect(() => {
        let filterData = {
            filterText: "",
            currentPage: currentPage,
            isActive: false,
            isDeleted: false,
            startingDate: null,
            dueDate: null,
            tenantId: props.selectedTenantId == "1" ? DataHandler.getUserDataWithKey("tenantId") : props.selectedTenantId,
            isAll: props.selectedTenantId == "1"
        };
        NetworkRequest("api/Report/GetFilteredLogsList", filterData).then((response) => {
            if (response.data.resultCode == 0) {
                let result = response.data.detail;
                result.list = _.orderBy(result.list, "createDateTime", "desc");
                setLogList(result.list);
                setPageCount(result.count);
            }
            setIsLoading(false);
        });
    }, [currentPage, props.selectedTenantId]);


    return (
        <div  className="logsContainer">
            <Tab className={"LogsTimeLineTabClass"} panes={panes}/>
        </div>
    );
}

export default LogsTimeLine;