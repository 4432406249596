import React from 'react';
import {Icon} from "semantic-ui-react";
import TranslateHelper from "../../../helper/TranslateHelper";

const NotFoundModuleMessage = () => {
    var {translate} = TranslateHelper();
    return (
        <div className="homePageV2NotFoundModule">
            <div>
                <Icon name={"snapchat ghost"} />
            </div>
            <div>{translate("Modül bulunamadı")}</div>
        </div>
    );
};

export default NotFoundModuleMessage;