import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Input } from "semantic-ui-react";
import { isNullOrEmpty } from '../../helper/HelperFunctions';
import { NetworkRequest } from '../../helper/NetworkRequest';

const GenerateSecret = (props) => {

    const [tenantName, setTenantName] = useState<any>();
    const [data, setData] = useState<any>();
    const [hashedData, setHashedData] = useState<any>();

    const hashDataCall = () => {
        if (isNullOrEmpty(tenantName)) {
            toast("Tenant Adı girmelisiniz.", {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3000
            });
            return;
        }
        if (isNullOrEmpty(data)) {
            toast("Veri girmelisiniz.", {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3000
            });
            return;
        }
        NetworkRequest("/api/TenantSecrets/GenerateSecret", {
            tenantName: tenantName,
            data: data
        }).then((response) => {
            if (response.data.resultCode == 0) {
                setHashedData(response.data.detail);
            }
        });
    }

    return (
        <Grid columns={1} className="widthHeight100">
            <Grid.Row className="widthHeight100 thisIsCenter">
                <Grid.Column width={5} className="textCenter">
                    <div>
                        <div className="loginScreenInputText textLeft">
                            Tenant Adı
                        </div>
                        <Input
                            className="greyInput width100"
                            size="huge"
                            value={tenantName}
                            onChange={(e) => { setTenantName(e.target.value); setHashedData(undefined); }}
                            name="tenantName"
                            id="tenantName"
                            placeholder='Tenant Adı'
                        />
                    </div>
                    <div>
                        <div className="loginScreenInputText textLeft">
                            Veri
                        </div>
                        <Input
                            className="greyInput width100"
                            size="huge"
                            value={data}
                            onChange={(e) => { setData(e.target.value); setHashedData(undefined); }}
                            name="toBeHashed"
                            type="toBeHashed"
                            id="toBeHashed"
                            placeholder="Veri"
                        />
                    </div>
                    {!isNullOrEmpty(hashedData)
                        &&
                        <div>
                            <div className="loginScreenInputText textLeft">
                                Şifrelenmiş Veri
                            </div>
                            <Input
                                className="greyInput width100"
                                size="huge"
                                value={hashedData}
                                name="hashedData"
                                type="hashedData"
                                id="hashedData"
                                placeholder='Veri'
                            />
                        </div>
                    }
                    <div className="loginScreenInputContainer">
                        <Button
                            circular={true}
                            style={{ backgroundColor: "#ff4290", color: "white" }}
                            size="huge"
                            onClick={hashDataCall}
                        >
                            Şifrele
                        </Button>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default GenerateSecret;