import React, {memo} from 'react';
import {Input} from "semantic-ui-react";
import {DateFormat} from "../../../helper/DateFormat";
import TranslateHelper from "../../../helper/TranslateHelper";

const HomePageFilterDate = ({isLoader, title,date, setDate}) => {
    var {translate} = TranslateHelper();
    
    return (
        <div className={"rendererFilterDateContent"}>
            <div>{translate(title)}</div>
            <Input disabled={isLoader} value={date != null ? DateFormat(date, true) : ""} className={"greyInput"}
                   onChange={(e) => {
                       var lastValue: any = e.target.value;
                       lastValue = e.target.value == "" ? undefined : e.target.value;
                       setDate(lastValue)
                   }}
                   type={"date"}/>
        </div>
    );
};

export default memo(HomePageFilterDate);