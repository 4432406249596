import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Icon, Loader, Progress, Transition } from 'semantic-ui-react';
import parse from "html-react-parser";
import "./QuestionCard.css";

import { NetworkRequest } from '../../helper/NetworkRequest';
import { checkFieldsIsFilled, getRandomIntInclusive01, scrollToTop } from '../../helper/HelperFunctions';
import { media, style } from 'typestyle';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { toast } from 'react-toastify';
import { Beforeunload } from 'react-beforeunload';
import ReportQuestionList from '../ReportQuestionList';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from '../../helper/TranslateHelper';
import { DataHandler } from '../../helper/DataHelper';

interface IActiveClasses {
    activeInfoBox: boolean;
    activeQuizBox: boolean;
    activeResultBox: boolean;
}

interface IQuestion {
    Id: string,
    Body: string,
    A: string,
    B: string,
    C: string,
    D: string,
    E: string,
    CorrectAnswer: string,
    Rank: number
}

interface IQuestionView {
    id: string,
    questionJson: IQuestion,
    rank: number
}

interface IQuestionAnswer {
    quizId: string;
    quizQuestionId: string;
    moduleId: string;
    courseContent: string;
    userEmail: string;
    userId: string;
    isCorrect: boolean;
    noAnswer: boolean;
    createdDate: Date;
}

function RenderContentQuiz(props) {
    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const [quizSessionId, setQuizSessionId] = useState("");
    const [localProps] = useState(props);
    const [canStart, setCanStart] = useState(true);
    const [remainingSeconds, setRemainingSeconds] = useState(0);
    const [showConfirmEnd, setShowConfirmEnd] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [canBack, setCanBack] = useState(false);
    const [loginUserId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [transactionUserId] = useState(props.transactionUserId);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [quizId, setQuizId] = useState(props.content.data.contentQuizResponse.id);
    const [moduleId, setModuleId] = useState(props.moduleId);
    const [sectionContentId, setSectionContentId] = useState(props.content.id);
    const [quizSessions, setQuizSessions] = useState<any[]>([]);
    const [lastQuizSession, setLastQuizSession] = useState<any[]>([]);
    const [content, setContent] = useState(props.content);
    const [quiz, setQuiz] = useState<any>({});
    const [lastQuizSessionAnswers, setLastQuizSessionAnswers] = useState<any[]>([]);
    const [showLastQuizSessionAnswers, setShowLastQuizSessionAnswers] = useState(false);
    const { initialMinute = 1, initialSeconds = 0 } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [question, setQuestion] = useState<IQuestion>({
        Id: "",
        CorrectAnswer: "",
        Body: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        Rank: 0
    });
    const [questionList, setQuestionList] = useState<any[]>([])
    const [result, setResult] = useState({
        correctCount: 0,
        falseCount: 0,
        point: 0,
        isSuccess: false,
        successRate: 0,
        failRate: 0
    })
    const [selectedOption, setSelectedOption] = useState("");
    const [notRandomOptions] = useState(["A", "B", "C", "D", "E"]);
    const [options, setOpitons] = useState(["A", "B", "C", "D", "E"]);
    const [questionAnswerList, setQuestionAnswerList] = useState<Array<IQuestionAnswer>>([]);
    const [activeClasses, setActiveClasses] = useState<IActiveClasses>({
        activeInfoBox: true,
        activeQuizBox: false,
        activeResultBox: false
    });

    const [userIsSuccess, setUserIsSuccess] = useState<any>(false);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState<any>(false);

    let styles = {
        sesionDescription: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem"
        },
        bold700: {
            fontWeight: "700"
        },
        header: {
            textAlign: "center"
        },
        startBox: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            gap: "1rem"
        },

        contentSettingHeader: {
            width: "10%",
            fontWeight: "bolder",
            alignItems: "center" as "center"
        },
        contentSettingDescription: {
            width: "90%"
        },
        contentDescriptionStyle: {
            borderRadius: "8px",

            width: "100%",
            minHeight: "100px",
            padding: "25px 31px",
            color: "rgba(41, 40, 40, 1)",
            fontWeight: "400",
            fontSize: "14px"

        },

        contentTimingDetailBox: {
            width: "100%",
        },
        contentTimingDetailBoxFirst: {
            width: "100%",
        },
        contentTimingHeader: {
            width: "21%",
            flexWrap: "wrap" as "wrap",
            fontWeight: "700"
        },
        contentTimingHeaderFirst: {
            width: "36%",
            flexWrap: "wrap" as "wrap",
            fontWeight: "700"
        },
        contentContainer: {
            display: "flex",
            flexDirection: "column " as "column",
            height: "auto"
        },
        quizBtnsContainer: {
            width: "100%",
            marginTop: "2rem",
            minHeight: "53px",
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: " flex-end",
            gap: "2rem",
            marginBottom: "1rem",
            padding: "0 1rem",
        },
        btnDarkOutline: style({
            width: "217px",
            right: "350px",
            top: "700px",
            border: "1px solid #292828 !important",
            boxSizing: "border-box",
            borderRadius: "8px",
            padding: "10px 0px !important",
            backgroundColor: "rgb(255,255,255)"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
        transparent: {
            background: 'rgba(255,255,255,.60)',

        },
        btnDark: style({
            width: "217px",
            right: "0px",
            top: "700px",
            borderRadius: "8px",
            padding: "10px 0px !important"
        },
            media({ maxWidth: 820 }, { width: "50% !important" })
        ),
        quizContainer: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column"
        },
        questionContainer: {
            padding: "1rem .75rem",
            fontSize: "14px",
            display: "flex"
        },
        wordOwerflow: {
            overflow: "auto" as "auto",
            width: "600px",
            textoverflow: "ellipsis",
            whitespace: "nowrap"
        },
        optionsContainer: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            gap: "1rem",
            background: "rgba(251,251,251,0.6)",
            borderRadius: "8px",
            padding: "2rem .75rem",
            marginTop: "20px",
            margin: "0 1rem"
        },
        option: {
            width: "95%",
            height: "40px",
            left: "233px",
            top: "381px",
            background: "rgba(240,240,240, 0.6)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            padding: "1rem",
            justifyContent: "flex-start",
            color: "#292828",
            gap: "1rem"
        },
        questionExplorerBtnsContainer: style({
            display: "flex",
            flexDirection: "row" as "row",
            width: "100%",
            justifyContent: "space-between",
            padding: "2rem .75rem !important",
            alignItems: "center"
        },
            media({ maxWidth: 820 }, { gap: "1rem" })
        ),
        prevBtn: {

            borderRadius: "8px",
            color: "white",
            width: "228px",
            height: "53px",
            opacity: '1',
            background: "rgb(41, 40, 40)",
        },
        nextBtn: {
            borderRadius: "8px",
            width: "228px",
            height: "53px",
            opacity: '1',
            background: "rgb(41, 40, 40)",
            color: "white",
        },
        confirmEndContainer: {
            width: "100%",
            height: "100em",
            position: "absolute" as "absolute",
            background: "rgba(0, 0, 0, 0.6)",
            top: "0",
            left: "0",
            padding: "1.5rem .75rem",
            display: "flex",
            alignItems: "center",
            flexDirection: "column" as "column",
            zIndex: "100"
        },
        confirmEnd: style({
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",

            position: "absolute" as "absolute",
            top: "50%",
            background: "rgba(255,255,255,1)",
            boxShadow: "8px 4px 10px rgba(187, 187, 187, 0.25)",
            gap: "20px",
            padding: "1rem 3rem",
            justifyContent: "space-between"
        },
            media({ maxWidth: 820 }, { width: "90%", padding: "1rem 0", })
        ),
        confirmBtnsContainer: {
            height: "53px",
            display: "flex",
            flexDirection: "row" as "row",
            justifyContent: "center",
            gap: "1rem",
            alignItems: "center",
            width: "100%",
            padding: "0 1rem"
        },
        processBar: {
            width: "349px",
            height: "26px",
            left: "550px",
            top: "482px",

            display: "flex"
        },
        successRate: {
            backgroundColor: "rgba(44, 183, 135, 0.6)",
            height: "100%",
            borderRadius: "4px 0 0 4px",

        },
        failRate: {
            backgroundColor: "rgba(252, 72, 72, 0.6)",
            height: "100%",
            borderRadius: "0 4px 4px 0",
        },
        trueAnswersBox: {
            width: "160px",
            height: "75px",
            left: "555px",
            top: "640px",
            border: "1px solid #E9E9E9",

            borderRadius: "4px",
            color: "#2CB787",
            fontSize: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        falseAnswersBox: {
            width: "160px",
            height: "75px",
            left: "555px",
            top: "640px",
            border: "1px solid #E9E9E9",

            borderRadius: "4px",
            color: "#FC4848",
            fontSize: "22px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        timeContainer: {
            color: "black",
            fontSize: "20px"

        },
        closeBtnStyle: {
            background: "rgba(255,255,255,1)",
            boxShadow: "0px 4px 4px rgba(160, 160, 160, 0.25)",
            position: "absolute" as "absolute",
            right: "-12px",
            top: "-12px",
            borderRadius: "100%",
            height: "48px",
            width: "48px",
            display: "flex",
            padding: "12px",
            cursor: "pointer",

        },
        contentSettingsContainer: style({
            width: "100%",
            display: "flex",

            marginBottom: isMobile ? "unset" : "2rem",
            alignItems: "center",
            flexDirection: "row" as "row",
            gap: "1rem"
        },
            media({ maxWidth: 820 }, { flexDirection: "column" })
        ),
        contentTimingContainer: style({
            width: "50%",
            fontWeight: "bold",
            display: "flex",
            flexDirection: "row" as "row",
            alignItems: "center" as "center"
        },
            media({ maxWidth: 820 }, { width: "100%" })
        ),
        alignRight: style({},
            media({ minWidth: 820 }, {
                textAlign: "right" as "right",
                paddingRight: "1rem",
                textIndent: "2rem",
                width: "20%"
            })
        ),
        complateContainer: {
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            marginTop: "1rem",
            flexDirection: "column" as "column",
        },
        complateLabel: {
            color: "rgba(244, 120, 32, 1)",
            fontSize: "14px",
            fontWeight: "700",
            position: "absolute" as "absolute",
            right: "1rem",
            top: "1rem",

        },
        complateBtnContainer: {
            display: "flex",
            flexDirection: "column" as "column",
            gap: "50px",
            alignItems: "flex-end",


        },
        boxBorder: {
            borderRadius: "5px",
            border: "1px #E9E9E9 solid",
        },
    }

    const shuffle = (array) => {
        let currentIndex = array.length;
        let randomIndex;

        while (currentIndex != 0) {
            randomIndex = Math.floor(getRandomIntInclusive01() * currentIndex);
            currentIndex = currentIndex - 1;

            let currentItem = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = currentItem;

        }
        return array;
    }

    const enforceQuizRules = (quizData) => {
        if (quizData.settingsModel.shuffleQuestionOrder) {
            setQuestionList(shuffle(questionList));
        }
        if (quizData.settingsModel.shuffleAnswerOrder) {
            setOpitons(shuffle(options))
        }
    }

    const getSessionAnswers = (sessionId) => {
  
        if (showCorrectAnswer == false && quiz.settingsModel.showCorrectAnswer.type == 3) {
            toast(translate("Sınavda doğru cevapları görmek için başarılı olmalısınız."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2000
            });
            return;
        }
        if (showLastQuizSessionAnswers) {
            setShowLastQuizSessionAnswers(false);
            return;
        }

        let data = {
            id: sessionId
        }

        NetworkRequest("/api/QuizSession/GetSessionAnswers", data).then(
            response => {
                if (response.data.resultCode === 0) {
                    let result = response.data.detail;
              
                    setLastQuizSessionAnswers(result);
                    setShowLastQuizSessionAnswers(true);
                }
            }
        );
    }

    const addOrUpdate = (question, option?) => {
        let answer = selectedOption;
        if (option != undefined) {
            answer = option;
        }
        let userAnswer = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            userAnswer: answer,
            quizId: quizId,
            quizQuestionId: question.id,
            moduleId: moduleId,
            sectionContentId: content.id,
            isCorrect: question.questionJson.CorrectAnswer == answer,
            quizSessionId: quizSessionId
        }
        NetworkRequest("/QuizAnswer/AddOrUpdate", userAnswer).then(response => {
            if (response.data.resultCode === 0) {
            }
        });

    }

    const didUserAnswerBefore = (question, sessionId?: string) => {
        if (!checkFieldsIsFilled(transactionUserId != "" ? transactionUserId : loginUserId, quizId, moduleId, sectionContentId))
            return;

        if (!(checkFieldsIsFilled(quizSessionId) || checkFieldsIsFilled(sessionId)))
            return;

        let userAnswer = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            quizId: quizId,
            quizQuestionId: question.id,
            moduleId: moduleId,
            sectionContentId: content.id,
            quizSessionId: quizSessionId == "" ? sessionId : quizSessionId
        }
        NetworkRequest("/QuizAnswer/DidUserAnswerBefore", userAnswer).then(response => {
            if (response.data.resultCode === 0) {
                ////
                let result = response.data.detail;
                if (result != null) {
                    setSelectedOption(result.userAnswer);
                }
            }
        });
    }

    const onComplete = (isComplete) => {
        props.onComplete();
    }

    const showResultAndEndQuiz = () => {
        setShowResult(true);
        setActiveClasses({ ...activeClasses, activeQuizBox: false, activeResultBox: true });
        setSeconds(0);
        setMinutes(0);
        props.setIsFocusContent(false)
        let answer = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            quizId: quizId,
            moduleId: moduleId,
            sectionContentId: content.id,
            quizSessionId: quizSessionId
        }

        NetworkRequest("/QuizAnswer/GetAnswers", answer).then(response => {
            if (response.data.resultCode === 0) {
                let correctCount = 0;
                let falseCount = 0;
                let result = response.data.detail;
                result.map((answer, i) => {
                    correctCount += answer.isCorrect ? 1 : 0;
                    falseCount += !answer.isCorrect ? 1 : 0;
                });
                let point = correctCount * (100 / questionList.length);
                point = Math.round(point * 10) / 10
                let isSuccess = point >= quiz.successScore;
                let successRate = (100 / questionList.length) * correctCount;
                let failRate = 100 - successRate;
                setResult({
                    correctCount: correctCount,
                    falseCount: falseCount,
                    isSuccess: isSuccess,
                    point: point,
                    successRate: successRate,
                    failRate: failRate
                });
            }
        });
    }

    const getOldSessions = (userId) => {
     
        let quizSession = {
            userId: userId,
            quizId: content.data.contentQuizResponse.quiz.id,
            contentId: content.id,
            moduleId: moduleId,
            tenantId: tenantId
        }

        NetworkRequest("/api/QuizSession/GetQuizSessions", quizSession).then(response => {
            if (response.data.resultCode === 0) {
                
                let result = response.data.detail;
                setQuizSessions(result);

                if (result != null && result != undefined && result.length > 0) {
                    let lastQuizSession: any = [result[result.length - 1]];
                 
                    if (result[result.length - 1] != undefined && result[result.length - 1] != null && result.length > 0) {
                        setUserIsSuccess(result[result.length - 1].isSuccess);
                    }

                    setLastQuizSession(lastQuizSession);
                }   
            }
        });

    }

    const createSession = () => {
        let quizSession = {
            userId: transactionUserId != "" ? transactionUserId : loginUserId,
            quizId: quizId,
            contentId: sectionContentId,
            moduleId: moduleId,
            tenantId: tenantId,
            remainingSeconds: minutes * 60 + seconds
        }

        NetworkRequest("/api/QuizSession/Add", quizSession).then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setQuizSessionId(result.id);
            }
        });
    }

    const setSessionCompleted = (isSuccess: boolean, totalScore, correctCount, falseCount) => {
        let quizSession = {
            id: quizSessionId,
            isSuccess: isSuccess,
            totalScore: totalScore,
            correctAnswerCount: correctCount,
            wrongAnswerCount: falseCount,
            remainingSeconds: remainingSeconds
        }

        NetworkRequest("/api/QuizSession/SetComplated", quizSession).then(response => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                getOldSessions(transactionUserId != "" ? transactionUserId : loginUserId);
                setQuizSessionId("");
            }
        });

    }

    const reviewQuestions = () => {
        let q = questionList[0];
        setCanBack(false);
        setQuestion({
            Id: q?.id,
            CorrectAnswer: q.questionJson.CorrectAnswer,
            Body: renderQuizByType(q.questionJson.Body),
            A: q.questionJson.A,
            B: q.questionJson.B,
            C: q.questionJson.C,
            D: q.questionJson.D,
            E: q.questionJson.E,
            Rank: 0
        });
        didUserAnswerBefore(q);
    }

    const startBtn = () => {
     
        //Appointment of Scroll at the top when the content changes
        scrollToTop();

        //props.setIsFocusContent(true)
        setMinutes(quiz.time);
        setSeconds(0);
        setSelectedOption("");
        //props.saveLogToCourseHistory();
        createSession();

        let q = questionList[0];
        setQuestion({
            Id: q?.id,
            CorrectAnswer: q?.questionJson?.CorrectAnswer,
            Body: renderQuizByType(q?.questionJson?.Body),
            A: q?.questionJson?.A,
            B: q?.questionJson?.B,
            C: q?.questionJson?.C,
            D: q?.questionJson?.D,
            E: q?.questionJson?.E,
            Rank: 0
        });
        setActiveClasses({ ...activeClasses, activeInfoBox: false, activeQuizBox: true });
    }

    const renderQuizByType = (questionBody) => {
        return typeof (questionBody) == "object" ? questionBody?.embedValue : question.Body;
    }

    const continueQuizSession = (id) => {
        props.setIsFocusContent(true)
        setQuizSessionId(id);
        setActiveClasses({ ...activeClasses, activeInfoBox: false, activeQuizBox: true });

        if (lastQuizSession[0].remainingSeconds != null) {
            setMinutes(Math.floor(lastQuizSession[0].remainingSeconds / 60));
            setSeconds(lastQuizSession[0].remainingSeconds % 60);
        }
        NetworkRequest("/api/QuizSession/GetAnsweredQuestions", { id: id }).then(
            response => {
                if (response.data.resultCode === 0) {
                    let result = response.data.detail;
                    if (result != null) {
                        let newQuestionList: Array<any> = [];
                        let questions = _.cloneDeep(questionList)
                        result.map((item) => {
                            let answeredQ = questionList.find(x => x.id == item.quizQuestionId);
                            if (answeredQ != undefined) {
                                newQuestionList.push(answeredQ)
                                questions = questions.filter(x => x.id != item.quizQuestionId);
                            }
                        });
                        newQuestionList = newQuestionList.concat(questions);
                        setQuestionList(newQuestionList);
                      
                        const q = result.length == newQuestionList.length ? newQuestionList[result.length - 1] : newQuestionList[result.length];
                        setQuestion({
                            Id: q?.id,
                            CorrectAnswer: q.questionJson.CorrectAnswer,
                            Body: renderQuizByType(q.questionJson.Body),
                            A: q.questionJson.A,
                            B: q.questionJson.B,
                            C: q.questionJson.C,
                            D: q.questionJson.D,
                            E: q.questionJson.E,
                            Rank: result.length == newQuestionList.length ? result.length - 1 : result.length
                        });
                        setCanBack(result.length > 0)
                        didUserAnswerBefore(q, id);
                        if (result.length == questionList.length - 1) {
                            toast(translate("Son soru!"), {
                                type: 'warning',
                                theme: 'dark',
                                position: 'top-center',
                                autoClose: 2000
                            });
                        }
                    } else {
                        const q = questionList[0];
                        setQuestion({
                            Id: q?.id,
                            CorrectAnswer: q.questionJson.CorrectAnswer,
                            Body: renderQuizByType(q.questionJson.Body),
                            A: q.questionJson.A,
                            B: q.questionJson.B,
                            C: q.questionJson.C,
                            D: q.questionJson.D,
                            E: q.questionJson.E,
                            Rank: 0
                        });
                        didUserAnswerBefore(q, id);

                    }
                }
            }
        );
        //const q = questionList[question.Rank];

    }

    const endQuiz = (isEnd) => {
        if (isEnd) {
            showResultAndEndQuiz();
        } else {
            reviewQuestions();
        }
    }

    const nextQuestion = (isNext: boolean) => {
     
        if (isNext && selectedOption == "") {
            toast("Lütfen Seçim Yapınız!", { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 4000 });
            return;
        }
        if (isNext && (question.Rank == questionList.length - 1)) {
        
            if (quiz.settingsModel.navigateThroughQuestions)
                setShowConfirmEnd(true);
            else
                endQuiz(true);

            return;
        }
        let rank = isNext ? question.Rank + 1 : question.Rank - 1
        setCanBack(rank > 0)
        //toast(rank, { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 2000 });
        const q = questionList[rank];
        if (quiz.settingsModel.shuffleAnswerOrder) {
            setOpitons(shuffle(options))
        }

        let questionBody = renderQuizByType(q.questionJson.Body);
        var div = document.createElement('div');
        div.innerHTML = questionBody;
        let images = div.getElementsByTagName('img');
        if (typeof images == "object") {
            for (let i = 0; i < images.length; i++) {
                images[i].setAttribute("style", "width:100%;");
            }

            questionBody = div.innerHTML;
        }

        let cloneQuestion = question;

        cloneQuestion.Id = q.id;
        //cloneQuestion.Body = q.questionJson.Body;
        cloneQuestion.Body = questionBody;
        cloneQuestion.A = q.questionJson.A;
        cloneQuestion.B = q.questionJson.B;
        cloneQuestion.C = q.questionJson.C;
        cloneQuestion.D = q.questionJson.D;
        cloneQuestion.E = q.questionJson.E;
        cloneQuestion.Rank = rank;
        cloneQuestion.CorrectAnswer = q.questionJson.CorrectAnswer;

        setQuestion(cloneQuestion);

        //setQuestion({
        //    Id: q.id,
        //    CorrectAnswer: q.questionJson.CorrectAnswer,
        //    Body: questionBody,
        //    A: q.questionJson.A,
        //    B: q.questionJson.B,
        //    C: q.questionJson.C,
        //    D: q.questionJson.D,
        //    E: q.questionJson.E,
        //    Rank: rank
        //})

        setSelectedOption("");
        didUserAnswerBefore(q);

        if (rank == questionList.length - 1) {
            toast(translate("Son soru!"), { type: 'warning', theme: 'dark', position: 'top-center', autoClose: 2000 });
        }

    }

    const children = (remainingTime) => {
        const minutes = Math.floor(remainingTime / 60)
        const seconds = remainingTime % 60;
        let secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;
        let minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${minutesString}.${secondsString}`
    }

    const selectOption = (option) => {
    
        setSelectedOption(selectedOption == option ? "" : option);
        addOrUpdate(questionList[question.Rank], option);
    }

    const saveRemainingTime = (s?) => {
        if (quizSessionId == "")
            return;
        let req = {
            id: quizSessionId,
            remainingSeconds: s != undefined ? s : remainingSeconds
        }
        NetworkRequest("/api/QuizSession/SetRemainingTime", req);
    }

    const getBackgroundStyle = (option) => {
        return {
            backgroundColor: selectedOption != option ? "#e7e7e799" : "rgb(109, 220, 135)"
        };
    }

    useEffect(() => {
        setMinutes(quiz.time);
        setSeconds(0);
    }, [quiz]);

    useEffect(() => {
        if (showConfirmEnd)
            document.getElementById("middleContainer")?.scrollTo({ top: 0, behavior: "smooth" });
    }, [showConfirmEnd])

    useEffect(() => {
        try {
            setContent(props.content);
            let quizData = props.content.data.contentQuizResponse.quiz;
            if (quizData == undefined)
                return;

            setQuizId(quizData.id);
            quizData.settingsModel = JSON.parse(quizData.settings);

       

            var quizSettingsModel = quizData.settingsModel;
            if (quizSettingsModel != null) {
                if (quizSettingsModel.showCorrectAnswer != null) {
                    debugger;
                    if (quizSettingsModel.showCorrectAnswer.isActive && quizSettingsModel.showCorrectAnswer.type == 3) {
                        setShowCorrectAnswer(userIsSuccess)
                    }
                }
            }
            
     
            setQuiz(quizData);

            let questionListData = props.content.data.contentQuizResponse.questionList;
            questionListData.map((question, i) => {
                question.questionJson =
                    typeof question.questionJSONString == 'string' ?
                        JSON.parse(question.questionJSONString)
                        : question.questionJSONString;
            });

          
            setQuestionList(questionListData);

            props.setContentLoading(false);
        } catch (e) {

        }
    }, [props]);

    useEffect(() => {
    }, [quiz, content, questionList, options]);

    useEffect(() => {
    }, [props.showContentInfo]);
    useEffect(() => {
    }, [activeClasses.activeInfoBox]);

    useEffect(() => {
        if (quiz.settingsModel == undefined || quiz.id == undefined || quiz.settingsModel == undefined)
            return;

        enforceQuizRules(quiz)

    }, [quiz]);

    useEffect(() => {
        if (quiz.settingsModel == undefined || quiz.id == undefined || quiz.settingsModel == undefined || quizSessions.length == 0)
            return;
   
        switch (quiz.settingsModel.repeatAnswer.type) {
            case 1: // kapalı
                setCanStart(quizSessions.length == 0);
                break;
            case 2: // sınırlı
                setCanStart(
                    quiz.settingsModel.repeatAnswer.numberOfRepetitions > quizSessions.length
                );
                break;
            case 3: // başarılı olana kadr
                setCanStart(quizSessions.filter(x => x.isSuccess == true).length == 0);
                break;
        }
        setActiveClasses({ ...activeClasses, activeInfoBox: true })
    }, [quizSessions]);

    useEffect(() => {
        if (transactionUserId != "" ? transactionUserId : loginUserId != "")
            getOldSessions(transactionUserId != "" ? transactionUserId : loginUserId);
    }, [loginUserId]);

    return (
        <div id="quizContainer">
            {questionList.length > 0 &&
                <div>

                    {activeClasses.activeInfoBox && props.showContentInfo
                        &&
                        <div style={styles.contentContainer}>
                            <div className={"renderContentEventContainer " + styles.contentSettingsContainer}>
                                <div
                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                    <div
                                        className={"renderContentSettingHeaderContainer"}
                                        style={{ ...styles.contentTimingHeaderFirst }}>
                                        {translate("Soru Sayısı")}
                                    </div>
                                    <span style={styles.contentTimingDetailBoxFirst}>
                                        {questionList.length}
                                    </span>
                                </div>
                                <div
                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                    <div style={{ ...styles.contentTimingHeaderFirst }}
                                        className={"renderContentSettingHeaderContainer " + styles.alignRight}>
                                        {translate("Süre")}
                                    </div>
                                    <span style={styles.contentTimingDetailBoxFirst}>
                                        {quiz.time} {translate("dk")}
                                    </span>
                                </div>
                                <div
                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                    <div style={{ ...styles.contentTimingHeaderFirst }}
                                        className={"renderContentSettingHeaderContainer " + styles.alignRight}>
                                        {translate("Başarı Puanı")}
                                    </div>
                                    <span style={styles.contentTimingDetailBoxFirst}>
                                        {quiz.successScore}
                                    </span>
                                </div>
                            </div>


                            <div
                                className={"renderContentQuizCustomMargin renderContentEventContainer " + styles.contentSettingsContainer}>
                                <div
                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentTimingContainer}>
                                    <div
                                        className={"renderContentSettingHeaderContainer"}
                                        style={styles.contentTimingHeader}>
                                        {translate("Tekrar Hakkı")}
                                    </div>
                                    <span style={styles.contentTimingDetailBox}>
                                        {quiz.settingsModel.repeatAnswer.type == 1
                                            &&
                                            <>{translate("Bu sınav tekrar edilemez.")}</>
                                        }
                                        {quiz.settingsModel.repeatAnswer.type == 2
                                            &&
                                            <> Sınavı {quiz.settingsModel.repeatAnswer.numberOfRepetitions} kez tekrar etme
                                                hakkınız var.</>
                                        }
                                        {quiz.settingsModel.repeatAnswer.type == 3
                                            &&
                                            <>{translate("Sınav başarılı oluncaya dek tekrar edecek.")}</>
                                        }
                                    </span>
                                </div>

                            </div>
                            {quiz.description != "" &&
                                <div
                                    className={"renderContentSettingsContainer renderContentContainerFlex " + styles.contentSettingsContainer}
                                    style={{ flexDirection: "column" }}
                                >
                                    <div
                                        className={"renderContentSettingHeaderContainer"}
                                        style={{
                                            ...styles.contentSettingHeader, ...styles.bold700,
                                            alignItems: "flex-start !important",
                                            width: "100%"
                                        }}>
                                        {translate("Sınav Açıklama")}
                                    </div>
                                    <div
                                        className={"renderContentDescContainer"}
                                        style={{ ...styles.contentDescriptionStyle, ...styles.boxBorder }}
                                    >
                                        {quiz.description}
                                        <br />
                                        <br />
                                        {quiz.navigateThroughQuestions ?
                                            translate("Sınav sırasında sorular arasında gezebilirsiniz.")
                                            : translate("Sınav esnasında sorular arasında gezinemezsiniz")}
                                    </div>
                                </div>
                            }

                        </div>
                    }
                    {activeClasses.activeQuizBox && props.isLocked != true
                        &&
                        <div style={styles.quizContainer}>
                            {!isMobile &&
                                <Grid columns={2} style={{ marginBottom: '3px' }}>
                                    <Grid.Row
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                        <Grid.Column style={{ width: '50%', paddingTop: '2%' }}>
                                            <div
                                                className={"renderContentQuizQuestionHeaderContainer"}
                                                style={{ ...styles.questionContainer, justifyContent: "space-between" }}>

                                                <div
                                                    style={{ fontSize: '27px', fontWeight: '700' }}>{question.Rank + 1}.
                                                </div>
                                                <div style={styles.wordOwerflow}>
                                                    {parse(question?.Body)}
                                                </div>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column style={{ float: 'rigth', paddingTop: '5%' }}>
                                                        <span style={{
                                                            ...styles.timeContainer,
                                                            position: "absolute",
                                                            right: "1.75rem"
                                                        }}>
                                                            <b>{question.Rank + 1}</b>
                                                            <span>/</span>
                                                            <span>{questionList.length}</span>
                                                        </span>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            }
                            {isMobile &&
                                <div className={"renderContentQuizMobileHeaderContainer"}>
                                    <div className={"renderContentQuizQuestionRankContainer"}>
                                        <b className={"renderContentQuizQuestionRankInfo"}>{question.Rank + 1 + " / " + questionList.length}</b>
                                    </div>
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column style={{ width: "15%" }}>
                                                <span style={{ fontSize: '27px', fontWeight: '700' }}>
                                                    {question.Rank + 1}.
                                                </span>
                                            </Grid.Column>
                                            <Grid.Column className="renderContentQuizQuestionBodyContainer">
                                                {parse(question.Body)}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            }

                            {!isMobile
                                &&
                                <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                    {options.map((option, i) => (
                                        <div key={uuidv4()} className={"renderContentSurveyOptionContainer"}>
                                            <div
                                                style={getBackgroundStyle(option)}
                                                className={"renderContentQuestionOptionMobileContainer"}
                                                onClick={(e) => selectOption(option)}
                                            >
                                                <b className={"renderContentQuestionOptionTextMobile"}>{notRandomOptions[i]} </b>
                                                <span>{question[option]}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {isMobile
                                &&
                                <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                                    {options.map((option, i) => (
                                        <div className={"renderContentSurveyOptionContainer"}>

                                            <div
                                                style={{
                                                    backgroundColor: selectedOption != option ? "#e7e7e799" : "rgb(109, 220, 135)"
                                                }}
                                                className={"renderContentQuestionOptionMobileContainer"}
                                                onClick={(e) => selectOption(option)}
                                            >
                                                <b className={"renderContentQuestionOptionTextMobile"}>{notRandomOptions[i]} </b>
                                                <span>{question[option]}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {(question.Rank == questionList.length - 1) &&
                                <div className={"renderContentQuizLastQuestionInfoContainer"}
                                    style={{ position: "relative" }}>
                                    <span
                                        className={"renderContentQuizLastQuestionText"}
                                        style={styles.complateLabel}
                                    >
                                        {translate("Lütfen testi tamamladım butonuna tıklamadan önce  kontrol edin.")}
                                    </span>
                                </div>
                            }
                            <Grid className={styles.questionExplorerBtnsContainer} columns={3}>
                                <Grid.Row>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column className="thisIsCenter">
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={minutes * 60 + seconds}
                                            onComplete={() => {
                                                showResultAndEndQuiz();
                                            }}
                                            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                                            colorsTime={[10, 5, 3, 0]}
                                            size={100}
                                            onUpdate={(remainingSeconds) => {
                                                setRemainingSeconds(remainingSeconds);
                                                if ((remainingSeconds % 5) == 0) {
                                                    saveRemainingTime(remainingSeconds)
                                                }
                                                if (remainingSeconds / 60 == 1) {
                                                    toast("Son 1 dakika!", {
                                                        type: 'warning',
                                                        theme: 'dark',
                                                        position: 'top-center',
                                                        autoClose: 2000
                                                    });
                                                }
                                            }}
                                        >
                                            {({ remainingTime }) => children(remainingTime)}
                                        </CountdownCircleTimer>
                                    </Grid.Column>
                                    <Grid.Column></Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <div>
                                        <Button
                                            circular
                                            disabled={!(canBack && quiz.settingsModel.navigateThroughQuestions)}
                                            onClick={() => {
                                                nextQuestion(false);
                                                scrollToTop();
                                            }}
                                            content={translate("Önceki Soru")} />
                                        {quizSessionId != "" &&
                                            <Beforeunload onBeforeunload={(event) => {
                                                saveRemainingTime();
                                            }} />
                                        }
                                    </div>
                               
                                    <div>
                                        <Button
                                            circular
                                            onClick={() => {
                                                nextQuestion(true);
                                                scrollToTop();
                                                // props.setIsOpenTaskContentForMobile(true);
                                            }}
                                            color={(question.Rank == questionList.length - 1) ? "green" : "blue"}
                                            content={(question.Rank == questionList.length - 1) ? translate("Bitir") : translate("Sonraki Soru")}
                                        />
                                    </div>
                                </Grid.Row>
                            </Grid>
                        </div>
                    }
                    {showResult
                        &&
                        <div>
                            <Transition
                                visible={(activeClasses.activeResultBox && props.isLocked != true && !isMobile)}
                                animation='drop'
                                duration={300}>
                                {/*<div style={styles.confirmEndContainer}>*/}
                                <div className={"renderContentActiveResultBoxMobile"}>
                                    <Icon
                                        style={{
                                            width: "100%",
                                            marginBottom: "5%"
                                        }}
                                        size={"big"}
                                        name={result.isSuccess ? "check circle" : "times circle"}
                                        color={result.isSuccess ? "green" : "red"}
                                    />

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "2em",
                                        fontWeight: "bold",
                                        color: result.isSuccess ? "green" : "red",
                                        textAlign: "center",
                                        lineHeight: "normal"
                                    }}>
                                        {result.isSuccess ? translate("Sınav Başarıyla Tamamlandı!") : translate("Başarısız")}
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            height: "40px",
                                            padding: "3%",
                                            marginTop: "2%",
                                            marginBottom: "2%"
                                        }}>
                                        <div style={{
                                            ...styles.successRate,
                                            width: result.successRate.toFixed(0) + "%"
                                        }}></div>

                                        <div
                                            style={{
                                                ...styles.failRate,
                                                width: result.failRate.toFixed(0) + "%"
                                            }}></div>
                                    </div>
                                    {quiz.settingsModel.showScore &&  
                                        <div style={{
                                            fontSize: "1.5em",
                                            fontWeight: "700",
                                            textAlign: "center"
                                        }}> {"Puan: " + result.point}</div>    
                                    }
                                   


                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column className="thisIsCenter colorGreen">
                                                <span>{translate("Doğru:")}<b>{result.correctCount}</b></span>
                                            </Grid.Column>
                                            <Grid.Column className="thisIsCenter colorRed">
                                                <span>{translate("Yanlış:")}<b>{result.falseCount}</b></span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                    <div style={{ marginTop: "4%" }}>
                                        <Button
                                            className={"dangerRedButton"}
                                            style={{
                                                width: "80%",
                                                margin: "0 auto",
                                                marginTop: "10%"
                                            }}
                                            onClick={() => {
                                                setActiveClasses({
                                                    ...activeClasses,
                                                    activeInfoBox: true,
                                                    activeResultBox: false

                                                });
                                                setShowResult(false);
                                                onComplete(result.isSuccess);
                                                setSessionCompleted(result.isSuccess, result.point, result.correctCount, result.falseCount);
                                            }}
                                            fluid size={"big"}>{translate("Kapat")}</Button>
                                    </div>
                                </div>
                            </Transition>
                        </div>
                    }

                    {showResult && isMobile
                        &&
                        <div>

                            <Transition
                                visible={(activeClasses.activeResultBox && props.isLocked != true && isMobile)}
                                animation='drop'
                                duration={300}
                            // onComplete={() => {
                            //     setTimeout(() => { closeSurveyDynamicIsland() }, 3000)
                            // }}
                            >
                                {/*<div style={styles.confirmEndContainer}>*/}
                                <div className={"renderContentActiveResultBoxMobile"}>
                                    <Icon
                                        style={{
                                            width: "100%",
                                            marginBottom: "5%"
                                        }}
                                        size={"big"}
                                        name={result.isSuccess ? "check circle" : "times circle"}
                                        color={result.isSuccess ? "green" : "red"}
                                    />

                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "2em",
                                        fontWeight: "bold",
                                        color: result.isSuccess ? "green" : "red",
                                        textAlign: "center",
                                        lineHeight: "normal"
                                    }}>
                                        {result.isSuccess ? translate("Sınav Başarıyla Tamamlandı!") : translate("Başarısız")}
                                    </div>


                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            height: "40px",
                                            padding: "3%",
                                            marginTop: "2%",
                                            marginBottom: "2%"
                                        }}>
                                        <div style={{
                                            ...styles.successRate,
                                            width: result.successRate.toFixed(0) + "%"
                                        }}></div>

                                        <div
                                            style={{
                                                ...styles.failRate,
                                                width: result.failRate.toFixed(0) + "%"
                                            }}></div>
                                    </div>
                                    <div style={{
                                        fontSize: "1.5em",
                                        fontWeight: "700",
                                        textAlign: "center"
                                    }}> {translate("Puan:") + result.point}</div>

                                    {/*<div style={{*/}
                                    {/*    fontSize: "22px",*/}
                                    {/*    padding: "4%",*/}
                                    {/*    borderRadius: "10px",*/}
                                    {/*    margin: "5% auto 5% auto",*/}
                                    {/*    width: "120px",*/}
                                    {/*    textAlign: "center",*/}
                                    {/*    color: "white",*/}
                                    {/*    backgroundColor: result.point < quiz.successScore ? "red" : result.point < 85 ? "orange" : "green"*/}
                                    {/*}}> {result.point < quiz.successScore ? translate("Başarısız") : result.point < 85 ? translate("Orta / İyi") : translate("Pekiyi")}</div>*/}

                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column className="thisIsCenter colorGreen">
                                                <span>{translate("Doğru:")} <b>{result.correctCount}</b></span>
                                            </Grid.Column>
                                            <Grid.Column className="thisIsCenter colorRed">
                                                <span>{translate("Yanlış:")} <b>{result.falseCount}</b></span>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                    <div style={{ marginTop: "4%" }}>
                                        <Button
                                            className={"dangerRedButton"}
                                            style={{
                                                width: "80%",
                                                margin: "0 auto",
                                                marginTop: "10%"
                                            }}
                                            onClick={() => {
                                                setActiveClasses({
                                                    ...activeClasses,
                                                    activeInfoBox: false,
                                                    activeResultBox: false
                                                });
                                                setShowResult(false);
                                                onComplete(result.isSuccess);
                                                setSessionCompleted(result.isSuccess, result.point, result.correctCount, result.falseCount);
                                            }}
                                            fluid size={"big"}>{translate("Kapat")}</Button>
                                    </div>


                                </div>
                                {/*</div>*/}

                            </Transition>
                        </div>
                    }

                    {showConfirmEnd && props.isLocked != true && !isMobile
                        &&
                        <div
                            className={"renderContentQuizConfirmEndContainer"}
                        // style={styles.confirmEndContainer}

                        >
                            <div className={"renderContentQuizConfirmEnd"} id="renderContentQuizConfirmEnd" style={{ height: "220px" }}>
                                &nbsp;
                                &nbsp;
                                <span style={{ fontSize: "18px" }}><b>{translate("Sınav Bitirilecek")}</b></span>
                                <span style={{ fontSize: "18px" }}>{translate("Emin Misiniz?")}</span>
                                <div style={styles.confirmBtnsContainer}>
                                    <Button  circular onClick={() => {
                                        setShowConfirmEnd(false);
                                        endQuiz(false)
                                    }}>{translate("Soruları Gözden Geçir")}
                                    </Button>
                                    <Button
                                        circular
                                        color="green"
                                        primary
                                        onClick={() => {
                                            setShowConfirmEnd(false);
                                            endQuiz(true)
                                        }}
                                    >{translate("Bitir")}</Button>
                                </div>
                                &nbsp;
                            </div>
                        </div>
                    }

                    <Transition
                        visible={showConfirmEnd && props.isLocked != true && isMobile}
                        animation='drop'
                        duration={300}
                    >
                        <div>

                            {isMobile &&
                                <div
                                    style={{ bottom: "4%" }}
                                    className={"dynamicIslandToatsContainer"}>
                                    <div className={"dynamicIslandContent"}>
                                        <div>
                                            <Icon
                                                color="orange"
                                                name="exclamation circle"
                                                size="massive"
                                            />
                                        </div>
                                        <div className={"dynamicIslandContentText"}>
                                            {translate("Sınav Bitirilecek, Emin misiniz?")}
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "1em",
                                            width: "100%"
                                        }}>
                                            <Button
                                                fluid={true}
                                                className={"disabledGreyButton"}
                                                onClick={() => {
                                                    setShowConfirmEnd(false);
                                                    endQuiz(false)
                                                }}>{translate("Soruları Gözden Geçir")}
                                            </Button>
                                            <Button
                                                fluid={true}
                                                className={"positiveGreenButton"}
                                                onClick={() => {
                                                    setShowConfirmEnd(false);
                                                    endQuiz(true)
                                                }}>{translate("Bitir")}</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Transition>

                    {activeClasses.activeInfoBox && lastQuizSession[0] != undefined && props.isLocked != true && question.Id == ""
                        &&
                        <div style={{ alignItems: "stretch", flexDirection: "column" }}
                            className={"renderContentQuizGlobalContainer " + styles.contentSettingsContainer}
                        >
                            {lastQuizSession[0].isComplated
                                ?
                                <div>
                                    {!isMobile &&
                                        <div
                                            className={"renderContentEventContainer renderContentQuizMobileContainer renderContentQuizCustomMargin"}>
                                            <div className={"renderContentQuizDesc"}>
                                                <div className={"renderContentQuizMobileHeader"}>{translate("Sınav tamamlandı.")}
                                                </div>
                                                {quiz.settingsModel.showScore && 
                                                    <div className={"renderContentQuizMobileTryCount"}>
                                                        <b>{translate("Puan:")}</b> {lastQuizSession[0].totalScore}
                                                    </div>
                                                }
                                                
                                                <div className={"renderContentQuizMobileTryCount"}>
                                                    <b>{translate("Deneme sayısı:")}</b> {lastQuizSession[0].repeatCount}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    fontSize: "1em",
                                                    color: "black"
                                                }}>
                                                <p>{translate("Cevaplanan Soru:")}{lastQuizSession[0].answeredCount}/{lastQuizSession[0].questionCount}</p>
                                            </div>

                                            {lastQuizSession.length > 0
                                                && lastQuizSession[0].answeredCount == lastQuizSession[0].questionCount
                                                && content.status != undefined && content.status.isInProgress
                                                &&
                                                <Button
                                                    onClick={() => {
                                                        if (quiz.settingsModel.navigateThroughQuestions) {
                                                            setShowConfirmEnd(true);
                                                        } else {
                                                            endQuiz(true);
                                                        }
                                                    }}
                                                    className="positiveGreenButton quizFİnishWidth"
                                                    content={translate("Bitir")}
                                                    icon="check"
                                                />
                                            }

                                            <Button
                                                size={"big"}
                                                color={showLastQuizSessionAnswers ? "black" : "blue"}
                                                style={{ top: "1rem", borderRadius: "15px" }}
                                                className={styles.btnDark}
                                                onClick={() => {
                                                    getSessionAnswers(lastQuizSession[0].id)
                                                }}
                                            >
                                                <Icon name={showLastQuizSessionAnswers ? 'eye slash' : 'eye'} />
                                                {showLastQuizSessionAnswers ? translate("Cevapları Gizle") :
                                                    translate("Cevapları Getir")}
                                            </Button>


                                        </div>
                                    }

                                    {isMobile
                                        &&
                                        <div
                                            className={"renderContentEventContainer renderContentQuizMobileContainer renderContentQuizCustomMargin"}>
                                            <div className={"renderContentQuizDesc"}>
                                                <div className={"renderContentQuizMobileHeader"}>{translate("Sınav tamamlandı")}
                                                </div>
                                                <div className={"renderContentQuizMobileTryCount"}><b>{translate("Deneme sayısı:")}</b> {lastQuizSession[0].repeatCount}</div>
                                            </div>

                                            <div className={"progressBarQuizContainer"}>
                                                <Progress
                                                    className={"renderContentQuizProgressBar"}
                                                    style={{ borderRadius: "20px", margin: "3%" }}
                                                    percent={(lastQuizSession[0].answeredCount / lastQuizSession[0].questionCount) * 100}
                                                    indicating
                                                    size="large"
                                                    progress
                                                />
                                            </div>


                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    fontSize: "1em",
                                                    color: "black"
                                                }}>
                                                <p>{translate("Cevaplanan Soru:")} {lastQuizSession[0].answeredCount}/{lastQuizSession[0].questionCount}</p>
                                            </div>

                                            <Button
                                                className={showLastQuizSessionAnswers ? "dangerRedButton" : "continueBlueButton"}
                                                style={{ top: "1rem", borderRadius: "15px" }}
                                                onClick={() => {
                                                    getSessionAnswers(lastQuizSession[0].id)
                                                }}
                                            >
                                                <Icon name={showLastQuizSessionAnswers ? 'eye slash' : 'eye'} />
                                                {showLastQuizSessionAnswers ? translate("Cevapları Gizle") :
                                                    translate("Cevapları Getir")}
                                            </Button>


                                        </div>
                                    }
                                </div>
                                :
                                <div
                                    className={"renderContentContentHeader renderContentContainerFlex renderContentQuizCustomMargin"}
                                    style={{ backgroundColor: "rgba(255, 235, 169, 0.36)" }}
                                >
                                    <div className={"renderContentSettingHeaderContainer"}>
                                        {translate("Bu sınavı yarıda bıraktınız.")}
                                    </div>
                                    <div
                                        style={{ fontWeight: "bold", fontSize: "16px", width: "100%" }}>
                                        {translate("Kalan Süre:")} {Math.floor(lastQuizSession[0].remainingSeconds / 60)} {translate("dakika")} {lastQuizSession[0].remainingSeconds % 60} {translate("saniye")}
                                    </div>
                                    <span style={{ width: "100%" }} className={"progressNotRadius"}>
                                        <Progress
                                            className={"userDashboardProgressPercentage"}
                                            percent={((lastQuizSession[0].answeredCount / lastQuizSession[0].questionCount) * 100).toFixed(1)}
                                            indicating
                                            size="large"
                                            progress
                                        />
                                    </span>

                                    {(lastQuizSession[0] != undefined && lastQuizSession[0].isComplated == false && isMobile) &&
                                        <Button
                                            color="orange"
                                            circular
                                            onClick={() => {
                                                continueQuizSession(lastQuizSession[0].id)
                                            }}>
                                            {translate("Teste Devam Et")}
                                        </Button>
                                    }

                                </div>
                            }


                        </div>
                    }
                    {activeClasses.activeInfoBox && props.isLocked != true
                        &&
                        <div
                            className={"renderContentQuizShowAnsweredList"}
                        >
                            {canStart
                                &&
                                <Button
                                    className={"mt4"}
                                    circular
                                    color="green"
                                    fluid={isMobile}
                                    onClick={startBtn}
                                    content={translate("Başla")}

                                />
                            }

                            {lastQuizSession[0] != undefined && lastQuizSession[0].isComplated == false && !isMobile
                                &&
                                <Button
                                    circular
                                    color="orange"
                                    className={"mt4"}
                                    onClick={() => {
                                        continueQuizSession(lastQuizSession[0].id)
                                    }}>
                                    {translate("Teste Devam Et")}
                                </Button>
                            }
                        </div>
                    }
                    {showLastQuizSessionAnswers && props.isLocked != true
                        &&
                        <ReportQuestionList questions={lastQuizSessionAnswers} />
                    }

                </div>
            }
        </div>
    )
}

export default RenderContentQuiz;
