
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Header, Input, Dropdown, Checkbox, Button, Transition, Loader, Icon, TextArea, Form, Popup, Label, Grid, Message, Card, Progress } from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { isMobile } from "react-device-detect";
import ContentAdd from '../../../components/ContentAdd';
import UserAttachToCourse from '../../../components/UserAttachToCourse';
import { ISection, IDropdown, IRole, IAccessibilityRule } from '../../../interfaces/interfaces';
import { checkFieldsIsFilled, digitallicaConfirm } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import CertificateAttachToModule from '../../../components/CertificateAttachToModule';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FileInputComp from "../components/FileInputComp";
import TranslateHelper from '../../../helper/TranslateHelper';
import { displayName } from 'react-quill';
import { DataHandler } from '../../../helper/DataHelper';

const ModuleAdd = (props) => {

    let { id, page } = useParams();
    var { translate } = TranslateHelper();
    const [progressContent, setProgressContent] = useState(0);
    const [progressContentText, setProgressContentText] = useState("Dosya Yükleniyor");
    const [isUploading, setIsUploading] = useState(false);
    const _ = require('lodash');
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [userId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [moduleId, setModuleId] = useState<any>(id);
    const [paramPage] = useState(page);
    const [image, setImage] = useState("");
    const [showModuleAddSectionId, setShowModuleAddSectionId] = useState("");
    const [showUpdateContentId, setShowUpdateContentId] = useState("");
    const [defaultStartStatusId, setDefaultStartStatusId] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [loader, setLoader] = useState(true);
    const [showNoti, setShowNoti] = useState(false);
    const [sectionEditEnabled, setSectionEditEnabled] = useState(false);
    const [showSectionAdd, setShowSectionAdd] = useState(false);
    const [showModuleTitleInput, setShowModuleTitleInput] = useState(false);
    const [moduleImage, setModuleImage] = useState("");
    const [modulePhotoUrl, setModulePhotoUrl] = useState("");
    const [selectedSectionModel, setSelectedSectionModel] = useState<any>({
        index: -1,
        section: undefined
    });
    const [moduleAccessModel, setModuleAccessModel] = useState<any>();
    const [apiModule, setApiModule] = useState<any>({
        id: "",
        title: "",
        description: "",

        createdDate: undefined,
        categoryId: null,
        continueAccessAfterDone: false,
        welcomeUserModule: false,
        showOnlyCurrentRoleTasks: false,
        isActive: true,
        isDeleted: true,
        coverPhoto: null,
        sectionList: [{
            id: "",
            title: "",
            showTitleEdit: false,
            showDescriptionEdit: false,
            showDetails: false,
            showAddContent: false,
            isEditEnabled: false,
            contentList: [{
                id: "",
                settings: { settingsJson: '{"id":"","finishDate":"","finishOption":"","startDate":"","startOption":"","delayDayOfStartDate":"","delayDayOfFinishDate":""}' }
            }]
        }]
    });
    const [section, setSection] = useState<ISection>({
        contentList: [],
        id: "",
        moduleId: moduleId,
        statusId: '',
        backgroundUrl: "",
        createdDate: "",
        deletedDate: "",
        description: "",
        isActive: true,
        isDeleted: false,
        showDetails: false,
        rank: -1,
        title: ""
    });
    const [isFormOk, setIsFormOk] = useState([false, false]);
    const [dropdownStartAccessibilityEvents, setDropdownStartAccessibilityEvents] = useState<Array<IDropdown>>([]);
    const [dropdownFinishAccessibilityEvents, setDropdownFinishAccessibilityEvents] = useState<Array<IDropdown>>([]);
    const [dropdownRoles, setDropdownRoles] = useState<Array<IDropdown>>([]);
    const [dropdownCategories, setDropdownCategories] = useState<Array<IDropdown>>([]);
    const [roles, setRoles] = useState<Array<IRole>>([]);
    const [accessibilityEvents, setAccessibilityEvents] = useState<Array<any>>([]);
    const [backgroundImage, setBackgroundImage] = useState<any>(undefined);
    const [showDetail, setShowDetail] = useState(false);
    const [currentSectionIndex, setCurrentSectionIndex] = useState("");
    const [currentContentIndex, setCurrentContentIndex] = useState("");

    const fileUploader = useRef<any>();

    let styles = {
        progress: { width: "100%" },
        moduleAddSection: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        },
        inputsSection: {
            display: 'flex',
            width: "100%",
            padding: "20px",
            flexDirection: isMobile ? "column" as "column" : "row" as "row",
            gap: "0.5em",
            justifyContent: "space-between",
            alignItems: isMobile ? "center" : "unset"
        },

        moduleSection: {
            display: 'flex',
            width: isMobile ? "100%" : "100%",
            padding: "20px",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column" as "column",
            gap: isMobile ? "0.5em" : "unset"
        },

        inputSectionBox: {
            display: "flex",
            gap: "0.8em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row" as "row"
        },

        inputStyle: {
            width: "100%",
        },
        inputStyleDescription: {
            width: "200%"
        },
        inputStyleModule: {
            width: "100%"
        },

        inputContainer: {
            width: isMobile ? "100%" : "100%",
            display: "flex",
            flexDirection: "column" as "column",
            gap: "0.5em",
            marginBottom: "1rem"
        },
        labelContainer: {
            width: "30%",
        },

        contentDetailPage: {
            marginTop: "1rem",
            padding: ".5rem .25rem",
            display: 'flex',
            flexDirection: 'row' as 'row',
            gap: "0.5em",
            justifyContent: 'start'
        },
        contentDetailContainer: {
            display: 'flex',
            flexDirection: 'column' as 'column'
        },
        button: {
            boxShadow: '-2px 10px 11px 1px rgba(0,0,0,0.5)',
            borderBottom: "2.5px solid black"
        },
        moduleDetailList: {
            // marginTop: "1rem",
            width: "100%",
            display: 'flex',
            flexDirection: 'column' as 'column',
            // backgroundColor: 'white',
            // boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            // borderRadius: "20px",
            // padding: "2%",
            gap: "0.5em"
        },
        sectionContainer: {
            backgroundColor: '#f9f9f9',
            // boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            padding: "1.5%",
            margin: "1%",
            borderRadius: "15px",
            display: "flex",
            flexDirection: 'column' as 'column',
            gap: "0.5em",
            borderLeft: "10px solid #eeeeee"
        },
        moduleSectionContainer: {
            display: 'flex',
            flexDirection: 'row' as 'row',
            margin: '.5rem .25rem',
            padding: '1rem .5rem',
            minHeight: '10em',
            backgroundColor: 'white',
            boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            borderRadius: "15px"
        },
        verticalCenter: {
            display: 'flex',
            flexDirection: 'column' as 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        buttonContainer: {
            textAlign: "left" as "left",
            width: "100%",
            marginTop: "4%",
            display: "flex",
            flexDirection: "row" as "row",
            gap: "1rem",
            alignItems: "center"
        },
        contentAddContainer: {
            backgroundColor: 'white',
            boxShadow: "rgb(149 157 165/20%) 0px 8px 24px",
            borderRadius: "15px",
            padding: '3%',
            margin: "1%"
        },
        sectionTitleContainer: {
            display: "flex",
            flexDirection: 'row' as 'row',
            justifyContent: 'space-between'
        },
        userAttachContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: "column" as "column",
            alignItems: 'center'
        },
        sectionHeader: {
            //background: '-webkit-gradient(linear, left top, left bottom, from(white), to(#c0bcbc))',
            background: 'linear-gradient(to top, #c0bcbc,#c0bcbc,white,white,white,white)',
            width: '100%',
            padding: "2px 5px",
            boxShadow: '1px 1px 3px #29292930',
            //color: '#454545',
            textShadow: '0 1px 0 white',
            borderRadius: "10px"
        },
        workflowTabloDiv: {
            height: '200px',
            overflow: 'auto',
            width: '100%'
        },
        bulletView: {
            margin: '2px',
            cursor: 'pointer'
        },
        editWorkflowButtons: {
            float: 'right',
            width: '29.5%',
            marginTop: '1%'
        }
    }

    const getDefaultSectionModel = () => {
        let model: ISection = {
            contentList: [],
            id: "",
            moduleId: moduleId,
            statusId: '',
            backgroundUrl: "",
            createdDate: "",
            deletedDate: "",
            description: "",
            isActive: true,
            isDeleted: false,
            showDetails: false,
            rank: -1,
            title: ""
        };

        return model;
    }

    const getRoles = () => {
        NetworkRequest('/api/UserRole/GetResponsibleRoles').then((response) => {
            if (response.data.resultCode == 0) {
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (role) => {
                    list.push({
                        key: role.roleId,
                        value: role.roleId,
                        text: translate(role.displayName)
                    });
                });
                setDropdownRoles(list);
                setRoles(response.data.detail);
            }
        });
    }

    const addmodule = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            setLoader(true);
            let moduleData = {
                title: apiModule?.title,
                isActive: apiModule?.isActive,
                description: apiModule?.description,
                continueAccessAfterDone: apiModule?.continueAccessAfterDone,
                welcomeUserModule: apiModule?.welcomeUserModule,
                showOnlyCurrentRoleTasks: apiModule?.showOnlyCurrentRoleTasks,
                tenantId: tenantId,
                createUserId: userId,
                categoryId: apiModule?.categoryId == "" ? null : apiModule?.categoryId,
                createDate: moment().format()
            }
            NetworkRequest("/api/Modules/Add", moduleData).then(response => {
                let result = response.data.detail;
                if (apiModule != undefined) {
                    apiModule.id = result.id;
                }
                if (moduleImage) {
                    attachImage({ id: result.id, imgFile: modulePhotoUrl });

                }
                setApiModule(apiModule);
                setModuleId(result.id);
                setTimeout(() => {
                    setPageNumber(2);
                }, 500);
                setLoader(false);
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    };

    const attachImage = (params) => {
        var formData = new FormData();
        let module: any = {
            id: params.id,
            tenantId: tenantId,
            appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod',
        }
        formData.append('ModuleView', JSON.stringify(module));
        formData.append('Data', params.imgFile);

        NetworkRequest("/api/Modules/AttachImage", formData, true, null, undefined).then((response) => {
            toast.dismiss();
            let result = response.data;
            setApiModule({ ...apiModule, coverPhoto: result.detail.coverPhoto });
        });
    }

    const deleteContent = (contentId: string, sectionId: string) => {
        digitallicaConfirm(
            translate("İçeriği silmek istediğinizden emin misiniz?"),
            () => {
                let contentData = {
                    id: contentId
                }
                NetworkRequest("/api/SectionContent/Delete", contentData).then((response) => {

                    let result = response.data;
                    if (result.resultCode == 0) {
                        getModuleWithDetails(moduleId);
                    }
                    toast(result.message, { type: result.resultCode == 0 ? 'info' : 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });

                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const toggleSectionDetails = (section) => {
        // //console.log("className: ", e.target.className);

        section.showDetails = !section.showDetails;
        let finalArray = apiModule.sectionList.filter(
            x => x.id != section.id
        )
        finalArray = [...finalArray, section];
        finalArray.sort(sortByRank);
        apiModule.sectionList = finalArray;
        setApiModule({ ...apiModule });


    }

    const openSectionDetails = (section, e) => {
        e.preventDefault();
        section.showDetails = true;
        let finalArray = apiModule.sectionList.filter(
            x => x.id != section.id
        )
        finalArray = [...finalArray, section];
        finalArray.sort(sortByRank);
        apiModule.sectionList = finalArray;
        setApiModule({ ...apiModule });
    }

    function sortByRank(a, b) {
        return a.rank - b.rank;
    }

    const updateContentFromLocalVariable = (content) => {

        let selectedContentId = apiModule.sectionList.find(
            x => x.id == content.moduleSectionId
        ).contentList.find(
            x => x.id == content.id
        ).id;

        let finalArray = apiModule.sectionList.find(
            x => x.id == content.moduleSectionId
        ).contentList.filter(c => c.id != selectedContentId);

        finalArray = [...finalArray, content];

        finalArray.sort(sortByRank);

        apiModule.sectionList.find(
            x => x.id == content.moduleSectionId
        ).contentList = finalArray;

        setApiModule({ ...apiModule });
        ////console.log(apiModule);
    }

    const addContentFromLocalVariable = (content) => {
        let finalArray = apiModule.sectionList.find(
            x => x.id == content.moduleSectionId
        ).contentList;

        finalArray = [...finalArray, content];

        finalArray.sort(sortByRank);

        apiModule.sectionList.find(
            x => x.id == content.moduleSectionId
        ).contentList = finalArray;

        setApiModule({ ...apiModule });
    }

    const cloneContent = (id: string, sectionIndex: number) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            let contentData = {
                id: id
            }
            NetworkRequest("/api/SectionContent/Clone", contentData).then((response) => {
                let result = response.data;
                ////console.log(result);
                let clone = _.cloneDeep(apiModule);
                _.each(clone.sectionList[sectionIndex].contentList, (doc) => {
                    if (doc.rank >= result.detail.rank) {
                        doc.rank = doc.rank + 1;
                    }
                })
                clone.sectionList[sectionIndex].contentList.push(result.detail);
                clone.sectionList[sectionIndex].contentList = _.sortBy(clone.sectionList[sectionIndex].contentList, 'rank');
                setApiModule(clone);
                getAccessModel();
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const contentDetailClosed = (e) => {
        getAccessModel();
        if (e.resultCode == 0) {
            if (e.operation == "u") {
                setShowUpdateContentId('');
                updateContentFromLocalVariable(e.detail);
            } else if (e.operation == "c") {
                setShowModuleAddSectionId('');
                addContentFromLocalVariable(e.detail);
            }
        }
        if (e.resultCode == -1) {
            setShowModuleAddSectionId('');
            setShowUpdateContentId('');
        }
    }

    const addSectionAfterAddSection = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            addSection(true);
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const addSectionHandler = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            addSection();
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const addSectionAfterAddContent = () => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            addSection(false, true)?.then((response) => {
                toast(translate("Lütfen bekleyin."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
                setShowSectionAdd(false);
                window.setTimeout(() => {
                    setSection(response);
                    setShowModuleAddSectionId(response.id);
                    setCurrentSectionIndex(response.id);
                }, 1300);
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
        });
    }

    const getNewSection = (result) => {
        let newSection = {
            contentList: [],
            moduleId: result.moduleId,
            backgroundUrl: result.backgroundUrl,
            createdDate: result.createdDate,
            deletedDate: result.deletedDate,
            description: result.description,
            id: result.id,
            isActive: result.isActive,
            isDeleted: result.isDeleted,
            rank: result.rank,
            title: result.title,
            statusId: result.statusId,
            type: -1
        };
        return newSection;
    }

    const addSectionSaved = (result, isAddAnother?, isAddContent?) => {
        let newSection = getNewSection(result);
        addSectionToLocaleVariable(newSection);
        if (isAddContent != true) {
            let defaultSection = getDefaultSectionModel();
            setSection(defaultSection);
        }
        return newSection;
    }

    const afterAddSectionHandler = (isWithFile?) => {
        setShowSectionAdd(false);
        setSection({
            contentList: [],
            id: "",
            moduleId: moduleId,
            statusId: '',
            backgroundUrl: "",
            createdDate: "",
            deletedDate: "",
            description: "",
            isActive: true,
            isDeleted: false,
            showDetails: false,
            rank: -1,
            title: ""
        });
        if (isWithFile == true) {
            setBackgroundImage(undefined);
        }
    }

    const getAddSectionModel = (section?) => {
        let sectionData = {
            moduleId: apiModule?.id,
            title: section?.title,
            description: section?.description,
            rank: 0,
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            statusId: defaultStartStatusId,
            appType: window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod'
        }
        return sectionData;
    }

    const addSection = (isAddAnother?, isAddContent?) => {
        let sectionData = getAddSectionModel(section);
        ////console.log("sectionData: ", sectionData);
        setShowSectionAdd(false);
        toast(translate("Lütfen bekleyin."), {
            type: 'info',
            theme: 'dark',
            position: 'top-center',
            autoClose: 100000
        });
        if (backgroundImage != undefined) {
            var formData = new FormData();
            formData.append('BackgroundImage', backgroundImage);
            formData.append('SectionData', JSON.stringify(sectionData));
            return NetworkRequest("/api/ModuleSection/AddWithFile", formData, true).then((response) => {
                toast.dismiss();
                let result = response.data.detail;
                let newSection = addSectionSaved(result, isAddAnother, isAddContent);
                if (isAddAnother != true && isAddContent != true) {
                    afterAddSectionHandler(true);
                } else if (isAddContent == true) {
                    return newSection;
                }
                return response;
            });
        } else {
            return NetworkRequest("/api/ModuleSection/Add", {
                section: sectionData
            }).then((response) => {
                toast.dismiss();
                if (response.data.resultCode == 0) {
                    toast(translate("Başarılı."), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                    let result = response.data.detail;
                    let newSection = addSectionSaved(result);

                    apiModule.sectionList.map(x => {
                        x.isEditEnabled = false;
                    });

                    setApiModule(apiModule);

                    if (isAddAnother != true && isAddContent != true) {
                        afterAddSectionHandler();
                    } else if (isAddContent == true) {
                        return newSection;
                    }
                    if (isAddAnother) {
                        setShowSectionAdd(true);
                    }
                    return response;
                }
            });
        }
    };

    const deleteSection = (section) => {
        let message =
            "Bu bölümde kayıtlı " + section.contentList.length + " içerik mevcut.\n\n" +
            "Bölümü içerikleriyle birlikte silmek istediğinzden emin misiniz?";

        digitallicaConfirm(
            message,
            () => {
                let sectionData = {
                    id: section.id
                }
                NetworkRequest("/api/ModuleSection/Delete", sectionData).then((response) => {
                    deleteSectionFromLocaleVariable(section.id);
                });
                setShowSectionAdd(false);
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    };

    const deleteSectionFromLocaleVariable = (id) => {
        let finalArray = apiModule.sectionList.filter(
            x => x.id != id
        )
        apiModule.sectionList = finalArray;
        setApiModule({ ...apiModule });
    }

    const addSectionToLocaleVariable = (newSection) => {
        let finalArray = [...apiModule.sectionList, newSection];
        finalArray.sort(sortByRank);
        apiModule.sectionList = finalArray;
        setApiModule({ ...apiModule });
    }

    const updateSectionStrings = (sectionData) => {
        NetworkRequest("/api/ModuleSection/UpdatePartialStrings", sectionData).then(response => {
            let result = response.data.detail;
            //getModuleWithDetails(moduleId);
        });
        setShowSectionAdd(false);
    };

    const handleChange = (e) => {
        if (apiModule != undefined) {
            setApiModule({ ...apiModule, [e.target.name]: e.target.value });
        }
    }

    const handleChange2 = (name, value) => {
        if (apiModule != undefined) {
            setApiModule({ ...apiModule, [name]: value });
        }
    }

    const sectionHandleChange = (e) => {
        if (section != undefined) {
            setSection({ ...section, [e.target.name]: e.target.value });
        }
        ////console.log(section);
    }

    const getModuleWithDetails = (moduleId: string) => {
        setLoader(true);
        let request = {
            userId: DataHandler.getUserDataWithKey("userId"),
            moduleId: moduleId,
            includeStatuses: false
        };
        NetworkRequest("/api/Modules/GetWithAllDetails", request).then((response) => {
            if (response.data.resultCode === 0) {
                //console.log("getModuleWithDetails", response.data.detail);
                let result = response.data.detail.moduleView;
                setApiModule(result);
                setModuleAccessModel(response.data.detail.moduleAccessModel)
                setLoader(false);
            }
        });
    }

    const getStatusList = (id: string) => {
        let request = {
            id: id
        }
        NetworkRequest("/api/Statuses/GetList", request).then((response) => {
            if (response.data.resultCode === 0) {
                let start = _.find(response.data.detail, (doc) => {
                    return doc.isInProgress;
                });
                if (start != undefined) {
                    setDefaultStartStatusId(start.statusId);
                }
            }
        });
    }

    const getAccessEvents = (id: string) => {
        let request = {
            id: id
        }
        NetworkRequest("/api/AccessibilityEvents/GetList", request).then((response) => {
            if (response.data.resultCode === 0) {
                var starts: Array<IDropdown> = [];
                var finishes: Array<IDropdown> = [];
                var startList = _.filter(response.data.detail, (doc) => {
                    return doc.isInit == true;
                });
                var finishList = _.filter(response.data.detail, (doc) => {
                    return doc.isSaved == true;
                });
                _.each(startList, (event) => {
                    starts.push({
                        key: event.accessibilityEventId,
                        value: event.accessibilityEventId,
                        text: translate(event.displayName)
                    });
                });
                _.each(finishList, (event) => {
                    finishes.push({
                        key: event.accessibilityEventId,
                        value: event.accessibilityEventId,
                        text: translate(event.displayName)
                    });
                });
                setAccessibilityEvents(response.data.detail);
                setDropdownStartAccessibilityEvents(starts);
                setDropdownFinishAccessibilityEvents(finishes);
            }
        });
    }

    const moveDown = (id: string, sectionIndex, contentIndex) => {
        toast(translate("Lütfen bekleyin..."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 5000 });
        let request = {
            id: id
        }
        NetworkRequest("/api/SectionContent/MoveDown", request).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail) {
                let contentToUpIndex = -1;
                let clone = _.cloneDeep(apiModule);
                let contentToUp = _.find(clone.sectionList[sectionIndex].contentList, (item, i) => {
                    if (item.rank > clone.sectionList[sectionIndex].contentList[contentIndex].rank) {
                        contentToUpIndex = i;
                        return item;
                    }
                });
                clone.sectionList[sectionIndex].contentList[contentIndex].rank = clone.sectionList[sectionIndex].contentList[contentIndex].rank + 1;
                clone.sectionList[sectionIndex].contentList[contentToUpIndex].rank = clone.sectionList[sectionIndex].contentList[contentToUpIndex].rank - 1;
                clone.sectionList[sectionIndex].contentList = _.sortBy(clone.sectionList[sectionIndex].contentList, 'rank');
                setApiModule(clone);
                toast.dismiss();
                toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
            else if (response.data.resultCode == 1) {
                toast(translate(response.data.message), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });

    }

    const moveUp = (id: string, sectionIndex, contentIndex) => {
        toast(translate("Lütfen bekleyin..."), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 5000 });
        let request = {
            id: id
        }
        NetworkRequest("/api/SectionContent/MoveUp", request).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail) {
                let clone = _.cloneDeep(apiModule);
                clone.sectionList[sectionIndex].contentList[contentIndex].rank = clone.sectionList[sectionIndex].contentList[contentIndex].rank - 1;
                clone.sectionList[sectionIndex].contentList[contentIndex - 1].rank = clone.sectionList[sectionIndex].contentList[contentIndex - 1].rank + 1;
                clone.sectionList[sectionIndex].contentList = _.sortBy(clone.sectionList[sectionIndex].contentList, 'rank');
                setApiModule(clone);
                toast.dismiss();
                toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
            else if (response.data.resultCode == 1) {
                toast(translate(response.data.message), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    const showSectionEdit = (index, value) => {
        let moduleClone = _.cloneDeep(apiModule);
        _.each(moduleClone.sectionList, (doc, i) => {
            if (i == index) {
                doc.isEditEnabled = value;
            } else {
                doc.isEditEnabled = false;
            }
        });
        setApiModule(moduleClone);
    }

    const hideSectionEdit = (index) => {
        showSectionEdit(index, false);
        setSectionEditEnabled(false);
    }

    const getRoleName = (roleId) => {
        let role = _.find(dropdownRoles, (doc) => {
            return doc.value == roleId;
        });
        if (role != undefined) {
            return role.text;
        }
        return '';
    }

    const getContentTypeName = (type) => {
        if (type == 1) {
            return 'Video';
        } else if (type == 2) {
            return 'Html';
        } else if (type == 4) {
            return 'Sınav';
        } else if (type == 8) {
            return 'Anket';
        } else if (type == 15) {
            return 'Task';
        }
        return '';
    }

    const showEditContent = (id) => {

        if (showUpdateContentId == id) {
            setShowUpdateContentId('');
        } else {
            setShowUpdateContentId(id);
        }
    }

    const showContentNoti = (id) => {
        if (showUpdateContentId == id) {
            setShowUpdateContentId('');
        } else {
            setShowNoti(true);
            setShowUpdateContentId(id);
        }
    }

    const setEditSectionTitleShown = (data, index) => {
        //let clone = _.cloneDeep(apiModule);
        //clone.sectionList[index].showTitleEdit = data;
        //setApiModule(clone);
        if (data) {
            setSelectedSectionModel({ index: index, section: apiModule.sectionList[index] });
        } else {
            let clone = _.cloneDeep(apiModule);
            clone.sectionList[index].title = selectedSectionModel.section.title;
            clone.sectionList[index].description = selectedSectionModel.section.description;
            setApiModule(clone);
            setSelectedSectionModel({
                index: -1,
                section: undefined
            });
        }
    }

    const simpleModuleUpdate = () => {
        NetworkRequest("/api/Modules/SimpleModuleUpdate", {
            id: apiModule.id,
            title: apiModule.title,
            description: apiModule.description,
            continueAccessAfterDone: apiModule.continueAccessAfterDone,
            welcomeUserModule: apiModule.welcomeUserModule,
            showOnlyCurrentRoleTasks: apiModule.showOnlyCurrentRoleTasks,
            isActive: apiModule.isActive,
            categoryId: apiModule.categoryId,
            tenantId: tenantId,

        }).then((response) => {
            if (moduleImage) {
                attachImage({ id: apiModule.id, imgFile: modulePhotoUrl })
            }
            setShowModuleTitleInput(false);
        });
    }

    const getCategories = () => {
        NetworkRequest("/api/Categories/GetListWithTenantId", { id: tenantId }).then((response) => {
            if (response.data.resultCode == 0) {
                let list: Array<IDropdown> = [];
                _.each(response.data.detail, (doc) => {
                    list.push({
                        key: doc.categoryId,
                        value: doc.categoryId,
                        text: translate(doc.displayName)
                    })
                });
                setDropdownCategories(list);
            }
        });
    }

    const getContentStartRuleDisplayName = (id) => {
        let contentAccess: any = null;
        _.each(moduleAccessModel.sectionList, (sec) => {
            _.each(sec.contentList, (con) => {
                if (con.contentId == id) {
                    contentAccess = con;
                }
            });
        });
        if (contentAccess != null && contentAccess.startAccessRule != undefined) {
            return contentAccess.startAccessRule.displayName;
        }
        return "";
    }

    const getContentFinishRuleDisplayName = (id) => {
        let contentAccess: any = null;
        _.each(moduleAccessModel.sectionList, (sec) => {
            _.each(sec.contentList, (con) => {
                if (con.contentId == id) {
                    contentAccess = con;
                }
            });
        });
        if (contentAccess != null && contentAccess.finishAccessRule != undefined) {
            return contentAccess.finishAccessRule.displayName;
        }
        return "";
    }

    const getAccessModel = () => {
        NetworkRequest("/api/ModuleAccessModels/GetAccessModel", { id: apiModule.id }).then((response) => {
            if (response.data.resultCode == 0) {
                setModuleAccessModel(response.data.detail);
                if (currentContentIndex == undefined || currentContentIndex == "") {
                    window.location.hash = "moduleAddSection" + (section.contentList.length + 1);
                }
                window.location.hash = "moduleAddSection" + currentContentIndex;
                // setTimeout(() => {window.location.hash = "sectionContainer" + currentSectionIndexNo;},500)                
            }
        });
    }

    const isSectionEditShown = () => {
        return selectedSectionModel.section != undefined;
    }

    useEffect(() => {
        setLoader(true);
        if ((paramPage == undefined || paramPage != "4") && moduleId !== undefined) {
            getModuleWithDetails(moduleId);
            if (apiModule != undefined) {
                apiModule.id = moduleId;
                setApiModule(apiModule);
            }
            setPageNumber(2);
        } else {
            setLoader(false)
        }
    }, [moduleId]);

    useEffect(() => {
        if (paramPage != undefined)
            setPageNumber(JSON.parse(paramPage));
    }, [paramPage]);

    useEffect(() => {
        if (paramPage == undefined || paramPage != "4") {
            getRoles();
            getCategories();
            getStatusList(tenantId);
            getAccessEvents(tenantId);
        }
    }, [tenantId]);

    useEffect(() => {
        if (showUpdateContentId == '') {
            setShowNoti(false);
        }
    }, [showUpdateContentId]);

    useEffect(() => {
        setIsFormOk([isFormOk[0], checkFieldsIsFilled(
            section?.title,
            section?.description,
        )]);
        if (section.title != undefined && section.title != "" && section.description != undefined && section.description != "") {
            let clone = _.cloneDeep(apiModule);
            let foundSection = _.find(clone.sectionList, (doc) => {
                return doc.id == section.id;
            });
            if (foundSection != undefined) {
                foundSection.showDetails = true;
                setApiModule(clone);
            }
        }
    }, [section]);

    useEffect(() => {
        ////console.log("apiModule changed: ", apiModule);
        if (apiModule != undefined && apiModule.title != '' && apiModule.sectionList.length == 0) {
            setShowSectionAdd(true);
        }
        setIsFormOk([checkFieldsIsFilled(apiModule?.title), isFormOk[1]]);
    }, [apiModule]);

    useEffect(() => {
        if (apiModule.welcomeUserModule) {
            toast(translate("Yalnızca 1 adet hoş geldin modülü oluşturabilirsiniz!. Kayıtlı hoş geldin modülünüz var ise yenisi ile değiştirelecektir."), {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3500
            });
        }

    }, [(moduleId == null || moduleId == undefined) && apiModule.welcomeUserModule]);

    useEffect(() => {
        if (apiModule.welcomeUserModule) {
            toast(translate("Yalnızca 1 adet hoş geldin modülü oluşturabilirsiniz!. Kayıtlı hoş geldin modülünüz var ise yenisi ile değiştirelecektir."), {
                type: 'info',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3500
            });
        }
    }, [(moduleId == null || moduleId == undefined) && apiModule.welcomeUserModule]);

    useEffect(() => {
        if (progressContent == 100)
            setProgressContentText("Lütfen Bekleyin...")
    }, [progressContent]);


    return (
        <div className={"generalContainerForWhiteBg"}>
            <div style={styles.inputsSection}>
                <Transition visible={loader} animation='scale' duration={0}>
                    <Loader size="huge" active inline='centered' />
                </Transition>
                {!loader &&
                    <>
                        {pageNumber == 1
                            &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <div style={{ ...styles.inputContainer, width: "100%" }}>
                                        <div style={styles.inputSectionBox}>
                                            <div style={styles.labelContainer}>
                                                <Header as='h3'>{translate("Modül Adı: (*)")}</Header>
                                            </div>
                                        </div>
                                        <div className={"moduleAddComponentContainer"}>
                                            <div className={"moduleAddComponentLeftPanel"}>
                                                <div style={styles.inputContainer}>
                                                    <Input
                                                        className={"greyInput"}
                                                        autoFocus
                                                        value={apiModule?.title}
                                                        name="title"
                                                        required="required"
                                                        size="big"
                                                        style={{ width: "100%" }}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div style={styles.inputSectionBox}>
                                                    <div style={styles.labelContainer}>
                                                        <Header as='h3'>{translate("Açıklama:")}</Header>
                                                    </div>
                                                </div>
                                                <div style={styles.inputContainer}>
                                                    <Form style={{ width: "100%" }}>
                                                        <TextArea
                                                            className={"greyInput"}
                                                            fluid="true"
                                                            value={apiModule?.description}
                                                            rows={5}
                                                            name="description"
                                                            required="required"
                                                            maxLength="150"
                                                            size="big"
                                                            style={{ width: "100%" }}
                                                            onChange={handleChange}
                                                        />
                                                    </Form>

                                                </div>
                                                <div style={styles.inputSectionBox}>
                                                    <div >
                                                        <Header as='h3'>{translate("Kapak Fotoğrafı")}:</Header>
                                                    </div>
                                                </div>
                                                <div style={styles.inputContainer}>
                                                    <FileInputComp
                                                        setPhotoUrl={setModulePhotoUrl}
                                                        setImage={setModuleImage}
                                                        type={"image"}
                                                        accept={".png, .jpg, .jpeg"}
                                                        dontShowPreview={true}
                                                    />
                                                </div>
                                            </div>

                                            <div className={"moduleAddComponentRightPanel"}>
                                                {dropdownCategories.length > 0
                                                    &&
                                                    <>
                                                        <div style={styles.inputSectionBox}>
                                                            <div style={styles.labelContainer}>
                                                                <Header as='h3'>{translate("Kategoriler:")}</Header>
                                                            </div>
                                                        </div>
                                                        <div style={{ ...styles.inputContainer, marginTop: "1%" }}>
                                                            <Form>
                                                                <Dropdown
                                                                    className={"greyInput"}
                                                                    style={{ width: '100%' }}
                                                                    value={apiModule?.categoryId}
                                                                    name="categoryId"
                                                                    required="required"
                                                                    clearable
                                                                    options={dropdownCategories}
                                                                    selection
                                                                    placeholder={translate("Kategori Seçiniz")}
                                                                    onChange={(e, data) => {
                                                                        setApiModule({ ...apiModule, categoryId: data.value });
                                                                    }}
                                                                />
                                                            </Form>
                                                        </div>
                                                    </>
                                                }


                                                <div style={{ width: "50%", marginTop: "2%" }}>

                                                    <div style={styles.inputSectionBox}>
                                                        <Checkbox toggle
                                                            name="isActive"
                                                            checked={apiModule?.isActive}
                                                            onChange={(e, data) => handleChange2("isActive", data.checked)}
                                                            label={translate("Aktif")}
                                                        />
                                                    </div>
                                                    <br />

                                                    <div style={styles.inputSectionBox}>
                                                        <Checkbox toggle
                                                            name="continueAccessAfterDone"
                                                            checked={apiModule?.continueAccessAfterDone}
                                                            onChange={(e, data) => handleChange2("continueAccessAfterDone", data.checked)}
                                                            label={translate("Tamamlayanlar Erişime Devam Etsin")}
                                                        />
                                                    </div>
                                                    <br />
                                                    <div style={styles.inputSectionBox}>
                                                        <Checkbox toggle
                                                            name="welcomeUserModule"
                                                            checked={apiModule?.welcomeUserModule}
                                                            onChange={(e, data) => handleChange2("welcomeUserModule", data.checked)}
                                                            label={translate("Hoş geldin Çalışan Modülü Olarak Ata")}
                                                        />
                                                    </div>
                                                    <br />
                                                    <div style={styles.inputSectionBox}>
                                                        <Checkbox toggle
                                                            name="showOnlyCurrentRoleTasks"
                                                            checked={apiModule?.showOnlyCurrentRoleTasks}
                                                            onChange={(e, data) => handleChange2("showOnlyCurrentRoleTasks", data.checked)}
                                                            label={translate("Çalışanlar sadece kendi sorumluluğundaki görevleri görsün")}
                                                        />
                                                    </div>
                                                    <div style={{ width: '25%', marginTop: '35%', float: 'right' }}>
                                                        <Button
                                                            color={"blue"}
                                                            style={{
                                                                width: '190px'
                                                            }}
                                                            onClick={addmodule}
                                                            disabled={!isFormOk[0]}
                                                            className="roundedButton"
                                                        >
                                                            {translate("Devam Et")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {pageNumber == 2
                            &&
                            <>
                                <div style={styles.moduleDetailList}>
                                    <Transition visible={isSectionEditShown()} animation="fade down" duration={100}>
                                        <Card className="width100">
                                            <Card.Content>
                                                <Card.Header>
                                                    {translate("Bölüm Düzenle")}
                                                </Card.Header>
                                            </Card.Content>
                                            <Card.Content style={{ display: "flex", flexDirection: "column" as "column", gap: "2em" }}>
                                                <div>
                                                    <span>{translate("Adı")}</span>
                                                    <Input
                                                        id={uuidv4()}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        className={"greyInput"}
                                                        value={selectedSectionModel.section?.title}
                                                        name="sectionTitle"
                                                        required="required"
                                                        size="big"
                                                        style={styles.inputStyle}
                                                        placeholder={translate("Bölüm Adı")}
                                                        onChange={(e) => {
                                                            setSelectedSectionModel({ ...selectedSectionModel, section: { ...selectedSectionModel.section, title: e.target.value, description: selectedSectionModel.section.description } });
                                                            //updateSectionTitle(selectedSectionTitleModel.id, e.target.value, selectedSectionTitleModel.description, sectionIndex);
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <span>{translate("Açıklama")}</span>
                                                    <Input
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                        autoFocus
                                                        className={"greyInput"}
                                                        value={selectedSectionModel.section?.description}
                                                        name="description"
                                                        required="required"
                                                        size="big"
                                                        style={styles.inputStyle}
                                                        placeholder={translate("Açıklama")}
                                                        onChange={(e) => {
                                                            setSelectedSectionModel({ ...selectedSectionModel, section: { ...selectedSectionModel.section, title: selectedSectionModel.section.title, description: e.target.value } });
                                                            //updateSectionTitle(selectedSectionDescModel.id, selectedSectionDescModel.title, e.target.value, sectionIndex);
                                                        }}
                                                    />
                                                </div>


                                            </Card.Content>
                                            <Card.Content>
                                                <Card.Header style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            updateSectionStrings(selectedSectionModel.section);
                                                            setEditSectionTitleShown(false, selectedSectionModel.index);
                                                        }}
                                                        circular
                                                        color="green"


                                                    >

                                                        {translate("Kaydet")}


                                                    </Button>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setEditSectionTitleShown(false, selectedSectionModel.index)
                                                        }}
                                                        circular
                                                        color="red"


                                                    >

                                                        {translate("İptal")}


                                                    </Button>
                                                </Card.Header>
                                            </Card.Content>
                                        </Card>
                                    </Transition>
                                    <Transition visible={!isSectionEditShown()} animation="fade down" duration={200}>
                                        <div>
                                            {!showModuleTitleInput
                                                &&
                                                <Grid.Row>
                                                    <Grid.Column
                                                        style={{ fontSize: '30px', width: '85%', wordBreak: 'break-all' }}>


                                                        {apiModule?.title + " "}
                                                        <Icon
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: "#f2711c85",
                                                                fontSize: "20px"
                                                            }}
                                                            name="edit"
                                                            onClick={() => {
                                                                setShowModuleTitleInput(true)
                                                            }}
                                                        />

                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                            {showModuleTitleInput
                                                &&
                                                <div>
                                                    <Header as='h3'>

                                                        {translate("Modül Adı")}

                                                        {/*<Icon style={{ cursor: 'pointer', fontSize: '20px', marginLeft:"2%" }} color="green" name="check" onClick={() => { simpleModuleUpdate(); }} />*/}

                                                    </Header>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        width: "100%",
                                                        gap: "2em"
                                                    }}>

                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "1.2em"
                                                        }}>
                                                            <div>
                                                                <Input
                                                                    className={"greyInput"}
                                                                    autoFocus
                                                                    value={apiModule.title}
                                                                    name="moduleTitle"
                                                                    required="required"
                                                                    size="big"
                                                                    style={styles.inputStyle}
                                                                    placeholder={translate("Modül Adı")}
                                                                    onChange={(e) => {
                                                                        setApiModule({ ...apiModule, title: e.target.value });
                                                                    }}
                                                                />
                                                            </div>


                                                            <div>
                                                                <Dropdown
                                                                    className={"greyInput"}
                                                                    style={{ width: '100%' }}
                                                                    value={apiModule.categoryId}
                                                                    name="categoryId"
                                                                    required="required"
                                                                    clearable
                                                                    options={dropdownCategories}
                                                                    selection
                                                                    placeholder={translate("Kategori Seçiniz")}
                                                                    onChange={(e, data) => {
                                                                        setApiModule({
                                                                            ...apiModule,
                                                                            categoryId: data.value
                                                                        });
                                                                    }}
                                                                />
                                                            </div>

                                                            <div>
                                                                <Form>
                                                                    <TextArea
                                                                        className={"greyInput"}
                                                                        value={apiModule?.description}
                                                                        rows={5}
                                                                        name="description"
                                                                        required="required"
                                                                        maxLength="150"
                                                                        size="big"
                                                                        style={{ width: "200%" }}
                                                                        placeholder={translate("Açıklama")}
                                                                        onChange={(e) => {
                                                                            setApiModule({
                                                                                ...apiModule,
                                                                                description: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form>
                                                            </div>

                                                        </div>
                                                        <div style={{ width: "100%" }}>

                                                            <div style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                gap: "1.2em",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <div style={{
                                                                    width: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    gap: "1.2em",
                                                                }}>
                                                                    <Checkbox toggle
                                                                        name="continueAccessAfterDone"
                                                                        style={{ marginLeft: '5px' }}
                                                                        checked={apiModule?.continueAccessAfterDone}
                                                                        onChange={(e, data) => setApiModule({
                                                                            ...apiModule,
                                                                            continueAccessAfterDone: data.checked
                                                                        })}
                                                                        label={translate("Tamamlayanlar Erişime Devam Etsin")}
                                                                    />

                                                                    <Checkbox toggle
                                                                        name="isActive"
                                                                        style={{ marginLeft: '5px' }}
                                                                        checked={apiModule?.isActive}
                                                                        onChange={(e, data) => setApiModule({
                                                                            ...apiModule,
                                                                            isActive: data.checked
                                                                        })}
                                                                        label={translate("Aktif")}
                                                                    />

                                                                    <Checkbox toggle
                                                                        name="welcomeUserModule"
                                                                        style={{ marginLeft: '5px' }}
                                                                        checked={apiModule?.welcomeUserModule}
                                                                        onChange={(e, data) => setApiModule({
                                                                            ...apiModule,
                                                                            welcomeUserModule: data.checked
                                                                        })}
                                                                        label={translate("Hoş geldin Çalışan Modülü Olarak Ata")}
                                                                    />
                                                                    <Checkbox toggle
                                                                        style={{ marginLeft: '5px' }}
                                                                        name="showOnlyCurrentRoleTasks"
                                                                        checked={apiModule?.showOnlyCurrentRoleTasks}
                                                                        onChange={(e, data) => handleChange2("showOnlyCurrentRoleTasks", data.checked)}
                                                                        label={translate("Çalışan sadece kendi sorumluluğundaki görevleri görsün")}
                                                                    />
                                                                    <div style={{ ...styles.inputContainer, gap: "1.5rem", flexDirection: "row" as "row" }}>
                                                                        <FileInputComp
                                                                            setPhotoUrl={setModulePhotoUrl}
                                                                            setImage={setModuleImage}
                                                                            type={"image"}
                                                                            accept={".png, .jpg, .jpeg"}
                                                                            dontShowPreview={true}
                                                                        />
                                                                        {apiModule.coverPhoto &&
                                                                            <img src={apiModule.coverPhoto} width="250" />
                                                                        }
                                                                    </div>

                                                                </div>


                                                            </div>

                                                        </div>


                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        width: "100%",
                                                        marginTop: "2%"
                                                    }}>
                                                        <Button
                                                            className={"roundedButton"}
                                                            color={"green"}
                                                            onClick={() => {
                                                                simpleModuleUpdate();
                                                            }}
                                                        >
                                                            {translate("Güncelle")}
                                                        </Button>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                    </Transition>
                                    <Transition visible={!isSectionEditShown()} animation="fade up" duration={200}>
                                        <div>
                                            <Header className="moduleEditHeader" as='h2'>{translate("Bölümler")}</Header>
                                            {apiModule.sectionList.map((section, sectionIndex) =>
                                                <div
                                                    onClick={(e) => {

                                                        if (section.id != currentSectionIndex || !showDetail) {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            toggleSectionDetails(section);
                                                            setShowDetail(true);
                                                            setCurrentSectionIndex(oldValue => section.id);
                                                        }
                                                    }}
                                                    id={"sectionContainer" + sectionIndex}
                                                    className={"sectionItemContainer"}
                                                    key={uuidv4()}
                                                >
                                                    {showDetail && (section.id == currentSectionIndex) &&
                                                        <div style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            marginBottom: "1%",
                                                            justifyContent: "flex-start"
                                                        }}>
                                                            <Button
                                                                className={"roundedButton"}
                                                                size={"large"}
                                                                color={"red"}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowDetail(false);
                                                                    setCurrentSectionIndex("");
                                                                }}
                                                            >{translate("Kapat")}</Button>
                                                        </div>
                                                    }
                                                    <Grid columns={2}>
                                                        <Grid.Row>
                                                            <Grid.Column style={{ width: '87%' }}>
                                                                <Grid.Row>
                                                                    <Grid.Column style={{ marginBottom: '1%' }}>
                                                                        <Grid columns={2}>
                                                                            {selectedSectionModel.section == undefined
                                                                                &&
                                                                                <Grid.Row>
                                                                                    <Grid.Column>
                                                                                        <div style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "flex-start",
                                                                                            gap: ".5em"
                                                                                        }}>
                                                                                            <div>
                                                                                                <Header as={"h3"}
                                                                                                    className={"moduleAddSectionTitleContent"}>
                                                                                                    {section?.title + " "}
                                                                                                </Header>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Popup content={translate("Başlığı Düzenle")}
                                                                                                    trigger={
                                                                                                        <span>
                                                                                                            <Icon
                                                                                                                size={"large"}
                                                                                                                style={{
                                                                                                                    cursor: 'pointer',
                                                                                                                    color: "#f2711c85"
                                                                                                                }}
                                                                                                                name="edit"
                                                                                                                onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    e.stopPropagation();
                                                                                                                    setEditSectionTitleShown(true, sectionIndex)
                                                                                                                }}
                                                                                                            />
                                                                                                        </span>
                                                                                                    } />
                                                                                            </div>

                                                                                        </div>

                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            }
                                                                        </Grid>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <Grid columns={2}>
                                                                            {selectedSectionModel.section == undefined
                                                                                &&
                                                                                <Grid.Row>
                                                                                    <Grid.Column style={{
                                                                                        fontSize: '15px',
                                                                                        width: "100%",
                                                                                        color: "#646464"
                                                                                    }}>
                                                                                        <p>{section?.description + "  "}
                                                                                            <Popup content={translate("Açıklamayı düzenle")}
                                                                                                trigger={
                                                                                                    <Icon
                                                                                                        style={{
                                                                                                            cursor: 'pointer',
                                                                                                            color: "#f2711c85"
                                                                                                        }}
                                                                                                        name="edit"
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            setEditSectionTitleShown(true, sectionIndex)
                                                                                                        }}
                                                                                                    />
                                                                                                } />
                                                                                        </p>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            }
                                                                        </Grid>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column>
                                                                        <p className="moduleDetailSubRecordInfo">
                                                                            {translate("Bu bölümde kayıtlı olan içerik sayısı")} : {section.contentList.length}
                                                                            {/*Bu bölümde toplam {section.contentList.length} içerik kayıtlıdır*/}
                                                                        </p>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid.Column>
                                                            <Grid.Column style={{ width: '13%', textAlign: 'right' }}>
                                                                {!section.isEditEnabled
                                                                    &&
                                                                    <div className={"moduleAddSectionSectionButtonGroup"}>

                                                                        <div>
                                                                            <Popup position='top left' content={translate("Sil")}
                                                                                trigger={<Button className={"roundedButton"}
                                                                                    icon='trash' color="red"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        e.preventDefault();
                                                                                        deleteSection(section)
                                                                                    }} />}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>

                                                    <div>
                                                        <Transition
                                                            visible={showDetail && (section.id == currentSectionIndex)}
                                                            animation={"fade down"}
                                                            duration={300}>
                                                            <div>
                                                                <>
                                                                    <div>
                                                                        <Header style={{
                                                                            width: 'fit-content',
                                                                            marginTop: "4%"
                                                                        }} as='h2'>{translate("İçerikler")}</Header>

                                                                        {section.contentList.map((content, contentIndex) =>
                                                                            <div
                                                                                key={uuidv4()}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                }}
                                                                                className={"sectionRow"}
                                                                            >
                                                                                <div id={"moduleAddSection" + contentIndex} className={"moduleAddSectionRowClass"}>
                                                                                    <div
                                                                                        className={"moduleAddSectionRowFirstContent"}>
                                                                                        <div
                                                                                            className={"moduleAddSectionUpOrDownContent"}>
                                                                                            {section.contentList.length > 1 && contentIndex > 0
                                                                                                &&
                                                                                                <Popup
                                                                                                    content={translate("Yukarı Taşı")}
                                                                                                    trigger={
                                                                                                        <Button
                                                                                                            className={"roundedButton"}
                                                                                                            color={"blue"}
                                                                                                            size={"mini"}
                                                                                                            onClick={() => {
                                                                                                                moveUp(content.id, sectionIndex, contentIndex)
                                                                                                            }}
                                                                                                            animated='vertical'>
                                                                                                            <Button.Content
                                                                                                                hidden>
                                                                                                                <Icon
                                                                                                                    name='chevron up' /></Button.Content>
                                                                                                            <Button.Content
                                                                                                                visible>
                                                                                                                <Icon
                                                                                                                    name='chevron up' />
                                                                                                            </Button.Content>
                                                                                                        </Button>
                                                                                                    }
                                                                                                />

                                                                                            }
                                                                                            {section.contentList.length > 1 && section.contentList.length - 1 != contentIndex
                                                                                                &&
                                                                                                <Popup
                                                                                                    content={translate("Aşağı Taşı")}
                                                                                                    position='bottom center'
                                                                                                    trigger={
                                                                                                        <Button
                                                                                                            className={"roundedButton"}
                                                                                                            color={"blue"}
                                                                                                            size={"mini"}
                                                                                                            onClick={() => {
                                                                                                                moveDown(content.id, sectionIndex, contentIndex)
                                                                                                            }}
                                                                                                            animated='vertical'>
                                                                                                            <Button.Content
                                                                                                                hidden>
                                                                                                                <Icon
                                                                                                                    name='chevron down' /></Button.Content>
                                                                                                            <Button.Content
                                                                                                                visible>
                                                                                                                <Icon
                                                                                                                    name='chevron down' />
                                                                                                            </Button.Content>
                                                                                                        </Button>
                                                                                                    }
                                                                                                />
                                                                                            }
                                                                                        </div>

                                                                                        <div
                                                                                            className={"moduleAddSectionContentHeader"}>
                                                                                            <strong
                                                                                                className="fontSize17">{content.rank} - {content.title}</strong>

                                                                                            <div>
                                                                                                <Label color={"orange"}
                                                                                                    className={"moduleAddInfoLabelClass"}>
                                                                                                    <Icon
                                                                                                        name='map' />
                                                                                                    {translate("Başlangıç")}
                                                                                                    <Label.Detail>{getContentStartRuleDisplayName(content.id)}</Label.Detail>
                                                                                                </Label>

                                                                                                <Label color={"orange"}
                                                                                                    className={"moduleAddInfoLabelClass"}>
                                                                                                    <Icon
                                                                                                        name='flag' />
                                                                                                    {translate("Bitiş")}
                                                                                                    <Label.Detail>{getContentFinishRuleDisplayName(content.id)}</Label.Detail>
                                                                                                </Label>

                                                                                                <Label color={"yellow"}
                                                                                                    className={"moduleAddInfoLabelClass"}>
                                                                                                    <Icon
                                                                                                        name='user' />
                                                                                                    {translate("Sorumlu")}
                                                                                                    <Label.Detail>{getRoleName(content.responsibleRoleId)}</Label.Detail>
                                                                                                </Label>

                                                                                                <Label color={"yellow"}
                                                                                                    className={"moduleAddInfoLabelClass"}>
                                                                                                    <Icon
                                                                                                        name='boxes' />
                                                                                                    {translate("Tipi")}
                                                                                                    <Label.Detail>{translate(getContentTypeName(content.contentType))}</Label.Detail>
                                                                                                </Label>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div
                                                                                            className={"moduleAddSectionButtonGroup"}>
                                                                                            <Popup position='top left'
                                                                                                content={translate("Düzenle")}
                                                                                                trigger={<Button
                                                                                                    className={"roundedButton"}
                                                                                                    icon='edit'
                                                                                                    color="blue"
                                                                                                    onClick={() => {
                                                                                                        showEditContent(content.id)
                                                                                                    }} />}
                                                                                            />
                                                                                            <Popup position='top left'
                                                                                                content={translate("Klonla")}
                                                                                                trigger={<Button
                                                                                                    className={"roundedButton"}
                                                                                                    icon='copy'
                                                                                                    onClick={() => {
                                                                                                        cloneContent(content.id, sectionIndex)
                                                                                                    }} />}
                                                                                            />
                                                                                            <Popup position='top left'
                                                                                                content={translate("Sil")}
                                                                                                trigger={<Button
                                                                                                    className={"roundedButton"}
                                                                                                    icon='trash'
                                                                                                    color="red"
                                                                                                    onClick={() => {
                                                                                                        deleteContent(content.id, section.id)
                                                                                                    }} />}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <Transition
                                                                                    visible={showUpdateContentId == content.id}
                                                                                    animation='fade down' duration={500}>
                                                                                    <div
                                                                                        style={styles.contentAddContainer}>
                                                                                        <ContentAdd
                                                                                            apiModule={apiModule}
                                                                                            setApiModule={setApiModule}
                                                                                            id={content.id}
                                                                                            sectionId={section.id}
                                                                                            moduleId={moduleId}
                                                                                            contentDetailClosed={contentDetailClosed}
                                                                                            startStatusId={defaultStartStatusId}
                                                                                            dropdownStartAccessibilityEvents={dropdownStartAccessibilityEvents}
                                                                                            dropdownFinishAccessibilityEvents={dropdownFinishAccessibilityEvents}
                                                                                            accessibilityEvents={accessibilityEvents}
                                                                                            dropdownRoles={dropdownRoles}
                                                                                            showNoti={showNoti}
                                                                                            setShowNoti={setShowNoti}
                                                                                            setShowModuleAddSectionId={setShowModuleAddSectionId}
                                                                                            setShowUpdateContentId={setShowUpdateContentId}
                                                                                            getAccessModel={getAccessModel}
                                                                                            setCurrentContentIndex={setCurrentContentIndex}
                                                                                            currentContentIndex={contentIndex}
                                                                                            setProgressContent={setProgressContent}
                                                                                            setProgressContentText={setProgressContentText}
                                                                                            setIsUploading={setIsUploading}
                                                                                        />
                                                                                    </div>
                                                                                </Transition>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            </div>
                                                        </Transition>
                                                    </div>
                                                    {showModuleAddSectionId == section.id
                                                        &&
                                                        <div style={styles.contentAddContainer}>
                                                            <ContentAdd
                                                                apiModule={apiModule}
                                                                setApiModule={setApiModule}
                                                                id=""
                                                                moduleId={moduleId}
                                                                sectionId={section.id}
                                                                contentDetailClosed={contentDetailClosed}
                                                                startStatusId={defaultStartStatusId}
                                                                dropdownStartAccessibilityEvents={dropdownStartAccessibilityEvents}
                                                                dropdownFinishAccessibilityEvents={dropdownFinishAccessibilityEvents}
                                                                accessibilityEvents={accessibilityEvents}
                                                                dropdownRoles={dropdownRoles}
                                                                setShowModuleAddSectionId={setShowModuleAddSectionId}
                                                                setShowUpdateContentId={setShowUpdateContentId}
                                                                getAccessModel={getAccessModel}
                                                                setCurrentContentIndex={setCurrentContentIndex}
                                                                // currentContentIndex = {currentContentIndex}
                                                                // contentListLength = {section.contentList.length}
                                                                setShowNoti={setShowNoti}
                                                                setProgressContent={setProgressContent}
                                                                setProgressContentText={setProgressContentText}
                                                                setIsUploading={setIsUploading}
                                                            />
                                                        </div>
                                                    }
                                                    <div style={styles.buttonContainer}>
                                                        {showModuleAddSectionId == '' && !sectionEditEnabled
                                                            &&
                                                            <Button
                                                                className={"roundedButton"}
                                                                onClick={(e) => {

                                                                    setSection({ ...section, showDetails: true });
                                                                    setShowModuleAddSectionId(section.id)
                                                                }}
                                                                color={"blue"} icon
                                                                disabled={isUploading}
                                                                labelPosition='left'>
                                                                <Icon name='plus' />
                                                                {translate("İçerik Ekle")}
                                                            </Button>
                                                        }
                                                        {isUploading &&
                                                            <Progress style={styles.progress} percent={progressContent} inverted indicating progress>
                                                                {progressContentText}
                                                            </Progress>}
                                                    </div>
                                                </div>
                                            )}
                                            {showSectionAdd
                                                &&
                                                <div style={styles.contentAddContainer}>
                                                    <div className={"contentAddHeaderContainer"}>
                                                        <div><Header as={"h3"}>{translate("Bölüm Ekle")}</Header></div>
                                                        <div>
                                                            <Button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowSectionAdd(false);
                                                                }}
                                                                className={"roundedButton"}
                                                                color={"red"}
                                                            >{translate("Kapat")}
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <Grid style={{ width: '100%', marginTop: '1%' }} columns={1}>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Header as='h3'>{translate("Adı (*)")}</Header>
                                                                <Input
                                                                    className={"greyInput"}
                                                                    autoFocus
                                                                    value={section?.title}
                                                                    name="title"
                                                                    required="required"
                                                                    size="big"
                                                                    style={styles.inputStyle}
                                                                    placeholder={translate("Bölüm Adı")}
                                                                    onChange={sectionHandleChange}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Header as='h3'>{translate("Açıklama (*)")}</Header>
                                                                <Form>
                                                                    <TextArea
                                                                        className={"greyInput"}
                                                                        value={section?.description}
                                                                        rows={5}
                                                                        name="description"
                                                                        required="required"
                                                                        size="big"
                                                                        style={styles.inputStyleDescription}
                                                                        placeholder={translate("Açıklama")}
                                                                        onChange={sectionHandleChange}
                                                                    />
                                                                </Form>
                                                            </Grid.Column>
                                                        </Grid.Row>

                                                        <div style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center"
                                                        }}>
                                                            <Button className={"roundedButton"} color='green'
                                                                onClick={addSectionHandler}
                                                                disabled={!isFormOk[1]}>
                                                                <Button.Content visible>{translate("Kaydet")}</Button.Content>
                                                            </Button>

                                                            <Button className={"roundedButton"} color='orange'
                                                                onClick={addSectionAfterAddSection}
                                                                disabled={!isFormOk[1]}>
                                                                <Button.Content visible>{translate("Kaydet ve Bölüm Ekle")}</Button.Content>
                                                            </Button>

                                                            <Button className={"roundedButton"} color='yellow'
                                                                onClick={addSectionAfterAddContent}
                                                                disabled={!isFormOk[1]}>
                                                                <Button.Content visible>{translate("Kaydet ve İçerik Ekle")}</Button.Content>
                                                            </Button>
                                                        </div>


                                                    </Grid>

                                                </div>
                                            }
                                            {!showSectionAdd && showModuleAddSectionId == ''
                                                &&
                                                <div style={{
                                                    width: "100%",
                                                    margin: "3% auto",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <Button
                                                        size={"big"}
                                                        className={"ingLeaf"}
                                                        onClick={() => {
                                                            setShowDetail(false);
                                                            setCurrentSectionIndex("");
                                                            setTimeout(() => {
                                                                setShowSectionAdd(true);
                                                            }, 1000);
                                                        }}
                                                        circular
                                                        icon
                                                        labelPosition='left'
                                                    >
                                                        <Icon name='plus' />
                                                        {translate("Bölüm Ekle")}
                                                    </Button>

                                                </div>
                                            }
                                            <div style={{ display: "flex", justifyContent: "center", gap: "2.5%" }}>
                                                <Button
                                                    onClick={() => setPageNumber(4)}
                                                    size={"big"}
                                                    circular
                                                    className="ingOrange"
                                                    icon
                                                    labelPosition='left'
                                                >
                                                    <Icon name='user' />
                                                    {translate("Modüle Çalışan Ekle")}
                                                </Button>
                                                <Button
                                                    onClick={() => setPageNumber(5)}
                                                    size={"big"}
                                                    className={"ingLime"}
                                                    circular

                                                    icon
                                                    labelPosition='left'
                                                >
                                                    <Icon name='certificate' />
                                                    {translate("Modüle Sertifika Tanımla")}
                                                </Button>
                                            </div>
                                        </div>
                                    </Transition>
                                </div>
                            </>
                        }
                        {pageNumber == 4
                            &&
                            <>
                                <div style={styles.userAttachContainer}>
                                    <div style={{ width: '80%' }}>

                                        <Message
                                            style={{ borderRadius: "45px" }}
                                            icon='exclamation circle'
                                            header={translate('Uyarı')}
                                            content={translate("Bu tabloda sadece kullanıcı rolü 'Çalışan' olan kullanıcılar gösterilir ve bir kullanıcının modüle atanabilmesi için kullanıcıya Buddy, Yönetici, İnsan Kaynakları Sorumlusu, BT Sorumlusu ve İdari İşler Sorumlusu tanımlanmış olmalıdır.")}
                                            color={"orange"}
                                        />

                                        <UserAttachToCourse id={moduleId} />

                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "1rem",
                                            alignItems: "center"
                                        }}>
                                            <Button color={"blue"} className={"roundedButton"}
                                                style={{ width: "10%" }}
                                                onClick={() => setPageNumber(2)} fluid>
                                                <Icon name='angle left' />{translate("Geri Dön")}
                                            </Button>
                                            {/*<Label style={{ width: "65%", height: "50px", textIndent: "1rem" }}> Bu*/}
                                            {/*    tabloda sadece kullanıcı rolü 'Kullanıcı' olan kullanıcılar gösterilir*/}
                                            {/*    ve bir kullanıcının modüle atanabilmesi için kullanıcıya Buddy, Yönetici, İnsan Kaynakları Sorumlusu,*/}
                                            {/*    BT Sorumlusu ve İdari İşler Sorumlusu tanımlanmış olmalıdır.*/}
                                            {/*</Label>*/}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {pageNumber == 5
                            &&
                            <>
                                <div style={styles.userAttachContainer}>
                                    <div style={{ width: '80%' }}>
                                        <CertificateAttachToModule id={moduleId} />
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "1rem",
                                            alignItems: "center"
                                        }}>
                                            <Button size="big" className={"roundedButton"} color={"blue"}
                                                style={{ margin: "2rem 0" }}
                                                onClick={() => setPageNumber(2)}>
                                                <Icon name='angle left' />{translate("Geri Dön")}
                                            </Button>

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default ModuleAdd;