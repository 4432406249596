import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Input, Label, Icon, Dropdown } from 'semantic-ui-react';
import { NetworkRequest } from '../../../../helper/NetworkRequest';
import { IDropdown } from '../../../../interfaces/interfaces';

const AddTenantSubscription = (props) => {

    let styles = {
        labelWidth: {
            width: '15%'
        }
    }

    const [displayName, setDisplayName] = useState('');
    const [subscriptionKey, setSubscriptionKey] = useState('');
    const [licenseCount, setLicenseCount] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tenantId, setTenantId] = useState<any>();
    const [subscriptionTypeId, setSubscriptionTypeId] = useState<any>();
    const [tenants, setTenants] = useState<Array<IDropdown>>([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState<Array<IDropdown>>([]);

    const save = () => {
        let model = {
            displayName: displayName,
            subscriptionKey: subscriptionKey,
            licenseCount: licenseCount,
            startDate: startDate,
            endDate: endDate,
            tenantId: tenantId,
            subscriptionTypeId: subscriptionTypeId
        };
        ////console.log(model);
        NetworkRequest("/api/TenantSubscriptions/Add", model).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                props.showList();
            }
        });
    }

    const getTenants = () => {
        NetworkRequest("/api/Tenants/GetAll").then((response) => {
            ////
            if (response.data.resultCode === 0) {
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response.data.detail.length; i++) {
                    list.push({
                        key: response.data.detail[i].tenantId,
                        value: response.data.detail[i].tenantId,
                        text: response.data.detail[i].displayName
                    })
                }
                setTenants(list);
            }
        });
    }

    const getSubscriptionTypes = () => {
        NetworkRequest("/api/SubscriptionTypes/GetAll").then((response) => {
            ////
            if (response.data.resultCode === 0) {
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response.data.detail.length; i++) {
                    list.push({
                        key: response.data.detail[i].subscriptionTypeId,
                        value: response.data.detail[i].subscriptionTypeId,
                        text: response.data.detail[i].displayName
                    })
                }
                setSubscriptionTypes(list);
            }
        });
    }

    useEffect(() => {
        getTenants();
        getSubscriptionTypes();
    }, []);

    return (
        <div>
            <Grid>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Adı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input placeholder='Adı'
                            onChange={(e) => { setDisplayName(e.target.value); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Abonelik Anahtarı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input placeholder='Abonelik Anahtarı'
                            onChange={(e) => { setSubscriptionKey(e.target.value); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Lisans Sayısı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input type="number"
                            placeholder='Lisans Sayısı'
                            onChange={(e) => { setLicenseCount(JSON.parse(e.target.value)); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Abone:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Dropdown
                            required="required"
                            clearable
                            options={tenants}
                            selection
                            placeholder="Abone Seçiniz"
                            onChange={(e, data) => { setTenantId(data.value); }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Abonelik Tipi:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Dropdown
                            required="required"
                            clearable
                            options={subscriptionTypes}
                            selection
                            placeholder="Abonelik Tipi Seçiniz"
                            onChange={(e, data) => { setSubscriptionTypeId(data.value); }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Başlama Tarihi:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            timezone="[[timezone]]"
                            name="workStartDateRangeStart"
                            required="required"
                            type="date"
                            placeholder='Başlama Tarihi'
                            onChange={(e) => { setStartDate(e.target.value) }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={styles.labelWidth}>
                        <Label>
                            Bitiş Tarihi:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input
                            timezone="[[timezone]]"
                            name="workStartDateRangeStart"
                            required="required"
                            type="date"
                            placeholder='Bitiş Tarihi'
                            onChange={(e) => { setEndDate(e.target.value) }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button className={"dangerRedButton"} onClick={props.showList}>
                            <Icon name="arrow left" />
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button className={"positiveGreenButton"} onClick={save}>
                            <Icon name="check" />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default AddTenantSubscription;