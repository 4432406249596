import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Input, Button, Loader, Dropdown } from "semantic-ui-react";
import { NetworkRequest } from "../../../helper/NetworkRequest";
import { toast } from "react-toastify";
import { ILanguageDropdown } from '../../../interfaces/interfaces';
import { selectedLanguageContext } from "../../../Store";
import { DataHandler } from '../../../helper/DataHelper';
import TranslateHelper from "../../../helper/TranslateHelper";

const LanguageSettings = () => {
    var { translate } = TranslateHelper();
    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [isSave, setIsSave] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const getActiveLanguages = () => {
        setIsLoading(true);
        NetworkRequest("/api/Language/GetActiveLanguages", null)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;

                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: l.text,
                            value: l.value
                        }

                        //console.log(languageForDropdown);

                        setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                    })

                    setIsLoading(false);
                }
            });
    }

    const getSelectedLanguageByTenantId = () => {

        let tenantData = {
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        };

        NetworkRequest("/api/Language/GetSelectedLanguageByTenantId", tenantData)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    var language = response.data.detail;

                    if (language != null) {
                        setSelectedDefaultLanguage(language.value);
                    }
                } else {
                    setSelectedDefaultLanguage("tr-TR");
                }
            });
    }

    const handleChanges = (event: any, data: any) => {
        setSelectedDefaultLanguage(data.value);
    }

    const saveLanguageSettings = () => {

        if (selectedDefaultLanguage == "") {
            toast("Lütfen bir dil seçiniz",
                {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });

            return;
        }

        setIsSave(true);

        let tenantData = {
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            languageCode: selectedDefaultLanguage
        };


        NetworkRequest("/api/Language/SaveSelectedLanguage", tenantData)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    //debugger;
                    var result = response.data.detail;
                    setSelectedLanguage(result);
                    toast(translate(response.data.message),
                        {
                            type: 'success',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });

                } else {
                    toast(translate(response.data.message),
                        {
                            type: 'error',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });
                }

                setIsSave(false)
            });
    }

    useEffect(() => {
        getActiveLanguages();
        getSelectedLanguageByTenantId();
    }, []);

    return (
        <div>
            <Header as={"h3"} content={translate("Dil Ayarları")} />

            <Header as={"h4"} content={translate("Varsayılan Dili Seçiniz")} />

            <div className="settingLanguageContent">
                <div>
                    {!isLoading
                        ?
                        <Dropdown
                            className="greyInput"
                            placeholder={translate('Dil Seçiniz')}
                            clearable
                            search
                            selection
                            value={selectedDefaultLanguage}
                            
                            options={languageListDropdown}
                            onChange={(event, data) => {
                                handleChanges(event, data)
                            }}
                        />
                        :
                        <Loader active inline />
                    }
                </div>
                <div>
                    <Button
                        disabled={selectedDefaultLanguage == ""}
                        loading={isSave}
                        onClick={saveLanguageSettings}
                        className="roundedButton"
                        color="green"
                    >
                        {translate("Kaydet")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LanguageSettings;