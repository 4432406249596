import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dropdown, Grid, Header, Input, Transition } from 'semantic-ui-react';

import { DataHandler } from '../../../helper/DataHelper';
import { checkFieldsIsFilled, isNullOrEmpty } from '../../../helper/HelperFunctions';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import TranslateHelper from '../../../helper/TranslateHelper';

const AddApplicationHelperType = () => {

    var { translate } = TranslateHelper();
    const navigate = useNavigate();
    let _ = require('lodash');
    let { id } = useParams();
    const [isFormOk, setIsFormOk] = useState(false);
    const [editorContent, setEditorContent] = useState("");
    const [helperType, setHelperType] = useState<any>({
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });

    const add = () => {
        NetworkRequest('/api/ApplicationHelperTypes/AddHelperType', helperType).then((response) => {
            if (response.data.resultCode == 0) {
                toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setTimeout(() => {
                    navigate("/applicationHelperTypes");
                }, 1000);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    const update = () => {
        NetworkRequest('/api/ApplicationHelperTypes/UpdateHelperType', helperType).then((response) => {
            if (response.data.resultCode == 0) {
                toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 5000 });
                setTimeout(() => {
                    navigate("/applicationHelperTypes");
                }, 1000);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    const getHelperType = () => {
        NetworkRequest('/api/ApplicationHelperTypes/GetHelperType', { id: id }).then((response) => {
            if (response.data.resultCode == 0) {
                setHelperType(response.data.detail);
            } else {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            }
        });
    }

    useEffect(() => {
        setIsFormOk(
            checkFieldsIsFilled(
                helperType.typeName
            )
        );
    }, [helperType]);

    useEffect(() => {
        if (id != undefined) {
            getHelperType();
        }
    }, []);

    return (
        <div className="generalContainerForWhiteBg">
            <div className="addAppHelperCont">
                <Transition visible={true} animation='scale' duration={500}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h3'>{translate("Tip Adı: (*)")}</Header>
                                    <Input
                                        value={helperType?.typeName}
                                        name="title"
                                        required="required"
                                        className="greyInput width40"
                                        placeholder={translate("Tip Adı")}
                                        onChange={(e) => { setHelperType({ ...helperType, typeName: e.target.value }); }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    {!isNullOrEmpty(helperType.id)
                                        &&
                                        <Button
                                            color="blue"
                                            className="roundedButton"
                                            onClick={update}
                                            disabled={!isFormOk}
                                        >
                                            {translate("Güncelle")}
                                        </Button>
                                    }
                                    {isNullOrEmpty(helperType.id)
                                        &&
                                        <Button
                                            className="roundedButton"
                                            color='green'
                                            onClick={add}
                                            disabled={!isFormOk}
                                        >
                                            {translate("Ekle")}
                                        </Button>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default AddApplicationHelperType;