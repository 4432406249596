import React, {useEffect, useState} from 'react';
import FixedBottomMenuElement from "./FixedBottomMenuElement";
import {IFixedBottomMenuElememt, IFixedBottomMenu} from "../../../../interfaces/interfaces";
import { v4 as uuidv4 } from 'uuid';

import {NetworkRequest} from "../../../../helper/NetworkRequest";
import TranslateHelper from "../../../../helper/TranslateHelper";
import { DataHandler } from '../../../../helper/DataHelper';
import {useLocation} from "react-router-dom";

const FixedBottomMenu = (props:IFixedBottomMenu) => {
    const location = useLocation();
    var {translate} = TranslateHelper();
    const [selectedElement, setSelectedElement] = useState("user");
    const [barBgColor, setBarBgColor] = useState(null);
    
    const [menu,setMenu] = useState<Array<IFixedBottomMenuElememt>>([
        {Icon: "user", Location:location.pathname, Text: translate("Profil"), Link: "/profile", Color: "white", IsSelected: false, SetSelectedElement:setSelectedElement, SetShowFabIcon:props.SetShowFabIcon, ShowFabIcon:props.ShowFabIcon,IsProfileElement:true,SetIsOpenTaskContentForMobile:props.SetIsOpenTaskContentForMobile,IsOpenTaskContentForMobile:props.IsOpenTaskContentForMobile},
        {Icon: "tasks", Location:location.pathname, Text: translate("Görevler"), Link: props.SetIsOpenTaskContentForMobile, Color: "white", IsSelected: false, SetSelectedElement:setSelectedElement, SetShowFabIcon:props.SetShowFabIcon, ShowFabIcon:props.ShowFabIcon,IsProfileElement:false,SetIsOpenTaskContentForMobile:props.SetIsOpenTaskContentForMobile,IsOpenTaskContentForMobile:props.IsOpenTaskContentForMobile},
        {Icon: "paint brush", Location:location.pathname, Text: translate("Araçlar"), Link: props.SetShowFabIcon, Color: "white", IsSelected: false, SetSelectedElement:setSelectedElement, SetShowFabIcon:props.SetShowFabIcon, ShowFabIcon:props.ShowFabIcon,IsProfileElement:false,SetIsOpenTaskContentForMobile:props.SetIsOpenTaskContentForMobile,IsOpenTaskContentForMobile:props.IsOpenTaskContentForMobile},
        {Icon: "sign-out", Location:location.pathname, Text: translate("Çıkış"), Link: "/", Color: "white", IsSelected: false, SetSelectedElement:setSelectedElement, SetShowFabIcon:props.SetShowFabIcon, ShowFabIcon:props.ShowFabIcon,IsProfileElement:false,SetIsOpenTaskContentForMobile:props.SetIsOpenTaskContentForMobile,IsOpenTaskContentForMobile:props.IsOpenTaskContentForMobile},
    ])

    const getTheme = () => {
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId").toString()
        };

        NetworkRequest("/api/SiteSetting/GetTheme", data).then((response) => {
            if (response.data.resultCode === 0) {
                if(response.data.detail != null) {
                    let themeJSON = JSON.parse(response.data.detail);
                    setBarBgColor(themeJSON.MenuItemBgColorHover);
                }
            }
        });
    }
    
    useEffect(()=> {
        getTheme();
    },[]);

    useEffect(() => {
        //console.log(location.pathname);
        
        if(location.pathname == "/userdashboard") {
           let newMenu = menu.filter(x=>x.Icon != "tasks" && x.Icon != "paint brush");
           setMenu(newMenu);
        }
    }, [location]);
    
    return (
        <div
            style={{
                backgroundColor: barBgColor == null ? "#cccccc" : barBgColor,
                justifyContent: location.pathname == "/userdashboard" ? "space-between": "space-around"
            }}
            className={"fixedBottomMenuMain"}>
            {menu.map((element) => (
                <FixedBottomMenuElement
                    key={uuidv4()}
                    Location={location.pathname}
                    IsSelected={element.Icon == selectedElement}
                    Icon={element.Icon}
                    Text={element.Text}
                    Link={element.Link}
                    Color={element.Color}
                    SetSelectedElement = {setSelectedElement}
                    SetShowFabIcon = {props.SetShowFabIcon}
                    ShowFabIcon = {props.ShowFabIcon}
                    IsProfileElement={element.IsProfileElement}
                    SetIsOpenTaskContentForMobile = {props.SetIsOpenTaskContentForMobile}
                    IsOpenTaskContentForMobile = {props.IsOpenTaskContentForMobile}
                />
            ))}
        </div>
    );
};

export default React.memo(FixedBottomMenu);