import React, {useEffect, memo, useState} from 'react';
import {Icon, Label} from "semantic-ui-react";
import TranslateHelper from "../../../helper/TranslateHelper";

const HomePageFilterStatusLabel = ({status, setterStatus, title, icon, currentSelectedLabel, isLoader,setFilterLock}) => {
    var {translate} = TranslateHelper();

    return (
        <Label
            style={{
                pointerEvents: isLoader ? "none" as "none" : "auto" as "auto",
                opacity: isLoader ? 0.3 : 1
            }}
            className={"rendererFilterLabelClass"}
            onClick={() => {
                // setFilterLock(true);
                setterStatus(status)
            }}
            color={
                ((status == currentSelectedLabel) && currentSelectedLabel == "newModules") ? "red"
                    : ((status == currentSelectedLabel) && currentSelectedLabel == "continueModules") ? "orange"
                        : ((status == currentSelectedLabel) && currentSelectedLabel == "doneModules") ? "green" : "grey"
            }
            circular={true}
        >
            <Icon name={icon}/>
            {translate(title)}
        </Label>
    );
};

export default memo(HomePageFilterStatusLabel);