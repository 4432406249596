import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Icon, Input, Transition} from 'semantic-ui-react';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import {isMobile} from "react-device-detect";
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const LibrarySearch = (props) => {
    var {translate} = TranslateHelper();
    const [loader, setLoader] = useState(true);
    const [dicId, setDicId] = useState(undefined);

    const [isExitsDic, setIsExitsDic] = useState<boolean>(false);
    const [wordText, setWordText] = useState<string>("");
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        header: {
            backgroundColor: "#012060",
            color: "#fff",
            textAlign: "center" as "center",
            width: "100%",
            padding: "1rem 0",
            fontSize: "1.5rem",
            fontWeight: 700
        },
        main: {
            height: "230px",
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem"

        },
        input: {
            borderColor: "#012060",
            borderRadius: "50px",
            fontSize: "2rem",
            minHeight: "40px"
        },
        btn: {
            backgroundColor: "#02aff1",
            borderRadius: "50px",
            color: "white",
            fontSize: "1.5rem",
            padding: "1rem 2.5rem"
        },
        footer: {
            width: "100%",
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            color: "#101010",
            fontSize: "1.5rem",
            marginBottom: "1rem",
            cursor: "pointer"
        }
    }

    const checkIsExitsDic = () => {
        let dicData = {
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        NetworkRequest("/api/Dictionary/GetByTenantId", dicData).then((response) => {
            ////
            setLoader(false);
            if (response.data.resultCode === 0 && response.data.detail != null) {
                let dic = response.data.detail;
                setDicId(dic.buddyDictionaryId);
                setIsExitsDic(true);
            }
        });
    }

    const onKeyUpLibrary = (e) => {
        if (e.keyCode === 13) {
            props.setShowDictionaryViewer(true);
            props.setShowFabIcon(false);
        }
    }

    useEffect(() => {
        checkIsExitsDic();
    }, []);

    useEffect(() => {
        if (dicId != undefined)
            props.setDicId(dicId);
    }, [dicId]);
    
    useEffect(() => {
        if (wordText != "" && !isMobile)
            props.setDicSearchWord(wordText);
    }, [wordText]);

    return (
        <>
            <Transition visible={isExitsDic} animation='scale' duration={500}>
                <div className={"dictionarySearchBox"}>
                    <div className={"dictionaryContainer"} style={styles.container}>
                        <div className={"dictionaryTitleContainer"} style={styles.header}>
                            {translate("Sözlük")}
                        </div>
                        <div className={"dictionaryBodyContainer"} style={styles.main}>

                            <Input
                                className={"dictionarySearchInputContainer"}
                                size="big"
                                disabled={props.showDictionaryViewer}
                                onKeyUp={onKeyUpLibrary}
                                value={wordText}
                                onChange={(e) => {
                                    setWordText(e.target.value)
                                }}
                                icon={<Icon name='search' inverted circular link onClick={() => {
                                    props.setShowDictionaryViewer(true)
                                }}/>}
                                placeholder={translate("Sözlük...")}
                            />

                        </div>
                        <div style={styles.footer}>
                            <Button
                                size="big"
                                className={"dictionaryListAllKeywordsButton"}
                                onClick={() => {
                                    props.setShowDictionaryViewer(!props.showDictionaryViewer)
                                    props.setShowFabIcon(!props.showFabIcon);
                                    props.setShowHelperViewer(false);
                                }}
                                style={{
                                    backgroundColor:"rgb(82,81,153)",
                                    color: "white",
                                    textDecoration: "none"
                                }}
                            >
                                {props.showDictionaryViewer ? translate("Sözlüğü Kapat") : translate("Tüm Kelimeleri Listele")
                                }</Button>
                        </div>
                        {/*<DictionarySeachWord dicId={dicId} Word={wordText} />*/}
                        {/*<Modal*/}
                        {/*    centered={false}*/}
                        {/*    open={props.showDictionaryViewer }*/}
                        {/*>*/}
                        {/*    <Modal.Header>Sözlük</Modal.Header>*/}
                        {/*    <Modal.Content>*/}
                        {/*        <DictionarySeachWord dicId={dicId} Word={wordText} />*/}
                        {/*    </Modal.Content>*/}
                        {/*    <Modal.Actions>*/}
                        {/*        <Button onClick={() => props.setShowDictionaryViewer(false)}>Kapat</Button>*/}
                        {/*    </Modal.Actions>*/}
                        {/*</Modal>*/}
                    </div>
                </div>
            </Transition></>
    );
}

export default LibrarySearch;