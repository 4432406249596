import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { Modal, Loader, Transition, Header, Button, Grid, Icon, Tab } from 'semantic-ui-react';
import DataTable from '../../../components/DataTable';
import { IDataTable } from '../../../interfaces/interfaces'
import { digitallicaConfirm, prepareDataForTable } from '../../../helper/HelperFunctions';

import { toast } from 'react-toastify';
import moment from 'moment';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';

const ScheduledJobList = (props) => {

    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const [notiListCurrentPage, setNotiListCurrentPage] = useState(1);
    const [historyCurrentPage, setHistoryCurrentPage] = useState(1);
    const [pendingCurrentPage, setPendingCurrentPage] = useState(1);
    const [canceledCurrentPage, setCanceledCurrentPage] = useState(1);
    const [errorCurrentPage, setErrorCurrentPage] = useState(1);
    const [notiListFilterText, setNotiListFilterText] = useState("");
    const [historyFilterText, setHistoryFilterText] = useState("");
    const [pendingFilterText, setPendingFilterText] = useState("");
    const [canceledFilterText, setCanceledFilterText] = useState("");
    const [errorFilterText, setErrorFilterText] = useState("");
    const [containerRunDate, setContainerRunDate] = useState("");

    //TODO: translate yapılacak dakika ve saniye değişkenliği
    const [remainingMinutes, setRemainingMinutes] = useState((60 - moment().minutes()) + " " + translate("dakika"));

    const [selectedScheduledJobId, setSelectedScheduledJobId] = useState<any>("");
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isAllSelectedHistory, setIsAllSelectedHistory] = useState(false);
    const [isAllSelectedPending, setIsAllSelectedPending] = useState(false);
    const [notiListStartingDate, setNotiListStartingDate] = useState();
    const [notiListDueDate, setNotiListDueDate] = useState();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalShown, setIsModalShown] = useState<boolean>(false);
    const [isPreviewModalShown, setIsPreviewModalShown] = useState<boolean>(false);
    const [previewModel, setPreviewModel] = useState<any>({ subject: "", body: "" });
    const [toReceivers, setToReceivers] = useState<any>([]);
    const [ccReceivers, setCcReceivers] = useState<any>([]);
    const [baseHistoryList, setBaseHistoryList] = useState<any>([]);
    const [basePendingList, setBasePendingList] = useState<any>([]);
    const [basePendingResponse, setBasePendingResponse] = useState<any>();
    const [isLoadingPurge, setIsLoadingPurge] = useState<boolean>(false);
    const [isSendAllLoader, setIsSendAllLoader] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [moduleRememberenceTime, setModuleRememberenceTime] = useState(0);


    const getRememberTime = () => {
        NetworkRequest("api/ScheduledJobs/GetRememberTime").then((response) => {
            setModuleRememberenceTime(response.data.detail)
        });
    }

    const turnOnOrOff = (id) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            NetworkRequest("api/ScheduledJobs/TurnOnOrOff", { id: id }).then((response) => {
                let filterData = {
                    filterText: notiListFilterText,
                    currentPage: notiListCurrentPage,
                    isActive: isActive,
                    isDeleted: isDeleted,
                    notiListStartingDate: notiListStartingDate,
                    notiListDueDate: notiListDueDate,
                    tenantId: DataHandler.getUserDataWithKey("tenantId")
                }
                getNotiList(filterData);
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const deleteCaller = (id) => {
        let req = {
            id: id
        };
        NetworkRequest("api/ScheduledJobs/Delete", req).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2500 });
                let filterData = {
                    filterText: notiListFilterText,
                    currentPage: notiListCurrentPage,
                    isActive: isActive,
                    isDeleted: isDeleted,
                    startingDate: notiListStartingDate,
                    dueDate: notiListDueDate,
                    tenantId: DataHandler.getUserDataWithKey("tenantId")
                }
                getNotiList(filterData);
            }
        });
    }

    //delete selected data
    const deleteScheduledJob = (id) => {
        digitallicaConfirm(
            translate("İşleme devam etmek istediğinize emin misiniz?"),
            () => {
                //console.log("deleter");
                deleteCaller(id);
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            if (model.data[i].secretData != undefined && model.data[i].secretData.length > 0) {
                model.data[i].secretData[0].isSelected = isAllSelected;
            }
        }

        window.setTimeout(() => {
            setNotiListTableModel({
                header: notiListTableModel.header,
                data: [],
                transactions: notiListTableModel.transactions,
                filter: notiListTableModel.filter,
                pagination: notiListTableModel.pagination,
                addRowLink: notiListTableModel.addRowLink
            });
            setNotiListTableModel(model);
        }, 100);
    }

    const selectAllHistoryHandler = (model) => {
        //for (var i = 0; i < model.data.length; i++) {
        //    if (model.data[i].secretData != undefined && model.data[i].secretData.length > 0) {
        //        model.data[i].secretData[0].isSelected = isAllSelectedHistory;
        //    }
        //}

        //window.setTimeout(() => {
        //    setHistoryTableModel({
        //        header: historyTableModel.header,
        //        data: [],
        //        transactions: historyTableModel.transactions,
        //        filter: historyTableModel.filter,
        //        pagination: historyTableModel.pagination,
        //        addRowLink: historyTableModel.addRowLink
        //    });
        //    setHistoryTableModel(model);
        //}, 100);
    }

    const selectAllPendingHandler = (model) => {
        //for (var i = 0; i < model.data.length; i++) {
        //    if (model.data[i].secretData != undefined && model.data[i].secretData.length > 0) {
        //        model.data[i].secretData[0].isSelected = isAllSelectedPending;
        //    }
        //}

        //window.setTimeout(() => {
        //    setPendingTableModel({
        //        header: pendingTableModel.header,
        //        data: [],
        //        transactions: pendingTableModel.transactions,
        //        filter: pendingTableModel.filter,
        //        pagination: pendingTableModel.pagination,
        //        addRowLink: pendingTableModel.addRowLink
        //    });
        //    setPendingTableModel(model);
        //}, 100);
    }


    const setNotiListRowSelected = (index, data, notiListTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        notiListTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setNotiListTableModel({
                header: notiListTableModel.header,
                data: [],
                transactions: notiListTableModel.transactions,
                filter: notiListTableModel.filter,
                pagination: notiListTableModel.pagination,
                addRowLink: notiListTableModel.addRowLink
            });
            setNotiListTableModel(notiListTableModel);
        }, 100);
    }

    const setHistoryRowSelected = (index, data, historyTableModel) => {
        //data.value = data.value == undefined ? true : !data.value;
        //historyTableModel.data[index].secretData[0].isSelected = data.checked;
        //window.setTimeout(() => {
        //    setHistoryTableModel({
        //        header: historyTableModel.header,
        //        data: [],
        //        transactions: historyTableModel.transactions,
        //        filter: historyTableModel.filter,
        //        pagination: historyTableModel.pagination,
        //        addRowLink: historyTableModel.addRowLink
        //    });
        //}, 100);
    }

    const setCanceledRowSelected = (index, data, cancelTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        cancelTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setCancelTableModel({
                header: cancelTableModel.header,
                data: [],
                transactions: cancelTableModel.transactions,
                filter: cancelTableModel.filter,
                pagination: cancelTableModel.pagination,
                addRowLink: cancelTableModel.addRowLink
            });
        }, 100);
    }

    const setPendingRowSelected = (index, data, historyTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        pendingTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setPendingTableModel({
                header: pendingTableModel.header,
                data: [],
                transactions: pendingTableModel.transactions,
                filter: pendingTableModel.filter,
                pagination: pendingTableModel.pagination,
                addRowLink: pendingTableModel.addRowLink
            });
        }, 100);
    }

    const notiListOnChangeDate1 = (date) => {
        setNotiListStartingDate(date);
    }

    const notiListOnChangeDate2 = (date) => {
        setNotiListDueDate(date);
    }

    const notiListSetIsActive = () => {
        setIsActive(!isActive);
    }

    const [notiListTableModel, setNotiListTableModel] = useState<IDataTable>({
        header: [
            translate("Başlık"),
            translate("Modül Adı"),
            translate("Açıklama"),
            translate("Aksiyon"),
            translate("Alıcılar"),
            translate("Aktif Mi?"),
            translate("İşlemler")
        ],
        data: [{
            showData: [],
            secretData: [
                { id: "" },
                { tenantId: "" },
                { jobId: "" },
                { frequencyId: "" },
                { moduleId: "" },
                { isDeleted: false },
                { isRefreshRequired: false },
                { frequency: {} },
                { job: {} }
            ]
        }],
        transactions: [{
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/editScheduledJob",
            type: "edit"
        },
        {
            icon: "power",
            desc: translate("Aç/Kapa"),
            color: undefined,
            function: turnOnOrOff,
            type: "onoff"
        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: deleteScheduledJob
        }],
        filter: {
            setSearch: setNotiListFilterText,
            search: notiListFilterText,
            filterOption: [{
                desc: translate("On/Off"),
                defaultValue: isActive,
                key: "isActive",
                type: "boolean",
                onChange: notiListSetIsActive
            }, {
                desc: translate("Tarih"),
                defaultValue: [notiListStartingDate, notiListDueDate],
                key: "date",
                type: "date",
                onChange: [notiListOnChangeDate1, notiListOnChangeDate2]
            }]
        },
        pagination: {
            count: 1,
            setCurrentPage: setNotiListCurrentPage, currentPage: notiListCurrentPage
        },
        setRowSelected: setNotiListRowSelected,
        addRowLink: "/addScheduledJob",
        noCheckBox: true,
        addButons: []
    });

    //prepare data for table
    const notiListPrepareData = (data, count) => {
        if (data.length == 0 && notiListCurrentPage > 1) {
            setNotiListCurrentPage(1);
            return;
        }
        //hidden column
        let model = prepareDataForTable(data, [
            "id",
            "tenantId",
            "frequencyId",
            "moduleId",
            "isDeleted",
            "isRefreshRequired",
            "isInformEnabled",
            "frequency",
            "notification",
            "createDateDateTime",
            "updateDateTime",
            "deleteDateTime",
            "runTime",
            "repeatCount",
            "runCount",
            "isInformEnabled",
        ]);

        ////debugger;
        setTimeout(() => {
            setNotiListTableModel({
                ...notiListTableModel,
                data: model,
                filter: {
                    setSearch: setNotiListFilterText,
                    search: notiListFilterText,
                    filterOption: [{
                        desc: translate("On/Off"),
                        defaultValue: isActive,
                        key: "isActive",
                        type: "boolean",
                        onChange: notiListSetIsActive
                    }, {
                        desc: translate("Tarih"),
                        defaultValue: [notiListStartingDate, notiListDueDate],
                        key: "date",
                        type: "date",
                        onChange: [notiListOnChangeDate1, notiListOnChangeDate2]
                    }]
                },

                pagination: {
                    count: Math.ceil(count / 25),
                    setCurrentPage: setNotiListCurrentPage, currentPage: notiListCurrentPage
                },
                addRowLink: notiListTableModel.addRowLink
            });

        }, 100)

        setIsLoading(false);
    }

    //get table data from database
    const getNotiList = (filterData?) => {
        setIsLoading(true);
        ////debugger;
        NetworkRequest("api/ScheduledJobs/GetList", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                /*   console.log("getNotiList: ", result);*/
                notiListPrepareData(result.scheduledJobs, result.count);
                setIsLoading(false);
            } else {
                toast(translate("Bildirimler, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const previewHistoryNoti = (id) => {

        NetworkRequest("api/ScheduledJobs/GetMailTemplateById", { id: id, type: "SUCCESS" }).then((response) => {
            if (response.data.resultCode === 0) {
                var responseData = response.data.detail;
                setPreviewModel({
                    subject: responseData.subject,
                    body: responseData.body,
                    mailTo: responseData.mailTo,
                    ccList: responseData.ccList,
                });
                setIsPreviewModalShown(true);
            } else {
                toast(translate("Önizleme, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const previewErrorNoti = (id) => {

        NetworkRequest("api/ScheduledJobs/GetMailTemplateById", { id: id, type: "ERROR" }).then((response) => {
            if (response.data.resultCode === 0) {
                var responseData = response.data.detail;
                setPreviewModel({
                    subject: responseData.subject,
                    body: responseData.body,
                    mailTo: responseData.mailTo,
                    ccList: responseData.ccList,
                });
                setIsPreviewModalShown(true);
            } else {
                toast(translate("Önizleme, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const previewCancelNoti = (id) => {

        NetworkRequest("api/ScheduledJobs/GetMailTemplateById", { id: id, type: "REPO" }).then((response) => {
            if (response.data.resultCode === 0) {
                var responseData = response.data.detail;
                setPreviewModel({
                    subject: responseData.subject,
                    body: responseData.body,
                    mailTo: responseData.mailTo,
                    ccList: responseData.ccList,
                });
                setIsPreviewModalShown(true);
            } else {
                toast(translate("Önizleme, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const previewErrorDetail = (id) => {

        NetworkRequest("api/ScheduledJobs/GetErrorDetailById", { id: id }).then((response) => {
            if (response.data.resultCode === 0) {
                var responseData = response.data.detail;
                setPreviewModel({
                    subject: translate("Hata Detayı"),
                    body: responseData.errorDetail,
                    mailTo: null,
                    ccList: null,
                });
                setIsPreviewModalShown(true);
            } else {
                toast(translate("Önizleme, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const previewPendingNoti = (id) => {


        let pending = _.find(basePendingResponse.list, (doc) => {
            return doc.id == id;
        });
        if (pending == undefined) {
            toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            return;
        }

        if (pending.id == null || pending.id == undefined || pending.id == "") {
            toast(translate("Önizleme, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            return;
        }

        NetworkRequest("api/ScheduledJobs/GetMailTemplateById", { id: pending.id, type: "QUEUE" }).then((response) => {
            if (response.data.resultCode === 0) {
                var responseData = response.data.detail;
                setPreviewModel({
                    subject: responseData.subject,
                    body: responseData.body,
                    mailTo: responseData.mailTo,
                    ccList: responseData.ccList,
                });
                setIsPreviewModalShown(true);
            } else {
                toast(translate("Önizleme, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const resendNoti = (logId) => {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            toast(translate("Gönderiliyor."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 1000 });
            NetworkRequest("api/Notifications/ResendByLogiId", { id: logId }).then((response) => {
                if (response.data.resultCode === 0) {
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 1500 });
                    let filterData = {
                        filterText: historyFilterText,
                        currentPage: historyCurrentPage,
                        tenantId: DataHandler.getUserDataWithKey("tenantId")
                    }
                    getHistory(filterData);
                } else {
                    toast(translate("Mail Tekrar Gönder,Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }


    const [errorTableModel, setErrorTableModel] = useState<IDataTable>({
        header: [
            translate("Aksiyon"),
            translate("Gönderen Mail (From)"),
            translate("Alıcı (TO)"),
            translate("CC"),
            translate("Konu"),
            translate("Modül Adı"),
            translate("Oluşturulma Tarihi"),
            translate("Son Deneme Tarihi"),
            translate("Deneme Sayısı"),
            translate("İşlemler"),
        ],
        data: [{
            showData: [],
            secretData: []
        }],
        transactions: [
            {
                icon: "eye",
                desc: translate("Önizle"),
                color: "blue",
                link: null,
                function: previewErrorNoti
            },

            {
                icon: "sticky note outline",
                desc: translate("Hata Detayı"),
                color: "red",
                link: null,
                function: previewErrorDetail
            }
        ],
        filter: {
            setSearch: setErrorFilterText,
            search: errorFilterText,
            filterOption: []
        },
        pagination: {
            count: 1,
            setCurrentPage: setErrorCurrentPage, currentPage: errorCurrentPage
        },
        setRowSelected: setHistoryRowSelected,
        noCheckBox: true,
        isAttachingPage: false,
        addButons: [],
        addRowLink: ""
    });



    const [cancelTableModel, setCancelTableModel] = useState<IDataTable>({
        header: [
            translate("Aksiyon"),
            translate("Gönderen Mail (From)"),
            translate("Alıcı (TO)"),
            translate("CC"),
            translate("Konu"),
            translate("Modül Adı"),
            translate("Oluşturulma Tarihi"),
            translate("İptal Tarihi"),
            translate("Deneme Sayısı"),
            translate("İşlemler"),
        ],
        data: [{
            showData: [],
            secretData: []
        }],
        transactions: [
            {
                icon: "eye",
                desc: translate("Önizle"),
                color: "blue",
                link: null,
                function: previewCancelNoti
            },
            //TODO:iptal edilenler, tekrardan kuyruğa ekleme buttonu yapılacak.
            //{
            //    icon: "send",
            //    desc: translate("Tekrar Gönder"),
            //    color: "green",
            //    link: null,
            //    function: resendNoti
            //}
        ],
        filter: {
            setSearch: setHistoryFilterText,
            search: historyFilterText,
            filterOption: []
        },
        pagination: {
            count: 1,
            setCurrentPage: setHistoryCurrentPage, currentPage: historyCurrentPage
        },
        setRowSelected: setHistoryRowSelected,
        noCheckBox: true,
        isAttachingPage: false,
        addButons: [],
        addRowLink: ""
    });



    const [historyTableModel, setHistoryTableModel] = useState<IDataTable>({
        header: [
            translate("Aksiyon"),
            translate("Gönderen Mail (From)"),
            translate("Alıcı (TO)"),
            translate("CC"),
            translate("Konu"),
            translate("Modül Adı"),
            translate("Oluşturulma Tarihi"),
            translate("Deneme Sayısı"),
            translate("İşlemler"),
        ],
        data: [{
            showData: [],
            secretData: []
        }],
        transactions: [
            {
                icon: "eye",
                desc: translate("Önizle"),
                color: "blue",
                link: null,
                function: previewHistoryNoti
            },
            //{
            //    icon: "send",
            //    desc: translate("Tekrar Gönder"),
            //    color: "green",
            //    link: null,
            //    function: resendNoti
            //}
        ],
        filter: {
            setSearch: setHistoryFilterText,
            search: historyFilterText,
            filterOption: []
        },
        pagination: {
            count: 1,
            setCurrentPage: setHistoryCurrentPage, currentPage: historyCurrentPage
        },
        setRowSelected: setHistoryRowSelected,
        noCheckBox: true,
        isAttachingPage: false,
        addButons: [],
        addRowLink: ""
    });

    const historyPrepareData = (data, count) => {

        if (data != undefined && data.length == 0 && historyCurrentPage > 1) {
            setHistoryCurrentPage(1);
            return;
        }
        //hidden column
        let model = prepareDataForTable(data, [
            "id",
            "notificationTypeId"
        ]);

        setHistoryTableModel({
            ...historyTableModel,
            data: model,
            filter: {
                setSearch: setHistoryFilterText,
                search: historyFilterText,
                filterOption: [{
                    desc: translate("Tarih"),
                    defaultValue: [notiListStartingDate, notiListDueDate],
                    key: "date",
                    type: "date",
                    onChange: [notiListOnChangeDate1, notiListOnChangeDate2]
                }]
            },
            pagination: {
                count: Math.ceil(count / 25),
                setCurrentPage: setHistoryCurrentPage, currentPage: historyCurrentPage
            }
        });

        setIsLoading(false);
    }

    const canceledPrepareData = (data, count) => {
        if (data != undefined && data.length == 0 && canceledCurrentPage > 1) {
            setCanceledCurrentPage(1);
            return;
        }
        //hidden column
        let model = prepareDataForTable(data, [
            "id",
            "notificationTypeId"
        ]);

        setCancelTableModel({
            ...cancelTableModel,
            data: model,
            filter: {
                setSearch: setCanceledFilterText,
                search: canceledFilterText,
                filterOption: [{
                    desc: translate("Tarih"),
                    defaultValue: [notiListStartingDate, notiListDueDate],
                    key: "date",
                    type: "date",
                    onChange: [notiListOnChangeDate1, notiListOnChangeDate2]
                }]
            },
            pagination: {
                count: Math.ceil(count / 25),
                setCurrentPage: setCanceledCurrentPage, currentPage: canceledCurrentPage
            }
        });

        setIsLoading(false);
    }

    const errorPrepareData = (data, count) => {
        if (data != undefined && data.length == 0 && errorCurrentPage > 1) {
            setErrorCurrentPage(1);
            return;
        }
        //hidden column
        let model = prepareDataForTable(data, [
            "id",
            "notificationTypeId"
        ]);

        setErrorTableModel({
            ...errorTableModel,
            data: model,
            filter: {
                setSearch: setErrorFilterText,
                search: errorFilterText,
                filterOption: [{
                    desc: translate("Tarih"),
                    defaultValue: [notiListStartingDate, notiListDueDate],
                    key: "date",
                    type: "date",
                    onChange: [notiListOnChangeDate1, notiListOnChangeDate2]
                }]
            },
            pagination: {
                count: Math.ceil(count / 25),
                setCurrentPage: setErrorCurrentPage, currentPage: errorCurrentPage
            }
        });

        setIsLoading(false);
    }

    //get table data from database
    const getHistory = (filterData?) => {
        setHistoryTableModel({
            header: [
                translate("Aksiyon"),
                translate("Gönderen Mail (From)"),
                translate("Alıcı (TO)"),
                translate("CC"),
                translate("Konu"),
                translate("Modül Adı"),
                translate("Oluşturulma Tarihi"),
                translate("Deneme Sayısı"),
                translate("İşlemler"),
            ],
            data: [{
                showData: [],
                secretData: []
            }],
            transactions: [
                {
                    icon: "eye",
                    desc: translate("Önizle"),
                    color: "blue",
                    link: null,
                    function: previewHistoryNoti
                },
                {
                    icon: "send",
                    desc: translate("Tekrar Gönder"),
                    color: "green",
                    link: null,
                    function: resendNoti
                }],
            filter: {
                setSearch: setHistoryFilterText,
                search: historyFilterText,
                filterOption: []
            },
            pagination: {
                count: 1,
                setCurrentPage: setHistoryCurrentPage, currentPage: historyCurrentPage
            },
            setRowSelected: setHistoryRowSelected,
            noCheckBox: true,
            isAttachingPage: false,
            addButons: [],
            addRowLink: ""
        });
        NetworkRequest("api/ScheduledJobs/GetHistory", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;

                setBaseHistoryList(result.list);
                historyPrepareData(result.list, result.count);
            } else {
                toast(translate("Geçmiş, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }



    //get table data from database
    const getCanceledQueue = (filterData?) => {
        setCancelTableModel({
            header: [
                translate("Aksiyon"),
                translate("Gönderen Mail (From)"),
                translate("Alıcı (TO)"),
                translate("CC"),
                translate("Konu"),
                translate("Modül Adı"),
                translate("Oluşturulma Tarihi"),
                translate("İptal Tarihi"),
                translate("Deneme Sayısı"),
                translate("İşlemler"),
            ],
            data: [{
                showData: [],
                secretData: []
            }],
            transactions: [
                {
                    icon: "eye",
                    desc: translate("Önizle"),
                    color: "blue",
                    link: null,
                    function: previewHistoryNoti
                },
                //TODO:iptal edilenler, tekrardan kuyruğa ekleme buttonu yapılacak.
                //{
                //    icon: "send",
                //    desc: translate("Tekrar Gönder"),
                //    color: "green",
                //    link: null,
                //    function: resendNoti
                //}
            ],
            filter: {
                setSearch: setCanceledFilterText,
                search: canceledFilterText,
                filterOption: []
            },
            pagination: {
                count: 1,
                setCurrentPage: setCanceledCurrentPage, currentPage: canceledCurrentPage
            },
            setRowSelected: setCanceledRowSelected,
            noCheckBox: true,
            isAttachingPage: false,
            addButons: [],
            addRowLink: ""
        });
        NetworkRequest("api/ScheduledJobs/GetCanceledQueue", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                //setBaseHistoryList(result.list);
                canceledPrepareData(result.list, result.count);
            } else {
                toast(translate("Geçmiş, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const getErrorQueue = (filterData?) => {
        setErrorTableModel({
            header: [
                translate("Aksiyon"),
                translate("Gönderen Mail (From)"),
                translate("Alıcı (TO)"),
                translate("CC"),
                translate("Konu"),
                translate("Modül Adı"),
                translate("Oluşturulma Tarihi"),
                translate("Son Deneme Tarihi"),
                translate("Deneme Sayısı"),
                translate("İşlemler"),
            ],
            data: [{
                showData: [],
                secretData: []
            }],
            transactions: [
                {
                    icon: "eye",
                    desc: translate("Önizle"),
                    color: "blue",
                    link: null,
                    function: previewErrorNoti
                },

                {
                    icon: "sticky note outline",
                    desc: translate("Hata Detayı"),
                    color: "green",
                    link: null,
                    function: resendNoti
                }
            ],
            filter: {
                setSearch: setErrorFilterText,
                search: errorFilterText,
                filterOption: []
            },
            pagination: {
                count: 1,
                setCurrentPage: setErrorCurrentPage, currentPage: errorCurrentPage
            },
            setRowSelected: setHistoryRowSelected,
            noCheckBox: true,
            isAttachingPage: false,
            addButons: [],
            addRowLink: ""
        });
        NetworkRequest("api/ScheduledJobs/GetErrorQueue", filterData).then((response) => {
            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                //setBaseHistoryList(result.list);

                errorPrepareData(result.list, result.count);
            } else {
                toast(translate("Geçmiş, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const cancelPendingNoti = (id, list) => {


        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            let pending = _.find(list, (doc) => {
                return doc.id == id;
            });
            if (pending == undefined) {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
                return;
            }


            NetworkRequest("api/ScheduledJobs/AddMailToRepo", {
                id: pending.id
            }).then((response) => {
                if (response.data.resultCode === 0) {


                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 1500 });

                    //let filterData = {
                    //    filterText: pendingFilterText,
                    //    currentPage: pendingCurrentPage,
                    //    tenantId: DataHandler.getUserDataWithKey("tenantId")
                    //}
                    //getPending(filterData);

                    refreshAllTabs();
                } else {
                    toast(translate("Çalışan İptal, Hata oluştu lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const sendNotiNow = (id, list) => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            let pending = _.find(list, (doc) => {
                return doc.id == id;
            });
            if (pending == undefined) {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
                return;
            }
            toast(translate("Gönderiliyor. Lütfen Bekleyin."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2500 });
            NetworkRequest("api/ScheduledJobs/SendQueueNow", {
                id: pending.id,
            }).then((response) => {
                toast.dismiss();
                if (response.data.resultCode === 0) {
                    ////console.log("cancelPendingNoti: ", response);
                    toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 1500 });
                    let filterData = {
                        filterText: pendingFilterText,
                        currentPage: pendingCurrentPage,
                        tenantId: DataHandler.getUserDataWithKey("tenantId")
                    }
                    getPending(filterData);
                    let filterData2 = {
                        filterText: historyFilterText,
                        currentPage: historyCurrentPage,
                        tenantId: DataHandler.getUserDataWithKey("tenantId")
                    }
                    getHistory(filterData2);
                } else {
                    toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                    let filterData = {
                        filterText: pendingFilterText,
                        currentPage: pendingCurrentPage,
                        tenantId: DataHandler.getUserDataWithKey("tenantId")
                    }
                    getPending(filterData);
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const refreshAllTabs = () => {
        let filterData = {
            filterText: pendingFilterText,
            currentPage: pendingCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getPending(filterData);

        let filterData2 = {
            filterText: historyFilterText,
            currentPage: historyCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getHistory(filterData2);

        let filterData3 = {
            filterText: errorFilterText,
            currentPage: errorCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getErrorQueue(filterData3);

        let filterData4 = {
            filterText: canceledFilterText,
            currentPage: canceledCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getCanceledQueue(filterData4);

        setIsSendAllLoader(false);
        setIsLoadingPurge(false);
    }

    const sendAllNotiNow = () => {

        digitallicaConfirm("Toplu mail gönderim işlemini başlatmak istediğinize emin misiniz?", () => {
            setIsSendAllLoader(true);
            setIsLoadingPurge(true);
            var data = {
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            NetworkRequest("api/ScheduledJobs/SendAllNotiNow", data).then((response) => {
                if (response.data.resultCode === 0) {
                    ////console.log("cancelPendingNoti: ", response);
                    toast(translate(response.data.detail), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 4500 });
                    refreshAllTabs();
                } else {
                    toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                    refreshAllTabs();
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });
    }

    const [pendingTableModel, setPendingTableModel] = useState<IDataTable>({
        header: [
            translate("Aksiyon"),
            translate("Gönderen Mail (From)"),
            translate("Alıcı (TO)"),
            translate("CC"),
            translate("Konu"),
            translate("Modül Adı"),
            translate("Oluşturulma Tarihi"),
            translate("Deneme Sayısı"),
            translate("İşlemler"),
        ],
        data: [{
            showData: [],
            secretData: []
        }],
        transactions: [
            {
                icon: "mail",
                desc: translate("Şimdi Gönder"),
                color: "green",
                link: null,
                function: sendNotiNow,
                functionRequest: basePendingList
            },
            {
                icon: "eye",
                desc: translate("Önizle"),
                color: "blue",
                link: null,
                function: previewPendingNoti
            },
            {
                icon: "times",
                desc: translate("İptal Et"),
                color: "red",
                link: null,
                function: cancelPendingNoti,
                functionRequest: basePendingList
            }
        ],
        filter: {
            setSearch: setPendingFilterText,
            search: pendingFilterText,
            filterOption: []
        },
        pagination: {
            count: 1,
            setCurrentPage: setPendingCurrentPage, currentPage: pendingCurrentPage
        },
        setRowSelected: setPendingRowSelected,
        noCheckBox: true,
        isAttachingPage: false,
        addButons: [],
        addRowLink: ""
    });

    const pendingPrepareData = (data, count) => {
        if (data != undefined && data.length == 0 && pendingCurrentPage > 1) {
            setPendingCurrentPage(1);
            return;
        }


        //hidden column
        let model = prepareDataForTable(data, [
            "id",
            "notificationTypeId"
        ]);

        setPendingTableModel({
            ...pendingTableModel,
            data: model,
            filter: {
                setSearch: setPendingFilterText,
                search: pendingFilterText,
                filterOption: []
            },
            pagination: {
                count: Math.ceil(count / 25),
                setCurrentPage: setPendingCurrentPage, currentPage: pendingCurrentPage
            },
            transactions: [
                {
                    icon: "mail",
                    desc: translate("Şimdi Gönder"),
                    color: "green",
                    link: null,
                    function: sendNotiNow,
                    functionRequest: basePendingList
                },
                {
                    icon: "eye",
                    desc: translate("Önizle"),
                    color: "blue",
                    link: null,
                    function: previewPendingNoti
                },
                {
                    icon: "times",
                    desc: translate("İptal Et"),
                    color: "red",
                    link: null,
                    function: cancelPendingNoti,
                    functionRequest: basePendingList
                }
            ]
        });
        setIsLoading(false);
    }

    //get table data from database
    const getPending = (filterData?) => {
        setPendingTableModel({
            header: [
                translate("Aksiyon"),
                translate("Gönderen Mail (From)"),
                translate("Alıcı (TO)"),
                translate("CC"),
                translate("Konu"),
                translate("Modül Adı"),
                translate("Oluşturulma Tarihi"),
                translate("Deneme Sayısı"),
                translate("İşlemler"),
            ],
            data: [{
                showData: [],
                secretData: []
            }],

            transactions: [
                {
                    icon: "mail",
                    desc: translate("Şimdi Gönder"),
                    color: "green",
                    link: null,
                    function: sendNotiNow,
                    functionRequest: basePendingList
                },
                {
                    icon: "eye",
                    desc: translate("Önizle"),
                    color: "blue",
                    link: null,
                    function: previewPendingNoti
                },
                {
                    icon: "times",
                    desc: translate("İptal Et"),
                    color: "red",
                    link: null,
                    function: cancelPendingNoti,
                    functionRequest: basePendingList
                }
            ],
            filter: {
                setSearch: setPendingFilterText,
                search: pendingFilterText,
                filterOption: []
            },
            pagination: {
                count: 1,
                setCurrentPage: setPendingCurrentPage, currentPage: pendingCurrentPage
            },
            setRowSelected: setPendingRowSelected,
            noCheckBox: true,
            isAttachingPage: false,
            addButons: [],
            addRowLink: ""
        });
        let start = performance.now();
        NetworkRequest("api/ScheduledJobs/GetPendingList", filterData).then((response) => {

            if (response.data.resultCode === 0) {
                let result = response.data.detail;
                setBasePendingResponse(result);
                setBasePendingList(result.list);
            } else if (response.data.resultCode == 14 && response.data.detail != undefined) {
                toast(translate(response.data.detail), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 5000 });
            } else if (response.data.resultCode != 0 && response.data.detail != undefined) {
                toast(translate("Gönderilecek listesinde hata oluştu, lütfen sistem yöneticinize başvurun."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
            }
        });
    }

    const purgeSchedulerPool = () => {

        digitallicaConfirm(translate("Bu işleme devam ederseniz gönderilecekler listesi yeniden oluşturulacaktır. Devam etmek istediğinize emin misiniz?"), () => {
            setIsSendAllLoader(true);
            setIsLoadingPurge(true);
            toast(translate("İşlem sayısına bağlı olarak gönderilecek listesinin oluşması zaman alabilir."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 3000 });
            NetworkRequest("api/ScheduledJobs/QueueProcess").then((response) => {
                if (response.data.resultCode === 0) {
                    getContainerRunDate();
                    toast(translate("Başarılı. Gönderilecek listesi güncellenmiştir."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 3000 });
                    setIsSendAllLoader(false);
                    setIsLoadingPurge(false);

                    let filterData = {
                        filterText: pendingFilterText,
                        currentPage: pendingCurrentPage,
                        tenantId: DataHandler.getUserDataWithKey("tenantId")
                    }
                    getPending(filterData);


                } else {
                    toast(translate("Hata. Gönderilecek listesi yeniden hesaplanırken bir hata meydana geldi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3000 });
                    setIsSendAllLoader(false);
                    setIsLoadingPurge(false);
                }
            });
        }, () => { toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 }); });

    }

    const getContainerRunDate = () => {
        NetworkRequest("api/ScheduledJobs/GetContainerRunDate").then((response) => {
            if (response.data.resultCode === 0) {
                setContainerRunDate(response.data.detail);
            }
        });
    }

    const panes = [
        {
            menuItem: translate('Bildirimler'), render: () => <Tab.Pane>
                <DataTable dataTable={notiListTableModel}
                    isAllSelected={isAllSelected}
                    selectAllHandler={selectAllHandler}
                    isLoading={props.isLoading}
                    isDateTimeFormat={true}

                />
            </Tab.Pane>
        },

        {
            menuItem: translate('Gönderilecekler'), render: () => <Tab.Pane>
                {pendingTableModel != undefined
                    &&
                    <>
                        <div className="width100">
                            <Button
                                circular
                                color="green"
                                className="floatRight"
                                disabled={isSendAllLoader}
                                loading={isSendAllLoader}
                                onClick={(e) => { sendAllNotiNow(); }}
                            >
                                <Icon name="send" />
                                {translate('Hepsini Gönder')}
                            </Button>

                            <Button
                                circular
                                className="floatRight"
                                style={{ float: 'rigth' }}
                                color="black"
                                onClick={purgeSchedulerPool}
                                disabled={isLoadingPurge}
                                loading={isLoadingPurge}
                            >
                                <Icon name="redo" />
                                {translate("Gönderilecek listesini yeniden hesapla")}
                            </Button>
                        </div>
                        <DataTable dataTable={pendingTableModel}
                            isAllSelected={isAllSelectedPending}
                            selectAllHandler={selectAllPendingHandler}
                            isLoading={props.isLoading}
                            isDateTimeFormat={true}
                        />
                    </>
                }
            </Tab.Pane>
        },
        {
            menuItem: translate('Geçmiş'), render: () => <Tab.Pane>
                <DataTable dataTable={historyTableModel}
                    isAllSelected={isAllSelectedHistory}
                    selectAllHandler={selectAllHistoryHandler}
                    isLoading={props.isLoading}
                    isDateTimeFormat={true}

                /></Tab.Pane>
        },
        {
            menuItem: translate('İptal edilenler'), render: () => <Tab.Pane>
                <DataTable dataTable={cancelTableModel}
                    isAllSelected={isAllSelectedHistory}
                    selectAllHandler={selectAllHistoryHandler}
                    isLoading={props.isLoading}
                    isDateTimeFormat={true}

                /></Tab.Pane>
        },
        {
            menuItem: translate('Hata alanlar'), render: () => <Tab.Pane>
                <DataTable dataTable={errorTableModel}
                    isAllSelected={isAllSelectedHistory}
                    selectAllHandler={selectAllHistoryHandler}
                    isLoading={props.isLoading}
                    isDateTimeFormat={true}

                /></Tab.Pane>
        },
    ]

    useEffect(() => {
        getContainerRunDate();
        getRememberTime();
    }, []);

    useEffect(() => {
        ////console.log("useEffect: ", notiListTableModel);
    }, [notiListTableModel]);

    useEffect(() => {
        ////console.log("useEffect baseHistoryList: ", baseHistoryList);
    }, [baseHistoryList]);

    useEffect(() => {
        //console.log("useEffect basePendingList: ", basePendingList);
        if (basePendingList.length > 0 && basePendingResponse != undefined) {
            let clone = _.cloneDeep(basePendingResponse);
            pendingPrepareData(clone.list, clone.count);
        }
    }, [basePendingList, basePendingResponse]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: notiListFilterText,
            currentPage: notiListCurrentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: notiListStartingDate,
            dueDate: notiListDueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getNotiList(filterData);
    }, [notiListFilterText, notiListCurrentPage, isActive, isDeleted, notiListStartingDate, notiListDueDate]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: historyFilterText,
            currentPage: historyCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getHistory(filterData);
    }, [historyFilterText, historyCurrentPage]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: pendingFilterText,
            currentPage: pendingCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getPending(filterData);
    }, [pendingFilterText, pendingCurrentPage]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: canceledFilterText,
            currentPage: canceledCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getCanceledQueue(filterData);
    }, [canceledFilterText, canceledCurrentPage]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: errorFilterText,
            currentPage: errorCurrentPage,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        getErrorQueue(filterData);
    }, [errorFilterText, errorCurrentPage]);

    const handleTabChange = (e, data) => {
        let index = data.activeIndex;
        /*  console.log("index", index);*/
        setActiveIndex(index);

        if (index == 0) {
            let filterData = {
                filterText: notiListFilterText,
                currentPage: notiListCurrentPage,
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            getNotiList(filterData);
        } else if (index == 1) {
            let filterData1 = {
                filterText: pendingFilterText,
                currentPage: pendingCurrentPage,
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            getPending(filterData1);
        } else if (index == 2) {
            /* console.log("girdi - geçmiş");*/
            let filterData2 = {
                filterText: historyFilterText,
                currentPage: historyCurrentPage,
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            getHistory(filterData2);
        } else if (index == 3) {
            let filterData3 = {
                filterText: canceledFilterText,
                currentPage: canceledCurrentPage,
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            getCanceledQueue(filterData3);
        } else if (index == 4) {
            let filterData4 = {
                filterText: errorFilterText,
                currentPage: errorCurrentPage,
                tenantId: DataHandler.getUserDataWithKey("tenantId")
            }
            getErrorQueue(filterData4);
        }
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
            <div>
                <Transition visible={false} animation='scale' duration={500}>
                    <div>
                        <Loader size={"huge"} active inline='centered' />
                    </div>
                </Transition>
                <Transition visible={true} animation='scale' duration={500}>
                    <div>
                        <Grid columns={2} style={{ marginBottom: '3px' }}>
                            <Grid.Row>
                                <Grid.Column style={{ width: '80%' }}>
                                    <h4 style={{ color: 'yellowgreen', margin: "unset" }}>
                                        {/*//TODO: translate yapılacak*/}
                                        {translate("Bildirimler saat başı gönderime başlar. Bir sonraki çalışma zamanına kalan süre")} : {remainingMinutes}
                                    </h4>
                                    <h4 style={{ color: 'yellowgreen', margin: "unset" }}>
                                        {/*//TODO: translate yapılacak*/}
                                        {translate("Mail kuyruğunun yeniden hesaplanması her saat 15 geçe başlar.")}
                                    </h4>
                                    <h4 style={{ color: 'yellowgreen', margin: "unset" }}>
                                        {/*//TODO: translate yapılacak*/}

                                        {translate(`Modül hatırlatmaları sadece saat ${moduleRememberenceTime} arasında hesaplanır.`)}
                                    </h4>
                                </Grid.Column>

                            </Grid.Row>

                        </Grid>
                        <div>
                            <Tab
                                activeIndex={activeIndex}
                                onTabChange={(e, data) => {
                                    handleTabChange(e, data);
                                }}
                                panes={panes}
                            />
                        </div>
                    </div>
                </Transition>
                <Transition visible={isPreviewModalShown} animation='scale' duration={500}>
                    <Modal open={isPreviewModalShown}
                        onClose={() => setIsPreviewModalShown(false)}>
                        <Modal.Header>{previewModel.mailTo != null ? translate("Bildirim Önizleme") : translate("Hata")}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Grid columns={1}>
                                    {previewModel.mailTo != null &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p><b>{translate("Alıcı(To):")}</b></p>
                                                <div dangerouslySetInnerHTML={{ __html: previewModel.mailTo }}></div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                    {previewModel.ccList != null &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p><b>{translate("CC:")}</b></p>
                                                <div dangerouslySetInnerHTML={{ __html: previewModel.ccList }}></div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                    {previewModel.subject != null &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p><b>{translate("Konu")}:</b></p>
                                                <p>{previewModel.subject}</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                    {previewModel.body != null &&
                                        <Grid.Row>
                                            <Grid.Column>
                                                <p><b>{translate("Mesaj:")}</b></p>
                                                <div dangerouslySetInnerHTML={{ __html: previewModel.body }}></div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                </Grid>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' circular={true} onClick={() => setIsPreviewModalShown(false)}>
                                {translate("Tamam")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>
            </div>
        </div>
    );
}

export default ScheduledJobList;