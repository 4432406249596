import React, {useEffect, useState, memo} from 'react';
import TranslateHelper from "../../../helper/TranslateHelper";
import RenderModules from "./RenderModules";
import {v4 as uuidv4} from 'uuid';
import {Button, Checkbox, Icon, Image, Input, Label, Popup} from "semantic-ui-react";
import {displayProtectedAppDoc} from "../../../helper/DisplayProtectedDocument";
import {motion} from "framer-motion";
import BuddyReCaptcha from "../../../components/BuddyReCaptcha";
import {isMobile} from "react-device-detect";
import {DateFormat} from "../../../helper/DateFormat";
import HomePageFilterPanel from "./HomePageFilterPanel";
import {Debugger} from "inspector";

interface HomePageModuleRenderer {
    modules: any
    getAssignedCoursesByFilter: Function
    getAssignedCourses: Function
    isLoader: boolean
    endUserModuleFilterData: any
    setEndUserModuleFilterData: any
    setFilterLock: any
}

const HomePageModuleRenderer = ({
                                    modules,
                                    getAssignedCoursesByFilter,
                                    getAssignedCourses,
                                    isLoader,
                                    // endUserModuleFilterData,
                                    setEndUserModuleFilterData,
                                    setFilterLock
                                }: HomePageModuleRenderer) => {
    var {translate} = TranslateHelper();

    const modulesArray = [
        {title: translate("Yeni Modüller"), category: "1"},
        {title: translate("Devam Eden Modüller"), category: "3"},
        {title: translate("Tamamlanan Modüller"), category: "5"},
    ];

    const [filterData, setFilterData] = useState<any>();

    const renderModuleByCategory = () => (
        modulesArray.map((module) => (
            <RenderModules
                key={uuidv4()}
                modules={modules}
                title={module.title}
                category={module.category}
                isLoader={isLoader}
            />
        ))
    )

    const filterModules = () => {
        //setFilterLock(true);
        getAssignedCoursesByFilter(filterData);
    };

    const isUndefined = (modules): boolean => {
        
        if (modules.list != undefined) return true;

        return (modules.filter(x => x.category == 1) == undefined
            && modules.filter(x => x.category == 3) == undefined
            && modules.filter(x => x.category == 5) == undefined)
    }

    useEffect(() => {
        if (filterData != undefined) {
            setEndUserModuleFilterData(filterData);
        }
    }, [filterData])

    return (
        <div className={"homePageModuleRendererContainer"}>
            <div className={"homePageModuleRendererHeader"}>
                <div className={"homePageV2ModuleTitle"}>{translate("Modüller")}</div>
                <div className={"homePageModuleRendererHeaderRight"}>
                    <HomePageFilterPanel
                        filterModules={filterModules}
                        // getAssignedCoursesByFilter={getAssignedCoursesByFilter}
                        setFilterData={setFilterData}
                        isLoader={isLoader}
                        filterData={filterData}
                        setFilterLock={setFilterLock}
                    />
                </div>
            </div>

            {isUndefined(modules)
                ? <RenderModules
                    // key={uuidv4()}
                    modules={modules}
                    title={""}
                    category={"filter"}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    getAssignedCoursesByFilter={getAssignedCoursesByFilter}
                    getAssignedCourses={getAssignedCourses}
                    isLoader={isLoader}
                /> : renderModuleByCategory()
            }
        </div>
    );
};

export default memo(HomePageModuleRenderer);