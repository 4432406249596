import React from 'react';
import TranslateHelper from '../../helper/TranslateHelper';

const PrivacyPolicy = () => {

    var { translate } = TranslateHelper();

    return (
        <div>
            {translate("GİZLİLİK POLİTİKASI"
                + "<br/>"
                + "İşbu Gizlilik Politikası’nın amacı, Dijital İnsan Kaynakları ve Tasarım LTD. ŞTİ."
                + "(“Şirket”) tarafından işletilmekte olan www.digitallica.com.tr internet sitesi (“Site”)"
                + "üzerinden temin edilen hizmetler sırasında Site kullanıcıları/üyeleri/ziyaretçileri (“Veri"
                + "Sahibi”) tarafından Şirket ile paylaşılan veya Şirket’in Veri Sahibi’nin Site’yi kullanımı"
                + "sırasında ürettiği kişisel verilerin kullanımına ilişkin koşul ve şartları tespit"
                + "etmektir. Veri Sahibi, işbu Gizlilik Politikası ile kişisel verilerinin işlenmesine"
                + "ilişkin olarak aydınlatılmış olduğunu ve kişisel verilerinin burada belirtilen"
                + "şekilde kullanımına muvafakat ettiğini beyan eder."
                + "Hangi Veriler İşlenmektedir?"
                + "İşbu başlık altında, Şirket tarafından işlenen ve Kişisel Verilerin Korunması Kanunu"
                + "uyarınca kişisel veri sayılan verilerin hangileri olduğu sıralanmıştır. Aksi açıkça"
                + "belirtilmedikçe, işbu Gizlilik Politikası kapsamında arz edilen hüküm ve koşullar"
                + "kapsamında “kişisel veri” ifadesi aşağıda yer alan bilgileri kapsayacaktır."
                + "<br/>"
                + " İsim, Soyisim       <br/>"
                + " E-posta adresi       <br/>"
                + " Telefon numarası       <br/>"
                + " Çalışılan firma bilgisi       <br/>"
                + " İş ünvanı       <br/>"
                + "<br/>"
                + "Şirket, Veri Sahibi’nin Site’yi kullanımı ile ilgili bilgileri teknik bir iletişim dosyası olan"
                + "çerezleri (Cookie) kullanarak elde edebilmekte olup Site üzerinde sunulmakta olan"
                + "hizmetlere erişim ve kullanım alışkanlıklarının tespit edilmesi için çerez yoluyla IP"
                + "bilgileri toplanmaktadır. Çerez kullanımına ilişkin Çerez Politikamıza ulaşabilirsiniz."
                + "Kişisel Verilerin Korunması Kanunu’nun 3 ve 7. maddesi dairesince, geri"
                + "döndürülemeyecek şekilde anonim hale getirilen veriler, anılan kanun hükümleri"
                + "uyarınca kişisel veri olarak kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri"
                + "işbu Gizlilik Politikası hükümleri ile bağlı olmaksızın gerçekleştirecektir."
                + "<br/>"
                + "Veriler Hangi Amaçlarla Kullanılmaktadır?       <br/>"
                + ""
                + "Şirket, Veri Sahibi tarafından sağlanan kişisel verileri, Digitallica ürün ve yeniliklerini"
                + "tanıtmak, e-öğrenme sektörüne dair bilgileri, gelişmeleri, yeni projeler hakkında"
                + "müşteri ve potansiyel müşterilerle buluşturmak gibi amaçlar başta olmak üzere, Şirket"
                + "tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz"
                + "tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi,"
                + "Şirket’in ticari ve/veya iş stratejilerinin planlanması ve icrası, Şirket tarafından"
                + "sunulan hizmetlerden ilgili kişileri faydalandırmak ve talep eden kişileri bilgilendirmek,"
                + "Şirket’in ve Şirket’le iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş"
                + "güvenliğini temin etmek, kişilerden gelen talepleri ve/veya şikayetleri değerlendirmek"
                + "<br/>"
                + ""
                + "ve sonuçlandırmak ve bu amaçla ilgili iş birimleri ve taraflarla gereken çalışmaları"
                + "koordine etmek, planlanmak ve icra etmek gibi amaçlarla işleyebilmektedir. Şirket"
                + "ayrıca, bahsi geçen kişisel verileri sunduğu hizmetlerin iyileştirilmesi, hizmetin"
                + "geliştirilmesi ve bu kapsamda Veri Sahibi’ne gerekli bilgilendirilmelerin yapılması ve"
                + "sunduğu hizmetlerin doğasından kaynaklanan yükümlülüklerin yerine getirilmesi için"
                + "işleyebilir.  Söz konusu kişisel bilgiler Veri Sahibi ile temas kurmak veya Veri"
                + "Sahibi’nin Site’deki tecrübesini iyileştirmek (mevcut hizmetlerin geliştirilmesi, yeni"
                + "hizmetler oluşturulması ve kişiye özel hizmetler sunulması gibi) amacıyla"
                + "kullanılabileceği gibi, Şirket raporlama ve iş geliştirme faaliyetleri kapsamında"
                + "kullanılabilecek, Veri Sahibi’nin kimliği ifşa edilmeden çeşitli istatistiksel"
                + "değerlendirmeler yapma, veri tabanı oluşturma ve pazar araştırmalarında bulunma"
                + "amacıyla da kullanılabilecektir. Söz konusu bilgiler Şirket ve iş birliğinde olduğu kişiler"
                + "tarafından doğrudan pazarlama yapmak amacıyla işlenebilecek, saklanabilecek,"
                + "üçüncü kişilere iletilebilecek ve söz konusu bilgiler üzerinden çeşitli uygulama, ürün"
                + "ve hizmetlerin tanıtımı, bakım ve destek faaliyetlerine ilişkin bildirimlerde bulunma"
                + "amacıyla Veri Sahibi ile iletişime geçilebilecektir.  Şirket ayrıca, Kişisel Verilerin"
                + "korunması Kanunu’nun 5 ve 8. maddeleri uyarınca ve/veya ilgili mevzuattaki"
                + "istisnaların varlığı halinde kişisel verileri Veri Sahibi’nin ayrıca rızasını almaksızın"
                + "işleyebilecek ve üçüncü kişilerle paylaşabilecektir. Bu durumlardan bazıları aşağıda"
                + "belirtilmiştir:"
                + "<br/>"
                + " Kanunlarda açıkça öngörülmesi,       <br/>"
                + " Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya"
                + "rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının"
                + "hayatı veya beden bütünlüğünün korunması için zorunlu olması,       <br/>"
                + " Veri Sahibi ile Şirket arasında herhangi bir sözleşmenin kurulması veya ifasıyla"
                + "doğrudan doğruya ilgili olması kaydıyla, kişisel verilerin işlenmesinin gerekli"
                + "olması,       <br/>"
                + " Hukuki yükümlülüklerin yerine getirebilmesi için zorunlu olması,       <br/>"
                + " Veri Sahibi’nin kendisi tarafından alenileştirilmiş olması,       <br/>"
                + " Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,       <br/>"
                + " Veri Sahibi’nin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirket’in"
                + "meşru menfaatleri için veri işlenmesinin zorunlu olması.       <br/>"
                + "<br/>"
                + "Yukarıda da belirtildiği üzere Şirket, çerez (Cookie) kullanabilecek ve bu kapsamda"
                + "veri işleyerek üçüncü kişiler tarafından sunulan analiz hizmetleri kapsamında"
                + "işlenmesi amacıyla sadece bu analiz hizmetlerinin gerektirdiği ölçüde kullanılması"
                + "amacıyla üçüncü kişilere iletebilecektir. Bahsi geçen teknik iletişim dosyaları, ana"
                + "bellekte saklanmak üzere Site’nin Veri Sahibi tarayıcısına (browser) gönderdiği küçük"
                + "metin dosyalarıdır. Teknik iletişim dosyası bir web sitesi hakkında durum ve tercih"
                + "ayarlarını saklayarak internetin kullanımını bu anlamda kolaylaştırır. Teknik iletişim"
                + "dosyası, internet sitelerini zamansal oranlamalı olarak kaç kişinin kullandığını, bir"
                + "kişinin herhangi bir internet sitesini hangi amaçla, kaç kez ziyaret ettiği ve ne kadar"
                + "kaldığı hakkında istatistiksel bilgileri elde etmek ve Kullanıcılar için özel tasarlanmış"
                + "kullanıcı sayfalarından dinamik olarak reklam ve içerik üretilmesine yardımcı olmak"
                + "üzere tasarlanmış olup, bu amaçlarla kullanılmaktadır. Teknik iletişim dosyası, ana"
                + "bellekten başkaca herhangi bir kişisel veri almak için tasarlanmamıştır. Tarayıcıların"
                + "pek çoğu başta teknik iletişim dosyasını kabul eder biçimde tasarlanmıştır, ancak"
                + "kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik iletişim"
                + "dosyasının gönderildiğinde ikaz verilmesini sağlayacak biçimde tarayıcı ayarlarını her"
                + ""
                + "zaman için değiştirebilirler. Şirket ayrıca, online davranışsal reklamcılık ve pazarlama"
                + "yapılabilmesi amacıyla Veri Sahibi’nin sitedeki davranışlarını tarayıcıda bulunan bir"
                + "cookie (çerez) ile ilişkilendirme ve görüntülenen sayfa sayısı, ziyaret süresi ve hedef"
                + "tamamlama sayısı gibi metrikleri temel alan yeniden pazarlama listeleri tanımlama"
                + "hakkını haizdir. Daha sonra Veri Sahibi’ne Site’de ya da görüntülü reklam ağındaki"
                + "diğer sitelerde ilgi alanlarına göre hedefe yönelik reklam içeriği gösterilebilir."
                + "Verilere Kimler Erişebilmektedir?"
                + "Şirket, Veri Sahibi’ne ait kişisel verileri ve bu kişisel verileri kullanılarak elde ettiği yeni"
                + "verileri, Veri Sahibi’ne karşı taahhüt ettiği edimlerin ifası amacıyla Şirket’in"
                + "hizmetlerinden faydalandığı üçüncü kişilere, söz konusu hizmetlerin temini amacıyla"
                + "sınırlı olmak üzere aktarılabilecektir.  Şirket, Veri Sahibi deneyiminin geliştirilmesi"
                + "(iyileştirme ve kişiselleştirme dâhil), Veri Sahibi’nin güvenliğini sağlamak, hileli ya da"
                + "izinsiz kullanımları tespit etmek, operasyonel değerlendirme araştırılması, Site veya"
                + "Şirket hizmetlerine ilişkin hataların giderilmesi ve işbu Gizlilik Politikası’nda yer alan"
                + "amaçlardan herhangi birisini gerçekleştirebilmek için SMS gönderimi yapanlar da"
                + "dahil olmak üzere dış kaynak hizmet sağlayıcıları, barındırma hizmet sağlayıcıları"
                + "(hosting servisleri), hukuk büroları, araştırma şirketleri, çağrı merkezleri gibi üçüncü"
                + "kişiler ile paylaşabilecektir. Veri Sahibi, yukarıda belirtilen amaçlarla sınırlı olmak"
                + "kaydı ile bahsi geçen üçüncü tarafların Veri Sahibi’nin kişisel verilerini dünyanın her"
                + "yerindeki sunucularında saklayabileceğini, bu hususa peşinen muvafakat ettiğini"
                + "kabul eder."
                + "Verilere Erişim Hakkı ve Düzeltme Talepleri Hakkında"
                + "Veri Sahibi, Şirkete başvurarak kendisiyle ilgili;       <br/>"
                + " Kişisel veri işlenip işlenmediğini öğrenme,       <br/>"
                + " Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,       <br/>"
                + " Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp"
                + "kullanılmadığını öğrenme,       <br/>"
                + " Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,       <br/>"
                + " Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini"
                + "isteme,       <br/>"
                + " İlgili mevzuatta öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya"
                + "yok edilmesini isteme,       <br/>"
                + " İlgili mevzuat uyarınca yapılan düzeltme, silme ve yok edilme işlemlerinin, kişisel"
                + "verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,       <br/>"
                + " İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi"
                + "suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,       <br/>"
                + " Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması"
                + "halinde zararın giderilmesini talep etme,       <br/>"
                + "<br/>"
                + "haklarına sahiptir. Veri Sahibi, yukarıda belirtilen taleplerini Şirket’e (Akasya Towers"
                + "Acıbadem Mah. Çeçen Sok. No:25 A Daire:150 Üsküdar – İSTANBUL adresine)"
                + "yazılı olarak iletebilecektir. Şirket, talebin niteliğine göre ve en geç 30 gün içerisinde"
                + "Veri Sahibi taleplerini sonuçlandırır. İşlemlerin bir maliyet gerektirmesi halinde, Kişisel"
                + "Verilerin Korunması Kurulu tarafından belirlenebilecek tarife üzerinden ücret talep"
                + "edilmesi mümkündür.   Veri Sahibi, işbu Gizlilik Politikası’na konu bilgilerinin tam,"
                + ""
                + "doğru ve güncel olduğunu, bu bilgilerde herhangi bir değişiklik olması halinde bunları"
                + "derhal güncelleyeceğini taahhüt eder. Veri Sahibi’nın güncel bilgileri sağlamamış"
                + "olması halinde Şirket’in herhangi bir sorumluluğu olmayacaktır.   Veri Sahibi,"
                + "herhangi bir kişisel verisinin Şirket tarafından kullanılamaması ile sonuçlanacak bir"
                + "talepte bulunması halinde Şirket’in kendisine taahhüt ettiği hizmetlerden tam olarak"
                + "faydalanamayabileceğini kabul ile bu kapsamda doğacak her türlü sorumluluğun"
                + "kendisine ait olacağını beyan eder."
                + "Kişisel Verilerin Saklama Süresi"
                + "Şirket, Veri Sahibi tarafından sağlanan kişisel verileri, sunduğu hizmetlerin"
                + "mahiyetinden kaynaklanan yükümlülüklerin yerine getirilmesi amacıyla, hizmetlerin"
                + "sağlandığı süre boyunca saklayacaktır.   Buna ek olarak, Şirket, Veri Sahibi ile"
                + "arasında doğabilecek herhangi bir uyuşmazlık durumunda, uyuşmazlık kapsamında"
                + "gerekli savunmaların gerçekleştirilebilmesi amacıyla sınırlı olmak üzere ve ilgili"
                + "mevzuat uyarınca belirlenen zamanaşımı süreleri boyunca kişisel verileri"
                + "saklayabilecektir. Kişisel verilerle ilgili olarak daha kapsamlı açıklamalar için Şirketin"
                + "“Kişisel Verilerin Korunması ve İşlenmesi Politikası” na aşağıdaki linklerden"
                + "ulaşabilirsiniz:"
                + "Kişisel Verilerin Korunması ve İşlenmesi Politikası"
                + "Veri Sahibi Başvuru Formu"
                + "Veri Güvenliğine İlişkin Önlemler ve Taahhütler"
                + "Şirket, ilgili mevzuatta belirlenen veya işbu Gizlilik Politikası’nda ifade edilen"
                + "şartlarda,       <br/>"
                + " kişisel verilerin hukuka aykırı olarak işlenmemesini,       <br/>"
                + " kişisel verilere hukuka aykırı olarak erişilmemesini ve       <br/>"
                + " kişisel verilerin muhafazasını       <br/>"
                + "<br/>"
                + "sağlamak amacıyla uygun güvenlik düzeyini temin etmeye yönelik gerekli teknik ve"
                + "idari tedbirleri almayı, gerekli denetimleri yaptırmayı taahhüt eder. Şirket, Veri Sahibi"
                + "hakkında elde ettiği kişisel verileri bu işbu Gizlilik Politikası ve 6698 sayıl Kişisel"
                + "Verilerin Korunması Kanunu hükümlerine aykırı olarak başkasına açıklayamaz ve"
                + "işleme amacı dışında kullanamaz. Site üzerinden başka uygulamalara link verilmesi"
                + "halinde, Şirket uygulamaların gizlilik politikaları ve içeriklerine yönelik herhangi bir"
                + "sorumluluk taşımamaktadır."
                + "Gizlilik Politikası’ndaki Değişiklikler"
                + "Şirket, işbu Gizlilik Politikası hükümlerini dilediği zaman değiştirebilir. Güncel Gizlilik"
                + "Politikası, Veri Sahibi’ne herhangi bir yöntemle sunulduğu tarihte yürürlük kazanır."
                + "İNTERNET SİTEMİZDE KULLANILAN ÇEREZLER"
                + "(ÇEREZ POLİTİKAMIZ)"
                + ""
                + " Çerez Nedir ve Neden Kullanılmaktadır?"
                + "<br/>"
                + "Çerezler, ziyaret ettiğiniz internet siteleri tarafından tarayıcılar aracılığıyla cihazınıza"
                + "veya ağ sunucusuna depolanan küçük metin dosyalarıdır.   İnternet Sitemizde çerez"
                + "kullanılmasının başlıca amaçları aşağıda sıralanmaktadır: <br/>"
                + " İnternet sitesinin işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan"
                + "hizmetleri geliştirmek,       <br/>"
                + " İnternet Sitesini iyileştirmek ve İnternet Sitesi üzerinden yeni özellikler sunmak"
                + "ve sunulan özellikleri sizlerin tercihlerine göre kişiselleştirmek;       <br/>"
                + " İnternet Sitesinin, sizin ve Şirketimizin hukuki ve ticari güvenliğinin teminini"
                + "sağlamak.       <br/>"
                + ""
                + " İnternet Sitemizde Kullanılan Çerez Türleri"
                + "<br/>"
                + "Oturum"
                + "Çerezleri (Session"
                + "Cookies)"
                + ""
                + "Oturum çerezleri ziyaretçilerimizin İnternet Sitesini ziyaretleri"
                + "süresince kullanılan, tarayıcı kapatıldıktan sonra silinen geçici"
                + "çerezlerdir. Bu tür çerezlerin kullanılmasının temel amacı"
                + "ziyaretiniz süresince İnternet Sitesinin düzgün bir biçimde"
                + "çalışmasının teminini sağlamaktır. Örneğin; birden fazla sayfadan"
                + "oluşan çevrimiçi formları doldurmanızın sağlanmaktadır."
                + ""
                + "Kalıcı Çerezler(Persistent"
                + "Cookies)"
                + ""
                + "Kalıcı çerezler İnternet Sitesinin işlevselliğini artırmak,"
                + "ziyaretçilerimize daha hızlı ve iyi bir hizmet sunmak amacıyla"
                + "kullanılan çerez türleridir. Bu tür çerezler tercihlerinizi hatırlamak"
                + "için kullanılır ve tarayıcılar vasıtasıyla cihazınızda depolanır."
                + "Kalıcı çerezlerin bazı türleri; İnternet Sitesini kullanım amacınız"
                + "gibi hususlar göz önünde bulundurarak sizlere özel öneriler"
                + "sunulması için kullanılabilmektedir. Kalıcı çerezler sayesinde"
                + "İnternet Sitemizi aynı cihazla tekrardan ziyaret etmeniz"
                + "durumunda, cihazınızda İnternet Sitemiz tarafından oluşturulmuş"
                + "bir çerez olup olmadığı kontrol edilir ve var ise, sizin siteyi daha"
                + "önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu"
                + "doğrultuda belirlenir ve böylelikle sizlere daha iyi bir hizmet"
                + "sunulur."
                + ""
                + " İnternet Sitemizde Kullanılan Çerezler"
                + "Teknik Çerezler"
                + "(Technical Cookies)"
                + ""
                + "Teknik çerezler ile internet sitesinin çalışmasının sağlanmakta,"
                + "internet sitesinin çalışmayan sayfaları ve alanları tespit"
                + "edilmektedir."
                + ""
                + "Otantikasyon Çerezleri"
                + "(Authentication Cookies)"
                + ""
                + "Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş yapmaları"
                + "durumunda, bu tür çerezler ile, ziyaretçinin internet sitesinde"
                + "ziyaret ettiği her bir sayfada site kullanıcısı olduğu belirlenerek,"
                + "kullanıcının her sayfada şifresini yeniden girmesi önlenir."
                + "Flash Çerezleri (Flash İnternet sitesinde yer alan görüntü veya ses içeriklerini"
                + ""
                + "Cookies) etkinleştirmek için kullanılan çerez türleridir."
                + "Kişiselleştirme Çerezleri"
                + "(Customization Cookies)"
                + ""
                + "Kullanıcıların tercihlerini farklı internet sitesinin farklı sayfalarını"
                + "ziyarette de hatırlamak için kullanılan çerezlerdir. Örneğin, seçmiş"
                + "olduğunuz dil tercihinizin hatırlanması."
                + ""
                + "Analitik Çerezler"
                + "(Analytical Cookies)"
                + ""
                + "Analitik çerezler ile internet sitesini ziyaret edenlerin sayıları,"
                + "internet sitesinde görüntülenen sayfaların tespiti, internet sitesi"
                + "ziyaret saatleri, internet sitesi sayfaları kaydırma hareketleri gibi"
                + "analitik sonuçların üretimini sağlayan çerezlerdir."
                + ""
                + " Çerezlerin Kullanımı Veri Sahipleri Tarafından Engellenebilir mi?"
                + "Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirme"
                + "imkanına sahipsiniz."
                + "Adobe Analytics"
                + "AOL"
                + "Google Adwords"
                + "Google Analytics"
                + "Google Chrome"
                + "Internet Explorer"
                + "MozillaFirefox"
                + "Opera"
                + "Safari")}
        </div>
    );
};

export default PrivacyPolicy;