import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Grid, Input, Label, Icon } from 'semantic-ui-react';
import { NetworkRequest } from '../../../../helper/NetworkRequest';

const AddSubscription = (props) => {

    const [displayName, setDisplayName] = useState('');

    const save = () => {
        NetworkRequest("/api/SubscriptionTypes/Add", { displayName: displayName }).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                props.showList();
            }
        });
    }

    return (
        <div>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Label>
                            Adı:
                        </Label>
                    </Grid.Column>
                    <Grid.Column>
                        <Input onChange={(e) => { setDisplayName(e.target.value); }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button className={"dangerRedButton"} onClick={props.showList}>
                            <Icon name="arrow left" />
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button className={"positiveGreenButton"} onClick={save}>
                            <Icon name="check" />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default AddSubscription;