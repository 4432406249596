import * as React from 'react';
import { useState, useEffect } from 'react';
import { NetworkRequest} from '../../../helper/NetworkRequest';
import DataTable from '../../../components/DataTable';
import { IDataTable, IQuestion } from '../../../interfaces/interfaces'
import { datableFilterChange, digitallicaConfirm, getQuestionContentForRender, prepareDataForTable } from '../../../helper/HelperFunctions';

import { Checkbox, Loader, Transition } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import TranslateHelper from '../../../helper/TranslateHelper';
import { DataHandler } from '../../../helper/DataHelper';


const Questions = (props) => {
    var {translate} = TranslateHelper();
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filterData, setFilterData] = useState<any>({});
    const [dataTableHtml, setDataTableHtml] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const cloneRow = (id) => {
        digitallicaConfirm(
            translate("Klonlamak istediğinizden emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Question/Clone", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        ////
                        let req = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        getData(req);
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );

    }

    //delete selected data
    const deleteRow = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Question/Delete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                            isActive: isActive,
                            isDeleted: isDeleted,
                            startingDate: startingDate,
                            dueDate: dueDate,
                            tenantId: DataHandler.getUserDataWithKey("tenantId")
                        }
                        setFilterData(filterData);
                        getData(filterData);
                        toast(translate("Başarılı."), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });

                    }

                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );
    }

    const undoDelete = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let test = {
                    id: id
                }
                NetworkRequest("/Question/UndoDelete", test).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let index = dataTableModel.data.findIndex(
                            data => data.secretData[0].id === id
                        )
                        if (index > -1) {
                            dataTableModel.data.splice(index, 1);
                            setDataTableModel({
                                header: dataTableModel.header,
                                data: [],
                                transactions: dataTableModel.transactions,
                                filter: dataTableModel.filter,
                                pagination: dataTableModel.pagination,
                                addRowLink: dataTableModel.addRowLink
                            });
                            setDataTableModel(dataTableModel);
                        }
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );

    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink,
                addExcelLink: dataTableModel.addExcelLink

            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsActive);
        setIsDeleted(!data.checked);
    }

    const onChangeDeleted = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsDeleted);
        setIsActive(!data.checked);
    }

    const onChangeDate1 = (date) => {
        setStartingDate(date);
    }

    const onChangeDate2 = (date) => {
        setDueDate(date);
    }

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }
        setFilterData(filterData);
        getData(filterData);
    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate]);

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            <Checkbox
                onChange={setAllSelected}
            />,
            translate("Soru Metni"),
            translate("A"),
            translate("B"),
            translate("C"),
            translate("D"),
            translate("E"),
            translate("Doğru Seçenek"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            //{
            //    icon: "chart bar",
            //    desc: "Rapor",
            //    color: undefined,
            //    link: "/report",
            //    type: "report"
            //},
            {
                icon: "clone",
                desc: translate("Klonla"),
                color: undefined,
                function: cloneRow,
                type: "clone"
            },
            {
                icon: "edit",
                desc: translate("Düzenle"),
                color: "blue",
                link: "/questionEdit",
                type: "edit",

            },
            {
                icon: "trash",
                desc: translate("Sil"),
                color: "red",
                link: null,
                type: "delete",
                function: deleteRow
            },
            {
                icon: "redo",
                desc: translate("Geri al"),
                color: "black",
                link: null,
                type: "undo",
                function: undoDelete
            }
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: translate("Aktif"),
                    defaultValue: true,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },
                {
                    desc: translate("Silinen"),
                    defaultValue: false,
                    key: "isDeleted",
                    type: "boolean",
                    onChange: onChangeDeleted
                },
                {
                    desc: translate("Tarih"),
                    defaultValue: [startingDate, dueDate],
                    key: "date",
                    type: "date",
                    onChange: [onChangeDate1, onChangeDate2]
                }
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/questionAdd",
        addExcelLink: "/questionImport",
        setRowSelected: setRowSelected,
    });

    //prepare data for table
    const prepareData = (data) => {
        // //debugger;
        if (data.list.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }

        //hidden column
        var model = prepareDataForTable(data.list, [
            'id',
            'updatedDate',
            'deletedDate',
            'isDeleted',
            'isActive',
            'userId'
        ]);


        setTimeout(() => {
            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        {
                            desc: translate("Aktif"),
                            defaultValue: isActive,
                            key: "isActive",
                            type: "boolean",
                            onChange: onChangeActive
                        },
                        {
                            desc: "Silinen",
                            defaultValue: isDeleted,
                            key: "isDeleted",
                            type: "boolean",
                            onChange: onChangeDeleted
                        },
                        {
                            desc: "Tarih",
                            defaultValue: [startingDate, dueDate],
                            key: "date",
                            type: "date",
                            onChange: [onChangeDate1, onChangeDate2]
                        }
                    ]
                },
                pagination: {
                    count: Math.ceil(data.count / 25),
                    setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });
        }, 100);

        setIsLoading(false);
    }

    //get table data from database
    const getData = (filterData?) => {
        setIsLoading(true);
        NetworkRequest("/Question/GetQuestions", filterData).then(response => {
            ////
            if (response.data.resultCode === 0) {
                let designedQuestionArray = Array<IQuestion>();

                let questions = response.data.detail.list;
                questions.map(question => {
                    let newQuestionFormat = {
                        id: question.id,
                        body: getQuestionContentForRender(question.questionJSON.body),
                        a: question.questionJSON.a,
                        b: question.questionJSON.b,
                        c: question.questionJSON.c,
                        d: question.questionJSON.d,
                        e: question.questionJSON.e,
                        correntOption: question.questionJSON.correctAnswer,
                        isDeleted: question.isDeleted,
                        updatedDate: question.updatedDate,
                        deletedDate: question.deletedDate,
                        userId: question.userId,
                        isActive: question.isActive,
                    }

                    designedQuestionArray.push(newQuestionFormat);
                });

                ////console.log(designedQuestionArray);

                prepareData({ list: designedQuestionArray, count: response.data.detail.count });
                setIsLoading(false);
            }
        });
    }

    return (
        <div className={"generalContainerForWhiteBg"}>
        <div>
            <Transition visible={false} animation='scale' duration={500}>
                <div>
                    <Loader size={"huge"} active inline='centered' />
                </div>
            </Transition>
            <Transition visible={true} animation='scale' duration={500}>
                <div>
                    {/*{dataTableHtml}*/}
                    <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected} setAllSelected={setAllSelected} selectAllHandler={selectAllHandler} isLoading={isLoading} />
                </div>
            </Transition>
        </div>
        </div>
    );
}


export default Questions;