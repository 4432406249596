import * as React from 'react';
import { toast } from 'react-toastify';
import { Button, TextArea } from 'semantic-ui-react';
import BuddyReCaptcha from '../../components/BuddyReCaptcha';
import { NetworkRequest} from '../../helper/NetworkRequest';
import TranslateHelper from '../../helper/TranslateHelper';
import './ContactMe.css';
import { v4 as uuidv4 } from 'uuid';

const ContactMe = (props) => {

    var { translate } = TranslateHelper();
    const [nameSurname, setNameSurname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [captcha, setCaptcha] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [isCaptchaOk, setIsCaptchaOk] = React.useState(false);
    const [captchaQuestion, setCaptchaQuestion] = React.useState({
        num1: 11,
        num2: 22,
        operation: "+",
        isRandom: false
    });


    const contactMe = () => {
        if (!isCaptchaOk)
            return;
        NetworkRequest("/Home/ContactMe", { nameSurname: nameSurname, email: email, message: message }).then((response) => {
            toast(translate(response.data.message), { type: response.data.resultCode == 0 ? 'success' : 'error', theme: 'dark', position: 'top-center', autoClose: 3500 });
            if (response.data.resultCode == 0) {
                setEmail("");
                setMessage("");
                setNameSurname("");
                setIsCaptchaOk(false);
                setCaptcha("");
            }
        });
    }

    const captchaOnChange = (e) => {
        if (!isNaN(e.target.value))
            setCaptcha(e.target.value);
        if (e.target.value == "")
            setIsCaptchaOk(false);
    }

    return (
        <div className="formContainer index4" id="contact">
            <div className="formStyle">
                <div className="form">
                    <div className="formHeader">
                        Demo için bize ulaşın
                    </div>
                    <input onChange={(e) => { setNameSurname(e.target.value) }}
                        value={nameSurname}
                        id="contactMeInput"
                        type="text" style={{ height: "50px" }} name="name" placeholder="Adınız - Soyadınız" />

                    <input onChange={(e) => { setEmail(e.target.value) }}
                        value={email}
                        id="contactMeInput"
                        type="text" style={{ height: "50px" }} name="email" placeholder="E-mail adresiniz" />

                    <TextArea
                        onChange={(e) => { setMessage(e.target.value) }}
                        value={message}
                        id="contactMeInput"
                        className=" textArea" type="text" style={{ height: "180px !important", backgroundColor: "white" }} name="message" placeholder="Mesajınız"
                    />

                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <BuddyReCaptcha setIsFormOk={setIsCaptchaOk} />
                        <button className="formSubmitBtn" disabled={!isCaptchaOk} onClick={() => contactMe()} type="submit" value="Gönder"
                            style={!isCaptchaOk ? { opacity: "0.7", cursor: "not-allowed" } : {}}
                        >Gönder</button>
                    </div>

                    <div style={{ width: "100%", display: "none" }}>
                        <div id="buddyCaptcha">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactMe;