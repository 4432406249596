import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Icon, Image, Input, TextArea, Accordion } from 'semantic-ui-react';
import '../Home.css';
import HomeV2Footer from '../HomeV2Components/HomeV2Footer';
import HomeV2Header from '../HomeV2Components/HomeV2Header';
import PartnerBanner from '../../../images/home/v2/partner-banner.png'
import PricingCard from '../HomeV2Components/PricingCard';
import { v4 as uuidv4 } from 'uuid';
import TranslateHelper from '../../../helper/TranslateHelper';
import {isLandscapeTablet} from "../../../helper/HelperFunctions";



type IPricingCard = {
    pricingHeader: string
    pricingText: string
    price: string
    priceFrequency: string
    noPrice: boolean
    billedYearly: boolean
    pricingCardInfoHeader: string
    pricingCardInfoDetailList: Array<string>
    buttonText: string
}

const Pricing = (props) => {

    var {translateFromEng} = TranslateHelper();
    
    const priceCardDataList: Array<IPricingCard> = [
        {
            pricingHeader: "Free",
            pricingText: "The perfect plan to get you started",
            price: "0",
            priceFrequency: "Month",
            noPrice: false,
            billedYearly: false,
            pricingCardInfoHeader: "Up tp 50 users",
            pricingCardInfoDetailList: ["2 Journey Design", "Reporting", "Unlimited email support"],
            buttonText: "Start Now"
        },
        {
            pricingHeader: "Standart",
            pricingText: "Get everything you need to create your own onboarding journey",
            price: "1",
            priceFrequency: "User x Month",
            noPrice: false,
            billedYearly: true,
            pricingCardInfoHeader: "Unlimited users",
            pricingCardInfoDetailList: ["All functions in Free Plan", "Unlimited Journey Design", "Single Sign On Integration", "Priority support"],
            buttonText: "Start with free plan"
        },
        {
            pricingHeader: "Enterprise",
            pricingText: "Need More? Request your annual plan",
            price: "",
            priceFrequency: "",
            noPrice: true,
            billedYearly: true,
            pricingCardInfoHeader: "Unlimited users",
            pricingCardInfoDetailList: ["Standart Plan Functions", "On Prem Installation", "Custom Login Page", "Custom Mobile App"],
            buttonText: "Start with free plan"
        }
    ]

    return (
        <Grid className={"homeV2Container"} columns={16}>
            <HomeV2Header />

            <Grid.Row columns={2} className="homeV2PricingBannerHeader">
                <Grid.Column computer={isLandscapeTablet() ? 16 : 8} mobile={16} tablet={16} className="homeV2BannerTextSectionContainer homeV2PricingBannerTextSectionContainer">
                    <Grid.Row centered>
                        <Grid.Column className="homeV2BannerTitle">
                            <div>{translateFromEng("No Hidden Cost in The Buddy App")}</div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <div className="homeV2BannerText mt4">{translateFromEng("Can't get simpler than that!")}</div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column className={"pricingPageBannerContainer"} style={{ height: "40vh" }} computer={isLandscapeTablet() ? 16 : 8} mobile={16} tablet={16}>
                    <Image className={"pricingBannerImageClass"} style={{ width: "100%", objectFit: "contain", height: "95.6%" }} src={PartnerBanner} />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row className={"pricingPagePricingSection"} columns={3} centered style={{ with: "90%", paddingBottom: "6%" }}>
                {priceCardDataList.map((item) => (
                    <Grid.Column key={uuidv4()} mobile={16} computer={5} tablet={9} className="pricingCardContainer">
                        <PricingCard
                            pricingHeader={translateFromEng(item.pricingHeader)}
                            pricingText={translateFromEng(item.pricingText)}
                            price={item.price}
                            priceFrequency={translateFromEng(item.priceFrequency)}
                            noPrice={item.noPrice}
                            billedYearly={item.billedYearly}
                            pricingCardInfoHeader={translateFromEng(item.pricingCardInfoHeader)}
                            pricingCardInfoDetailList={item.pricingCardInfoDetailList}
                            buttonText={translateFromEng(item.buttonText)}
                        />
                    </Grid.Column>
                ))}

            </Grid.Row>

            <Grid.Row columns={2}>
                <div className="pricingPContainer">
                    <div style={{ height:"65px" }} className="homeV2BannerTitle textCenter">{translateFromEng("Frequently Asked Questions")}</div>

                    <div className="pricingPageAskedQuestionContainer">
                        <div>
                            <div className="pricingSubTitle">{translateFromEng("What is Buddy App?")}</div>
                            <p>
                                {translateFromEng("Buddy App is a simple, modern tool for your onboarding needs. From one convenient place, you can track useful people analytics, organize onboarding, and manage employee data and company docs. And your employees have access to important information like policies.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("Who's Buddy App for?")}</div>
                            <p>
                                {translateFromEng("Buddy App is built for small to medium-sized businesses, no matter where they’re located across the world. With an intuitive design and rich customization options, Buddy App frees up HR managers' time and helps businesses organize their onboarding operations from a single platform.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("How much does Buddy App cost?")}</div>
                            <p>
                                {translateFromEng("For up to 50 users, Buddy App is free forever. As you scale, move to our paid plan with $1 / user per year.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("Does my plan come with free support?")}</div>
                            <p>
                                {translateFromEng("Yes! All plans include free, unlimited email support with a response guarantee of one business day.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("What’s the setup process like?")}</div>
                            <p>
                                {translateFromEng("Setting up your account at Buddy App is as simple as creating a new email address. No IT knowledge required or numerous calls with customer support. Sign up for free using your email and start adding your data. Depending on your company size, setup will take between a few hours and 2-3 business days.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("Do I need a credit card to create an account?")}</div>
                            <p>
                                {translateFromEng("No! Creating your account at Buddy App is as simple as creating a free email account. Simply set up your domain name, fill in your name and email address, choose a password, and you’re in! The free plan doesn’t have a time limit either. As you scale and need more than 50 users in your portal, upgrade your subscription and add your billing details.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("Can I upgrade, downgrade, or cancel at any time?")}</div>
                            <p>
                                {translateFromEng("Yes! Depending on your company needs, you can change plans or cancel your subscription any time you want. If you decide to cancel your paid plan, you won’t lose access to your portal and data.")}
                            </p>
                        </div>

                        <div>
                            <div className="pricingSubTitle">{translateFromEng("How secure is Buddy App?")}</div>
                            <p>
                                {translateFromEng("Buddy App is built on modern infrastructure and follows the latest privacy protocols to safeguard your sensitive company and employee data.")}
                            </p>
                        </div>


                    </div>

                </div>

            </Grid.Row>



            <HomeV2Footer />

        </Grid>
    )
}

export default Pricing;