import React, { useEffect, useRef, useState, useCallback } from 'react';
import { render } from '@testing-library/react';
import Cropper from 'react-easy-crop';
import { Button, Modal } from 'semantic-ui-react';
import TranslateHelper from '../helper/TranslateHelper';
import DefaultProfileImage from '../images/user.png';
import { getCroppedImg } from '../helper/HelperFunctions';
import { NetworkRequest } from '../helper/NetworkRequest';
import { toast } from 'react-toastify';



const DigitallicaImageCrop = (props) => {
    var { translate } = TranslateHelper();

    const [showLoadImgCrop, setShowLoadImgCrop] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState<any>(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);


    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                props.imageUrl || DefaultProfileImage,
                croppedAreaPixels
            );


            /*setCroppedImage(croppedImage);*/

            let data = {
                UserId: props.userId,
                ProfilePictureBase64: croppedImage
            }

            NetworkRequest("api/User/UpdateUserProfilePhoto", data, true).then((response) => {
                if (response.data.resultCode == 0) {
                    toast(translate(response.data.detail), {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });

                    var imageStr = "data:image/png;base64, " + croppedImage;

                    props.setImage(imageStr);

                } else {
                    toast(translate(response.data.detail), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                }

                setShowLoadImgCrop(false);
            });

        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, props.imageUrl || DefaultProfileImage]);


    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, []);


    return (
        <>
            <Button
                className={"mb-3 cropButton ingOrange"}
                circular={true}
                content={translate('Fotoğraf Kırp')}
                icon='crop'
                labelPosition='right'
                
                onClick={() => {
                    setShowLoadImgCrop(true);
                }}
            />
            <Modal
                style={{ height: "60vh" }}
                size={'large'}
                open={showLoadImgCrop}
            >
                <Modal.Content>

                    {/*<ReactCrop*/}
                    {/*    crop={crop}*/}
                    {/*    onChange={(crop, percentCrop) => setCrop(crop)}*/}
                    {/*    onComplete={(c) => setCompletedCrop(c)}*/}
                    {/*>*/}
                    {/*    <img*/}
                    {/*        ref={imgRef}*/}
                    {/*        src={props.imageUrl || DefaultProfileImage}*/}

                    {/*    />*/}
                    {/*</ReactCrop>*/}

                    <div style={{}}>
                        <Cropper
                            image={props.imageUrl || DefaultProfileImage}
                            crop={crop}
                            onCropChange={setCrop}
                            zoom={zoom}
                            zoomSpeed={0.5}
                            cropShape="round"
                            maxZoom={3}
                            zoomWithScroll={true}
                            showGrid={true}
                            aspect={1}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <div className={"userPhotoCropButtonClass"}>
                        <Button
                            color="green"
                            circular={true}
                            onClick={showCroppedImage}>
                            {translate("Kırp")}
                        </Button>

                        <Button
                            onClick={() => setShowLoadImgCrop(false)}
                            circular={true}>
                            {translate("İptal")}
                        </Button>
                    </div>
                   

                    {/*<Button circular={true} negative onClick={() => setShowLoadImgCrop(false)}>*/}
                    {/*    {translate('İptal')}*/}
                    {/*</Button>*/}
                    {/*<Button circular={true} positive onClick={() => { setShowLoadImgCrop(false); }}>*/}
                    {/*    {translate('Onay')}*/}
                    {/*</Button>*/}
                </Modal.Actions>
            </Modal>
        </>

    )
}

export default DigitallicaImageCrop;