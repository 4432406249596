import * as React from 'react';
import { useEffect } from 'react';
import { Tab } from 'semantic-ui-react'
import SettingsAll from '../SettingsAll/SettingsAll';
import WelcomeBant from '../../CommonPages/WelcomeBannerSettings';
import { useState } from 'react';
import LdapSettings from '../../CommonPages/LdapSettings';
import ThemeSettings from '../components/ThemeSettings';
import LanguageSettings from '../components/LanguageSettings';
import { useLocation } from 'react-router-dom';
import UserSettings from "../components/UserSettings";
import PasswordPolicySettings from "../components/PasswordPolicySettings";
import DocumentSettings from '../components/DocumentSettings';
import LogSettings from '../components/LogSettings';
import { DataHandler } from '../../../helper/DataHelper';
import ApiSettings from "../components/ApiSettings";
import IntegrationSettings from "../components/IntegrationSettings";
import TranslateHelper from "../../../helper/TranslateHelper";
import { v4 as uuidv4 } from 'uuid';
import AddTOUCPopup from '../PopupFirstLogin/AddTOUCPopup';

const Settings = () => {
    var { translate } = TranslateHelper();
    const location = useLocation();
    const [isDefaultSetting, setIsDefaultSetting] = useState(DataHandler.getUserDataWithKey("isDefaultSetting"));
    const [logoUrl, setLogoUrl] = useState(DataHandler.getUserDataWithKey("logoUrl"));
    const [siteTitle, setSiteTitle] = useState(DataHandler.getUserDataWithKey("siteTitle"));
    const [favIconUrl, setFavIconUrl] = useState(DataHandler.getUserDataWithKey("favIconUrl"));
    const [isSiteActive, setIsSsSiteActive] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);

    const selectMenu = () => {
        let pathname = (location.search).slice(1).split("=")[1];
        switch (pathname) {
            case "ldap":
                setActiveIndex(3)
                break;

            case "welcome":
                setActiveIndex(2)
                break;

            case "bant":
                setActiveIndex(1)
                break;

            case "general":
                setActiveIndex(0)
                break;

            case "theme":
                setActiveIndex(4)
                break;

            case "language":
                setActiveIndex(5)
                break;

            case "user":
                setActiveIndex(6)
                break;

            case "passwordPolicy":
                setActiveIndex(7)
                break;

            case "documentSettings":
                setActiveIndex(8)
                break;

            case "logSettings":
                setActiveIndex(9)
                break;

            case "apiSettings":
                setActiveIndex(10)
                break;

            case "integrationSettings":
                setActiveIndex(11)
                break;
        }
    }

    const panes = [
        {
            menuItem: { key: uuidv4(), content: translate('Ayarlar') },
            render: () => <Tab.Pane>
                <SettingsAll
                    isDefaultSetting={isDefaultSetting}
                    setIsDefaultSetting={setIsDefaultSetting}
                    setLogoUrl={setLogoUrl}
                    setFavIconUrl={setFavIconUrl}
                    setSiteTitle={setSiteTitle}
                    setIsSsSiteActive={setIsSsSiteActive}
                /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Bant Mesaj Ayarları') },
            render: () => <Tab.Pane><WelcomeBant /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Kullanım Koşulları Ayarları') },
            render: () => <Tab.Pane><AddTOUCPopup /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Ldap Ayarları') },
            render: () => <Tab.Pane><LdapSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Tema Ayarları') },
            render: () => <Tab.Pane><ThemeSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Dil Ayarları') },
            render: () => <Tab.Pane><LanguageSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Çalışan Ayarları') },
            render: () => <Tab.Pane><UserSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Şifre Ayarları') },
            render: () => <Tab.Pane><PasswordPolicySettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Doküman Ayarları') },
            render: () => <Tab.Pane><DocumentSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Log Ayarları') },
            render: () => <Tab.Pane><LogSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('API Ayarları') },
            render: () => <Tab.Pane><ApiSettings /></Tab.Pane>
        },
        {
            menuItem: { key: uuidv4(), content: translate('Entegrasyonlar') },
            render: () => <Tab.Pane><IntegrationSettings /></Tab.Pane>
        }
    ]

    useEffect(() => {
        selectMenu();
    }, []);

    useEffect(() => {
        selectMenu();
    }, [location.search]);

    return (
        <div className={"generalContainerForWhiteBg"}>
            <Tab
                className="settingsTabClass"
                activeIndex={activeIndex}
                menu={{ fluid: true, vertical: true }}
                panes={panes}
            />
        </div>
    );
}

export default Settings;