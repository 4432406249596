import * as React from 'react';
import {useState, useEffect, useCallback, useContext} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Button, Dropdown, Icon, Image, Input, Label} from 'semantic-ui-react';
import './HomeLogin.css';
import {NetworkRequest} from '../../helper/NetworkRequest';
import MobileHeader from './MobileHeader';
import Footer from './Footer';
import {toast} from 'react-toastify';
import {isMobile} from "react-device-detect";
import Wave from "react-wavify";
import {selectedLanguageContext} from "../../Store";
import {displayProtectedAppDoc} from '../../helper/DisplayProtectedDocument';
import {motion} from 'framer-motion';
import TranslateHelper from '../../helper/TranslateHelper';
import {displayName} from 'react-quill';
import {DataHandler} from '../../helper/DataHelper';
import {IDropdown} from '../../interfaces/interfaces';
import {isLandscapeTablet, isNullOrEmpty} from '../../helper/HelperFunctions';
import Logo from '../../images/home/v2/logo_standart.png';
import People from '../../images/home/v2/login.png';
import newTenant from "./NewTenant";


const NewTenant = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    var {translateFromEng} = TranslateHelper();

    const navigate = useNavigate();

    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [languageId, setLanguageId] = useState();
    const [newTenant, setNewTenant] = useState<any>({displayName: ""});
    const [admin, setAdmin] = useState<any>();
    const [dropdownLanguages, setDropdownLanguages] = useState<Array<IDropdown>>([]);

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            createNewTenant();
        }
    }

    const handleChange = (e) => {
        setNewTenant({
            ...newTenant,
            [e.target.name]: e.target.value
        });
    }

    const getLanguages = () => {
        setIsLoading(true);
        NetworkRequest("/api/Language/GetActiveLanguages", newTenant).then(response => {
            if (response.data.resultCode === 0) {
                let dropdownlangs = response.data.detail.map(elem => (
                    {
                        text: elem.text + " (" + elem.key + ")",
                        key: elem.languageId,
                        value: elem.languageId
                    }
                ));
                setDropdownLanguages(dropdownlangs);
            }
        });
    }

    function isBusinessEmail(email) {

        const businessDomain = ["gmail.com", "yahoo.com", "hotmail.com", "yahoo.co.in", "aol.com", "live.com", "outlook.com", "outlook.com.tr"]; // Örnek olarak "example.com" değiştirin.
        const lowercaseEmail = email.toLowerCase();
        const emailDomain = lowercaseEmail.split('@')[1];


        return !businessDomain.includes(emailDomain);
    }

    const createNewTenant = () => {
        setIsSaveLoading(true);

        if (languageId == undefined) {
            toast(translateFromEng("You must select the language."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3000
            });
            setIsSaveLoading(false);
            return;
        }
        if (isNullOrEmpty(newTenant.displayName)) {
            toast(translateFromEng("You must enter the tenant name."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3000
            });
            setIsSaveLoading(false);
            return;
        }

        if (isNullOrEmpty(newTenant.email)) {
            toast(translateFromEng("You must enter the email."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3000
            });
            setIsSaveLoading(false);
            return;
        } else {
            if (!isBusinessEmail(newTenant.email)) {
               /* debugger;*/
                toast(translateFromEng("You must enter a bussiness email."), {
                    type: 'warning',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
                setIsSaveLoading(false);
                return;
            }
        }


        newTenant.languageId = languageId;
    /*    debugger;*/
        NetworkRequest("/api/Tenants/Add", newTenant).then(response => {
         /*   debugger;*/
            if (response.data.resultCode === 0) {
                toast(translateFromEng("Success"), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
                setAdmin(response.data.detail);
            } else {
                toast(response.data.message, {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
            }

            setIsSaveLoading(false);
        });
    }

    const redirectByUserRole = (userRole: string, isActive: boolean, isHaveMoreThanOneModule: boolean) => {
        if (userRole == "User" && !isActive) {
            window.location.href = "/siteOff";
            return;
        }
        if (isHaveMoreThanOneModule) {
            window.location.href = "/home";
            return;
        }

        window.location.href = "/";
    }

    const login = () => {
        setIsSaveLoading(true);
        let request = {
            Email: admin.userName,
            Password: admin.password,
            rememberMe: false,
            tenantName: admin.tenantName
        }
        NetworkRequest("/Authentication/Login", request).then(response => {

            if (response.data.resultCode === 0) {
                let userData = response.data.detail;
                setSelectedLanguage(userData.userLanguage);

                DataHandler.removePages();
                DataHandler.pushLoginData(userData);

                setTimeout(() => {
                    redirectByUserRole(userData.userRole[0], userData.isActive, userData.isHaveMoreThanOneModule);
                }, 2000);

            } else {
                toast(translateFromEng(response.data.message), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
                setIsSaveLoading(false);
            }
        });
    }

    const changeDropdown = (event, data) => {
        setLanguageId(data.value);
    }

    useEffect(() => {
        getLanguages();

        var email = searchParams.get("email");
        var company = searchParams.get("company");

        setNewTenant({displayName: company, email: email});
    }, []);

    return (
        <div>
            <div className="loginMobileBg">
                <div className="loginBgImageContent">
                    <img className="loginBgImageContentImage" src={People} alt=""/>
                </div>
                {!isMobile
                    &&
                    <MobileHeader/>
                }
                <div className={"infoContentNewTenant"}>
                    <div className="formContainerLogin">
                        <div className="formStyleLogin formStyleLoginNewTenantClass">
                            <div className="loginScreenForm">
                                <motion.div
                                    style={{zIndex: "9"}}
                                    className="loginFormElements"
                                    animate={{
                                        marginLeft: ["-10px", "0px"]
                                    }}
                                    transition={{duration: 1, delay: .4}}
                                >
                                    <div className="logoContainerLogin">
                                        <Link to="/home">
                                            <Image src={Logo} className={"newTenantWebLogo"}/>
                                        </Link>

                                        {/*<a href="/home">*/}
                                        {/*    <Image id="logoLoginImage"*/}
                                        {/*        source={displayProtectedAppDoc("/Documents/Buddy/images/BUDDY_LOGO_mavi.png", "logoLoginImage")}*/}
                                        {/*        className="homeBuddyLogoLogin"*/}
                                        {/*    />*/}
                                        {/*</a>*/}
                                    </div>
                                </motion.div>
                                {(isMobile || isLandscapeTablet())
                                    &&
                                    <Link to={"/"}>
                                        <div className="width100 thisIsCenter newTenantLogoImageClass">
                                            <Image id="logoLoginImage-2"
                                                   src={Logo}
                                                   width="60%"
                                            />
                                        </div>
                                    </Link>
                                }
                                {/*{isMobile*/}
                                {/*    &&*/}
                                {/*    <Wave*/}
                                {/*        fill="#ffffff"*/}
                                {/*        paused={false}*/}
                                {/*        options={{*/}
                                {/*            height: 20,*/}
                                {/*            amplitude: 20,*/}
                                {/*            speed: 0.15,*/}
                                {/*            points: 4*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*}*/}
                                <div className="loginFormElements">
                                    {admin == undefined
                                        &&
                                        <motion.div
                                            className="loginFormElements"
                                            animate={{marginTop: ["0px", "-10px"]}}
                                            transition={{duration: .8}}
                                        >
                                            <div className={"newTenantInputContainer"}>
                                                <div
                                                    className="loginScreenInputText">{translateFromEng('Company Name')}</div>
                                                <Input
                                                    className="greyInput"
                                                    size="huge"
                                                    value={newTenant.displayName}
                                                    onChange={handleChange}
                                                    name="displayName"
                                                    onKeyUp={onKeyUp}
                                                    id="tenantNameInput"
                                                    placeholder={translateFromEng('Company Name')}
                                                />
                                            </div>
                                            <div className={"newTenantInputContainer"}>
                                                <div className="loginScreenInputText">{"Email"}</div>
                                                <Input
                                                    className="greyInput"
                                                    size="huge"
                                                    value={newTenant.email}
                                                    onChange={handleChange}
                                                    name="email"
                                                    type={"email"}
                                                    onInvalid={() => {
                                                        alert("Please enter business email")
                                                    }}
                                                    pattern="^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
                                                    onKeyUp={onKeyUp}
                                                    id="tenantNameInput"
                                                    placeholder={translateFromEng('Email')}
                                                />
                                            </div>
                                            <div className={"newTenantInputContainer"}>
                                                <div
                                                    className="loginScreenInputText">{translateFromEng('Select Language')}</div>
                                                <div>
                                                    <Dropdown
                                                        style={{padding: "7%"}}
                                                        fluid
                                                        selection
                                                        clearable
                                                        className="greyInput"
                                                        name="userRoles"
                                                        size=""
                                                        placeholder={translateFromEng('Select Language')}
                                                        options={dropdownLanguages}
                                                        value={languageId}
                                                        onChange={changeDropdown}
                                                    />
                                                </div>
                                            </div>

                                            <div className="loginScreenInputContainer">
                                                <Button
                                                    disabled={isSaveLoading}
                                                    loading={isSaveLoading}
                                                    circular={true}
                                                    fluid={isMobile}
                                                    style={{backgroundColor: "#ff4290", color: "white"}}
                                                    size="huge"
                                                    onClick={createNewTenant}
                                                >
                                                    {translateFromEng('Save')}
                                                </Button>
                                            </div>

                                            <div>
                                                {isMobile || isLandscapeTablet()
                                                    &&
                                                    <div style={{textAlign: "center"}}>
                                                         <span><Icon title={"Refresh Page"} name="redo"
                                                                     style={{color: "#4183c4"}} onClick={() => {
                                                             window.location.reload();
                                                         }}/> {translateFromEng('Refresh page')}
                                                        </span>
                                                        <div className={"newTenantBackButton"}
                                                             style={{marginTop: "20%"}}>
                                                            <Link to={"/"}>{translateFromEng('Back')}</Link>
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                        </motion.div>
                                    }
                                    {admin != undefined
                                        &&
                                        <motion.div
                                            className="loginFormElements"
                                            animate={{marginTop: ["0px", "-10px"]}}
                                            transition={{duration: .8}}
                                        >
                                            <div>
                                                <h4>{translateFromEng('You can log in with the user information below.')}</h4>
                                                <h5 className="colorOrange"> {translateFromEng('We recommend that you change your password after logging into the application.')}</h5>
                                                <h5 className="colorRed">{translateFromEng('Please do not forget to save your username and password.')}</h5>
                                            </div>
                                            <div>
                                                <div
                                                    className="loginScreenInputText">{translateFromEng('Username')}</div>
                                                <Input
                                                    className="greyInput"
                                                    size="huge"
                                                    value={admin.userName || ""}
                                                    id="adminNameInput"
                                                    onChange={() => {
                                                        setAdmin(admin);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <div
                                                    className="loginScreenInputText">{translateFromEng('Password')}</div>
                                                <Input
                                                    className="greyInput"
                                                    size="huge"
                                                    value={admin.password || ""}
                                                    id="adminPassInput"
                                                    onChange={() => {
                                                        setAdmin(admin);
                                                    }}
                                                />
                                            </div>

                                            <div className="loginScreenInputContainer">
                                                <Button
                                                    disabled={isSaveLoading}
                                                    loading={isSaveLoading}
                                                    circular={true}
                                                    fluid={isMobile}
                                                    style={{backgroundColor: "#ff4290", color: "white"}}
                                                    size="huge"
                                                    onClick={login}
                                                >
                                                    {translateFromEng('Login')}
                                                </Button>
                                            </div>

                                            <div>
                                                {isMobile
                                                    &&
                                                    <span> | <Icon title={"Refresh Page"} name="redo"
                                                                   style={{color: "#4183c4"}} onClick={() => {
                                                        window.location.reload();
                                                    }}/>
                                                    </span>
                                                }
                                            </div>
                                        </motion.div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NewTenant;