import * as React from 'react';
import { Image } from 'semantic-ui-react';
import './Home.css';
import './Packets.css';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ContactMe from './ContactMe';
import HomeHeader from './HomeHeader';
import MobileHeader from './MobileHeader';
import Footer from './Footer';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';

const Packets = (props) => {
    return (
        <div className="paketsContainer">

            <HomeHeader page="packets" />
            <MobileHeader />
            <div className="content">
                <div className="header">Üyelik Seçenekleri
                </div>
                <div className="text" id="textM0" style={{ marginRight: "0 !important" }}>
                    Şeffaf fiyatlandırma ile sürpriz maliyetlerle karşılaşmayacağınız seçeneklerimiz <br />
                    sizin için özel olarak oluşturulmuştur.
                </div>
            </div>
            <div className="packets">
                <div className="flagContainer">
                    <Image id="desktop_paket1" source={displayProtectedAppDoc('/Documents/Buddy/images/desktop_paket1.png', 'desktop_paket1')} />
                    <div className="header">Başlangıç
                    </div>
                    <div className="texts">
                        <div className="text">
                            100 Kullanıcı
                        </div>
                        <div className="text">
                            3 Yolculuk Tasarımı
                        </div>
                        <div className="text">
                            Sınırsız Destek
                        </div>
                    </div>
                </div><div className="flagContainer">
                    <Image id="desktop_paket2" source={displayProtectedAppDoc('/Documents/Buddy/images/desktop_paket2.png', 'desktop_paket2')} />
                    <div className="header">Basic
                    </div>
                    <div className="texts">
                        <div className="text">
                            250 Kullanıcı
                        </div>
                        <div className="text">
                            10 Yolculuk Tasarımı
                        </div>
                        <div className="text">
                            Sınırsız Destek
                        </div>
                        <div className="text">
                            SSO Entegrasyonu
                        </div>
                    </div>

                </div>
                <div className="flagContainer">
                    <Image id="desktop_paket3" source={displayProtectedAppDoc('/Documents/Buddy/images/desktop_paket3.png', 'desktop_paket3')} />

                    <div className="header">Premium
                    </div>
                    <div className="texts">
                        <div className="text">
                            500 Kullanıcı
                        </div>
                        <div className="text">
                            50 Yolculuk Tasarımı
                        </div>
                        <div className="text">
                            Sınırsız Destek
                        </div>
                        <div className="text">
                            SSO Entegrasyonu
                        </div>
                        <div className="text">
                            Özel Domain + SSL
                        </div>
                    </div>
                </div>
                <div className="flagContainer">
                    <Image id="desktop_paket4" source={displayProtectedAppDoc('/Documents/Buddy/images/desktop_paket4.png', 'desktop_paket4')} />
                    <div className="header">Enterprise
                    </div>
                    <div className="texts">
                        <div className="text">
                            Sınırsız Kullanıcı
                        </div>
                        <div className="text">
                            Sınırsız Yolculuk Tasarımı
                        </div>
                        <div className="text">
                            Sınırsız Destek
                        </div>
                        <div className="text">
                            SSO Entegrasyonu
                        </div>
                        <div className="text">
                            Özel Domain + SSL
                        </div>
                        <div className="text">
                            Kuruma Özel Entegrasyonlar
                        </div>
                    </div>

                </div>
            </div>
            <div className="contactMeContainer">
                <ContactMe />
            </div>
            <div className="footerPackets">
                <Footer />
            </div>
        </div >
    );
}

export default Packets;