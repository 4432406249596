import * as React from 'react';
import { useEffect } from 'react';

import {Grid, Image} from 'semantic-ui-react';
import '../Home.css';
import HomeV2Footer from '../HomeV2Components/HomeV2Footer';
import HomeV2Header from '../HomeV2Components/HomeV2Header';
import { Helmet } from "react-helmet";
import Banner from "../../../images/home/v2/requestdemo.png";
import TranslateHelper from '../../../helper/TranslateHelper';
import {isLandscapeTablet} from "../../../helper/HelperFunctions";


const RequestDemo = (props) => {
    var {translateFromEng} = TranslateHelper();

    
    return (
        <Grid className={"homeV2Container"}>
            <HomeV2Header />

            <Grid.Row columns={2} className="homeV2DemoBannerHeader">
                <Grid.Column computer={isLandscapeTablet() ? 16 : 8} mobile={16} tablet={16} className="homeV2DemoBannerContainer">
                    <Grid.Row centered>
                        <Grid.Column className="homeV2BannerTitle">
                            <div>{translateFromEng("Schedule a personalized 1:1")}</div>
                        </Grid.Column>
                    </Grid.Row>

                   
                </Grid.Column>
                <Grid.Column className={"requestDemoBannerContainer"} computer={isLandscapeTablet() ? 16 : 8} mobile={16} tablet={16}>
                    <Image className={"requestDemoBannerImageClass"} style={{ width: "auto", objectFit: "contain", height: "120%" }} src={Banner} />
                </Grid.Column>
            </Grid.Row>
            
            <Grid.Row columns={1} className="homeV2RequestBannerContainer">
                <Grid.Column computer={16}>
                    <div className={"requestDemoDescContainer"}>
                        <p>{translateFromEng("Talk to a Buddy App expert about how we can help with your organization's unique people management needs.")}</p>
                    </div>
                    <div className="responsive-iframe-container">
                        <div
                            className="calendly-inline-widget"
                            data-url="https://calendly.com/usebuddyapp?hide_landing_page_details=1&hide_gdpr_banner=1"
                            style={{ minWidth: "320px", height: "700px", marginTop: "-57%" }}>
                        </div>
                        <Helmet>
                            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
                        </Helmet>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <HomeV2Footer />
        </Grid>
    );
}

export default RequestDemo;