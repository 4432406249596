import * as React from 'react';

import { DataHandler } from '../../helper/DataHelper';
import { NetworkRequest } from '../../helper/NetworkRequest';
import TranslateHelper from "../../helper/TranslateHelper";

const WordImportExcelOutline = () => {
    var { translate } = TranslateHelper();
    const [tenantId] = React.useState(DataHandler.getUserDataWithKey("tenantId"));
    const [viewExcel, setViewExcel] = React.useState("");
    let styles = {

        importContainer: {
            display: "flex",
            width: "100%",
            justifyContent: "center"
        }
    }

    var excelExample = [];

    React.useEffect(() => {
        if (tenantId != undefined)
            generateExcelFormat();

    }, [tenantId]);


    const generateExcelFormat = () => {
        var data = {
            id: tenantId,
        };

        NetworkRequest('api/DictionaryWord/GenerateExcelFormat', data).then((response) => {
            var tds: any = [<td>Kelime</td>, <td>{translate('Açıklama')}</td>, <td>Kısaltma</td>, <td>Ses</td>];
            let result = response.data.detail;
            for (let l = 0; l < result.length; l++) {
                tds.push(
                    <td>{result[l]}</td>
                );
            }
            setViewExcel(tds);
        });
    }

    return (
        <div style={styles.importContainer}>
            <div className={"add-dictionary-button-box excel-box"}>
                <h5 className={"excel-header"}>Bu sözlüğe import edebileceğiniz excel formatı aşağıdaki gibi olmalıdır.
                    <br />
                    Yukarıdan indirebileceğiniz excel taslak dosyasına 'Ses' kolunundan itibaren, sözlüğünüzdeki dilleri aşağıdaki gibi sırasıyla eklemelisiniz.
                </h5>

                <table className={"excel-format-table"}>{viewExcel}</table>
            </div>
        </div>
    )
}

export default WordImportExcelOutline;