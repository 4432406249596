import * as React from 'react';
import {Button, Grid, Icon, Image, Input, TextArea, Accordion, GridRow} from 'semantic-ui-react';
import '../Home.css';
import HomeV2Footer from '../HomeV2Components/HomeV2Footer';
import HomeV2Header from '../HomeV2Components/HomeV2Header';
import PartnerBanner from '../../../images/home/v2/partner-banner.png'
import AboutUsBox from '../HomeV2Components/AboutUsBox';
import {v4 as uuidv4} from 'uuid';
import ContactUsForm from '../HomeV2Components/ContactUsForm';
import '../HomeV2PagesStyles/AboutUs.css';
import {useEffect} from "react";
import * as d3 from 'd3'
import {feature} from 'topojson';
import ScrollDown from '../../../images/home/v2/scroll-bar.png';
import {motion} from 'framer-motion';
import TranslateHelper from '../../../helper/TranslateHelper';

const AboutUs = (props) => {
    // const topojson = require('topojson');
    var {translateFromEng} = TranslateHelper();
    let worldJson = require('./world-110m.json');

    useEffect(() => {

        var width = 500,
            height = 500;

        var centroid = d3.geo.path()
            .projection(function (d) {
                return d;
            })
            .centroid;

        var projection = d3.geo.orthographic()
            .scale(height / 2.0)
            .translate([width / 2, height / 2])
            .clipAngle(90);

        var path = d3.geo.path()
            .projection(projection);

        var graticule = d3.geo.graticule()
            .extent([[-180, -90], [180 - .1, 90 - .1]]);

        var svg = d3.select(".wordSectionContent").append("svg")
            .attr("width", width)
            .attr("height", height);

        svg.append("circle")
            .attr("class", "world-outline")
            .attr("cx", width / 2)
            .attr("cy", height / 2)
            .attr("r", projection.scale());

        var rotate = d3_geo_greatArcInterpolator();


        d3.json(worldJson, function (error, world) {

            var countries1 = feature(worldJson, worldJson.objects.countries)
            var countries = countries1.features;

            var i = -1,
                n = countries.length;

            projection.clipAngle(180);

            var backLine = svg.append("path")
                .datum(graticule)
                .attr("class", "back-line")
                .attr("d", path);

            var backCountry = svg.selectAll(".back-country")
                .data(countries)
                .enter().insert("path", ".back-line")
                .attr("class", "back-country")
                .attr("d", path);

            projection.clipAngle(90);

            var line = svg.append("path")
                .datum(graticule)
                .attr("class", "line")
                .attr("d", path);

            var country = svg.selectAll(".country")
                .data(countries)
                .enter().insert("path", ".line")
                .attr("class", "country")
                .attr("d", path);

            var title = svg.append("text")
                .attr("x", width / 2)
                .attr("y", height * 3 / 5);

            step();

            function step() {
                if (++i >= n) i = 0;

                // title.text(countries[i].id);

                country.transition()
                    .style("fill", function (d, j) {
                        return j === i ? "#ff0280" : "#cccccc";
                    });

                d3.transition()
                    .delay(250)
                    .duration(1250)
                    .tween("rotate", function () {
                        var point = centroid(countries[i]);
                        rotate.source(projection.rotate()).target([-point[0], -point[1]]).distance();
                        return function (t) {
                            projection.rotate(rotate(t)).clipAngle(180);
                            backCountry.attr("d", path);
                            backLine.attr("d", path);

                            projection.rotate(rotate(t)).clipAngle(90);
                            country.attr("d", path);
                            line.attr("d", path);
                        };
                    })
                    .transition()
                    .each("end", step);
            }
        });

        var d3_radians = Math.PI / 180;

        function d3_geo_greatArcInterpolator() {
            var x0, y0, cy0, sy0, kx0, ky0,
                x1, y1, cy1, sy1, kx1, ky1,
                d,
                k;

            function interpolate(t) {
                var B = Math.sin(t *= d) * k,
                    A = Math.sin(d - t) * k,
                    x = A * kx0 + B * kx1,
                    y = A * ky0 + B * ky1,
                    z = A * sy0 + B * sy1;
                return [
                    Math.atan2(y, x) / d3_radians,
                    Math.atan2(z, Math.sqrt(x * x + y * y)) / d3_radians
                ];
            }

            interpolate.distance = function () {
                if (d == null) k = 1 / Math.sin(d = Math.acos(Math.max(-1, Math.min(1, sy0 * sy1 + cy0 * cy1 * Math.cos(x1 - x0)))));
                return d;
            };

            interpolate.source = function (_) {
                var cx0 = Math.cos(x0 = _[0] * d3_radians),
                    sx0 = Math.sin(x0);
                cy0 = Math.cos(y0 = _[1] * d3_radians);
                sy0 = Math.sin(y0);
                kx0 = cy0 * cx0;
                ky0 = cy0 * sx0;
                d = null;
                return interpolate;
            };

            interpolate.target = function (_) {
                var cx1 = Math.cos(x1 = _[0] * d3_radians),
                    sx1 = Math.sin(x1);
                cy1 = Math.cos(y1 = _[1] * d3_radians);
                sy1 = Math.sin(y1);
                kx1 = cy1 * cx1;
                ky1 = cy1 * sx1;
                d = null;
                return interpolate;
            };

            return interpolate;
        }
    }, []);

    const aboutUsBoxItems = [
        {title: "70K+ Onboardees / Year"},
        {title: "20+ Supported Language"},
        {title: "100+ Global Customer"},
    ]

    return (
        <Grid className={"homeV2Container"} columns={16}>
            <HomeV2Header/>

            <Grid.Row columns={2} className="homeV2PricingBannerHeader" centered>
                <Grid.Column style={{height: "40vh"}} computer={8} mobile={14} textAlign="center">
                    <Image className={"aboutUsBannerContainer"}
                           style={{width: "100%", objectFit: "contain", height: "95.6%"}} src={PartnerBanner}/>
                    <div className="homeV2AboutUsFormContainer">
                        <div className="homeV2AboutBannerTitle">{translateFromEng("Got a Question?")}</div>
                        <ContactUsForm/>
                        {/*<Input className="parnerInput" type="text" placeholder="Full Name" size="huge" />*/}
                        {/*<Input className="parnerInput" type="email" placeholder="Email" size="huge" />*/}
                        {/*<Input className="parnerInput" type="text" placeholder="Country" size="huge" />*/}
                        {/*<TextArea className="parnerInput" placeholder='Start the conversation' style={{ minHeight: 160, with: "100%" }} />*/}
                        {/*<Button size="big" className="homeV2StartedButton textColorWhite" circular>Apply Now</Button>*/}
                    </div>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row centered className={"aboutUsScrollDownIcomContainer"}>
                <motion.div
                    style={{position:"relative" as "relative"}}
                    initial={{marginTop: "0%"}}
                    
                    animate={{
                        top: ["0%", "10%", "0%"]
                    }}
                    transition={{ repeat: Infinity, duration: 1.5}}
                >
                    <img width={"100%"} height={"auto"} src={ScrollDown} alt="scroll-down-icon"/>
                </motion.div>
            </Grid.Row>

            <Grid.Row columns={2}>
                <div className="aboutUsPContainer">
                    <div style={{height: "65px"}} className="pricingSubTitleAbout">{translateFromEng("About Us")}</div>

                    <div className="pricingPageAskedQuestionContainer">
                        <div>
                            <p>
                                {translateFromEng("Buddy App is delivered by the EdTech company Digitallica LLC. The founders are dedicated learning professionals with many years of experience within the digital learning industry.")}
                            </p>
                            <p>
                                {translateFromEng("After completing numerous onboarding programs for various clients, they found that there was a need for a process-driven, lightweight pre-boarding solution to bridge the gap between the day of decision/signing and the first day at the company and beyond.")}
                            </p>
                        </div>

                        <div className={"aboutUsAnimationWorldSection"}>
                            <div className="aboutUsBoxContainer">
                                {aboutUsBoxItems.map((item) => (
                                    <AboutUsBox title={translateFromEng(item.title)} key={uuidv4()}/>
                                ))}
                            </div>
                            <div className={"wordSectionContent"}>

                            </div> 
                        </div>

                    </div>

                </div>

            </Grid.Row>

            <HomeV2Footer/>

        </Grid>
    )
        ;
}

export default AboutUs;