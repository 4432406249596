import * as React from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Footer.css';
import { Image } from 'semantic-ui-react';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';

const Footer = (props) => {

    return (
        <div className="mainFooter">
            <div className="footer">
                <div className="logoAndIcon">
                    <Image id="budLogoImage" source={displayProtectedAppDoc("/Documents/Buddy/images/BUDDY_LOGO_beyaz.png", "budLogoImage")} className="footerLogo" />
                    <div className="footerIcon">
                        <a>
                            <Image id="googleImage" source={displayProtectedAppDoc("/Documents/Buddy/images/google.png", "googleImage")} className="google" />
                        </a> &nbsp; &nbsp;
                        <a>
                            <Image id="appleImage" source={displayProtectedAppDoc("/Documents/Buddy/images/appstore.png", "appleImage")} className="apple" />
                        </a>
                    </div>
                    <div className="socialMediaMobil">
                        <a>
                            <Image id="linkMobilImage" source={displayProtectedAppDoc("/Documents/Buddy/images/linkedin.png", "linkMobilImage")} className="linkMobil" />
                        </a>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <a>
                            <Image id="instagramMobilImage" source={displayProtectedAppDoc("/Documents/Buddy/images/instagram.png", "instagramMobilImage")} className="instagramMobil" />
                        </a>
                        <a>
                            <Image id="googleImage-2" source={displayProtectedAppDoc("/Documents/Buddy/images/google.png", "googleImage-2")} className="google" />
                        </a> &nbsp; &nbsp;
                        <a>
                            <Image id="appleImage-2" source={displayProtectedAppDoc("/Documents/Buddy/images/appstore.png", "appleImage-2")} className="apple" />
                        </a>
                    </div>
                </div>
                <div className="footerText" id="textMargin">
                    <p className="title">Adres:</p>
                    <p className="footerDescription">Akasya Towers Acıbadem Mah. Çeçen Sok.</p>
                    <p className="footerDescription">No:25A A1 Blok D:150 Üsküdar</p>
                    <p className="title">E-posta:</p>
                    <p className="footerDescription">hello@usebuddyapp.com</p>
                    <p className="title">Telefon:</p>
                    <p className="footerDescription">+905332102710</p>
                    
                </div>
            </div>
            <div className="footerPolicy">
                <p className="secret">Copyright 2022 Buddy | All Rights Reserved | Gizlilik koruması ve çerez kullanımı | Site kullanım koşulları ve kişisel verilerin işlenmesi</p>
                <p className="secret1">Copyright 2022 Buddy | All Rights Reserved</p>
                <p className="secret2">Gizlilik koruması ve çerez kullanımı</p>
                <p className="secret3">Site kullanım koşulları ve kişisel verilerin işlenmesi</p>
                <div className="socialMedia">
                    <a href="https://www.instagram.com/usebuddyapp" target="_blank">
                        <Image id="linkedinImage" source={displayProtectedAppDoc("/Documents/Buddy/images/linkedin.png", "linkedinImage")} className="iconFooterLink" />
                    </a>                    
                    <a href="https://www.instagram.com/usebuddyapp" target="_blank">
                        <Image id="iconFooterInstaImage" source={displayProtectedAppDoc("/Documents/Buddy/images/instagram.png", "iconFooterInstaImage")} className="iconFooterInsta" />
                    </a>                   
                </div>
            </div>
        </div>
       
    );
}

export default Footer;