import React, { useEffect, useState } from 'react';
import {Button, Checkbox, Icon, Input, Label, Message, Popup, Transition} from "semantic-ui-react";
import TranslateHelper from '../../../helper/TranslateHelper';
import { NetworkRequest } from "../../../helper/NetworkRequest";
import { toast } from "react-toastify";
import { DataHandler } from "../../../helper/DataHelper";

import { motion } from 'framer-motion';

const ApiSettings = () => {
    var { translate } = TranslateHelper();
    const [tenantId, setTenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [isActiveApi, setIsActiveApi] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [copyIsStarted, setCopyIsStarted] = useState(false);

    const updateApiStatus = (e, data) => {
        let apiData = {
            tenantId: tenantId,
            key: 'API',
            value: data.checked.toString()
        };

        NetworkRequest('/api/ApiSettings/AddOrUpdateSetting', apiData).then((response) => {
            var result = response.data.detail;
            if (response.data.resultCode == 0) {
                setIsActiveApi(data.checked);
                setUsername(result.userView.userName);
                setPassword(result.userView.password);

                toast(translate(data.checked ? translate("API etkinleştirildi") : translate("API Kullanımı kapandı")),
                    { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
            }
        });
    }

    const reGenerateUser = () => {
        setIsGenerating(true);
        toast(translate(translate("Yeni API kullanıcısı oluşturuluyor")), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 2000 });

        if (!isActiveApi) {
            toast(translate(translate("Lütfen API'ı etkinleştirin")), { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2000 });
            return;
        }

        let apiData = {
            tenantId: tenantId,
            key: 'API',
        };

        NetworkRequest('/api/ApiSettings/ReGenerateUser', apiData).then((response) => {
            if (response.data.resultCode == 0) {
                var result = response.data.detail;
                setUsername(result.userName);
                setPassword(result.password);

                toast(translate(translate("Yeni API kullanıcısı oluşturuldu")), { type: 'success', theme: 'dark', position: 'top-center', autoClose: 2000 });
                setIsGenerating(false);
            }
        });
    }

    const getStatus = () => {
        let apiData = {
            tenantId: tenantId,
            key: 'API',
        };

        NetworkRequest('/api/ApiSettings/GetSetting', apiData).then((response) => {
            var result = response.data;

            if (response.data.resultCode == 0) {
                setIsActiveApi(result.detail.setting.settingValue == "true");
                setUsername(result.detail.userView.userName);
                setPassword(result.detail.userView.password);
            }
        });
    }

    const copyValue = (val) => {
        setCopyIsStarted(true);
        navigator.clipboard.writeText(val);

        setTimeout(() => {
            setCopyIsStarted(false);
        }, 2000)
    };

    useEffect(() => {
        getStatus();
    }, [])

    return (
        <div className="documentSettingsContainer">
            <motion.div
                className="apiSettingsCopiedAlert"
                animate={copyIsStarted ? {
                    opacity: [0, 1],
                    marginTop: ["0%", "3%"]
                } : {
                    opacity: [1, 0],
                    marginTop: ["3%", "0%"]
                }}
                transition={{ duration: .5 }}
            >
                <Icon size="big" name="copy" />
                <span>{translate("Kopyalandı")}</span>
            </motion.div>
            <h1>{translate("API ayarları")}</h1>
            <div>
                <div className="apiSettingsCheckboxContainer">
                    <div>
                        <Checkbox
                            disabled={isGenerating}
                            checked={isActiveApi}
                            onChange={updateApiStatus}
                            toggle
                            label={translate("API'ı etkinleştir")}
                        />
                    </div>
                    <div>
                        <Popup
                            trigger={<Icon color="blue" circular name='info' />}
                            content={translate("API'ler iki sistemin birbiriyle konuşmasını sağlar. Web sitenizin Buddy hesabınızla entegre olması ve veri alışverişi yapması için API'mizi kullanabilirsiniz.")}
                            size='mini'
                        />
                    </div>
                </div>


                <div className="apiSettingsUserInfoContainer">
                    <div className="apiSettingsUserInfo">
                        <label>{translate("Çalışan adı")}</label>
                        <Input
                            icon='user'
                            iconPosition='left'
                            className="greyInput"
                            value={username || ""}
                            action={{
                                color: 'teal',
                                labelPosition: 'right',
                                icon: 'copy',
                                content: 'Copy',
                                onClick: () => { copyValue(username) }
                            }}
                            disabled={!isActiveApi}
                        />
                    </div>

                    <div className="apiSettingsUserInfo">
                        <label>{translate("Şifre")}</label>
                        <Input
                            style={{ width: "65%" }}
                            icon='key'
                            iconPosition='left'
                            className="greyInput"
                            value={password || ""}
                            action={{
                                color: 'teal',
                                labelPosition: 'right',
                                icon: 'copy',
                                content: 'Copy',
                                onClick: () => { copyValue(password) }
                            }}
                            disabled={!isActiveApi}
                        />
                    </div>
                </div>

                <div className="mt6">

                    <Message warning>
                        <Message.Header>{translate("Uyarı")}</Message.Header>
                        <p>{translate("API kullanıcınıza ait şifreyi sadece yeni bir tane üreterek görüntüleyebilirsiniz. Bu sayfayı daha sonra tekrar ziyaret ettiğinizde şifreyi tekrar görüntüleme imkanınız olmayacaktır. API kullanıcınızı oluşturduktan sonra lütfen şifre bilginizi güvenli bir şekilde kaydediniz.")}</p>
                    </Message>
                    
                    <div className={"mt3"}>
                        <span>{translate("API dökümantasyonunu")}
                            <a
                                target="_blank"
                                href="https://docs.google.com/document/d/1aGmaLHxfgQS783azTFE1vGG8LmisThgtn5HWydLshy8/edit?usp=sharing"
                                className="textColorBlue"> {translate("buraya")}
                            </a> {translate("tıklayarak indirebilirsin.")}</span>
                    </div>
                    
                    <Button
                        className="mt1"
                        loading={isGenerating}
                        disabled={!isActiveApi || isGenerating}
                        circular={true}
                        icon
                        labelPosition='left'
                        color="blue"
                        onClick={reGenerateUser}
                    >
                        <Icon name='refresh' />
                        {translate("Yeni üret")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ApiSettings;