import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './mobileHeader.css';
import { Dropdown, Image } from 'semantic-ui-react';
import { displayProtectedAppDoc } from '../../helper/DisplayProtectedDocument';
import TranslateHelper from '../../helper/TranslateHelper';
import { NetworkRequest } from '../../helper/NetworkRequest';
import { ILanguageDropdown } from '../../interfaces/interfaces';
import { selectedLanguageContext } from '../../Store';

const MobileHeader = (props) => {

    var { translate } = TranslateHelper();
    let _ = require("lodash");
    const [mobileMenuClass, setMobileMenuClass] = useState("");
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useContext(selectedLanguageContext);
    const [languageList, setLanguageList] = useState<ILanguageDropdown[]>([]);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);

    const checkClientClaim = () => {
        NetworkRequest('/api/Home/CheckClientClaim').then((response) => {
            if (response.data.resultCode == 0) {
                let lang = _.find(languageList, (doc) => {
                    return doc.key == response.data.detail.value;
                });
                if (lang != undefined) {
                    setSelectedDefaultLanguage(lang.value);
                    setSelectedLanguage(lang);
                }
            } else {
                setSelectedDefaultLanguage("tr");
            }
        });
    }

    const addOrUpdate = (event, data) => {
        let lang = _.find(languageList, (doc) => {
            return doc.value == data.value;
        });
        NetworkRequest('/api/Home/AddOrUpdateClientClaim', {
            claimType: 'Language',
            value: lang.key
        }).then((response) => {
            if (response.data.resultCode == 0) {
                setSelectedDefaultLanguage(lang.value);
                setSelectedLanguage(lang);
            }
        });
    }

    const getLanguages = () => {
        NetworkRequest("/api/Language/GetActiveLanguages").then((response) => {
            if (response.data.resultCode == 0) {
                setLanguageList(response.data.detail);
                let mappedList = _.map(response.data.detail, (l) => {
                    let languageForDropdown: ILanguageDropdown = {
                        flag: l.flag,
                        key: l.key,
                        text: l.text,
                        value: l.value
                    }
                    return languageForDropdown;
                });
                setLanguageListDropdown(mappedList);
                checkClientClaim();
            }
        });
    }

    useEffect(() => {
        if (languageList.length > 0) {
            checkClientClaim();
        }
    }, [languageList]);

    useEffect(() => {
        getLanguages();
    }, []);

    React.useEffect(() => {
        ////console.log("mobileMenuClass: ", mobileMenuClass);
    }, [mobileMenuClass])

    return (
        <div className={"phone " + mobileMenuClass}>
            <div className="mobileHomeHeader">
                <Image id="logoBeyazImage" source={displayProtectedAppDoc("/Documents/Buddy/images/BUDDY_LOGO_beyaz.png", "logoBeyazImage")} onClick={() => { window.location.href = "/" }} className="mobileLogo" />
                {window.location.pathname != '/homeLogin' &&
                    <Image id="ucdotImage" source={displayProtectedAppDoc("/Documents/Buddy/images/ucdot.png", "ucdotImage")}
                        onClick={() => { mobileMenuClass.length > 0 ? setMobileMenuClass("") : setMobileMenuClass("activeMenu") }} className="hamburgher" />
                }
            </div>

            <div className="mobileHeaderMenu" onClick={() => { mobileMenuClass.length > 0 ? setMobileMenuClass("") : setMobileMenuClass("activeMenu") }}>
                <div className="mobileHomeHeader mobileHomeHeaderComp">
                    <Image id="logoBeyazImage-2" source={displayProtectedAppDoc("/Documents/Buddy/images/BUDDY_LOGO_beyaz.png", "logoBeyazImage-2")} onClick={() => { window.location.href = "/" }} className="mobileLogo"  />

                    <Image id="ucdotImage-2" source={displayProtectedAppDoc("/Documents/Buddy/images/ucdot.png", "ucdotImage-2")}
                        onClick={() => { mobileMenuClass.length > 0 ? setMobileMenuClass("") : setMobileMenuClass("activeMenu") }} className="hamburgher" />

                </div>
                <div className="options">
                    {props.page != undefined && props.page == "home"
                        ?
                        <AnchorLink href="#scrollDown" id="scroll">{translate('Neden Buddy?')}</AnchorLink>
                        :
                        <Link to='/home#scrollDown'>{translate('Neden Buddy?')}</Link>
                    }
                    <Link to="/packets" className={window.location.pathname.indexOf("packets") != -1 ? "activeLink" : ""}>
                        <div className="option">{translate('Paketler')}</div>
                    </Link>
                    <Link to="/aboutUs" className={window.location.pathname.indexOf("aboutUs") != -1 ? "activeLink" : ""}>
                        <div className="option">{translate('Hakkımızda')}</div>
                    </Link>
                    <AnchorLink href='#contact' className={window.location.pathname.indexOf("contact") != -1 ? "activeLink" : ""}>
                        <div className="option">{translate('İletişim')}</div>
                    </AnchorLink>
                    <Link to="/newTenant" className={window.location.pathname.indexOf("newTenant") != -1 ? "activeLink" : ""}>
                        <div className="option">{translate('Üye Ol')}</div>
                    </Link>
                    <a href="/homeLogin" className="borderedLinkBtn">{translate('Giriş')}</a>
                    <Dropdown
                        className="greyInput"
                        placeholder={translate('Dil Seçiniz')}
                        clearable
                        search
                        selection
                        value={selectedDefaultLanguage}
                        options={languageListDropdown}
                        onChange={(event, data) => {
                            addOrUpdate(event, data)
                        }}
                    />
                </div>
            </div>
            {window.location.pathname != '/homeLogin'
                &&
                <div className="menu-click-area" onClick={() => { mobileMenuClass.length > 0 ? setMobileMenuClass("") : setMobileMenuClass("activeMenu") }}></div>
            }
        </div>);
}

export default MobileHeader;