import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import BreadcrumbComp from '../../components/Breadcrumb';
import TranslateHelper from '../../helper/TranslateHelper';

function SuperAdminLayout(props) {

    var { translate } = TranslateHelper();
    const location = useLocation();
    const [breadCrumbHtml, setBreadCrumbHtml] = useState<any>();

    let styles = {
        adminLayoutStyle: {
            width: "100%",
            paddingTop: "60px"
        },
        breadcrumbStyle: {
            paddingLeft: "3em",
            paddingTop: "3em",
            zIndex: "4",
            position: "relative" as "relative"
        },
        version: {
            textAlign: "right" as "right",
            right: "1rem",
            opacity: ".71",
            fontStyle: "italic",
            userSelect: "none" as "none",
            bottom: ".5rem",
            fontVariant: "small-caps",
            fontVariantCaps: "petite-caps" as "petite-caps",
            position: "fixed" as "fixed"
        }
    }

    let breadCrumbArray = {
        tenants: [
            { Menu: translate('Anasayfa'), Link: "/" },
            { Menu: translate('Aboneler'), Link: "/tenants" }
        ],
        subscriptions: [
            { Menu: translate('Anasayfa'), Link: "/" },
            { Menu: translate('Abonelik Tipleri'), Link: "/subscriptions" }
        ],
        tenantSubscriptions: [
            { Menu: translate('Anasayfa'), Link: "/" },
            { Menu: translate('Abonelik Kayıtları'), Link: "/tenantSubscriptions" }
        ],
        languageSettings: [
            { Menu: translate('Anasayfa'), Link: "/" },
            { Menu: translate('Dil Ayarları'), Link: "/languageSettings" }
        ]
    };

    useEffect(() => {
        let pathname = (location.pathname).slice(1).split("/")[0];
        ////console.log("pathname: ", pathname);
        if (pathname)
            ////console.log("SuperAdminLayout useEffect pathname: ", breadCrumbArray[pathname]);
            setBreadCrumbHtml(
                <BreadcrumbComp
                    BreadcrumbProps={breadCrumbArray[pathname]}
                    BreadcrumbContainerStyle={{ position: "fixed" }}
                />
            );
    }, [location]);

    return (
        <div>
            <Navbar
                IsDefaultSetting={props.IsDefaultSetting}
                LogoUrl={props.LogoUrl}
                SetIsDefaultSetting={props.SetIsDefaultSetting}
                FavIconUrl={props.FavIconUrl}
                SiteTitle={props.SiteTitle}
                UserRole={props.UserRole}
                IsSuperAdmin={true}
                BgColor={"white"}
            />

            {location.pathname !== "/" &&
                <div style={styles.breadcrumbStyle}>
                    {breadCrumbHtml}
                </div>
            }
            <div style={styles.adminLayoutStyle}>
                {props.children}
            </div>
            <p style={styles.version}>version:1.0.14</p>
        </div>
    );
}

export default SuperAdminLayout;