import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import {
    Input,
    Button,
    Popup,
    Dropdown,
    Table,
    Checkbox,
    Pagination,
    Transition,
    Loader,
    Icon,
    Header,
    Modal,
    Form,
    TextArea,
    Grid,
    Image
} from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { NetworkRequest } from '../../../helper/NetworkRequest';
import { DateFormat, DateFormatDatePicker, DateFormatDatePickerRead } from '../../../helper/DateFormat';
import { digitallicaConfirm, scrollToTop } from '../../../helper/HelperFunctions';
import { toast } from 'react-toastify';
import EditorComponent from '../../../components/EditorComponent';
import { v4 as uuidv4 } from 'uuid';
import { parse } from 'querystring';
import TranslateHelper from '../../../helper/TranslateHelper';
import { displayProtectedAppDoc } from '../../../helper/DisplayProtectedDocument';
import { DataHandler } from '../../../helper/DataHelper';
import ModuleListCardItem from "../components/ModuleListCardItem";
import DefaultProfileImage from '../../../images/user.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


interface Dropdown {
    key?: string;
    value?: string;
    text?: string;
}

interface User {
    id?: string,
    username?: string,
    name?: string,
    surname?: string,
    email?: string,
    companyName?: string,
    functionTitle?: string,
    location?: string,
    workStartDate?: Date,
    lastLogin?: Date,
    photo: string,
    isActive: boolean,
    isDeleted: boolean,
    userRole?: Array<any>,
    roleNames?: string,
    details: string,
    userDetailView: any,
    isAcceptedTOUC: boolean
}

const UserList = () => {

    const [isHovered, setIsHovered] = useState(false);
    const [hoveredUserId, setHoveredUserId] = useState<string | null>();
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = (userId: string | undefined) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setIsHovered(true);
        setHoveredUserId(userId);
    };

    const handleMouseLeave = () => {


        timerRef.current = setTimeout(() => {
            setIsHovered(false);
            setHoveredUserId(null);
        }, 200); // 500ms sonra panel kapanır  
    };


    const handlePanelEnter = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    const handlePanelLeave = () => {
        timerRef.current = setTimeout(() => {
            setIsHovered(false);
        }, 200); // 500ms sonra panel kapanır
    };



    var { translate } = TranslateHelper();
    //styles
    let styles = {
        header: {
            display: "flex",
            gap: "1em",
            flexDirection: "column" as "column"
        },

        filterSection: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "row" as "row",
            alignItems: "unset",
            gap: "unset"
        },

        filterLeftSection: {
            display: "flex",
            gap: "1em",
            alignItems: "center",
            flexDirection: "row" as "row"
        },

        tableSection: {
            marginTop: "20px"
        },

        paginationBox: {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },

        noFoundDataBox: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column" as "column",
            alignItems: "center",
            margin: "10px",
            gap: "1em"
        },
        inputSectionBox: {
            display: "flex",
            gap: "1em",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row" as "row",
            marginBottom: "1rem",
            height: "auto",
            width: "100%"
        },
        inputStyle: {
            width: "100%"
        },
        emailListBox: {
            width: "100%",
            heigth: "2rem",
            padding: "5px 3px",
            borderRadius: "5px",
            display: "flex",
            backgroundColor: "#CAF7E302",
            flexWrap: "wrap" as "wrap"
        },
        emailBox: {
            width: "auto",
            heigth: "1rem",
            padding: "5px 3px",
            margin: "4px",
            borderRadius: "5px",
            border: "1px solid black"
        },
        filter: {
            position: "absolute" as "absolute",
            top: "119px",
            left: "380px",
            width: "440px",
            height: "auto",
            zIndex: 12,
            borderRadius: "10px",
            boxShadow: "0px 3px 17px 2px rgba(0,0,0,0.18)",
            backgroundColor: "rgb(253 253 253)",
            padding: "2%"
        },
        filterInputbox: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "row" as "row",
            // marginBottom: "1rem",
            height: "auto",
            width: "100%",
            marginTop: "2em",
            // padding: "4%",
            // borderRadius: "10px",
            // backgroundColor: "#f9f9f9"
        },
        labelBox: {
            width: "50%",
            left: "0px"
        },
        inputBox: {
            width: "50%",
            right: "0px"
        },

        messageModal: {
            top: "5%",
            left: "25%",
            height: "auto"
        },
    }
    const navigate = useNavigate();
    //varables
    let _ = require('lodash');
    const collectiveProccessOptions = [
        { key: 0, text: translate('Seçim yapınız...'), value: 0, message: translate('Seçim yapınız...') },
        { key: 1, text: translate('Sil'), value: 1, message: translate('Silmek') },
        { key: 2, text: translate('Pasif Yap'), value: 2, message: translate('Pasif Yapmak') },
        { key: 3, text: translate('Mesaj Gönder'), value: 3, message: translate('Mesaj Göndermek') },
        { key: 4, text: translate('Modül Ata'), value: 4, message: translate('Modül Atamak') },
        { key: 5, text: translate('Hiçbiri') }
    ]

    const tableHeader = [
        <Checkbox className={"userListCheckboxClass"} disabled />,
        translate("Profil Fotoğrafı"),
        translate("Ad Soyad"),
        translate("E-posta"),
        translate("Şirket"),
        translate("Fonksiyon"),
        translate("Lokasyon"),
        translate("İşe Giriş Tarihi"),
        translate("Son Giriş Tarihi"),
        translate("Çalışan Rolleri"),
        translate("Aktif/Pasif"),
        translate("KVKK Kabul"),
        translate("LDAP"),
        translate("Detaylar")
    ];

    const [userRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [showModuleCantAdded, setShowModuleCantAdded] = useState(false);
    const [moduleCantAddedList, setModuleCantAddedList] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState(false);
    const [collectiveProcesValue, setCollectiveProcesValue] = useState<any>();
    const [isFiltered, setIsFiltered] = useState(false);
    const [showSendMessage, setShowSendMessage] = useState(false);
    const [showModules, setShowModules] = useState(false);
    const [currentPage, setCurrentPage] = useState<string | number>(1);
    const [filterText, setFilterText] = useState<string>("");
    const [paginationHtml, setPaginationHtml] = useState<any>();
    const [editorContent, setEditorContent] = useState("");
    const [editorContentIndex, setEditorContentIndex] = useState(0);
    const [mailTitle, setMailTitle] = useState<string>("");
    const [defaultUserImage] = DefaultProfileImage;
    const [userList, setUserList] = useState<Array<Dropdown>>([]);
    const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
    const [users, setUsers] = useState<Array<User>>([]);
    const [buddyList, setBuddyList] = useState<Array<User>>([]);
    const [managerList, setManagerList] = useState<Array<User>>([]);
    const [humanResourcesList, setHumanResourcesList] = useState<Array<User>>([]);
    const [modules, setModules] = useState<Array<any>>([]);
    const [userRoleList, setUserRoleList] = useState([{}]);
    const [isFirstDataPull, setIsFirstDataPull] = useState(true);
    const [filter, setFilter] = useState({
        filterText: "",
        currentRole: DataHandler.getUserDataWithKey("userRole"),
        isDeleted: false,
        isActive: true,
        isRegisteredNotLogged: false,
        userRoleId: null,
        workStartDateRangeStart: null,
        workStartDateRangeEnd: null,
        buddyId: undefined,
        managerId: undefined,
        humanResourcesId: undefined,
        currentPage: 1,
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });

    const dropdownRef = React.useRef<any>(null);

    let colors = [
        { backgroundColor: "#DE8971", color: "white" },
        { backgroundColor: "#7B6079", color: "white" },
        { backgroundColor: "#A7D0CD", color: "black" },
        { backgroundColor: "#FFE9D6", color: "black" }
    ];

    const IMPORT_USER_ERROR_MESSAGE = {
        1: "İsim alanı boş bırakılamaz",
        2: "Soyisim alanı boş bırakılamaz",
        4: "Email alanı boş veya zaten daha önceden kayıtlı",
        8: "İşe başlama tarihi boş bırakılamaz",
        15: "İnsan kaynakları email adresi boş veya girmiş olduğunuz email adresi insan kaynakları uzmanına ait değil",
        16: "Yönetici email adresi boş veya girmiş olduğunuz email adresi bir yöneticiye ait değil",
        17: "Buddy email adresi boş veya girmiş olduğunuz email adresi bir buddyye ait değil",
        18: "Bu çalışan daha önceden kayıt edilmiş (Email adresi ve telefon numarası benzersiz olmalıdır)",
        19: "IsActive alanında tanımlanamayan bir değer tespit edildi",
        20: "Yönetici, Buddy veya İnsan Kaynakları email adreslerinden biri bulunamadı veya çalışan silinmiş"
    }

    async function resetCookieEvent(userData, user) {
        //bence olmamalı
        //dispatch(logOutUser({ token: getCookie("Secret"),isStopRedirect:false }));
        DataHandler.removePages();

        /*   debugger;*/
        DataHandler.pushForceLoginData(userData, user);

        if (userData.isModuleCountMoreThanOne && userData.userRole == "User") {
            window.location.href = "/userdashboard";
            return;
        } else {
            window.location.href = "/";
        }
    }

    async function loginUserAccount(userId) {
        digitallicaConfirm(translate("İşleme devam etmek istediğinize emin misiniz?"), () => {
            /*  debugger;*/
            let userData = {
                userId: userId,
                transactionUserId: DataHandler.getUserDataWithKey("userId")
            }

            if (userData.userId == userData.transactionUserId) {
                toast(translate("Login olduğunuz çalışana zorlu giriş yapamazsınız."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 5000
                });
            }
            NetworkRequest("/Authentication/ForceLogin", userData, true).then((response) => {
                let result = response.data;

                if (result.resultCode == 0) {
                    let user = result.detail;

                    let newUser = {
                        userId: user.userId,
                        name: user.name,
                        surname: user.surname,
                        fullname: user.fullname,
                        token: user.token,
                        userRole: user.userRole[0],
                        userRoleList: user.userRoleList,
                        roleId: user.userRoleList[0].roleID,
                        email: user.email,
                        photo: user.photo,
                        tenantId: user.tenantId,
                        siteTitle: user.siteTitle,
                        logoUrl: user.logoUrl,
                        favIconUrl: user.favIconUrl,
                        isDefaultSetting: user.isDefaultSetting,
                        isActive: user.isActive,
                        workStartDate: user.workStartDate,
                        isBaseTenant: DataHandler.getUserDataWithKey("isBaseTenant"),
                        isModuleCountMoreThanOne: user.isHaveMoreThanOneModule
                    };
                    /*debugger;*/
                    let store = DataHandler.getStore();
                    let oldUser = {
                        fullName: DataHandler.getUserDataWithKey("fullname"),
                        token: store.token,
                        name: DataHandler.getUserDataWithKey("name"),
                        surname: DataHandler.getUserDataWithKey("surname"),
                        userRole: DataHandler.getUserDataWithKey("userRole"),
                        userId: DataHandler.getUserDataWithKey("userId"),
                        email: DataHandler.getUserDataWithKey("email"),
                        photo: DataHandler.getUserDataWithKey("photo"),
                        userRoleList: DataHandler.getUserDataWithKey("userRoleList"),
                        roleId: DataHandler.getUserDataWithKey("roleId"),
                        tenantId: DataHandler.getUserDataWithKey("tenantId"),
                        workStartDate: DataHandler.getUserDataWithKey("workStartDate"),
                        logoUrl: DataHandler.getUserDataWithKey("logoUrl")
                    }

                    resetCookieEvent(newUser, oldUser);
                } else {
                    toast(translate("Hata oluştu. Lütfen sistem yöneticinize başvurun."), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });
                }
            });
        }, () => {
            toast(translate("İşlem iptal edildi."), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
        });
    }

    const getUsers = () => {
        ////console.log("getUsers started");
        setIsLoading(true);


        const filterTimer = setTimeout(() => {
            let req = { ...filter, isFirstDataPull: isFirstDataPull }

            NetworkRequest("api/User/Filter", req).then((response) => {
                if (response.data.resultCode == 0) {
                    let result = response.data.detail;

                    setUsers(result.list);

                    setPaginationHtml(<Pagination
                        defaultActivePage={1}
                        firstItem={null}
                        lastItem={null}
                        pointing
                        secondary
                        totalPages={Math.ceil((result.count === 0 ? 1 : result.count) / 25)}
                        onPageChange={
                            (event, data) => {
                                setFilter({ ...filter, currentPage: Number(data.activePage) });
                            }
                        }
                    />);

                    setIsLoading(false);

                    scrollToTop();
                }
            });

            if (isFirstDataPull) {
                setIsFirstDataPull(false);
            }

        }, 500);


        return () => {
            clearInterval(filterTimer);
        }


    }
    //const getPagination = (filterText?: string) => {
    //    if (filterText) {
    //        filterText = filterText.trim().length > 0 ? filterText : undefined;
    //    }
    //    let req = { ...filter, isFirstDataPull: isFirstDataPull, filterText: filterText };
    //    NetworkRequest("api/User/GetFilteredCount", req).then((response: any) => {
    //        if (response.data.resultCode == 0) {
    //            let count: number = parseInt(response.data.detail === 0 ? 1 : response.data.detail);

    //            setPaginationHtml(<Pagination
    //                defaultActivePage={1}
    //                firstItem={null}
    //                lastItem={null}
    //                pointing
    //                secondary
    //                totalPages={Math.ceil(count / 25)}
    //                onPageChange={
    //                    (event, data) => {
    //                        setFilter({ ...filter, currentPage: Number(data.activePage) });
    //                    }
    //                }
    //            />);
    //        }
    //    });
    //}
    const deleteUser = (user) => {
        digitallicaConfirm(
            translate("Çalışanı silmek istediğinizden emin misiniz?"),
            () => {
                NetworkRequest("api/User/Delete", { id: user.id }).then((response) => {
                    if (response.data.resultCode === 0) {
                        search(filter);
                    }
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 2000
                    });
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
    }

    const destroyUser = (user) => {
        digitallicaConfirm(
            translate("Çalışanı tamamen silmek istediğinizden emin misiniz?"),
            () => {

                toast(translate("Silme işlemi başladı. Lütfen bekleyiniz. (Bu işlem biraz uzun sürebilir)"), {
                    type: 'info',
                    isLoading: true,
                    theme: 'dark',
                    position: 'top-center'
                });


                NetworkRequest("api/User/DestroyUser", { id: user.id }).then((response) => {
                    if (response.data.resultCode === 0) {
                        toast.dismiss();
                        search(filter);
                        toast(translate(response.data.message), {
                            type: 'success',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });
                    } else {
                        toast.dismiss();
                        toast(translate(response.data.message), {
                            type: 'error',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 2000
                        });
                    }

                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );
    }

    const undoDeleteUser = (user) => {
        digitallicaConfirm("İşleme devam etmek istediğinize emin misiniz?", () => {
            NetworkRequest("api/User/UndoDelete", { id: user.id }).then((response) => {
                if (response.data.resultCode === 0) {
                    search(filter);
                }
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2000
                });
            });
        }, () => {
            toast(translate(translate("İşlem iptal edildi.")), {
                type: 'error',
                theme: 'dark',
                position: 'top-center',
                autoClose: 2500
            });
        });
    }
    const getUserRoles = () => {
        NetworkRequest("api/UserRole/GetRoles", {}).then((response) => {
            if (response.data.resultCode === 0) {
                /* setUsers([...users.filter(user => user.id != response.data.data.id), response.data.data]);*/
                let result = response.data.detail;
                const roleToDropdownType = result.map(elem => (
                    {
                        text: elem.displayName,
                        key: elem.roleId,
                        value: elem.roleId
                    }
                ));
                setUserRoleList(roleToDropdownType.filter(x => x.text != "SuperAdmin"));
                ////console.log(roleToDropdownType.filter(x=>x.text!="SuperAdmin"));
            }
            //toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
        });
    }
    const apiSearchSuccess = (response: any) => {
        if (response.data.resultCode === 0) {
            let users = response.data.detail;
            setUsers(users);
            /*     getPagination(filterText);*/
            setIsLoading(false);
        }
    }
    const search = (filter: any) => {
        getUsers();
    }
    const setUserSelected = (e) => {
        if (e.target.checked) {
            let selectedUser: User[] = users.filter(user => user.id == e.target.value);
            setSelectedUsers(oldArray => [...oldArray, selectedUser[0]]);
        } else {
            setSelectedUsers(selectedUsers.filter(user => user.id !== e.target.value));
        }
    }
    const getModules = () => {
        NetworkRequest("/api/Modules/GetTenantModules", { id: DataHandler.getUserDataWithKey("tenantId") }).then((response) => {
            if (response.data.resultCode == 0) {
                setModules(response.data.detail);
                setShowModules(true);
            }
        });
    }
    const collectiveProces = (e, data) => {
        
           


        if (data.value == "")
            return;
        if (selectedUsers.length < 1) {
            toast(translate("İşlem yapmak için çalışanı seçiniz."), {
                type: 'warning',
                theme: 'dark',
                position: 'top-center',
                autoClose: 3500
            });
            return;
        }

        var collectivePO = collectiveProccessOptions.find(x => x.key == data.value);
        digitallicaConfirm(
            'Seçilen ' + selectedUsers.length + ' çalışanı '
            + collectivePO?.message
            + ' istediğinizden emin misiniz?',
            () => {
                setIsLoading(true);
                switch (data.value) {
                    case 1: {
                        NetworkRequest("api/User/MultipleDelete", selectedUsers).then(response => {
                            setIsLoading(true);
                            if (response.data.resultCode == 0) {
                                //clearDropdown();
                                search(filter);
                            }
                        });
                        break;//SİL; 
                    }
                    case 2: {
                        NetworkRequest("api/User/UndoMultipleDelete", selectedUsers).then(response => {
                            setIsLoading(true);
                            ////console.log("lkjhghjklşlkj", response.data)
                            if (response.data.resultCode == 0) {
                                //clearDropdown();
                                search(filter);
                            }
                        });
                        break;//PASİF; 
                    }
                    case 3: {
                        setShowSendMessage(true);
                        break;//MESAJ; 
                    }
                    case 4: {
                        getModules();
                        break;//MODÜL; 
                    }
                    default: {
                        toast(translate("İşlem yapılmadı!"), {
                            type: 'warning',
                            theme: 'dark',
                            position: 'top-center',
                            autoClose: 3000
                        });
                        break;
                    }
                }
                setIsLoading(false);
            },
            () => {
                toast(translate("İşlem iptal edildi."), {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 2500
                });
            }
        );

        setCollectiveProcesValue(0)
    }
    const sendMail = (e) => {
        setIsLoading(true);
        let mail = {
            UserList: selectedUsers,
            Title: mailTitle,
            Body: DataHandler.getUserDataWithKey("fullname") + "(" + DataHandler.getUserDataWithKey("email") + ")" + " Tarafından Gönderilmiştir" + "<br />" + editorContent,
            From: DataHandler.getUserDataWithKey("email")
        }
        NetworkRequest("api/User/SendMail", mail).then((response) => {
            setIsLoading(false);
            if (response.data.resultCode == 0) {
                setShowSendMessage(false);
                setMailTitle("");
                setEditorContent("");
                toast(response.data.detail.length + translate(" kişiye başarıyla toplu mesaj gönderildi."), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });
    }

    const filterHandleChange = (e, id?) => {
        setIsFiltered(true);

        const target = e?.target;
        var value = target?.type === 'checkbox' ? target?.checked : target?.value;
        const name = target?.name || id;

        if (name == "workStartDateRangeStart" || name == "workStartDateRangeEnd") {
            value = e;
        }

        setFilter({
            ...filter,
            [name]: value
        });
    }
    const filterChangeDropdown = (event, data) => {
        setIsFiltered(true);
        let val = data.value;
        if (data.value == "") {
            val = undefined;
        }
        setFilter({
            ...filter,
            [data.name]: val
        });
    }
    const filterEvent = (e) => {
        setShowFilter(!showFilter);
    }
    const apiFilterSuccess = (response: any) => {
        setIsLoading(true);
        if (response.data.resultCode == 0) {
            setIsLoading(false);
            setUsers(response.data.detail);
            /*     getPagination();*/
        }
    }
    const getAllUsers = () => {
        let req = {
            id: DataHandler.getUserDataWithKey("tenantId"),
            checkTenants: DataHandler.getUserDataWithKey("userRole") == "HeadAdmin"
        };
        NetworkRequest("api/User/GetAllAssignedBuddies", req).then((response) => {
            if (response.data.resultCode === 0) {
                const userToDropdownType = response.data.detail.map(elem => (
                    {
                        text: elem.name + " " + elem.surname,
                        key: elem.id,
                        value: elem.id
                    }
                ));
                setBuddyList(userToDropdownType);
            }
        });
        NetworkRequest("api/User/GetAllAssignedManagers", req).then((response) => {
            if (response.data.resultCode === 0) {
                const userToDropdownType = response.data.detail.map(elem => (
                    {
                        text: elem.name + " " + elem.surname,
                        key: elem.id,
                        value: elem.id
                    }
                ));
                setManagerList(userToDropdownType);
            }
        });

        NetworkRequest("api/User/GetAllAssignedHumanResources", req).then((response) => {
            if (response.data.resultCode === 0) {
                const userToDropdownType = response.data.detail.map(elem => (
                    {
                        text: elem.name + " " + elem.surname,
                        key: elem.id,
                        value: elem.id
                    }
                ));
                setHumanResourcesList(userToDropdownType);
            }
        });
    }
    const clearDropdown = () => {
        if (dropdownRef != null && dropdownRef.current != null) {
            var element = dropdownRef.current.querySelector('[aria-selected="true"]');
            if (element) {
                dropdownRef.current.querySelector('.clear')?.click();
            }
        }
    };
    const clearFilter = () => {
        setIsFiltered(false);
        setFilter({
            filterText: "",
            currentRole: DataHandler.getUserDataWithKey("userRole"),
            isDeleted: false,
            isActive: true,
            isRegisteredNotLogged: false,
            userRoleId: null,
            workStartDateRangeStart: null,
            workStartDateRangeEnd: null,
            buddyId: undefined,
            managerId: undefined,
            humanResourcesId: undefined,
            currentPage: 1,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        });
        setShowFilter(false);
    }
    const handleEditorChange = (data) => {
        setEditorContent(data);
    }
    const setSelection = (data, data2, data3) => {
        if (data != undefined) {
            setEditorContentIndex(data.index);
        }
    }
    const addUsersTo = (module) => {
        let arr: any = [];
        _.each(selectedUsers, (doc) => {
            arr.push({ userId: doc.id, moduleId: module.id })
        });
        setShowModules(false);
        toast(translate("Lütfen bekleyin..."), { type: 'info', theme: 'dark', position: 'top-center', isLoading: true });
        NetworkRequest("/api/ModuleUserRegistrations/AddCollective", arr).then((response) => {
            if (response.data.resultCode == 0) {
                toast.dismiss();
                toast(response.data.detail.addedList.length + translate(" Çalışan eklendi!"), {
                    type: 'success',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
                if (response.data.detail.cantAddedList.length > 0) {
                    toast(response.data.detail.cantAddedList.length + translate(" Çalışan Eklenemedi!"), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });

                    setTimeout(() => {
                        setModuleCantAddedList(response.data.detail.cantAddedList);
                        setShowModuleCantAdded(true);
                    }, 1000);
                } else
                    toast(translate("Tüm Çalışanlar Eklendi"), {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 5000
                    });

            }
        });
    }
    const checkId = (target: any) => {
        let control = false;
        if (target.id == "filterBox") {
            control = true;
        }
        if (!control && target.parentElement != undefined) {
            return checkId(target.parentElement);
        }
        return control;
    }

    useEffect(() => {
        if (filterText == filter.filterText)
            return;

        setFilter({ ...filter, filterText: filterText });
    }, [filterText]);

    useEffect(() => {
        ////console.log("useEffect filter: ", filter);
        search(filter);
        //var userFilterTime = setTimeout(() => {

        //}, 1000);
        /*return (() => { clearTimeout(userFilterTime) });*/

    }, [filter]);

    useEffect(() => {
        ////console.log("useEffect filter: ", filter);
        //console.log(users);
    }, [users]);

    useEffect(() => {
        getAllUsers();
        getUserRoles();
    }, []);

    return (
        <div className="generalContainerForWhiteBg" onClick={(e: any) => {
            if (showFilter == true && !checkId(e.target)) {
                ////console.log("e.target", e.target);
                setShowFilter(false);
            }
        }}
        >
            <div style={styles.header}>
                <Transition visible={showSendMessage} animation='scale' duration={500}>
                    <Modal
                        centered={false}
                        open={showSendMessage}
                        onClose={() => setShowSendMessage(false)}
                    >
                        <Modal.Header>{translate("Mesaj Gönder")}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <div>
                                    {/*<Header as='h2'> Mesaj Gönder</Header>*/}
                                    <div style={styles.inputSectionBox}>
                                        <div style={styles.emailListBox}>
                                            {
                                                selectedUsers.map((selectedUser, index) => (<div key={uuidv4()}
                                                    style={{ ...styles.emailBox, ...colors[index % 5] }}>{selectedUser.email}</div>))
                                            }
                                        </div>
                                    </div>
                                    <div style={styles.inputSectionBox}>
                                        <Input
                                            style={styles.inputStyle}
                                            control={Input}
                                            label={translate("Başlık")}
                                            placeholder={translate("Başlık")}
                                            onChange={(e) => {
                                                setMailTitle(e.target.value)
                                            }}
                                            value={mailTitle}
                                        />
                                    </div>
                                    <div>
                                        <EditorComponent
                                            setEditorContent={setEditorContent}
                                            value={editorContent}
                                        />
                                    </div>
                                </div>

                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setShowSendMessage(false)}>{translate("Kapat")}</Button>
                            <Button color='green' onClick={sendMail}>{translate("Gönder")}</Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>
                <Transition visible={showModules} animation='scale' duration={500}>
                    <Modal
                        centered={false}
                        open={showModules}
                        onClose={() => setShowModules(false)}
                    >
                        <Modal.Header>Modüller</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Grid columns={2} className="usersModuleGrid">
                                    {/*<Grid.Row key={uuidv4()} className="rowBorder">*/}
                                    {/*    <Grid.Column width={10} className="thisIsCenter">*/}
                                    {/*        <b>{translate("Modül Adı")}</b>*/}
                                    {/*    </Grid.Column>*/}
                                    {/*    <Grid.Column width={2} className="thisIsCenter">*/}
                                    {/*        <b>{translate("İşlem")}</b>*/}
                                    {/*    </Grid.Column>*/}
                                    {/*</Grid.Row>*/}
                                    {modules.map((module) => (
                                        <div className={"moduleListCardItemContainer"}>
                                            <ModuleListCardItem
                                                key={uuidv4()}
                                                moduleTitle={module.title}
                                                clickFunc={() => {
                                                    addUsersTo(module)
                                                }}
                                            />
                                        </div>


                                        // <Grid.Row key={uuidv4()} className="rowBorder">
                                        //     <Grid.Column width={10} className="thisIsCenter">
                                        //         {module.title}
                                        //     </Grid.Column>
                                        //     <Grid.Column width={2} className="thisIsCenter">
                                        //         <Button color='green' size='medium' onClick={(e) => {
                                        //             addUsersTo(module)
                                        //         }}>{translate("Seç")}</Button>
                                        //     </Grid.Column>
                                        // </Grid.Row>
                                    ))}
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setShowModules(false)}>{translate("Kapat")}</Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>
                <div style={styles.filterSection} id="filterSection">
                    <div style={styles.filterLeftSection}>
                        <div>
                            <Input
                                className={"greyInput"}
                                icon='search'
                                placeholder={translate("Arama..")}
                                size="big"
                                value={filterText}
                                onChange={(e) => {
                                    setFilterText(e.target.value)
                                }}
                            />
                        </div>

                        <div>
                            <Popup
                                content={translate("Filtrele")}
                                trigger={
                                    <Button
                                        circular={true}
                                        className={"continueBlueButton"}
                                        icon='filter'
                                        size="big"
                                        onClick={filterEvent}
                                    ></Button>
                                }
                            />
                            {showFilter
                                &&
                                <div id="filterBox" style={styles.filter}>
                                    <h2>{translate("Filtreler")}</h2>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("Aktif")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <input
                                                type="checkbox"
                                                checked={filter.isActive}
                                                name="isActive"
                                                onChange={filterHandleChange}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("Silinen")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <input
                                                type="checkbox"
                                                checked={filter.isDeleted}
                                                name="isDeleted"
                                                onChange={filterHandleChange}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("Giriş Yapmamış Çalışan")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <input
                                                className={"greyInput"}
                                                type="checkbox"
                                                checked={filter.isRegisteredNotLogged}
                                                name="isRegisteredNotLogged"
                                                onChange={filterHandleChange}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("İşe Giriş Tarihi - Başlangıç")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            {/*<Input*/}
                                            {/*    className={"greyInput"}*/}
                                            {/*    value={filter.workStartDateRangeStart}*/}
                                            {/*    timezone="[[timezone]]"*/}
                                            {/*    name="workStartDateRangeStart"*/}
                                            {/*    required="required"*/}
                                            {/*    type="date"*/}
                                            {/*    size="big"*/}
                                            {/*    style={styles.inputStyle}*/}
                                            {/*    placeholder={translate("İşe Giriş Tarihi")}*/}
                                            {/*    onChange={filterHandleChange}*/}
                                            {/*/>*/}

                                            <DatePicker
                                                isClearable
                                                type="date"
                                                className={"greyInput datePickerCustomClass"}
                                                name="workStartDateRangeStart"
                                                id="workStartDateRangeStart"
                                                maxLength="8"
                                                selected={filter?.workStartDateRangeStart}
                                                value={DateFormatDatePicker(filter?.workStartDateRangeStart, false)}
                                                onChange={date => filterHandleChange(date, "workStartDateRangeStart")}
                                                placeholderText="dd-mm-yyyy"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("İşe Giriş Tarihi - Bitiş")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            {/*<Input*/}
                                            {/*    className={"greyInput"}*/}
                                            {/*    value={filter.workStartDateRangeEnd}*/}
                                            {/*    timezone="[[timezone]]"*/}
                                            {/*    name="workStartDateRangeEnd"*/}
                                            {/*    required="required"*/}
                                            {/*    type="date"*/}
                                            {/*    size="big"*/}
                                            {/*    style={styles.inputStyle}*/}
                                            {/*    placeholder={translate("İşe Giriş Tarihi")}*/}
                                            {/*    onChange={filterHandleChange}*/}
                                            {/*/>*/}

                                            <DatePicker
                                                isClearable
                                                type="date"
                                                className={"greyInput datePickerCustomClass"}
                                                name="workStartDateRangeEnd"
                                                id="workStartDateRangeEnd"
                                                maxLength="8"
                                                selected={filter?.workStartDateRangeEnd}
                                                value={DateFormatDatePicker(filter?.workStartDateRangeEnd, false)}
                                                onChange={date => filterHandleChange(date, "workStartDateRangeEnd")}
                                                placeholderText="dd-mm-yyyy"
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("Çalışan Rolü")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <Dropdown
                                                className={"greyInput"}
                                                value={filter.userRoleId || ""}
                                                name="userRoleId"
                                                clearable
                                                options={userRoleList}
                                                selection
                                                placeholder={translate("Çalışan Rolü Seçiniz")}
                                                onChange={filterChangeDropdown}

                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("Buddy'e göre ara")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <Dropdown
                                                className={"greyInput"}
                                                value={filter.buddyId || ""}
                                                name="buddyId"
                                                options={buddyList}
                                                clearable
                                                selection
                                                placeholder={translate("Buddy Seçiniz")}
                                                onChange={filterChangeDropdown}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("IK'ya göre ara")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <Dropdown
                                                className={"greyInput"}
                                                value={filter.humanResourcesId || ""}
                                                name="humanResourcesId"
                                                clearable
                                                options={humanResourcesList}
                                                selection
                                                placeholder={translate("IK Seçiniz")}
                                                onChange={filterChangeDropdown}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>
                                        <div style={styles.labelBox}>
                                            <Header as='h4'>{translate("Yönetici'ye göre ara")}</Header>
                                        </div>
                                        <div style={styles.inputBox}>
                                            <Dropdown
                                                className={"greyInput"}
                                                value={filter.managerId || ""}
                                                name="managerId"
                                                clearable
                                                options={managerList}
                                                selection
                                                placeholder={translate("Yönetici Seçiniz")}
                                                onChange={filterChangeDropdown}
                                            />
                                        </div>
                                    </div>
                                    <div style={styles.filterInputbox}>

                                        <Button
                                            onClick={clearFilter}
                                            className={"roundedButton"}
                                            color="blue"
                                        >Temizle</Button>

                                        <Button
                                            onClick={filterEvent}
                                            className={"roundedButton"}
                                            color={"green"}
                                        >{translate("Ara")}</Button>

                                        {/*<Button.Group style={{ position: "absolute", right: "1rem", bottom: "1rem" }}>*/}
                                        {/*    <Button color="red" icon="cancel" onClick={clearFilter}>Temizle</Button>*/}
                                        {/*    <Button.Or text="-" />*/}
                                        {/*    <Button onClick={filterEvent} positive>Ara</Button>*/}
                                        {/*</Button.Group>*/}
                                    </div>
                                </div>
                            }
                        </div>

                        <div>
                            <Dropdown
                                className={"greyInput"}
                                
                                options={collectiveProccessOptions}
                                onChange={collectiveProces}
                                value={collectiveProcesValue}
                                selection
                                placeholder={translate("Toplu İşlemler")}
                               
                            />
                        </div>
                    </div>
                    <div>
                        {userRole != "HeadAdmin" &&
                            <div style={{ display: "flex" }}>
                                <Link to="/userImport">
                                    <Popup
                                        content={translate("Excel içeri aktar")}
                                        trigger={
                                            <div style={{ display: "flex" }}>
                                                <Button
                                                    color={"blue"}
                                                    circular={true}
                                                    icon='file excel'
                                                    size="big"
                                                />
                                            </div>
                                        }
                                    />
                                </Link>
                                <Link to="/userAdd">
                                    <Popup
                                        content={translate("Çalışan ekle")}
                                        trigger={
                                            <Button
                                                color={"green"}
                                                circular={true}
                                                icon='plus'
                                                size="big"
                                            />
                                        }
                                    />
                                </Link>
                            </div>}
                    </div>
                </div>
                <p style={{ position: 'relative', top: '-0.5em', left: '1em' }}>
                    {users.length} {translate("kayıt bulundu.")}
                </p>
                <Transition visible={isLoading} animation='scale' duration={500}>
                    <div>
                        <Loader size="huge" active inline='centered' />
                    </div>
                </Transition>
                <Transition visible={!isLoading} animation='fade' duration={500}>
                    <div style={styles.tableSection}>
                        <Table basic='very'>
                            <Table.Header>
                                <Table.Row>
                                    {tableHeader.map(header => (
                                        <Table.HeaderCell key={uuidv4()}>{header}</Table.HeaderCell>
                                    ))}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {users.map((user, userIndex) => (
                                    <Table.Row key={user.id}>
                                        <Table.Cell>
                                            <input className={"userListCheckboxClass"} type="checkbox"
                                                onChange={setUserSelected} value={user.id} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            {user.photo == undefined &&

                                                <Image
                                                    circular={true}
                                                    id={"userListUserImage" + userIndex}
                                                    src={DefaultProfileImage}
                                                    width="50"

                                                />


                                            }
                                            {user.photo != undefined
                                                &&
                                                <Image
                                                    style={{ objectFit: "cover", width: "50px", height: "50px" }}
                                                    circular={true}
                                                    id={"userListUserImage-2" + userIndex}
                                                    src={displayProtectedAppDoc(user.photo, 'userListUserImage-2' + userIndex)}
                                                />
                                            }
                                        </Table.Cell>
                                        <Table.Cell>{[user.name, user.surname].join(" ")}</Table.Cell>
                                        <Table.Cell>{user.email}</Table.Cell>
                                        <Table.Cell>{user.companyName == null ? "-" : user.companyName}</Table.Cell>
                                        <Table.Cell>{user.functionTitle == null ? "-" : user.functionTitle}</Table.Cell>
                                        <Table.Cell>{user.location == null ? "-" : user.location}</Table.Cell>
                                        <Table.Cell>{DateFormatDatePicker(user.workStartDate, false) || "-"}</Table.Cell>
                                        <Table.Cell>{DateFormat(user.lastLogin) || "-"}</Table.Cell>
                                        <Table.Cell
                                            style={{
                                                textAlign: "left",
                                                width: "75px"
                                            }}
                                        >
                                            {user.roleNames}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Icon

                                                name='circle'
                                                color={user.isActive ? 'green' : 'grey'} />
                                        </Table.Cell>

                                        <Table.Cell>
                                            <Icon

                                                name='circle'
                                                color={user.isAcceptedTOUC ? 'green' : 'grey'} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Popup
                                                content={user?.userDetailView?.details?.isLdapUser != null && JSON.parse(user?.userDetailView?.details)?.isLdapUser ? translate("LDAP kullanıcısı") : translate("Sistem kullanıcısı")}
                                                trigger={
                                                    <Icon
                                                        color={user?.userDetailView?.details?.isLdapUser != null && JSON.parse(user?.userDetailView?.details)?.isLdapUser ? "green" : "grey"}
                                                        name="circle" />
                                                }
                                            />

                                        </Table.Cell>

                                        <Table.Cell>

                                            <Button
                                                onMouseEnter={(e) => {
                                                    handleMouseEnter(user?.id);
                                                }}
                                                onMouseLeave={handleMouseLeave}
                                                circular icon='clone outline' />




                                            <Transition
                                                className="userListOpenableTransitionBoxClass"
                                                visible={isHovered && hoveredUserId == user?.id}
                                                animation='scale'
                                                duration={100}
                                            >

                                                <div
                                                    onMouseEnter={(e) => {
                                                        handlePanelEnter();
                                                    }}
                                                    onMouseLeave={handlePanelLeave}
                                                    className={"transitionOpenableBoxClass"}>

                                                    <div>
                                                        <Link className="userListOpenableListElementClass" to={"/employeeReport/" + user.id}>
                                                            <Icon name="chart bar" />
                                                            <span>{translate("Rapor")}</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link onClick={() => loginUserAccount(user.id)} className="userListOpenableListElementClass" to="#">
                                                            <Icon name="sign in" />
                                                            <span>{translate("Login")}</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Link className="userListOpenableListElementClass" to={"/userEdit/" + user.id}>
                                                            <Icon name="edit" />
                                                            <span>{translate("Düzenle")}</span>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        {userRole != "HeadAdmin" &&

                                                            (user.isDeleted == false ?

                                                                <Link onClick={(x) => deleteUser(user)} className="userListOpenableListElementClass" to={"#"}>
                                                                    <Icon name="trash" />
                                                                    <span>{translate("Sil")}</span>
                                                                </Link>
                                                                :
                                                                <div style={{ display: "flex", flexDirection: "column" as "column", gap: ".9em" }}>
                                                                    <div>
                                                                        <Link onClick={(x) => undoDeleteUser(user)} className="userListOpenableListElementClass" to={"#"}>
                                                                            <Icon name="redo" />
                                                                            <span>{translate("Silmeyi Geri Al")}</span>
                                                                        </Link>
                                                                    </div>
                                                                    <div>
                                                                        <Link onClick={(x) => destroyUser(user)} className="userListOpenableListElementClass" to={"#"}>
                                                                            <Icon name="trash alternate outline" />
                                                                            <span>{translate("Tamamen Sil")}</span>
                                                                        </Link>
                                                                    </div>

                                                                </div>


                                                            )
                                                        }




                                                    </div>



                                                    {/*<Link to={"/employeeReport/" + user.id}>*/}
                                                    {/*    <Popup*/}
                                                    {/*        content={translate("Rapor")}*/}
                                                    {/*        trigger={*/}
                                                    {/*            <Button*/}
                                                    {/*                icon='chart bar'*/}
                                                    {/*                color="black"*/}
                                                    {/*            />*/}
                                                    {/*        }*/}
                                                    {/*    />*/}
                                                    {/*</Link>*/}
                                                    {/*<Popup*/}
                                                    {/*    content={translate("Login")}*/}
                                                    {/*    trigger={*/}
                                                    {/*        <Button*/}
                                                    {/*            onClick={() => loginUserAccount(user.id)}*/}
                                                    {/*            color="yellow"*/}
                                                    {/*            icon='sign in'*/}
                                                    {/*        />*/}
                                                    {/*    }*/}
                                                    {/*/>*/}
                                                    {/*<Link to={"/userEdit/" + user.id}>*/}
                                                    {/*    <Popup*/}
                                                    {/*        content={translate("Düzenle")}*/}
                                                    {/*        trigger={*/}
                                                    {/*            <Button*/}
                                                    {/*                icon='edit'*/}
                                                    {/*                color="blue"*/}
                                                    {/*            />*/}
                                                    {/*        }*/}
                                                    {/*    />*/}
                                                    {/*</Link>*/}
                                                    {/*{userRole != "HeadAdmin" &&*/}

                                                    {/*    (user.isDeleted == false ?*/}
                                                    {/*        <Popup*/}
                                                    {/*            content={translate("Sil")}*/}
                                                    {/*            trigger={*/}
                                                    {/*                <Button*/}
                                                    {/*                    onClick={(x) => deleteUser(user)}*/}
                                                    {/*                    color="red"*/}
                                                    {/*                    icon='trash'*/}
                                                    {/*                />*/}
                                                    {/*            }*/}
                                                    {/*        />*/}
                                                    {/*        :*/}
                                                    {/*        <Popup*/}
                                                    {/*            content={translate("Silmeyi Geri Al")}*/}
                                                    {/*            trigger={*/}
                                                    {/*                <Button*/}
                                                    {/*                    onClick={(x) => undoDeleteUser(user)}*/}
                                                    {/*                    color="green"*/}
                                                    {/*                    icon='undo'*/}
                                                    {/*                />*/}
                                                    {/*            }*/}
                                                    {/*        />*/}
                                                    {/*    )*/}
                                                    {/*}*/}
                                                </div>

                                            </Transition>


                                        </Table.Cell>

                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </Transition>

                <Transition
                    visible={showModuleCantAdded}
                    animation='scale'
                    duration={500}
                >
                    <Modal
                        style={styles.messageModal}
                        open={showModuleCantAdded}
                        centered={true}
                        onClose={() => setShowModuleCantAdded(false)}
                    >
                        <Modal.Header>Hata</Modal.Header>
                        <Modal.Content
                            image
                            scrolling
                        >
                            <Modal.Description style={{ width: "100%" }}>

                                {showModuleCantAdded && moduleCantAddedList.length > 0 &&
                                    <div>
                                        <h3>Modüle Kaydedilemeyen Çalışan Listesi</h3>
                                        <Table basic='very'>
                                            <Table.Header>
                                                <Table.Row>
                                                    {[translate("Ad"), translate("Soyad"), translate("E-Mail"), translate("Sebep")].map((item) =>
                                                        <Table.HeaderCell>{item}</Table.HeaderCell>
                                                    )}
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {moduleCantAddedList.map((item: any, index) =>
                                                    <Table.Row key={index}>

                                                        <Table.Cell>{item.name}</Table.Cell>
                                                        <Table.Cell>{item.surName}</Table.Cell>
                                                        <Table.Cell>{item.email}</Table.Cell>
                                                        <Table.Cell>{item.message}</Table.Cell>


                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                }

                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                onClick={() => {
                                    setShowModuleCantAdded(false)
                                }}
                            >
                                {translate("Kapat")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Transition>


                {
                    users.length > 0 &&
                    <div style={styles.paginationBox}>
                        {paginationHtml}
                    </div>
                }
            </div>
        </div>

    );
}

export default UserList;