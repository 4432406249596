import React, {useEffect, useState} from 'react';
import {Header} from "semantic-ui-react";
import DataTable from "../../../components/DataTable";
import {IDataTable} from "../../../interfaces/interfaces";
import {digitallicaConfirm, prepareDataForTable} from "../../../helper/HelperFunctions";
import TranslateHelper from "../../../helper/TranslateHelper";
import {NetworkRequest} from "../../../helper/NetworkRequest";
import {toast} from "react-toastify";

const LanguageKeywords = () => {
    var {translate} = TranslateHelper();
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isLader, setLoader] = useState(false);
    
    const deleteTranslationKeyword = (id) => {
        digitallicaConfirm(
            translate("Silmek istediğinize emin misiniz?"),
            () => {
                let word = {
                    id: id
                }
                NetworkRequest("/api/Translation/DeleteWord", word).then((response) => {
                    ////
                    if (response.data.resultCode === 0) {
                        let filterData = {
                            filterText: filterText,
                            currentPage: currentPage,
                        }
                        getKeywords(filterData);
                    } else {
                        toast(translate("Bir hata meydana geldi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
                    }
                });
            },
            () => {
                toast(translate("İşlem iptal edildi."), {type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500});
            }
        );
    }
    
    let transactionArr = [
        {
            icon: "edit",
            desc: translate("Düzenle"),
            color: "blue",
            link: "/editTranslationKeyword",
            type: "edit",

        },
        {
            icon: "trash",
            desc: translate("Sil"),
            color: "red",
            link: null,
            type: "delete",
            function: deleteTranslationKeyword
        }
    ]

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }
    
    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            translate("Kelime"),
            translate("İşlemler"),
        ],
        data: [
            {
                showData: [],
                secretData: [
                    {id: ""},
                    {isDeleted: false}
                ]
            }
        ],
        transactions: transactionArr,
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                
            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "/addTranslationKeyword",
        noCheckBox: true
    });

    //prepare data for table
    const prepareData = (response) => {
        let data = response.list;

        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }

        let model = prepareDataForTable(data, [
            'id',
            'createDateTime',
            'language',
            'languageId',
            'translatedWord'
        ]);
        ////console.log("dataTableModel.filter", dataTableModel.filter);
        //setDataTableModel({
        //    header: dataTableModel.header,
        //    data: [],
        //    transactions: dataTableModel.transactions,
        //    filter: dataTableModel.filter,
        //    pagination: dataTableModel.pagination,
        //    addRowLink: dataTableModel.addRowLink
        //});
        setTimeout(() => {

            setDataTableModel({
                ...dataTableModel,
                data: model,
                filter: {
                    setSearch: setFilterText,
                    search: filterText,
                    filterOption: [
                        // {
                        //     desc: translate("Aktif"),
                        //     defaultValue: isActive,
                        //     key: "isActive",
                        //     type: "boolean",
                        //     onChange: onChangeActive
                        // },
                        // {
                        //     desc: translate("Silinen"),
                        //     defaultValue: isDeleted,
                        //     key: "isDeleted",
                        //     type: "boolean",
                        //     onChange: onChangeDeleted
                        // },
                        // {
                        //     desc: translate("Tarih"),
                        //     defaultValue: [startingDate, dueDate],
                        //     key: "date",
                        //     type: "date",
                        //     onChange: [onChangeDate1, onChangeDate2]
                        // }
                    ]
                },
                pagination: {
                    count: Math.ceil(
                        response.count / 25
                    ), setCurrentPage: setCurrentPage, currentPage: currentPage
                }
            });

        }, 100);


        setIsLoading(false);
    }

    //get table data from database
    const getKeywords = (filterData?) => {
        if (filterData == undefined)
            filterData = { filterText: filterText,
                currentPage: currentPage }
        NetworkRequest("/api/Translation/GetKeywords", filterData).then((response) => {
            ////
            if (response.data.resultCode == 0) {
                prepareData(response.data.detail.detail);
                setLoader(false)
            }
        });
    }
    
    useEffect(()=> {
        getKeywords();
    },[])

    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
        }
        getKeywords(filterData);
    }, [filterText, currentPage]);
    
    return (
        <div style={{marginTop:"5%"}}>
            <Header as='h2'>Kelimeler</Header>

            <DataTable dataTable={dataTableModel} isAllSelected={isAllSelected}
                        selectAllHandler={selectAllHandler}
                       isLoading={isLoading} />
        </div>
    );
};

export default LanguageKeywords;