import * as React from 'react';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Button, Input, TextArea} from 'semantic-ui-react';
import BuddyReCaptcha from '../../../components/BuddyReCaptcha';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import TranslateHelper from "../../../helper/TranslateHelper";
import '../Home.css';
import {v4 as uuidv4} from 'uuid';
import {DataHandler} from "../../../helper/DataHelper";



const ContactUsForm = (props) => {
    var {translateFromEng} = TranslateHelper();
    const [isCaptchaOk, setIsCaptchaOk] = useState(true);

    const [formFields, setFormField] = useState({
        fullname: "",
        email: "",
        country: "",
        comment: ""
    })
    const handleChange = (e) => {
        formFields[e.target.name] = e.target.value;
        setFormField(formFields);
    }

    const sendForm = () => {
        let fullname = formFields.fullname.trim();
        let email = formFields.email.trim();
        let country = formFields.country.trim();
        let comment = formFields.comment.trim();

        if ((fullname.length == 0 || fullname == "")
            || (email.length == 0 || email == "")
            || (country.length == 0 || country == "")
            || (comment.length == 0 || comment == "")) {
            toast(translateFromEng("Please fill in all fields"),
                {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
        } else if (!isCaptchaOk) {
            toast(translateFromEng("Please verify captha"),
                {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
        } else {
            NetworkRequest("/api/Home/ContactMe", {
                nameSurname: fullname,
                email: email,
                country: country,
                message: comment
            }).then((response) => {
                toast(translateFromEng(response.data.message), {
                    type: response.data.resultCode == 0 ? 'success' : 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
                if (response.data.resultCode == 0) {

                    setFormField({
                        fullname: "",
                        email: "",
                        country: "",
                        comment: ""
                    });

                    setIsCaptchaOk(true);

                }
            });
        }
    }

    return (
        <>
            <Input onChange={handleChange} name="fullname" className="parnerInput" type="text" placeholder={translateFromEng("Full Name")}
                   size="huge"/>
            <Input onChange={handleChange} name="email" className="parnerInput" type="email" placeholder={translateFromEng("Email")}
                   size="huge"/>
            <Input onChange={handleChange} name="country" className="parnerInput" type="text" placeholder={translateFromEng("Country")}
                   size="huge"/>
            <TextArea onChange={handleChange} name="comment" className="parnerInput"
                      placeholder={translateFromEng("Start the conversation")} style={{minHeight: 160, with: "100%"}}/>

            {(DataHandler.getGAKey() != ""
                    && DataHandler.getGAKey() != undefined
                    && DataHandler.getGAKey() != null
                    // && !DataHandler.getGAKey()
                    && DataHandler.getSiteKey() != undefined
                    && DataHandler.getSiteKey() != null
                    // && !DataHandler.getSiteKey()
                    && DataHandler.getSiteKey() != "") &&
                <BuddyReCaptcha
                    key={uuidv4().toString()}
                    setIsFormOk={setIsCaptchaOk}
                />
            }


            <Button disabled={!isCaptchaOk} onClick={sendForm} size="big" className="homeV2StartedButton textColorWhite"
                    circular>{translateFromEng("Apply Now")}</Button>
        </>
    );
}

export default ContactUsForm;