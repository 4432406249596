import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardHeader from '../../components/DashboardHeader';
import BreadcrumbComp from '../../components/Breadcrumb';
import Navbar from '../../components/Navbar';
import LeftNavbar from '../../components/LeftNavbar';
import SurveyPersonDetailReport from "./Reports/SurveyPersonDetailReport";
import { NetworkRequest } from '../../helper/NetworkRequest';

import { media, style } from 'typestyle';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { DataHandler } from '../../helper/DataHelper';
import TranslateHelper from "../../helper/TranslateHelper";

function AdminLayout(props) {
    var {translate} = TranslateHelper();
    const history = useNavigate();
    const location = useLocation();
    const [breadCrumbHtml, setBreadCrumbHtml] = useState<any>();
    const [isOpenLeftNav, setIsOpenLeftNav] = useState<boolean>(!isMobile);

    const [leftNavbarBgColor, setLeftNavbarBgColor] = useState("#ffffff");
    const [headerBgColor, setHeaderBgColor] = useState("#ffffff");
    const [menuItemBgColor, setMenuItemBgColor] = useState("transparent");
    const [menuItemBgColorHover, setMenuItemBgColorHover] = useState("transparent");
    const [menuItemTextColor, setMenuItemTextColor] = useState("#000000");
    const [menuItemTextColorHover, setMenuItemTextColorHover] = useState("#000000");
    const [menuItemIconColor, setMenuItemIconColor] = useState("#000000");
    const [menuItemIconColorHover, setMenuItemIconColorHover] = useState("#000000");
    const [menuItemMarkerColor, setMenuItemMarkerColor] = useState("#000000");


    let styles = {
        adminLayoutStyle: {
            width: isOpenLeftNav ? "76%" : "87%",
            padding: "1em",
            marginLeft: isOpenLeftNav ? "302px" : "136px",
            transition: "all 0.5s ease-in-out 0s",
            backgroundColor: "white",
            borderRadius: "10px",
            position: "absolute" as "absolute"
        },
        breadcrumbStyle: {
            paddingLeft: "1.3em",
            paddingTop: "8.2rem",
            marginLeft: "113px",
            transition: "all 0.5s ease-in-out 0s",
            position: "absolute" as "absolute",
            zIndex: "2"

        },
        version: {
            textAlign: "right" as "right",
            right: "1rem",
            opacity: ".71",
            fontStyle: "italic",
            userSelect: "none" as "none",
            bottom: ".5rem",
            fontVariant: "small-caps",
            fontVariantCaps: "petite-caps" as "petite-caps",
            position: "fixed" as "fixed"
        },
        footer: style({
            width: "100%",
            backgroundColor: "#292828",
            textAlign: "center" as "center",
            bottom: "0",
            color: "white",

            display: "flex",
            alignItems: "center",
            flexDirection: "column" as "column",
            justifyContent: "center",
            position: "absolute" as "absolute",
            height: "2.5rem",

        },
            media({ maxWidth: 820 })
        ),
        container: {
            position: "relative" as "relative",
            minHeight: "100vh"
        }
    }

    let breadCrumbArray = {
        users: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Çalışanlar"), Link: "#" }
        ],
        userAdd: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Çalışanlar"), Link: "/users" },
            { Menu: translate("Çalışan ekle"), Link: "#" }
        ],
        userEdit: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Çalışanlar"), Link: "/users" },
            { Menu: translate("Çalışan Düzenle"), Link: "#" }
        ],
        userImport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Çalışanlar"), Link: "/users" },
            { Menu: translate("Toplu Çalışan Ekleme"), Link: "#" }
        ],
        quizes: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınavlar"), Link: "#" }
        ],
        AddQuiz: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınavlar"), Link: "/quizes" },
            { Menu: translate("Sınav Ekle"), Link: "#" }
        ],
        EditQuiz: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınavlar"), Link: "/quizes" },
            { Menu: translate("Sınav Düzenle"), Link: "/#" }
        ],
        questions: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınav Sorular"), Link: "#" }
        ],
        questionImport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınav Sorular"), Link: "/questions" },
            { Menu: translate("Toplu Sınav Sorusu Ekleme"), Link: "#" }
        ],
        inquiryContentList: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anket Sorular"), Link: "#" }
        ],
        questionAdd: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınav Sorular"), Link: "/questions" },
            { Menu: translate("Sınav Soru Ekle"), Link: "#" }
        ],
        inquiryContentAdd: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anket Sorular"), Link: "/inquiryContentList" },
            { Menu: translate("Anket Soru Ekle"), Link: "#" }
        ],
        inquiryContentEdit: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anket Sorular"), Link: "/inquiryContentList" },
            { Menu: translate("Anket Soru Düzenle"), Link: "#" }
        ],
        questionEdit: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınav Sorular"), Link: "/questions" },
            { Menu: translate("Sınav Soru Düzenle"), Link: "#" }
        ],
        quizContentPool: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sınavlar"), Link: "/quizes" },
            { Menu: translate("Sınava Soru Havuzundan Soru Ekle"), Link: "#" }
        ],
        modules: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Modüller"), Link: "#" }
        ],
        moduleAdd: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Modüller"), Link: "/modules" },
            { Menu: translate("Modül Ekle"), Link: "#" }
        ],
        moduleEdit: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Modüller"), Link: "/modules" },
            { Menu: translate("Modül Düzenle"), Link: "#" }
        ],
        surveyList: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anketler"), Link: "#" }
        ],
        addSurvey: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anketler"), Link: "/surveyList" },
            { Menu: translate("Anket Ekle"), Link: "#" }
        ],
        editSurvey: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anketler"), Link: "/surveyList" },
            { Menu: translate("Anket Düzenle"), Link: "#" }
        ],
        categoryList: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Kategoriler"), Link: "#" }
        ],
        addCategory: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Kategoriler"), Link: "/categoryList" },
            { Menu: translate("Kategori Ekle"), Link: "#" }
        ],
        editCategory: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Kategoriler"), Link: "/categoryList" },
            { Menu: translate("Kategori Düzenle"), Link: "#" }
        ],
        surveyContentPool: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Anketler"), Link: "/surveyList" },
            { Menu: translate("Ankete Soru Havuzundan Soru Ekle"), Link: "#" }
        ],
        activities: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Etkinlikler"), Link: "#" }
        ],
        activityImport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Etkinlikler"), Link: "/activities" },
            { Menu: translate("Toplu Etkinlik Ekleme"), Link: "#" }
        ],
        activityAdd: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Etkinlikler"), Link: "/activities" },
            { Menu: translate("Etkinlik Ekle"), Link: "#" }
        ],
        activityEdit: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Etkinlikler"), Link: "/activities" },
            { Menu: translate("Etkinlik Düzenle"), Link: "#" }
        ],
        mailTemplates: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Bildirimler"), Link: "#" }
        ],
        mailTemplateAdd: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Bildirimler"), Link: "/mailTemplates" },
            { Menu: translate("Bildirim Ekle"), Link: "#" }
        ],
        addPopupFirstLogin: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Ayarlar"), Link: "/settings" },
            { Menu: translate("Kullanım Koşulları Ekle"), Link: "#" }
        ],
        reports: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "#" }
        ],
        settings: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Ayarlar"), Link: "#" }
        ],
        documents: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Dokümanlar"), Link: "#" },

        ],
        addDocument: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Dokümanlar"), Link: "/documents" },
            { Menu: translate("Doküman Ekle"), Link: "#" }
        ],
        editDocument: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Dokümanlar"), Link: "/documents" },
            { Menu: translate("Doküman Düzenle"), Link: "#" }
        ],
        employeeReport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "/reports" },
            { Menu: translate("Çalışan Raporları"), Link: "/reports?page=2" },
            { Menu: translate("Çalışan"), Link: "#" },
        ],
        capsuleReport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "/reports" },
            { Menu: translate("Modül Raporları"), Link: "/reports?page=3" },
            { Menu: translate("Modül"), Link: "#" },

        ],
        qizQestionReport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "/reports" },
            { Menu: translate("Sınav Raporları"), Link: "/reports?page=4" },
            { Menu: translate("Sınav"), Link: "#" },
        ],
        examReportDetail: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "/reports" },
            { Menu: translate("Sınav Raporları"), Link: "/reports?page=4" },
            {
                Menu: translate("Sınav"), Link: null, Function: () => {
                    window.history.go(-1);
                }
            },
            { Menu: translate("Sınav Soruları Raporu"), Link: "#" },
        ],
        srvyPersonDetailReport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "/reports" },
            { Menu: translate("Anket Raporları"), Link: "/reports?page=5" },
            { Menu: translate("Anket"), Link: "#" },
        ],
        srvyQstnDetailReport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Raporlar"), Link: "/reports" },
            { Menu: translate("Anket Raporları"), Link: "/reports?page=5" },
            {
                Menu: translate("Anket"), Link: null, Function: () => {
                    window.history.go(-1);
                }
            },
            { Menu: translate("Anket Soruları Raporu"), Link: "#" },
        ],
        scheduledJobList: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Bildirimler"), Link: "#" },
        ],
        addScheduledJob: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Bildirimler"), Link: "/scheduledJobList" },
            { Menu: translate("Bildirimler Ekle"), Link: "#" }
        ],
        editScheduledJob: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Bildirimler"), Link: "/scheduledJobList" },
            { Menu: translate("Bildirimler Düzenle"), Link: "#" }
        ],
        certificates: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sertifikalar"), Link: "#"},

        ],
        addCertificate: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sertifikalar"), Link: "/certificates" },
            { Menu: translate("Sertifika Ekle"), Link: "#" }
        ],
        editCertificate: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sertifikalar"), Link: "/certificates" },
            { Menu: translate("Sertifika Düzenle"), Link: "#" }
        ],
        dictionary: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "#" },
        ],
        addDictionary: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "/dictionary" },
            { Menu: translate("Sözlük Ekle"), Link: "#" },
        ],
        addWord: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "/dictionary" },
            { Menu: translate("Kelime Ekle"), Link: "#" },
        ],
        editWord: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "/dictionary" },
            { Menu: translate("Kelime Listesi"), Link: "/wordList" },
            { Menu: translate("Kelime Güncelle"), Link: "#" },
        ],
        applicationHelpers: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sıkça Sorulan Sorular"), Link: "#" }
        ],
        addApplicationHelper: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sıkça Sorulan Soru Tipleri"), Link: "/applicationHelpers" },
            { Menu: translate("Soru Ekle"), Link: "#" },
        ],
        editApplicationHelper: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sıkça Sorulan Soru Tipleri"), Link: "/applicationHelpers" },
            { Menu: translate("Soru Güncelle"), Link: "#" },
        ],
        applicationHelperTypes: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sıkça Sorulan Soru Tipleri"), Link: "#" }
        ],
        addHelperType: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sıkça Sorulan Soru Tipleri"), Link: "/applicationHelperTypes" },
            { Menu: translate("Tip Ekle"), Link: "#" },
        ],
        editHelperType: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sıkça Sorulan Soru Tipleri"), Link: "/applicationHelperTypes" },
            { Menu: translate("Tip Güncelle"), Link: "#" },
        ],
        wordList: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "/dictionary" },
            { Menu: translate("Kelime Listesi"), Link: "#" },
        ],
        dictionarySettings: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "/dictionary" },
            { Menu: translate("Sözlük Ayarları"), Link: "#" },
        ],
        dictionaryExcelImport: [
            { Menu: translate("Anasayfa"), Link: "/" },
            { Menu: translate("Sözlük"), Link: "/dictionary" },
            { Menu: translate("Toplu Kelime Ekle"), Link: "#" },
        ]
    };

    const getTheme = () => {
        if (DataHandler.getUserDataWithKey("tenantId") == undefined) {
            return;
        }
        let data = {
            TenantId: DataHandler.getUserDataWithKey("tenantId").toString()
        };

        NetworkRequest("/api/SiteSetting/GetTheme", data).then((response) => {
            if (response.data.resultCode === 0) {

                if (response.data.detail != null) {
                    let themeJSON = JSON.parse(response.data.detail);

                    setLeftNavbarBgColor(themeJSON.LeftNavbarBgColor);
                    setHeaderBgColor(themeJSON.HeaderBgColor);
                    setMenuItemBgColor(themeJSON.MenuItemBgColor);
                    setMenuItemBgColorHover(themeJSON.MenuItemBgColorHover);
                    setMenuItemTextColor(themeJSON.MenuItemTextColor);
                    setMenuItemTextColorHover(themeJSON.MenuItemTextColorHover);
                    setMenuItemIconColor(themeJSON.MenuItemIconColor);
                    setMenuItemIconColorHover(themeJSON.MenuItemIconColorHover);
                    setMenuItemMarkerColor(themeJSON.MenuItemMarkerColor);
                }
            }
        });
    }

    useEffect(() => {
        let pathname = (location.pathname).slice(1).split("/")[0];
        ////console.log(pathname);
        if (pathname) {
            setBreadCrumbHtml(
                <BreadcrumbComp
                    BreadcrumbProps={breadCrumbArray[pathname]}
                    BreadcrumbContainerStyle={null } 
                />
            );
        }
    }, [location]);

    useEffect(() => {
        getTheme();
    }, []);

    window.document.body.addEventListener("click", function (e: any) {
        if (isMobile) {
            if ((e?.target?.className != 'leftNavbarContainer') && (e?.target?.className != "list big icon")) {
                setIsOpenLeftNav(false);
            }
        }
    });

    return (
        <div>
            <LeftNavbar
                LogoUrl={props.LogoUrl}
                Open={isOpenLeftNav}
                CloseLeftNavbar={setIsOpenLeftNav}
                BgColor={leftNavbarBgColor}
                MenuItemTextColor={menuItemTextColor}
                MenuItemTextColorHover={menuItemTextColorHover}
                MenuItemIconColor={menuItemIconColor}
                MenuItemIconColorHover={menuItemIconColorHover}
                MenuItemMarkerColor={menuItemMarkerColor}
                MenuItemBgColor={menuItemBgColor}
                MenuItemBgColorHover={menuItemBgColorHover}
            />
            <Navbar
                IsDefaultSetting={props.isDefaultSetting}
                LogoUrl={props.LogoUrl}
                CloseLeftNavbar={setIsOpenLeftNav}
                OpenLeftNavState={isOpenLeftNav}
                BgColor={headerBgColor}
            />
            {location.pathname !== "/" &&
                <div className={"breadCrumbHtml"} style={styles.breadcrumbStyle}>
                    {breadCrumbHtml}
                </div>
            }
            <div className={"adminLayoutContentContainer"} style={styles.adminLayoutStyle}>
                {props.children}
            </div>
        </div>
    );
}

export default AdminLayout;