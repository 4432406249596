import * as React from 'react';
import {useEffect, useRef, useState, memo} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Input,
    Label,
    Loader,
    Modal,
    Popup,
    Progress,
    Transition
} from "semantic-ui-react";
import {Link, useAsyncError, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ILanguageDropdown, IStatus, IUser} from "../../../interfaces/interfaces";
import {isMobile} from 'react-device-detect';
import {NetworkRequest} from '../../../helper/NetworkRequest';
import {toast} from 'react-toastify';
import {v4 as uuidv4} from 'uuid';
import {
    checkFieldsIsFilled,
    digitallicaConfirm,
    isNullOrEmpty,
    logoutApi,
    scrollToElem
} from '../../../helper/HelperFunctions';
import DictionaryViewer from '../../../components/Dictionary/DictionaryViewer';
import TeamContactList from '../CoursePageComponents/TeamContactList';
import DocumentsList from '../CoursePageComponents/DocumentsList';
import LibrarySearch from '../CoursePageComponents/LibrarySearch';
import CertificatePrinter from '../../../Certificate/CertificatePrinter';
import CustomModal from '../../../components/CustomModal';
import TranslateHelper from '../../../helper/TranslateHelper';
import {motion} from "framer-motion";
import PasswordPolicyChecker from "../../../components/PasswordPolicyChecker/PasswordPolicyChecker";
import {displayProtectedAppDoc} from '../../../helper/DisplayProtectedDocument';
import {DataHandler} from '../../../helper/DataHelper';
import HelperSearch from '../CoursePageComponents/HelperSearch';
import HelperViewer from '../CoursePageComponents/HelperViewer';
import FixedBottomMenu from "../ModuleDetail/components/FixedBottomMenu";
import HomePageModuleRenderer from "../components/HomePageModuleRenderer";
import ModuleListByCategory from "../components/ModuleListByCategory";
import loadingGif from '../../../images/loading-gif.gif';

import DefaultProfileImage from '../../../images/user.png';
import {json} from 'node:stream/consumers';
import { DateFormatDatePicker } from '../../../helper/DateFormat';
import DatePicker from 'react-datepicker';

const HomePageV2 = (props) => {
    const [searchParams] = useSearchParams();

    const [userId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [userEmail] = useState(DataHandler.getUserDataWithKey("Email"));
    const [courseList, setCourseList] = useState<any>();
    const [defaultUserImage] = useState<string>('/Documents/DefaultUserImage/user.png');
    const [modalImage, setModalImage] = useState<any>("");
    const navigate = useNavigate();
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("");
    const [logoUrl, setLogoUrl] = useState(DataHandler.getUserDataWithKey("logoUrl"));

    var {translate} = TranslateHelper();
    let {id, viewMode, registrationId} = useParams();
    let isSectionEvent = false;
    let _ = require('lodash');
    const [loader, setLoader] = useState(true);
    const [showPartial, setShowPartial] = useState(false); // amacı modülü olmayan kullanıcının sözlük ve dosyaları görebilmesini sağlamak.
    const [isQrHook, setIsQrHook] = useState(false);
    const [isShownAllContentCompleted, setIsShownAllContentCompleted] = useState(false);
    const [isFocusContent, setIsFocusContent] = useState(false);
    const [contentLoading, setContentLoading] = useState(false);
    const [showDictionaryViewer, setShowDictionaryViewer] = useState(false);
    const [showHelperViewer, setShowHelperViewer] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [showFabIcon, setShowFabIcon] = useState(false);
    const [showSendMessage, setShowSendMessage] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [showCelebration, setShowCelebration] = useState(true);
    const [isMailOk, setIsMailOk] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [showTasks, setShowTasks] = useState(true);
    const [editorContent, setEditorContent] = useState("");
    const [mailTitle, setMailTitle] = useState("");
    const [isLogin, setIsLogin] = useState(DataHandler.getUserDataWithKey("isLogin") != undefined ? JSON.parse(DataHandler.getUserDataWithKey("isLogin")) : false);
    const [forceLogin, setForceLogin] = useState(DataHandler.getUserDataWithKey("forceLogin") != undefined ? JSON.parse(DataHandler.getUserDataWithKey("forceLogin")) : false);
    const [tenantId] = useState(DataHandler.getUserDataWithKey("tenantId"));
    const [selectedBg, setSelectedBg] = useState("");
    const [selectedModuleId, setSelectedModuleId] = useState<any>(id);
    const [registrationIdHook, setRegistrationIdHook] = useState<any>(registrationId);
    const [viewModeHook] = useState<any>(viewMode);
    const [loginUserId] = useState(DataHandler.getUserDataWithKey("userId"));
    const [loginUserEmail] = useState(DataHandler.getUserDataWithKey("email"));
    const [userRole, setUserRole] = useState(DataHandler.getUserDataWithKey("userRole"));
    const [userPhoto, setUserPhoto] = useState(DataHandler.getUserDataWithKey("photo"));
    const [transactionUserId, setTransactionUserId] = useState("");
    const [dicId, setDicId] = useState();
    const [dicSearchWord, setDicSearchWord] = useState("");
    const [helperSearchWord, setHelperSearchWord] = useState("");
    const [bodyScrollTop, setBodyScrollTop] = useState<any>(0);
    const [sectionProgress, setSectionProgress] = useState(0);
    const [sessionComplateCount, setSessionComplateCount] = useState(0);
    const [hoveredSection, setHoveredSection] = useState<any>();
    const [selectedUserForMail, setSelectedUserForMail] = useState({email: "", name: "", surname: "", photo: ""});
    const [homePageFilterLoader, setHomePageFilterLoader] = useState(false);
    const [filterLock, setFilterLock] = useState(false);

    const [endUserModuleFilterData, setEndUserModuleFilterData] = useState<any>();

    interface ObjectLiteral {
        [key: string]: any;
    }

    const [selectedSectionAndContent, setSelectedSectionAndContent] = useState<ObjectLiteral>({
        selectedContent: {id: "", title: ""},
        selectedSection: {id: "", description: ""}
    });
    const [nextContentModel, setNextContentModel] = useState<any>();
    const [eventRequest, setEventRequest] = useState({selectedDate: '', user: {}, data: {}});
    const [baseApiModule, setBaseApiModule] = useState<any>();
    const [moduleAccessModel, setModuleAccessModel] = useState<any>();
    const [apiModule, setApiModule] = useState<any>({
        id: "",
        title: "",
        description: "",
        isActive: null,
        continueAccessAfterDone: false,
        sectionList: []
    });
    const [loginUser, setLoginUser] = useState<IUser | any>({});
    const [updateUser, setUpdateUser] = useState<IUser | any>({});
    const [transactionUser, setTransactionUser] = useState<IUser | any>({});
    const [userModuleRegisteration, setUserModuleRegisteration] = useState<any>({});
    const [statuses, setStatuses] = useState<Array<IStatus>>([]);
    const [moduleHistoryList, setModuleHistoryList] = useState<any[]>([]);
    const [statusLogs, setStatusLogs] = useState<Array<any>>([]);
    const [responsibleUsers, setResponsibleUsers] = useState<any>([]);

    const [isOpenTaskContentForMobile, setIsOpenTaskContentForMobile] = useState(false);

    const [isOpenSlidePanel, setIsOpenSlidePanel] = useState(false);
    const [isOpenSubSlidePanel, setIsOpenSubSlidePanel] = useState(false);
    const [currentSubHeader, setCurrentSubHeader] = useState("");
    const [profilePictureHover, setProfilePictureHover] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);
    const [isUpdateStarting, setIsUpdateStarting] = useState(false);
    const fileInputRef = useRef<any>();
    const [userPassword, setUserPassword] = useState<any>({
        Email: loginUserEmail,
        TenantId: DataHandler.getUserDataWithKey("tenantId")
    })

    const [isPasswordMatchError, setIsPasswordMatchError] = useState(false);
    const [isShowCurrentPassword, setIsShowCurrentPassword] = useState(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState(false);
    const [isShowReNewPassword, setIsShowReNewPassword] = useState(false);

    const [isUserFirstEntryPassword, setIsUserFirstEntryPassword] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);

    const [isShowPasswordPolicyChecker, setIsShowPasswordPolicyChecker] = useState(false);
    const [passwordPolicyResponseList, setPasswordPolicyResponseList] = useState<any>();
    const [isPasswordPolicyAllDone, setIsPasswordPolicyAllDone] = useState<any>(false);
    const [isCloseAllPasswordSettings, setIsCloseAllPasswordSettings] = useState(false);
    const [requestedSectionIndex, setRequestedSectionIndex] = useState(-1);

    const [page, setPage] = useState(1);
    const [filterText, setFilterText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);

    const [isLdapUser, setIsLdapUser] = useState(false);


    const styles = {
        imgContainer: {
            display: "flex",
            alignItems: "center",
            width: "20%"
        },
        nameAndTitleContainer: {
            display: "flex",
            flexDirection: "column" as "column",
            width: "80%"
        },
        name: {
            color: "#fff39c",
            fontWeight: "bold"
        },
        title: {
            color: "#d7d7e5"
        },
        profileImage: {
            borderRadius: "50%"
        },
        leftSide: {
            display: "flex",
            flexDirection: "row" as "row",

            width: "90%",
            gap: "1rem",
            alignItems: "center"

        },
        rightSide: {
            display: "flex",
            flexDirection: "column" as "column",
            alignItems: "center",
            width: "auto",
            justifyContent: "center",
            gap: ".2rem",
            position: "absolute" as "absolute",
            top: "1%",
            right: "2%"

        },
        user: {
            display: "flex",
            flexDirection: "row" as "row",
            border: "1px solid #c1c1c1",
            padding: ".5rem"
        },
        borderColors: [{borderLeft: "10px solid #05afec"}, {borderLeft: "10px solid #72309e"}, {borderLeft: "10px solid #ff0163"}],
    }

    const getAssignedCourses = (filterData) => {
        setHomePageFilterLoader(true);

        NetworkRequest("api/ModuleUserRegistrations/GetAssignedsByUserId", {
            id: userId,
            pagination: filterData
        }).then(response => {
            if (response.data.resultCode == 0) {
                // //debugger;
                setCourseList(response.data.detail);
                setLoader(false);
                setHomePageFilterLoader(false);
            } else {
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });
    }

    const getAssignedCoursesByFilter = (filterData) => {
        if (filterLock) {
            setHomePageFilterLoader(true);

            NetworkRequest("api/ModuleUserRegistrations/GetAssignedsByFilter", {
                id: userId,
                pagination: filterData
            }).then(response => {
                if (response.data.resultCode == 0) {
                    setCourseList(response.data.detail);
                    setHomePageFilterLoader(false);
                } else {
                    toast(translate(response.data.message), {
                        type: 'info',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 3500
                    });
                }
            });
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (endUserModuleFilterData?.filterText?.trim()?.length > 3) {
                getAssignedCoursesByFilter(endUserModuleFilterData)

            } else if (endUserModuleFilterData?.filterText?.trim()?.length == 0 && (endUserModuleFilterData.startingDate == null && endUserModuleFilterData.dueDate == null && endUserModuleFilterData.status == "") && filterLock) {
                getAssignedCourses(endUserModuleFilterData);
            } else {
                getAssignedCoursesByFilter(endUserModuleFilterData)
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, [endUserModuleFilterData?.filterText])

    useEffect(() => {

        if (endUserModuleFilterData?.filterText?.trim()?.length == 0 && (endUserModuleFilterData.startingDate == null && endUserModuleFilterData.dueDate == null && endUserModuleFilterData.status == "" && endUserModuleFilterData.currentPage == 1) && filterLock) {
            getAssignedCourses(endUserModuleFilterData);
        } else if (endUserModuleFilterData?.filterText?.trim()?.length != 0 || (endUserModuleFilterData.startingDate != null || endUserModuleFilterData.dueDate != null || endUserModuleFilterData.status != "") && filterLock) {
            getAssignedCoursesByFilter(endUserModuleFilterData);
        }


    }, [endUserModuleFilterData])

    // query string useEffect
    // useEffect(() => {
    //     let category = searchParams.get('moduleCategory');
    //    
    //    
    // }, [searchParams.get('moduleCategory')]);

    const getAssignedCoursesCount = () => {
        let user = {
            Email: userEmail
        };
        NetworkRequest("api/ModuleUserRegistrations/GetAssignedsCountByUserEmail", user).then((response) => {
            if (response.data.resultCode == 0) {
                setDataCount(response.data.detail)
            } else {
                toast(translate(response.data.message), {
                    type: 'info',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3500
                });
            }
        });
    }

    //
    // const resolveHandler = (value) => {
    //     return value;
    // }

    // const promiseMaker = (value) => {
    //     return new Promise(function (resolve, reject) {
    //         resolve(resolveHandler(value));
    //         reject('Promise Hata');
    //     });
    // }

    // const getToastMessage = (sectionOrContent) => {
    //     let accessModel: any = null;
    //     if (sectionOrContent.moduleSectionId != undefined) {
    //         accessModel = getContentAccess(sectionOrContent.id, sectionOrContent.moduleSectionId);
    //     }
    //     let mes = "";
    //     if (accessModel.message != undefined && accessModel.accessMessage != undefined) {
    //         mes = accessModel.message + " " + accessModel.accessMessage;
    //     } else if (accessModel.message != undefined && accessModel.accessMessage == undefined) {
    //         mes = accessModel.message;
    //     } else if (accessModel.message == undefined && accessModel.accessMessage != undefined) {
    //         mes = accessModel.accessMessage;
    //     }
    //     return mes;
    // }

    // const getContentAccess = (contentId, sectionId) => {
    //     if (moduleAccessModel == undefined) {
    //         return {
    //             isDisabled: false
    //         };
    //     }
    //     let sectionAccess = _.find(moduleAccessModel.sectionList, (section) => {
    //         return section.sectionId == sectionId;
    //     });
    //     if (sectionAccess == undefined) {
    //         return {
    //             isDisabled: false
    //         };
    //     }
    //     let contentAccess = _.find(sectionAccess.contentList, (cont) => {
    //         return cont.contentId == contentId;
    //     });
    //     if (sectionAccess == undefined) {
    //         contentAccess = {
    //             isDisabled: false
    //         };
    //     }
    //     return contentAccess;
    // }

    // const setContentAccessMessage = (contentId, sectionId, mes) => {
    //     if (moduleAccessModel == undefined) {
    //         return;
    //     }
    //     let control = false;
    //     let clone = _.cloneDeep(moduleAccessModel);
    //     _.each(clone.sectionList, (sec) => {
    //         _.each(sec.contentList, (con) => {
    //             if (sec.sectionId == sectionId && con.contentId == contentId) {
    //                 con.accessMessage = mes;
    //                 control = true;
    //             }
    //         });
    //     });
    //     if (control) {
    //         setModuleAccessModel(clone);
    //     }
    // }

    // const onContentSelect = (content, section?) => {
    //     setSelectedSectionAndContent({...selectedSectionAndContent, selectedContent: {id: "", title: ""}});
    //     toast.dismiss();
    //     setShowDictionaryViewer(false);
    //     toast.dismiss();
    //     setIsLocked(false);
    //     setShowFabIcon(window.innerWidth < 820);
    //     if (isDisabled(content)) {
    //         ////debugger;
    //         toast.dismiss();
    //         let mes = getToastMessage(content);
    //         toast(mes, {type: 'warning', theme: 'dark', position: 'top-center', autoClose: 5000});
    //     }
    //     setTimeout(() => {
    //         setSelectedSectionAndContent({
    //             ...selectedSectionAndContent,
    //             selectedContent: content,
    //             selectedSection: (section == undefined || section == null) ? {id: "", description: ""} : section
    //         });
    //     }, 700);
    // }

    // const getBeforeModuleSection = (sectionId) => {
    //     try {
    //         let data = apiModule;
    //         if (apiModule.id == '') {
    //             data = baseApiModule;
    //         }
    //         let currentSection = _.find(data.sectionList, (doc) => {
    //             return doc.id == sectionId;
    //         });
    //         let before = _.find(data.sectionList, (doc) => {
    //             return doc.rank == (currentSection.rank - 1);
    //         });
    //         return before;
    //     } catch (e) {
    //         ////
    //     }
    // }

    // const getBeforeSectionContent = (contentId) => {
    //     try {
    //         if (isSectionEvent) {
    //             return getBeforeModuleSection(contentId);
    //         }
    //         let data = apiModule;
    //         if (apiModule.id == '') {
    //             data = baseApiModule;
    //         }
    //         let currentSection = _.find(data.sectionList, (doc) => {
    //             return _.some(doc.contentList, (elem) => {
    //                 return elem.id == contentId;
    //             });
    //         });
    //         let currentContent = _.find(currentSection.contentList, (doc) => {
    //             return doc.id == contentId;
    //         });
    //         let before = _.find(currentSection.contentList, (doc) => {
    //             return doc.rank == currentContent.rank - 1;
    //         });
    //         ////console.log('before', before);
    //         return before;
    //     } catch (e) {
    //         ////
    //     }
    // }

    // const getHistory = (moduleId: string, userId: string) => {
    //     moduleId = moduleId == undefined ? selectedModuleId : moduleId;
    //     if (userId == undefined) {
    //         userId = transactionUserId != "" ? transactionUserId : loginUserId;
    //     }
    //     let courseHistory = {
    //         moduleId: moduleId,
    //         userId: userId
    //     }
    //     NetworkRequest("/ModuleHistory/GetDetailHistory", courseHistory).then((response) => {
    //         if (response.data.resultCode === 0) {
    //             let result = response.data.detail;
    //             setModuleHistoryList(result);
    //         }
    //     });
    //     ////console.log(apiModule);
    // }

    // const getStatusLogs = (request) => {
    //     return NetworkRequest('/api/StatusLogs/GetUserLogs', request).then((response) => {
    //         if (response.data.resultCode == 0) {
    //             let list = _.orderBy(response.data.detail, "createDateTime");
    //             setStatusLogs(list);
    //         }
    //         return response;
    //     });
    // }

    // const getSectionWithDetails = (moduleId: string, sectionId: string, userId: string) => {
    //     getHistory(moduleId, userId);
    //     NetworkRequest("/api/Modules/GetSectionWithAllDetails", {
    //         userId: userId,
    //         moduleId: moduleId,
    //         sectionId: sectionId,
    //         includeStatuses: true
    //     }).then((response) => {
    //         ////debugger;
    //         if (response.data.resultCode == 0) {
    //             ////console.log("sectionWithDetails: ", response.data.detail);
    //             let sectionResult = response.data.detail;
    //             let moduleResult = _.cloneDeep(apiModule);
    //             let index = -1;
    //             _.each(moduleResult.sectionList, (sec: any, i: number) => {
    //                 if (sec.id == sectionResult.id) {
    //                     index = i;
    //                 }
    //             });
    //
    //             if (index != -1) {
    //                 moduleResult.sectionList[index] = sectionResult;
    //             }
    //             setBaseApiModule(moduleResult);
    //             //moduleResult = runAccessibilityControls(moduleResult);
    //             setApiModule({
    //                 id: "",
    //                 title: "",
    //                 description: "",
    //                 isActive: null,
    //                 continueAccessAfterDone: false,
    //                 sectionList: []
    //             });
    //             setApiModule(moduleResult);
    //             setTimeout(() => {
    //                 setRequestedSectionIndex(index);
    //             }, 500);
    //         }
    //     });
    // }

    // const getModuleWithDetails = (moduleId: string, userId: string) => {
    //     setLoader(true);
    //     getHistory(moduleId, userId);
    //     NetworkRequest("/api/Modules/GetWithAllDetails", {
    //         userId: userId,
    //         moduleId: moduleId,
    //         includeStatuses: true
    //     }).then((response) => {
    //         if (response.data.resultCode === 0) {
    //             ////debugger;
    //             let moduleResult = response.data.detail.moduleView;
    //             let moduleAccess = response.data.detail.moduleAccessModel;
    //             setModuleAccessModel(moduleAccess);
    //             setBaseApiModule(moduleResult);
    //             if (moduleResult.showOnlyCurrentRoleTasks) {
    //
    //                 var sectionListClone = _.cloneDeep(moduleResult.sectionList);
    //
    //                 let currentRole = _.find(loginUser.userRole, (doc) => {
    //                     return doc.role.roleName == DataHandler.getUserDataWithKey("userRole");
    //                 });
    //                 for (var i = 0; i < sectionListClone.length; i++) {
    //                     sectionListClone[i].contentList = sectionListClone[i].contentList.filter(x => x.responsibleRoleId == currentRole.roleId);
    //                 }
    //
    //                 moduleResult.sectionList = sectionListClone.filter(x => x.contentList.length > 0);
    //             }
    //             setApiModule(moduleResult);
    //             toast.dismiss();
    //             setLoader(false);
    //             setIsOpenTaskContentForMobile(true);
    //         }
    //     });
    // }

    // const getLastAssignedModuleByEmail = () => {
    //     let request = {
    //         userId: transactionUserId != "" ? transactionUserId : loginUserId,
    //         email: loginUserEmail
    //     };
    //
    //     NetworkRequest("/api/ModuleUserRegistrations/GetLastAssignedModule", request).then((response) => {
    //         if (response.data.resultCode == 0) {
    //             let result = response.data.detail;
    //             setSelectedModuleId(result.id);
    //             setRegistrationIdHook(result.registerId);
    //         } else {
    //             toast.dismiss();
    //             setShowPartial(true); // amacı modülü olmayan kullanıcının sözlük ve dosyaları görebilmesini sağlamak.
    //             toast(translate("Size atanmış bir modül bulunmamaktadır. Yöneticinizle irtibata geçebilirsiniz."), {
    //                 type: 'warning',
    //                 theme: 'dark',
    //                 position: 'top-center',
    //                 autoClose: 5000
    //             });
    //         }
    //     });
    // }

    // const getModuleWithRegistrationId = (id: string) => {
    //     NetworkRequest("api/ModuleUserRegistrations/GetById", {id: id}).then((response) => {
    //         if (response.data.resultCode == 0) {
    //             let resultUserModuleRegisteration = response.data.detail;
    //             setUserModuleRegisteration(resultUserModuleRegisteration);
    //             setSelectedModuleId(resultUserModuleRegisteration.moduleId);
    //             setTransactionUserId(resultUserModuleRegisteration.userId);
    //             getModuleWithDetails(resultUserModuleRegisteration.moduleId, resultUserModuleRegisteration.userId);
    //         }
    //     });
    // }

    // const getRegistration = () => {
    //     NetworkRequest("/api/ModuleUserRegistrations/GetUserRegistrationId", {
    //         moduleId: props.moduleId,
    //         userId: DataHandler.getUserDataWithKey("userId")
    //     }).then((response) => {
    //         if (response.data.resultCode == 0) {
    //             setRegistrationIdHook(response.data.detail);
    //         }
    //     });
    // }

    // const getResponsibleUsersByUserId = (id: string) => {
    //     if (viewModeHook == "management")
    //         return;
    //     let user = {
    //         id: id
    //     }
    //     NetworkRequest("/api/User/GetResponsibleUsersByUserId", user).then((response) => {
    //         if (response.data.resultCode === 0) {
    //             setResponsibleUsers(response.data.detail);
    //         }
    //     });
    // }

    const getLoginUser = (userId) => {
        NetworkRequest('/api/User/GetViewById', {id: userId}).then((response) => {
            if (response.data.resultCode == 0) {

                setLoginUser(response.data.detail);
        
                setUpdateUser(response.data.detail);

                var jsonUserDetail = response?.data?.detail?.details;

                if (jsonUserDetail)
                    jsonUserDetail = JSON.parse(jsonUserDetail);

                setIsLdapUser(jsonUserDetail?.isLdapUser || false);

                if (jsonUserDetail?.IsLdapUser) {
                    setIsOpenSlidePanel(false)
                }

                setIsFirstLogin(response.data.detail.firstLogin);
            }
        });
    }

    const getResponsibleUsersByUserId = (id: string) => {
        if (viewModeHook == "management")
            return;
        let user = {
            id: id
        }
        NetworkRequest("/api/User/GetResponsibleUsersByUserId", user).then((response) => {
            if (response.data.resultCode === 0) {
                setResponsibleUsers(response.data.detail);
            }
        });
    }

    useEffect(() => {
        if (loginUserId != undefined) {
            getResponsibleUsersByUserId(loginUserId);
            getLoginUser(loginUserId);
        }
    }, [loginUserId]);

    // const getTransactionUser = (userId) => {
    //     NetworkRequest('/api/User/GetViewById', {id: userId}).then((response) => {
    //         ////console.log("getTransactionUser response: ", response);
    //         if (response.data.resultCode == 0) {
    //             setTransactionUser(response.data.detail);
    //         }
    //     });
    // }

    // const isDisabled = (sectionOrContent) => {
    //     var finishedSection = _.find(apiModule.sectionList, (doc) => {
    //         return doc.status != undefined && doc.status.isCompleted == false;
    //     });
    //     let accessModel: any = null;
    //     if (sectionOrContent.moduleSectionId != undefined) {
    //         accessModel = getContentAccess(sectionOrContent.id, sectionOrContent.moduleSectionId);
    //     }
    //     if (!apiModule.continueAccessAfterDone && finishedSection == undefined) {
    //         accessModel.accessMessage = translate("\r\nTamamlandığı için erişemezsiniz.");
    //         return true;
    //     }
    //     if (sectionOrContent.moduleSectionId != undefined) {//content demektir
    //         if (sectionOrContent.status == undefined) {
    //             return true;
    //         }
    //         let authorizedRole = _.find(loginUser.userRole, (doc) => {
    //             return doc.roleId == sectionOrContent.responsibleRoleId;
    //         });
    //
    //         if ((
    //                 (authorizedRole != undefined
    //                     && userRole != authorizedRole.role.roleName
    //                 )
    //                 ||
    //                 (authorizedRole == undefined)
    //             )
    //             && sectionOrContent.contentType == 15
    //             && sectionOrContent.data != undefined
    //             && sectionOrContent.data.contentTaskResponse != undefined
    //             && sectionOrContent.data.contentTaskResponse.type == 1
    //             && sectionOrContent.status.isCompleted) {
    //             return false;
    //         }
    //         if ((
    //                 authorizedRole != undefined
    //                 && userRole != authorizedRole.role.roleName
    //             )
    //             ||
    //             authorizedRole == undefined
    //         ) {
    //             if (sectionOrContent.moduleSectionId != undefined) {
    //                 setContentAccessMessage(sectionOrContent.id, sectionOrContent.moduleSectionId, translate("Erişim yetkiniz bulunmamaktadır."));
    //             }
    //             setTimeout(() => {
    //                 return true;
    //             }, 1000)
    //         }
    //     }
    //     return accessModel.isDisabled;
    // }

    // const getSectionStatusIconName = (sectionOrContent, type?) => {
    //     if (sectionOrContent.status == undefined) {
    //         return "";
    //     }
    //     if (sectionOrContent.status.isCompleted) {
    //         return "icon-check"
    //     }
    //     if (sectionOrContent.status.isInProgress) {
    //         return "inprogress"
    //     }
    //     return "";
    // }

    // const getStatusIconName = (sectionOrContent, type?) => {
    //     if (sectionOrContent.moduleSectionId != undefined) {
    //         if (sectionOrContent.id == "7E29F292-4E47-4C29-8F1C-02708ACA9417" || sectionOrContent.id == "EF2FF511-17BE-4195-A871-FABA01CCC5A5") {
    //             ////debugger;
    //         }
    //         let currentRole = _.filter(loginUser.userRole, (doc) => {
    //             return doc.role.roleName == DataHandler.getUserDataWithKey("userRole");
    //         });
    //         let control = _.find(currentRole, (doc) => {
    //             return doc.roleId == sectionOrContent.responsibleRoleId;
    //         });
    //         if (control == undefined && sectionOrContent.status != undefined && sectionOrContent.status.isCompleted) {
    //             return "icon-check"
    //         }
    //         if (control == undefined) {
    //             return "lock";
    //         }
    //         if (sectionOrContent.status == undefined) {
    //             return "";
    //         }
    //     } else {
    //         if (sectionOrContent.contentList != undefined && sectionOrContent.contentList.length != 0) {
    //             let contentStatuses: Array<boolean> = [];
    //             sectionOrContent.contentList.map((content) => {
    //                 let currentRole = _.filter(loginUser.userRole, (doc) => {
    //                     return doc.role.roleName == DataHandler.getUserDataWithKey("userRole");
    //                 });
    //                 let control = _.find(currentRole, (doc) => {
    //                     return doc.roleId == content.responsibleRoleId;
    //                 });
    //                 if (control == undefined) {
    //                     contentStatuses.push(true);
    //                 } else {
    //                     let result = isDisabled(content);
    //                     contentStatuses.push(result);
    //                 }
    //             });
    //             //////console.log("contentStatuses", contentStatuses);
    //             if (contentStatuses.filter(x => x == false).length == 0) {
    //                 return "lock";
    //             }
    //         }
    //     }
    //     if (sectionOrContent.status == undefined) {
    //         return "";
    //     }
    //     if (sectionOrContent.status.isCompleted) {
    //         return "icon-check"
    //     }
    //     if (sectionOrContent.status.isInProgress) {
    //         return "inprogress"
    //     }
    //     return isDisabled(sectionOrContent) ? sectionOrContent.status != undefined && sectionOrContent.status.isCompleted ? "icon-check" : "lock" : "unlock";
    // }

    // const toggleSection = (section) => {
    //     try {
    //         let index = 0;
    //         _.each(apiModule.sectionList, (sec, i) => {
    //             if (section.id == sec.id) {
    //                 index = i;
    //             }
    //         });
    //         let clone = _.cloneDeep(apiModule);
    //         clone.sectionList[index].showDetails = !clone.sectionList[index].showDetails;
    //         ////console.log("toggleSection clone: ", clone);
    //         setApiModule(clone);
    //     } catch (e) {
    //         ////debugger;
    //     }
    // }

    // const runValidateEvents = (apiModuleClone?) => {
    //     if (apiModuleClone == false) {
    //         return;
    //     }
    //     let request = {
    //         moduleId: selectedModuleId,
    //         userId: transactionUserId != "" ? transactionUserId : loginUserId
    //     }
    //     let data = apiModule;
    //     if (apiModuleClone != undefined) {
    //         data = apiModuleClone;
    //     }
    //     let clone = _.cloneDeep(data);
    //     //clone = runAccessibilityControls(clone);
    //     setApiModule(clone);
    //     return clone;
    // }

    // const startSection = (sectionId) => {
    //     let changeStatus = false;
    //     var index = -1;
    //     let sectionClone = _.find(apiModule.sectionList, (doc, i) => {
    //         if (doc.id == sectionId) {
    //             index = i;
    //             return doc;
    //         }
    //     });
    //     if (sectionClone != undefined
    //         && sectionClone.status != undefined
    //         && sectionClone.status.isInProgress == false
    //         && sectionClone.status.isCompleted == false
    //     ) {
    //         let nextStatus: any = _.find(statuses, (doc) => {
    //             return doc.isInProgress;
    //         });
    //         if (nextStatus != undefined) {
    //             sectionClone.statusId = nextStatus.statusId;
    //             sectionClone.status = nextStatus;
    //             changeStatus = true;
    //         }
    //     }
    //     return {section: sectionClone, index: index, changeStatus: changeStatus};
    // }

    // const getProgress = () => {
    //     if (apiModule != undefined && apiModule.sectionList.length > 0) {
    //         let completedCount = 0, totalCount = 0;
    //
    //         apiModule.sectionList.map((section) => {
    //             section.contentList.map((content) => {
    //                 if (content == undefined || content.status == undefined) {
    //                     ////console.log(content);
    //                 } else {
    //                     if (content.status.isCompleted) {
    //                         completedCount += 1;
    //                     }
    //                     totalCount += 1;
    //                 }
    //             })
    //         })
    //
    //         if (totalCount > 0) {
    //             let rate = ((completedCount / totalCount) * 100).toFixed(0);
    //             setSectionProgress(JSON.parse(rate));
    //         }
    //     }
    // }

    // const updateSectionStatusCall = (section, sectionIndex) => {
    //     ////console.log("updateSectionStatusCall");
    //     return NetworkRequest('/api/ModuleSection/UpdateStatus', {
    //         id: section.id,
    //         statusId: section.statusId,
    //         registeredUserId: transactionUserId != "" ? transactionUserId : loginUserId,
    //         createUserId: loginUserId
    //     }).then((response) => {
    //         ////console.log("respons: ", response);
    //         getProgress();
    //         if (response.data.resultCode == 0) {
    //             ////console.log("updateSectionStatusCall", response.data.detail);
    //             return response.data.detail;
    //         }
    //         return false;
    //     });
    // }

    // const updateContentStatusCall = (content, sectionIndex, index) => {
    //     ////console.log("updateContentStatusCall");
    //     //return promiseMaker(moduleAccessModel);
    //     return NetworkRequest('/api/SectionContent/UpdateStatus', {
    //         id: content.id,
    //         statusId: content.statusId,
    //         registeredUserId: transactionUserId != "" ? transactionUserId : loginUserId,
    //         createUserId: loginUserId,
    //         ModuleAccessModel: moduleAccessModel
    //     }).then((response) => {
    //         getProgress();
    //         checkAndCompleteSection(content.moduleSectionId, content);
    //         getHistory(selectedModuleId, loginUserId);
    //         if (response.data.resultCode == 0) {
    //             ////console.log("updateContentStatusCall", response.data.detail);
    //             return response.data.detail;
    //         }
    //         return false;
    //     });
    // }

    // const updateNextStatus = (section) => {
    //     let nextStatus: any = null;
    //     if (!section.status.isInProgress && !section.status.isCompleted) {
    //         nextStatus = _.find(statuses, (doc) => {
    //             return doc.isInProgress;
    //         });
    //     } else if (section.status.isInProgress && !section.status.isCompleted) {
    //         nextStatus = _.find(statuses, (doc) => {
    //             return doc.isCompleted;
    //         });
    //     }
    //     if (nextStatus != null) {
    //         var index = -1;
    //         let sectionClone = _.find(apiModule.sectionList, (doc, i) => {
    //             if (doc.id == section.id) {
    //                 index = i;
    //                 return doc;
    //             }
    //         });
    //         sectionClone.statusId = nextStatus.statusId;
    //         sectionClone.status = nextStatus;
    //         let moduleClone = _.cloneDeep(apiModule);
    //         moduleClone.sectionList[index] = sectionClone;
    //         setApiModule(moduleClone);
    //         return updateSectionStatusCall(sectionClone, index).then((response) => {
    //             if (response != false) {
    //                 getProgress();
    //             }
    //         });
    //     }
    // }

    // const checkAndCompleteSection = (sectionId, content) => {
    //     let index = -1;
    //     let data = apiModule;
    //     if (apiModule.id == '') {
    //         data = baseApiModule;
    //     }
    //     let clone = _.cloneDeep(data);
    //
    //     let selectedSection = _.find(clone.sectionList, (doc, i) => {
    //         if (doc.id == sectionId) {
    //             index = i;
    //             return doc;
    //         }
    //     });
    //     if (selectedSection != undefined && content != undefined) {
    //         let control = _.some(selectedSection.contentList, (doc) => {
    //             return doc.status.isCompleted == false;
    //         });
    //         if (!control) {
    //             updateNextStatus(selectedSection);
    //         }
    //     }
    // }

    // const nextContent = (content, apiModuleClone) => {
    //
    //     checkDidUserComplateModule();
    //     setNextContentModel(undefined);
    //     if (isMobile) {
    //         setIsOpenTaskContentForMobile(true);
    //         return;
    //     }
    //
    //     setSessionComplateCount(sessionComplateCount + 1);
    //     if (selectedSectionAndContent.selectedSection.contentList.length == selectedSectionAndContent.selectedContent.rank && selectedSectionAndContent.selectedSection.rank == apiModule.sectionList.length) {
    //         return;
    //     }
    //     toast.dismiss();
    //     let apiData = apiModule;
    //     if (apiModuleClone != undefined) {
    //         apiData = apiModuleClone;
    //     }
    //     //apiData = runAccessibilityControls(apiData);
    //     let sectionData = _.find(apiData.sectionList, (sec) => {
    //         return sec.id == content.moduleSectionId;
    //     });
    //     let contentData = _.find(sectionData.contentList, (con) => {
    //         return con.id == content.id;
    //     });
    //     if (sectionData.contentList.length > selectedSectionAndContent.selectedContent.rank) {
    //         toast(translate("Bir sonraki içeriğe geçiliyor."), {
    //             type: 'info',
    //             theme: 'dark',
    //             position: 'top-center',
    //             autoClose: 3500
    //         });
    //         contentData = _.find(sectionData.contentList, (con) => {
    //             return con.rank == content.rank + 1;
    //         });
    //         //onContentSelect(contentData, sectionData);
    //         setNextContentModel({
    //             content: contentData,
    //             section: sectionData
    //         });
    //     } else if (sectionData.rank < apiData.sectionList.length) {
    //         let newSection = apiData.sectionList[sectionData.rank];
    //         let first = newSection.contentList[0];
    //         if (first == null || first == undefined)
    //             return;
    //         toast(translate("Bir sonraki içeriğe geçiliyor."), {
    //             type: 'info',
    //             theme: 'dark',
    //             position: 'top-center',
    //             autoClose: 3500
    //         });
    //         ////debugger;
    //         toggleSection(newSection);
    //         setNextContentModel({
    //             content: first,
    //             section: newSection
    //         });
    //         //onContentSelect(first, newSection);
    //     } else {
    //         toast(translate("Bu son içerikti."), {
    //             type: 'info',
    //             theme: 'dark',
    //             position: 'top-center',
    //             autoClose: 3500
    //         });
    //     }
    // }

    // const updateContentNextStatus = (content, showRating?) => {
    //     if (isLocked || showRating) {
    //         toast.dismiss();
    //         return promiseMaker(false);
    //     }
    //     let nextStatus: any = null;
    //     if (!content.status.isInProgress && !content.status.isCompleted) {
    //         nextStatus = _.find(statuses, (doc) => {
    //             return doc.isInProgress;
    //         });
    //     } else if (content.status.isInProgress && !content.status.isCompleted) {
    //         nextStatus = _.find(statuses, (doc) => {
    //             return doc.isCompleted;
    //         });
    //     }
    //     if (nextStatus != null) {
    //         let startSectionModel = startSection(content.moduleSectionId);
    //         var contentIndex = -1;
    //         let contentClone;
    //         _.each(apiModule.sectionList, (sec) => {
    //             _.each(sec.contentList, (con, conI) => {
    //                 if (con.id == content.id) {
    //                     contentIndex = conI;
    //                     contentClone = con;
    //                 }
    //             });
    //         });
    //         if (contentClone != undefined) {
    //             if (startSectionModel.changeStatus) {
    //                 contentClone.statusId = nextStatus.statusId;
    //                 contentClone.status = nextStatus;
    //                 let moduleClone = _.cloneDeep(apiModule);
    //                 startSectionModel.section.contentList[contentIndex] = contentClone;
    //                 moduleClone.sectionList[startSectionModel.index] = startSectionModel.section;
    //                 setApiModule(moduleClone);
    //
    //                 return updateSectionStatusCall(startSectionModel.section, startSectionModel.index).then((sectionResponse) => {
    //                     return updateContentStatusCall(contentClone, startSectionModel.index, contentIndex).then((contentResponse) => {
    //                         setModuleAccessModel(undefined);
    //                         if (contentResponse != false) {
    //                             setModuleAccessModel(contentResponse);
    //                         }
    //                         return moduleClone;
    //                     });
    //                 });
    //             } else {
    //                 contentClone.statusId = nextStatus.statusId;
    //                 contentClone.status = nextStatus;
    //                 let moduleClone = _.cloneDeep(apiModule);
    //                 moduleClone.sectionList[startSectionModel.index].contentList[contentIndex] = contentClone;
    //                 setApiModule(moduleClone);
    //                 return updateContentStatusCall(contentClone, startSectionModel.index, contentIndex).then((contentResponse) => {
    //                     setModuleAccessModel(undefined);
    //                     if (contentResponse != false) {
    //                         setModuleAccessModel(contentResponse);
    //                     }
    //                     return moduleClone;
    //                 });
    //             }
    //         }
    //         return promiseMaker(false);
    //     }
    //     return promiseMaker(false);
    // }

    // const openSection = () => {
    //
    //     let control = _.find(apiModule.sectionList, (sec: any) => {
    //         return sec.showDetails == true;
    //     });
    //     if (control == undefined) {
    //
    //         if (viewModeHook != "management" && control == undefined) {
    //             ////debugger;
    //             let secI = -1;
    //             _.each(apiModule.sectionList, (sec, i) => {
    //                 if (sec.status != undefined && !sec.status.isCompleted && sec.status.isInProgress == true) {
    //                     secI = i;
    //                 }
    //             });
    //             if (secI != -1 && secI + 1 <= apiModule.sectionList.length) {
    //                 ////debugger;
    //                 toggleSection(apiModule.sectionList[secI]);
    //                 let conI = -1;
    //                 // //debugger;
    //                 _.each(apiModule.sectionList[secI].contentList, (con, i) => {
    //                     if (con.status != undefined && !con.status.isCompleted && con.status.isInProgress == true) {
    //                         conI = i;
    //                     }
    //                 });
    //                 if (conI != -1 && conI + 1 <= apiModule.sectionList[secI].contentList.length) {
    //                     // //debugger;
    //                     // onContentSelect(apiModule.sectionList[secI + 1].contentList[conI + 1], apiModule.sectionList[secI + 1]);
    //                     onContentSelect(apiModule.sectionList[secI].contentList[conI], apiModule.sectionList[secI]);
    //                 }
    //             } else {
    //                 let firstSection = apiModule.sectionList.find(x => x.status != undefined && ((x.status.isInProgress == false && x.status.isCompleted == false) || x.status.isInProgress == true));
    //                 if (firstSection != undefined && !isDisabled(firstSection)) {
    //                     ////debugger;
    //                     toggleSection(firstSection);
    //                     let firstContent = firstSection.contentList.find(x => x.status != undefined && ((x.status.isInProgress == false && x.status.isCompleted == false) || x.status.isInProgress == true));
    //                     if (firstContent != undefined) {
    //                         onContentSelect(firstContent, firstSection)
    //                     }
    //                 }
    //             }
    //         } else if (viewModeHook == "management") {
    //
    //             var loginUserRoleObj = loginUser.userRole.find(x => x.role.roleName == DataHandler.getUserDataWithKey("userRole"));
    //             var isContentFind = false;
    //             apiModule.sectionList.forEach((item, index) => {
    //                 if (!isContentFind) {
    //                     var cList = item.contentList.filter(x => x.status != undefined && x.status.isInProgress == true && x.status.isCompleted == false && x.responsibleRoleId == loginUserRoleObj.roleId);
    //                     if (cList.length > 0) {
    //                         toggleSection(apiModule.sectionList[index]);
    //                         onContentSelect(cList[0], item);
    //                         isContentFind = true;
    //                     } else {
    //                         var cListUncomplated = item.contentList.filter(x => x.status != undefined && x.status.isCompleted == false && x.responsibleRoleId == loginUserRoleObj.roleId);
    //                         if (cListUncomplated.length > 0) {
    //                             toggleSection(apiModule.sectionList[index]);
    //                             onContentSelect(cListUncomplated[0], item);
    //                             isContentFind = true;
    //                         }
    //                     }
    //                 }
    //             })
    //         }
    //     }
    // }

    // const checkContentsAndCompleteSection = () => {
    //     _.each(apiModule.sectionList, (sec: any) => {
    //         if (sec.status != undefined && !sec.status.isCompleted) {
    //             var control = _.filter(sec.contentList, (con: any) => {
    //                 return con.status != undefined && con.status.isCompleted;
    //             });
    //             if (control.length > 0 && control.length == sec.contentList.length) {
    //                 updateNextStatus(sec);
    //             }
    //         }
    //     });
    // }

    // const getStatuses = () => {
    //     NetworkRequest('/api/Statuses/GetList', {id: tenantId}).then((response) => {
    //         ////console.log("getStatuses response: ", response);
    //         if (response.data.resultCode == 0) {
    //             setStatuses(response.data.detail);
    //         }
    //     });
    // }

    const isCertVisible = () => {
        let isOtherPerson = loginUser.id != userModuleRegisteration != undefined && userModuleRegisteration.userId;
        if (isOtherPerson) {
            return false;
        }
        if (apiModule.certificateId != null && apiModule.certificateId != "" && registrationIdHook != undefined) {
            return true;
        }
        return false;
    }

    const getActiveLanguages = () => {

        NetworkRequest("/api/Language/GetActiveLanguages", null)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;

                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: "",
                            value: l.value
                        }

                        ////console.log(languageForDropdown);

                        setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                    })
                }
            });
    }

    const getUserClaimLanguage = () => {

        let tenantData = {
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            userId: DataHandler.getUserDataWithKey("userId")
        };


        NetworkRequest("/api/Language/GetUserClaimLanguage", tenantData)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    var language = response.data.detail;

                    if (language != null) {
                        setSelectedDefaultLanguage(language.value);
                    }
                }
            });
    }

    const handleChanges = (event: any, data: any) => {
        setSelectedDefaultLanguage(data.value);

        let userClaimData = {
            userId: DataHandler.getUserDataWithKey("userId"),
            languageFlagCode: data.value
        }

        NetworkRequest("/api/Language/AddUserClaimLanguage", userClaimData)
            .then((response) => {

                if (response.data.resultCode == 0) {
                    var language = response.data.detail;

                    if (language != null) {
                        setSelectedDefaultLanguage(language);
                    }

                    toast(translate(response.data.message), {
                        type: 'success',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    toast(translate(response.data.message), {
                        type: 'error',
                        theme: 'dark',
                        position: 'top-center',
                        autoClose: 10000
                    });
                }
            });
    }

    const openSlideAnimate = {
        borderRadiusTopLeft: ["0px", "25px"],
        borderRadiusBottomLeft: ["0px", "25px"],
        right: ["-100%", "0%"],
        opacity: [0, 1],
        backdropFilter: ["blur(0px)", "blur(50px)"]
    };

    const closeSlideAnimate = {
        borderRadiusTopLeft: ["25px", "0px"],
        borderRadiusBottomLeft: ["25px", "0px"],
        right: ["0%", "-100%"],
        opacity: [1, 0],
        backdropFilter: ["blur(50px)", "blur(0px)"]
    };

    const openSubSlideAnimate = {
        borderRadiusTopLeft: ["0px", "25px"],
        borderRadiusBottomLeft: ["0px", "25px"],
        // right: ["-100%", "0%"],
        width: ["0px", "350px"],
        opacity: [0, 1]
    };

    const closeSubSlideAnimate = {
        borderRadiusTopLeft: ["25px", "0px"],
        borderRadiusBottomLeft: ["25px", "0px"],
        // right: ["0%", "-100%"],
        width: ["350px", "0px"],
        opacity: [1, 0]
    };

    const openSubSlidePanel = (headerTitle: string) => {

        if (headerTitle == currentSubHeader && isOpenSubSlidePanel) {
            setIsOpenSubSlidePanel(false);
            setCurrentSubHeader("");
            return;
        }
        setResponseMessage(null);
        setErrorMessage(null);

        setUserPassword({
            Email: loginUserEmail,
            TenantId: DataHandler.getUserDataWithKey("tenantId")
        });

        setIsOpenSubSlidePanel(false);
        setTimeout(() => {
            setCurrentSubHeader(headerTitle);
            setIsOpenSubSlidePanel(true);
        }, 400);

    }

    // const checkDidUserComplateModule = () => {
    //     if (isShownAllContentCompleted)
    //         return;
    //     if (DataHandler.getUserDataWithKey("userRole") == "User")
    //         return;
    //     if (apiModule.sectionList == null || apiModule.sectionList.length == 0)
    //         return;
    //     var sectionListClone = _.cloneDeep(apiModule.sectionList);
    //     var contentList: any = []
    //
    //     let currentRole = _.find(loginUser.userRole, (doc) => {
    //         return doc.role.roleName == DataHandler.getUserDataWithKey("userRole");
    //     });
    //     //debugger;
    //     for (var i = 0; i < sectionListClone.length; i++) {
    //         contentList = contentList.concat(sectionListClone[i].contentList.filter(x => x.responsibleRoleId == currentRole.roleId));
    //     }
    //     if (contentList.length > 0 && contentList.filter(x => !x.status.isCompleted).length == 0) {
    //         //toast(translate("Sorumlusu olduğunuz tüm görevleri tamamladınız."), {
    //         //    type: 'success',
    //         //    theme: 'dark',
    //         //    position: 'top-center',
    //         //    autoClose: false
    //         //});
    //         //debugger;
    //
    //         if (window.localStorage.getItem(apiModule.id) != "1") {
    //             setIsShownAllContentCompleted(true);
    //             window.localStorage.setItem(apiModule.id, "1");
    //         }
    //     }
    // }

    const handleUserProfileChanges = (e: any) => {

        if (e.target.value == "") {
            setResponseMessage(null);
        }

        setUpdateUser(old => {
            let updatedField = old[e.target.name] = e.target.value
            return {...old, [e.target.name]: updatedField};
        });

    }

    const handleUserChange2FactorSetting = (isActive: boolean) => {
        setResponseMessage(null);
        setErrorMessage(null);
        setUpdateUser(old => {
            return {...old, ["isTwoFactorEnabled"]: isActive};
        });
    }

    const saveUser2FactorSetting = () => {
        setResponseMessage(null);
        setErrorMessage(null);
        setIsUpdateStarting(true);
        let userData = {
            id: updateUser.id,
            isTwoFactorEnabled: updateUser.isTwoFactorEnabled
        }

        NetworkRequest("api/User/UpdateTwoFactorEnabled", userData, true).then((response) => {
            if (response.data.resultCode == 0) {
                setResponseMessage(response.data.message);
            } else {
                setErrorMessage(response.data.message);
            }

            setIsUpdateStarting(false);
        });
    }

    const handleUserPasswordChanges = (e: any) => {

        setUserPassword(old => {
            let updatedPassword = old[e.target.name] = e.target.value
            return {...old, [e.target.name]: updatedPassword}
        })

        if (e.target.name == "newPassword") {
            let passwordData = {
                tenantId: DataHandler.getUserDataWithKey("tenantId"),
                password: e?.target?.value?.trim()
            }

            NetworkRequest("api/Settings/CheckPasswordPolicyRequirementsStatus", passwordData, true).then((response) => {
                //
                if (response.data.resultCode == 0) {
                    let data = response.data.detail;
                    let list = data.passwordPolicyRequirementsStatusList;
                    let isAllPassed = data.isAllPassed;

                    setPasswordPolicyResponseList(list);
                    setIsPasswordPolicyAllDone(isAllPassed);
                    setIsCloseAllPasswordSettings(list != null);
                }
            });
        }
    }

    const updateProfileData = () => {

        setResponseMessage(null);

        if (updateUser.name == null || updateUser.name == "") {
            setErrorMessage(translate("Lütfen zorunlu alanları boş bırakmayınız"));
            return;
        }

        setIsUpdateStarting(true);
        let appType = window.location.origin.indexOf('local') != -1 || window.location.origin.indexOf('test') != -1 ? 'Test' : 'Prod';
        var formData = new FormData();
        formData.append('File', selectedFile);
        formData.append('Request', JSON.stringify({...updateUser, appType: appType}));

        NetworkRequest("api/User/Update", formData, true).then((response) => {
            if (response.data.resultCode == 0) {
                setResponseMessage(response.data.message);
                if (!forceLogin) {

                    let userData = response.data.detail;
                    DataHandler.updatePhotoInfo(userData.photo);
                    DataHandler.setDataWithKey('fullname', userData.name + " " + userData.surname);
                }
            } else {
                setErrorMessage(response.data.message);
            }
            setIsUpdateStarting(false);
        });

    }

    const getUserFirstEntryPasswordChangeMustSetting = () => {
        let data = {
            key: "FirstEntryPasswordChangeMust",
            tenantId: DataHandler.getUserDataWithKey("tenantId"),
            // value: isChecked
        };

        NetworkRequest("api/Settings/GetSetting", data, true).then((response) => {
            if (response.data.resultCode == 0 && response.data.detail != null) {
                setIsUserFirstEntryPassword(response.data.detail.settingValue == "1" ? true : false)
            }
        });
    }

    const updatePassword = () => {
        setIsShowPasswordPolicyChecker(false);
        setIsUpdateStarting(true);
        NetworkRequest("api/User/UpdateUserPassword", userPassword, true).then((response) => {
            if (response.data.resultCode == 0) {
                setErrorMessage(null);
                setResponseMessage(response.data.message);
                setIsFirstLogin(response.data != null && response.data.detail != null && response.data.detail.firstLogin == "1" ? true : false);
            } else {
                setResponseMessage(null);
                setErrorMessage(response.data.message);
            }

            setIsUpdateStarting(false);
        });
    }

    useEffect(() => {
        if (!(userPassword.newPassword || userPassword.reNewPassword))
            return;

        if (userPassword?.newPassword?.trim() !== userPassword?.reNewPassword?.trim()) {
            setIsPasswordMatchError(true);
        } else {
            setIsPasswordMatchError(false);
        }
    }, [userPassword])

    const validateFile = (filename) => {

        // Dosya uzantısını al
        var ext = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();

        // Dosya uzantısı, görsel uzantıları listesinde mi?
        if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].indexOf(ext) !== -1) {
            // Dosyanın görsel olduğunu belirt
            return true;
        }

        // Dosya görsel değilse, dosyanın içeriğine bakarak kontrol et
        var img = new HTMLImageElement();
        img.src = filename;

        // Dosya görsel ise, yüklenmesi başarılı olur
        if (img.complete) {
            return true;
        }

        // Dosya görsel değilse, yüklenmesi başarısız olur
        return false;
    }

    const handleFiles = (file) => {
        if (validateFile(file.name)) {
            setErrorMessage(null);
            setSelectedFile(file);
            setSelectedImage(URL.createObjectURL(file));
        } else {
            setErrorMessage(translate("Bu dosya tipi desteklenmemektedir"));
        }
    }

    const filesSelected = (): any => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files[0]);
        }
    }

    const handleLogout = () => {
        logoutApi();
        DataHandler.logOutUser();
        navigate('/homeLogin');
    }


    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate
        }

        // if(filterData.filterText.length > 3 && (filterData.startingDate != null || filterData.dueDate != null || filterData.status != ""))
        //     getAssignedCoursesByFilter(filterData);

        getAssignedCourses(filterData);

        //getAssignedCoursesCount();


        setModalImage(displayProtectedAppDoc("/Documents/Buddy/tebrikler-ekrani.png"));
    }, [])


    const renderContentBySettingsTitle = (headerTitle: string) => {
        switch (headerTitle) {
            case "Profil":
                return (
                    <motion.div
                        className={"moduleDetailSubSlidePanelContentContainer"}
                        animate={isOpenSubSlidePanel ? {
                            opacity: [0, 1],
                            marginTop: ["-10px", "0px"]
                        } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                        transition={{duration: isOpenSubSlidePanel ? 1.2 : .2}}
                    >
                        <div style={{position: "relative"}}>
                            {/*<motion.div*/}
                            {/*    onClick={() => {*/}
                            {/*        fileInputRef.current.click();*/}
                            {/*    }}*/}
                            {/*    className={"moduleDetailChooseProfilePicture"}*/}
                            {/*    onMouseEnter={() => {*/}
                            {/*        setProfilePictureHover(true)*/}
                            {/*    }}*/}
                            {/*    onMouseLeave={() => {*/}
                            {/*        setProfilePictureHover(false)*/}
                            {/*    }}*/}
                            {/*    animate={profilePictureHover ? {opacity: [0, 1]} : {*/}
                            {/*        opacity: [1, 0],*/}
                            {/*        marginTop: ["0px", "-10px"]*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <div style={{width: "100%"}}>{translate("Fotoğraf Seç")}</div>*/}
                            {/*</motion.div>*/}
                            <input
                                ref={fileInputRef}
                                style={{display: "none"}}
                                type="file"
                                multiple
                                onChange={filesSelected}
                            />
                            <motion.img
                                id="motionImage"
                                style={{
                                    width: "100%",
                                    borderRadius: "20%",
                                    /*filter: profilePictureHover ? "blur(50px)" : "blur(0)",*/
                                    cursor: "pointer",
                                    transition: "all 1s"
                                }}
                                onMouseEnter={() => {
                                    setProfilePictureHover(true)
                                }}
                                onMouseLeave={() => {
                                    setProfilePictureHover(false)
                                }}
                                src={selectedImage == null ? loginUser.photo == null ? DefaultProfileImage : loginUser.photo : selectedImage}
                                onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = displayProtectedAppDoc(DefaultProfileImage)
                                }}
                            />
                        </div>

                        <div style={{display: "flex", flexDirection: "column" as "column", width: "100%"}}>
                            <label>{translate("İsim")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.name || "-"}
                                fluid
                            />
                        </div>

                        <div style={{display: "flex", flexDirection: "column" as "column", width: "100%"}}>
                            <label>{translate("Soyisim")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.surname || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Telefon Numarası")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.phone || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("TC Kimlik No")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.tcNo || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("İşe Giriş Tarihi")}</label>

                            <DatePicker
                                yearDropdownItemNumber={10}
                                name="workStartDate"
                                type="date"
                                className={"greyInput datePickerCustomClass"}

                                maxLength="8"
                                selected={updateUser?.workStartDate}
                                value={DateFormatDatePicker(updateUser?.workStartDate, false)}
                               disabled
                                placeholderText="dd-mm-yyyy"
                                dateFormat="dd-MM-yyyy"
                            />

                            
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Kullanıcı Adı")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.userName || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("E-posta Adresi")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.email || "-"}
                                fluid
                            />
                        </div>


                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Kişisel E-posta Adresi")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.emailPersonal || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Departman")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.departman || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Lokasyon")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.userDetailView?.location || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Fonksiyon")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.functionTitle || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Cinsiyet")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.gender || "-"}
                                fluid
                            />
                        </div>

                        <div style={{ display: "flex", flexDirection: "column" as "column", width: "100%" }}>
                            <label>{translate("Sistem Rolü")}</label>
                            <Input
                                disabled
                                className={"greyInput"}
                                size={"big"}
                                value={updateUser?.roleNames || "-"}
                                fluid
                            />
                        </div>

                       

                       
                       
                        {/*<div>*/}
                        {/*    <Button*/}
                        {/*        disabled={((updateUser?.name)?.trim() == "" || (updateUser?.surname)?.trim() == "" || errorMessage != null || isUpdateStarting)}*/}
                        {/*        loading={isUpdateStarting}*/}
                        {/*        onClick={updateProfileData}*/}
                        {/*        circular={true}*/}
                        {/*        color={"orange"}*/}
                        {/*    >{translate("Güncelle")}</Button>*/}
                        {/*</div>*/}

                        <div className={"moduleDetailUserProfileUpdatesErrorContainer"}>
                            {(updateUser?.name)?.trim() == "" &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={(((updateUser?.name)?.trim()) == "") ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate("* İsim alanı boş geçilemez")}
                                </motion.div>
                            }

                            {(updateUser?.surname)?.trim() == "" &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={((updateUser?.surname)?.trim() == "") ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate("* Soyisim alanı boş geçilemez")}
                                </motion.div>
                            }

                            {errorMessage != null &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={errorMessage != null ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    <Icon size={"big"} name={"file outline"}/>
                                    {errorMessage}
                                </motion.div>
                            }

                            {responseMessage != null && updateUser?.surname?.trim() != "" && updateUser?.name?.trim() != "" &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesResponseField"}
                                    animate={responseMessage != null ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    <Icon size={"big"} name={"check"}/>
                                    {responseMessage}
                                </motion.div>
                            }

                            {responseMessage != null && forceLogin &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesForceResponseField"}
                                    animate={responseMessage != null ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    <Icon size={"big"} name={"info"}/>
                                    {translate("Bu hesaba dışarıdan bağlantı kurduğunuz için güncellenen verileri anlık olarak görüntüleyemeyebilirsiniz.")}
                                </motion.div>
                            }
                        </div>


                    </motion.div>
                );


            case "Şifre":
                return (
                    <motion.div
                        className={"moduleDetailSubSlidePanelContentContainer"}
                        animate={isOpenSubSlidePanel ? {
                            opacity: [0, 1],
                            marginTop: ["-10px", "0px"]
                        } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                        transition={{duration: isOpenSubSlidePanel ? 1.2 : .2}}
                    >
                        <div>
                            <Input
                                icon={true}
                                type={isShowCurrentPassword ? "text" : "password"}
                                name={"currentPassword"}
                                value={userPassword.currentPassword}
                                onChange={e => handleUserPasswordChanges(e)}
                                style={{
                                    transition: "all .8s",
                                }}
                                size={"big"}
                                className={userPassword?.currentPassword?.trim() == "" ? "redInput" : "greyInput50"}
                                placeholder={"Geçerli Şifreniz"}
                            >
                                <Icon
                                    onClick={() => {
                                        setIsShowCurrentPassword(!isShowCurrentPassword)
                                    }}
                                    link={true}
                                    name={isShowCurrentPassword ? "eye" : "eye slash"}
                                />
                                <input/>
                            </Input>
                        </div>

                        <div>
                            <div className={"newPasswordField"}>
                                <PasswordPolicyChecker
                                    PasswordPolicyResponseList={passwordPolicyResponseList}
                                    IsShow={isShowPasswordPolicyChecker}
                                />
                            </div>
                            <Input
                                onFocus={() => {
                                    setIsShowPasswordPolicyChecker(true)
                                }}
                                onBlur={() => {
                                    setIsShowPasswordPolicyChecker(false)
                                }}
                                icon={true}
                                type={isShowNewPassword ? "text" : "password"}
                                name={"newPassword"}
                                value={userPassword.newPassword}
                                onChange={e => handleUserPasswordChanges(e)}
                                style={{
                                    transition: "all .8s",
                                }}
                                size={"big"}
                                className={userPassword?.newPassword?.trim() == "" ? "redInput" : "greyInput50"}
                                placeholder={translate("Yeni Şifreniz")}
                            >
                                <Icon
                                    onClick={() => {
                                        setIsShowNewPassword(!isShowNewPassword)
                                    }}
                                    link={true}
                                    name={isShowNewPassword ? "eye" : "eye slash"}
                                />
                                <input/>
                            </Input>
                        </div>

                        <div>
                            <Input
                                icon={true}
                                value={userPassword.reNewPassword}
                                type={isShowReNewPassword ? "text" : "password"}
                                name={"reNewPassword"}
                                onChange={e => handleUserPasswordChanges(e)}
                                style={{
                                    transition: "all .8s",
                                }}
                                size={"big"}
                                className={(userPassword?.reNewPassword?.trim() == "" || isPasswordMatchError) ? "redInput" : "greyInput50"}
                                placeholder={"Yeni Şifreniz - Tekrar"}
                            >
                                <Icon
                                    onClick={() => {
                                        setIsShowReNewPassword(!isShowReNewPassword)
                                    }}
                                    link={true}
                                    name={isShowReNewPassword ? "eye" : "eye slash"}
                                />
                                <input/>
                            </Input>
                        </div>

                        <div>
                            <Button
                                disabled={
                                    isNullOrEmpty(userPassword.currentPassword)
                                    || isNullOrEmpty(userPassword.newPassword)
                                    || isNullOrEmpty(userPassword.reNewPassword)
                                    || isPasswordMatchError
                                    || isUpdateStarting
                                    // || (isCloseAllPasswordSettings ? false : isPasswordPolicyAllDone ? false : true)
                                    || !isPasswordPolicyAllDone
                                }
                                loading={isUpdateStarting}
                                onClick={updatePassword}
                                circular={true}
                                color={"orange"}
                            >{translate("Güncelle")}</Button>
                        </div>

                        <div className={"moduleDetailUserProfileUpdatesErrorContainer"}>

                            {userPassword?.currentPassword?.trim() == "" &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={userPassword?.currentPassword?.trim() == "" ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate("Geçerli şifre boş geçilemez")}
                                </motion.div>
                            }
                            {userPassword?.newPassword?.trim() == "" &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={userPassword?.newPassword?.trim() == "" ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate("Yeni şifre boş geçilemez")}
                                </motion.div>
                            }
                            {userPassword?.reNewPassword?.trim() == "" &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={userPassword?.reNewPassword?.trim() == "" ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate("Yeni şifre tekrar boş geçilemez")}
                                </motion.div>
                            }
                            {isPasswordMatchError &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={isPasswordMatchError ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate("Şifreler eşleşmiyor.")}
                                </motion.div>
                            }

                            {responseMessage != null &&
                                <motion.div
                                    style={{
                                        flexDirection: "column" as "column",
                                        height: "113px",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                    className={"moduleDetailUserProfileUpdatesResponseField"}
                                    animate={responseMessage != null ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    <div>
                                        <Icon size={"big"} name={"check"}/>
                                        {translate(responseMessage)}
                                    </div>
                                    <div>
                                        <Button
                                            circular={true}
                                            color={"green"}
                                            icon
                                            labelPosition='left'
                                            onClick={openScreen}
                                        >
                                            <Icon name='thumbs up outline'/>
                                            {translate("Anasayfaya git")}
                                        </Button>
                                    </div>
                                </motion.div>
                            }

                            {errorMessage != null &&
                                <motion.div
                                    className={"moduleDetailUserProfileUpdatesErrorField"}
                                    animate={errorMessage != null ? {
                                        opacity: [0, 1],
                                    } : {opacity: [1, 0]}}
                                    transition={{duration: 1.1}}
                                >
                                    {translate(errorMessage)}
                                </motion.div>
                            }
                        </div>


                        {isFirstLogin && isUserFirstEntryPassword && !isLdapUser && <motion.div
                            className={"passwordChangeRequiredContainer"}
                            animate={isFirstLogin ? {
                                opacity: [0, 1],
                                bottom: ["-2%", "2%"]
                            } : {
                                opacity: [1, 0],
                                bottom: ["2%", "-2%"]
                            }}
                            transition={{duration: 1, delay: .2}}
                        >
                            <motion.div
                                animate={{
                                    scale: [1, 1.4, 1],
                                    filter: ["blur(2px)", "blur(0px)", "blur(2px)"]
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    times: [0, 0.6],
                                    repeat: Infinity,
                                    repeatDelay: 0
                                }}
                            >
                                <span> <Icon style={{color: "#f4f4f4cf"}} name={"attention"} size={"big"}/></span>
                            </motion.div>

                            {translate("İlk defa giriş yapan çalışanlar güvenliği için şifre değiştirmesi zorunludur.")}

                            <motion.div
                                animate={isFirstLogin ? {
                                    opacity: [0, 1],
                                } : {opacity: [1, 0]}}
                                transition={{duration: 1, delay: .3}}
                            >
                                <div>
                                    <Button onClick={handleLogout} color={"red"} circular={true} icon
                                            labelPosition='left'>
                                        <Icon name='log out'/>
                                        {translate("Çıkış")}
                                    </Button>
                                </div>

                            </motion.div>
                        </motion.div>
                        }

                    </motion.div>
                );

            case "Güvenlik":
                return (
                    <motion.div
                        className={"moduleDetailSubSlidePanelContentContainer"}
                        animate={isOpenSubSlidePanel ? {
                            opacity: [0, 1],
                            marginTop: ["-10px", "0px"]
                        } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                        transition={{duration: isOpenSubSlidePanel ? 1.2 : .2}}
                    >
                        <Checkbox
                            checked={updateUser.isTwoFactorEnabled}
                            onChange={(e, data) => {
                                handleUserChange2FactorSetting(data.checked != undefined ? data.checked : false)
                            }}
                            className="moduleDetailSafetySettingCheckbox"
                            label={translate("İki Faktörlü Kimlik Doğrulama")}
                            toggle/>

                        <div>
                            <Button
                                disabled={isUpdateStarting}
                                loading={isUpdateStarting}
                                onClick={saveUser2FactorSetting}
                                circular={true}
                                color={"green"}
                            >
                                {translate("Kaydet")}
                            </Button>
                        </div>

                        {errorMessage != null &&
                            <motion.div
                                className={"moduleDetailUserProfileUpdatesErrorField"}
                                animate={errorMessage != null ? {
                                    opacity: [0, 1],
                                } : {opacity: [1, 0]}}
                                transition={{duration: 1.1}}
                            >
                                <Icon size={"big"} name={"eye slash outline"}/>
                                {errorMessage}
                            </motion.div>
                        }

                        {responseMessage != null && updateUser?.surname?.trim() != "" && updateUser?.name?.trim() != "" &&
                            <motion.div
                                className={"moduleDetailUserProfileUpdatesResponseField"}
                                animate={responseMessage != null ? {
                                    opacity: [0, 1],
                                } : {opacity: [1, 0]}}
                                transition={{duration: 1.1}}
                            >
                                <Icon size={"big"} name={"check"}/>
                                {responseMessage}
                            </motion.div>
                        }


                        <motion.div
                            className={"passwordChangeRequiredContainer"}
                            animate={updateUser.isTwoFactorEnabled ? {
                                opacity: [0, 1],
                                bottom: ["-2%", "2%"]
                            } : {
                                opacity: [1, 0],
                                bottom: ["2%", "-2%"]
                            }}
                            transition={{
                                duration: updateUser.isTwoFactorEnabled ? .5 : .3,
                                delay: updateUser.isTwoFactorEnabled ? .2 : 0
                            }}
                        >
                            <motion.div
                                animate={{
                                    scale: [1, 1.4, 1],
                                    filter: ["blur(2px)", "blur(0px)", "blur(2px)"]
                                }}
                                transition={{
                                    duration: 1,
                                    delay: .2,
                                    ease: "easeInOut",
                                    times: [0, 0.6],
                                    repeat: Infinity,
                                    repeatDelay: 0
                                }}
                            >
                                <span> <Icon style={{color: "#f4f4f4cf"}} name={"shield"} size={"big"}/></span>
                            </motion.div>

                            {translate("İki faktörlü kimlik doğrulamasını etkinleştirdiğinizde, sistem giriş aşamasında size bir kod gönderecektir. Bu kodu kullanarak giriş yapabileceksiniz.")}


                        </motion.div>


                    </motion.div>
                );
            default:
                return <></>;
        }
    }

    // useEffect(() => {
    //     getStatuses();
    // }, [tenantId]);

    useEffect(() => {
        setIsMailOk(checkFieldsIsFilled(editorContent, mailTitle))
    }, [editorContent, mailTitle]);

    useEffect(() => {
        (isMobile && !showFabIcon) ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";
    }, [showFabIcon]);

    useEffect(() => {
        if (isUserFirstEntryPassword && isFirstLogin && !isMobile) {
            if (!isLdapUser) {
                setIsOpenSlidePanel(true);
                setIsOpenSubSlidePanel(true);
                setCurrentSubHeader("Şifre");
                renderContentBySettingsTitle("Şifre");
            }
        }
    }, [isUserFirstEntryPassword, isFirstLogin, isLdapUser])

    const openScreen = () => {
        setIsOpenSlidePanel(false);
        setIsOpenSubSlidePanel(false);
        setCurrentSubHeader("");
        renderContentBySettingsTitle("");
    }

    // useEffect(() => {
    //     if (loginUser.id == undefined && transactionUser.id == undefined)
    //         return;
    //     if (viewModeHook == undefined) {
    //         if (selectedModuleId != undefined) {
    //             let request = {
    //                 moduleId: selectedModuleId,
    //                 userId: transactionUserId != "" ? transactionUserId : loginUserId
    //             };
    //             NetworkRequest("api/ModuleUserRegistrations/CheckMeAssigned", request).then((result) => {
    //                 if (result.data.resultCode == 0 && result.data.detail) {
    //                     getStatusLogs(request).then((response) => {
    //                         getModuleWithDetails(selectedModuleId, loginUserId);
    //                     });
    //                 } else {
    //                     toast.dismiss();
    //                     toast(translate("Bu modüle kaydınız yok."), {
    //                         type: 'error',
    //                         theme: 'dark',
    //                         position: 'top-center',
    //                         autoClose: 5000
    //                     });
    //                     window.setTimeout(() => {
    //                         window.location.href = "/";
    //                     }, 5000)
    //                 }
    //             })
    //         } else {
    //             getLastAssignedModuleByEmail();
    //         }
    //     } else if (viewModeHook == "management" && selectedModuleId == undefined) {
    //         getModuleWithRegistrationId(registrationIdHook)
    //     }
    // }, [selectedModuleId, loginUser, transactionUser]);

    // useEffect(() => {
    //     if (loginUserId != undefined) {
    //         getResponsibleUsersByUserId(loginUserId);
    //         getLoginUser(loginUserId);
    //     }
    // }, [loginUserId]);

    // useEffect(() => {
    //     if (transactionUserId != "") {
    //         getTransactionUser(transactionUserId);
    //     }
    // }, [transactionUserId]);


    // useEffect(() => {
    //     if (nextContentModel != undefined) {
    //         onContentSelect(nextContentModel.content, nextContentModel.section);
    //     }
    // }, [nextContentModel]);

    useEffect(() => {
        if (sectionProgress == 100) {
            var req = {
                userId: transactionUserId != "" ? transactionUserId : loginUserId,
                moduleId: selectedModuleId,
                isComplate: true,
                tenantId: tenantId
            };
            NetworkRequest("api/ModuleUserRegistrations/ComplateModule", req).then((response) => {
                ////console.log(response) 
            });
            scrollToElem("customModal", 100);
        }
    }, [sectionProgress]);

    // useEffect(() => {
    //     ////console.log("apiModule useeffect: ", apiModule);
    //     if (apiModule != undefined && apiModule.sectionList.length == 0)
    //         return;
    //     if (isFirstTime) {
    //         openSection();
    //         setIsFirstTime(false);
    //     }
    //     getProgress();
    //     if (apiModule != undefined && apiModule.sectionList != undefined && apiModule.sectionList.length > 0) {
    //         checkContentsAndCompleteSection();
    //     }
    //     checkDidUserComplateModule();
    // }, [apiModule]);

    // useEffect(() => {
    //     toggleSection(apiModule.sectionList[requestedSectionIndex]);
    //     if (selectedSectionAndContent.selectedContent.id != "") {
    //         let content = _.find(apiModule.sectionList[requestedSectionIndex].contentList, (con: any) => {
    //             return con.id == selectedSectionAndContent.selectedContent.id;
    //         });
    //         //setSelectedSectionAndContent({
    //         //    ...selectedSectionAndContent,
    //         //    selectedContent: { id: "", title: "" }
    //         //});
    //         onContentSelect(content, apiModule.sectionList[requestedSectionIndex]);
    //     }
    //     toast.dismiss();
    // }, [requestedSectionIndex]);

    useEffect(() => {
        //console.log("selectedSectionAndContent:", selectedSectionAndContent);
    }, [selectedSectionAndContent]);

    useEffect(() => {
        getUserFirstEntryPasswordChangeMustSetting();
        getActiveLanguages();
        getUserClaimLanguage();

        if (isMobile) {
            setShowTasks(false);
            //setIsOpenTaskContentForMobile(true);
        }
        setInterval(checkMobile, 1000);

        function checkMobile() {
            if (window.innerWidth > 820)
                setShowFabIcon(false);
        }
    }, []);

    return (
        <>
            <Transition
                visible={loader && !showPartial}
                animation='scale'
                duration={100}
            >
                <div style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "ceter",
                    alignItems: "center"
                }}>
                    <div className="loaderContainerGif">
                        <Loader color="orange" size="big" active inline='centered'/>
                    </div>
                </div>
            </Transition>

            {isMobile &&
                <>
                    <div
                        style={{
                            bottom: loader && !showPartial ? "-10%" : "0",
                            transition: "all .5s"
                        }}
                        className={"fixedBottomMainMenu"}>
                        <FixedBottomMenu
                            SetShowFabIcon={setShowFabIcon}
                            ShowFabIcon={showFabIcon}
                            SetIsOpenTaskContentForMobile={setIsOpenTaskContentForMobile}
                            IsOpenTaskContentForMobile={isOpenTaskContentForMobile}

                        />
                    </div>

                    <div
                        style={{bottom: isOpenTaskContentForMobile ? "0" : "-100%"}}
                        className={"taskContentForMobilePanel"}
                    >
                        <div style={{width: "100%"}}>
                            <div style={{color: "#4e4a95", fontSize: "1.8em", margin: "3% 3% 5% 3%"}}>
                                <Icon size={"small"} name={"folder"}/>
                                {apiModule.title}
                            </div>
                            <div className={"moduleDetailColumnGrid"}>
                                <Grid columns={12}
                                      className="margin0 gap1 font16 moduleDetailGridFlexModuleDetail"
                                >
                                    <></>
                                    {apiModule.sectionList.length > 0 && apiModule.sectionList.map((section, sectionIndex) => (
                                        <Grid.Row id={section.id}
                                                  key={uuidv4()}
                                                  className={section.showDetails != true ? "moduleDetailSectionRow padding0 height5 cusrsorPointer" : "moduleDetailSectionRow padding0 minHeight5 cusrsorPointer"}
                                        >
                                            <Grid.Column
                                                width={2}
                                                className="padding0 thisIsCenter"
                                            >
                                            </Grid.Column>
                                            <Popup
                                                position="right center"
                                                inverted
                                                content={section.description}
                                                trigger={
                                                    <Grid.Column width={12}
                                                                 className="textLeft moduleDetailSectionPadding"

                                                    >
                                                        <b style={{fontSize: "1.2em"}}>{translate("Bölüm")} {section.rank}:</b>
                                                        <p style={{color: "#6c6c6c"}}>
                                                            {section.title}
                                                        </p>
                                                    </Grid.Column>
                                                }/>
                                            <Popup
                                                position="right center"
                                                inverted
                                                content={section.description}
                                                trigger={
                                                    <Grid.Column width={2}
                                                                 className="padding0 thisIsCenter"
                                                    >
                                                        <Icon
                                                            name={section.showDetails == true ? 'angle down' : 'angle right'}/>
                                                    </Grid.Column>
                                                }/>
                                            <Grid columns={2}
                                                  className={section.showDetails == true ? 'width100 displayBlock moduleDetailContentsBg margin0 marginTop1rem moduleDetailBorderRadius moduleDetailMenuGrid' : 'displayNone'}
                                            >
                                                {section.contentList.map((content) => (
                                                    <Grid.Row
                                                        key={uuidv4()}
                                                        className={content.id == selectedSectionAndContent.selectedContent.id ? "width100 moduleDetailActiveContent" : "width100"}
                                                        id={content.id == selectedSectionAndContent.selectedContent.id ? "moduleDetailActiveContentId" : ""}

                                                    >
                                                        <Grid.Column width={2}>

                                                        </Grid.Column>
                                                        <Grid.Column width={12}>
                                                            {content.title}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                ))}
                                            </Grid>
                                        </Grid.Row>
                                    ))}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </>
            }


            {(!loader || showPartial)
                &&
                <Grid
                    columns={isMobile ? 1 : 3}
                    className={"moduleDetailGrid margin0 positionAbsolute moduleDetailGridContainerContent" + (!isMobile ? " widthHeight100" : "")}
                >
                    {isOpenSlidePanel && DataHandler.getUserDataWithKey("userRole") == "User" &&
                        <motion.div
                            // onTap={()=> {setIsOpenSubSlidePanel(false)}}
                            style={{
                                borderTopLeftRadius: isOpenSubSlidePanel ? "unset" : "25px",
                                borderBottomLeftRadius: isOpenSubSlidePanel ? "unset" : "25px"
                            }}
                            animate={isOpenSlidePanel ? openSlideAnimate : closeSlideAnimate}
                            transition={{
                                duration: .3,
                                delay: isOpenSlidePanel ? 0.1 : 0.2
                            }}
                            className={"moduleDetailSettingsSlidePanel"}>


                            <motion.div
                                // onTap={(e) => {e.stopPropagation(); e.preventDefault(); }}
                                // onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
                                animate={isOpenSubSlidePanel ? openSubSlideAnimate : closeSubSlideAnimate}
                                transition={{
                                    duration: .3,
                                    delay: isOpenSlidePanel ? 0.1 : 0.2
                                }}
                                className={"moduleDetailSettingsSubSlidePanel"}
                            >
                                <motion.div className={"moduleDetailSettingsSubSlidePanelHeaderContainer"}>

                                    <motion.div
                                        animate={isOpenSubSlidePanel ? {
                                            opacity: [0, 1],
                                            marginTop: ["-10px", "0px"]
                                        } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                                        transition={{duration: isOpenSubSlidePanel ? 1.2 : .2}}
                                    >
                                        <Button circular={true} onClick={() => {
                                            setIsOpenSubSlidePanel(false);
                                            setCurrentSubHeader("");
                                            setResponseMessage(null);
                                            setErrorMessage(null);
                                        }} color={"red"}>{translate("Kapat")}</Button>
                                    </motion.div>

                                    <motion.div
                                        animate={isOpenSubSlidePanel ? {opacity: [0, 1]} : {opacity: [1, 0]}}
                                        transition={{duration: isOpenSubSlidePanel ? 1.2 : .2}}
                                    >
                                        <Header style={{color: "#686868"}}
                                                as={"h3"}>{translate(currentSubHeader)}</Header>

                                    </motion.div>
                                </motion.div>

                                <motion.div
                                    className={"moduleDetailSettingsSlidePanelContent"}
                                    animate={isOpenSlidePanel ? {opacity: [0, 1],} : {opacity: [1, 0],}}
                                    transition={{duration: 1, delay: 0.3}}
                                >
                                    {renderContentBySettingsTitle(currentSubHeader)}
                                </motion.div>

                            </motion.div>

                            <motion.div className={"moduleDetailSettingsSlidePanelHeaderContainer"}>

                                <motion.div
                                    animate={isOpenSlidePanel ? {
                                        opacity: [0, 1],
                                        marginTop: ["-10px", "0px"]
                                    } : {opacity: [1, 0], marginTop: ["0px", "-10px"]}}
                                    transition={{duration: 1, delay: .5}}
                                >
                                    <Button
                                        disabled={(isFirstLogin && isUserFirstEntryPassword) && isLdapUser == false}
                                        circular={true}
                                        onClick={() => {
                                            setIsOpenSlidePanel(false);
                                            setIsOpenSubSlidePanel(false);
                                            setCurrentSubHeader("");
                                            setResponseMessage(null);
                                            setErrorMessage(null);
                                        }} color={"red"}>{translate("Kapat")}</Button>
                                </motion.div>

                                <motion.div
                                    style={{
                                        color: "#686868",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                    animate={isOpenSlidePanel ?
                                        {
                                            opacity: [0, 1],
                                            marginTop: ["-10px", "0px"],
                                            fontSize: [".5em", "2em"]
                                        } :
                                        {
                                            opacity: [1, 0],
                                            marginTop: ["0px", "-10px"]
                                        }
                                    }
                                    transition={{duration: 1, delay: .3}}
                                >
                                    <span>{translate("Ayarlar")}</span>

                                </motion.div>

                            </motion.div>


                            <motion.div
                                className={"moduleDetailSettingsSlidePanelContent"}
                                animate={isOpenSlidePanel ?
                                    {
                                        opacity: [0, 1],
                                    } :
                                    {
                                        opacity: [1, 0],
                                    }
                                }
                                transition={{duration: 1, delay: 0.3}}
                            >
                                <motion.div
                                    style={{
                                        backgroundColor: currentSubHeader == "Profil" ? "#94ba8947" : "#ffffff75",
                                        transition: "all .7s"
                                    }}
                                    whileTap={{scale: 0.85}}
                                    onTap={() => openSubSlidePanel("Profil")}
                                    whileHover={{
                                        // backgroundColor: "#94ba8947",
                                        marginLeft: "1%",
                                        transition: {duration: 0.2},
                                        cursor: "pointer"
                                    }}
                                    className={"moduleDetailSlidePanelPasswordChangeContainer"}>
                                    <div>
                                        <Header as='h4'>
                                            <Icon style={{color: "#848484"}} size={"small"} name='user'/>
                                            <Header.Content>{translate("Profil")}</Header.Content>
                                        </Header>
                                    </div>

                                    <div style={{marginLeft: "3%"}}>

                                        <Button
                                            style={{
                                                color: "white",
                                                width: currentSubHeader != "Profil" ? "100px" : "60px",
                                                backgroundColor: currentSubHeader == "Profil" ? "#db2828" : "#f2711c",
                                                transition: "all .5s"
                                            }}
                                            onClick={(e) => {
                                                if (currentSubHeader == "") {
                                                    setIsOpenSubSlidePanel(false);
                                                    setResponseMessage(null);
                                                    setErrorMessage(null);
                                                } else {
                                                    openSubSlidePanel("Profil")
                                                }
                                            }}
                                            circular={true}
                                        >
                                            {currentSubHeader == "Profil" ? "X" : translate("Detay")}
                                        </Button>
                                    </div>
                                </motion.div>

                                <motion.div
                                    style={{
                                        backgroundColor: currentSubHeader == "Şifre" ? "#94ba8947" : "#ffffff75",
                                        transition: "all .7s"
                                    }}
                                    whileTap={{scale: 0.95}}
                                    onTap={() => openSubSlidePanel("Şifre")}
                                    whileHover={{
                                        // backgroundColor: "#94ba8947",
                                        marginLeft: "1%",
                                        transition: {duration: 0.2},
                                        cursor: "pointer"
                                    }}

                                    className={"moduleDetailSlidePanelPasswordChangeContainer"}>
                                    <div>
                                        <Header as='h4'>
                                            <Icon style={{color: "#848484"}} size={"small"} name='key'/>
                                            <Header.Content>{translate("Şifre Ayarları")}</Header.Content>
                                        </Header>
                                    </div>

                                    <div style={{marginLeft: "3%"}}>
                                        <Button
                                            style={{
                                                color: "white",
                                                backgroundColor: currentSubHeader == "Şifre" ? "#db2828" : "#f2711c",
                                                width: currentSubHeader != "Şifre" ? "100px" : "60px",
                                                transition: "all .5s"
                                            }}
                                            onClick={() => {
                                                if (currentSubHeader == "") {
                                                    setIsOpenSubSlidePanel(false);
                                                    setResponseMessage(null);
                                                    setErrorMessage(null);
                                                } else {
                                                    openSubSlidePanel("Şifre")
                                                }
                                            }}
                                            circular={true}
                                        >{currentSubHeader == "Şifre" ? "X" : translate("Değiştir")}</Button>
                                    </div>
                                </motion.div>

                                <motion.div
                                    style={{
                                        backgroundColor: currentSubHeader == "Güvenlik" ? "#94ba8947" : "#ffffff75",
                                        transition: "all .7s"
                                    }}
                                    whileTap={{scale: 0.95}}
                                    onTap={() => openSubSlidePanel("Güvenlik")}
                                    whileHover={{
                                        // backgroundColor: "#94ba8947",
                                        marginLeft: "1%",
                                        transition: {duration: 0.2},
                                        cursor: "pointer"
                                    }}

                                    className={"moduleDetailSlidePanelPasswordChangeContainer"}>
                                    <div>
                                        <Header as='h4'>
                                            <Icon style={{color: "#848484"}} size={"small"} name='shield'/>
                                            <Header.Content>{translate("Güvenlik Ayarları")}</Header.Content>
                                        </Header>
                                    </div>

                                    <div style={{marginLeft: "3%"}}>
                                        <Button
                                            style={{
                                                color: "white",
                                                backgroundColor: currentSubHeader == "Güvenlik" ? "#db2828" : "#f2711c",
                                                width: currentSubHeader != "Güvenlik" ? "100px" : "60px",
                                                transition: "all .5s"
                                            }}
                                            onClick={() => {
                                                if (currentSubHeader == "") {
                                                    setIsOpenSubSlidePanel(false);
                                                    setResponseMessage(null);
                                                    setErrorMessage(null);
                                                } else {
                                                    openSubSlidePanel("Güvenlik")
                                                }
                                            }}
                                            circular={true}
                                        >{currentSubHeader == "Güvenlik" ? "X" : translate("Değiştir")}</Button>
                                    </div>
                                </motion.div>

                                <motion.div
                                    whileTap={{scale: 0.9}}
                                    whileHover={{
                                        marginLeft: "1%",
                                        transition: {duration: 0.2},
                                        cursor: "pointer"
                                    }}
                                    className={"moduleDetailSlidePanelPasswordChangeContainer"}>
                                    <div>
                                        <Header as='h4'>
                                            <Icon style={{color: "#848484"}} size={"small"} name='language'/>
                                            <Header.Content>{translate("Dil Ayarları")}</Header.Content>
                                        </Header>
                                    </div>

                                    <div style={{marginLeft: "3%"}}>
                                        <Dropdown
                                            style={{transform: "scale(1.1)", minWidth: "unset", right: "8px"}}
                                            className="greyInput"
                                            placeholder={translate("Dil Seçiniz")}
                                            clearable
                                            search
                                            selection
                                            value={selectedDefaultLanguage}
                                            // defaultValue={selectedDefaultLanguage}
                                            options={languageListDropdown}
                                            onChange={(event, data) => {
                                                handleChanges(event, data)
                                            }}
                                        />
                                    </div>
                                </motion.div>


                            </motion.div>

                            {/*slider panel*/}
                        </motion.div>
                    }
                    <Grid.Row style={{
                        transition: "all .7s",
                        filter: (isOpenSlidePanel) ? "blur(50px)" : "blur(0)",
                    }} className="positionUnset">

                        <Grid.Column
                            style={{pointerEvents: isOpenSlidePanel && !isMobile ? "none" : "auto"}}
                            width={isMobile ? 16 : 12}
                            className="moduleDetailColStyle moduleDetailColumn moduleDetailContentContainer"
                            id="middleContainer">

                            <img className={"homePageV2EndUserLogo"} src={logoUrl} alt="logo"/>

                            {(!showDictionaryViewer && !showHelperViewer) &&
                                <div>
                                    {searchParams.get('moduleCategory') == undefined ?
                                        <HomePageModuleRenderer
                                            modules={courseList}
                                            getAssignedCoursesByFilter={getAssignedCoursesByFilter}
                                            getAssignedCourses={getAssignedCourses}
                                            isLoader={homePageFilterLoader}
                                            endUserModuleFilterData={endUserModuleFilterData}
                                            setEndUserModuleFilterData={setEndUserModuleFilterData}
                                            setFilterLock={setFilterLock}
                                        /> :
                                        <ModuleListByCategory
                                            category={searchParams.get('moduleCategory')}
                                        />

                                    }
                                </div>
                            }

                            {showDictionaryViewer && !showHelperViewer
                                &&
                                <DictionaryViewer
                                    DictionaryId={dicId}
                                    Word={dicSearchWord}
                                    showDictionaryViewer={showDictionaryViewer}
                                    setShowDictionaryViewer={setShowDictionaryViewer}
                                />
                            }
                            {showHelperViewer && !showDictionaryViewer
                                &&
                                <HelperViewer
                                    helperSearchWord={helperSearchWord}
                                    showHelperViewer={showHelperViewer}
                                    setShowHelperViewer={setShowHelperViewer}
                                    showDictionaryViewer={showDictionaryViewer}
                                    setShowDictionaryViewer={setShowDictionaryViewer}
                                />
                            }
                        </Grid.Column>

                        <Grid.Column
                            style={{pointerEvents: isOpenSlidePanel && !isMobile ? "none" : "auto"}}
                            width={4}
                            className="moduleDetailColStyle moduleDetailColumn moduleDetailToolsContainer">
                            <motion.div
                                animate={{
                                    opacity: [0, 1],
                                }}
                            >
                                <Grid columns={1}
                                      className="margin0 paddingTop"
                                >
                                    <Grid.Row>
                                        <Grid.Column className="textCenter">

                                            <Image
                                                style={{objectFit: "cover", width: "150px", height: "150px"}}
                                                circular={true}
                                                id="modDetailUserPhoto"
                                                className={"userDashboardProfileImageClass"}

                                                src={loginUser.photo == null ? DefaultProfileImage : loginUser.photo}
                                                onError={({currentTarget}) => {
                                                    currentTarget.src = DefaultProfileImage
                                                }}
                                            />

                                            <Popup
                                                content={DataHandler.getUserDataWithKey("adminFullName")}
                                                trigger={
                                                    <div className={"backToAdminButtonContainer positionAbsolute"}
                                                         onClick={() => {
                                                             DataHandler.bringOldLogin();
                                                         }}>
                                                        {isLogin && forceLogin
                                                            &&
                                                            <div
                                                                className="userDashboardProfileBackToAdminContainer">
                                                                <span className="cusrsorPointer">
                                                                    <Icon name="arrow left"/>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            />
                                            <Popup
                                                content={translate("Çıkış")}
                                                trigger={
                                                    <div className={"exitButtonContainer"}>
                                                        <div className={"userDashboardProfileBackToAdminContainer"}>
                                                            <Button
                                                                color={"red"}
                                                                onClick={() => {
                                                                    DataHandler.logOutUser()
                                                                }}
                                                                style={{borderRadius: "15px"}}
                                                                icon="sign-out"
                                                            >
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            />


                                            <div
                                                className={"userDashboardProfileFullname"}> {DataHandler.getUserDataWithKey("fullname")}</div>
                                            <div className={"userDashboardProfileFullname"}>
                                                <Popup
                                                    content={translate("İşe giriş tarihi")}
                                                    trigger={
                                                        <Label
                                                            style={{marginTop: "2%"}}
                                                            size={"mini"}
                                                            content={DataHandler.getUserDataWithKey("workStartDate")}
                                                            icon='calendar alternate'
                                                        />
                                                    }
                                                />
                                            </div>

                                            <div className={"moduleDetailProfileSettingsContainer"}>
                                                <Button
                                                    onClick={() => {
                                                        if (DataHandler.getUserDataWithKey("forceLogin") == false || DataHandler.getUserDataWithKey("forceLogin") == undefined)
                                                            setIsOpenSlidePanel(true)
                                                        else {
                                                            setIsOpenSlidePanel(false);
                                                            toast(translate("Kişisel ayarlara yalnızca çalışanın kendisi erişebilir."), {
                                                                type: 'error',
                                                                theme: 'dark',
                                                                position: 'top-center',
                                                                autoClose: 2500
                                                            });
                                                        }

                                                    }}

                                                    className={"ingOrange"}
                                                    circular icon labelPosition='left'>
                                                    <Icon name='setting'/>
                                                    {translate("Ayarlar")}
                                                </Button>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <TeamContactList
                                                setShowSendMessage={setShowSendMessage}
                                                setSelectedUserForMail={setSelectedUserForMail}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <DocumentsList/>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <LibrarySearch
                                                showDictionaryViewer={showDictionaryViewer}
                                                setShowDictionaryViewer={setShowDictionaryViewer}
                                                // showHelperViewer={showHelperViewer}
                                                setShowHelperViewer={setShowHelperViewer}
                                                setDicId={setDicId}
                                                setDicSearchWord={setDicSearchWord}
                                                setShowFabIcon={setShowFabIcon}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <HelperSearch
                                                showHelperViewer={showHelperViewer}
                                                setShowHelperViewer={setShowHelperViewer}
                                                setHelperSearchWord={setHelperSearchWord}
                                                setShowFabIcon={setShowFabIcon}
                                                setShowDictionaryViewer={setShowDictionaryViewer}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </motion.div>

                            <Modal
                                onClose={() => setShowCertificate(false)}
                                onOpen={() => setShowCertificate(true)}
                                open={showCertificate}
                                centered={false}
                            >
                                <Modal.Actions>
                                    <Button onClick={() => setShowCertificate(false)}>{translate("Kapat")}</Button>
                                </Modal.Actions>
                                <Modal.Content
                                    style={{display: "flex", flexDirection: "column" as "column", alignItems: "center"}}
                                    image>
                                    {registrationIdHook != undefined
                                        &&
                                        <CertificatePrinter id={registrationIdHook}/>
                                    }
                                </Modal.Content>
                            </Modal>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            {(sessionComplateCount > 0 && sectionProgress == 100 && showCelebration) || isShownAllContentCompleted
                &&
                <CustomModal
                    subText={"Modül " + apiModule.title}
                    setShow={isShownAllContentCompleted ? setIsShownAllContentCompleted : setShowCelebration}
                    title={translate("Tebrikler!")}
                    image={modalImage}
                    body={isShownAllContentCompleted ? translate("Sorumlusu olduğunuz tüm görevleri tamamladınız.") : translate("Modül Tamamlandı")}
                />
            }
        </>
    );

}

export default memo(HomePageV2);