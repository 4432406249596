
import * as React from 'react';
import { useState, useEffect } from 'react';

import { Button, Card, Checkbox, Dropdown, Header, Icon, Input, Loader, Popup, Table, Transition } from 'semantic-ui-react';
import { datableFilterChange, digitallicaConfirm, prepareDataForTable } from '../helper/HelperFunctions';
import { NetworkRequest } from '../helper/NetworkRequest';
import DataTable from './DataTable';
import { IDataTable } from '../interfaces/interfaces';

import { toast } from 'react-toastify';
import { DataHandler } from '../helper/DataHelper';
import TranslateHelper from "../helper/TranslateHelper";

interface UserAttachToCourse {
    id?: string,
    UserAdd?: string,
    description?: string,
    successPoint: number,
    createdDate?: Date
}


const UserAttachToCourse = (props) => {
    var { translate } = TranslateHelper();
    const _ = require('lodash');
    const [currentPage, setCurrentPage] = useState(1);
    const [moduleName, setModuleName] = useState<any>({ titleUser: "", titleNotUser: "" });
    const [isLoading, setIsLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [moduleId] = useState(props.id);
    const [user, setUser] = useState({ id: "", nameSurname: "", email: "" });
    const [userAndCourse, setUserAndCourse] = useState({ id: "", moduleId: "", userId: "" });
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [startingDate, setStartingDate] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [isSigned, setIsSigned] = useState(false);
    const [filterData] = useState({
        filterText: filterText,
        currentPage: currentPage,
        isActive: isActive,
        isDeleted: isDeleted,
        startingDate: startingDate,
        dueDate: dueDate,
        tenantId: DataHandler.getUserDataWithKey("tenantId")
    });

    const onComplate = (id) => {

        toast("Lütfen bekleyin...", { type: 'info', theme: 'dark', position: 'top-center', isLoading: true});
        let userData = { userId: id, moduleId: moduleId };
        if (!isSigned) {
            NetworkRequest("/api/ModuleUserRegistrations/Add", userData).then(response => {
                if (response.data.resultCode == 0) {
                    toast.dismiss();
                    toast("Eklendi", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                    getUsers(filterData);
                } else {
                    toast.dismiss();
                    toast(response.data.message, { type: 'error', theme: 'dark', position: 'top-center', autoClose: 3500 });
                }

            });
        } else {
            digitallicaConfirm(
                translate("Silmek istediğinzden emin misiniz?"),
                () => {
                    NetworkRequest("/api/ModuleUserRegistrations/Delete", userData).then(response => {
                        if (response.data.resultCode == 0) {
                            toast.dismiss();
                            toast("Silindi", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                            getUsers(filterData);
                        }
                    });
                },
                () => {
                    toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
                }
            );
        }
        toast.dismiss();
    }

    const onChangeActive = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 1, setDataTableModel, setIsActive);
    }

    const onChangeSigned = (e, data) => {
        datableFilterChange(dataTableModel, data.checked, 0, setDataTableModel, setIsSigned);
    }

    const setAllSelected = (data) => {
        setIsAllSelected(data.checked);
    }

    const selectAllHandler = (model) => {
        for (var i = 0; i < model.data.length; i++) {
            model.data[i].secretData[0].isSelected = isAllSelected;
        }

        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(model);
        }, 100);
    }

    const setRowSelected = (index, data, dataTableModel) => {
        data.value = data.value == undefined ? true : !data.value;
        dataTableModel.data[index].secretData[0].isSelected = data.checked;
        window.setTimeout(() => {
            setDataTableModel({
                header: dataTableModel.header,
                data: [],
                transactions: dataTableModel.transactions,
                filter: dataTableModel.filter,
                pagination: dataTableModel.pagination,
                addRowLink: dataTableModel.addRowLink
            });
            setDataTableModel(dataTableModel);
        }, 100);
    }

    const collectiveProggress = (e, data, dtm?) => {
        if (data.value == "")
            return;
        dtm = dtm == undefined ? dataTableModel : dtm;
        digitallicaConfirm(
            "Toplu işlem yapacaksınız ? Emin misiniz ?",
            () => {
                let rows: Array<any> = [];
                let datatableClone = _.cloneDeep(dtm);
                let filtereeds = datatableClone.data.filter(x => x.secretData[0].isSelected == true);

                filtereeds.map(x => {
                    rows.push({ userId: x.secretData[0].id, moduleId: moduleId })
                })
                ////console.log(rows);

                switch (data.value) {
                    case 1:
                        NetworkRequest("/api/ModuleUserRegistrations/AddCollective", rows).then((response) => {
                            if (response.data.resultCode == 0) {
                                getUsers(filterData);
                                toast(response.data.detail.addedList.length + " Çalışan eklendi!", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                                if (response.data.detail.cantAddedList.length != 0)
                                    toast(response.data.detail.cantAddedList.length + " Çalışan Eklenemedi! Lütfen yöneticinize başvurun.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: false });
                            }
                        });
                        break;
                    case 2:
                        NetworkRequest("/api/ModuleUserRegistrations/DeleteCollective", rows).then(response => {
                            if (response.data.resultCode == 0) {
                                getUsers(filterData);
                                toast(rows.length + " çalışan silindi!", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                            } else {
                                getUsers(filterData);
                                toast(rows.length + " çalışandan " + response.data.detail + " çalışan silinemedi!", { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
                            }
                        });
                        break;
                    default:
                }
            },
            () => {
                toast("İşlem iptal edildi.", { type: 'error', theme: 'dark', position: 'top-center', autoClose: 2500 });
            }
        );

    }

    //table data default
    const [dataTableModel, setDataTableModel] = useState<IDataTable>({
        header: [
            translate("Adı"),
            translate("Soyadı"),
            translate("E Posta"),
            translate("Bölüm"),
            translate("Fonksiyon"),
            translate("Ünvan"),
            translate("Lokasyon"),
            translate("Şirket Bilgisi"),
            translate("Aktif"),
            translate("İşlemler")
        ],
        data: [
            {
                showData: [],
                secretData: [
                    { id: "" },
                    { isDeleted: false }
                ]
            }
        ],
        transactions: [
            {
                icon: isSigned ? "remove" : "plus",
                desc: isSigned ? "Çalışan Sil" : "Çalışan ekle",
                color: isSigned ? "red" : "green",
                function: onComplate,
                type: isSigned ? "remove" : "add",
            },
        ],
        filter: {
            setSearch: setFilterText,
            search: filterText,
            filterOption: [
                {
                    desc: "Kayıtlı Çalışan",
                    defaultValue: isSigned,
                    key: "isSigned",
                    type: "boolean",
                    onChange: onChangeSigned
                },
                {
                    desc: translate("Aktif"),
                    defaultValue: isActive,
                    key: "isActive",
                    type: "boolean",
                    onChange: onChangeActive
                },

            ]
        },
        pagination: {
            count: 1,
            setCurrentPage: setCurrentPage, currentPage: currentPage
        },
        addRowLink: "",
        isAttachingPage: true,
        setRowSelected: setRowSelected,
        collectiveProgresses:
        {
            options: [{ key: 2, text: 'Toplu Kaldır', value: 2 }],
            event: collectiveProggress
        },
        noCheckBox: true
    });

    //prepare data for table
    const prepareData = (data, count) => {
        if (data.length == 0 && currentPage > 1) {
            setCurrentPage(1);
            return;
        }
        //hidden column
        dataTableModel.data = prepareDataForTable(data, [
            'id',
            'isDeleted'
        ]);

        setDataTableModel({
            ...dataTableModel,
            filter: {
                setSearch: setFilterText,
                search: filterText,
                filterOption: [
                    {
                        desc: "Kayıtlı Çalışan",
                        defaultValue: isSigned,
                        key: "isSigned",
                        type: "boolean",
                        onChange: onChangeSigned
                    },
                    {
                        desc: translate("Aktif"),
                        defaultValue: isActive,
                        key: "isActive",
                        type: "boolean",
                        onChange: onChangeActive
                    },

                ]
            },
            pagination: {
                count: count / 25,
                setCurrentPage: setCurrentPage, currentPage: currentPage
            },
            addRowLink: "",
            setRowSelected: setRowSelected
        });

        //setDataTableModel(dataTableModel);
    }

    const getModuleById = () => {
        return NetworkRequest("api/Modules/GetById", { id: moduleId }).then((response) => {
            if (response.data.resultCode == 0) {
                var result = response.data.detail;
                let model: any = {};
                if (result.title != null) {
                    model.titleUser = result.title + " " + "Modülüne Tanımlanan Çalışanlar";
                    model.titleNotUser = result.title + " " + "Modülüne Tanımlanmayan Çalışanlar";
                    ////console.log("hjdsjvlkdsjfjsdlgvnlönvdlf", model.titleNotUser )
                }
                setModuleName(model);
                setIsLoading(false);
            }
        });
    }

    //get table data from database
    const getUsers = (filterData?) => {
        setIsLoading(true);
        let uri = !isSigned ? "/api/ModuleUserRegistrations/GetNotAssignedUsers" : "/api/ModuleUserRegistrations/GetAssignedUsers";

        let req = {
            id: moduleId,
            pagination: filterData
        };

        NetworkRequest(uri, req).then(response => {
            //console.log("getUsers");
            if (response.data.resultCode == 0) {
                prepareData(response.data.detail.list, response.data.detail.count);
                setIsLoading(false);
            } else {
                toast(translate(response.data.message), { type: 'info', theme: 'dark', position: 'top-center', autoClose: 3500 });
            }
        });
    }

    const checkAssignedUser = () => {
        //let checkData = {
        //    id: moduleId,
        //    pagination: filterData
        //}


        //NetworkRequest("/api/ModuleUserRegistrations/GetAssignedUsers", checkData).then(response => {
        //    if (response.data.resultCode == 0) {
        //        let result = response.data.detail;
        //        if (result == null)
        //            return;
        //        setUserAndCourse({
        //            id: result.id,
        //            userId: result.userId,
        //            moduleId: result.moduleId
        //        });

        //        debugger;
        //        NetworkRequest("/api/User/GetById", result.userId).then(responseUser => {
        //            if (responseUser.data.resultCode == 0) {
        //                let resultUser = responseUser.data.detail;
        //                setUser({ nameSurname: resultUser.name + " " + resultUser.surName, id: resultUser.id, email: resultUser.email })
        //            }
        //        });
        //    }
        //});
    }

    useEffect(() => {
        if (!isSigned) {
            dataTableModel.transactions = [
                {
                    icon: "plus",
                    desc: "Çalışan ekle",
                    color: "green",
                    function: onComplate,
                    type: "add",
                },
            ];
            dataTableModel.collectiveProgresses = {
                options: [{ key: 1, text: 'Toplu Ekle', value: 1 }],
                event: collectiveProggress
            }
            setDataTableModel({ ...dataTableModel });
        } else {
            dataTableModel.transactions = [
                {
                    icon: "remove",
                    desc: "Çalışan sil",
                    color: "red",
                    function: onComplate,
                    type: "delete",
                }
            ];
            dataTableModel.collectiveProgresses = {
                options: [{ key: 2, text: 'Toplu Sil', value: 2 }],
                event: collectiveProggress
            }
            //setDataTableModel({ ...dataTableModel });
            setDataTableModel(dataTableModel);
        }
    }, [isSigned]);

    useEffect(() => {
        if (moduleId != "") {
            /*checkAssignedUser();*/
            getModuleById();
        }
    }, [moduleId]);

    //filter's every change
    useEffect(() => {
        let filterData = {
            filterText: filterText,
            currentPage: currentPage,
            isActive: isActive,
            isDeleted: isDeleted,
            startingDate: startingDate,
            dueDate: dueDate,
            tenantId: DataHandler.getUserDataWithKey("tenantId")
        }

        getUsers(filterData);

    }, [filterText, currentPage, isActive, isDeleted, startingDate, dueDate, isSigned]);

    return (
        <div>
            <Header style={{
                fontSize: "2.5em",
                margin: "0 0 3% 0",
                textAlign: "center",
                color: "#2185d0"
            }}
                as="h3"
            >
                {!isSigned ? moduleName.titleNotUser : moduleName.titleUser}
            </Header>
            <DataTable
                dataTable={dataTableModel}
                isAllSelected={isAllSelected}
                setAllSelected={setAllSelected}
                selectAllHandler={selectAllHandler}
                isLoading={isLoading}
                filterData={filterData}
            />
        </div>
    );
}

export default UserAttachToCourse;