import * as React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Input, Label, Icon, Dropdown } from 'semantic-ui-react';
import { NetworkRequest } from '../../../../helper/NetworkRequest';
import { IDropdown, ILanguageDropdown } from '../../../../interfaces/interfaces';

const AddTenant = (props) => {

    const [displayName, setDisplayName] = useState('');
    const [tenantName, setTenantName] = useState('');
    const [user, setUser] = useState<any>();
    const [parentTenant, setParentTenant] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [parentTenantList, setParentTenantList] = useState<Array<IDropdown>>([]);
    const [languageListDropdown, setLanguageListDropdown] = useState<ILanguageDropdown[]>([]);
    const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState("tr");

    const save = () => {
        let tenantData = {
            tenantName: tenantName,
            displayName: displayName,
            parentTenantId: parentTenant,
            defaultLanguage: selectedDefaultLanguage
        }
        NetworkRequest("/api/Tenants/Add", tenantData).then((response) => {
           
            var result = response.data;
            if (result.resultCode == 0) {
                setUser(response.data.detail);
            } else {
                toast(result.message, {
                    type: 'error',
                    theme: 'dark',
                    position: 'top-center',
                    autoClose: 3000
                });
            }

        });
    }
    const styles = {
        inputContainer: {
            display: "flex",
            justifyContent: "space-between",
            width: "450px",
            alignItems: "center"
        },
        label: {
            minWidth: "130px",
            display: "flex",
            alignItems: "center",
            height: "70%"
        },
        input: {
            width: "202px"
        }
    }

    const removeSpaces = (e) => {
        setTenantName(e.target.value.split(' ').join(''));
    }


    const getParentTenants = (filterData?) => {
        NetworkRequest("/api/Tenants/GetParentTenants", filterData).then((response) => {
            ////
            if (response.data.resultCode === 0) {
                let count = response.data.detail.length;
                var list: Array<IDropdown> = [];
                for (var i = 0; i < response.data.detail.length; i++) {
                    list.push({
                        key: response.data.detail[i].tenantId,
                        value: response.data.detail[i].tenantId,
                        text: response.data.detail[i].displayName
                    });
                }
                setParentTenantList(list);
                setIsLoading(false);
            }
        });
    }

    const changeDropdown = (event, data) => {
        
        setParentTenant(data.value==""?null:data.value);
    }

    const getActiveLanguages = () => {
        setIsLoading(true);
        NetworkRequest("/api/Language/GetActiveLanguages", null)
            .then((response) => {
                if (response.data.resultCode == 0) {
                    const activeLanguages = response.data.detail;

                    activeLanguages.map((l) => {
                        let languageForDropdown: ILanguageDropdown = {
                            flag: l.flag,
                            key: l.key,
                            text: l.text,
                            value: l.value
                        }

                        //console.log(languageForDropdown);

                        setLanguageListDropdown(oldArray => [...oldArray, languageForDropdown])
                    })
                }

                setIsLoading(false);
            });
    }

    const handleChanges = (event: any, data: any) => {
        setSelectedDefaultLanguage(data.value);
    }

    useEffect(() => {
        getParentTenants();
        getActiveLanguages();
    }, []);

    return (
        <Grid columns={1} style={{marginTop:"2rem"} }>
            <Grid.Row>
                <Grid.Column style={styles.inputContainer}>
                    <Label style={styles.label}>
                         Adı: (*)
                    </Label>
                    <Input style={styles.input} value={tenantName} onChange={(e) => { setTenantName(e.target.value); }} onBlur={removeSpaces} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={styles.inputContainer}>
                    <Label style={styles.label}>
                        Görünen Adı: (*)
                    </Label>
                    <Input style={styles.input} onChange={(e) => { setDisplayName(e.target.value); }} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={styles.inputContainer}>
                    <Label style={styles.label}>
                        Üst Şirket:
                    </Label>
                    <Dropdown
                        style={styles.input}
                        value={parentTenant}
                        name="parentTenantId"
                        required="required"
                        clearable
                        options={parentTenantList}
                        selection
                        placeholder="Üst Şirket Seçiniz"
                        onChange={changeDropdown}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column style={styles.inputContainer}>
                    <Label style={styles.label}>
                        Varsayılan Dil:
                    </Label>
                    <Dropdown
                        style={styles.input}
                        placeholder='Dil Seçiniz'
                        clearable
                        search
                        selection
                        defaultValue={"tr"}
                        options={languageListDropdown}
                        onChange={(event, data) => {
                            handleChanges(event, data)
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            {user != undefined
                &&
                <Grid.Row>
                    <Grid.Column>
                        <Label className="userSelect">
                            Bu tenant ile giriş yapabilmek için; kullanıcı adı: {user.userName}, şifre: {user.password} 'dir.
                        </Label>
                    </Grid.Column>
                </Grid.Row>
            }
            <Grid.Row>
                <Grid.Column>
                    <Button className={"dangerRedButton"} onClick={props.showList}>
                        <Icon name="arrow left" />
                    </Button>
                    {user == undefined
                        &&
                        <Button className={"positiveGreenButton"} onClick={save}>
                            <Icon name="check" />
                        </Button>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AddTenant;