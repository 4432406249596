import React, { useEffect } from "react";
import {Button, Header, Loader, Transition} from "semantic-ui-react";

const RightPanel = (props:any) => {

    const styles = {
        rightPanel: {
            width: "80%",
            height: "100vh",
            position: "fixed" as "fixed",
            display: "flex",
            backgroundColor: "white",
            zIndex: "10",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
            flexDirection: "column" as "column",
            transition: "all 0.5s ease-in-out",
            right: props.Open ? "0px" : "-80%",
            top: "0",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px"
        },

        panelHeader: {
            display: "flex",
            alignItems: "center" as "center",
            height: "100px",
            width: "100%",
            padding: "20px",
            justifyContent: "flex-start",
            gap: "2em"
        },
        
        content: {
            padding: "30px",
            overflow:"auto"
        }

         
    }

    useEffect(() => {
        //console.log("propsss", props.SectionContentId)

    }, [props.SectionContentId]);

    return (
        <div>
            <div style={styles.rightPanel}>
                <div style={styles.panelHeader}>
                    <div>
                        <Button
                            onClick={props.ClosePanel}
                            size={"huge"}
                            icon='close'
                        />
                    </div>
                    <div>
                        <Header as='h2'>{props.Header}</Header>
                    </div>
                </div>
                
                <div style={styles.content}>
                    <Transition visible={props.Content === undefined} animation='scale' duration={500}>
                        <Loader size={"huge"} active inline='centered' />
                    </Transition>
                    
                    <Transition visible={props.Content !== undefined} animation='scale' duration={500}>
                        <div>
                            {props.Content}
                        </div>
                    </Transition>
                    
                </div>
            </div>
        </div>
    )
}

export default RightPanel;